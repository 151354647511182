import React, { useCallback, useEffect, useState } from "react"
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { useNavigate } from "react-router-dom";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { proposal_status } from "../../utils/proposal_status";
import { ALL_USERS, STAFF_USERS } from "../../utils/users.groups";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { IFilters, ITagList, proposalsAttributes } from "../../interfaces/index";
import { getAnalyst, getBroker, getIndicator, getStatus } from "../../utils/columnsTables";
import { correctDate, correctName, setUpConsultation } from "../../services/generalServices";

const initialFilters: IFilters[] = [
    getFilterFields("broker_id", STAFF_USERS),
    getFilterFields("date_range", ALL_USERS)
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "indicator", name: "", style: { padding: 0 }, styleContent: { padding: 0 } },
        { attr: "friendly_id", name: "Código", style: { whiteSpace: "nowrap" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "tenants", name: "Locatário(s)", style: {}, styleContent: {} },
        { attr: "owners", name: "Proprietário(s)", style: {}, styleContent: {} },
        { attr: "broker", name: "Corretor(a)", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "analyst", name: "Analista", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "status", name: "Status", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "rent_value", name: "Aluguel", style: { textAlign: "center" }, styleContent: { fontSize: 14, fontWeight: 600, textAlign: "center" } },
        { attr: "updatedAt", name: "Última interação", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } },
        { attr: "createdAt", name: "Abertura", style: { textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } },
    ]
}

const getTenants: React.FC<ITagList> = ({ proposal, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {proposal.consults?.map((owner: any) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], color: "#FFF", fontWeight: 600, fontSize: 12 }}>
                            {correctName(owner.customer?.registration_data as any)}
                        </div>
                    </div>
                )
            })}
            {proposal.consults?.length === 0 &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

const getOwners: React.FC<ITagList> = ({ proposal, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {proposal.property && proposal.property.owners.map((owner: any) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], color: "#FFF", fontWeight: 600, fontSize: 12 }}>
                            {correctName(owner.customer?.registration_data as any)}
                        </div>
                    </div>
                )
            })}
            {(!proposal.property || (proposal.property && proposal?.property?.owners.length === 0)) &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const OpportunitiesList: React.FC = () => {
    const navigate = useNavigate()

    const { markAsRead, notifications, user, api } = useApi()
    const { theme, paginationLimit, notify, franchise } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [numOfProposals, setNumOfProposals] = useState(0)
    const [table, setTable] = useState<ITable>(initialTable)
    const [loadingAccept, setLoadingAccept] = useState(false)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [loadingProposals, setLoadingProposals] = useState(true)

    const searchProposals = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingProposals(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }

            let period: any = {}
            period.date_init = filters_obj.date_init ? filters_obj.date_init : null
            period.date_end = filters_obj.date_end ? filters_obj.date_end : null
            if (!period.date_init || !period.date_end) period = undefined
            else period = JSON.stringify([period.date_init, period.date_end])

            filters_obj.period = period
            filters_obj.opportunities = "true"

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/proposals?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_proposals = result.data.rows as proposalsAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_proposals.forEach((proposal) => {
                    new_table.data.push({
                        id: proposal.id,
                        indicator: getIndicator({ proposal, theme }),
                        friendly_id: `#${proposal.friendly_id}`,
                        tenants: getTenants({ proposal, theme }),
                        owners: getOwners({ proposal, theme }),
                        broker: getBroker({ person: proposal, theme }),
                        analyst: getAnalyst({ proposal, theme }),
                        status: getStatus({ ...proposal_status[proposal.status], theme }),
                        rent_value: proposal.contractorData.rent_value ? maskFunctions.currency.mask(proposal.contractorData.rent_value) : "R$ 0,00",
                        createdAt: correctDate(proposal.createdAt),
                        updatedAt: correctDate(proposal.updatedAt),
                        this: proposal
                    })
                })
                return { ...new_table }
            })

            setNumOfProposals(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelas oportunidades!", "error")
        }
        setLoadingProposals(false)
    }, [franchise, filters, searchString])

    const acceptOpportunity = useCallback(async (proposal: proposalsAttributes) => {
        setLoadingAccept(true)
        try {
            await api.post(`/proposals/accept/${proposal.id}`)
            notify(`Você assumiu a proposta ${proposal.friendly_id}!`, "success")
            navigate(`/proposals?proposalId=${proposal.id}`)
        } catch (err) {
            notify("Erro ao assumir oportunidade!", "error")
            searchProposals(1)
        }
        setLoadingAccept(false)
    }, [franchise, filters, searchString])

    useEffect(() => { searchProposals(1) }, [])

    useEffect(() => {
        const notiticationsUnread = notifications.filter(notification => {
            return notification.type === "new-opportunity" && notification.user_id === user.id
        })
        if (notiticationsUnread.length > 0) markAsRead({ user_id: user.id, type: "new-opportunity" })
    }, [user, notifications])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingProposals}
            setLoading={setLoadingProposals}
            numberOfItems={numOfProposals}
            textHeader="Oportunidades"
            textItems="oportunidades encontradas"
            theme={theme}
            search={searchProposals}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_accept
            accept_action={(proposal: any) => acceptOpportunity(proposal)}
            text_loading={"Buscando Oportunidades"}
            text_empty={"Nenhuma oportunidade encontrada"}
            pages={numberOfPages}
            page={atualPage}
            onChangeSearch={setSearchString}
        >
            <ModalLoading
                loading={loadingAccept}
                theme={theme}
            />
        </PageList>
    )
}
