import { userTypes } from "../interfaces";
import {
  documentEletronicBondsOptions,
  documentsTypesOptions,
  personsTypesOptions,
  statusProposalOptions,
  warrantyNameOptions,
} from "./options";

const fields = {
  franchise_id: {
    key: "franchise_id",
    name: "Imobiliária",
    type: "select_search",
    searchOptionsKey: "franchise_id",
    loading: true,
  },
  broker_id: {
    key: "broker_id",
    name: "Corretor",
    type: "select_search",
    searchOptionsKey: "person_id",
    loading: true,
    moreQueries: { person_types: ["broker"] },
  },
  contract_author_id: {
    key: "person_id",
    name: "Autor do documento",
    type: "select_search",
    searchOptionsKey: "person_id",
    loading: true,
  },
  query_author_id: {
    key: "person_id",
    name: "Autor da consulta",
    type: "select_search",
    searchOptionsKey: "person_id",
    loading: true,
  },
  analyst_id: {
    key: "analyst_id",
    name: "Analista",
    type: "select_search",
    searchOptionsKey: "analyst_id",
    loading: true,
  },
  proposal_status: {
    key: "status",
    name: "Status",
    type: "select",
    options: statusProposalOptions,
  },
  date_range: {
    key: "date_range",
    name: "Período",
    type: "range_picker",
  },
  range_picker: {
    key: "range_picker",
    name: "Período",
    type: "range_picker",
  },
  state_id: {
    key: "state_id",
    name: "Estado",
    type: "select",
    loading: true,
  },
  city_id: {
    key: "city_id",
    name: "Cidade",
    type: "select",
    loading: false,
    options: [],
  },
  person_type: {
    key: "type",
    name: "Tipo",
    type: "select",
    options: personsTypesOptions,
    noIcon: true,
  },
  type_consult: {
    key: "type_consult",
    name: "Tipo",
    type: "select",
    options: personsTypesOptions,
  },
  property_type_id: {
    key: "property_type_id",
    name: "Tipo de imóvel",
    type: "select_search",
    searchOptionsKey: "property_type_id",
    loading: true,
  },
  value_min: {
    key: "value_min",
    name: "Valor mínimo",
    type: "input",
    mask: "currency",
  },
  value_max: {
    key: "value_max",
    name: "Valor máximo",
    type: "input",
    mask: "currency",
  },
  occupation: {
    key: "occupation",
    name: "Status",
    type: "select",
    noIcon: true,
    options: [
      { label: "Disponível", value: "not_have" },
      { label: "Ocupado", value: "have" },
    ],
  },
  bond_type: {
    key: "bond_type",
    name: "Vínculo",
    type: "select",
    options: documentEletronicBondsOptions,
  },
  document_eletronic_status: {
    key: "status",
    name: "Status",
    type: "select",
    loading: false,
    options: [
      { label: "Aguardando assinatura", value: "pending" },
      { label: "Cancelado", value: "canceled" },
      { label: "Assinado", value: "signed" },
    ],
  },
  user_type: {
    key: "person_type",
    name: "Tipo",
    type: "select",
    noIcon: true,
    options: [
      { value: "broker", label: "Corretor" },
      { value: "analyst", label: "Analista" },
      { value: "manager", label: "Gerente" },
    ],
  },
  document_types_type: {
    key: "type",
    name: "Tipo",
    type: "select",
    noIcon: true,
    options: documentsTypesOptions,
  },
  warranty_type: {
    key: "warranty_type",
    name: "Garantia",
    type: "select",
    noIcon: true,
    options: warrantyNameOptions
  },
  is_managed: {
    key: "is_managed",
    name: "Administrado?",
    type: "select",
    noIcon: true,
    options: [
      { value: "true", label: "Sim" },
      { value: "false", label: "Não" },
    ],
  }
};

type FieldsKeys = keyof typeof fields;

export const getFilterFields = (type: FieldsKeys, userTypes?: userTypes[]) => {
  const field: any = fields?.[type] ?? {};
  if (userTypes) field.userTypes = userTypes;

  return field;
};
