import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, inactiveItens, shadowColor } from "../../../theme";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    outline: 0;
    min-width:100vw;
    max-width:100vw;
    min-height: calc(100 * var(--vh));
    max-height: calc(100 * var(--vh));
    box-shadow: 0.125rem 0.125rem 0.5rem ${props => shadowColor[props.theme as themeTypes]};
    @media (min-width: 550px) {
        min-width:80vw;
        max-width:80vw;
        min-height: calc(90 * var(--vh));
        max-height: calc(90 * var(--vh));
    }
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`