import { fail, info, success } from "../theme";

export const queries_status: any = {
    pending: { text: "Pendente", color: info },
    waiting: { text: "Aguardando Birô", color: info },
    approved_by_biro: { text: "Aprovado pelo Birô", color: success },
    rejected_by_biro: { text: "Rejeitado pelo Birô", color: fail },
    approved_by_responsible: { text: "Aprovado pela Imobiliária", color: success },
    rejected_by_responsible: { text: "Rejeitado pela Imobiliária", color: fail },
    not_search: { text: "Não consultado", color: info }
}