import React, { useCallback, useEffect, useState } from "react";
import { FormUser } from "./form";
import { Container } from "./style";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { ModalGeneric } from "../../ModalGeneric";
import { useGlobal } from "../../../hooks/global";
import { personsAttributes } from "../../../interfaces";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { mapDefaultForm, userModalDefaultForm } from "../../../utils/defaultFormsUtils";

interface IModalUserProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

const users_names = {
    capitalize: "Usuário",
    lowercase: "usuário",
    plural: "usuários",
    prefix: "do"
}

const subTitle: any = {
    person: `Informe os dados ${users_names.prefix} ${users_names.capitalize}`,
    person_selected: `Segue abaixo as informações ${users_names.prefix} ${users_names.capitalize}`
}

const title: any = {
    person: `Cadastro de ${users_names.capitalize}`,
    person_selected: `Informações ${users_names.prefix} ${users_names.capitalize}`
}

export const ModalUser: React.FC<IModalUserProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId = null } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [defaultData, setDefaultData] = useState<any>(null)
    const [defaultPerson, setDefaultPerson] = useState<personsAttributes | undefined>(undefined)

    const searchUser = useCallback(async () => {
        if (defaultId) {
            setLoading(true)
            try {

                const { data } = await api.get(`/persons/${defaultId}`)

                const _data: any = mapDefaultForm({ data, fields: userModalDefaultForm })

                setDefaultPerson({ ...data })

                setDefaultData({ ..._data })

            } catch (err) {

                notify(`Erro ao buscar ${users_names.capitalize}`, "error")
                onCancel()
            }
        }
        setLoading(false)
    }, [defaultId])

    useEffect(() => { searchUser() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }} >
            <Container theme={theme}>
                <ModalHeader
                    title={defaultId ? title.person_selected : title.person}
                    subTitle={defaultId ? subTitle.person_selected : subTitle.person}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading loading={loading} theme={theme} type="wave">
                    <FormUser
                        defaultData={defaultData}
                        defaultPerson={defaultPerson}
                        onSave={onSave}
                        onCancel={onCancel}
                    />
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    )
}