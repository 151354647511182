import styled, { css } from "styled-components";
import { themeTypes } from "../../interfaces";

export const Span = styled.span`
    font-style: italic;
    min-height: 24px;
    display: flex;
    flex-direction: column;
`

export const H4 = styled.h4`
    margin: 0px;
    font-weight: 600;
`

export const ContainerLastUpdate = styled.div`
    gap: 10px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: 550px){
        display: none;
    }
`

export const Container = styled.div<{ theme: themeTypes, haveBorder: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    ${props => props.haveBorder && css`
        border-bottom: 1px solid ${props.theme === "light" ? "#CCCCCC" : "#545454"};
        padding-bottom: 10px;
    `}
`