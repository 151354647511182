import React from "react";
import { useGlobal } from "../../../../hooks/global";
import { FieldList, IFieldProps } from "../../../FiedlList";
import { maskFunctions } from "../../../../services/maskServices";
import { proposalsAttributes, warrantyAttributes } from "../../../../interfaces";
import { ContainerObsOrDocs, ContentObsOrDocs, EmptyText, ObsOrDocsTitle } from "../style";
import { get_corrrect_names, responsibleTypeOptions, warrantyNameOptions } from "../../../../utils/options";

interface IWarrantyData {
    warranty?: warrantyAttributes
}

export const WarrantyData: React.FC<IWarrantyData> = (props) => {
    const { warranty } = props

    const { theme } = useGlobal()

    const FieldsDataWarranty: IFieldProps[] = [
        {
            label: "Tipo",
            keys: ["warranty_type"],
            transform: get_corrrect_names(warrantyNameOptions)
        },
        {
            label: "Responsável",
            keys: ["responsible_type"],
            transform: get_corrrect_names(responsibleTypeOptions),
            can_show: (proposal: proposalsAttributes) => ["SECURITY"].includes(proposal?.warranty_type)
        },
        {
            label: "De",
            keys: ["date_init"],
            mask: maskFunctions.date.mask,
            can_show: (proposal: proposalsAttributes) => ["SECURITY", "GUARANTEE_INSURANCE", "CAPITALIZATION_TITLE", "FIDUCIARY_ASSIGNMENT"].includes(proposal?.warranty_type)
        },
        {
            label: "Até",
            keys: ["date_end"],
            mask: maskFunctions.date.mask,
            can_show: (proposal: proposalsAttributes) => ["SECURITY", "GUARANTEE_INSURANCE", "CAPITALIZATION_TITLE", "FIDUCIARY_ASSIGNMENT"].includes(proposal?.warranty_type)
        },
        {
            label: "Valor",
            keys: ["value"],
            mask: maskFunctions.currency.mask,
            can_show: (proposal: proposalsAttributes) => ["SECURITY", "GUARANTEE_INSURANCE", "CAPITALIZATION_TITLE", "FIDUCIARY_ASSIGNMENT"].includes(proposal?.warranty_type)
        },
        {
            label: "Seguradora",
            keys: ["insurance_company"],
            can_show: (proposal: proposalsAttributes) => ["GUARANTEE_INSURANCE"].includes(proposal?.warranty_type)
        },
        {
            label: "Banco",
            keys: ["bank"],
            can_show: (proposal: proposalsAttributes) => ["CAPITALIZATION_TITLE"].includes(proposal?.warranty_type)
        },
        {
            label: "Apólice",
            keys: ["policy"],
            can_show: (proposal: proposalsAttributes) => ["GUARANTEE_INSURANCE", "CAPITALIZATION_TITLE"].includes(proposal?.warranty_type)
        },
        {
            label: "Nº de parcelas",
            keys: ["number_of_installments"],
            can_show: (proposal: proposalsAttributes) => ["GUARANTEE_INSURANCE"].includes(proposal?.warranty_type)
        },
        {
            label: "Tipo de fundo",
            keys: ["background_type"],
            can_show: (proposal: proposalsAttributes) => ["FIDUCIARY_ASSIGNMENT"].includes(proposal?.warranty_type)
        },
        {
            label: "Nº de aplicação",
            keys: ["application_number"],
            can_show: (proposal: proposalsAttributes) => ["FIDUCIARY_ASSIGNMENT"].includes(proposal?.warranty_type)
        }
    ]

    const ListFieldsWarrantyData = [
        {
            title: "",
            fields: FieldsDataWarranty
        }
    ]

    return (
        <>
            <FieldList
                theme={theme}
                data={warranty}
                listFields={ListFieldsWarrantyData}
            />

            {warranty?.warranty_type !== "NO_WARRANTY" &&
                <ContainerObsOrDocs theme={theme}>

                    <ObsOrDocsTitle>
                        OBSERVAÇÕES
                    </ObsOrDocsTitle>

                    <ContentObsOrDocs>
                        {warranty?.observations}
                        {!warranty?.observations && <EmptyText>Sem observações</EmptyText>}
                    </ContentObsOrDocs>

                </ContainerObsOrDocs>
            }
        </>
    )
}