import React from "react";
import Button from "../../../components/Buttons/Button";
import { secondary } from "../../../theme";
import { BsArrowLeft } from "react-icons/bs";
import { useGlobal } from "../../../hooks/global";
import { Bag } from "../../../components/Bags/Bag";
import { IconButton, Tooltip } from "@material-ui/core";
import { ModalGeneric } from "../../../components/ModalGeneric";
import { BiBuilding, BiCopyAlt, BiUserPin } from "react-icons/bi";
import { FiFile, FiFileText, FiHome, FiUser } from "react-icons/fi";
import { Anchor, BagContent, Container, ContainerBack, Content, Header, InputVariable, ModalTitle, Table, TDActions, TDDescription, TDName, TH, THead } from "./style";

interface IModalVariablesProps {
    open: boolean;
    handleClose: () => void;
    handleTestVariables: () => void;
}

export const variables_groups = [
    {
        name: "Imóvel",
        description: "Variáveis do imóvel",
        icon: <FiHome size={24} color={secondary["dark"]} />,
        variables: [
            { name: "{{imovelTipo}}", description: "Tipo de imóvel" },
            { name: "{{imovelValorAluguel}}", description: "Valor do aluguel" },
            { name: "{{imovelValorIPTU}}", description: "Valor do IPTU" },
            { name: "{{imovelValorIPTUExtenso}}", description: "Valor do IPTU por extenso" },
            { name: "{{imovelValorAluguelExtenso}}", description: "Valor do aluguel por extenso" },
            { name: "{{imovelValorCondominio}}", description: "Valor do condomínio" },
            { name: "{{imovelValorCondominioExtenso}}", description: "Valor do condomínio por extenso" },
            { name: "{{imovelTaxaAdministracao}}", description: "Taxa de administração" },
            { name: "{{imovelTaxaAdministracaoExtenso}}", description: "Taxa de administração por extenso" },
            { name: "{{imovelTaxaLocacao}}", description: "Taxa de locação" },
            { name: "{{imovelTaxaLocacaoExtenso}}", description: "Taxa de locação por extenso" },
            { name: "{{imovelCodAgua}}", description: "Código da água" },
            { name: "{{imovelCodLuz}}", description: "Código da luz" },
            { name: "{{imovelCodGas}}", description: "Código do gás" },
            { name: "{{imovelIPTU}}", description: "IPTU" },
            { name: "{{imovelMetragem}}", description: "Metragem do imóvel" },
            { name: "{{imovelLocalizacao}}", description: "Localização" },
            { name: "{{imovelDestinacao}}", description: "Destinação" },
            { name: "{{imocelCEP}}", description: "CEP" },
            { name: "{{imovelEstado}}", description: "Estado" },
            { name: "{{imovelUF}}", description: "UF" },
            { name: "{{imovelCidade}}", description: "Cidade" },
            { name: "{{imovelBairro}}", description: "Bairro" },
            { name: "{{imovelLogradouro}}", description: "Logradouro" },
            { name: "{{imovelNumero}}", description: "Número" },
            { name: "{{imovelComplemento}}", description: "Complemento" },
            { name: "{{imovelEndereco}}", description: "Endereço completo" },
            { name: "{{imovelCaptador}}", description: "Corretor" }
        ]
    },
    {
        name: "Proprietário",
        description: "Variáveis do proprietário",
        icon: <BiUserPin size={24} color={secondary["dark"]} />,
        variables: [
            { name: "{{proprietarioTipo}}", description: "Tipo de pessoa" },
            { name: "{{proprietarioNome}}", description: "Nome" },
            { name: "{{proprietarioRazaoSocial}}", description: "Razão social" },
            { name: "{{proprietarioNomeFantasia}}", description: "Nome fantasia" },
            { name: "{{proprietarioEmail}}", description: "E-mail" },
            { name: "{{proprietarioTelefone}}", description: "Telefone" },
            { name: "{{proprietarioNascimento}}", description: "Data de nascimento" },
            { name: "{{proprietarioCNPJ}}", description: "CNPJ" },
            { name: "{{proprietarioCPF}}", description: "CPF" },
            { name: "{{proprietarioRG}}", description: "RG" },
            { name: "{{proprietarioOrgaoEmissor}}", description: "Órgão emissor" },
            { name: "{{proprietarioNacionalidade}}", description: "Nacionalidade" },
            { name: "{{proprietarioProfissao}}", description: "Profissão" },
            { name: "{{proprietarioEstadoCivil}}", description: "Estado civil" },
            { name: "{{proprietarioInscricaoMunicipal}}", description: "Inscrição municipal" },
            { name: "{{proprietarioInscricaoEstadual}}", description: "Inscrição estadual" },
            { name: "{{proprietarioCEP}}", description: "CEP" },
            { name: "{{proprietarioEstado}}", description: "Estado" },
            { name: "{{proprietarioUF}}", description: "UF" },
            { name: "{{proprietarioCidade}}", description: "Cidade" },
            { name: "{{proprietarioBairro}}", description: "Bairro" },
            { name: "{{proprietarioLogradouro}}", description: "Logradouro" },
            { name: "{{proprietarioNumero}}", description: "Número" },
            { name: "{{proprietarioComplemento}}", description: "Complemento" },
            { name: "{{proprietarioEndereco}}", description: "Endereço completo" },
            { name: "{{proprietarioFormaRecebimento}}", description: "Forma de recebimento" },
            { name: "{{proprietarioBanco}}", description: "Banco" },
            { name: "{{proprietarioAgencia}}", description: "Agência" },
            { name: "{{proprietarioConta}}", description: "Conta" },
            { name: "{{proprietarioTipoConta}}", description: "Tipo da Conta" },
            { name: "{{proprietarioOperacao}}", description: "Operação" },
            { name: "{{proprietarioRecebedor}}", description: "Recebedor" },
            { name: "{{proprietarioCPFCNPJ}}", description: "CPF/CNPJ" },
            { name: "{{proprietarioConjugeNome}}", description: "Nome cônjuge" },
            { name: "{{proprietarioConjugeCPF}}", description: "CPF cônjuge" },
            { name: "{{proprietarioConjugeRG}}", description: "RG cônjuge" },
            { name: "{{proprietarioConjugeOrgaoEmissor}}", description: "Órgão emissor cônjuge" },
            { name: "{{proprietarioConjugeNacionalidade}}", description: "Nacionalidade cônjuge" },
            { name: "{{proprietarioConjugeProfissao}}", description: "Profissão cônjuge" },
            { name: "{{proprietarioConjugeEmail}}", description: "E-mail cônjuge" },
            { name: "{{proprietarioConjugeTelefone}}", description: "Telefone cônjuge" },
            { name: "{{proprietarioRepresentanteNome}}", description: "Nome representante" },
            { name: "{{proprietarioRepresentanteCPF}}", description: "CPF representante" },
            { name: "{{proprietarioRepresentanteRG}}", description: "RG representante" },
            { name: "{{proprietarioRepresentanteOrgaoEmissor}}", description: "Órgão emissor representante" },
            { name: "{{proprietarioRepresentanteNacionalidade}}", description: "Nacionalidade representante" },
            { name: "{{proprietarioRepresentanteProfissao}}", description: "Profissão representante" },
            { name: "{{proprietarioRepresentanteEmail}}", description: "E-mail representante" },
            { name: "{{proprietarioRepresentanteTelefone}}", description: "Telefone representante" },
            { name: "{{proprietarioDadosTodos}}", description: "Dados completo de todos os proprietários" },
            { name: "{{proprietarioDadosComConjuge}}", description: "Dados proprietário com cônjuge " },
            { name: "{{proprietarioDadosComRepresentante}}", description: "Dados proprietário com representante" }
        ]
    },
    {
        name: "Inquilino",
        description: "Variáveis do inquilino",
        icon: <FiUser size={24} color={secondary["dark"]} />,
        variables: [
            { name: "{{inquilinoTipo}}", description: "Tipo de pessoa" },
            { name: "{{inquilinoNome}}", description: "Nome" },
            { name: "{{inquilinoRazaoSocial}}", description: "Razão social" },
            { name: "{{inquilinoNomeFantasia}}", description: "Nome fantasia" },
            { name: "{{inquilinoEmail}}", description: "E-mail" },
            { name: "{{inquilinoTelefone}}", description: "Telefone" },
            { name: "{{inquilinoNascimento}}", description: "Data de nascimento" },
            { name: "{{inquilinoCNPJ}}", description: "CNPJ" },
            { name: "{{inquilinoCPF}}", description: "CPF" },
            { name: "{{inquilinoRG}}", description: "RG" },
            { name: "{{inquilinoOrgaoEmissor}}", description: "Órgão emissor" },
            { name: "{{inquilinoNacionalidade}}", description: "Nacionalidade" },
            { name: "{{inquilinoProfissao}}", description: "Profissão" },
            { name: "{{inquilinoEstadoCivil}}", description: "Estado civil" },
            { name: "{{inquilinoInscricaoMunicipal}}", description: "Inscrição municipal" },
            { name: "{{inquilinoInscricaoEstadual}}", description: "Inscrição estadual" },
            { name: "{{inquilinoCEP}}", description: "CEP" },
            { name: "{{inquilinoEstado}}", description: "Estado" },
            { name: "{{inquilinoUF}}", description: "UF" },
            { name: "{{inquilinoCidade}}", description: "Cidade" },
            { name: "{{inquilinoBairro}}", description: "Bairro" },
            { name: "{{inquilinoLogradouro}}", description: "Logradouro" },
            { name: "{{inquilinoNumero}}", description: "Número" },
            { name: "{{inquilinoComplemento}}", description: "Complemento" },
            { name: "{{inquilinoEndereco}}", description: "Endereço completo" },
            { name: "{{inquilinoConjugeNome}}", description: "Nome cônjuge" },
            { name: "{{inquilinoConjugeCPF}}", description: "CPF cônjuge" },
            { name: "{{inquilinoConjugeRG}}", description: "RG cônjuge" },
            { name: "{{inquilinoConjugeOrgaoEmissor}}", description: "Órgão emissor cônjuge" },
            { name: "{{inquilinoConjugeNacionalidade}}", description: "Nacionalidade cônjuge" },
            { name: "{{inquilinoConjugeProfissao}}", description: "Profissão cônjuge" },
            { name: "{{inquilinoConjugeEmail}}", description: "E-mail cônjuge" },
            { name: "{{inquilinoConjugeTelefone}}", description: "Telefone cônjuge" },
            { name: "{{inquilinoRepresentanteNome}}", description: "Nome representante" },
            { name: "{{inquilinoRepresentanteCPF}}", description: "CPF representante" },
            { name: "{{inquilinoRepresentanteRG}}", description: "RG representante" },
            { name: "{{inquilinoRepresentanteOrgaoEmissor}}", description: "Órgão emissor representante" },
            { name: "{{inquilinoRepresentanteNacionalidade}}", description: "Nacionalidade representante" },
            { name: "{{inquilinoRepresentanteProfissao}}", description: "Profissão representante" },
            { name: "{{inquilinoRepresentanteEmail}}", description: "E-mail representante" },
            { name: "{{inquilinoRepresentanteTelefone}}", description: "Telefone representante" },
            { name: "{{inquilinoDadosTodos}}", description: "Dados completo de todos os inquilinos" },
            { name: "{{inquilinoDadosComConjuge}}", description: "Dados inquilino com cônjuge " },
            { name: "{{inquilinoDadosComRepresentante}}", description: "Dados inquilino com representante" }
        ]
    },
    {
        name: "Fiador",
        description: "Variáveis do fiador",
        icon: <FiUser size={24} color={secondary["dark"]} />,
        variables: [
            { name: "{{fiadorTipo}}", description: "Tipo de pessoa" },
            { name: "{{fiadorNome}}", description: "Nome" },
            { name: "{{fiadorRazaoSocial}}", description: "Razão social" },
            { name: "{{fiadorNomeFantasia}}", description: "Nome fantasia" },
            { name: "{{fiadorEmail}}", description: "E-mail" },
            { name: "{{fiadorTelefone}}", description: "Telefone" },
            { name: "{{fiadorNascimento}}", description: "Data de nascimento" },
            { name: "{{fiadorCNPJ}}", description: "CNPJ" },
            { name: "{{fiadorCPF}}", description: "CPF" },
            { name: "{{fiadorRG}}", description: "RG" },
            { name: "{{fiadorOrgaoEmissor}}", description: "Órgão emissor" },
            { name: "{{fiadorNacionalidade}}", description: "Nacionalidade" },
            { name: "{{fiadorProfissao}}", description: "Profissão" },
            { name: "{{fiadorEstadoCivil}}", description: "Estado civil" },
            { name: "{{fiadorInscricaoMunicipal}}", description: "Inscrição municipal" },
            { name: "{{fiadorInscricaoEstadual}}", description: "Inscrição estadual" },
            { name: "{{fiadorCEP}}", description: "CEP" },
            { name: "{{fiadorEstado}}", description: "Estado" },
            { name: "{{fiadorUF}}", description: "UF" },
            { name: "{{fiadorCidade}}", description: "Cidade" },
            { name: "{{fiadorBairro}}", description: "Bairro" },
            { name: "{{fiadorLogradouro}}", description: "Logradouro" },
            { name: "{{fiadorNumero}}", description: "Número" },
            { name: "{{fiadorComplemento}}", description: "Complemento" },
            { name: "{{fiadorEndereco}}", description: "Endereço completo" },
            { name: "{{fiadorConjugeNome}}", description: "Nome cônjuge" },
            { name: "{{fiadorConjugeCPF}}", description: "CPF cônjuge" },
            { name: "{{fiadorConjugeRG}}", description: "RG cônjuge" },
            { name: "{{fiadorConjugeOrgaoEmissor}}", description: "Órgão emissor cônjuge" },
            { name: "{{fiadorConjugeNacionalidade}}", description: "Nacionalidade cônjuge" },
            { name: "{{fiadorConjugeProfissao}}", description: "Profissão cônjuge" },
            { name: "{{fiadorConjugeEmail}}", description: "E-mail cônjuge" },
            { name: "{{fiadorConjugeTelefone}}", description: "Telefone cônjuge" },
            { name: "{{fiadorRepresentanteNome}}", description: "Nome representante" },
            { name: "{{fiadorRepresentanteCPF}}", description: "CPF representante" },
            { name: "{{fiadorRepresentanteRG}}", description: "RG representante" },
            { name: "{{fiadorRepresentanteOrgaoEmissor}}", description: "Órgão emissor representante" },
            { name: "{{fiadorRepresentanteNacionalidade}}", description: "Nacionalidade representante" },
            { name: "{{fiadorRepresentanteProfissao}}", description: "Profissão representante" },
            { name: "{{fiadorRepresentanteEmail}}", description: "E-mail representante" },
            { name: "{{fiadorRepresentanteTelefone}}", description: "Telefone representante" },
            { name: "{{fiadorDadosTodos}}", description: "Dados completo de todos os fiadores" },
            { name: "{{fiadorDadosComConjuge}}", description: "Dados fiador com cônjuge " },
            { name: "{{fiadorDadosComRepresentante}}", description: "Dados fiador com representante" }
        ]
    },
    {
        name: "Contrato",
        description: "Variáveis do contrato",
        icon: <FiFileText size={24} color={secondary["dark"]} />,
        variables: [

            { name: "{{contratoDiasLocacao}}", description: "Quantidade de dias" },
            { name: "{{contratoDiasLocacaoExtenso}}", description: "Quantidade de dias por extenso" },
            { name: "{{contratoMesesLocacao}}", description: "Quantidade de meses" },
            { name: "{{contratoMesesLocacaoExtenso}}", description: "Quantidade de meses por extenso" },
            { name: "{{contratoDataInicio}}", description: "Data de início" },
            { name: "{{contratoDataInicioSemiExtenso}}", description: "Data de início semi extenso" },
            { name: "{{contratoDataInicioPorExtenso}}", description: "Data de início por extenso" },
            { name: "{{contratoDataOcupacao}}", description: "Data ocupação" },
            { name: "{{contratoDataOcupacaoSemiExtenso}}", description: "Data ocupação semi extenso" },
            { name: "{{contratoDataOcupacaoPorExtenso}}", description: "Data ocupação por extenso" },
            { name: "{{contratoDataTermino}}", description: "Data de término" },
            { name: "{{contratoDataTerminoSemiExtenso}}", description: "Data de término semi extenso" },
            { name: "{{contratoDataTerminoPorExtenso}}", description: "Data de término por extenso" },
            { name: "{{contratoValorIPTU}}", description: "Valor do IPTU" },
            { name: "{{contratoValorAluguel}}", description: "Valor do aluguel" },
            { name: "{{contratoValorIPTUExtenso}}", description: "Valor do IPTU por extenso" },
            { name: "{{contratoValorAluguelExtenso}}", description: "Valor do aluguel extenso" },
            { name: "{{contratoDiaVencimento}}", description: "Dia de vencimento" },
            { name: "{{contratoDiaVencimentoExtenso}}", description: "Dia de vencimento por extenso" },
            { name: "{{contratoIndiceReajuste}}", description: "Indice de reajuste" },
            { name: "{{contratoTipo}}", description: "Tipo de contrato" },
            { name: "{{contratoTipoGarantia}}", description: "Tipo de garantia" },
            { name: "{{contratoTipoCaucao}}", description: "Tipo de caução" },
            { name: "{{contratoResponsavelCaucao}}", description: "Responsável pela caução" },
            { name: "{{contratoValorGarantia}}", description: "Valor da garantia" },
            { name: "{{contratoValorGarantiaExtenso}}", description: "Valor da garantia por extenso" },
            { name: "{{contratoSeguradoraGarantia}}", description: "Nome seguradora" },
            { name: "{{contratoBancoTituloGarantia}}", description: "Banco do título" },
            { name: "{{contratoDataInicioGarantia}}", description: "Data início garantia" },
            { name: "{{contratoDataTerminoGarantia}}", description: "Data término garantia" },
            { name: "{{contratoApoliceGarantia}}", description: "Apólice garantia" },
            { name: "{{contratoObservacaoGarantia}}", description: "Observação da garantia" },
            { name: "{{contratoDetalheGarantia}}", description: "Texto com detalhes da garantia" }
        ]
    },
    {
        name: "Imobiliária",
        description: "Variáveis da imobiliária",
        icon: <BiBuilding size={24} color={secondary["dark"]} />,
        variables: [
            { name: "{{imobiliariaNome}}", description: "Razão social da imobiliária" },
            { name: "{{imobiliariaNomeFantasia}}", description: "Nome fantasia da imobiliária" },
            { name: "{{imobiliariaCidade}}", description: "Cidade da imobiliária" },
            { name: "{{imobiliariaEstado}}", description: "Estada imobiliária" },
            { name: "{{imobiliariaEndereco}}", description: "Endereço da imobiliária" },
            { name: "{{imobiliariaCNPJ}}", description: "CNPJ da imobiliária" },
            { name: "{{imobiliariaTelefone}}", description: "Telefone da imobiliária" }
        ]
    },
    {
        name: "Gerais",
        description: "Variáveis gerais",
        icon: <FiFile size={24} color={secondary["dark"]} />,
        variables: [
            { name: "{{hoje}}", description: "Data de hoje" },
            { name: "{{diaAtual}}", description: "Dia atual" },
            { name: "{{diaAtualExtenso}}", description: "Dia atual extenso" },
            { name: "{{mesAtual}}", description: "Mês atual" },
            { name: "{{mesAtualExtenso}}", description: "Mês atual extenso" },
            { name: "{{anoAtual}}", description: "Ano atual" },
            { name: "{{anoAtualExtenso}}", description: "Ano atual extenso" }
        ]
    }
]

const DRIVE_LINK = "https://drive.google.com/drive/folders/1Z-XZOdMzH6qNnRp-KTgHbkTM2_dWrqRs?usp=share_link"

export const ModalVariables: React.FC<IModalVariablesProps> = (props) => {
    const { open, handleClose, handleTestVariables } = props;

    const { theme, notifyBottom } = useGlobal();

    const copyVariable = (_variable_name: string) => {
        try {
            const variable_name: any = document.getElementById(_variable_name)
            if (variable_name) {
                variable_name?.select();
                const success = document.execCommand("copy");
                variable_name.selectionEnd = 0
                if (success) return notifyBottom("Variável copiada para área de tranferência!", "success");
                else throw new Error();
            }
        } catch (err) {
            notifyBottom("Erro ao copiar o link para área de tranferência!", "error");
        }
    }

    return (
        <ModalGeneric onClose={handleClose} open={open}>
            <Container theme={theme}>
                <Header theme={theme}>
                    <ContainerBack onClick={handleClose}>
                        <BsArrowLeft size={20} />
                    </ContainerBack>
                    <ModalTitle>
                        Variáveis
                    </ModalTitle>
                </Header>
                <Content>
                    <span style={{ fontFamily: "Montserrat" }}>
                        Utilize as variáveis para preencher de forma automática os dados das pessoas,
                        imóveis e contratos nos documentos
                        eletrônicos. <Anchor theme={theme} target="_blank" href={DRIVE_LINK}>Clique aqui</Anchor> para acessar alguns modelos de documentos.
                    </span>
                    <Button
                        background={secondary[theme]}
                        color="#FFF"
                        onClick={() => handleTestVariables()}
                        children="Clique para visualizar valores reais das variáveis"
                    />
                    {variables_groups.map((group) => (
                        <Bag key={group.name} defaultOpen={false} icon={group.icon} description={group.description} title={group.name}>
                            <BagContent theme={theme}>
                                <Table>
                                    <THead theme={theme}>
                                        <tr>
                                            <TH>Descrição</TH>
                                            <TH style={{ width: "100%" }}>Nome</TH>
                                            <TH style={{ textAlign: "center" }}>Ações</TH>
                                        </tr>
                                    </THead>
                                    <tbody>
                                        {group.variables.map((variable) => (
                                            <tr key={variable.name} onClick={() => copyVariable(variable.name)} style={{ cursor: "pointer" }}>
                                                <TDDescription>
                                                    {variable.description}
                                                </TDDescription>
                                                <TDName>
                                                    <InputVariable
                                                        theme={theme}
                                                        id={variable.name}
                                                        readOnly
                                                        value={variable.name}
                                                    />
                                                </TDName>
                                                <TDActions>
                                                    <Tooltip title="Copiar" style={{ margin: 0, padding: 10 }}>
                                                        <IconButton aria-label="Copiar">
                                                            <BiCopyAlt
                                                                size={20}
                                                                color={secondary[theme]}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TDActions>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </BagContent>
                        </Bag>
                    ))}
                </Content>
            </Container>
        </ModalGeneric>
    )

}