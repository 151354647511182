import styled, { css } from "styled-components";
import { secondary } from "../../../../../theme";
import { IThemeProps, themeTypes } from "../../../../../interfaces";

export const Form = styled.form`
    display: flex;
    justify-content:space-between;
    flex-direction: column;
    gap: 20px;
`

export const EditButton = styled.div<IThemeProps>`
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    :hover{
        ${props => css`
            color: ${secondary[props.theme as themeTypes]};
        `}
    }
`

export const TextArea = styled.textarea`
    resize: none;
    padding: 10px;
`

export const BaseInput = styled.div`
    width:100%;
    textarea{
        width:100%;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        :disabled{
            background: #F2F2F2 !important;
            border: 1px solid #E6E6E6;
            color:#999999;
        }
        :focus{
            box-shadow:none !important;
            outline:2px solid #2684FF !important;
        }
    }
`