import React, { useCallback, useEffect, useState } from "react";
import { Column } from "../../../style";
import { secondary } from "../../../theme";
import { useApi } from "../../../hooks/api";
import { AiOutlineReload } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
import { useGlobal } from "../../../hooks/global";
import { CircularProgress } from "@material-ui/core";
import { ModalConfirmCancel } from "./ModalConfirmCancel";
import { DashboardPage } from "../../../components/DashboardPage";
import { ContentOrLoading } from "../../../components/Loadings/ContentOrLoading";
import { TransactionList } from "../../../components/PlansComponents/TransactionList";
import { subscriptionsAttributes, transactionsAttributes } from "../../../interfaces";
import { SubscriptionCard } from "../../../components/PlansComponents/SubscriptionCard";
import { correctDateDashboard, setUpConsultation } from "../../../services/generalServices";
import { SelectNoCreateSingleMulti } from "../../../components/Inputs/BaseSelects/SelectNoCreateSingleMulti";
import { ContainerLastUpdate, ContainerNoSubscription, ContainerReload, ContainerSelectFranchise, ContainerSubscription, Content, H4, Line, SubTitle, TextNoSubscription, Title } from "./style";

export const SubscriptionRoot: React.FC = () => {
    const { theme, notify } = useGlobal()
    const { api, consultsServices } = useApi()

    const [loading_all, set_loading_all] = useState(true)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false)
    const [loadingCancelSubscription, setLoadingCancelSubscription] = useState(false)
    const [franchiseIdSelected, setFranchiseIdSelected] = useState<string | null>(null)
    const [transaction_selected, setTransactionSelected] = useState<string | null>(null)
    const [subscription, setSubscription] = useState<subscriptionsAttributes | null>(null)
    const [transactions_subscriptions, setTransactionsSubscriptions] = useState<transactionsAttributes[]>([])

    const searchSubscritpion = useCallback(async () => {
        if (franchiseIdSelected) {
            set_loading_all(true)
            try {
                const string = setUpConsultation({ franchise_id: franchiseIdSelected })
                const url = "/franchises/subscription?" + string
                const { data } = await api.get(url)

                setSubscription(data?.subscription)
                setTransactionsSubscriptions(data?.transactions)
                setUpdatedAt(new Date())
            } catch (err) {
                console.log(err)
                notify("Erro ao buscar assinatura da empresa!", "error")
            }
        }
        set_loading_all(false)
    }, [franchiseIdSelected])

    const handleCancelSubscription = useCallback(async () => {
        setLoadingCancelSubscription(true)
        try {
            await api.post(`/subscriptions/cancel/${subscription?.id}`)
            notify("Assinatura cancelada com sucesso!", "success")
        } catch (err) {
            notify("Erro ao cancelar assinatura!", "error")
        }
        setOpenCancelSubscription(false)
        setLoadingCancelSubscription(false)
        searchSubscritpion()
    }, [franchiseIdSelected, subscription])

    useEffect(() => { searchSubscritpion() }, [franchiseIdSelected])

    return (
        <DashboardPage>

            <Title theme={theme}>
                <Column>
                    <H4>Assinatura</H4>
                    <ContainerLastUpdate>
                        {!loading_all ?
                            <Column>
                                <Line>
                                    <SubTitle>Última atualização realizada em <b>{correctDateDashboard(updatedAt)}</b></SubTitle>
                                    <ContainerReload onClick={() => searchSubscritpion()}>
                                        <AiOutlineReload />
                                    </ContainerReload>
                                </Line>
                            </Column>
                            :
                            <Line>
                                <SubTitle>Atualizando dados da sua assinatura</SubTitle>
                                <CircularProgress size={10} variant="indeterminate" style={{ color: secondary[theme] }} />
                            </Line>
                        }
                    </ContainerLastUpdate>
                </Column>
                <ContainerSelectFranchise>
                    <SelectNoCreateSingleMulti
                        isRequired
                        onChange={(value: any) => setFranchiseIdSelected(value?.value ?? null)}
                        noOptionsMessage="Nenhuma imobiliária encontrada"
                        searchOptions={(search: string) => consultsServices.franchise_id({ search })}
                        icon={<BiBuildingHouse size={20} />}
                        isDisabled={loading_all}
                    />
                </ContainerSelectFranchise>
            </Title>

            <ContentOrLoading loading={loading_all} text="Buscando assinatura da imobiliária" theme={theme}>
                <Content>
                    {!franchiseIdSelected && (
                        <ContainerNoSubscription>
                            <TextNoSubscription>
                                Nenhuma imobiliária selecionada
                            </TextNoSubscription>
                        </ContainerNoSubscription>
                    )}
                    {!subscription?.id && franchiseIdSelected && (
                        <ContainerNoSubscription>
                            <TextNoSubscription>
                                Imobiliária ainda não possui uma assinatura
                            </TextNoSubscription>
                        </ContainerNoSubscription>
                    )}
                    {subscription?.id && franchiseIdSelected && (
                        <ContainerSubscription>
                            <SubscriptionCard
                                openCancelSubscription={() => setOpenCancelSubscription(true)}
                                changeCreditCard={() => { }}
                                changePlan={() => { }}
                                subscription={subscription}
                                theme={theme}
                                isRoot
                            />
                            <TransactionList
                                transactions={transactions_subscriptions}
                                theme={theme}
                                setTransactionSelected={setTransactionSelected}
                                transaction_selected={transaction_selected}
                            />
                        </ContainerSubscription>
                    )}
                </Content>
            </ContentOrLoading>

            {openCancelSubscription && (
                <ModalConfirmCancel
                    loading={loadingCancelSubscription}
                    onCancel={() => !loadingCancelSubscription ? setOpenCancelSubscription(false) : {}}
                    onConfirm={handleCancelSubscription}
                    open={openCancelSubscription}
                />
            )}

        </DashboardPage>
    )
}