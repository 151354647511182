import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

interface ProposalChatContextData {
    scroll_message_container_ref: React.RefObject<HTMLDivElement>;
    controlRenderMessages: boolean;
    setControlRenderMessages: React.Dispatch<React.SetStateAction<boolean>>;
    socket: Socket | null;
    setSocket(value: Socket | null): void;
    socketSteps: Socket | null;
    setSocketSteps(value: Socket | null): void;
    socketContracts: Socket | null;
    setSocketContracts(value: Socket | null): void;
    socketContract: Socket | null;
    setSocketContract(value: Socket | null): void;
}

const ProposalChatContext = createContext<ProposalChatContextData>({} as ProposalChatContextData)

export const ProposalChatProvider: React.FC = ({ children }) => {
    const scroll_message_container_ref = React.useRef<HTMLDivElement>(null)

    const [socket, setSocket] = useState<Socket | null>(null)
    const [socketSteps, setSocketSteps] = useState<Socket | null>(null)
    const [controlRenderMessages, setControlRenderMessages] = useState(false)
    const [socketContract, setSocketContract] = useState<Socket | null>(null)
    const [socketContracts, setSocketContracts] = useState<Socket | null>(null)

    useEffect(() => {
        const socketInstanceChat = io(`${process.env.REACT_APP_API}`)
        const socketInstanceSteps = io(`${process.env.REACT_APP_API}`)
        const socketInstanceContracts = io(`${process.env.REACT_APP_API}`)
        const socketInstanceContract = io(`${process.env.REACT_APP_API}`)

        setSocketContract((atual) => {
            atual?.removeAllListeners()
            atual?.disconnect()
            return socketInstanceContract
        })
        
        setSocketContracts((atual) => {
            atual?.removeAllListeners()
            atual?.disconnect()
            return socketInstanceContracts
        })

        setSocket((atual) => {
            atual?.removeAllListeners()
            atual?.disconnect()
            return socketInstanceChat
        })

        setSocketSteps((atual) => {
            atual?.removeAllListeners()
            atual?.disconnect()
            return socketInstanceSteps
        })
    }, [])

    return (
        <ProposalChatContext.Provider value={{ controlRenderMessages, setSocketContract, socketContract, setSocketContracts, socketContracts, setSocketSteps, socketSteps, setSocket, socket, scroll_message_container_ref, setControlRenderMessages }}>
            {children}
        </ProposalChatContext.Provider>
    )
}

export function useProposalChat(): ProposalChatContextData {
    const context = useContext(ProposalChatContext)
    if (!context) {
        throw new Error("useProposalChat must be used within an ProposalChatProvider")
    }
    return context;
}