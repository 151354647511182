import React from "react";
import { useApi } from "../../../hooks/api";
import { IOptionsProps } from "../../../interfaces";
import { ModalPerson } from "../../Modals/ModalPerson";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { ISelectCreateMultiProps, ISelectCreateMultiRefProps, SelectCreateMulti } from "../BaseSelects/SelectCreateMulti";

export interface ISelectMultiPersonsRefProps {
    getValues: () => IOptionsProps[]
    focus: () => void
}

interface ISelectMultiPersonsProps extends ISelectCreateMultiProps {
    searchOptions: Function
    onCreateOption: Function
    defaultValue?: IOptionsProps[]
    icon?: any
    moreQueries?: object
    get_enabled_change_form_type?: (type: "edit" | "new") => boolean
    backgroundVisible?: boolean
    removeIds?: string[]
    isPersonInPerson?: boolean
    personType?: "spouse" | "person" | "representative"
}

const SelectMultiPersonsComponent: React.ForwardRefRenderFunction<ISelectMultiPersonsRefProps, ISelectMultiPersonsProps> = (props, ref) => {
    const { backgroundVisible, removeIds, isPersonInPerson, personType } = props
    const { defaultValue = [], onChange = () => { }, get_enabled_change_form_type = () => true } = props

    const [selected, setSelected] = useState<IOptionsProps | null>(null)
    const [openModalNewPerson, setOpenModalNewPerson] = useState(false)
    const [personsSelected, setPersonsSelected] = useState<IOptionsProps[]>(defaultValue)

    const select_ref = useRef<ISelectCreateMultiRefProps>(null)

    const { consultsServices } = useApi()

    const handleCancelModalPerson = useCallback(() => {
        setOpenModalNewPerson(false)
        setSelected(null)
    }, [])

    const handleSaveModalPerson = useCallback((data: IOptionsProps) => {
        let newPersonsSelected: IOptionsProps[] = []
        setPersonsSelected((prev) => {
            const index = prev.findIndex((item) => item.value === data.value)

            if (index >= 0) prev[index] = data
            else prev.push(data)

            newPersonsSelected = [...prev]

            return newPersonsSelected
        })

        handleCancelModalPerson()

        onChange(newPersonsSelected)
    }, [])

    const handleCreateNewPerson = useCallback(() => {
        setOpenModalNewPerson(true)
    }, [])

    const handleEditPerson = useCallback((value: IOptionsProps) => {
        setSelected({ ...value })
    }, [])

    const onChangeOption = useCallback((options) => {
        setPersonsSelected([...options])
        onChange(options)
    }, [])

    const getValues = useCallback(() => personsSelected, [personsSelected])

    const focus = useCallback(() => { select_ref.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ getValues, focus }))

    return (
        <>
            <SelectCreateMulti
                ref={select_ref}
                placeholder=""
                onClick={handleEditPerson}
                onChange={onChangeOption}
                {...props}
                onCreateOption={handleCreateNewPerson}
                searchOptions={(search: string) => consultsServices.person_id({ search, ...props.moreQueries, removeIds })}
            />

            {(selected?.value || openModalNewPerson) && (
                <ModalPerson
                    openModal
                    onCancel={handleCancelModalPerson}
                    onSave={handleSaveModalPerson}
                    defaultId={selected?.value}
                    enabled_change_form_type={get_enabled_change_form_type(selected?.value ? "edit" : "new")}
                    backgroundVisible={backgroundVisible}
                    isPersonInPerson={isPersonInPerson}
                    personType={personType}
                />
            )}
        </>
    )
}

export const SelectMultiPersons = forwardRef(SelectMultiPersonsComponent)