import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { BiExport } from "react-icons/bi";
import { ModalImport } from "./ModalImport";
import { ModalExport } from "./ModalExport";
import { useGlobal } from "../../hooks/global";
import { useWallet } from "../../hooks/wallet";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { getBroker } from "../../utils/columnsTables";
import { fail, secondary, success } from "../../theme";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { ALL_USERS, STAFF_USERS } from "../../utils/users.groups";
import { ModalProperty } from "../../components/Modals/ModalProperty";
import { correctName, setUpConsultation } from "../../services/generalServices";
import { Superlogica16 } from "../../components/SvgComponents/Icons/Superlogica/Superlogica16";
import {
  propertiesAttributes,
  ITagList,
  IActions,
  personsAttributes,
  IIntegrationsProps,
  IFilters,
} from "../../interfaces/index";

const initialFilters: IFilters[] = [
  getFilterFields("property_type_id", ALL_USERS),
  getFilterFields("state_id", ALL_USERS),
  getFilterFields("city_id", ALL_USERS),
  getFilterFields("broker_id", STAFF_USERS),
  getFilterFields("value_min", ALL_USERS),
  getFilterFields("value_max", ALL_USERS),
  getFilterFields("occupation", ALL_USERS),
];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "indicator",
      name: "",
      style: { padding: 0 },
      styleContent: { padding: 0 },
    },
    {
      attr: "cod",
      name: "Código",
      style: {},
      styleContent: { fontSize: 16, fontWeight: 600 },
    },
    { attr: "type", name: "Tipo de imóvel", style: {}, styleContent: {} },
    { attr: "address", name: "Endereço", style: {}, styleContent: {} },
    { attr: "owners", name: "Proprietário(s)", style: {}, styleContent: {} },
    {
      attr: "broker",
      name: "Corretor(a)",
      style: { textAlign: "center" },
      styleContent: { textAlign: "center" },
    },
    {
      attr: "rent_value",
      name: "Valor do aluguel",
      style: { whiteSpace: "nowrap", textAlign: "center" },
      styleContent: { fontSize: 14, fontWeight: 600, textAlign: "center" },
    },
  ],
};

const getAddress: React.FC<propertiesAttributes> = (property) => {
  return (
    <>
      {property.address?.public_place}, {property.address?.district}
      <br />
      {property.address?.number}, {property.address?.city.name} -{" "}
      {property.address?.state.initials}
    </>
  );
};

const getOwners: React.FC<ITagList> = ({ property, theme }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
      {property.owners?.map((owner: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{
                padding: 5,
                whiteSpace: "nowrap",
                display: "flex",
                paddingLeft: 7,
                paddingRight: 7,
                borderRadius: 3,
                background: secondary[theme],
                color: "#FFF",
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              {correctName(owner.customer?.registration_data as any)}
            </div>
          </div>
        );
      })}
      {property.owners?.length === 0 && (
        <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>
          Nenhum(a)
        </span>
      )}
    </div>
  );
};

const getIndicator: React.FC<ITagList> = ({ property }) => {
  return (
    <div
      style={{
        background: property.status === "FREE" ? success : fail,
        width: 10,
        height: 30,
      }}
    />
  );
};

export const PropertyList: React.FC = () => {
  const { api, user } = useApi();
  const { subscription } = useWallet();
  const { theme, paginationLimit, notify, franchise } = useGlobal();

  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [openModal, setOpenModal] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [table, setTable] = useState<ITable>(initialTable);
  const [selecteds, setSelecteds] = useState<string[]>([]);
  const [numOfpropertiess, setNumOfpropertiess] = useState(0);
  const [searchString, setSearchString] = useState<string>("");
  const [openModalImport, setOpenModalImport] = useState(false);
  const [openModalExport, setOpenModalExport] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [loadingpropertiess, setLoadingpropertiess] = useState(true);
  const [openModalExportAll, setOpenModalExportAll] = useState(false);
  const [propertySelected, setPropertySelected] =
    useState<propertiesAttributes | null>(null);
  const [integrationsTokens, setIntegrationsTokens] =
    useState<IIntegrationsProps>({
      super_logica: {},
      click_sign: {},
    } as IIntegrationsProps);

  const searchPropertiess = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingpropertiess(true);
      try {
        await searchIntegrationsTokens();
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        const string = setUpConsultation(filters_obj);
        const url = `/generic_searches/properties?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);

        const new_properties = result.data.rows as propertiesAttributes[];

        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_properties.forEach((property) => {
            new_table.data.push({
              id: property.id,
              indicator: getIndicator({ property, theme }),
              cod: `#${property.friendly_id}`,
              type: property.property_type?.name || null,
              address: getAddress(property),
              owners: getOwners({ property, theme }),
              broker: getBroker({ person: property, theme }),
              rent_value: property.rent_value
                ? maskFunctions.currency.mask(property.rent_value)
                : null,
              this: property,
            });
          });
          return { ...new_table };
        });

        setNumOfpropertiess(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelas imóveis!", "error");
      }
      setLoadingpropertiess(false);
    },
    [franchise, filters, searchString]
  );

  const onSaveProperties = useCallback(() => {
    setOpenModal(false);
    searchPropertiess(1);
  }, [franchise, filters, searchString]);

  const selectProperties = useCallback((properties: propertiesAttributes) => {
    setPropertySelected({ ...properties });
    setOpenModal(true);
  }, []);

  const openModalNewproperties = useCallback(() => {
    setPropertySelected(null);
    setOpenModal(true);
  }, []);

  const getQueryPropertyId = useCallback(() => {
    const query = window.location.search.split("?").pop();
    const queries = query ? query.split("&") : [""];
    let propertyId: any = queries.find(
      (item: string) => item.split("=")[0] === "propertyId"
    );
    propertyId = propertyId ? propertyId.split("=")[1] : null;
    if (propertyId) {
      setPropertySelected({ id: propertyId } as any);
      setOpenModal(true);
    }
  }, []);

  const handleAction = (action_id: string) => {
    if (action_id === "new_item") openModalNewproperties();
    else if (action_id === "import_super") setOpenModalImport(true);
    else if ("export_all" === action_id) setOpenModalExportAll(true);
    else if ("export_super" === action_id) setOpenModalExport(true);
    else console.log({ action_id });
  };

  const handleFinish = useCallback(() => {
    searchPropertiess(atualPage);
  }, [atualPage, franchise, filters, searchString]);

  const validateAccessCRM = useCallback(() => {
    const access_token = integrationsTokens.super_logica.access_token;
    const app_token = integrationsTokens.super_logica.app_token;
    const status = integrationsTokens.super_logica.status;
    const permissions = JSON.parse(
      (subscription?.plan?.permissions as any) ?? "[]"
    );
    const plan_have_crm = !!permissions?.includes("crms");
    return (
      !!access_token && !!app_token && status === "active" && plan_have_crm
    );
  }, [integrationsTokens, subscription]);

  const searchIntegrationsTokens = useCallback(async () => {
    try {
      const { data } = await api.get(`/integrations/get-tokens`);

      setIntegrationsTokens(data);
    } catch (err) {
      notify("Erro ao buscar as integrações", "error");
    }

    return true;
  }, []);

  const actionsInitial: IActions[] = [
    {
      only_to_selecteds: true,
      main_option: { text: "Exportar", id: "export" },
      className: "export",
      icon: <BiExport size={18} className="export" style={{ marginTop: -5 }} />,
      options: [
        {
          text: "Exportar - Superlógica",
          id: "export_super",
          validateAccess: validateAccessCRM,
          icon: <Superlogica16 color={"#1034F2"} />,
        },
      ],
      canAccess: ["manager", "responsible", "analyst"],
    },
    {
      main_option: { text: "Exportar Todos", id: "export_main_all" },
      className: "export_main_all",
      icon: (
        <BiExport
          size={18}
          className="export_main_all"
          style={{ marginTop: -5 }}
        />
      ),
      options: [
        {
          text: "Exportar Todos - Superlógica",
          id: "export_all",
          validateAccess: validateAccessCRM,
          icon: <Superlogica16 color={"#1034F2"} />,
        },
      ],
      canAccess: ["manager", "responsible", "analyst"],
      validateAccess: validateAccessCRM,
    },
    {
      main_option: { text: "Novo Imóvel", id: "new_item" },
      className: "new_item",
      icon: <IoAddCircleOutline size={20} className="new_item" />,
      options: [],
      type: "solid",
      validateAccess: () => !user?.type.includes("root"),
    },
  ];

  useEffect(() => {
    searchPropertiess(1);
  }, []);

  useEffect(() => {
    getQueryPropertyId();
  }, []);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingpropertiess}
      setLoading={setLoadingpropertiess}
      numberOfItems={numOfpropertiess}
      textHeader="Imóveis"
      textItems="imóveis encontrados"
      text_loading={"Buscando Imóveis"}
      text_empty={"Nenhum imóvel encontrado"}
      theme={theme}
      actions={actionsInitial}
      search={searchPropertiess}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      onChangeSelecteds={setSelecteds}
      table={table}
      have_edit
      edit_action={(customer: any) => selectProperties(customer)}
      pages={numberOfPages}
      page={atualPage}
      onClickOption={handleAction}
      onChangeSearch={setSearchString}
    >
      {openModal && (
        <ModalProperty
          onCancel={() => setOpenModal(false)}
          onSave={() => onSaveProperties()}
          openModal={openModal}
          backgroundVisible={false}
          defaultId={propertySelected?.id}
        />
      )}
      {openModalImport && (
        <ModalImport
          onClose={() => setOpenModalImport(false)}
          open={openModalImport}
          onFinish={handleFinish}
          theme={theme}
          franchise_id={franchise.id}
        />
      )}
      {openModalExport && (
        <ModalExport
          onClose={() => setOpenModalExport(false)}
          open={openModalExport}
          onFinish={handleFinish}
          theme={theme}
          franchise_id={franchise.id}
          to_export={selecteds.map((id) => ({ id } as personsAttributes))}
        />
      )}
      {openModalExportAll && (
        <ModalExport
          onClose={() => setOpenModalExportAll(false)}
          open={openModalExportAll}
          onFinish={handleFinish}
          theme={theme}
          franchise_id={franchise.id}
          to_export={[]}
        />
      )}
    </PageList>
  );
};
