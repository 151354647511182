import Button from "../../components/Buttons/Button";
import React, { useCallback, useState, useEffect } from "react";
import PaginationCustom from "../../components/PaginationCustom";
import { Column } from "../../style";
import { useApi } from "../../hooks/api";
import { IoCard } from "react-icons/io5";
import { useGlobal } from "../../hooks/global";
import { useWallet } from "../../hooks/wallet";
import { PixSharp } from "@mui/icons-material";
import { RiBarcodeFill } from "react-icons/ri";
import { Bag } from "../../components/Bags/Bag";
import { BsArrowUpShort } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { TbHeartHandshake } from "react-icons/tb";
import { FiInfo, FiPlusCircle } from "react-icons/fi";
import { getStatus } from "../../utils/columnsTables";
import { maskFunctions } from "../../services/maskServices";
import { DashboardPage } from "../../components/DashboardPage";
import { ModalReceipt } from "../../components/Modals/ModalReceipt";
import { ModalContract } from "../../components/Modals/ModalContract";
import { MaxCoin } from "../../components/SvgComponents/Icons/MaxCoin";
import { MdLibraryBooks, MdOutlineRemoveRedEye } from "react-icons/md";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { ModalTransaction } from "../../components/Modals/ModalTransaction";
import { ModalResultQuery } from "../../components/Modals/ModalQuery/Result";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { correctDate, correctDateDashboard } from "../../services/generalServices";
import { ContentOrLoadingBag } from "../../components/Loadings/ContentOrLoadingBag";
import { MaxCoinSmall20 } from "../../components/SvgComponents/Icons/MaxCoinSmall20";
import { background, fail, inactiveItens, info, secondary, success } from "../../theme";
import { contractsAttributes, themeTypes, transactionsAttributes } from "../../interfaces";
import { BalanceCard, ContainerBalance, ContainerIconMaxCoin, ContainerLastUpdate, ContainerPagination, ContainerReload, ContainerTable, H4, Line, SubTitle, Title } from "./style";

const colors: any = {
    "outline": {
        "dark": secondary.dark,
        "light": secondary.light
    },
    "solid": {
        "dark": "#FFF",
        "light": "#FFF"
    }
}

const status_of_transaction: any = {
    "increase": {
        "new": {
            color: info,
            text: "Aguardando pagamento"
        },
        "waiting": {
            color: info,
            text: "Aguardando pagamento"
        },
        "identified": {
            color: info,
            text: "Aguardando pagamento"
        },
        "approved": {
            color: info,
            text: "Aguardando pagamento"
        },
        "paid": {
            color: success,
            text: "Pago"
        },
        'paid-free': {
            color: info,
            text: 'Teste grátis'
        },
        "unpaid": {
            color: fail,
            text: "Não pago"
        },
        "refunded": {
            color: fail,
            text: "Devolvido"
        },
        "contested": {
            color: fail,
            text: "Contestado"
        },
        "canceled": {
            color: fail,
            text: "Cancelado"
        },
        "settled": {
            color: success,
            text: "Pago"
        },
        "link": {
            color: info,
            text: "Aguardando pagamento"
        },
        "expired": {
            color: fail,
            text: "Expirado"
        }
    },
    "decrease": {
        "new": {
            color: info,
            text: "Provisionado"
        },
        "waiting": {
            color: info,
            text: "Provisionado"
        },
        "paid": {
            color: success,
            text: "Pago"
        },
        "unpaid": {
            color: fail,
            text: "Não pago"
        },
        "refunded": {
            color: fail,
            text: "Devolvido"
        },
        "contested": {
            color: fail,
            text: "Contestado"
        },
        "canceled": {
            color: fail,
            text: "Cancelado"
        },
        "settled": {
            color: success,
            text: "Pago"
        },
        "link": {
            color: info,
            text: "Provisionado"
        },
        "expired": {
            color: fail,
            text: "Expirado"
        }
    }
}

const transaction_description = (transaction: transactionsAttributes) => {
    if (transaction?.query?.id) return `Consulta ${transaction?.query?.type}`
    else if (transaction.contract?.id) return `Doc. eletrônico concluído`
    else return "Compra de créditos"
}

const transaction_pay_method = (transaction: transactionsAttributes, theme: themeTypes) => {
    if (transaction.method === "credit_card") {
        return (
            <Line style={{ alignItems: "center", gap: 5 }}>
                <IoCard size={20} color={inactiveItens[theme]} />
                <b style={{ textTransform: "capitalize" }}>{transaction?.card_flag} </b>
                ****{transaction?.card_mask?.replace(/\D/g, "")}
            </Line>
        )
    }
    else if (transaction.method === "pix") {
        return (
            <Line style={{ alignItems: "center", gap: 5 }}>
                <PixSharp fontSize="small" />
                <b style={{ textTransform: "capitalize", color: inactiveItens[theme] }}>Pix</b>
            </Line>
        )
    }
    else if (transaction.method === "mxs") return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <div style={{ display: "flex", background: secondary[theme], justifyContent: "center", alignItems: "center", minWidth: 36, maxWidth: 36, minHeight: 36, maxHeight: 36, borderRadius: "50%" }}>
                <MaxCoinSmall20 />
            </div>
            <b style={{ textTransform: "capitalize" }}>Créditos MXs</b>
        </Line>
    )
    else if (transaction.method === "banking_billet") return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <RiBarcodeFill size={20} color={inactiveItens[theme]} />
            <b style={{ textTransform: "capitalize" }}>Boleto Bancário</b>
        </Line>
    )
    else return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <TbHeartHandshake size={20} color={inactiveItens[theme]} />
            <b style={{ textTransform: "capitalize" }}>Grátis</b>
        </Line>
    )
}

const transaction_types_aux: any = {
    "increase": {
        color: success,
        rotate: 0,
        text: "Entrada"
    },
    "decrease": {
        color: fail,
        rotate: 180,
        text: "Saída"
    }
}

const hoursToMs = (hours: number) => {
    return hours * 60 * 60 * 1000
}

const correct_status = (transaction: transactionsAttributes) => {
    const createdAt = new Date(transaction?.createdAt ?? "")
    let status = transaction.status
    const atualDate = new Date()
    const diff = Math.abs(atualDate.getTime() - createdAt.getTime())
    if (diff > hoursToMs(1) && transaction.method === "pix") status = status === "waiting" ? "expired" : status
    return status
}

export const Wallet: React.FC = () => {
    const { api } = useApi()
    const _wallet = useWallet()
    const { theme, notify, franchise, multiple_mxs, menuOpen } = useGlobal()

    const [openModal, setOpenModal] = useState(false)
    const [query_selected, setQuerySelected] = useState<string | null>(null)
    const [transaction_selected, setTransactionSelected] = useState<string | null>(null)
    const [contract_selected, setContractSelected] = useState<contractsAttributes | null>(null)

    const onSaveTransaction = useCallback(async (transaction_id) => {
        notify("Solicitação de compra de créditos realizada com sucesso!", "success")
        setOpenModal(false)
        setTransactionSelected(transaction_id)
        await search()
    }, [])

    const search = useCallback(async () => {
        try {
            _wallet.set_loading_all(true)
            await api.get(`/franchises/validate-subsctiption`)
            await _wallet.forceSearchAll()
        } catch (err: any) {
            await _wallet.forceSearchAll()
        }
    }, [franchise, _wallet])

    const onClickSeeDetails = useCallback(async (_transaction: transactionsAttributes) => {
        const transaction_type = _transaction.type
        const query_id = _transaction.query_id
        const contract = _transaction.contract
        setTransactionSelected(null)
        setQuerySelected(null)
        setContractSelected(null)
        if (transaction_type === "increase") setTransactionSelected(_transaction.id)
        else if (query_id) setQuerySelected(query_id)
        else if (contract?.id) setContractSelected(contract)
    }, [])

    useEffect(() => { search() }, [])

    return (
        <DashboardPage>
            <Title theme={theme}>
                <Column>
                    <H4>Carteira de Créditos</H4>
                    <ContainerLastUpdate menuIsOpen={menuOpen}>
                        {!_wallet.loading_all ?
                            <>
                                <SubTitle>Última atualização realizada em <b>{correctDateDashboard(_wallet.updatedAt)}</b></SubTitle>
                                <ContainerReload onClick={() => search()}>
                                    <AiOutlineReload />
                                </ContainerReload>
                            </>
                            :
                            <>
                                <SubTitle>Atualizando dados da sua carteira de créditos</SubTitle>
                                <CircularProgress
                                    size={10}
                                    variant="indeterminate"
                                    style={{ color: secondary[theme] }}
                                />
                            </>
                        }
                    </ContainerLastUpdate>
                </Column>
            </Title>
            <ContentOrLoading loading={_wallet.loading_all} text="Buscando dados da sua carteira de créditos" theme={theme}>
                <Column style={{ flex: 1, paddingTop: 20, gap: 20 }}>
                    <ContainerBalance theme={theme}>
                        <BalanceCard>
                            <ContainerIconMaxCoin>
                                <MaxCoin size={50} />
                            </ContainerIconMaxCoin>
                            <Column style={{ color: "#FFF", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ fontSize: 30, textAlign: "center" }}>
                                    Seu saldo de MXs
                                </div>
                                <div style={{ fontSize: 50, fontWeight: 600 }}>
                                    {maskFunctions.mxs.mask((_wallet.wallet?.balance ?? 0) * multiple_mxs)}
                                </div>
                                <Line style={{ gap: 5, color: "#FAAD14", alignItems: "center" }}>
                                    <b>{maskFunctions.mxs.mask((_wallet.wallet?.provisioned ?? 0) * multiple_mxs)}</b>
                                    <Line style={{ alignItems: "center", gap: 5 }}>
                                        <span style={{ fontSize: 12, fontWeight: "normal" }}>provisionados</span>
                                        <Tooltip placement="top" style={{ padding: 0 }} title="Saldo reservado para Doc. Eletrônicos em aberto">
                                            <IconButton aria-label="Saldo reservado para Doc. Eletrônicos em aberto">
                                                <FiInfo size={15} color={"#FAAD14"} />
                                            </IconButton>
                                        </Tooltip>
                                    </Line>
                                </Line>
                            </Column>
                        </BalanceCard>
                        <Line style={{ justifyContent: "center" }}>
                            <div>
                                <Button
                                    background={secondary[theme]}
                                    color="#FFF"
                                    style={{ gap: 10, alignItems: "center", whiteSpace: "nowrap" }}
                                    onClick={() => setOpenModal(true)}
                                >
                                    <FiPlusCircle size={26} />
                                    Comprar créditos
                                </Button>
                            </div>
                        </Line>
                    </ContainerBalance>
                    <Bag
                        title="Extrato"
                        description="Aqui estão todas suas movimentações de créditos"
                        icon={<MdLibraryBooks color={secondary[theme]} size={26} />}
                    >
                        <ContentOrLoadingBag theme={theme} type="wave" loading={_wallet.loading_transactions_extract}>
                            <Column style={{ gap: 20, padding: 20, justifyContent: "center" }}>
                                {_wallet.transactions_extract.length > 0 &&
                                    <>
                                        <ContainerTable theme={theme}>
                                            <table cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                                                <thead>
                                                    <tr style={{ background: background[theme] }}>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>CÓDIGO</th>
                                                        <th style={{ whiteSpace: "nowrap", padding: 10, textAlign: "center" }}>EFETUADA EM</th>
                                                        <th style={{ padding: 10 }}>DESCRIÇÃO</th>
                                                        <th style={{ padding: 10 }}>VALOR EM CRÉDITOS</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>TRANSAÇÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_wallet.transactions_extract.map((transaction) => (
                                                        <tr style={{ background: colors[theme] }} key={transaction.id}>
                                                            <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                {transaction?.friendly_id ? `#${transaction?.friendly_id}` : "-"}
                                                            </td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                                {correctDate(transaction.updatedAt)}
                                                            </td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                                                {transaction_description(transaction)} <b onClick={() => onClickSeeDetails(transaction)} style={{ cursor: "pointer" }}>ver detalhes</b>
                                                            </td>
                                                            <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                <Line style={{ alignItems: "center", gap: 5 }}>
                                                                    <MaxCoinSmall20 />
                                                                    <div>{maskFunctions.mxs.mask(transaction.value * multiple_mxs)}</div>
                                                                </Line>
                                                            </td>
                                                            <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                <Line style={{ alignItems: "center", gap: 5, textTransform: "capitalize" }}>
                                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", transform: `rotate(${transaction_types_aux[transaction.type].rotate}deg)` }}>
                                                                        <BsArrowUpShort size={22} color={transaction_types_aux[transaction.type].color} />
                                                                    </div>
                                                                    <b>{transaction_types_aux[transaction.type].text}</b>
                                                                </Line>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </ContainerTable>
                                        <ContainerPagination>
                                            <PaginationCustom
                                                page={_wallet.atual_page_extract}
                                                pages={_wallet.number_of_pages_extract}
                                                disable={_wallet.loading_transactions_extract}
                                                onChange={(value: any) => _wallet.forceSearchTransactionsExtract(value)}
                                            />
                                        </ContainerPagination>
                                    </>
                                }
                                {_wallet.transactions_extract.length === 0 &&
                                    <div style={{ textAlign: "center", fontStyle: "italic", opacity: 0.7 }}>Nenhuma movimentação de crédito</div>
                                }
                            </Column>
                        </ContentOrLoadingBag>
                    </Bag>
                    <Bag
                        title="Histórico de compras de créditos"
                        description="Relatório de compras de créditos realizadas"
                        icon={<MdLibraryBooks color={secondary[theme]} size={26} />}
                    >
                        <ContentOrLoadingBag theme={theme} type="wave" loading={_wallet.loading_transactions_history}>
                            <Column style={{ gap: 20, padding: 20, justifyContent: "center" }}>
                                {_wallet.transactions_history.length > 0 &&
                                    <>
                                        <ContainerTable theme={theme}>
                                            <table cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                                                <thead>
                                                    <tr style={{ background: background[theme] }}>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>CÓDIGO</th>
                                                        <th style={{ whiteSpace: "nowrap", padding: 10, textAlign: "center" }}>GERADA EM</th>
                                                        <th style={{ whiteSpace: "nowrap", padding: 10, textAlign: "center" }}>PAGO EM</th>
                                                        <th style={{ padding: 10 }}>PREÇO</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>VALOR EM CRÉDITOS</th>
                                                        <th style={{ textAlign: "center", padding: 10, whiteSpace: "nowrap" }}>MÉTODO DE PAGAMENTO</th>
                                                        <th style={{ textAlign: "center", padding: 10, whiteSpace: "nowrap" }}>STATUS</th>
                                                        <th style={{ padding: 10, textAlign: "center" }}> AÇÕES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_wallet.transactions_history.map((transaction) => (
                                                        <tr onClick={() => setTransactionSelected(transaction.id)} style={{ background: colors[theme] }} key={transaction.id}>
                                                            <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                {transaction?.friendly_id ? `#${transaction?.friendly_id}` : "-"}
                                                            </td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                                {correctDate(transaction.createdAt)}
                                                            </td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                                {["paid", "settled", "paid-free"].includes(correct_status(transaction)) ? correctDate(transaction.updatedAt) : "-"}
                                                            </td>
                                                            <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                {maskFunctions.currency.mask(transaction.value)}
                                                            </td>
                                                            <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                <Line style={{ alignItems: "center", gap: 5 }}>
                                                                    <MaxCoinSmall20 />
                                                                    <div>{maskFunctions.mxs.mask(transaction.value * multiple_mxs)}</div>
                                                                </Line>
                                                            </td>
                                                            <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                <Line style={{ alignItems: "center", gap: 5, justifyContent: "center" }}>
                                                                    {transaction_pay_method(transaction, theme)}
                                                                </Line>
                                                            </td>
                                                            <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                <Line style={{ justifyContent: "center" }}>
                                                                    {getStatus({ ...status_of_transaction.increase[correct_status(transaction)], theme })}
                                                                </Line>
                                                            </td>
                                                            <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                                <Tooltip onClick={() => setTransactionSelected(transaction.id)} title={"Visualizar"} style={{ margin: 5 }}>
                                                                    <IconButton aria-label={"Visualizar"}>
                                                                        <MdOutlineRemoveRedEye color={secondary[theme]} size={20} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </ContainerTable>
                                        <ContainerPagination>
                                            <PaginationCustom
                                                page={_wallet.atual_page_history}
                                                pages={_wallet.number_of_pages_history}
                                                disable={_wallet.loading_transactions_history}
                                                onChange={(value: any) => _wallet.forceSearchTransactionsHistory(value)}
                                            />
                                        </ContainerPagination>
                                    </>
                                }
                                {_wallet.transactions_history.length === 0 &&
                                    <div style={{ textAlign: "center", fontStyle: "italic", opacity: 0.7 }}>Nenhuma compra de crédito</div>
                                }
                            </Column>
                        </ContentOrLoadingBag>

                    </Bag>
                </Column>
            </ContentOrLoading>
            {openModal &&
                <ModalTransaction
                    subscription={_wallet.subscription}
                    onCancel={() => setOpenModal(false)}
                    onSave={onSaveTransaction}
                    openModal={openModal}
                />
            }
            {transaction_selected &&
                <ModalReceipt
                    transaction_id={transaction_selected}
                    onCancel={() => setTransactionSelected(null)}
                    openModal={true}
                />
            }
            {query_selected &&
                <ModalResultQuery
                    onCancel={() => setQuerySelected(null)}
                    openModal={!!query_selected}
                    backgroundVisible={false}
                    defaultId={query_selected}
                />
            }
            {contract_selected?.id &&
                <ModalContract
                    onCancel={() => setContractSelected(null)}
                    onSave={() => { }}
                    openModal={!!contract_selected?.id}
                    backgroundVisible={false}
                    defaultContract={contract_selected}
                />
            }

        </DashboardPage >
    )
}