import React from "react";
import { Login } from "../pages/Login/index";
import { QueryPDF } from "../pages/QueryPDF";
import { NewAccount } from "../pages/NewAccount";
import { ResetPassword } from "../pages/ResetPassword";
import { Routes as Switch, Route, Navigate } from "react-router-dom";

export const AuthRoutes: React.FC = () => {
    return (
        <Switch>
            <Route path="query-pdf/:query_id" element={<QueryPDF />} />
            <Route path="login" element={<Login />} />
            <Route path="create-account" element={<NewAccount />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Switch>
    )
}
