import styled, { css } from "styled-components";
import { borderColors, primary } from "../../../../../theme";
import { IThemeProps, themeTypes } from "../../../../../interfaces";

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`

export const ContainerDefineQuantity = styled.div<IThemeProps>`
    display: flex;
    gap:20px;
    @media (max-width: 1000px){
        flex-wrap: wrap;
    }
`

export const ContainerMxCoins = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
    border-radius: 4px;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-width: 30vw;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
        border: 1px solid ${borderColors[props.theme as themeTypes]};
    `}
`

export const ContainerTable = styled.div<IThemeProps>`
    font-size: 13.5px;
    border-radius: 4px;
    overflow: auto;
    display:flex;
    flex:1;
    max-width:calc(100vw - 40px);
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]};
    `}
`