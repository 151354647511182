import React, { useCallback, useImperativeHandle, useState } from "react";
import { useApi } from "../../../hooks/api";
import { IOptionsProps } from "../../../interfaces";
import { ModalPerson } from "../../Modals/ModalPerson";
import { SelectFixedCards } from "../BaseSelects/SelectFixedCards";
import { ISelectCreateSingleProps, ISelectCreateSingleRefProps, SelectCreateSingle } from "../BaseSelects/SelectCreateSingle";

export interface ISelectPersonRefProps {
    getValue: () => IOptionsProps | null
    focus: () => void
}

interface ISelectPersonProps extends ISelectCreateSingleProps {
    defaultValue?: IOptionsProps
    icon?: any
    moreQueries?: object
    get_enabled_change_form_type?: (type: "edit" | "new") => boolean
    backgroundVisible?: boolean
    removeIds?: string[]
    isPersonInPerson?: boolean
    personType?: "spouse" | "person" | "representative"
    options?: IOptionsProps[]
}

const SelectPersonComponent: React.ForwardRefRenderFunction<ISelectPersonRefProps, ISelectPersonProps> = (props, ref) => {
    const { backgroundVisible, removeIds, isPersonInPerson, personType, options } = props
    const { defaultValue = null, onChange = () => { }, get_enabled_change_form_type = () => true } = props

    const { consultsServices } = useApi()

    const [openModalNewPerson, setOpenModalNewPerson] = useState(false)
    const [openModalEditPerson, setOpenModalEditPerson] = useState(false)
    const [personSelected, setPersonSelected] = useState<IOptionsProps | null>(defaultValue)

    const select_ref = React.useRef<ISelectCreateSingleRefProps>(null)

    const onChangeOption = useCallback((value) => {
        setPersonSelected(value ? { ...value } : null)
        onChange(value ? { ...value } : null)
    }, [])

    const handleCancelModalPerson = useCallback(() => {
        setOpenModalNewPerson(false)
        setOpenModalEditPerson(false)
    }, [])

    const handleSaveModalPerson = useCallback((data: IOptionsProps) => {
        setPersonSelected(data)
        handleCancelModalPerson()
        onChange(data)
    }, [options])

    const handleCreateNewPerson = useCallback(() => {
        setOpenModalNewPerson(true)
    }, [])

    const handleEditPerson = useCallback(() => {
        setOpenModalEditPerson(true)
    }, [])

    const getValue = useCallback(() => personSelected, [personSelected])

    const focus = useCallback(() => { select_ref.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ getValue, focus }))

    return (
        <>
            {options && (
                <SelectFixedCards
                    ref={select_ref}
                    placeholder=""
                    onClick={handleEditPerson}
                    onChange={onChangeOption}
                    options={options}
                    {...props}
                />
            )}

            {!options && (
                <SelectCreateSingle
                    ref={select_ref}
                    placeholder=""
                    onClick={handleEditPerson}
                    onChange={onChangeOption}
                    {...props}
                    onCreateOption={handleCreateNewPerson}
                    searchOptions={(search: string) => consultsServices.person_id({ search, ...props.moreQueries, removeIds })}
                />
            )}

            {(openModalEditPerson || openModalNewPerson) && (
                <ModalPerson
                    openModal
                    onCancel={handleCancelModalPerson}
                    onSave={handleSaveModalPerson}
                    defaultId={openModalEditPerson ? personSelected?.value : undefined}
                    enabled_change_form_type={get_enabled_change_form_type(openModalEditPerson ? "edit" : "new")}
                    backgroundVisible={backgroundVisible}
                    isPersonInPerson={isPersonInPerson}
                    personType={personType}
                    relation={personSelected?.relation}
                />
            )}
        </>
    )
}

export const SelectPerson = React.forwardRef(SelectPersonComponent)