import styled, { css } from "styled-components";
import { themeTypes } from "../../interfaces";

export const ContainerMessage = styled.div<{ revert: boolean, theme: themeTypes }>`
    display: flex;
    flex-direction: column;
    padding: 10px;
    white-space: pre-line;
    border-radius: 5px;
    gap:10px;
    position: relative;
    background-color: #383838;
    ${props => css`
            background-color: ${props.theme === "dark" ? "#383838" : "#F6F6F6"};
    `}
    ::before{
        border-style:solid;
        border-color: #383838 transparent;
        ${props => css`
            border-color: ${props.theme === "dark" ? "#383838" : "#F6F6F6"} transparent;
        `}
        border-width: 0.375rem 0.375rem 0 0.375rem;
        top:10px;
        content: "";
        position: absolute;
        ${props => props.revert && css`
            transform: rotate(-90deg);
            right:-8px;
        `}
        ${props => !props.revert && css`
            transform: rotate(90deg);
            left:-8px;
        `}
    }
`