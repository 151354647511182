import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { ROOT_USERS } from "../../utils/users.groups";
import { get_corrrect_names } from "../../utils/options";
import { personsTypesOptions } from "../../utils/options";
import { getFilterFields } from "../../utils/filterFields";
import { setUpConsultation } from "../../services/generalServices";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ModalAttention } from "../../components/Modals/ModalAttention";
import { ModalCreditPolicy } from "../../components/Modals/ModalCreditPolicy";
import { credit_policiesAttributes, IActions, IFilters } from "../../interfaces/index";

const initialFilters: IFilters[] = [
    getFilterFields("type_consult", ROOT_USERS)
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome", style: { width: "50%" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "type", name: "Tipo", style: { width: "50%" }, styleContent: {} }
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Nova Política de Análise", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

export const CreditPoliciesList: React.FC = () => {
    const { api } = useApi()
    const { theme, paginationLimit, notify } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [numOfCreditPolicies, setNumOfCreditPolicies] = useState(0)
    const [loadingCreditPolicies, setLoadingCreditPolicies] = useState(true)
    const [loadingDestroyCreditPolicy, setLoadingDestroyCreditPolicy] = useState(false)
    const [openModalDestroyCreditPolicy, setOpenModalDestroyCreditPolicy] = useState(false)
    const [creditPolicieselected, setCreditPolicieselected] = useState<credit_policiesAttributes | null>(null)

    const searchCreditPolicies = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingCreditPolicies(true)
        try {
            const filters_obj = _filters ? { ..._filters, name: _filters.search } : { ...filters, name: searchString }
            filters_obj.type = filters_obj.type_consult

            const string = setUpConsultation(filters_obj)
            const url = `/credit_policies/search/?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_credit_policies = result.data.rows as credit_policiesAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_credit_policies.forEach((credit_policy) => {
                    new_table.data.push({
                        id: credit_policy.id,
                        name: credit_policy?.name || null,
                        type: get_corrrect_names(personsTypesOptions)[credit_policy?.type],
                        this: credit_policy
                    })
                })
                return { ...new_table }
            })

            setNumOfCreditPolicies(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelos tipos de documentos!", "error")
        }
        setLoadingCreditPolicies(false)
    }, [filters, searchString])

    const onSaveCreditPolicy = useCallback(() => {
        setOpenModal(false)
        searchCreditPolicies(1)
    }, [filters, searchString])

    const selectCreditPolicy = useCallback((CreditPolicy: credit_policiesAttributes) => {
        setCreditPolicieselected({ ...CreditPolicy })
        setOpenModal(true)
    }, [])

    const openModalNewCreditPolicy = useCallback(() => {
        setCreditPolicieselected(null)
        setOpenModal(true)
    }, [])

    const onClickDestroyCreditPolicy = useCallback(async (creditPolicy) => {
        setCreditPolicieselected({ ...creditPolicy })
        setOpenModalDestroyCreditPolicy(true)
    }, [])

    const confirmDestroyCreditPolicy = useCallback(async () => {
        setOpenModalDestroyCreditPolicy(false)
        setLoadingDestroyCreditPolicy(true)
        try {
            await api.delete(`/credit_policies/${creditPolicieselected?.id}`)
            notify("Política de análise excluída com sucesso!", "success")
            searchCreditPolicies(1)
        } catch (err) {
            notify("Erro ao excluir a política de análise!", "error")
        }
        setLoadingDestroyCreditPolicy(false)
    }, [creditPolicieselected, paginationLimit, filters, searchString])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewCreditPolicy()
        else console.log({ action_id })
    }

    const changeDefaultPolicy = useCallback(async (credit_policy_id: string, old_status: boolean) => {
        const new_status = !old_status
        setTable((atual) => {
            const new_table = { ...atual }
            const pos = new_table.data.findIndex((manager: any) => manager?.this?.id === credit_policy_id)
            if (pos !== -1) {
                const type = new_table.data[pos].this.type
                for (let i = 0; i < new_table.data.length; i++) {
                    if (new_table.data[i].this.type === type) {
                        new_table.data[i].this.is_default = false
                    }
                }
                new_table.data[pos].this.is_default = new_status
            }
            return { ...new_table }
        })

        try {
            await api.put(`/credit_policies/change-id-default/${credit_policy_id}`, { is_default: new_status })
            notify("Política padrão atualizada com sucesso.", "success")
        } catch (err) {
            notify("Erro ao alterar política padrão.", "error")
            setTable((atual) => {
                const new_table = { ...atual }
                const pos = new_table.data.findIndex((manager: any) => manager?.this?.id === credit_policy_id)
                if (pos !== -1) {
                    const type = new_table.data[pos].this.type
                    for (let i = 0; i < new_table.data.length; i++) {
                        if (new_table.data[i].this.type === type) {
                            new_table.data[i].this.is_default = false
                        }
                    }
                    new_table.data[pos].this.is_default = old_status
                }
                return { ...new_table }
            })
        }

    }, [])

    useEffect(() => { searchCreditPolicies(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingCreditPolicies}
            setLoading={setLoadingCreditPolicies}
            numberOfItems={numOfCreditPolicies}
            textHeader="Políticas de Análise"
            textItems="políticas de análise encontradas"
            theme={theme}
            search={searchCreditPolicies}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_edit
            have_delete
            have_default
            change_default_action={changeDefaultPolicy}
            edit_action={(credi_policy: any) => selectCreditPolicy(credi_policy)}
            delete_action={(credi_policy: any) => onClickDestroyCreditPolicy(credi_policy)}
            text_loading={"Buscando políticas de análise"}
            text_empty={"Nenhuma política de análise encontrada"}
            pages={numberOfPages}
            page={atualPage}
            onClickOption={handleAction}
            actions={actionsInitial}
            onChangeSearch={setSearchString}
        >
            {openModal && (
                <ModalCreditPolicy
                    openModal
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSaveCreditPolicy()}
                    defaultId={creditPolicieselected?.id}
                />
            )}

            <ModalAttention
                theme={theme}
                open={openModalDestroyCreditPolicy}
                cancelAction={() => setOpenModalDestroyCreditPolicy(false)}
                confirmAction={confirmDestroyCreditPolicy}
                content={(
                    <>
                        Você deseja excluir a política de análise <b>{creditPolicieselected?.name}</b> ?
                    </>
                )}
            />

            <ModalLoading loading={loadingDestroyCreditPolicy} theme={theme} />

        </PageList>
    )
}
