import styled, { css } from "styled-components";
import { themeTypes } from "../../interfaces";
import { secondary, tabInativeColor } from "../../theme";

interface ITabProps {
    canClick: boolean
    active: boolean
    theme: themeTypes
}

interface ITextProps {
    theme: themeTypes
    active: boolean
    canClick: boolean
}

export const Tab = styled.div<ITabProps>`
    border-radius: 5px;
    padding: 8px;
    ${props => css`
        cursor: ${props.canClick ? "pointer" : "not-allowed"};
        background: ${props.active ? secondary[props.theme as themeTypes] : undefined};
        border: 1px solid ${props.active ? secondary[props.theme as themeTypes] : (props.canClick ? secondary[props.theme as themeTypes] : tabInativeColor[props.theme as themeTypes])};
    `}
`

export const Text = styled.h6<ITextProps>`
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
    white-space: nowrap;
    margin: 0px;
    ${props => css`
        color: ${props.active ? "#FFF" : (props.canClick ? secondary[props.theme as themeTypes] : tabInativeColor[props.theme as themeTypes])};
    `}
`

export const ContainerTabs = styled.div`
    display: flex;
    flex-direction: row;
    overflow: auto;
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 10px;
    gap: 10px;
`