import Switch from "rc-switch";
import Button from "../../components/Buttons/Button";
import React, { useCallback, useState, useEffect } from "react";
import { Line } from "../../style";
import { FiEdit } from "react-icons/fi";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { inactiveItens, secondary } from "../../theme";
import { copyOf } from "../../services/generalServices";
import { HeaderList } from "../../components/HeaderList";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { credit_policiesAttributes } from "../../interfaces";
import { DashboardPage } from "../../components/DashboardPage";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { ModalCreditPolicy } from "../../components/Modals/ModalCreditPolicy";
import { ModalTestCreditPolicy } from "../../components/Modals/ModalTestCreditPolicy";
import { Column, ContainerCreditPolicies, ContainerCreditPolicyLogo, CreditPolicyContainer, CreditPolicyDescription, SwitchContainer } from "./style";

export const FranchiseCreditPolicies: React.FC = () => {
    const { api } = useApi()
    const { notify, theme } = useGlobal()

    const [loading_search, set_loading_search] = useState<boolean>(true)
    const [loading_create_default, set_loading_create_default] = useState<boolean>(false)
    const [credit_policies, set_credit_policies] = useState<credit_policiesAttributes[]>([])
    const [credit_policy_edit, set_credit_policy_edit] = useState<credit_policiesAttributes | null>(null)
    const [credit_policy_test, set_credit_policy_test] = useState<credit_policiesAttributes | null>(null)

    const searchCreditPolicies = useCallback(async () => {
        set_loading_search(true)
        try {
            const { data } = await api.get("/credit_policies/search")
            data.rows = data.rows.map((credit_policy: credit_policiesAttributes) => {
                return {
                    ...credit_policy,
                    executors: JSON.parse(credit_policy.executors as any)
                }
            })
            set_credit_policies(data.rows)
        } catch (err) {
            notify("Erro ao buscar políticas de análise", "error")
        }
        set_loading_search(false)
    }, [])

    const handleCreateCreditPoliciesDefault = useCallback(async () => {
        set_loading_create_default(true)
        try {
            await api.post("/franchises/create-default-policies")
            notify("Políticas de análise criadas com sucesso", "success")
            searchCreditPolicies()
        } catch (err) {
            notify("Erro ao criar políticas de análise", "error")
        }
        set_loading_create_default(false)
    }, [])

    const handleEditCreditPolicy = useCallback(async (creditPolicy: credit_policiesAttributes) => {
        set_credit_policy_edit({ ...creditPolicy })
    }, [])

    const handleTestCreditPolicy = useCallback(async (creditPolicy: credit_policiesAttributes) => {
        set_credit_policy_test({ ...creditPolicy })
    }, [])

    const handleSaveCreditPolicy = useCallback(async () => {
        set_credit_policy_edit(null)
        searchCreditPolicies()
    }, [])

    const handleChangeActiveOfCreditPolicy = useCallback(async (creditPolicy: credit_policiesAttributes) => {
        const new_active = !creditPolicy.active
        set_loading_create_default(true)
        try {
            set_credit_policies((old_credit_policies) => {
                const new_credit_policies = copyOf(old_credit_policies) as credit_policiesAttributes[]
                const index = new_credit_policies.findIndex((old_credit_policy) => old_credit_policy.id === creditPolicy.id)
                new_credit_policies[index].active = new_active
                return [...new_credit_policies]
            })
            await api.put(`/credit_policies/change-active/${creditPolicy.id}`, { new_active })
            notify("Status da política de análise " + creditPolicy.type + " alterado com sucesso", "success")
        }
        catch (err) {
            set_credit_policies((old_credit_policies) => {
                const new_credit_policies = copyOf(old_credit_policies) as credit_policiesAttributes[]
                const index = new_credit_policies.findIndex((old_credit_policy) => old_credit_policy.id === creditPolicy.id)
                new_credit_policies[index].active = creditPolicy.active
                return [...new_credit_policies]
            })
            notify("Erro ao alterar status da política de análise " + creditPolicy.type, "error")
        }
        set_loading_create_default(false)
    }, [])

    useEffect(() => { searchCreditPolicies() }, [])

    return (
        <DashboardPage>
            <Column style={{ gap: 20 }}>
                <HeaderList
                    textHeader="Políticas de Análise"
                    textDescritpion="Essas são as políticas de análise automatizadas para aprovação/reprovação de seus clientes."
                    theme={theme}
                    haveBorder
                    onRefresh={searchCreditPolicies}
                    loading={loading_search}
                />
                <ContentOrLoading loading={loading_search} text="Buscando suas políticas de análise" theme={theme}>
                    <ContainerCreditPolicies>
                        {credit_policies.map((credit_policy) => (
                            <CreditPolicyContainer key={credit_policy.id} theme={theme}>
                                <Column style={{ gap: 5 }}>
                                    <SwitchContainer>
                                        <Switch
                                            id="tooltip_switch"
                                            className="custom-switch custom-switch-primary custom-switch-small"
                                            checked={credit_policy.active}
                                            onChange={() => handleChangeActiveOfCreditPolicy(credit_policy)}
                                        />
                                    </SwitchContainer>
                                    <ContainerCreditPolicyLogo>
                                        {credit_policy.name}
                                    </ContainerCreditPolicyLogo>
                                </Column>
                                <CreditPolicyDescription>
                                    {credit_policy.description}
                                </CreditPolicyDescription>
                                <div style={{ padding: 10, fontWeight: 600, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 5, border: `1px solid ${inactiveItens[theme]}` }}>
                                    {credit_policy.executors.length} {credit_policy.executors.length === 1 ? "executor" : "executores"}
                                </div>
                                <Button onClick={() => handleEditCreditPolicy(credit_policy)} style={{ textTransform: "uppercase", gap: 10, alignItems: "center", padding: 10 }} background={secondary[theme]} color="#FFF">
                                    <FiEdit size={20} color="#FFF" />
                                    Editar
                                </Button>
                                <Button onClick={() => handleTestCreditPolicy(credit_policy)} style={{ textTransform: "uppercase", gap: 10, alignItems: "center", padding: 10 }} background={secondary[theme]} color="#FFF">
                                    <BsFillLightningChargeFill size={20} color="#FFF" />
                                    Testar
                                </Button>
                            </CreditPolicyContainer>
                        ))}
                    </ContainerCreditPolicies>
                    {credit_policies.length === 0 && (
                        <Line style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <div>
                                <Button onClick={handleCreateCreditPoliciesDefault} style={{ textTransform: "uppercase", gap: 10, alignItems: "center", padding: 10 }} background={secondary[theme]} color="#FFF">
                                    Criar políticas de análise para sua imobiliária
                                </Button>
                            </div>
                        </Line>
                    )}
                    {loading_create_default && (
                        <ModalLoading
                            loading={loading_create_default}
                            theme={theme}
                        />
                    )}
                </ContentOrLoading>
                {credit_policy_edit?.id && (
                    <ModalCreditPolicy
                        openModal
                        onCancel={() => set_credit_policy_edit(null)}
                        onSave={handleSaveCreditPolicy}
                        defaultId={credit_policy_edit?.id}
                    />
                )}
                {credit_policy_test?.id && (
                    <ModalTestCreditPolicy
                        executors={credit_policy_test.executors as any}
                        openModal
                        onCancel={() => set_credit_policy_test(null)}
                        type={credit_policy_test.type}
                    />
                )}
            </Column>
        </DashboardPage>
    )
}