import Button from "../../../Buttons/Button";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { Bag } from "../../../Bags/Bag";
import { useForm } from "react-hook-form";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { FiHome, FiSend } from "react-icons/fi";
import { Column, Line } from "../../../../style";
import { useGlobal } from "../../../../hooks/global";
import { IGroupProps } from "../../../../interfaces";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { Form, BagContent, TH, THead, Table, TD } from './style';
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { setUpConsultation } from "../../../../services/generalServices";
import { documentEletronicBondsOptions } from "../../../../utils/options";
import { variables_groups } from "../../../../pages/ContractModels/ModalVariables";


export const FormVariablesTest: React.FC = () => {

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        mode: "all"
    });

    const _form = watch()

    const { api, consultsServices } = useApi()
    const { theme, notify } = useGlobal()

    const [loading_create, setLoadingCreate] = useState(false)
    const [variables, setVariables] = useState<{ [key: string]: any } | null>(null)

    const form_ref = useRef<IGenericFormRefProps>(null)

    const onSubmit = useCallback(async () => {

        setLoadingCreate(true)

        try {
            const form = form_ref.current?.getForm()
            const proposal_id = form?.proposal_id
            const property_id = form?.property_id
            const query = setUpConsultation({ proposal_id, property_id })

            const result = await api.get('/contracts/template?' + query)
            setVariables(result?.data?.data ?? null)

        } catch (err: any) {

            notify("Erro ao buscar variáveis", "error")

        }

        setLoadingCreate(false)

    }, [form_ref, _form, api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const handleChangePropertyOrProposal = useCallback(async (data: any) => {
        setVariables(null)
    }, [])

    const handleChangeBondType = useCallback(async (data: any) => {
        setValue("proposal_id", null)
        setValue("property_id", null)
        setVariables(null)
    }, [])

    const getValueOfVariable = useCallback((variable: string) => {
        const value = variables?.[variable.replaceAll('{', '').replaceAll('}', '')]
        return value
    }, [variables])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "bond_type",
                        label: "Vínculo",
                        type: "select-fixed",
                        isClearable: true,
                        required: true,
                        options: documentEletronicBondsOptions.filter((item) => item.value !== "free"),
                        executeOnChange: handleChangeBondType,
                    },
                    {
                        name: "proposal_id",
                        label: "Proposta",
                        type: "select-single-cards-no-creatable",
                        isClearable: true,
                        required: true,
                        searchOptions: consultsServices.proposal_id,
                        moreQueries: { status_array: ["PA", "CF"] },
                        icon: <FiSend />,
                        canSee: (data: any) => data?.bond_type?.value === "proposal",
                        executeOnChange: async (data: any) => handleChangePropertyOrProposal({ proposal_id: data?.value }),
                    },
                    {
                        name: "property_id",
                        label: "Imóvel",
                        type: "select-single-cards-no-creatable",
                        isClearable: true,
                        required: true,
                        searchOptions: consultsServices.property_id,
                        icon: <FiHome />,
                        canSee: (data: any) => data?.bond_type?.value === "property",
                        executeOnChange: async (data: any) => handleChangePropertyOrProposal({ property_id: data?.value }),
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <Form onSubmit={handleStopPropagation}>
                <GenericForm
                    ref={form_ref}
                    groups={groups}
                    _form={_form}
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    setValue={setValue}
                    register={register}
                />
                <Line style={{ justifyContent: "flex-end" }}>
                    <Line>
                        <Button
                            type="submit"
                            background={secondary[theme]}
                            color={"#FFF"}
                            style={{ padding: 8, fontSize: 14 }}
                            children="Visualizar Variáveis"
                        />
                    </Line>
                </Line>
                {variables && (
                    <Column style={{ gap: 16 }}>
                        {variables_groups.map((group, index) => (
                            <Bag key={group.name} defaultOpen={false} icon={group.icon} description={group.description} title={group.name}>
                                <BagContent theme={theme}>
                                    <Table>
                                        <THead theme={theme}>
                                            <tr>
                                                <TH>Descrição</TH>
                                                <TH>Nome</TH>
                                                <TH>Valor</TH>
                                            </tr>
                                        </THead>
                                        <tbody>
                                            {group.variables.map((variable) => (
                                                <tr key={variable.name}>
                                                    <TD>
                                                        {variable.description}
                                                    </TD>
                                                    <TD>
                                                        {variable.name}
                                                    </TD>
                                                    <TD style={{
                                                        fontStyle: ['', null, undefined].includes(getValueOfVariable(variable.name)) ? 'italic' : 'normal',
                                                        fontWeight: ['', null, undefined].includes(getValueOfVariable(variable.name)) ? 'bold' : 'normal',
                                                    }}>
                                                        {['', null, undefined].includes(getValueOfVariable(variable.name)) ? '{Sem valor}' : getValueOfVariable(variable.name)}
                                                    </TD>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </BagContent>
                            </Bag>
                        ))}
                    </Column>
                )}
            </Form>
            <ModalLoading
                loading={loading_create}
                theme={theme}
            />
        </>
    )
}