import styled, { css } from "styled-components";
import { ITDProps, themeTypes } from "../../interfaces";
import { activeItens, primary, separator } from "../../theme";

interface SwitchContainerProps {
    theme: themeTypes
}

export const Table = styled.table`
    border-spacing: 0 0.6rem;
    border-collapse: initial;
    font-size: 13.6px;
    padding-bottom:10px;
    width: 100%;
`

export const TH = styled.th`
    padding: 10px;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.7;
    white-space: nowrap;
    cursor: default !important;
`

export const TD = styled.td<ITDProps>`
    padding: 10px;
    font-size: 16px;
    white-space:nowrap;
    cursor: ${props => props.noHaveClick ? "default" : "pointer"};
    ${props => props.isLeft && css`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    `}
    ${props => props.isRight && css`
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    `}
    ${props => props.noData && css`
        font-weight: normal !important;
        font-style: italic;
        opacity: 0.7;
        font-size: 14px;
    `}
`

export const SwitchContainer = styled.div<SwitchContainerProps>`
    padding: 10px;
    display: flex;
    justify-content:center;
    @media(max-width: 575px){
        display:none;
    }
    ${props => css`
        .custom-switch {
            display: block !important;
            background: ${props.theme === "light" ? separator["dark"] : "#6f6e6e"} !important;
            width: 58px !important;
            height: 27px !important;
            border:none;
            :after {
                width: 18px !important;
                height: 18px !important;
                top: 4px !important;
                margin-left: 3px !important;
                box-shadow: initial;
                transform: initial !important;
                animation-name: unset !important;
                margin-top:1px;
                background: ${primary["light"]} !important;
            }
            &.rc-switch:after {
                box-shadow: none !important;
            }
        
            &.rc-switch-checked:after {
                left: 30px !important;
                box-shadow: none !important;
            }
        
            &.rc-switch-checked.custom-switch-primary {
                background: ${activeItens[props.theme as themeTypes]} !important;
                border: 1px solid $theme-color-1 !important;
            }
        
            &.rc-switch-checked.custom-switch-secondary {
                background: $theme-color-2 !important;
                border: 1px solid $theme-color-2 !important;
            }
        
            &.custom-switch-primary-inverse {
                border: 1px solid $separator-color !important;
            }
        
            &.rc-switch-checked.custom-switch-primary-inverse {
                background: $input-background !important;
                border: 1px solid $theme-color-1 !important;
                &:after {
                    background: $theme-color-1 !important;
                }
            }
        
            &.custom-switch-secondary-inverse {
                border: 1px solid $separator-color !important;
            }
        
            &.rc-switch-checked.custom-switch-secondary-inverse {
                background: $input-background !important;
                border: 1px solid $theme-color-2 !important;
                &:after {
                    background: $theme-color-2 !important;
                }
            }
        }
  
        .rc-switch:focus {
            box-shadow: initial !important;
        }
        
        .rc-switch.custom-switch.custom-switch-small {
            width: 34px !important;
            height: 19px !important;
        }
        
        .rc-switch.custom-switch.custom-switch-small:after {
            width: 12px !important;
            height: 12px !important;
            left: 0px !important;
            top: 2px !important;
        }
        
        .rc-switch-checked.custom-switch.custom-switch-small:after {
            left: 14px !important;
        }
  `}
  
`

export const NoData = styled.h6`
    font-style: italic;
    justify-content: center;
    align-items: center;
    flex: 1;
    display: flex;
    margin: 16px;
    margin-bottom: 0px;
`