import styled, { css } from "styled-components";
import { themeTypes } from "../interfaces";
import { borderDisabled, disabledItems, inactiveItens, primary } from "../theme";

export const Container = styled.div<{ theme: themeTypes }>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => css`
        .form-control{
            background-color: ${primary[props.theme as themeTypes]};
            color: ${props.theme === "light" ? "#000" : inactiveItens.dark};
            border-color: ${props.theme === "light" ? "#CCCCCC" : "rgb(68 68 68)"};
            :disabled{
                background-color: ${disabledItems[props.theme as themeTypes]};
                color: #999999;
                border-color: ${borderDisabled[props.theme as themeTypes]};
            }
        }
    `}
`

export const NotifyContainer = styled.span`
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
`

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const MessageContainer = styled.div`
    cursor: default;
    text-align: center;
`