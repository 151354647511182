import styled from "styled-components";

export const ContainerRankingPersons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    @media (max-width: 800px){
        flex-direction: column;
    }
`