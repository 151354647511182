import styled, { css } from "styled-components";
import { primary } from "../../theme";
import { IThemeProps, themeTypes } from "../../interfaces";

export const FormContainer = styled.div<IThemeProps>`
    display: flex;
    flex: 1;
    justify-content:space-between;
    flex-direction: column;
    border-radius: 4px;
    padding: 30px;
    box-shadow: 0.125rem 0.125rem 0.5rem #57545419;
    ${props => css`
        background-color: ${primary[props.theme as themeTypes]};
    `}
`

export const Form = styled.form`
    display: flex;
    margin-top: -20px;
    gap: 30px;
    flex-direction: column;
    flex: 1;
    justify-content: space-between
`