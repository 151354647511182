import { FormEvent, forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { Form } from "./style";
import { useForm } from "react-hook-form";
import { Column } from "../../../../../../style";
import { useApi } from "../../../../../../hooks/api";
import { IGroupProps } from "../../../../../../interfaces";
import { useGlobal } from "../../../../../../hooks/global";
import { ModalLoading } from "../../../../../Loadings/ModalLoading";
import { validateDate } from "../../../../../../utils/validatesFields";
import { generateDate } from "../../../../../../services/generalServices";
import { GenericForm, IGenericFormRefProps } from "../../../../../GenericForm";
import { correctDate2, valueIsEmpty } from "../../../../../../services/generalServices";
import { isManagerOptions, readjustmentIndicesOptions } from "../../../../../../utils/options";

interface IContractorDataFormProps {
    defaultData: any
    isDisabled: boolean
    proposalId: string
    onSuccess: (data: any) => void
}

export interface IContractorDataFormRefProps {
    forceSubmit: () => any
}

const calcEndDate = async ({ date_init, duration, type, date_end }: any) => {
    if (!valueIsEmpty(date_init) && !valueIsEmpty(duration)) {
        const validateStartDate = await validateDate(date_init)
        if (typeof validateStartDate === "string") return date_end

        const startDate = generateDate(date_init) as Date;
        const year = startDate.getFullYear()
        let month = startDate.getMonth()
        let day = startDate.getDate()

        let newDate: any = ""

        if (type === "month") month += Number.parseInt(duration)
        else day += Number.parseInt(duration)

        newDate = new Date(year, month, day);

        if (type === "month" && newDate.getDate() !== day) newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0);

        return correctDate2(newDate)
    }
}

const ContractorDataFormComponent: React.ForwardRefRenderFunction<IContractorDataFormRefProps, IContractorDataFormProps> = (props, ref) => {
    const { defaultData, isDisabled, proposalId, onSuccess } = props

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(false)

    const formRef = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const onSubmit = useCallback(async () => {

        setLoading(true)

        const contractorData = formRef.current?.getForm()

        try {

            await api.put(`/proposals/contractor-data/${proposalId}`, { contractorData })

            notify("Proposta atualizada com sucesso.", "success")

            onSuccess(contractorData)

        } catch (err: any) {

            notify("Erro ao atualizar proposta.", "error")

        }

        setLoading(false)

    }, [formRef, _form, api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, formRef, _form, api])

    const forceSubmit = useCallback(() => buttonSubmitRef.current?.click(), [buttonSubmitRef])

    useImperativeHandle(ref, () => ({ forceSubmit }))

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "date_init",
                        label: "Início",
                        type: "input",
                        mask: "date",
                        validate: validateDate,
                        required: true,
                        executeOnChange: async (date_init) => {
                            const form = watch()
                            const duration = form?.duration?.value ?? 0
                            const type = form?.duration?.type ?? "month"
                            let date_end = form?.date_end

                            date_end = await calcEndDate({ date_init, duration, type, date_end })

                            if (date_end) setValue("date_end", date_end)
                        }
                    },
                    {
                        name: "duration",
                        label: "Duração",
                        type: "input-day-or-month",
                        tooltip: "Este campo serve apenas para calcular a data de fim do contrato, portanto a duração do contrato levará em consideração apenas a data inicial e final",
                        executeOnChange: async (data) => {
                            const form = watch()
                            const date_init = form?.date_init
                            const duration = data?.value
                            const type = data?.type ?? "month"
                            let date_end = form?.date_end

                            date_end = await calcEndDate({ date_init, duration, type, date_end })

                            if (date_end) setValue("date_end", date_end)
                        }
                    },
                    {
                        name: "date_end",
                        label: "Fim",
                        type: "input",
                        mask: "date",
                        validate: validateDate,
                        required: true
                    }
                ],
                [
                    {
                        name: "expiration_day",
                        label: "Dia de vencimento",
                        type: "input",
                        mask: "onlyNumber",
                        required: true
                    },
                    {
                        name: "readjustment_index",
                        label: "Índice de reajuste",
                        type: "select-fixed",
                        options: readjustmentIndicesOptions,
                        required: true,
                        isClearable: true
                    }
                ],
                [
                    {
                        name: "occupation",
                        label: "Ocupação",
                        type: "input",
                        mask: "date",
                        validate: validateDate,
                        tooltip: "Informe a data que o locatário vai ocupar o imóvel, caso ela seja diferente da data de início"
                    }
                ],
                [
                    {
                        name: "rent_value",
                        label: "Valor do aluguel",
                        type: "input",
                        mask: "currency",
                        required: true
                    },
                    {
                        name: "condo_value",
                        label: "Valor do condomínio",
                        type: "input",
                        mask: "currency"
                    },
                    {
                        name: "iptu_value",
                        label: "Valor do IPTU",
                        type: "input",
                        mask: "currency"
                    }
                ],
                [
                    {
                        name: "is_managed",
                        label: "Administrado?",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: isManagerOptions
                    },
                    {
                        name: "managed",
                        label: "Taxa de Administração",
                        type: "input-porcen-or-money",
                        canSee: (data: any) => data?.is_managed?.value
                    },
                    {
                        name: "rental_fee",
                        label: "Taxa de Locação",
                        type: "input-porcen-or-money"
                    }
                ],
                [
                    {
                        name: "observation",
                        label: "Observações",
                        type: "textarea"
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <Form theme={theme} onSubmit={handleStopPropagation}>
                <Column style={{ gap: 20 }}>
                    <GenericForm
                        ref={formRef}
                        groups={groups}
                        _form={_form}
                        control={control}
                        errors={errors}
                        trigger={trigger}
                        setValue={setValue}
                        register={register}
                        disabled={isDisabled}
                    />
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Column>
            </Form>

            <ModalLoading theme={theme} loading={loading} />
        </>
    )
}

export const ContractorDataForm = forwardRef(ContractorDataFormComponent)