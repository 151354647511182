import styled from "styled-components";
import { Column, Line } from "../../style";

export const GroupTitle = styled.div`
    align-items: center;
    margin-top: 20px;
    margin-bottom: -10px;
    font-weight: 600;
`

export const FieldsLine = styled(Line)`
    gap: 20px;
    flex-wrap: wrap;
`

export const ContainerGroup = styled(Column)`
    gap: 20px;
`

export const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`