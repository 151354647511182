import Button from "../../../../Buttons/Button";
import React, { useCallback, useEffect, useState } from "react";
import { TiCancel } from "react-icons/ti";
import { FiDownload } from "react-icons/fi";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { ModalContract } from "../../../ModalContract";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IconButton, Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../../hooks/global";
import { ModalAttention } from "../../../ModalAttention";
import { getStatus } from "../../../../../utils/columnsTables";
import { ModalLoading } from "../../../../Loadings/ModalLoading";
import { useProposalChat } from "../../../../../hooks/proposals";
import { contract_status } from "../../../../../utils/contract_status";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { background, borderColors, secondary } from "../../../../../theme";
import { copyOf, correctDate } from "../../../../../services/generalServices";
import { contractsAttributes, proposalsAttributes } from "../../../../../interfaces";

export interface IStepRef {
    validate: () => boolean
}

interface IContractStep {
    defaultId: string
    loading: boolean
    control: number
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
}

const ContractComponent: React.ForwardRefRenderFunction<IStepRef, IContractStep> = (props, ref) => {
    const { control, defaultId, loading, setLoading } = props

    const { api } = useApi()
    const { socketContracts } = useProposalChat()
    const { theme, notify, franchise } = useGlobal()

    const [loadingCancel, setLoadingCancel] = useState(false)
    const [modalNewContract, setModalNewContract] = useState(false)
    const [openConfirmCancel, setOpenConfirmCancel] = useState(false)
    const [contracts, setContracts] = useState<contractsAttributes[]>([])
    const [proposal, setProposal] = useState<proposalsAttributes | null>(null)
    const [contractSelected, setContractSelected] = useState<contractsAttributes | null>(null)

    const searchBackground = useCallback(async () => {
        try {
            socketContracts?.removeAllListeners()
            const [result_proposal, result_contracts] = await Promise.all([
                api.get("/proposals/" + defaultId),
                api.get("/proposals/contracts/" + defaultId)
            ])
            setProposal({ ...result_proposal.data })
            setContracts([...result_contracts.data])
            socketContracts?.on(`${defaultId}-contracts`, () => search())

        } catch (err) {
            notify("Erro ao buscar documentos eletrônicos da proposta", "error")
        }
    }, [franchise, socketContracts])

    const search = useCallback(async () => {
        setLoading(true)
        try {
            socketContracts?.removeAllListeners()
            const [result_proposal, result_contracts] = await Promise.all([api.get("/proposals/" + defaultId), api.get("/proposals/contracts/" + defaultId)])
            setProposal({ ...result_proposal.data })
            setContracts([...result_contracts.data])
            socketContracts?.on(`${defaultId}-contracts`, () => search())

        } catch (err) {
            notify("Erro ao buscar documentos eletrônicos da proposta", "error")
        }
        setLoading(false)
    }, [franchise, socketContracts])

    const onSaveContract = useCallback(() => {
        setModalNewContract(false)
        search()
    }, [franchise])

    const handleCancel = useCallback(() => {
        setOpenConfirmCancel(false)
        setContractSelected(null)
    }, [])

    const confirmCancelContract = useCallback(async () => {
        setOpenConfirmCancel(false)
        setLoadingCancel(true)
        try {
            await api.put(`/contracts/cancel/${contractSelected?.id}`)
            notify("Documento eletrônico cancelado com sucesso!", "success")
            setContractSelected(null)
            search()
        } catch (err) {
            setContractSelected(null)
            notify("Erro ao cancelar documento eletrônico!", "error")
        }
        setLoadingCancel(false)
    }, [contractSelected, franchise])

    const onClickCancelContract = useCallback(async (contract) => {
        setContractSelected({ ...contract })
        setOpenConfirmCancel(true)
    }, [])

    const openModalContract = useCallback(async (contract) => {
        setContractSelected({ ...contract })
        setModalNewContract(true)
    }, [])

    const onCancelModal = useCallback(() => {
        setContractSelected(null)
        setModalNewContract(false)
    }, [])

    const handleDownload = useCallback((id: string) => {
        const anchor = document.getElementById(id)
        if (anchor) anchor.click()
    }, [])

    useEffect(() => { search() }, [])

    useEffect(() => { searchBackground() }, [control])

    return (
        <>
            <ContentOrLoading loading={loading} theme={theme} type="wave">
                <>
                    <Line style={{ justifyContent: "space-between", flexWrap: "wrap", gap: 20 }}>
                        <Column>
                            <div style={{ fontWeight: 600, color: secondary[theme], fontSize: 20 }}>Documentos Eletrônicos</div>
                            <div>Aqui você gerencia os documentos eletrônicos da proposta e seus signatários.</div>
                        </Column>
                        <div>
                            <Button onClick={() => setModalNewContract(true)} background={secondary[theme]} color="#FFF">Novo Documento Eletrônico</Button>
                        </div>
                    </Line>

                    <Column style={{ flex: 1, marginTop: 20 }}>
                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                            <table style={{ width: "100%", textTransform: "uppercase" }}>
                                <thead style={{ background: background[theme] }}>
                                    <tr>
                                        <th style={{ padding: 10 }}>NOME</th>
                                        <th style={{ padding: 10 }}>SIGNATÁRIO(S)</th>
                                        <th style={{ padding: 10, textAlign: "center" }}>AUTOR</th>
                                        <th style={{ padding: 10, textAlign: "center" }}>STATUS</th>
                                        <th style={{ padding: 10, textAlign: "center" }}>CRIAÇÃO</th>
                                        <th style={{ padding: 10, textAlign: "center" }}>EXPIRAÇÃO</th>
                                        <th style={{ padding: 10, textAlign: "center" }}>AÇÕES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contracts.map((contract) => (
                                        <tr>
                                            <td style={{ padding: 10 }}>{contract.name}</td>
                                            <td style={{ padding: 10 }}>
                                                <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                                                    {contract.signatories.map((signatory) => {
                                                        return (
                                                            <div style={{ display: "flex" }}>
                                                                <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], fontWeight: 600, color: "#FFF", fontSize: 12 }}>
                                                                    {signatory.name}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    {contract.signatories.length === 0 &&
                                                        <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a) signatário(a)</span>
                                                    }
                                                </div>
                                            </td>
                                            <td style={{ padding: 10, textAlign: "center" }}>
                                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 5 }}>
                                                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>
                                                        {contract.person.registration_data?.name}
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ padding: 10, textAlign: "center" }}>
                                                {getStatus({ ...contract_status[contract.status] })}
                                            </td>
                                            <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                {correctDate(contract.createdAt)}
                                            </td>
                                            <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                {correctDate(contract.expiries_in)}
                                            </td>
                                            <td style={{ padding: 10, textAlign: "center", whiteSpace: "nowrap" }}>
                                                <Tooltip onClick={() => openModalContract(contract)} title="Visualizar" style={{ margin: 0, padding: 10 }}>
                                                    <IconButton aria-label="Visualizar">
                                                        <MdOutlineRemoveRedEye
                                                            color={secondary[theme]}
                                                            size={20}
                                                        />
                                                    </IconButton>
                                                </Tooltip>

                                                {["pending"].includes(contract.status) &&
                                                    <Tooltip onClick={() => onClickCancelContract(contract)} style={{ margin: 0, padding: 10 }} title="Cancelar">
                                                        <IconButton aria-label="Cancelar">
                                                            <TiCancel
                                                                color={secondary[theme]}
                                                                size={20}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                }

                                                {["signed", "pending"].includes(contract.status) && (contract.file_signed_url || contract.file_original_url) && (
                                                    <Tooltip onClick={() => handleDownload(contract.id)} style={{ margin: 0, padding: 10 }} title={contract.file_signed_url ? "Documento eletrônico Assinado" : "Documento eletrônico Original"}>
                                                        <IconButton aria-label={contract.file_signed_url ? "Documento eletrônico Assinado" : "Documento eletrônico Original"}>
                                                            <FiDownload
                                                                color={secondary[theme]}
                                                                size={20}
                                                            />
                                                            <a
                                                                id={contract.id}
                                                                href={contract?.file_signed_url ? contract?.file_signed_url : contract?.file_original_url}
                                                                target="_blank" style={{ display: "none" }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                            </td>
                                        </tr>
                                    ))}
                                    {contracts.length === 0 &&
                                        <tr>
                                            <td colSpan={7} style={{ padding: 10, textAlign: "center", fontWeight: 600, color: "rgb(127 127 127)", fontStyle: "italic" }}>
                                                Proposta sem documentos eletrônicos
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Column>
                    </Column>
                </>
            </ContentOrLoading>

            {modalNewContract &&
                <ModalContract
                    onCancel={onCancelModal}
                    onSave={onSaveContract}
                    openModal={modalNewContract}
                    backgroundVisible={false}
                    defaultContract={copyOf(contractSelected)}
                    proposal={proposal}
                />
            }

            <ModalAttention
                theme={theme}
                open={openConfirmCancel}
                cancelAction={handleCancel}
                confirmAction={confirmCancelContract}
                cancelText="Voltar"
                content={(
                    <>
                        Você deseja cancelar o documento eletrônico <b>{contractSelected?.name?.toUpperCase()}</b> ?
                    </>
                )}
            />

            <ModalLoading
                loading={loadingCancel}
                theme={theme}
            />
        </>
    )
}

export const ContractStep = React.forwardRef(ContractComponent)
