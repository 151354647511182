import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../../interfaces";
import { borderColors, inactiveItens, primary } from "../../../../theme";

export const StatusSubscription = styled.div<{ background: string }>`
    display: flex;
    color: #FFF;
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 4px;
    ${props => css`
        background:${props.background};
    `}
`

export const PaymentLink = styled.a<IThemeProps>`
    display: flex;
    text-decoration: none;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
        border: 1px solid ${borderColors[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]} !important;
    `}
`

export const ContainerQrCode = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 531px) {
        flex-direction: column;
        align-items: center;
    }
`

export const ImageQrCode = styled.img`
    object-fit: cover;
    height: 180px;
    width: 180px;
`

export const InputCopy = styled.input<IThemeProps>`
    background: transparent;
    resize: none;
    border: none;
    width: 100%;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`