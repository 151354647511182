import Logo from "./../../assets/Logo.png";
import Button from "../../components/Buttons/Button";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { useForm } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { useGlobal } from "../../hooks/global";
import { IGroupProps } from "../../interfaces";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { GenericForm, IGenericFormRefProps } from "../../components/GenericForm";
import { validateCEP, validateCNPJ, validateCPF, validateEmail, validatePhone } from "../../utils/validatesFields";
import { Container, ContainerBottomButtons, ContainerImage, ContainerLink, ContainerTop, Description, Form, Link, Title, LogoImg, Content, ContainerTerms, AnchorTerms, Span } from "./style";

const terms_url = "https://max-locacao-v2.s3.sa-east-1.amazonaws.com/TERMOS+DE+USO%2C+DE+POL%C3%8DTICA+DE+PRIVACIDADE+E+DE+TRATAMENTO+DE+DADOS+PESSOAIS+-+MAX+LOCA%C3%87%C3%83O+(1).pdf"

interface INewAccountProps { }

export const NewAccount: React.FC<INewAccountProps> = () => {
    const [loading, setLoading] = useState(false)
    const [loadingCEP, setLoadingCEP] = useState(false)

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        mode: "all"
    });

    const _form = watch()

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const { notify } = useGlobal()
    const { api, signIn, consultsServices } = useApi()

    const onSubmit = useCallback(async (event) => {

        setLoading(true)

        const form = form_ref.current?.getForm()

        const { corporate_name, fantasy_name, cnpj, cep, state_id, city_id, name, cpf, phone, email, password, confirm_password } = form

        const address = { cep, state_id, city_id }

        const franchise = {
            registration_data: { corporate_name, fantasy_name, cnpj, phone },
            address
        }

        const responsible = {
            registration_data: { name, cpf, phone },
            address,
            new_account: { email, password, confirm_password }
        }

        const data = { franchise, responsible }

        try {

            await api.post("/create-new-account", data)

            await signIn({ email, password })

        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoading(false)

    }, [form_ref, api, _form])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const changeCEP = useCallback(async (search) => {
        search = search.replace(/\D/g, "")
        if (search.length === 8) {
            setLoadingCEP(true)

            const { city, state } = await consultsServices.address({ search })

            if (city) setValue("city_id", { label: city.name, value: city.id, this: city })

            if (state) setValue("state_id", { label: state.name, value: state.id, initials: state.initials, this: state })

            setLoadingCEP(false)

            trigger()
        }
    }, [])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "corporate_name",
                        label: "Razão Social",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "fantasy_name",
                        label: "Nome Fantasia",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "cnpj",
                        label: "CNPJ",
                        type: "input",
                        mask: "cnpj",
                        validate: validateCNPJ,
                        required: true
                    },
                    {
                        name: "cep",
                        label: "CEP",
                        type: "input",
                        mask: "cep",
                        validate: validateCEP,
                        executeOnChange: changeCEP,
                        required: true
                    }
                ],
                [
                    {
                        name: "state_id",
                        label: "Estado",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        searchOptions: consultsServices.state_id,
                        executeOnChange: async () => setValue("city_id", null),
                        required: true
                    },
                    {
                        name: "city_id",
                        label: "Cidade",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        searchOptions: consultsServices.city_id,
                        additionalsQueries: (data: any) => ({ state_id: data?.state_id?.value }),
                        getIsDisabled: (data: any) => !data?.state_id,
                        required: true
                    }
                ],
                [
                    {
                        name: "name",
                        label: "Responsável legal",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "cpf",
                        label: "CPF",
                        type: "input",
                        mask: "cpf",
                        validate: validateCPF,
                        required: true
                    },
                    {
                        name: "phone",
                        label: "Telefone",
                        type: "input",
                        mask: "phone",
                        validate: validatePhone,
                        required: true
                    }
                ],
                [
                    {
                        name: "email",
                        label: "Email",
                        type: "input",
                        validate: validateEmail,
                        required: true
                    }
                ],
                [
                    {
                        name: "password",
                        label: "Senha",
                        type: "input",
                        required: true,
                        isPassword: true,
                        noUpperCase: true
                    },
                    {
                        name: "confirm-password",
                        label: "Senha",
                        type: "input",
                        required: true,
                        isPassword: true,
                        validate: async (value: any) => {
                            const password = watch("password")
                            if (value !== password) return "As senhas não conferem."
                            return true
                        },
                        noUpperCase: true
                    }
                ]
            ]
        }
    ]

    return (
        <Container>

            <ContainerImage />

            <Content>

                <ContainerTop>

                    <ContainerLink>
                        <Link
                            to="/login"
                            children={(
                                <>
                                    <BsArrowLeft />
                                    Voltar para o login
                                </>
                            )}
                        />
                    </ContainerLink>

                    <LogoImg src={Logo} height={70} />

                    <Title>
                        Preencha o formulário e
                        experimente <Span>gratuitamente</Span>.
                    </Title>

                    <Description>
                        Preencha o formulário e faça um teste grátis de 7 dias.
                        Descubra como a nossa plataforma pode ajudar a sua imobiliária!
                    </Description>

                </ContainerTop>

                <Form onSubmit={handleStopPropagation}>

                    <GenericForm
                        ref={form_ref}
                        groups={groups}
                        _form={_form}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                        disabled={loadingCEP}
                    />

                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />

                </Form>

                <ContainerBottomButtons>

                    <Button
                        color="#FFF"
                        background={secondary.light}
                        onClick={() => buttonSubmitRef.current?.click()}
                        style={{ fontSize: 15, padding: "8px 16px" }}
                        children="Criar conta"
                        disabled={loadingCEP || loading}
                    />

                    <ContainerTerms>
                        Ao criar sua conta, você concorda com os
                        <AnchorTerms
                            target="_blank"
                            href={terms_url}
                            children={<Span> termos de uso</Span>}
                        />
                        .
                    </ContainerTerms>

                </ContainerBottomButtons>

            </Content>

            <ModalLoading loading={loading} theme="light" />

        </Container>
    )
}