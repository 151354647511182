import React, { useCallback, useEffect, useState } from "react";
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { useNavigate } from "react-router-dom";
import { FiHome, FiSend } from "react-icons/fi";
import { FaFileContract } from "react-icons/fa";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { getFilterFields } from "../../utils/filterFields";
import { contract_status } from "../../utils/contract_status";
import { ALL_USERS, ROOT_USERS, STAFF_USERS } from "../../utils/users.groups";
import { ModalContract } from "../../components/Modals/ModalContract";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ModalAttention } from "../../components/Modals/ModalAttention";
import { getFranchise, getAuthor, getStatus } from "../../utils/columnsTables";
import {
  copyOf,
  correctDate,
  setUpConsultation,
} from "../../services/generalServices";
import {
  contractsAttributes,
  historyAttributes,
  IActions,
  IFilters,
  ITagList,
} from "../../interfaces";

const initialFilters: IFilters[] = [
  getFilterFields("franchise_id", ROOT_USERS),
  getFilterFields("bond_type", ALL_USERS),
  getFilterFields("contract_author_id", STAFF_USERS),
  getFilterFields("document_eletronic_status", ALL_USERS),
  getFilterFields("range_picker", ALL_USERS),
];

const initialTable: ITable = {
  data: [],
  columns: [
    {
      attr: "friendly_id",
      name: "Código",
      style: {},
      styleContent: { fontSize: 16, fontWeight: 600 },
    },
    {
      attr: "name",
      name: "Nome",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 16, fontWeight: 600 },
    },
    {
      attr: "bond",
      name: "Vínculo",
      style: { textAlign: "center" },
      styleContent: {},
    },
    { attr: "signatories", name: "Signatário(s)", style: {}, styleContent: {} },
    {
      attr: "author",
      name: "Autor",
      style: { textAlign: "center", whiteSpace: "nowrap" },
      styleContent: { textAlign: "center" },
    },
    {
      attr: "status",
      name: "Status",
      style: { textAlign: "center" },
      styleContent: { textAlign: "center" },
    },
    {
      attr: "createdAt",
      name: "Criação",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
  ],
};

const initialTableRoot: ITable = {
  data: [],
  columns: [
    {
      attr: "friendly_id",
      name: "Código",
      style: {},
      styleContent: { fontSize: 16, fontWeight: 600 },
    },
    {
      attr: "name",
      name: "Nome",
      limitWidth: true,
      style: {},
      styleContent: { fontSize: 16, fontWeight: 600 },
    },
    {
      attr: "bond",
      name: "Vínculo",
      style: { textAlign: "center" },
      styleContent: {},
    },
    {
      attr: "franchise",
      name: "Imobiliária",
      style: { textAlign: "center" },
      styleContent: { textAlign: "center" },
    },
    {
      attr: "status",
      name: "Status",
      style: { textAlign: "center" },
      styleContent: { textAlign: "center" },
    },
    {
      attr: "createdAt",
      name: "Criação",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "signedAt",
      name: "Assinatura",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
    {
      attr: "canceledAt",
      name: "Cancelamento",
      style: { textAlign: "center" },
      styleContent: { fontSize: 14, textAlign: "center" },
    },
  ],
};

const actionsInitial: IActions[] = [
  {
    main_option: {
      text: "Modelos de Documentos Eletrônicos",
      id: "go_to_models",
    },
    className: "go_to_models",
    icon: <FaFileContract size={20} />,
    options: [],
    type: "outline",
    canAccess: ["responsible", "manager"],
  },
  {
    main_option: {
      text: "Novo Documento Eletrônico",
      id: "new_item",
    },
    className: "new_item",
    icon: <IoAddCircleOutline size={20} className="new_item" />,
    options: [],
    type: "solid",
    canAccess: ["responsible", "manager", "broker", "analyst"],
  },
];

const getBond: React.FC<ITagList> = ({ theme, contract }) => {
  if (!contract.property_id && !contract.proposal_id)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontStyle: "italic",
          opacity: 0.7,
          fontSize: 14,
        }}
      >
        NENHUM
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 5,
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            padding: 5,
            whiteSpace: "nowrap",
            alignItems: "center",
            gap: 5,
            display: "flex",
            paddingLeft: 7,
            paddingRight: 7,
            borderRadius: 3,
            background: secondary[theme],
            color: "#FFF",
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          <div>
            {contract.property_id ? <FiHome size={13} /> : <FiSend size={13} />}
          </div>
          {contract.property_id
            ? `Imóvel - #${contract.property?.friendly_id}`
            : `Proposta - #${contract.proposal?.friendly_id}`}
        </div>
      </div>
    </div>
  );
};

const getSignatories: React.FC<ITagList> = ({ theme, contract }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
      {contract.signatories.map((signatory: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{
                padding: 5,
                whiteSpace: "nowrap",
                display: "flex",
                paddingLeft: 7,
                paddingRight: 7,
                borderRadius: 3,
                background: secondary[theme],
                color: "#FFF",
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              {signatory.name}
            </div>
          </div>
        );
      })}
      {contract.signatories.length === 0 && (
        <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>
          Nenhum(a)
        </span>
      )}
    </div>
  );
};

export const ContractsList: React.FC = () => {
  const navigate = useNavigate();

  const { api, user } = useApi();
  const { theme, notify, paginationLimit, franchise } = useGlobal();

  const [atualPage, setAtualPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [numOfContracts, setNumOfContracts] = useState(0);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [loadingContracts, setLoadingContracts] = useState(false);
  const [openModalContract, setOpenModalContract] = useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [contractSelected, setContractSelected] =
    useState<contractsAttributes | null>(null);
  const [table, setTable] = useState<ITable>(
    user.type === "root" ? initialTableRoot : initialTable
  );

  const searchContracts = useCallback(
    async (page, _filters = null) => {
      setAtualPage(page);
      setLoadingContracts(true);
      try {
        const filters_obj = _filters
          ? _filters
          : { ...filters, search: searchString };

        let period: any = {};
        period.date_init = filters_obj.date_init ? filters_obj.date_init : null;
        period.date_end = filters_obj.date_end ? filters_obj.date_end : null;
        if (!period.date_init || !period.date_end) period = undefined;
        else period = JSON.stringify([period.date_init, period.date_end]);

        filters_obj.period = period;

        const string = setUpConsultation(filters_obj);
        const url = `/generic_searches/contracts?page=${page}&limit=${paginationLimit}&${string}`;

        const result = await api.get(url);

        let new_contracts: contractsAttributes[] = result.data.rows;

        setTable((atual) => {
          const new_table = { ...atual };
          new_table.data = [];
          new_contracts.forEach((contract) => {
            const history = JSON.parse(
              contract.history as any
            ) as historyAttributes[];
            const last_history = history[history.length - 1];
            new_table.data.push({
              id: contract.id,
              friendly_id: `#${contract.friendly_id}`,
              name: contract?.name,
              bond: getBond({ contract, theme }),
              signatories: getSignatories({ contract, theme }),
              status: getStatus({ ...contract_status[contract.status] }),
              author: getAuthor({ person: contract, theme }),
              franchise: getFranchise({ person: contract, theme }),
              createdAt: correctDate(contract.createdAt),
              expiration: correctDate(contract.expiries_in),
              canceledAt:
                contract.status === "canceled"
                  ? correctDate(last_history.createdAt)
                  : "-",
              signedAt:
                contract.status === "signed"
                  ? correctDate(last_history.createdAt)
                  : "-",
              this: contract,
            });
          });
          return { ...new_table };
        });

        setNumOfContracts(result.data.count);
        setNumberOfPages(Math.ceil(result.data.count / paginationLimit));
        setUpdatedAt(new Date());
      } catch (err) {
        notify("Erro na busca pelos documentos eletrônicos!", "error");
      }
      setLoadingContracts(false);
    },
    [franchise, filters, searchString]
  );

  const onCancelModal = useCallback(() => {
    setContractSelected(null);
    setOpenModalContract(false);
    searchContracts(atualPage);
  }, [franchise, filters, searchString, atualPage]);

  const onSaveContract = useCallback(() => {
    setOpenModalContract(false);
    searchContracts(1);
  }, [franchise, filters, searchString]);

  const handleOpenModalContract = useCallback(async (contract) => {
    setContractSelected({ ...contract });
    setOpenModalContract(true);
  }, []);

  const confirmCancelContract = useCallback(async () => {
    setOpenConfirmCancel(false);
    setLoadingCancel(true);
    try {
      await api.put(`/contracts/cancel/${contractSelected?.id}`);
      notify("Documento eletrônico cancelado com sucesso!", "success");
      setContractSelected(null);
      searchContracts(atualPage);
    } catch (err) {
      notify("Erro ao cancelar documento eletrônico!", "error");
    }
    setLoadingCancel(false);
  }, [contractSelected, atualPage, franchise, filters, searchString]);

  const onClickCancelContract = useCallback(async (contract) => {
    setContractSelected({ ...contract });
    setOpenConfirmCancel(true);
  }, []);

  const closeConfirmCancel = useCallback(() => {
    setOpenConfirmCancel(false);
    setContractSelected(null);
  }, []);

  const handleAction = (action_id: string) => {
    if (action_id === "new_item") setOpenModalContract(true);
    else if (action_id === "go_to_models")
      navigate("/settings/real_estate/models-documents-electronic");
    else console.log({ action_id });
  };

  const getQueryContractId = useCallback(async () => {
    const query = window.location.search.split("?").pop();
    const queries = query ? query.split("&") : [""];
    let contractId: any = queries.find(
      (item: string) => item.split("=")[0] === "contractId"
    );
    contractId = contractId ? contractId.split("=")[1] : null;
    if (contractId) {
      const { data } = await api.get(`/contracts/${contractId}`);
      setContractSelected({ ...data });
      setOpenModalContract(true);
    }
  }, []);

  useEffect(() => {
    getQueryContractId();
  }, []);

  useEffect(() => {
    searchContracts(1);
  }, []);

  return (
    <PageList
      updatedAt={updatedAt}
      loading={loadingContracts}
      setLoading={setLoadingContracts}
      numberOfItems={numOfContracts}
      textHeader="Documentos Eletrônicos"
      textItems="documentos eletrônicos encontrados"
      text_loading={"Buscando Documentos Eletrônicos"}
      text_empty={"Nenhum documento eletrônico encontrado"}
      theme={theme}
      initialFilters={initialFilters}
      onChangeFilters={setFilters}
      table={table}
      have_edit
      have_cancel={user.type !== "root"}
      have_download
      getUrlDownload={(contract) =>
        contract.file_signed_url
          ? contract.file_signed_url
          : contract.file_original_url
      }
      canDownload={(contract) =>
        ["signed", "pending"].includes(contract.status) &&
        (contract.file_signed_url || contract.file_original_url)
      }
      canCancel={(contract) => ["pending"].includes(contract.status)}
      edit_action={(contract) => handleOpenModalContract(contract)}
      cancel_action={(contract) => onClickCancelContract(contract)}
      search={searchContracts}
      pages={numberOfPages}
      page={atualPage}
      onClickOption={handleAction}
      actions={actionsInitial}
      onChangeSearch={setSearchString}
      getTextDownload={(contract) =>
        contract.file_signed_url
          ? "Documento eletrônico Assinado"
          : "Documento eletrônico Original"
      }
    >
      {openModalContract && (
        <ModalContract
          onCancel={onCancelModal}
          onSave={onSaveContract}
          openModal={openModalContract}
          backgroundVisible={false}
          defaultContract={copyOf(contractSelected)}
        />
      )}

      <ModalAttention
        theme={theme}
        open={openConfirmCancel}
        cancelAction={closeConfirmCancel}
        confirmAction={confirmCancelContract}
        cancelText="Voltar"
        content={
          <>
            Você deseja cancelar o documento eletrônico{" "}
            <b>{contractSelected?.name?.toUpperCase()}</b> ?
          </>
        }
      />

      <ModalLoading loading={loadingCancel} theme={theme} />
    </PageList>
  );
};
