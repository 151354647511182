import styled, { css } from "styled-components";
import { background, inactiveItens } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    min-width:500px;
    max-width: 500px;
    outline: 0;
    padding:20px;
    align-items: center;
    justify-content: space-between;
    gap:20px;
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`