import React from "react";
import { Container } from "./style";
import { Bar, Line } from "react-chartjs-2";

interface GraficoProps {
  infos: any;
  color?: string
  labels: any;
  labelYFunction?: any
  labelTooltipFunction?: any
  mask?: Function;
  isInverted?: boolean;
}


export const ChartLine: React.FC<GraficoProps> = (props) => {
  const defaultTooltipFunction = (context: any) => {
    var label = context.dataset.label || "";
    if (label) label += ": ";
    if (context.parsed?.[isInverted ? "x" : "y"] !== null) {
      const value = context.parsed?.[isInverted ? "x" : "y"] || 0;
      label += props.mask ? props.mask(value) : value;
    }
    return `  ${label}`;
  }

  const defaultLabelYFunction = (value: any, index: any, values: any) => {
    const correctValue = value || 0;
    return props.mask ? props.mask(correctValue) : correctValue;
  }

  const { infos, color = "#000", labels, labelYFunction = defaultLabelYFunction, labelTooltipFunction = defaultTooltipFunction, isInverted } = props;

  const x = {
    display: true,
    grid: {},
    ticks: {
      callback: (value: any, index: any, values: any) => labels[index],
      color: color,
      font: { family: "Poppins", weight: 600 }
    }
  }

  const y = {
    grid: {},
    ticks: {
      callback: labelYFunction,
      color: color,
      font: { family: "Poppins", weight: 600 }
    }
  }
  const options = {
    indexAxis: isInverted ? "y" : "x",
    scales: {
      x: isInverted ? y : x,
      y: isInverted ? x : y
    },
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: { display: false },
      labelFontColor: "#FFF",
      tooltip: {
        bodyFont: { family: "Poppins", weight: 600 },
        titleFont: { family: "Poppins", weight: 600 },
        yAlign: "bottom",
        xAlign: "center",
        callbacks: { label: labelTooltipFunction },
        overflow: "scroll",
      }
    }
  }

  const data: any = { labels: labels, datasets: [] }

  for (const info of infos) {
    data.datasets.push({
      axis: 'y',
      label: info.name,
      backgroundColor: info.color || "#FF9000",
      borderColor: info.color || "#FF9000",
      borderCapStyle: "butt",
      borderJoinStyle: "miter",
      borderRadius: 5,
      pointRadius: 1,
      maxBarThickness: 25,
      tension: 0.4,
      pointHitRadius: 10,
      borderDash: [5, 5],
      data: info.values,
    })
  }

  return (
    <>
      <Container haveData={!!infos.data}>
        <Bar type options={options} data={data} />
      </Container>
    </>
  )
}