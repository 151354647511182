import Button from "../../../components/Buttons/Button";
import { Form } from "../style";
import { useForm } from "react-hook-form";
import { secondary } from "../../../theme";
import { useApi } from "../../../hooks/api";
import { useNavigate } from "react-router-dom";
import { IGroupProps } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";
import { FormEvent, useCallback, useRef, useState } from "react";
import { ModalLoading } from "../../../components/Loadings/ModalLoading";
import {
  GenericForm,
  IGenericFormRefProps,
} from "../../../components/GenericForm";

interface INewPasswordFormProps {}

export const NewPasswordForm: React.FC<INewPasswordFormProps> = () => {
  const { api } = useApi();
  const navigate = useNavigate();
  const { theme, notify } = useGlobal();

  const [loadingSave, setLoadingSave] = useState(false);

  const form_ref = useRef<IGenericFormRefProps>(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({ mode: "all" });

  const _form = watch();

  const onSubmit = useCallback(
    async (form: any) => {
      setLoadingSave(true);

      try {
        await api.put("/users/change_password", form);

        notify("Senha alterada com sucesso.", "success");

        navigate("/");
      } catch (err: any) {
        setLoadingSave(false);

        const error = err.response ? err.response.data : "SERVER ERROR";

        if (error.path !== "SERVER ERROR" && error.path)
          setError(error.path, { type: "manual", message: error.message });
      }

      setLoadingSave(false);
    },
    [form_ref, _form, api]
  );

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api]
  );

  const groups: IGroupProps[] = [
    {
      name: "",
      label: "",
      fields: [
        [
          {
            name: "oldPassword",
            label: "Senha atual",
            type: "input",
            required: true,
            isPassword: true,
            noUpperCase: true,
          },
          {
            name: "newPassword",
            label: "Nova senha",
            type: "input",
            required: true,
            isPassword: true,
            noUpperCase: true,
          },
          {
            name: "confirmPassword",
            label: "Confirmar nova senha",
            type: "input",
            required: true,
            isPassword: true,
            validate: async (value: any) => {
              const password = watch("newPassword");
              if (value !== password) return "As senhas não conferem.";
              return true;
            },
            noUpperCase: true,
          },
        ],
      ],
    },
  ];

  return (
    <>
      <Form onSubmit={handleStopPropagation} style={{ marginTop: 0 }}>
        <GenericForm
          ref={form_ref}
          groups={groups}
          _form={_form}
          control={control}
          trigger={trigger}
          errors={errors}
          setValue={setValue}
          register={register}
        />

        <Button
          type="submit"
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, fontSize: 14 }}
          children="Salvar"
        />
      </Form>

      <ModalLoading theme={theme} loading={loadingSave} />
    </>
  );
};
