import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useCallback, useEffect } from "react";
import { useApi } from "../../hooks/api";
import { IoMdSearch } from "react-icons/io";
import { VscSettings } from "react-icons/vsc";
import { TbReportMoney } from "react-icons/tb";
import { useWallet } from "../../hooks/wallet";
import { useGlobal } from "../../hooks/global";
import { MdOutlineAnalytics } from "react-icons/md";
import { IoDocuments, IoSettingsOutline } from "react-icons/io5";
import { RiDashboardFill, RiSendPlaneFill } from "react-icons/ri";
import { HiCurrencyDollar, HiOutlineLightBulb } from "react-icons/hi";
import { MdMapsHomeWork, MdOutlineDocumentScanner } from "react-icons/md";
import { FaUserTie, FaUsers, FaWallet, FaFileContract } from "react-icons/fa";
import {
  BsCardList,
  BsCreditCardFill,
  BsFillInfoSquareFill,
} from "react-icons/bs";
import {
  Container,
  Item,
  MenuList,
  Link,
  IconLink,
  LinkName,
  ActiveIndicator,
  SubMenuList,
  ItemSubMenu,
  IconSubMenu,
  IconArrow,
  ContainerSubSubMenuItems,
} from "./style";

interface menuItemsProps {
  name: string;
  to: string;
  icon: string;
  iconComponent?: React.ReactElement;
  package: string;
  subMenus: menuItemsProps[];
}

interface menuProps {
  [key: string]: menuItemsProps[];
}

const menus: menuProps = {
  root: [
    {
      name: "Dashboard",
      to: "/",
      icon: "i-Home1",
      iconComponent: <RiDashboardFill size={30} style={{ marginBottom: 10 }} />,
      package: "Dashboard",
      subMenus: [],
    },
    {
      name: "Responsáveis",
      to: "/responsibles",
      icon: "i-Management",
      iconComponent: <FaUserTie size={30} style={{ marginBottom: 10 }} />,
      package: "Responsáveis",
      subMenus: [],
    },
    {
      name: "Imobiliárias",
      to: "/real_estate",
      icon: "i-Shop-2",
      package: "Imobiliárias",
      subMenus: [
        {
          name: "Listar Imobiliárias",
          icon: "i-Shop-2",
          iconComponent: <BsCardList size={19} style={{ marginRight: 10 }} />,
          to: "",
          package: "Imobiliárias",
          subMenus: [],
        },
        {
          name: "Assinatura",
          icon: "i-Management",
          iconComponent: (
            <BsCreditCardFill size={19} style={{ marginRight: 10 }} />
          ),
          to: "/subscription",
          package: "Imobiliárias",
          subMenus: [],
        },
        {
          name: "Carteira de Créditos",
          icon: "i-Management",
          iconComponent: <FaWallet size={19} style={{ marginRight: 10 }} />,
          to: "/wallet",
          package: "Imobiliárias",
          subMenus: [],
        },
        {
          name: "Pessoas",
          to: "/persons",
          icon: "i-Management",
          iconComponent: <FaUsers size={19} style={{ marginRight: 10 }} />,
          package: "Imobiliárias",
          subMenus: [],
        },
        {
          name: "Consultas",
          to: "/queries",
          iconComponent: <IoMdSearch size={19} style={{ marginRight: 10 }} />,
          icon: "i-Home1",
          package: "Imobiliárias",
          subMenus: [],
        },
        {
          name: "Propostas",
          to: "/proposals",
          icon: "i-Paper-Plane",
          iconComponent: (
            <RiSendPlaneFill size={19} style={{ marginRight: 10 }} />
          ),
          package: "Imobiliárias",
          subMenus: [],
        },
        {
          name: "Imóveis",
          to: "/properties",
          iconComponent: (
            <MdMapsHomeWork size={19} style={{ marginRight: 10 }} />
          ),
          icon: "i-Home1",
          package: "Imóveis",
          subMenus: [],
        },
        {
          name: "Doc's Eletrônicos",
          to: "/documents-electronic",
          iconComponent: (
            <MdOutlineDocumentScanner size={19} style={{ marginRight: 10 }} />
          ),
          icon: "i-Paper-Plane",
          package: "Imobiliárias",
          subMenus: [],
        },
      ],
    },
    {
      name: "Políticas de Análise",
      to: "/credit-policies",
      icon: "i-Management",
      iconComponent: (
        <MdOutlineAnalytics size={30} style={{ marginBottom: 10 }} />
      ),
      package: "Políticas de Análise",
      subMenus: [],
    },
    {
      name: "Planos",
      to: "/plans",
      icon: "i-Management",
      iconComponent: <TbReportMoney size={30} style={{ marginBottom: 10 }} />,
      package: "Planos",
      subMenus: [],
    },
    {
      name: "Configurações",
      to: "/settings",
      icon: "i-Paper-Plane",
      iconComponent: (
        <IoSettingsOutline size={30} style={{ marginBottom: 10 }} />
      ),
      package: "Configurações",
      subMenus: [
        {
          name: "Tipos de Imóveis",
          icon: "i-Management",
          iconComponent: (
            <MdMapsHomeWork size={19} style={{ marginRight: 10 }} />
          ),
          to: "/properties_types",
          package: "Configurações",
          subMenus: [],
        },
        {
          name: "Tipos de Doc's",
          icon: "i-Add",
          iconComponent: <IoDocuments size={19} style={{ marginRight: 10 }} />,
          to: "/documents_types",
          package: "Configurações",
          subMenus: [],
        },
      ],
    },
  ],
  responsible: [
    {
      name: "Dashboard",
      to: "/",
      icon: "i-Home1",
      iconComponent: <RiDashboardFill size={30} style={{ marginBottom: 10 }} />,
      package: "Dashboard",
      subMenus: [],
    },
    {
      name: "Propostas",
      to: "/proposals",
      icon: "i-Paper-Plane",
      package: "Propostas",
      subMenus: [],
    },
    {
      name: "Pessoas",
      to: "/persons",
      icon: "i-Management",
      iconComponent: <FaUsers size={30} style={{ marginBottom: 10 }} />,
      package: "Pessoas",
      subMenus: [],
    },
    {
      name: "Consultas Cadastrais",
      to: "/queries",
      iconComponent: <IoMdSearch size={30} style={{ marginBottom: 10 }} />,
      icon: "i-Home1",
      package: "Consultas Cadastrais",
      subMenus: [],
    },
    {
      name: "Imóveis",
      to: "/properties",
      iconComponent: <MdMapsHomeWork size={30} style={{ marginBottom: 10 }} />,
      icon: "i-Home1",
      package: "Imóveis",
      subMenus: [],
    },
    {
      name: "Documentos Eletrônicos",
      to: "/documents-electronic",
      iconComponent: (
        <MdOutlineDocumentScanner size={30} style={{ marginBottom: 10 }} />
      ),
      icon: "i-Paper-Plane",
      package: "Documentos Eletrônicos",
      subMenus: [],
    },
    {
      name: "Preços e Faturamento",
      to: "/billing",
      iconComponent: (
        <HiCurrencyDollar size={30} style={{ marginBottom: 10 }} />
      ),
      icon: "i-Management",
      package: "Preços e Faturamento",
      subMenus: [
        {
          name: "Assinatura",
          icon: "i-Management",
          iconComponent: (
            <BsCreditCardFill size={19} style={{ marginRight: 10 }} />
          ),
          to: "/subscription",
          package: "Preços e Faturamento",
          subMenus: [],
        },
        {
          name: "Carteira de Créditos",
          icon: "i-Management",
          iconComponent: <FaWallet size={19} style={{ marginRight: 10 }} />,
          to: "/wallet",
          package: "Preços e Faturamento",
          subMenus: [],
        },
      ],
    },
    {
      name: "Configurações",
      to: "/settings/real_estate",
      icon: "i-Paper-Plane",
      iconComponent: (
        <IoSettingsOutline size={30} style={{ marginBottom: 10 }} />
      ),
      package: "Configurações",
      subMenus: [
        {
          name: "Usuários",
          icon: "i-Management",
          iconComponent: <FaUsers size={19} style={{ marginRight: 10 }} />,
          to: "/users",
          package: "Configurações",
          subMenus: [],
        },
        {
          name: "Integrações",
          icon: "i-Add",
          iconComponent: <VscSettings size={19} style={{ marginRight: 10 }} />,
          to: "/integrations",
          package: "Configurações",
          subMenus: [],
        },
        {
          name: "Modelos de doc. eletrônicos",
          icon: "i-Management",
          iconComponent: (
            <FaFileContract size={19} style={{ marginRight: 10 }} />
          ),
          to: "/models-documents-electronic",
          package: "Configurações",
          subMenus: [],
        },
        {
          name: "Políticas de análise",
          icon: "i-Management",
          iconComponent: (
            <MdOutlineAnalytics size={22} style={{ marginRight: 10 }} />
          ),
          to: "/credit-policies",
          package: "Configurações",
          subMenus: [],
        },
        {
          name: "Dados da Empresa",
          icon: "i-Management",
          iconComponent: (
            <BsFillInfoSquareFill size={19} style={{ marginRight: 10 }} />
          ),
          to: "/company-data",
          package: "Configurações",
          subMenus: [],
        },
      ],
    },
  ],
  manager: [
    {
      name: "Dashboard",
      to: "/",
      icon: "i-Home1",
      iconComponent: <RiDashboardFill size={30} style={{ marginBottom: 10 }} />,
      package: "Dashboard",
      subMenus: [],
    },
    {
      name: "Propostas",
      to: "/proposals",
      icon: "i-Paper-Plane",
      package: "Propostas",
      subMenus: [],
    },
    {
      name: "Pessoas",
      to: "/persons",
      icon: "i-Management",
      iconComponent: <FaUsers size={30} style={{ marginBottom: 10 }} />,
      package: "Pessoas",
      subMenus: [],
    },
    {
      name: "Consultas Cadastrais",
      to: "/queries",
      iconComponent: <IoMdSearch size={30} style={{ marginBottom: 10 }} />,
      icon: "i-Home1",
      package: "Consultas Cadastrais",
      subMenus: [],
    },
    {
      name: "Imóveis",
      to: "/properties",
      iconComponent: <MdMapsHomeWork size={30} style={{ marginBottom: 10 }} />,
      icon: "i-Home1",
      package: "Imóveis",
      subMenus: [],
    },
    {
      name: "Documentos Eletrônicos",
      to: "/documents-electronic",
      iconComponent: (
        <MdOutlineDocumentScanner size={30} style={{ marginBottom: 10 }} />
      ),
      icon: "i-Paper-Plane",
      package: "Documentos Eletrônicos",
      subMenus: [],
    },
  ],
  broker: [
    {
      name: "Dashboard",
      to: "/",
      icon: "i-Home1",
      iconComponent: <RiDashboardFill size={30} style={{ marginBottom: 10 }} />,
      package: "Dashboard",
      subMenus: [],
    },
    {
      name: "Propostas",
      to: "/proposals",
      icon: "i-Paper-Plane",
      package: "Propostas",
      subMenus: [],
    },
    {
      name: "Pessoas",
      to: "/persons",
      icon: "i-Management",
      iconComponent: <FaUsers size={30} style={{ marginBottom: 10 }} />,
      package: "Pessoas",
      subMenus: [],
    },
    {
      name: "Imóveis",
      to: "/properties",
      iconComponent: <MdMapsHomeWork size={30} style={{ marginBottom: 10 }} />,
      icon: "i-Home1",
      package: "Imóveis",
      subMenus: [],
    },
    {
      name: "Documentos Eletrônicos",
      to: "/documents-electronic",
      iconComponent: (
        <MdOutlineDocumentScanner size={30} style={{ marginBottom: 10 }} />
      ),
      icon: "i-Paper-Plane",
      package: "Documentos Eletrônicos",
      subMenus: [],
    },
  ],
  analyst: [
    {
      name: "Dashboard",
      to: "/",
      icon: "i-Home1",
      iconComponent: <RiDashboardFill size={30} style={{ marginBottom: 10 }} />,
      package: "Dashboard",
      subMenus: [],
    },
    {
      name: "Propostas",
      to: "/proposals",
      icon: "i-Paper-Plane",
      package: "Propostas",
      subMenus: [],
    },
    {
      name: "Oportunidades",
      to: "/opportunities",
      iconComponent: (
        <HiOutlineLightBulb size={30} style={{ marginBottom: 10 }} />
      ),
      icon: "",
      package: "Oportunidades",
      subMenus: [],
    },
    {
      name: "Pessoas",
      to: "/persons",
      icon: "i-Management",
      iconComponent: <FaUsers size={30} style={{ marginBottom: 10 }} />,
      package: "Pessoas",
      subMenus: [],
    },
    {
      name: "Imóveis",
      to: "/properties",
      iconComponent: <MdMapsHomeWork size={30} style={{ marginBottom: 10 }} />,
      icon: "i-Home1",
      package: "Imóveis",
      subMenus: [],
    },
    {
      name: "Documentos Eletrônicos",
      to: "/documents-electronic",
      iconComponent: (
        <MdOutlineDocumentScanner size={30} style={{ marginBottom: 10 }} />
      ),
      icon: "i-Paper-Plane",
      package: "Documentos Eletrônicos",
      subMenus: [],
    },
  ],
};

export const Sidebar: React.FC = () => {
  const { user } = useApi();
  const { subscription } = useWallet();
  const {
    menuOpen,
    subMenuOpen,
    setSubMenuOpen,
    theme,
    menuItemSelected,
    seeNotification,
    setMenuItemSelected,
    setSubMenuItemSelected,
    subMenuItemSelected,
    isMobile,
  } = useGlobal();

  const clickLink = useCallback(
    (event, menuItem: menuItemsProps) => {
      if (isMobile && menuItem.subMenus.length > 0) {
        if (subMenuOpen) {
          if (
            menuItemSelected.name === menuItem.name &&
            menuItemSelected.package === menuItem.package
          )
            setSubMenuOpen(false);
          else {
            setMenuItemSelected(menuItem);
            setSubMenuOpen(true);
          }
        } else {
          setMenuItemSelected(menuItem);
          setSubMenuOpen(true);
        }
        event.preventDefault();
      } else {
        if (
          menuItemSelected.name === menuItem.name &&
          menuItemSelected.package === menuItem.package
        ) {
          setSubMenuOpen(!subMenuOpen);
        }
      }
    },
    [menuItemSelected, subMenuOpen, isMobile]
  );

  const clickSubLink = useCallback((event, menuItem: menuItemsProps) => {
    if (menuItem.subMenus.length > 0) {
      setSubMenuItemSelected((atual) => {
        return atual.name === menuItem.name &&
          atual.package === menuItem.package
          ? ({} as menuItemsProps)
          : menuItem;
      });
      event.preventDefault();
    } else setSubMenuOpen(false);
  }, []);

  const hoverLink = useCallback(
    (menuItem: menuItemsProps) => {
      if (!isMobile) {
        if (menuItem.subMenus.length > 0) {
          setMenuItemSelected(menuItem);
          setSubMenuOpen(true);
        } else setSubMenuOpen(false);
      }
    },
    [isMobile]
  );

  const clickActiveIndicator = useCallback((menuItemId) => {
    document.getElementById(menuItemId)?.click();
  }, []);

  const getHeight = useCallback(() => {
    const header = document.getElementById("header-content");
    return `calc(calc(100 * var(--vh)) - ${header?.clientHeight}px)`;
  }, []);

  useEffect(() => {
    setSubMenuItemSelected({} as menuItemsProps);
  }, [menuItemSelected]);

  useEffect(() => {
    const header = document.getElementById("header-content");
    if (header) header.click();
  }, [subscription, seeNotification]);

  return (
    <Container theme={theme} menuOpen={menuOpen}>
      <div
        id="nav-sidebar"
        style={{ display: "flex", flexDirection: "row", height: "100%" }}
      >
        <MenuList theme={theme}>
          <PerfectScrollbar
            style={{ maxHeight: getHeight() }}
            options={{ suppressScrollY: false }}
          >
            {menus[user.type].map((item, index) => (
              <Item
                theme={theme}
                onMouseEnter={() => hoverLink(item)}
                noBorder={index === menus[user.type].length - 1}
                key={`${item.name}-${item.icon}`}
              >
                <Link
                  style={{ maxHeight: 109.5, justifyContent: "center" }}
                  id={`link-to-${item.name}`}
                  theme={theme}
                  active={
                    item.package === menuItemSelected.package && subMenuOpen
                  }
                  to={item.to}
                  onClick={(event) => clickLink(event, item)}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.iconComponent && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minWidth: 30,
                        }}
                      >
                        {item.iconComponent}
                      </div>
                    )}
                    {!item.iconComponent && <IconLink className={item.icon} />}
                  </div>
                  <LinkName>{item.name}</LinkName>
                </Link>
                <ActiveIndicator
                  active={
                    item.package === menuItemSelected.package && subMenuOpen
                  }
                  onClick={() => clickActiveIndicator(`link-to-${item.name}`)}
                  theme={theme}
                />
              </Item>
            ))}
          </PerfectScrollbar>
        </MenuList>
        <SubMenuList
          open={
            subMenuOpen &&
            menuItemSelected.subMenus &&
            menuItemSelected.subMenus.length > 0
          }
          theme={theme}
        >
          <PerfectScrollbar
            style={{
              maxHeight: "calc(calc(100 * var(--vh)) - 82px)",
              paddingTop: 10,
              paddingBottom: 10,
            }}
            options={{ suppressScrollY: false }}
          >
            {menuItemSelected.subMenus &&
              menuItemSelected.subMenus.map((subMenu) => (
                <>
                  <ItemSubMenu
                    active={
                      subMenuItemSelected.name === subMenu.name &&
                      subMenu.package === subMenuItemSelected.package
                    }
                    key={subMenu.name}
                  >
                    <Link
                      id={`link-to-${subMenu.name}`}
                      active={false}
                      theme={theme}
                      to={`${menuItemSelected.to}${subMenu.to}`}
                      style={{
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        flexDirection: "row",
                      }}
                      onClick={(event) => clickSubLink(event, subMenu)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {subMenu.iconComponent && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: 40,
                            }}
                          >
                            {subMenu.iconComponent}
                          </div>
                        )}
                        {!subMenu.iconComponent && (
                          <IconSubMenu className={subMenu.icon} />
                        )}
                        <span style={{ textAlign: "left" }}>
                          {subMenu.name}
                        </span>
                      </div>
                      {subMenu.subMenus.length > 0 && (
                        <IconArrow
                          open={
                            subMenuItemSelected.name === subMenu.name &&
                            subMenu.package === subMenuItemSelected.package
                          }
                          theme={theme}
                          className="i-Arrow-Down"
                          data-fullscreen
                        />
                      )}
                    </Link>
                  </ItemSubMenu>
                  <ContainerSubSubMenuItems
                    maxHeight={subMenu.subMenus.length * 60}
                    open={
                      subMenuItemSelected.name === subMenu.name &&
                      subMenu.package === subMenuItemSelected.package
                    }
                  >
                    {subMenu.subMenus.map((subSubMenu) => (
                      <ItemSubMenu>
                        <Link
                          active={false}
                          theme={theme}
                          to={`${menuItemSelected.to}${subMenuItemSelected.to}${subSubMenu.to}`}
                          style={{
                            padding: 10,
                            paddingLeft: 49,
                            paddingRight: 20,
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ textAlign: "left" }}>
                            {subSubMenu.name}
                          </span>
                        </Link>
                      </ItemSubMenu>
                    ))}
                  </ContainerSubSubMenuItems>
                </>
              ))}
          </PerfectScrollbar>
        </SubMenuList>
      </div>
    </Container>
  );
};
