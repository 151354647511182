import React, { useState } from "react";
import Input from "../../../../Inputs/Input";
import Button from "../../../../Buttons/Button";
import { TiDelete } from "react-icons/ti";
import { ModalGeneric } from "../../../../ModalGeneric";
import { inactiveItens, secondary } from "../../../../../theme";
import { maskFunctions } from "../../../../../services/maskServices";
import { Container, ContainerText, Description, Title } from "./style";
import { correctDate2 } from "../../../../../services/generalServices";
import { plansAttributes, themeTypes } from "../../../../../interfaces";

interface IFinishSubscription {
    onCancel: Function
    onConfirm: Function
    openModal: boolean
    backgroundVisible?: boolean
    planSelected: plansAttributes
    theme: themeTypes
}

export const FinishSubscription: React.FC<IFinishSubscription> = (props) => {
    const { onCancel, onConfirm, openModal, backgroundVisible, planSelected, theme } = props

    const [textConfirm, setTextConfirm] = useState("")

    return (
        <ModalGeneric onClose={() => onCancel()} open={openModal} backgroundVisible={backgroundVisible} >
            <Container theme={theme}>
                <ContainerText>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <Title>
                            Você tem certeza disso?
                        </Title>
                        <div onClick={() => onCancel()} style={{ cursor: "pointer" }}>
                            <TiDelete
                                color={theme === "dark" ? "#FFF" : inactiveItens.light}
                                size={20}
                            />
                        </div>
                    </div>
                    <Description>
                        Ao assinar um novo plano, você concorda
                        em cancelar o seu plano atual, caso possua algum e
                        em pagar mensalmente a partir do dia <b>{correctDate2(new Date())}</b> o
                        valor de <b>{maskFunctions.currency.mask(planSelected.value)}</b> pela assinatura
                        do plano <b>{planSelected.name}</b>.
                    </Description>
                </ContainerText>
                <div style={{ display: "flex", gap: 5, flexDirection: "column", width: "100%", marginBottom: 10 }}>
                    <div>
                        Por favor digite <b>PAGAR</b> para confirmar
                    </div>
                    <Input
                        onChange={(value: any) => setTextConfirm(value)}
                        style={{ textTransform: "uppercase" }}
                    />
                </div>
                <Button
                    disabled={textConfirm.toUpperCase() !== "PAGAR"}
                    background={secondary[theme]}
                    color="#FFF"
                    style={{ width: "100%", fontSize: 16 }}
                    onClick={() => onConfirm()}
                    children="Estou ciente, pagar agora"
                />
            </Container>
        </ModalGeneric>
    )
}