import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { GenericButton } from "./style";

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    color: string
    background: string
    borderColor?: string
}

export interface ButtonRefProps {
    click: Function
    getWidth: Function
}

const Button: React.ForwardRefRenderFunction<ButtonRefProps, ButtonProps> = (props, ref) => {
    const { children, borderColor = undefined, background, color, ...rest } = props

    const button_ref = useRef<HTMLButtonElement>(null)

    const click = useCallback(() => button_ref.current?.click(), [button_ref])

    const getWidth = useCallback(() => button_ref.current?.offsetWidth ?? 0, [button_ref])

    useImperativeHandle(ref, () => ({ click, getWidth }))

    return (
        <GenericButton ref={button_ref} border={borderColor} color={color} background={background} {...rest}>
            {children}
        </GenericButton>
    )
}

export default forwardRef(Button)