import React from "react";
import { useApi } from "../hooks/api";
import { AppRoutes } from "./appRoutes";
import { AuthRoutes } from "./authRoutes";
import { useGlobal } from "../hooks/global";

export const Routes: React.FC = () => {
    const { user } = useApi()
    const { clickOnApp } = useGlobal()

    return (
        <div onClick={clickOnApp} style={{ display: "flex", flex: 1 }}>
            {user ? <AppRoutes /> : <AuthRoutes />}
        </div>
    )
}
