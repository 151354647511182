import BaseSelect from "react-select";
import BaseCreatableSelect from "react-select/creatable";
import React, { forwardRef, useImperativeHandle } from "react";
import { useGlobal } from "../../../../hooks/global";
import { useCallback, useRef, useState } from "react";
import { removeAccents } from "../../../../services/generalServices";
import { ContainerField, ContainerSelect, FieldError } from "../../../../style";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import { borderColors, borderDisabled, disabledItems, fail, inactiveItens, primary, secondary } from "../../../../theme";

export interface ISelectRefProps {
    focus: () => void
}

export interface IFixRequiredSelectProps extends StateManagerProps {
    onChange?: (value: any) => void
    error?: any
    isCreatable?: boolean
    [key: string]: any
}

const FixRequiredSelectComponent: React.ForwardRefRenderFunction<ISelectRefProps, IFixRequiredSelectProps> = (props, ref) => {
    const { required, hasIcon, noWrap, onBlur = () => { }, onFocus = () => { }, ...rest } = props;
    const { error, isDisabled, isCreatable } = props;

    const { theme } = useGlobal()

    const [isFocused, setIsFocused] = useState(false);

    const select_ref = useRef<any>();

    const handleFocus = (e: any) => {
        setIsFocused(true);
        onFocus(e);
    }

    const handleBlur = (e: any) => {
        setIsFocused(false);
        onBlur(e)
    }

    const getBorderColor = useCallback(() => {
        let color = borderColors[theme];
        if (isFocused) color = "#2684FF";
        else if (isDisabled) color = borderDisabled[theme];
        else if (error) color = fail;

        return color + " !important";
    }, [theme, isFocused, isDisabled, error])

    const customStyles = {
        container: (provided: any) => ({
            ...provided,
            minHeight: 38
        }),
        input: (provided: any) => ({
            ...provided,
            overflow: "hidden",
            color: isDisabled ? "#999999 !important" : `${inactiveItens[theme]} !important`
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            paddingLeft: hasIcon ? 40 : 10,
            fontSize: "16px !important",
            flexWrap: noWrap ? "nowrap" : "wrap"
        }),
        control: (provided: any) => ({
            ...provided,
            minHeight: 38,
            transition: "0s all",
            backgroundColor: isDisabled ? `${disabledItems[theme]} !important` : `${primary[theme]} !important`,
            borderColor: getBorderColor(),
            outline: isFocused && "1px solid #2684FF !important"
        }),
        placeholder: (provided: any) => ({
            ...provided
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: isDisabled ? "#999999 !important" : `${inactiveItens[theme]} !important`
        }),
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: `${secondary[theme]} !important`,
            color: isDisabled ? "#999999 !important" : `#FFF !important`
        }),
        menuList: (provided: any) => ({
            ...provided,
            maxHeight: 150,
            backgroundColor: `${primary[theme]} !important`
        }),
        clearIndicator: (provided: any) => ({
            ...provided,
            zIndex: 10
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: 50
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            color: "#CCCCCC !important"
        }),
    };

    const filterOption = (option: any, inputValue: any) => {
        option.label = option.label.toUpperCase()
        inputValue = removeAccents(inputValue.toUpperCase()).replace(/[^\w\s]/gi, '');
        const option_string = removeAccents(option.label.toUpperCase()).replace(/[^\w\s]/gi, '');
        return (option_string?.toString()?.match(inputValue) || []).length > 0
    };

    const focus = useCallback(() => { select_ref?.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ focus }))

    return (
        <ContainerField>
            <ContainerSelect>
                {!isCreatable && (
                    <BaseSelect
                        {...rest}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        styles={{ ...customStyles, ...props.styles }}
                        filterOption={filterOption}
                        ref={select_ref}
                        noOptionsMessage={() => "Nenhuma opção"}
                    />
                )}
                {isCreatable && (
                    <BaseCreatableSelect
                        {...rest}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        styles={{ ...customStyles, ...props.styles }}
                        filterOption={filterOption}
                        ref={select_ref}
                        noOptionsMessage={() => "Nenhuma opção"}
                    />
                )}
            </ContainerSelect>
            {(error && !props.isDisabled) && <FieldError title={error}>{error}</FieldError>}
        </ContainerField>
    );
}

export const FixRequiredSelect = forwardRef(FixRequiredSelectComponent);