import React from "react";
import { useGlobal } from "../../../../hooks/global";
import { FieldList, IFieldProps } from "../../../FiedlList";
import { maskFunctions } from "../../../../services/maskServices";
import { contractorDataAttributes } from "../../../../interfaces";
import { correctDate2 } from "../../../../services/generalServices";
import { ContainerObsOrDocs, ContentObsOrDocs, EmptyText, ObsOrDocsTitle } from "../style";

interface IContractorData {
    contractorData?: contractorDataAttributes
}

export const ContractorData: React.FC<IContractorData> = (props) => {
    const { contractorData } = props

    const { theme } = useGlobal()

    const FieldsDataContractorData: IFieldProps[] = [
        {
            label: "Início",
            keys: ["date_init"],
            mask: correctDate2
        },
        {
            label: "Fim",
            keys: ["date_end"],
            mask: correctDate2
        },
        {
            label: "Ocupação",
            keys: ["occupation"],
            mask: correctDate2,
            can_show: (contractorData: contractorDataAttributes) => !!contractorData.occupation
        },
        {
            label: "Dia de vencimento",
            keys: ["expiration_day"]
        },
        {
            label: "Índice de reajuste",
            keys: ["readjustment_index"],
        },
        {
            label: "Valor do aluguel",
            keys: ["rent_value"],
            mask: maskFunctions.currency.mask
        },
        {
            label: "Valor do condomínio",
            keys: ["condo_value"],
            mask: maskFunctions.currency.mask
        },
        {
            label: "Valor do IPTU",
            keys: ["iptu_value"],
            mask: maskFunctions.currency.mask
        },
        {
            label: "Administrado?",
            keys: ["is_managed"],
            transform: (value: any) => value ? "SIM" : "NÃO"
        },
        {
            label: "Taxa de Administração",
            keys: ["managed_value"],
            get_type_mask: (value: any) => value.managed_type === "PORCEN" ? (value: any) => maskFunctions.porcen.mask(value, true) : maskFunctions.currency.mask,
            can_show: (value: any) => value.is_managed
        },
        {
            label: "Taxa de Locação",
            keys: ["rental_fee_value"],
            get_type_mask: (value: any) => value.rental_fee_type === "PORCEN" ? (value: any) => maskFunctions.porcen.mask(value, true) : maskFunctions.currency.mask
        },
    ]

    const ListFieldsContractData = [
        {
            title: "",
            fields: FieldsDataContractorData
        }
    ]

    return (
        <>
            <FieldList
                theme={theme}
                data={contractorData}
                listFields={ListFieldsContractData}
            />

            <ContainerObsOrDocs theme={theme}>

                <ObsOrDocsTitle>OBSERVAÇÕES</ObsOrDocsTitle>

                <ContentObsOrDocs>
                    {contractorData?.observation}
                    {!contractorData?.observation && <EmptyText>Sem observações</EmptyText>}
                </ContentObsOrDocs>

            </ContainerObsOrDocs>
        </>
    )
}

