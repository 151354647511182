import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, inactiveItens, primary, divider } from "../../../theme";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    outline: 0;
    min-width:100vw;
    max-width:100vw;
    min-height: calc(100 * var(--vh));
    max-height: calc(100 * var(--vh));
    @media (min-width: 650px) {
        min-width:650px;
        max-width:650px;
        min-height: calc(90 * var(--vh));
        max-height: calc(90 * var(--vh));
    }
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const Title = styled.div<IThemeProps>`
    font-weight: 600;
    font-size: 18px;
    ${props => css`
        color: ${props.theme === "dark" ? "#FFF" : inactiveItens.light};
    `}
`

export const ContainerButton = styled.div`
    cursor: pointer;
`

export const ContentHeader = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 30px;
    padding: 20px;
    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,0.1);
    position: relative;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
    `}
`

export const ContainerBottom = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    ${props => css`
        border-top: 1px solid ${divider[props.theme as themeTypes]};
    `}
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
`