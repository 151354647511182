import React, { useState } from 'react';
import Input from '../../../components/Inputs/Input';
import Button from '../../../components/Buttons/Button';
import { Modal } from '@material-ui/core';
import { TiDelete } from 'react-icons/ti';
import { useGlobal } from '../../../hooks/global';
import { inactiveItens, secondary } from '../../../theme';
import { Container, ContainerText, Description, Title } from './style';
import { WaveLoading } from '../../../components/Loadings/WaveLoading';

interface IModalConfirmCancel {
    open: boolean
    loading: boolean
    onCancel: Function
    onConfirm: Function
}

export const ModalConfirmCancel: React.FC<IModalConfirmCancel> = (props) => {
    const { open, onCancel, onConfirm, loading } = props

    const { theme } = useGlobal()

    const [textConfirm, setTextConfirm] = useState('')

    return (
        <Modal open={open} onClose={() => onCancel()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container theme={theme}>
                {!loading &&
                    <>
                        <ContainerText>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Title theme={theme}>
                                    Você tem certeza disso?
                                </Title>
                                <div onClick={() => onCancel()} style={{ cursor: "pointer" }}>
                                    <TiDelete color={theme === 'dark' ? '#FFF' : inactiveItens.light} size={20} />
                                </div>
                            </div>
                            <Description>
                                Está é uma ação irreversível,
                                cancelando sua assinatura novas
                                cobranças não serão geradas e
                                você perderá acesso a plataforma. Porém
                                seus dados serão mantidos e você poderá
                                reativar sua assinatura a qualquer momento.
                            </Description>
                        </ContainerText>
                        <div style={{ display: "flex", gap: 5, flexDirection: "column", width: "100%", marginBottom: 10 }}>
                            <div>Por favor digite <b>CANCELAR</b> para confirmar</div>
                            <Input onChange={(value: any) => setTextConfirm(value)} style={{ textTransform: "uppercase" }} />
                        </div>
                        <Button
                            disabled={textConfirm.toUpperCase() !== 'CANCELAR'}
                            background={secondary[theme]}
                            color='#FFF'
                            style={{ width: "100%", fontSize: 16 }}
                            onClick={() => onConfirm()}
                        >
                            Eu entendo as consequências, cancelar minha assinatura
                        </Button>
                    </>
                }
                {loading &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 300 }}>
                        <WaveLoading />
                    </div>
                }
            </Container>
        </Modal>
    )
}