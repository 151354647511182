import React, { useEffect } from "react";
import { Container } from "./style";
import { Modal } from "@mui/material";
import { useGlobal } from "../../hooks/global";
import { calculateVH } from "../../services/calculateVH.service";

interface ModalProps {
    open: boolean;
    onClose: Function
    backgroundVisible?: boolean
}

export const ModalGeneric: React.FC<ModalProps> = (props) => {
    const { open, onClose, children, backgroundVisible = false } = props

    const { theme } = useGlobal()

    useEffect(() => { calculateVH() }, [])

    return (
        <Modal BackdropProps={{ invisible: backgroundVisible }} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} open={open} onClose={() => onClose()}>
            <Container theme={theme}>
                {children}
            </Container>
        </Modal>
    )
}