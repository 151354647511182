import React from "react";
import parse from "html-react-parser";
import Button from "../../Buttons/Button";
import { Bag } from "../../Bags/Bag";
import { TiCancel } from "react-icons/ti";
import { SubscriptionName } from "./style";
import { Column, Line } from "../../../style";
import { RiBarcodeFill } from "react-icons/ri";
import { IoCardOutline } from "react-icons/io5";
import { getStatus } from "../../../utils/columnsTables";
import { maskFunctions } from "../../../services/maskServices";
import { correctDate } from "../../../services/generalServices";
import { subscriptionsAttributes, themeTypes } from "../../../interfaces";
import { borderColors, secondary, inactiveItens, primary } from "../../../theme";
import { subscription_situations, subscription_description } from "../../../utils/subscriptionSituantions";

interface ISubscriptionCard {
    subscription: subscriptionsAttributes
    changePlan: Function
    changeCreditCard: Function
    openCancelSubscription: Function
    theme: themeTypes
    isRoot?: boolean
}

function addDays(date: Date, days: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export const SubscriptionCard: React.FC<ISubscriptionCard> = (props) => {
    const { subscription, changePlan, changeCreditCard, openCancelSubscription, theme, isRoot } = props

    const title = "Meu plano atual"
    const description = "Gerencie aqui o seu plano e formas de pagamento"

    return (
        <Bag title={title} description={description} icon={<IoCardOutline color={secondary[theme]} size={26} />}>
            <Line style={{ padding: 16, gap: 10, justifyContent: "space-between", borderBottom: `1px solid ${borderColors[theme]}` }}>
                <Column style={{ gap: 5, flex: 1 }}>
                    <Line style={{ gap: 10 }}>
                        <SubscriptionName theme={theme}>
                            {subscription.plan?.name}
                        </SubscriptionName>
                        {getStatus({
                            color: subscription_situations[subscription.situation].color,
                            text: subscription_situations[subscription.situation].text,
                            theme
                        })}
                    </Line>
                    <div style={{}}>
                        {subscription_description[subscription.situation]} {subscription.situation === "paid-free" ? `Encerrando este período no dia ${correctDate(addDays(subscription?.transactions?.[0]?.createdAt as any, 7), { month_in_extension: true })}` : ""}
                    </div>
                </Column>
                <Column style={{ gap: 5, alignItems: "flex-end", whiteSpace: "nowrap" }}>
                    <div><b>{maskFunctions.currency.mask(subscription.value)}</b> / mês</div>
                    {!isRoot && (
                        <div onClick={() => changePlan()} style={{ color: secondary[theme], cursor: "pointer" }}>
                            {["expired", "canceled"].includes(subscription.situation) ? "Renovar assinatura" : "Alterar plano"}
                        </div>
                    )}
                </Column>
            </Line>
            <Column style={{ padding: 16, gap: 10, borderBottom: !["canceled", "expired"].includes(subscription.situation) ? `1px solid ${borderColors[theme]}` : "none" }}>
                <div style={{ fontWeight: 600 }}>Benefícios do plano</div>
                {parse(subscription.plan?.description as any)}
            </Column>

            {!["canceled", "expired"].includes(subscription.situation) &&
                <>
                    {!!subscription.next_expire_at &&
                        <Column style={{ padding: 16, gap: 10, borderBottom: `1px solid ${borderColors[theme]}` }}>
                            <div style={{ fontWeight: 600 }}>Próxima fatura</div>
                            {correctDate(subscription.next_expire_at, { month_in_extension: true, no_have_time: true })} no valor de {maskFunctions.currency.mask(subscription.value)}
                        </Column>
                    }
                    {subscription.value > 0 &&
                        <Line style={{ padding: 16, gap: 10, alignItems: "center", justifyContent: "space-between", borderBottom: `1px solid ${borderColors[theme]}` }}>
                            <Column style={{ gap: 10 }}>
                                <div style={{ fontWeight: 600 }}>Método de pagamento</div>
                                {subscription.card_mask !== "-" && (
                                    <Line style={{ gap: 5, alignItems: "center" }}>
                                        <IoCardOutline size={20} color={inactiveItens[theme]} />
                                        <div>
                                            <b style={{ textTransform: "capitalize" }}>
                                                {subscription.card_flag}
                                            </b>
                                            ****{subscription.card_mask.replace(/\D/g, "")}
                                        </div>
                                    </Line>
                                )}
                                {subscription.card_mask === "-" && (
                                    <Line style={{ gap: 5, alignItems: "center" }}>
                                        <RiBarcodeFill size={20} color={inactiveItens[theme]} />
                                        <b style={{ textTransform: "capitalize" }}>Boleto Bancário</b>
                                    </Line>
                                )}
                            </Column>
                            {!isRoot && (
                                <div onClick={() => changeCreditCard()} style={{ color: secondary[theme], cursor: "pointer" }}>
                                    Alterar método de pagamento
                                </div>
                            )}
                        </Line>
                    }
                    <Column style={{ padding: 16, gap: 10, alignItems: "flex-end" }}>
                        <div>
                            <Button
                                onClick={() => openCancelSubscription()}
                                background={primary[theme]}
                                borderColor={inactiveItens[theme]}
                                color={inactiveItens[theme]}
                                style={{ alignItems: "center", gap: 5, fontSize: 16 }}
                            >
                                <TiCancel size={20} />
                                <b>Cancelar assinatura</b>
                            </Button>
                        </div>
                    </Column>
                </>
            }
        </Bag>
    )
}