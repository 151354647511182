import { maskFunctions } from "../../../services/maskServices";

function cep(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

function currency(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  if (value !== '') value = maskFunctions.currency.mask(Number.parseInt(value) / 100)
  e.currentTarget.value = value;
  return e;
}

function currencyInt(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  if (value !== '') value = value ? maskFunctions.currencyInt.mask(Number.parseInt(value)) : ""
  e.currentTarget.value = value;
  return e;
}

function cpf(e: React.FormEvent<HTMLInputElement>, haveLimit = true) {
  if (haveLimit) e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
  value = value.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d{1,2})/, '$1-$2')
  value = value.replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

  e.currentTarget.value = value;

  return e;
}

function cnpj(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 18;
  let value = e.currentTarget.value;

  value = value.replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
  value = value.replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
  value = value.replace(/(\d{3})(\d)/, '$1.$2')
  value = value.replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
  value = value.replace(/(\d{4})(\d)/, '$1-$2')

  e.currentTarget.value = value;
  return e
}

function date(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;

  value = value.replace(/\D+/g, '')
  value = maskFunctions.date.mask(value)

  e.currentTarget.value = value;
  return e
}

function dueDate(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 7;
  let value = e.currentTarget.value;

  value = value.replace(/\D+/g, '')
  value = maskFunctions.dueDate.mask(value)

  e.currentTarget.value = value;
  return e
}

function cpfOrCnpj(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  if (value.length > 14) return cnpj(e)
  else return cpf(e, false)
}

function onlyNumber(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;

  value = value.replace(/\D+/g, '')

  e.currentTarget.value = value;

  return e
}

function m2(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, "");
  if (value !== '') value = maskFunctions.m2.mask(Number.parseInt(value) / 100, true)
  e.currentTarget.value = value;

  return e
}

function porcen(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, "");
  if (value !== '') value = maskFunctions.porcen.mask(Number.parseInt(value) / 100, true)
  e.currentTarget.value = value;

  return e
}

function int(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");

  if (value !== '') value = maskFunctions.int.mask(value)
  e.currentTarget.value = value;

  return e
}

function phone(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 16;
  let value = e.currentTarget.value;

  value = value.replace(/\D+/g, '')
  value = maskFunctions.phone.mask(value)

  e.currentTarget.value = value;

  return e
}

function creditCard(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;

  value = value.replace(/\D/g, "");
  value = maskFunctions.creditCard.mask(value)

  e.currentTarget.value = value;

  return e;
}

export const Masks: any = { cep, cpf, cnpj, phone, date, dueDate, cpfOrCnpj, onlyNumber, porcen, int, creditCard, m2, currencyInt, currency }