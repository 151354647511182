import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { useApi } from "./api";
import { useGlobal } from "./global";
import { io, Socket } from "socket.io-client";
import { subscriptionsAttributes, transactionsAttributes, userProps, walletAttributes } from "../interfaces";

interface WalletContextData {
    loading_wallet: boolean;
    loading_transactions_extract: boolean;
    loading_transactions_history: boolean;
    loading_all: boolean;
    set_loading_all: Function;
    wallet: walletAttributes | null;
    subscription: subscriptionsAttributes | null;
    transactions_extract: transactionsAttributes[];
    transactions_history: transactionsAttributes[];
    transactions_subscriptions: transactionsAttributes[];
    num_of_transactions_extract: number;
    num_of_transactions_history: number;
    atual_page_extract: number;
    atual_page_history: number;
    number_of_pages_extract: number;
    number_of_pages_history: number;
    forceSearchAll: Function;
    forceSearchWallet: Function;
    forceSearchTransactionsExtract: Function;
    forceSearchTransactionsHistory: Function;
    updatedAt: Date
    forceClearAll: Function;
}

interface ISearchProps {
    page?: number
    user?: UserInterface
    franchise_id?: string
}

interface UserInterface extends userProps {
    type: "root" | "responsible" | "broker" | "analyst" | "manager"
}

interface ISearchAllProps {
    franchise_id?: string
    user?: UserInterface
}

interface ICanSearchProps {
    franchise_id?: string
    user?: UserInterface
}

const WalletContext = createContext<WalletContextData>({} as WalletContextData)

export const WalletProvider: React.FC = ({ children }) => {

    const [loading_all, set_loading_all] = useState(true)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [socket, setSocket] = useState<Socket | null>(null)
    const [loading_wallet, setLoadingWallet] = useState(true)
    const [atual_page_history, set_atual_page_history] = useState(1)
    const [atual_page_extract, set_atual_page_extract] = useState(1)
    const [wallet, setWallet] = useState<walletAttributes | null>(null)
    const [number_of_pages_extract, set_number_of_pages_extract] = useState(1)
    const [number_of_pages_history, set_number_of_pages_history] = useState(1)
    const [num_of_transactions_history, set_num_of_transactions_history] = useState(0)
    const [num_of_transactions_extract, set_num_of_transactions_extract] = useState(0)
    const [subscription, setSubscription] = useState<subscriptionsAttributes | null>(null)
    const [loading_transactions_extract, set_loading_transactions_extract] = useState(true)
    const [loading_transactions_history, set_loading_transactions_history] = useState(true)
    const [transactions_extract, set_transactions_extract] = useState<transactionsAttributes[]>([])
    const [transactions_history, set_transactions_history] = useState<transactionsAttributes[]>([])
    const [transactions_subscriptions, setTransactionsSubscriptions] = useState<transactionsAttributes[]>([])

    const { api, user } = useApi()
    const { franchise, notify, paginationLimit } = useGlobal()

    const canSearch = useCallback((props: ICanSearchProps) => {
        const { franchise_id, user } = props
        const is_responsible_and_has_franchise = user?.type === "responsible" && franchise_id
        const is_staff = !["responsible", "root"].includes(user?.type ?? "") && user?.type
        return is_staff || (is_responsible_and_has_franchise)
    }, [])

    const searchAll = useCallback(async (props: ISearchAllProps) => {
        const { franchise_id, user } = props
        set_loading_all(true)

        if (canSearch({ franchise_id, user })) {
            try {
                await Promise.all([
                    searchWallet({ franchise_id, user }),
                    searchTransactionsExtract({ page: 1, franchise_id, user }),
                    searchTransactionsHistory({ page: 1, franchise_id, user }),
                    searchSubscritpion({ franchise_id, user })
                ])
                setUpdatedAt(new Date())
            } catch (err) {
                console.log(err)
            }
        }

        set_loading_all(false)
    }, [])

    const forceClearAll = useCallback(() => {
        setWallet(null)
        set_transactions_extract([])
        set_transactions_history([])
        setSubscription(null)
        setTransactionsSubscriptions([])
        set_num_of_transactions_extract(0)
        set_num_of_transactions_history(0)
        set_atual_page_extract(1)
        set_atual_page_history(1)
        set_number_of_pages_extract(1)
        set_number_of_pages_history(1)
    }, [])

    const searchSubscritpion = useCallback(async (props: ISearchProps) => {
        const { franchise_id, user } = props
        if (canSearch({ franchise_id, user })) {
            try {
                const [result_subscription] = await Promise.all([
                    api.get(`/franchises/subscription`)
                ])
                setSubscription(result_subscription?.data?.subscription)
                setTransactionsSubscriptions(result_subscription?.data?.transactions)
                setUpdatedAt(new Date())
            } catch (err) {
                console.log(err)
                notify("Erro ao buscar assinatura da empresa!", "error")
            }
        }
    }, [])

    const searchWallet = useCallback(async (props: ISearchProps) => {
        const { franchise_id, user } = props
        if (canSearch({ franchise_id, user })) {
            setLoadingWallet(true)
            try {
                const result = await api.get(`/franchises/wallet`)
                setWallet(result.data)
                setUpdatedAt(new Date())
            } catch (err) {
                notify("Erro ao buscar carteira", "error")
                console.log(err)
            }
            setLoadingWallet(false)
        }
    }, [])

    const searchTransactionsExtract = useCallback(async (props: ISearchProps) => {
        const { page, franchise_id, user } = props
        if (canSearch({ franchise_id, user })) {
            set_atual_page_extract(page as number)
            set_loading_transactions_extract(true)
            try {
                const result = await api.get(`/franchises/transactions?page=${page}&limit=${paginationLimit}&type=extract`)
                set_transactions_extract(result.data.rows)
                set_num_of_transactions_extract(result.data.count)
                set_number_of_pages_extract(Math.ceil((result.data.count / paginationLimit)))

            } catch (err) {
                notify("Erro ao buscar extrato", "error")
                console.log(err)
            }
            set_loading_transactions_extract(false)
        }
    }, [paginationLimit])

    const searchTransactionsHistory = useCallback(async (props: ISearchProps) => {
        const { page, franchise_id, user } = props
        if (canSearch({ franchise_id, user })) {
            set_atual_page_history(page as number)
            set_loading_transactions_history(true)
            try {
                const result = await api.get(`/franchises/transactions?page=${page}&limit=${paginationLimit}&type=history`)
                set_transactions_history(result.data.rows)
                set_num_of_transactions_history(result.data.count)
                set_number_of_pages_history(Math.ceil((result.data.count / paginationLimit)))

            } catch (err) {
                notify("Erro ao buscar histórico", "error")
                console.log(err)
            }
            set_loading_transactions_history(false)
        }
    }, [paginationLimit])

    const forceSearchAll = useCallback(async () => {
        await searchAll({ franchise_id: franchise?.id, user })
    }, [franchise, user])

    const forceSearchTransactionsExtract = useCallback(async (_page) => {
        await searchTransactionsExtract({ page: _page, franchise_id: franchise?.id, user })
    }, [franchise, user])

    const forceSearchTransactionsHistory = useCallback(async (_page) => {
        await searchTransactionsHistory({ page: _page, franchise_id: franchise?.id, user })
    }, [franchise, user])

    const forceSearchWallet = useCallback(async () => {
        await searchWallet({ franchise_id: franchise?.id, user })
    }, [franchise, user])

    useEffect(() => {
        const socketInstance = io(`${process.env.REACT_APP_API}`)

        setSocket((atual) => {
            atual?.removeAllListeners()
            atual?.disconnect()
            return socketInstance
        })

        if (socketInstance && franchise?.id && user) {
            const franchise_id = franchise?.id
            searchAll({ franchise_id, user }).then(() => {
                socketInstance?.on(`${franchise_id}-wallet`, async () => {
                    await searchAll({ franchise_id, user })
                })
            })
        }
    }, [franchise, user])

    useEffect(() => {
        document.getElementById("nav-sidebar")?.click()
    }, [subscription])

    return (
        <WalletContext.Provider value={{
            forceSearchAll,
            forceSearchTransactionsHistory,
            forceSearchTransactionsExtract,
            forceSearchWallet,
            set_loading_all,
            updatedAt,
            wallet,
            subscription,
            transactions_extract,
            transactions_history,
            transactions_subscriptions,
            num_of_transactions_extract,
            atual_page_extract,
            atual_page_history,
            num_of_transactions_history,
            number_of_pages_extract,
            number_of_pages_history,
            loading_wallet,
            loading_transactions_extract,
            loading_transactions_history,
            loading_all,
            forceClearAll
        }}>
            {children}
        </WalletContext.Provider>
    )
}

export function useWallet(): WalletContextData {
    const context = useContext(WalletContext)
    if (!context) {
        throw new Error("useWallet must be used within an WalletProvider")
    }
    return context;
}