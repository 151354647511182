import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormContractModel } from "./form";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { ModalGeneric } from "../../ModalGeneric";
import { useGlobal } from "../../../hooks/global";
import { contractModelsAttributes } from "../../../interfaces";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";

interface IModalContractModelProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

const texts: any = {
    "title": {
        "new": "Cadastro de Modelo de Documento Eletrônico",
        "edit": "Informações do Modelo de Documento Eletrônico"
    },
    "subTitle": {
        "new": "Informe os dados necessários para criar um novo Modelo de Documento Eletrônico",
        "edit": "Segue abaixo as informações do Modelo de Documento Eletrônico"
    }

}

export const ModalContractModel: React.FC<IModalContractModelProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId = null } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [defaultContractModal, setDefaultContractModal] = useState<contractModelsAttributes | null>(null)

    const searchAnalyst = useCallback(async () => {

        if (defaultId) {

            setLoading(true)

            try {
                const { data } = await api.get(`/contract-models/${defaultId}`)

                setDefaultContractModal({ ...data })

            } catch (err) {

                notify("Erro ao buscar Modelo de Documento Eletrônico", "error")

                onCancel()

            }
        }

        setLoading(false)

    }, [defaultId, api])

    useEffect(() => { searchAnalyst() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }} >
            <Container theme={theme}>
                <ModalHeader
                    title={texts.title[defaultId ? "edit" : "new"]}
                    subTitle={texts.subTitle[defaultId ? "edit" : "new"]}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    <FormContractModel
                        defaultData={defaultContractModal}
                        onSave={onSave}
                        onCancel={onCancel}
                    />
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    )
}