import React, { useRef } from "react";
import { BiSearch } from "react-icons/bi";
import { secondary } from "../../../../theme";
import { useGlobal } from "../../../../hooks/global";
import { Line, LineAllCenter } from "../../../../style";
import { IconButton, Tooltip } from "@material-ui/core";
import { maskFunctions } from "../../../../services/maskServices";
import { correctDate2 } from "../../../../services/generalServices";
import { ContainerObsOrDocs, EmptyText, ObsOrDocsTitle } from "../style";
import { ContainerTitle, ContentTitle, Description, Title } from "./style";
import { FieldList, IFieldProps, IListFieldsProps } from "../../../FiedlList";
import { documentsAttributes, personsAttributes } from "../../../../interfaces";
import { accountTypesOptions, banksOptions, get_corrrect_names, maritalStatusOptions, paymentTypesOptions, personsTypesOptions } from "../../../../utils/options";

interface ICustomerData {
    customer?: personsAttributes
    documents?: documentsAttributes[] | null | undefined
}

export const CustomerData: React.FC<ICustomerData> = (props) => {
    const { customer, documents } = props

    const { theme } = useGlobal()

    const anchor_refs = useRef<HTMLAnchorElement[]>([])

    const FieldsDataPF: IFieldProps[] = [
        {
            label: "Tipo",
            keys: ["registration_data", "type"],
            transform: get_corrrect_names(personsTypesOptions)
        },
        {
            label: "Nome",
            keys: ["registration_data", "name"],
        },
        {
            label: "E-mail",
            keys: ["user", "email"]
        },
        {
            label: "Telefone",
            keys: ["registration_data", "phone"],
            mask: maskFunctions.phone.mask
        },
        {
            label: "Data de Nascimento",
            keys: ["registration_data", "birth"],
            mask: correctDate2
        },
        {
            label: "CPF",
            keys: ["registration_data", "cpf"],
            mask: maskFunctions.cpf.mask,
        },
        {
            label: "RG",
            keys: ["registration_data", "rg"],
        },
        {
            label: "Orgão Emissor",
            keys: ["registration_data", "issuing_body"],
        },
        {
            label: "Nacionalidade",
            keys: ["registration_data", "nationality"],
        },
        {
            label: "Profissão",
            keys: ["registration_data", "profession"],
        },
        {
            label: "Estado Civil",
            keys: ["registration_data", "marital_status"],
            transform: get_corrrect_names(maritalStatusOptions),
            only: (person: personsAttributes) => person.registration_data?.type === "PF"
        },
        {
            label: "Cônjuge",
            keys: ["spouse"],
            is_person: true,
            can_show: (value: any) => ["married", "stable_union"].includes(value.registration_data.marital_status)
        },
        {
            label: "Representantes",
            keys: ["representatives"]
        }
    ]

    const FieldsDataPJ: IFieldProps[] = [
        {
            label: "Tipo",
            keys: ["registration_data", "type"],
            transform: get_corrrect_names(personsTypesOptions)
        },
        {
            label: "Razão Social",
            keys: ["registration_data", "corporate_name"],
        },
        {
            label: "Nome de fantasia",
            keys: ["registration_data", "fantasy_name"],
        },
        {
            label: "E-mail",
            keys: ["user", "email"]
        },
        {
            label: "Telefone",
            keys: ["registration_data", "phone"],
            mask: maskFunctions.phone.mask
        },
        {
            label: "CNPJ",
            keys: ["registration_data", "cnpj"],
            mask: maskFunctions.cnpj.mask
        },
        {
            label: "Inscrição estadual",
            keys: ["registration_data", "state_registration"]
        },
        {
            label: "Inscrição municipal",
            keys: ["registration_data", "municipal_registration"]
        },
        {
            label: "Representantes",
            keys: ["representatives"]
        }
    ]

    const FieldsAddress: IFieldProps[] = [
        {
            label: "CEP",
            keys: ["address", "cep"],
            mask: maskFunctions.cep.mask
        },
        {
            label: "Estado",
            keys: ["address", "state", "initials"]
        },
        {
            label: "Cidade",
            keys: ["address", "city", "name"]
        },
        {
            label: "Bairro",
            keys: ["address", "district"]
        },
        {
            label: "Logradouro",
            keys: ["address", "public_place"]
        },
        {
            label: "Complemento",
            keys: ["address", "complement"]
        },
        {
            label: "Número",
            keys: ["address", "number"]
        }
    ]

    const FieldsPayment: IFieldProps[] = [
        {
            label: "Recebimento padrão",
            keys: ["payment", "type"],
            transform: get_corrrect_names(paymentTypesOptions)
        },
        {
            label: "Banco",
            keys: ["payment", "bank_code"],
            transform: get_corrrect_names(banksOptions),
            can_show: (person: personsAttributes) => !["RD", "RC"].includes(person?.payment?.type as any)
        },
        {
            label: "Agência",
            keys: ["payment", "agency"],
            can_show: (person: personsAttributes) => !["RD", "RC"].includes(person?.payment?.type as any)
        },
        {
            label: "Conta",
            keys: ["payment", "account"],
            can_show: (person: personsAttributes) => !["RD", "RC"].includes(person?.payment?.type as any)
        },
        {
            label: "Tipo de conta",
            keys: ["payment", "account_type"],
            transform: get_corrrect_names(accountTypesOptions),
            can_show: (person: personsAttributes) => !["RD", "RC"].includes(person?.payment?.type as any)
        },
        {
            label: "Operação",
            keys: ["payment", "operation"],
            can_show: (person: personsAttributes) => !["RD", "RC"].includes(person?.payment?.type as any)
        },
        {
            label: "Em nome de",
            keys: ["payment", "in_name"],
            can_show: (person: personsAttributes) => !["RD"].includes(person?.payment?.type as any)
        },
        {
            label: "CPF ou CNPJ",
            keys: ["payment", "cpf_cnpj"],
            mask: maskFunctions.cpfOrCnpj.mask,
            can_show: (person: personsAttributes) => !["RD"].includes(person?.payment?.type as any)
        }
    ]

    const ListFields: IListFieldsProps[] = [
        {
            title: "Dados de Registro",
            getFields: (person: personsAttributes) => person.registration_data?.type === "PF" ? FieldsDataPF : FieldsDataPJ
        },
        {
            title: "Dados de Endereço",
            fields: FieldsAddress
        },
        {
            title: "Dados de Recebimento",
            fields: FieldsPayment
        }
    ]

    return (
        <>
            <FieldList
                theme={theme}
                data={customer}
                listFields={ListFields}
            />

            <ContainerObsOrDocs theme={theme}>

                <ObsOrDocsTitle>DOCUMENTAÇÃO</ObsOrDocsTitle>

                {documents?.map((document, index) => (
                    <ContainerTitle theme={theme} haveBorder={documents.length > 1 && index !== (documents.length - 1)}>

                        <ContentTitle>
                            <Title>{document.name}</Title>
                            <Line>
                                <Description theme={theme}>
                                    {document.document_type?.name}
                                </Description>
                            </Line>
                        </ContentTitle>

                        <LineAllCenter>
                            <Tooltip onClick={() => anchor_refs.current[index].click()} style={{ height: 40, width: 40 }} title="Visualizar">
                                <IconButton aria-label="Visualizar">
                                    <LineAllCenter>
                                        <BiSearch
                                            size={19}
                                            color={secondary[theme]}
                                        />
                                    </LineAllCenter>
                                </IconButton>
                            </Tooltip>
                        </LineAllCenter>

                        <a
                            style={{ display: "none" }}
                            target="_blank"
                            href={`${process.env.REACT_APP_S3_URL}/${document.name}`}
                            ref={(ref: any) => anchor_refs.current[index] = ref}
                        />
                    </ContainerTitle>
                ))}

                {documents?.length === 0 && (
                    <EmptyText style={{ padding: 10 }}>
                        Nenhum documento encontrado
                    </EmptyText>
                )}

            </ContainerObsOrDocs>
        </>
    )
}