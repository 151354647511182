import styled, { css } from "styled-components";
import { Column, Line } from "../../../style";
import { primary, borderColors } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const Container = styled(Column)<IThemeProps>`
    page-break-before: always; 
    border-radius: 4px;
    background: ${props => primary[props.theme as themeTypes]}; 
    border: 1px solid ${props => borderColors[props.theme as themeTypes]};
`

export const ContainerTitle = styled(Line) <{ everOpen: boolean }>`
    padding: 16px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    cursor: ${props => props.everOpen ? "default" : "pointer"};
`

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
`

export const Description = styled.div`
    font-size: 12px;
    margin-top: -3px;
    text-transform: uppercase;
`

export const ContainerArrow = styled.div<{ open: boolean }>`
    cursor: pointer;
    transition: all 0.3s;
    ${props => props.open && css`
        transform: rotate(90deg);
    `}
`

export const ContainerContent = styled.div<{ open: boolean, everOpen: boolean, height: number }>`
    position: relative;
    overflow: hidden;
    height: ${props => props.open ? props.height : 0}px;
    transition: ${props => props.everOpen ? "all 0s" : "all 0.3s"};
`

export const Content = styled(Column) <IThemeProps>`
    position: absolute;
    top: 0;
    width: 100%;
    border-top: 1px solid ${props => borderColors[props.theme as themeTypes]};
`