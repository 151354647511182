import React from "react";
import { Perfil } from "../pages/Perfil";
import { Wallet } from "../pages/Wallet";
import { PlansList } from "../pages/Plans";
import { NotPaid } from "../pages/NotPaid";
import { UsersList } from "../pages/Users";
import { QueryPDF } from "../pages/QueryPDF";
import { QueryList } from "../pages/Queries";
import { PrivateRoute } from "./privateRoute";
import { PersonsList } from "../pages/Persons";
import { Dashboard } from "../pages/Dashboard";
import { ProposalList } from "../pages/Proposals";
import { WalletRoot } from "../pages/Root/Wallet";
import { CompanyData } from "../pages/CompanyData";
import { ContractsList } from "../pages/Contracts";
import { PropertyList } from "../pages/Properties";
import { FranchisesList } from "../pages/Franchises";
import { Subscription } from "../pages/Subscription";
import { IntegrationsList } from "../pages/Integrations";
import { ResponsiblesList } from "../pages/Responsibles";
import { ChooseFranchise } from "../pages/ChooseFranchise";
import { OpportunitiesList } from "../pages/Opportunities";
import { ContractModelsList } from "../pages/ContractModels";
import { CreditPoliciesList } from "../pages/CreditPolicies";
import { DocumentsTypesList } from "../pages/DocumentsTypes";
import { SubscriptionRoot } from "../pages/Root/Subscription";
import { PropertiesTypesList } from "../pages/PropertiesTypes";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import { FranchiseCreditPolicies } from "../pages/FranchiseCreditPolicies";

export const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        path="/"
        element={
          <PrivateRoute
            canAcess={["responsible", "manager", "broker", "analyst", "root"]}
          />
        }
      >
        <Route index element={<Dashboard />} />
      </Route>

      <Route path="query-pdf/:query_id" element={<QueryPDF />} />

      <Route
        path="billing"
        element={
          <PrivateRoute redirectTo="/not-paid" canAcess={["responsible"]} />
        }
      >
        <Route index element={<Navigate to="/billing/subscription" />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="*" element={<Navigate to="/billing/subscription" />} />
      </Route>

      <Route
        path="not-paid"
        element={
          <PrivateRoute
            redirectTo="/subscription"
            canAcess={["manager", "analyst", "broker"]}
          />
        }
      >
        <Route index element={<NotPaid />} />
      </Route>

      <Route path="responsibles" element={<PrivateRoute canAcess={["root"]} />}>
        <Route index element={<ResponsiblesList />} />
      </Route>

      <Route path="real_estate" element={<PrivateRoute canAcess={["root"]} />}>
        <Route index element={<FranchisesList />} />
        <Route path="subscription" element={<SubscriptionRoot />} />
        <Route path="wallet" element={<WalletRoot />} />
        <Route path="persons" element={<PersonsList />} />
        <Route path="queries" element={<QueryList />} />
        <Route path="proposals" element={<ProposalList />} />
        <Route path="properties" element={<PropertyList />} />
        <Route path="documents-electronic" element={<ContractsList />} />
        <Route path="*" element={<Navigate to="/real_estate" />} />
      </Route>

      <Route
        path="persons"
        element={
          <PrivateRoute
            canAcess={["root", "responsible", "manager", "broker", "analyst"]}
          />
        }
      >
        <Route index element={<PersonsList />} />
      </Route>

      <Route
        path="choose_the_real_estate"
        element={<PrivateRoute chooseFranchise canAcess={["responsible"]} />}
      >
        <Route index element={<ChooseFranchise />} />
      </Route>

      <Route
        path="credit-policies"
        element={<PrivateRoute canAcess={["root"]} />}
      >
        <Route index element={<CreditPoliciesList />} />
      </Route>

      <Route
        path="proposals"
        element={
          <PrivateRoute
            canAcess={["root", "responsible", "broker", "analyst", "manager"]}
          />
        }
      >
        <Route index element={<ProposalList />} />
      </Route>

      <Route
        path="opportunities"
        element={<PrivateRoute canAcess={["analyst"]} />}
      >
        <Route index element={<OpportunitiesList />} />
      </Route>

      <Route
        path="documents-electronic"
        element={
          <PrivateRoute
            canAcess={["root", "analyst", "responsible", "broker", "manager"]}
          />
        }
      >
        <Route index element={<ContractsList />} />
      </Route>

      <Route
        path="settings"
        element={<PrivateRoute canAcess={["responsible", "root", "manager"]} />}
      >
        <Route index element={<Navigate to="/" />} />
        <Route path="properties_types" element={<PropertiesTypesList />} />
        <Route path="documents_types" element={<DocumentsTypesList />} />

        <Route
          path="real_estate"
          element={<PrivateRoute canAcess={["responsible", "manager"]} />}
        >
          <Route
            index
            element={<Navigate to="/settings/real_estate/users" />}
          />
          <Route
            path="users"
            element={<PrivateRoute canAcess={["responsible"]} />}
          >
            <Route index element={<UsersList />} />
          </Route>
          <Route
            path="integrations"
            element={<PrivateRoute canAcess={["responsible"]} />}
          >
            <Route index element={<IntegrationsList />} />
          </Route>
          <Route
            path="company-data"
            element={<PrivateRoute canAcess={["responsible"]} />}
          >
            <Route index element={<CompanyData />} />
          </Route>
          <Route
            path="credit-policies"
            element={<PrivateRoute canAcess={["responsible"]} />}
          >
            <Route index element={<FranchiseCreditPolicies />} />
          </Route>
          <Route
            path="models-documents-electronic"
            element={<PrivateRoute canAcess={["responsible", "manager"]} />}
          >
            <Route index element={<ContractModelsList />} />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/settings/real_estate/users" />}
          />
        </Route>
      </Route>

      <Route
        path="properties"
        element={
          <PrivateRoute
            canAcess={["responsible", "broker", "manager", "analyst"]}
          />
        }
      >
        <Route index element={<PropertyList />} />
      </Route>

      <Route
        path="perfil"
        element={
          <PrivateRoute
            canAcess={[
              "analyst",
              "broker",
              "customer",
              "responsible",
              "manager",
            ]}
          />
        }
      >
        <Route index element={<Perfil />} />
      </Route>

      <Route
        path="queries"
        element={<PrivateRoute canAcess={["responsible", "manager", "root"]} />}
      >
        <Route index element={<QueryList />} />
      </Route>

      <Route path="plans" element={<PrivateRoute canAcess={["root"]} />}>
        <Route index element={<PlansList />} />
      </Route>

      <Route path="*" element={<Navigate to="" />} />
    </Switch>
  );
};
