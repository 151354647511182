import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { info, success } from "../../theme";
import { io, Socket } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../../hooks/global";
import { MdOutlineAnalytics } from "react-icons/md";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { ROOT_USERS } from "../../utils/users.groups";
import { MANAGERS_USERS } from "../../utils/users.groups";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { ModalQuery } from "../../components/Modals/ModalQuery";
import { IActions, IFilters, queriesAttributes } from "../../interfaces";
import { ModalResultQuery } from "../../components/Modals/ModalQuery/Result";
import { getAuthor, getFranchise, getStatus } from "../../utils/columnsTables";
import { correctDate, setUpConsultation } from "../../services/generalServices";

const correct_type = {
    "PF": "Pessoa Física",
    "PJ": "Pessoa Jurídica"
}

const queires_status: any = {
    "waiting": { color: info, text: "Aguardando Birô" },
    "finished": { color: success, text: "Finalizado" },
}

const initialFilters: IFilters[] = [
    getFilterFields("franchise_id", ROOT_USERS),
    getFilterFields("query_author_id", MANAGERS_USERS),
    getFilterFields("type_consult", MANAGERS_USERS),
    getFilterFields("date_range", MANAGERS_USERS),
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "cpf_cnpj", name: "CPF/CNPJ", style: {}, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "type", name: "Tipo de consulta", style: { whiteSpace: "nowrap" }, styleContent: {} },
        { attr: "target", name: "Alvo", style: {}, limitWidth: true, styleContent: { fontSize: 14, fontWeight: 600 } },
        { attr: "author", name: "Autor da consulta", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "status", name: "Status", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "createdAt", name: "Realizada em", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } }
    ]
}

const initialTableRoot: ITable = {
    data: [],
    columns: [
        { attr: "cpf_cnpj", name: "CPF/CNPJ", style: {}, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "type", name: "Tipo de consulta", style: { whiteSpace: "nowrap" }, styleContent: {} },
        { attr: "target", name: "Alvo", style: {}, limitWidth: true, styleContent: { fontSize: 14, fontWeight: 600 } },
        { attr: "author", name: "Autor da consulta", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "franchise", name: "Imobiliária", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "status", name: "Status", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "createdAt", name: "Realizada em", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } }
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: {
            text: "Políticas de análise",
            id: "credit-policies"
        },
        className: "credit-policies",
        icon: <MdOutlineAnalytics size={20} className="credit-policies" />,
        options: [],
        type: "outline",
        canAccess: ["responsible"]
    },
    {
        main_option: {
            text: "Nova Consulta",
            id: "new_item"
        },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid",
        canAccess: ["responsible", "manager"]
    }
]

const getTargetName = (query: queriesAttributes) => {
    return (
        <>
            {(query.result_biro && query.result_biro.BestInfo && (query.result_biro.BestInfo.PersonName || query.result_biro.BestInfo.CompanyName)) ? (query.type === "PF" ? query.result_biro.BestInfo.PersonName.Name.Full : query.result_biro.BestInfo.CompanyName) : "--"}
        </>
    )
}

export const QueryList: React.FC = () => {
    const navigate = useNavigate()

    const { api, token, user } = useApi()
    const { theme, paginationLimit, notify, franchise } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [numOfqueries, setNumOfqueries] = useState(0)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [socket, setSocket] = useState<Socket | null>(null)
    const [loadingqueries, setLoadingqueries] = useState(true)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [query_selected, set_query_selected] = useState<queriesAttributes | null>(null)
    const [table, setTable] = useState<ITable>(user.type === "root" ? initialTableRoot : initialTable)

    const searchQueries = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingqueries(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }

            let period: any = {}
            period.date_init = filters_obj.date_init ? filters_obj.date_init : null
            period.date_end = filters_obj.date_end ? filters_obj.date_end : null
            if (!period.date_init || !period.date_end) period = undefined
            else period = JSON.stringify([period.date_init, period.date_end])

            filters_obj.period = period

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/queries?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            for (let i = 0; i < result.data.rows.length; i++) {
                result.data.rows[i].result_biro = JSON.parse(result?.data?.rows[i]?.result_biro)
            }
            const new_queries = result.data.rows as queriesAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_queries.forEach((query) => {
                    new_table.data.push({
                        id: query.id,
                        cpf_cnpj: maskFunctions.cpfOrCnpj.mask(query.cpf_cnpj),
                        type: correct_type[query.type],
                        target: getTargetName(query),
                        status: getStatus({ ...queires_status[query.status], theme }),
                        author: getAuthor({ person: query, theme }),
                        franchise: getFranchise({ person: query, theme }),
                        createdAt: correctDate(query.createdAt),
                        this: query
                    })
                })
                return { ...new_table }
            })

            setNumOfqueries(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelas consultas!", "error")
        }
        setLoadingqueries(false)
    }, [franchise, filters, searchString])

    const onSavequeries = useCallback(() => {
        setOpenModal(false)
        searchQueries(1)
    }, [franchise, filters, searchString])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") setOpenModal(true)
        else if (action_id === "credit-policies") navigate("/settings/real_estate/credit-policies")
        else console.log({ action_id })
    }

    useEffect(() => {
        if (!socket) {
            const socketInstance = io(`${process.env.REACT_APP_API}`)
            setSocket(socketInstance)
            socketInstance?.on(`query_franchise_${franchise.id}`, () => searchQueries(1))
        }
        else {
            socket.removeAllListeners()
            socket.on(`query_franchise_${franchise.id}`, () => searchQueries(1))
        }
    }, [franchise, socket, filters, searchString])

    useEffect(() => { searchQueries(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingqueries}
            setLoading={setLoadingqueries}
            numberOfItems={numOfqueries}
            textHeader="Consultas Cadastrais"
            textItems="consultas cadastrais encontradas"
            text_loading={"Buscando Consultas Cadastrais"}
            text_empty={"Nenhuma consulta cadastral encontrada"}
            theme={theme}
            search={searchQueries}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            actions={actionsInitial}
            table={table}
            have_edit={true}
            canEdit={(query) => query.status !== "waiting"}
            edit_action={(query: any) => set_query_selected({ ...query })}
            pages={numberOfPages}
            page={atualPage}
            onClickOption={handleAction}
            onChangeSearch={setSearchString}
            have_download
            getUrlDownload={(query) => `${process.env.REACT_APP_API_PDF_URL}/pdf?query_id=${query.id}&franchise_id=${franchise?.id}&token=${token}`}
            canDownload={(query) => query.status === "finished"}
        >
            {openModal &&
                <ModalQuery
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSavequeries()}
                    openModal={openModal}
                    backgroundVisible={false}
                />
            }
            {query_selected &&
                <ModalResultQuery
                    onCancel={() => set_query_selected(null)}
                    openModal={!!query_selected}
                    backgroundVisible={false}
                    defaultId={query_selected.id}
                />
            }
        </PageList>
    )
}