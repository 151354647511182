import Button from "../../../../Buttons/Button";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { uniqueId } from "lodash";
import { FiAlertCircle } from "react-icons/fi";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { ModalConsults } from "../../../ModalConsults";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IconButton, Tooltip } from "@material-ui/core";
import { useGlobal } from "../../../../../hooks/global";
import { ModalAttention } from "../../../ModalAttention";
import { BiDislike, BiLike, BiSearch } from "react-icons/bi";
import { getPersonLabel } from "../../../../../utils/labels";
import { ModalResultQuery } from "../../../ModalQuery/Result";
import { getStatus } from "../../../../../utils/columnsTables";
import { ModalLoading } from "../../../../Loadings/ModalLoading";
import { ModalDetailsAnalysis } from "../../../ModalDetailsAnalysis";
import { maskFunctions } from "../../../../../services/maskServices";
import { queries_status } from "../../../../../utils/queries_status";
import { correctDate } from "../../../../../services/generalServices";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { background, borderColors, inactiveItens, secondary } from "../../../../../theme";
import { get_corrrect_names, consultsOptionsRelations } from "../../../../../utils/options";
import { customers_proposalAttributes, proposalsAttributes } from "./../../../../../interfaces/index";

export interface IStepRef {
    validate: () => boolean
}

interface IQueriesStep {
    defaultId: string
    loading: boolean
    control: number
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
    consults: customers_proposalAttributes[]
    setConsults: (consults: customers_proposalAttributes[]) => void
}

const Title: React.FC<{ reason: any }> = ({ reason }) => {
    return (
        <>
            {reason.map((item: any, index: number) => (
                <>
                    {index + 1}. {item}
                    <br />
                </>
            ))}
        </>
    )
}

const QueriesStepComponent: React.ForwardRefRenderFunction<IStepRef, IQueriesStep> = (props, ref) => {
    const { defaultId, control, setUpdatedAt, consults, setConsults, loading, setLoading, changeProposal } = props

    const { user, api } = useApi()
    const { theme, notify } = useGlobal()

    const [openModalEdit, setOpenModalEdit] = useState(false)
    const [loadingChange, setLoadingChange] = useState(false)
    const [consult_selected, set_consult_selected] = useState<customers_proposalAttributes | null>(null)
    const [consult_new_selected, set_consult_new_selected] = useState<customers_proposalAttributes | null>(null)
    const [consult_reject_selected, set_consult_reject_selected] = useState<customers_proposalAttributes | null>(null)
    const [consult_analysis_details, set_consult_analysis_details] = useState<customers_proposalAttributes | null>(null)
    const [consult_approve_selected, set_consult_approve_selected] = useState<customers_proposalAttributes | null>(null)
    const [defaultData, setDefaultData] = useState<proposalsAttributes | null>(null)
    const [defaultConsults, setDefaultConsults] = useState<any[]>([])

    const searchProposal = useCallback(async () => {
        if (defaultId) {
            setLoading(true)
            try {
                const { data } = await api.get(`/proposals/queries/${defaultId}`)

                const _defaultConsults = data.map((c: any) => {
                    const label = getPersonLabel({ person: c.customer, haveCpfOrCnpj: true })
                    const value = c.customer_id
                    const id = c.id
                    const relation = consultsOptionsRelations.find((t) => t.value === c?.relation)
                    const _uniqueId = uniqueId()
                    const customer_id = { label, value, this: c.customer }

                    return ({ id, customer_id, relation, uniqueId: _uniqueId })
                })

                const _defaultData: any = { id: defaultId }

                for (const consult of _defaultConsults) {
                    _defaultData[`customer_id-${consult.uniqueId}`] = consult.customer_id
                    _defaultData[`relation-${consult.uniqueId}`] = consult.relation
                }

                setDefaultData({ ..._defaultData })

                setDefaultConsults([..._defaultConsults])

                setConsults([...data])

            } catch (err) {
                notify("Erro ao buscar consultas da proposta", "error")
            }
        }
        setLoading(false)
    }, [defaultId])

    const approveCustomer = useCallback(async (id: string) => {
        setLoadingChange(true)
        try {
            await api.post(`/proposals/customer/approve/${id}`)
            setUpdatedAt(new Date())
            set_consult_approve_selected(null)
            notify("Pessoa aprovado com sucesso", "success")
            changeProposal(1)
            searchProposal()
        } catch (err) {
            set_consult_approve_selected(null)
            notify("Erro ao aprovar pessoa", "error")
        }
        setLoadingChange(false)
    }, [defaultId])

    const rejectCustomer = useCallback(async (id: string) => {
        setLoadingChange(true)
        try {
            await api.post(`/proposals/customer/reject/${id}`)
            setUpdatedAt(new Date())
            set_consult_reject_selected(null)
            notify("Pessoa rejeitada com sucesso", "success")
            changeProposal(1)
            searchProposal()
        } catch (err) {
            set_consult_reject_selected(null)
            notify("Erro ao rejeitar pessoa", "error")
        }
        setLoadingChange(false)
    }, [defaultId])

    const consultCustomer = useCallback(async (id: string) => {
        setLoadingChange(true)
        try {
            await api.post(`/proposals/customer/consult/${id}`)
            set_consult_new_selected(null)
            notify("Pessoa consultada com sucesso", "success")
            searchProposal()
        } catch (err: any) {
            const message = err?.response?.data?.message === 'CPF or CNPJ not found' ? "ESTRANGEIROS não podem ser consultados" : "Erro ao consultar pessoa"
            set_consult_new_selected(null)
            notify(message, err?.response?.data?.message === 'CPF or CNPJ not found' ? "alert" : "error")
        }
        setLoadingChange(false)
    }, [defaultId])

    const saveCustomerConsults = useCallback(() => {
        setUpdatedAt(new Date())
        changeProposal(1)
        setOpenModalEdit(false)
        searchProposal()
    }, [])

    const validate = useCallback(() => {
        return !consults.find((item) => ["pending", "not_search", "waiting", "rejected_by_biro", "rejected_by_responsible"].includes(item.status))
    }, [consults])

    useImperativeHandle(ref, () => ({ validate }))

    useEffect(() => { searchProposal() }, [control])

    return (
        <Column style={{ gap: 20, flex: 1 }}>
            <ContentOrLoading type="wave" loading={loading} theme={theme}>
                <>
                    <Column>
                        <div style={{ fontWeight: 600, color: secondary[theme], fontSize: 20 }}>
                            Lista de Consultas Cadastrais
                        </div>
                        <div>
                            Confira relatório completo com análise de risco e histórico de crédito
                        </div>
                    </Column>
                    <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                        <table>
                            <thead style={{ background: background[theme], fontSize: 12 }}>
                                <tr>
                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>CPF/CNPJ</th>
                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>NOME/RAZÃO SOCIAL</th>
                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>TIPO</th>
                                    <th style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>STATUS</th>
                                    <th style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>CONSULTADO EM</th>
                                    {["responsible", "manager"].includes(user.type) &&
                                        <th style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>AÇÕES</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {consults.map((consult) => {
                                    const document = consult?.customer?.registration_data?.type === "PF" ? consult?.customer?.registration_data?.cpf : consult?.customer?.registration_data?.cnpj;

                                    return (
                                        <tr>
                                            <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>
                                                        {document ? maskFunctions.cpfOrCnpj.mask(document) : 'ESTRANGEIRO'}
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ padding: 10, whiteSpace: "nowrap", textTransform: "uppercase" }}>
                                                {consult?.customer?.registration_data?.type === "PF" ? (consult?.customer.registration_data?.name ? consult?.customer.registration_data?.name : "Não informado") : (consult?.customer?.registration_data?.fantasy_name ? consult?.customer.registration_data?.fantasy_name : "Não informado")}
                                            </td>
                                            <td style={{ padding: 10, whiteSpace: "nowrap", textTransform: "uppercase" }}>
                                                {get_corrrect_names(consultsOptionsRelations)[consult.relation]}
                                            </td>
                                            <td style={{ padding: 10, whiteSpace: "nowrap", textTransform: "uppercase" }}>
                                                <div style={{ display: "flex", gap: 5, alignItems: "center", justifyContent: "center" }}>
                                                    {getStatus({ ...queries_status[consult.status] })}
                                                    {consult.reason.length > 0 &&
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: -3 }}>
                                                            <Tooltip onClick={() => ["responsible", "manager"].includes(user.type) ? set_consult_analysis_details(consult) : {}} placement="top" title={<Title reason={consult.reason} />} style={{ padding: 0, margin: 0, height: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <IconButton>
                                                                    <div>
                                                                        <FiAlertCircle size={19} color={inactiveItens[theme]} opacity={0.7} />
                                                                    </div>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center", textTransform: "uppercase" }}>{consult?.query?.createdAt ? correctDate(consult?.query?.createdAt) : "--"}</td>
                                            {["responsible", "manager"].includes(user.type) &&
                                                <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                    <Line style={{ justifyContent: "center" }}>
                                                        {consult.query && consult.query.result_biro &&
                                                            <Tooltip onClick={() => set_consult_selected(consult)} title="Visualizar" style={{ minHeight: 40, maxHeight: 40, minWidth: 40, maxWidth: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <IconButton aria-label="Visualizar">
                                                                    <div>
                                                                        <MdOutlineRemoveRedEye size={19} color={secondary[theme]} />
                                                                    </div>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {!consult.query_id &&
                                                            <Tooltip onClick={() => set_consult_new_selected(consult)} title="Consultar" style={{ minHeight: 40, maxHeight: 40, minWidth: 40, maxWidth: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <IconButton aria-label="Consultar">
                                                                    <div>
                                                                        <BiSearch size={19} color={secondary[theme]} />
                                                                    </div>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {["waiting", "pending", "rejected_by_biro", "rejected_by_responsible", "not_search"].includes(consult.status) &&
                                                            <Tooltip onClick={() => set_consult_approve_selected(consult)} style={{ minHeight: 40, maxHeight: 40, minWidth: 40, maxWidth: 40, display: "flex", justifyContent: "center", alignItems: "center" }} title="Aprovar">
                                                                <IconButton aria-label="Aprovar">
                                                                    <div>
                                                                        <BiLike size={19} color={secondary[theme]} />
                                                                    </div>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {["waiting", "pending", "approved_by_biro", "approved_by_responsible", "not_search"].includes(consult.status) &&
                                                            <Tooltip onClick={() => set_consult_reject_selected(consult)} style={{ minHeight: 40, maxHeight: 40, minWidth: 40, maxWidth: 40, display: "flex", justifyContent: "center", alignItems: "center" }} title="Reprovar">
                                                                <IconButton aria-label="Reprovar">
                                                                    <div>
                                                                        <BiDislike size={19} color={secondary[theme]} />
                                                                    </div>
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </Line>
                                                </td>
                                            }

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Column>
                    <Button onClick={() => setOpenModalEdit(true)} style={{ maxWidth: 200 }} color="#FFF" background={secondary[theme]}>Gerenciar consultas</Button>
                </>
            </ContentOrLoading>

            {consult_selected &&
                <ModalResultQuery
                    onCancel={() => set_consult_selected(null)}
                    openModal={!!consult_selected}
                    defaultId={consult_selected.query_id}
                />
            }

            {consult_analysis_details && (
                <ModalDetailsAnalysis
                    executors={JSON.parse(JSON.parse(consult_analysis_details.credit_policy as any).executors as any)}
                    onCancel={() => set_consult_analysis_details(null)}
                    openModal
                    defaultQuery={consult_analysis_details.query}
                    type={consult_analysis_details.customer?.registration_data?.type as any}
                />
            )}

            {loadingChange && (
                <ModalLoading
                    loading={loadingChange}
                    theme={theme}
                />
            )}

            <ModalAttention
                theme={theme}
                open={!!consult_approve_selected && !loadingChange}
                cancelAction={() => { set_consult_approve_selected(null) }}
                confirmAction={() => approveCustomer(consult_approve_selected?.id as string)}
                content={(
                    <>
                        Deseja realmente <b>APROVAR</b> a pessoa <b>{consult_approve_selected?.customer?.registration_data?.name}</b> ?
                    </>
                )}
            />

            <ModalAttention
                theme={theme}
                open={!!consult_reject_selected && !loadingChange}
                cancelAction={() => { set_consult_reject_selected(null) }}
                confirmAction={() => rejectCustomer(consult_reject_selected?.id as string)}
                content={(
                    <>
                        Deseja realmente <b>REPROVAR</b> a pessoa <b>{consult_reject_selected?.customer?.registration_data?.name}</b> ?
                    </>
                )}
            />

            <ModalAttention
                theme={theme}
                open={!!consult_new_selected && !loadingChange}
                cancelAction={() => { set_consult_new_selected(null) }}
                confirmAction={() => consultCustomer(consult_new_selected?.id as string)}
                content={(
                    <>
                        Deseja realmente <b>CONSULTAR</b> a pessoa <b>{consult_new_selected?.customer?.registration_data?.name}</b> ?
                    </>
                )}
            />

            {openModalEdit &&
                <ModalConsults
                    onCancel={() => { setOpenModalEdit(false) }}
                    onSave={saveCustomerConsults}
                    defaultData={defaultData}
                    defaultConsults={defaultConsults}
                />
            }

        </Column>
    )
}

export const QueriesStep = forwardRef(QueriesStepComponent)