import React from "react"

export const DocAdd = ({ size, color, ...props }: any) => (
  <svg
    width={18}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 16h-1v-1a1 1 0 0 0-2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2Zm-7 2H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v3a1 1 0 0 0 2 0V6.94a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.29.29 0 0 0-.1 0A1.1 1.1 0 0 0 9.06 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h7a1 1 0 0 0 0-2Zm0-14.59L12.59 6H11a1 1 0 0 1-1-1V3.41ZM5 6a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H5Zm5 8H5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2Zm1-4H5a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2Z"
      fill={color}
    />
  </svg>
)
