import InputWithLogo, { InputWithLogoRefProps } from "../Inputs/InputWithLogo";
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react"
import { BiSearch } from "react-icons/bi";
import { inactiveItens } from "../../theme";
import { IFilters } from "../../interfaces";
import { ModalFilters } from "./ModalFilters";
import { useGlobal } from "../../hooks/global";
import { IoFilterSharp } from "react-icons/io5";
import { ContainerFiltersNumber } from "./style";
import { valueIsEmpty } from "../../services/generalServices";

const debounceEvent = () => {
    let timer: any = null
    return (fn: any, wait: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => fn(), wait)
    }
}

const debounce = debounceEvent()

export interface ISearchWithFiltersProps {
    loading: boolean
    initialFilters: IFilters[]
    clickButtonSearch: Function
    onChangeSearch: (value: string) => Promise<void>
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export interface FiltersRef {
    getFilters: () => any
    getSearch: () => string
}

const correct_date_filter = (date: any) => {
    return date ? `${Number.parseInt(date.day) > 9 ? Number.parseInt(date.day) : `0${Number.parseInt(date.day)}`}/${Number.parseInt(date.month) > 9 ? Number.parseInt(date.month) : `0${Number.parseInt(date.month)}`}/${date.year}` : null
}

const SearchWithFiltersComponent: React.ForwardRefRenderFunction<FiltersRef, ISearchWithFiltersProps> = (props, ref) => {
    const { loading, setLoading, initialFilters, clickButtonSearch, onChangeSearch } = props

    const { theme } = useGlobal()

    const [openFilters, setOpenFilters] = useState(false)
    const [filters, setFilters] = useState<IFilters[]>(initialFilters)

    const inputRef = useRef<InputWithLogoRefProps>(null)

    const getCorrectFilters = (filters: IFilters[]) => {
        return filters.filter((filter) => {
            if (filter.type === "input") return !valueIsEmpty(filter?.value)
            else if (filter.type === "range_picker") return !!filter?.value?.from || !!filter?.value?.to
            else return !!filter.value
        })
    }

    const normalizeFilters = useCallback((filters: IFilters[]) => {
        let filters_apply: any = getCorrectFilters(filters)

        filters_apply = filters_apply.map((filter: any) => ({
            key: filter.key,
            value: ["range_picker", "input"].includes(filter.type) ? filter.value : filter?.value?.value,
            type: filter.type
        }))

        const filters_object: any = {}
        filters_apply.forEach((filter: any) => {
            if (filter.type === "range_picker") {
                let from = correct_date_filter(filter?.value?.from)
                let to = correct_date_filter(filter?.value?.to)
                if (!from && to) from = to
                if (!to && from) to = from
                filters_object["date_init"] = from
                filters_object["date_end"] = to
                delete filters_object[filter.key]
            }
            else filters_object[filter.key] = filter.value
        })

        return filters_object
    }, [])

    const getFilters = useCallback(() => {
        return normalizeFilters(filters)
    }, [filters])

    const getSearch = useCallback(() => {
        return inputRef.current?.getValue() || ""
    }, [inputRef])

    const handleClickFilters = useCallback(() => {
        setOpenFilters(true)
    }, [])

    const handleCloseFilters = useCallback(() => {
        setOpenFilters(false)
    }, [])

    const handleApplyFilters = useCallback((filters: IFilters[]) => {
        const filters_object: any = normalizeFilters(filters)
        setFilters([...filters])
        setOpenFilters(false)
        clickButtonSearch(filters_object)
    }, [])

    const handleChangeSearch = useCallback(async (value: string) => {
        setLoading(true)
        debounce(async () => { await onChangeSearch(value) }, 800)
    }, [])

    useImperativeHandle(ref, () => ({ getFilters, getSearch }))

    const secondIconComponent = (
        <div style={{ position: "relative" }}>
            <IoFilterSharp size={19} color={inactiveItens["dark"]} />
            {getCorrectFilters(filters).length > 0 && (
                <ContainerFiltersNumber theme={theme}>
                    {getCorrectFilters(filters).length > 9 ? "+9" : getCorrectFilters(filters).length}
                </ContainerFiltersNumber>
            )}
        </div>
    )

    return (
        <div style={{ marginTop: 10 }}>
            <InputWithLogo
                ref={inputRef}
                iconComponent={<BiSearch size={19} color={inactiveItens["dark"]} />}
                secondIconComponent={filters.length > 0 ? secondIconComponent : undefined}
                onClickSecondIcon={filters.length > 0 ? handleClickFilters : undefined}
                onChange={(value: any) => handleChangeSearch(value)}
                placeholder="O que você procura?"
                containerStyle={{ minWidth: 0 }}
                noUpperCase

            />
            {openFilters && filters.length > 0 &&
                <ModalFilters
                    handleApply={handleApplyFilters}
                    handleClose={handleCloseFilters}
                    initialFilters={filters}
                    open={openFilters}
                    disabled={loading}
                />
            }
        </div>
    )
}

export const SearchWithFilters = forwardRef(SearchWithFiltersComponent);