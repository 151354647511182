import styled, { css } from "styled-components";
import { secondary } from "../../../../../theme";
import { IThemeProps, themeTypes } from "../../../../../interfaces";

export const EditButton = styled.div<IThemeProps>`
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    :hover{
        ${props => css`
            color: ${secondary[props.theme as themeTypes]};
        `}
    }
`

export const Title = styled.div<IThemeProps>`
    font-weight: 600;
    font-size: 20px;
    color: ${props => secondary[props.theme as themeTypes]};
`