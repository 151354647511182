import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { EditButton } from "./style";
import { secondary } from "../../../../../theme";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { useGlobal } from "../../../../../hooks/global";
import { getPersonLabel } from "../../../../../utils/labels";
import { IWarrantyFormRefProps, WarrantyForm } from "./form";
import { HiOutlinePencilAlt, HiOutlineSave } from "react-icons/hi";
import { consultsOptionsRelations } from "../../../../../utils/options";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { mapDefaultForm, warrantyDefaultForm } from "../../../../../utils/defaultFormsUtils";
import { customers_proposalAttributes, IOptionsProps, warrantyAttributes } from "../../../../../interfaces";

export interface IStepRef {
    validate: () => boolean
}

interface IWarrantyStep {
    defaultId: string
    loading: boolean
    control: number
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
    editWarranty: boolean
    setEditWarranty: (editWarranty: any) => void
    warranty: warrantyAttributes | null
    setWarranty: React.Dispatch<React.SetStateAction<warrantyAttributes | null>>
}

const WarrantyStepComponent: React.ForwardRefRenderFunction<IStepRef, IWarrantyStep> = (props, ref) => {
    const { changeProposal, control, setWarranty, warranty, editWarranty, setEditWarranty, setUpdatedAt, defaultId, loading, setLoading } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [defaultData, setDefaultData] = useState<any>({})
    const [defaultGuarantors, setDefaultGuarantors] = useState<any>([])
    const [consultsOptions, setConsultsOptions] = useState<IOptionsProps[]>([])
    const [guarantorsConsults, setGuarantorsConsults] = useState<customers_proposalAttributes[]>([])

    const formRef = useRef<IWarrantyFormRefProps>(null)

    const onSucessSubmit = useCallback((data: any) => {
        setWarranty({ ...data })
        setEditWarranty(false)
        setUpdatedAt(new Date())
        changeProposal(4)
    }, [])

    const search = useCallback(async () => {
        setLoading(true)

        try {
            const [response_consults, response_warranty] = await Promise.all([
                api.get(`/proposals/queries/${defaultId}`),
                api.get(`/proposals/warranty/${defaultId}`)
            ])

            let _defaultData: any = mapDefaultForm({ data: response_warranty.data, fields: warrantyDefaultForm })

            setWarranty(response_warranty.data)

            const all_guarantors = response_consults.data.filter((consult: customers_proposalAttributes) => ["guarantor", "guarantor_spouse"].includes(consult.relation))
            setGuarantorsConsults([...all_guarantors])

            const consults_result: IOptionsProps[] = all_guarantors.map((consult: customers_proposalAttributes) => {
                return {
                    relation: consult?.relation,
                    label: getPersonLabel({ person: consult.customer as any }),
                    value: consult?.customer?.id,
                    spouse: consult?.customer?.spouse,
                    this: consult?.customer
                }
            }) as IOptionsProps[]
            setConsultsOptions([...consults_result])

            const guarantors_includes = all_guarantors.filter((consult: customers_proposalAttributes) => consult.include)

            const defaultDataGuarantors = guarantors_includes.map((guarantor: any) => {
                const _uniqueId = uniqueId()
                const label = getPersonLabel({ person: guarantor?.customer })
                const value = guarantor?.customer?.id
                const _this = guarantor?.customer
                const _relation = consultsOptionsRelations.find((e) => e?.value === guarantor?.relation)
                return {
                    ["customer_id-" + _uniqueId]: { label, value, this: _this, relation: guarantor?.relation },
                    ["relation-" + _uniqueId]: _relation,
                    uniqueId: _uniqueId
                }
            })
            setDefaultGuarantors([...defaultDataGuarantors])

            for (const _tenant of defaultDataGuarantors) _defaultData = { ..._defaultData, ..._tenant }
            setDefaultData({ ..._defaultData })

        } catch (err) {

            notify("Erro ao Buscar Garantia da Proposta", "error")

        }

        setLoading(false)

    }, [defaultId])

    const validate = useCallback(() => {
        if (warranty?.warranty_type === "GUARANTOR") return !!(warranty?.guarantors && warranty.guarantors.length > 0)
        else return !!warranty
    }, [warranty])

    useImperativeHandle(ref, () => ({ validate }))

    useEffect(() => { setEditWarranty(false) }, [])

    useEffect(() => { search() }, [control])

    return (
        <Column style={{ flex: 1 }}>

            <ContentOrLoading type="wave" theme={theme} loading={loading}>
                <Column style={{ gap: 20, flex: 1 }}>
                    <Line style={{ justifyContent: "space-between" }}>
                        <Column>
                            <div style={{ fontWeight: 600, color: secondary[theme], fontSize: 20 }}>Selecione a garantia utilizada</div>
                            <div>Informe abaixo os dados da garantia utilizada</div>
                        </Column>
                        <div>
                            {!editWarranty &&
                                <EditButton
                                    onClick={() => setEditWarranty((atual: any) => !atual)}
                                    theme={theme}
                                    children={(
                                        <>
                                            <div style={{ fontWeight: 600 }}>Editar</div>
                                            <HiOutlinePencilAlt size={20} />
                                        </>
                                    )}
                                />
                            }
                            {editWarranty &&
                                <EditButton
                                    onClick={() => formRef.current?.forceSubmit()}
                                    theme={theme}
                                    children={(
                                        <>
                                            <div style={{ fontWeight: 600 }}>Salvar</div>
                                            <HiOutlineSave size={20} />
                                        </>
                                    )}
                                />
                            }
                        </div>
                    </Line>
                    <WarrantyForm
                        defaultData={defaultData}
                        onSuccess={onSucessSubmit}
                        consultsOptions={consultsOptions}
                        defaultGuarantors={defaultGuarantors}
                        guarantorsConsults={guarantorsConsults}
                        isDisabled={!editWarranty}
                        proposalId={defaultId}
                        ref={formRef}
                    />
                </Column>
            </ContentOrLoading>
        </Column>
    )
}

export const WarrantyStep = React.forwardRef(WarrantyStepComponent)