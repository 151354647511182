import styled from "styled-components";
import { Line } from "../../../../style";
import { borderColors, primary } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const ContainerConsult = styled(Line) <IThemeProps>`
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
    background: ${props => primary[props.theme as themeTypes]};
    border: 1px solid ${props => borderColors[props.theme as themeTypes]};
`

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
`

export const Description = styled.div`
    font-size: 12px;
    margin-top: -3px;
    text-transform: uppercase;
`

export const Img = styled.img`
     height: 120px;
     object-fit: cover;
`