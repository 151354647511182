import React from "react";
import { Container, Title } from "./style";
import { themeTypes } from "../../../../../interfaces";

interface IDashboardCardProps {
    title: string;
    theme: themeTypes;
    minWidth: number;
    minHeight?: number;
    total?: number;
    mask?: Function;
}

export const DashboardCard: React.FC<IDashboardCardProps> = (props) => {
    const { children, title, theme, minWidth, minHeight = null, total, mask } = props;
    return (
        <Container theme={theme} minWidth={minWidth} minHeight={minHeight}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10, overflow: "hidden", justifyContent: "space-between", padding: 20, flexWrap: "wrap" }}>
                <Title>{title}</Title>
            </div>
            {children}
        </Container>
    )
}