import Button from "../../../components/Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { Container, Form } from "./style";
import { useForm } from "react-hook-form";
import { useApi } from "../../../hooks/api";
import { FaFileImage } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
import { useGlobal } from "../../../hooks/global";
import { inactiveItens, secondary } from "../../../theme";
import { ModalHeader } from "../../../components/ModalHeader";
import { AiFillFileWord, AiOutlineFileGif } from "react-icons/ai";
import { ModalLoading } from "../../../components/Loadings/ModalLoading";
import { ContainerFormModal, ModalContainerButtons } from "../../../style";
import { GenericForm, IGenericFormRefProps } from "../../../components/GenericForm";
import { documents_typeAttributes, IGroupProps, IOptionsProps } from "../../../interfaces";
import { documentsExtensionsOptions, documentsTypesOptions } from "../../../utils/options";

interface IFormDocumentTypeModalProps {
    onCancel: Function
    onSave: Function
    defaultData?: documents_typeAttributes | null
}

const texts: any = {
    new: {
        title: "Novo Tipo de Documento",
        subTitle: "Informe os dados necessários para criar um novo Tipo de Documento"
    },
    edit: {
        title: "Editar Tipo de Documento",
        subTitle: "Atualize os dados do Tipo de Documento"
    }
}

export const FormDocumentType: React.FC<IFormDocumentTypeModalProps> = (props) => {
    const { onCancel, onSave, defaultData } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const _defaultData = {}

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: defaultData?.id ? defaultData : _defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const [loadingCreate, setLoadingCreate] = useState(false)

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const onSubmit = useCallback(async () => {

        setLoadingCreate(true)

        const form = form_ref.current?.getForm()
        form.extensions = form.extensions.map((item: any) => item.id)
        form.extensions = JSON.stringify(form.extensions)

        try {

            if (defaultData?.id) {
                await api.put(`/documents_types/${defaultData.id}`, form)
                notify("Tipo de Documento atualizado com sucesso.", "success")
            }
            else {
                await api.post("/documents_types", form)
                notify("Tipo de Documento cadastrado com sucesso.", "success")
            }

            onSave()

        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoadingCreate(false)

    }, [form_ref, _form, api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, api])

    const whatIsTheExtension = (fileName: string) => {
        if (!fileName) return <FaFileImage style={{ marginRight: 5 }} size={14} />
        const fileName_array = fileName.split(".")
        const extension = fileName_array[fileName_array.length - 1]?.toLocaleLowerCase()
        if (extension === "pdf") return <VscFilePdf style={{ marginRight: 5 }} size={14} />
        else if (["doc", "docx"].includes(extension)) return <AiFillFileWord style={{ marginRight: 5 }} size={14} />
        else if (["gif"].includes(extension)) return <AiOutlineFileGif style={{ marginRight: 5 }} size={14} />
        else return <FaFileImage style={{ marginRight: 5 }} size={14} />
    }

    const formatOptionLabel = useCallback((value: any, options) => {
        if (!!options?.find && !!options?.find((option: IOptionsProps) => option.value === value.value)) {
            return (
                <div style={{ display: "flex", color: "#FFF", flexDirection: "row", marginRight: 10, alignItems: "center", gap: 5, cursor: "pointer" }}>
                    {whatIsTheExtension(value.value)}
                    <div style={{ fontWeight: 600 }}>{value.label}</div>
                </div >
            )
        } else return (
            <div style={{ display: "flex", flexDirection: "row", marginRight: 10, alignItems: "center", gap: 5, cursor: "pointer" }}>
                {whatIsTheExtension(value.value)}
                <div>{value.label}</div>
            </div>
        )
    }, [])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "name",
                        label: "Nome",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "size",
                        label: "Tamanho(MB)",
                        type: "input",
                        mask: "int",
                        required: true
                    }
                ],
                [
                    {
                        name: "type",
                        label: "Tipo",
                        type: "select-fixed",
                        options: documentsTypesOptions,
                        isClearable: true,
                        required: true
                    }
                ],
                [
                    {
                        name: "extensions",
                        label: "Extensões",
                        type: "select-fixed-multi",
                        options: documentsExtensionsOptions,
                        isClearable: true,
                        required: true,
                        formatOptionLabel
                    }
                ]
            ]
        }
    ]

    return (
        <Container theme={theme}>

            <ModalHeader
                theme={theme}
                onCancel={() => onCancel()}
                title={texts[defaultData ? "edit" : "new"].title}
                subTitle={texts[defaultData ? "edit" : "new"].subTitle}
            />

            <ContainerFormModal>
                <Form onSubmit={handleStopPropagation} theme={theme}>
                    <GenericForm
                        ref={form_ref}
                        groups={groups}
                        _form={_form}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                    />
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Form>
            </ContainerFormModal>

            <ModalContainerButtons theme={theme}>
                <Button
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Cancelar"
                />
                <Button
                    onClick={() => buttonSubmitRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Salvar"
                />
            </ModalContainerButtons>

            {loadingCreate && (
                <ModalLoading
                    loading={loadingCreate}
                    theme={theme}
                />
            )}

        </Container >

    )
}
