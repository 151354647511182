import React from "react"

export const DocSign = ({ size, color, ...props }: any) => (
  <svg
    width={19}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.71 14.71-2.42-2.42a1.002 1.002 0 0 0-1.42 0l-3.58 3.58a1 1 0 0 0-.29.71V19a1 1 0 0 0 1 1h2.42a1 1 0 0 0 .71-.29l3.58-3.58a1.002 1.002 0 0 0 0-1.42ZM13 18h-1v-1l2.58-2.58 1 1L13 18Zm-6 0H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v1a1 1 0 0 0 2 0V6.94a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.32.32 0 0 0-.09 0L9.06 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h4a1 1 0 0 0 0-2Zm3-14.59L12.59 6H11a1 1 0 0 1-1-1V3.41ZM5 12h6a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2Zm0-4h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2Zm2 6H5a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2Z"
      fill={color}
    />
  </svg>
)
