import styled, { css } from "styled-components";
import { themeTypes } from "../../interfaces";
import { primary, secondary } from "../../theme";

export const Container = styled.div`
    height: 20px;
    border-radius: 15px;
    width: 100%;
    background: #C4C4C4;
    overflow: hidden;
    position: relative;
`

export const ProgressContent = styled.div<{ theme: themeTypes, width: number }>`
    display: flex;
    border-radius: 15px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    transition: min-width 0.5s;
    position: absolute;
    justify-content: center;
    align-items: center;
    ${props => css`
        min-width: ${props.width}%;
        background: ${secondary[props.theme as themeTypes]};
    `};
`

export const ProgressBackground = styled.div`
    display: flex;
    color: ${primary.light};
    font-size: 12px;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    justify-content: center;
    align-items: center;
    font-weight: 600;
`