import styled, { css } from "styled-components";
import { IThemeProps } from "../../interfaces";

export const Line = styled.div`
    display:flex;
    flex-direction:row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
`

export const Title = styled.div<IThemeProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 10px;
    ${props => css`
        border-bottom: 1px solid ${props.theme === "light" ? "#CCCCCC" : "#545454"};
    `}
`

export const Content = styled.div`
    display: flex;
    flex: 1;
`

export const ContainerNoSubscription = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    justify-content: center;
    align-items: center;
`

export const TextNoSubscription = styled.div`
    font-size: 22px;
    font-style: italic;
`

export const H4 = styled.h4`
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    display: flex;
    align-items: center;
`

export const ContainerReload = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const SubTitle = styled.div`
    font-size: 14px;
    white-space: nowrap;
`

export const ContainerSubscription = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 20px;
    width: 100%;
`