import React from "react";
import { Card, Column } from "./style";
import { CSSProperties } from "styled-components";
import { themeTypes } from "../../../../interfaces";
import { IconButton, Tooltip } from "@material-ui/core";
import { inactiveItens, shadowColor } from "../../../../theme";

interface ITopCard {
    icon: any
    title: string
    value: any
    theme: themeTypes
    containerStyle?: CSSProperties
    titleStyle?: CSSProperties
    tooltip: string
}

export const TopCard: React.FC<ITopCard> = (props) => {
    const { icon, theme, title, value, containerStyle = {}, titleStyle = {}, tooltip } = props

    return (
        <div style={{ minWidth: 220, display: "flex", flex: 1, overflow: "hidden", borderRadius: 5, boxShadow: `0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]}` }}>
            <Tooltip placement="top" title={tooltip} style={{ display: "flex", overflow: "hidden", flex: 1, borderRadius: 5, padding: 0, margin: 0, cursor: "default" }}>
                <IconButton disableRipple aria-label={tooltip}>
                    <Card theme={theme} style={{ minWidth: 220, overflow: "hidden", paddingTop: 30, paddingBottom: 30, display: "flex", flex: 1, flexDirection: "row", alignItems: "center", gap: 10, ...containerStyle }}>
                        <div>{icon}</div>
                        <Column style={{ overflow: "hidden" }}>
                            <div style={{ color: inactiveItens[theme], fontSize: 14, whiteSpace: "nowrap", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", ...titleStyle, fontWeight: 600, textTransform: "uppercase" }}>{title}</div>
                            <div style={{ fontWeight: 600, fontSize: 22, marginTop: -6, whiteSpace: "nowrap", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden" }}>{value}</div>
                        </Column>
                    </Card>
                </IconButton>
            </Tooltip>
        </div>
    )
}