import styled from "styled-components"
import { IThemeProps, themeTypes } from "../../interfaces"
import { divider, inactiveItens, primary } from "../../theme"

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0,0,0,0.1);
    padding: 20px;
    position: relative;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    background: ${props => primary[props.theme as themeTypes]};
`

export const Title = styled.div<IThemeProps>`
    font-weight: 600;
    font-size: 18px;
    color: ${props => props.theme === "dark" ? "#FFF" : inactiveItens.light};
`