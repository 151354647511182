import React from "react";
import { Bag } from "../../Bags/Bag";
import { Line } from "../../../style";
import { IoCard } from "react-icons/io5";
import { ContainerTable } from "./style";
import { PixSharp } from "@mui/icons-material";
import { RiBarcodeFill } from "react-icons/ri";
import { TbHeartHandshake } from "react-icons/tb";
import { IconButton, Tooltip } from "@material-ui/core";
import { ModalReceipt } from "../../Modals/ModalReceipt";
import { getStatus } from "../../../utils/columnsTables";
import { maskFunctions } from "../../../services/maskServices";
import { correctDate } from "../../../services/generalServices";
import { MdLibraryBooks, MdOutlineRemoveRedEye } from "react-icons/md";
import { themeTypes, transactionsAttributes } from "../../../interfaces";
import { MaxCoinSmall20 } from "../../SvgComponents/Icons/MaxCoinSmall20";
import { background, fail, inactiveItens, info, secondary, success } from "../../../theme";

interface ITransactionList {
    transactions: transactionsAttributes[]
    theme: themeTypes
    transaction_selected: string | null
    setTransactionSelected: React.Dispatch<React.SetStateAction<string | null>>
}

const colors: any = {
    "outline": {
        "dark": secondary.dark,
        "light": secondary.light
    },
    "solid": {
        "dark": "#FFF",
        "light": "#FFF"
    }
}

const status_of_transaction: any = {
    "new": {
        color: info,
        text: "Aguardando pagamento"
    },
    "waiting": {
        color: info,
        text: "Aguardando pagamento"
    },
    "identified": {
        color: info,
        text: "Aguardando pagamento"
    },
    "approved": {
        color: info,
        text: "Aguardando pagamento"
    },
    "paid": {
        color: success,
        text: "Pago"
    },
    "paid-free": {
        color: success,
        text: "Pago"
    },
    "unpaid": {
        color: fail,
        text: "Não pago"
    },
    "refunded": {
        color: fail,
        text: "Devolvido"
    },
    "contested": {
        color: fail,
        text: "Contestado"
    },
    "canceled": {
        color: fail,
        text: "Cancelado"
    },
    "settled": {
        color: success,
        text: "Pago"
    },
    "link": {
        color: info,
        text: "Aguardando pagamento"
    },
    "expired": {
        color: fail,
        text: "Expirado"
    }
}

const transaction_pay_method = (transaction: transactionsAttributes, theme: themeTypes) => {
    if (transaction.method === "credit_card") {
        return (
            <Line style={{ alignItems: "center", gap: 5 }}>
                <IoCard size={20} color={inactiveItens[theme]} />
                <b style={{ textTransform: "capitalize" }}>{transaction?.card_flag} </b>
                ****{transaction?.card_mask?.replace(/\D/g, "")}
            </Line>
        )
    }
    else if (transaction.method === "pix") {
        return (
            <Line style={{ alignItems: "center", gap: 5 }}>
                <PixSharp fontSize="small" />
                <b style={{ textTransform: "capitalize", color: inactiveItens[theme] }}>Pix</b>
            </Line>
        )
    }
    else if (transaction.method === "mxs") return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <div style={{ display: "flex", background: secondary[theme], justifyContent: "center", alignItems: "center", minWidth: 36, maxWidth: 36, minHeight: 36, maxHeight: 36, borderRadius: "50%" }}>
                <MaxCoinSmall20 />
            </div>
            <b style={{ textTransform: "capitalize" }}>Créditos MXs</b>
        </Line>
    )
    else if (transaction.method === "banking_billet") return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <RiBarcodeFill size={20} color={inactiveItens[theme]} />
            <b style={{ textTransform: "capitalize" }}>Boleto Bancário</b>
        </Line>
    )
    else return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <TbHeartHandshake size={20} color={inactiveItens[theme]} />
            <b style={{ textTransform: "capitalize" }}>Grátis</b>
        </Line>
    )
}

export const TransactionList: React.FC<ITransactionList> = (props) => {
    const { transactions, theme, setTransactionSelected, transaction_selected } = props

    return (
        <Bag title="Histórico de cobranças" description="Relatório de cobranças realizadas em seu meio de pagamento." icon={<MdLibraryBooks color={secondary[theme]} size={26} />}>
            <ContainerTable theme={theme}>
                <table cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                    <thead>
                        <tr style={{ background: background[theme] }}>
                            <th style={{ padding: 10, whiteSpace: "nowrap" }}>CÓDIGO</th>
                            <th style={{ whiteSpace: "nowrap", padding: 10, textAlign: "center" }}>GERADA EM</th>
                            <th style={{ whiteSpace: "nowrap", padding: 10, textAlign: "center" }}>PAGO EM</th>
                            <th style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>PLANO</th>
                            <th style={{ textAlign: "center", padding: 10 }}>VALOR</th>
                            <th style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10 }}>MÉTODO DE PAGAMENTO</th>
                            <th style={{ textAlign: "center", padding: 10 }}>STATUS</th>
                            <th style={{ padding: 10, textAlign: "center" }} > AÇÕES</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction) => (
                            <tr onClick={() => setTransactionSelected(transaction.id)} style={{ background: colors[theme] }} key={transaction.id}>
                                <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                    {transaction?.friendly_id ? `#${transaction?.friendly_id}` : "-"}
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                    {correctDate(transaction.createdAt)}
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                    {["paid", "settled", "paid-free"].includes(transaction.status) ? correctDate(transaction.updatedAt) : "-"}
                                </td>
                                <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                    {transaction.subscription?.plan?.name}
                                </td>
                                <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                    {maskFunctions.currency.mask(transaction.value)}
                                </td>
                                <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                    <Line style={{ alignItems: "center", gap: 5, justifyContent: "center" }}>
                                        {transaction_pay_method(transaction, theme)}
                                    </Line>
                                </td>
                                <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                    <Line style={{ justifyContent: "center" }}>
                                        {getStatus({
                                            color: status_of_transaction[transaction.status].color,
                                            text: status_of_transaction[transaction.status].text,
                                            theme
                                        })}
                                    </Line>
                                </td>
                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                    <Tooltip onClick={() => setTransactionSelected(transaction.id)} title={"Visualizar"} style={{ margin: 5 }}>
                                        <IconButton aria-label={"Visualizar"}>
                                            <MdOutlineRemoveRedEye color={secondary[theme]} size={20} />
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ContainerTable>
            {transaction_selected &&
                <ModalReceipt
                    transaction_id={transaction_selected}
                    onCancel={() => setTransactionSelected(null)}
                    openModal={true}
                    title="Pagamento da assinatura"
                />
            }
        </Bag>
    )
}