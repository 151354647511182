import Button from "../../../Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { Form } from "./style";
import { useForm } from "react-hook-form";
import { Column } from "../../../../style";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { divider, inactiveItens, secondary } from "../../../../theme";
import { IGroupProps, plansAttributes } from "../../../../interfaces";
import { modulesOptions, planIconsOptions } from "../../../../utils/options";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";

interface IFormModalPlanProps {
    onCancel: Function
    onSave: Function
    defaultData?: plansAttributes
    defaultPlan?: plansAttributes | null
}

export const FormPlan: React.FC<IFormModalPlanProps> = (props) => {
    const { onCancel, onSave, defaultData = { frequency: 1 } as any } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const [loadingCreate, setLoadingCreate] = useState(false)

    const onSubmit = useCallback(async () => {
        setLoadingCreate(true)

        try {

            const plan_data = form_ref.current?.getForm()
            plan_data.permissions = plan_data.permissions.map((permission: any) => permission?.id?.name)
            plan_data.permissions = JSON.stringify(plan_data.permissions)

            if (defaultData?.id) {
                await api.put(`/plans/${defaultData.id}`, { plan_data })
                notify("Plano atualizado com sucesso!", "success")
            } else {
                await api.post(`/plans`, { plan_data })
                notify("Plano criado com sucesso!", "success")
            }

            onSave()
        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoadingCreate(false)

    }, [form_ref, defaultData, api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form])


    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "name",
                        label: "Nome",
                        type: "input",
                        required: true,
                        getIsDisabled: () => !!defaultData?.id
                    },
                    {
                        name: "icon",
                        label: "Ícone",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: planIconsOptions,
                        getIsDisabled: () => !!defaultData?.id
                    }
                ],
                [
                    {
                        name: "value",
                        label: "Valor",
                        type: "input",
                        required: true,
                        getIsDisabled: () => !!defaultData?.id,
                        mask: "currency"
                    },
                    {
                        name: "frequency",
                        label: "Frequência",
                        type: "input",
                        required: true,
                        mask: "int",
                        getIsDisabled: () => true
                    },
                    {
                        name: "recurrence",
                        label: "Recorrência",
                        type: "input",
                        required: true,
                        mask: "int",
                        getIsDisabled: () => !!defaultData?.id
                    }
                ],
                [
                    {
                        name: "users_limit",
                        label: "Limite de usuários",
                        type: "input",
                        required: true,
                        mask: "int",
                        getIsDisabled: () => !!defaultData?.id
                    },
                    {
                        name: "cloud_limit",
                        label: "Limite de armazenamento(GB)",
                        type: "input",
                        required: true,
                        mask: "int",
                        getIsDisabled: () => !!defaultData?.id
                    },
                    {
                        name: "contract_templates_limit",
                        label: "Limite de modelos de Doc's eletrônicos",
                        type: "input",
                        required: true,
                        mask: "int",
                        getIsDisabled: () => !!defaultData?.id
                    }
                ],
                [
                    {
                        name: "pj_unit_value",
                        label: "Consulta PJ - Valor Unitário",
                        type: "input",
                        required: true,
                        mask: "currency",
                        getIsDisabled: () => !!defaultData?.id
                    },
                    {
                        name: "pf_unit_value",
                        label: "Consulta PF - Valor Unitário",
                        type: "input",
                        required: true,
                        mask: "currency",
                        getIsDisabled: () => !!defaultData?.id
                    },
                    {
                        name: "doc_unit_value",
                        label: "Doc. Eletrônico - Valor Unitário",
                        type: "input",
                        required: true,
                        mask: "currency",
                        getIsDisabled: () => !!defaultData?.id
                    }
                ],
                [
                    {
                        name: "permissions",
                        label: "Permissões",
                        type: "select-fixed-multi",
                        required: true,
                        isClearable: true,
                        options: modulesOptions
                    }
                ],
                [
                    {
                        name: "description",
                        label: "Descrição",
                        type: "textarea",
                        required: true
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <Column style={{ flex: 1, overflow: "auto", gap: 20 }}>
                <Form onSubmit={handleStopPropagation}>
                    <div style={{ flexDirection: "column", flex: 1, display: "flex", padding: 20, gap: 20 }}>
                        <GenericForm
                            ref={form_ref}
                            groups={groups}
                            _form={_form}
                            control={control}
                            trigger={trigger}
                            errors={errors}
                            setValue={setValue}
                            register={register}
                        />
                    </div>
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Form>
            </Column>

            <div style={{ display: "flex", justifyContent: "space-between", padding: 20, borderTop: `1px solid ${divider[theme]}` }}>
                <Button
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Voltar"
                />
                <Button
                    onClick={() => buttonSubmitRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14, display: "flex" }}
                    children="Salvar"
                />
            </div>

            {loadingCreate && (
                <ModalLoading
                    loading={loadingCreate}
                    theme={theme}
                />
            )}
        </ >
    )
}
