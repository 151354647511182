import styled, { css } from "styled-components";
import { IThemeProps } from "../../../../interfaces";

export const Value = styled.div<IThemeProps>`
    text-align: end;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13.5px;
    text-transform: uppercase;
    ${props => css`
        color: ${props.theme === "dark" ? "#FFF" : "#000"};
    `}
`