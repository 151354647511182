import styled from "styled-components";
import { Column } from "../../../../style";
import { Link as BaseLink } from "react-router-dom"

export const Container = styled(Column)`
    gap: 30px;
`

export const ContentFields = styled(Column)`
    width: 100%;
    gap: 16px;
`

export const Link = styled(BaseLink)`
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    color: #212529;
    font-weight: 600;
`

export const ContainerLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`