import Button from "../../Buttons/Button";
import { FiAlertCircle } from "react-icons/fi";
import { themeTypes } from "../../../interfaces";
import { ModalGeneric } from "../../ModalGeneric";
import { background, borderColors, inactiveItens, secondary } from "../../../theme";

interface IModalAttentionProps {
    open: boolean
    theme: themeTypes
    cancelText?: string
    confirmText?: string
    cancelAction: Function
    confirmAction: Function
    content: any
}

export const ModalAttention: React.FC<IModalAttentionProps> = (props) => {
    const { open, theme, content, cancelText = "Cancelar", confirmText = "Confirmar", cancelAction, confirmAction } = props

    return (
        <ModalGeneric open={open} onClose={() => cancelAction()} >
            <div style={{ background: background[theme], color: inactiveItens[theme], gap: 20, minHeight: 200, width: 350, display: "flex", justifyContent: "space-between", flexDirection: "column", padding: 20, borderRadius: 5 }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ padding: 20, display: "flex", justifyContent: "center", alignItems: "center", background: borderColors[theme], borderRadius: "50%" }}>
                        <FiAlertCircle size={40} color={inactiveItens[theme]} />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3>Atenção</h3>
                    <p style={{ textAlign: "center", maxWidth: 310, textOverflow: "ellipsis", overflow: "hidden" }}>
                        {content}
                    </p>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 16, flex: 1 }}>
                    <Button onClick={() => cancelAction()} background={inactiveItens[theme]} color="#FFF" style={{ height: 40, margin: 0, boxShadow: "0.125rem 0.125rem 0.5rem #57545419", justifyContent: "center", alignItems: "center", gap: 5, fontSize: 16, minWidth: 80, flex: 1 }}>
                        {cancelText}
                    </Button>
                    <Button onClick={() => { confirmAction() }} background={secondary[theme]} color="#FFF" style={{ height: 40, margin: 0, boxShadow: "0.125rem 0.125rem 0.5rem #57545419", justifyContent: "center", alignItems: "center", gap: 5, fontSize: 16, minWidth: 80, flex: 1 }}>
                        {confirmText}
                    </Button>
                </div>
            </div>
        </ModalGeneric>
    )
}