import styled from "styled-components";
import { Column } from "../../../style";

export const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
`

export const Description = styled.span`
    color: #7E838E;
    text-align: center;
`

export const Container = styled(Column)`
    align-items: center;
    width: 100%;
`

export const CircleOuside = styled.div<{ color: string }>`
     margin-bottom: 10px;
     min-height: 60px;
     max-height: 60px;
     min-width: 60px;
     max-width: 60px;
     border-radius: 50%;
     background: ${props => props.color};
     display: flex;
     justify-content: center;
     align-items: center;
`

export const CircleInside = styled.div<{ color: string }>`
     min-height: 40px;
     max-height: 40px;
     min-width: 40px;
     max-width: 40px;
     border-radius: 50%;
     background: ${props => props.color};
     display: flex;
     justify-content: center;
     align-items: center;
`