import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    font-size:13px;
    background: #FFF;
    z-index: 10;
    @media (min-width: 1000px) {
        max-width: 600px;
    }
`

export const ContainerTop = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 30px 30px 30px;
    gap: 10px;
`

export const ContainerImage = styled.div`
    background-image: url(/assets/bg-2.png);
    background-size: cover;
    background-position: left;
    flex: 1;
    width: calc(100vw - 600px);
    height: calc(100 * var(--vh));
    position: fixed;
    left: 0;
`

export const ContainerBottomButtons = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    gap: 20px;
`

export const ContainerCheckBox = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 24px;
`

export const Form = styled.form`
    padding: 15px 30px 30px 30px;
`

export const Title = styled.div`
    font-weight: 600;
    font-size: 20px;
`

export const Description = styled.div`
    font-size: 13px;
`

export const LogoImg = styled.img`
    object-fit: contain;
`

export const ResetPasswordButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`