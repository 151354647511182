import styled, { css } from "styled-components";
import { borderColors } from "../../../theme";
import { themeTypes } from "../../../interfaces";

export const ContainerPagination = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`

export const Line = styled.div`
    display:flex;
    flex-direction:row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
`

export const Title = styled.div<{ theme: themeTypes }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 10px;
    ${props => css`
        border-bottom: 1px solid ${props.theme === "light" ? "#CCCCCC" : "#545454"};
    `}
`

export const H4 = styled.h4`
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    display: flex;
    align-items: center;
`

export const ContainerReload = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const SubTitle = styled.div`
    font-size: 14px;
    white-space: nowrap;
`

export const ContainerBalance = styled.div<{ theme: themeTypes }>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 4px;
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]} ;
    `}
`

export const BalanceCard = styled.div`
    border-radius: 5px;
    min-height: 159px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-image: linear-gradient(to right,#E20055,#000094);
    padding: 20px;
`

export const ContainerTable = styled.div<{ theme: themeTypes }>`
    font-size: 13.5px;
    border-radius: 4px;
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]};
    `}
    overflow: auto;
`

export const ContainerIconMaxCoin = styled.div`
    position: absolute;
    left: 20px;
    @media(max-width: 690px){
        display: none;
    }
`

export const ContainerLastUpdate = styled.div<{ menuIsOpen: boolean }>`
    gap: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    ${props => !props.menuIsOpen && css`
        @media(max-width: 440px) {
            display: none;
        }
    `}
    ${props => props.menuIsOpen && css`
        @media(max-width: 550px) {
            display: none;
        }
    `}
`

export const ContainerSelectFranchise = styled.div`
    min-width: 350px;
`

export const ContainerNoSubscription = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    justify-content: center;
    align-items: center;
`

export const TextNoSubscription = styled.div`
    font-size: 22px;
    font-style: italic;
`