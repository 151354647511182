import styled, { css } from "styled-components";
import { Pagination } from "@material-ui/lab";
import { secondary, inactiveItens } from "../../theme";
import { IThemeProps, themeTypes } from "../../interfaces";

export const PaginationComponent = styled(Pagination) <IThemeProps>`
    button{
        ${props => css`
            color: ${inactiveItens[props.theme as themeTypes]};
        `}
        font-family:inherit !important;
        font-size:12px;
    }
    .Mui-selected{
        ${props => !props.disabled && css`
            background: transparent !important;
            border-radius:50%;
            border:1px solid ${secondary[props.theme as themeTypes]};
            color: ${secondary[props.theme as themeTypes]} !important;
        `}
        ${props => props.disabled && css`
            color: ${inactiveItens[props.theme as themeTypes]} !important;
        `}
    }
    .MuiPaginationItem-ellipsis{
        cursor: default;
        ${props => css`
            color: ${inactiveItens[props.theme as themeTypes]};
        `}
    }
`