import styled from "styled-components";
import { Column } from "../../../../style";
import { divider } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
`

export const ContainerForm = styled(Column) <{ open: boolean }>`
    display: ${props => props.open ? "flex" : "none"};
    flex: 1;
    margin-top: -20px;
`

export const ContainerDocumentsEletronics = styled(Column)<{ open: boolean }>`
    display: ${props => props.open ? "flex" : "none"};
    flex: 1;
    gap: 20px;
`

export const ContainerDocuments = styled(Column)<{ open: boolean }>`
    display: ${props => props.open ? "flex" : "none"};
    flex: 1;
    margin: -16px;
`

export const ContainerButtons = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    border-top: 1px solid ${props => divider[props.theme as themeTypes]}
`