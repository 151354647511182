import React from "react";
import { Value } from "./style";
import { Bag } from "../../../Bags/Bag";
import { Pix } from "@mui/icons-material";
import { RiBarcodeFill } from "react-icons/ri";
import { BiCheckCircle } from "react-icons/bi";
import { FiAlertCircle } from "react-icons/fi";
import { IoCardOutline } from "react-icons/io5";
import { Column, Line } from "../../../../style";
import { BsInfoSquareFill } from "react-icons/bs";
import { useGlobal } from "../../../../hooks/global";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FieldList, IFieldProps } from "../../../FiedlList";
import { getStatus } from "../../../../utils/columnsTables";
import { maskFunctions } from "../../../../services/maskServices";
import { correctDate } from "../../../../services/generalServices";
import { themeTypes, transactionsAttributes } from "../../../../interfaces";
import { MaxCoinSmall20 } from "../../../SvgComponents/Icons/MaxCoinSmall20";
import { fail, inactiveItens, info, secondary, success } from "../../../../theme";

interface IReceiptProps {
    theme: themeTypes
    transaction: transactionsAttributes | null
}

const status_of_transaction: any = {
    "new": {
        color: info,
        text: "Aguardando pagamento",
        title: "Aguardando pagamento!",
        background: "#EED7AB",
        icon: <FiAlertCircle size={22} color={info} />,
        description: "Pague sua compra para utilizar seus créditos MXs."
    },
    "waiting": {
        color: info,
        text: "Aguardando pagamento",
        title: "Sua compra está aguardando pagamento!",
        background: "#EED7AB",
        icon: <FiAlertCircle size={22} color={info} />,
        description: "Pague sua compra para utilizar seus créditos MXs."
    },
    "identified": {
        color: info,
        text: "Aguardando pagamento",
        title: "Sua compra está aguardando pagamento!",
        background: "#EED7AB",
        icon: <FiAlertCircle size={22} color={info} />,
        description: "Pague sua compra para utilizar seus créditos MXs."
    },
    "approved": {
        color: info,
        text: "Aguardando pagamento",
        title: "Sua compra está aguardando pagamento!",
        background: "#EED7AB",
        icon: <FiAlertCircle size={22} color={info} />,
        description: "Pague sua compra para utilizar seus créditos MXs."
    },
    "paid": {
        color: success,
        text: "Pago",
        title: "Sua compra foi paga!",
        background: "#CCE3CD",
        icon: <BiCheckCircle size={22} color={success} />,
        description: "Tudo certo com sua compra! Clique no botão abaixo para voltar à plataforma e utilizar seus pontos."
    },
    "paid-free": {
        color: success,
        text: "Pago",
        title: "Sua compra foi paga!",
        background: "#CCE3CD",
        icon: <BiCheckCircle size={22} color={success} />,
        description: "Tudo certo com sua compra! Clique no botão abaixo para voltar à plataforma e utilizar seus pontos."
    },
    "unpaid": {
        color: fail,
        text: "Não pago",
        title: "Sua compra não foi paga!",
        background: "#FDEAE8",
        icon: <IoCloseCircleOutline size={22} color={fail} />,
        description: "Sua compra não foi paga. Realize uma nova compra e continue usufruindo dos benefícios da plataforma."
    },
    "refunded": {
        color: fail,
        text: "Devolvido",
        title: "Sua compra foi devolvida!",
        background: "#FDEAE8",
        icon: <IoCloseCircleOutline size={22} color={fail} />,
        description: "Sua compra não foi paga. Realize uma nova compra e continue usufruindo dos benefícios da plataforma."
    },
    "contested": {
        color: fail,
        text: "Contestado",
        title: "Sua compra foi contestada!",
        background: "#FDEAE8",
        icon: <IoCloseCircleOutline size={22} color={fail} />,
        description: "Sua compra não foi paga. Realize uma nova compra e continue usufruindo dos benefícios da plataforma."
    },
    "canceled": {
        color: fail,
        text: "Cancelado",
        title: "Sua compra foi cancelada!",
        background: "#FDEAE8",
        icon: <IoCloseCircleOutline size={22} color={fail} />,
        description: "Sua compra foi cancelada. Realize uma nova compra e continue usufruindo dos benefícios da plataforma."
    },
    "settled": {
        color: success,
        text: "Pago",
        title: "Sua compra foi paga!",
        background: "#CCE3CD",
        icon: <BiCheckCircle size={22} color={success} />,
        description: "Tudo certo com sua compra! Clique no botão abaixo para voltar à plataforma e utilizar seus pontos."
    },
    "link": {
        color: info,
        text: "Aguardando pagamento",
        title: "Sua compra está aguardando pagamento!",
        background: "#EED7AB",
        icon: <FiAlertCircle size={22} color={info} />,
        description: "Pague sua compra para utilizar seus créditos MXs."
    },
    "expired": {
        color: fail,
        text: "Expirado",
        title: "Sua compra expirou!",
        background: "#FDEAE8",
        icon: <IoCloseCircleOutline size={22} color={fail} />,
        description: "Sua compra expirou por falta de pagamento. Realize uma nova compra e continue usufruindo dos benefícios da plataforma."
    }
}

const correct_payment_method: any = {
    "credit_card": "Cartão de crédito",
    "pix": "PIX",
    "free": "Grátis",
    "banking_billet": "Boleto Bancário"
}

const correct_payment_method_icon: any = {
    "credit_card": <IoCardOutline size={22} color={"#FFF"} />,
    "pix": <Pix style={{ color: "#FFF" }} />,
    "free": <IoCardOutline size={22} color={"#FFF"} />,
    "banking_billet": <RiBarcodeFill size={22} color={"#FFF"} />
}

export const Receipt: React.FC<IReceiptProps> = (props) => {
    const { theme, transaction } = props

    const { multiple_mxs } = useGlobal()

    const Fields: IFieldProps[] = [
        {
            label: "Item comprado",
            keys: [],
            is_component: true,
            mask: (transaction: transactionsAttributes) => {
                if (!transaction?.subscription_id) return (
                    <Line style={{ alignItems: "center", gap: 5 }}>
                        <MaxCoinSmall20 />
                        <Value theme={theme}>{maskFunctions.mxs.mask((transaction?.value ?? 0) * multiple_mxs)}</Value>
                    </Line>
                )
                else return <Value theme={theme}>{transaction.subscription?.plan?.name}</Value>
            }
        },
        {
            label: "Código da compra",
            keys: [],
            mask: (transaction: transactionsAttributes) => {
                if (transaction.friendly_id) return `#${transaction?.friendly_id}`
                else return "-"
            }
        },
        {
            label: "Data da compra",
            keys: ["updatedAt"],
            mask: correctDate
        },
        {
            label: "Valor",
            keys: ["value"],
            mask: maskFunctions.currency.mask
        },
        {
            label: "Método de pagamento",
            keys: [],
            mask: (transaction: transactionsAttributes) => correct_payment_method[transaction?.method ?? ""]
        },
        {
            label: "Status",
            keys: [],
            is_component: true,
            mask: (transaction: transactionsAttributes) => {
                const color = status_of_transaction[transaction.status].color
                const text = status_of_transaction[transaction.status].text
                return getStatus({ color, text, theme })
            }
        }
    ]

    const ListFields = [{ title: "", fields: Fields }]

    return (
        <Column>
            <Line style={{ background: status_of_transaction[transaction?.status ?? ""].background, color: inactiveItens.light, padding: 20, justifyContent: "space-between" }}>
                <Line>
                    <div>
                        {status_of_transaction[transaction?.status ?? ""].icon}
                    </div>
                    <Column style={{ marginLeft: 10 }}>
                        <div style={{ fontWeight: 600 }}>{status_of_transaction[transaction?.status ?? ""].title}</div>
                        <div style={{ fontSize: 14 }}>{correct_payment_method[transaction?.method ?? ""]}</div>
                    </Column>
                </Line>
                <div style={{ display: "flex", justifyContent: "center", background: secondary.light, alignItems: "center", borderRadius: "50%", minHeight: 40, maxHeight: 40, minWidth: 40, maxWidth: 40 }}>
                    {correct_payment_method_icon[transaction?.method ?? ""]}
                </div>
            </Line>
            <Column style={{ padding: 20, gap: 20 }}>
                <Bag icon={<BsInfoSquareFill size={24} color={secondary[theme]} />} everOpen title="Detalhes da compra" description="Confira os detalhes da sua compra">
                    <Column style={{ padding: 20 }}>
                        <FieldList
                            data={transaction}
                            theme={theme}
                            listFields={ListFields}
                            fontSize={13.5}
                        />
                    </Column>
                </Bag>
            </Column>
        </Column>
    )
}