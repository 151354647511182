import { FaBoxes } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { FaToilet } from "react-icons/fa";
import { TbHandStop } from "react-icons/tb";
import { IoIosDesktop } from "react-icons/io";
import { BiCar, BiCloset, BiHome } from "react-icons/bi";
import { IoAirplaneOutline, IoBed } from "react-icons/io5";
import { GiBathtub, GiHomeGarage, GiSofa } from "react-icons/gi";
import { FaFaucet, FaLayerGroup, FaLightbulb, FaPalette, FaWarehouse } from "react-icons/fa";
import { MdKitchen, MdLocalLaundryService, MdOutdoorGrill, MdOutlinePedalBike, MdPool } from "react-icons/md";

export const PlanIcons: React.FC<{ type: string, size: number, opacity?: number }> = ({ size, type, opacity = 1 }) => {
    if (type === "FaCar") {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#FFF", opacity: opacity }}>
                <BiCar size={size} />
            </div>
        )
    }
    else if (type === "FaAirPlane") {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#FFF", opacity: opacity, rotate: "-45deg" }}>
                <IoAirplaneOutline size={size} />
            </div>
        )
    }
    else if (type === "TbHandStop") {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#FFF", opacity: opacity }}>
                <TbHandStop size={size} />
            </div>
        )
    }
    else if (type === "MdOutlinePedalBike") {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#FFF", opacity: opacity }}>
                <MdOutlinePedalBike size={size} />
            </div>
        )
    }
    return <></>
}

export const Icons: any = {
    FaToilet: <FaToilet size={26} />,
    IoIosDesktop: <IoIosDesktop size={26} />,
    BiCloset: <BiCloset size={26} />,
    BiHome: <BiHome size={26} />,
    GiBathtub: <GiBathtub size={26} />,
    GiHomeGarage: <GiHomeGarage size={26} />,
    GiSofa: <GiSofa size={26} />,
    MdKitchen: <MdKitchen size={26} />,
    MdLocalLaundryService: <MdLocalLaundryService size={26} />,
    MdOutdoorGrill: <MdOutdoorGrill size={26} />,
    MdPool: <MdPool size={26} />,
    FaBoxes: <FaBoxes size={26} />,
    IoBed: <IoBed size={26} />,
    FaFaucet: <FaFaucet size={26} />,
    FaLayerGroup: <FaLayerGroup size={26} />,
    FaLightbulb: <FaLightbulb size={26} />,
    FaPalette: <FaPalette size={26} />,
    FaWarehouse: <FaWarehouse size={26} />,
    IoHome: <IoHome size={26} />,
}