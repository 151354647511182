import React, { useCallback, useEffect, useState } from "react"
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { FormDocumentType } from "./Form";
import { VscFilePdf } from "react-icons/vsc";
import { FaFileImage } from "react-icons/fa";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { ROOT_USERS } from "../../utils/users.groups";
import { getFilterFields } from "../../utils/filterFields";
import { ModalGeneric } from "../../components/ModalGeneric";
import { AiFillFileWord, AiOutlineFileGif } from "react-icons/ai";
import { setUpConsultation } from "../../services/generalServices";
import { documentsTypesDefaultForm, mapDefaultForm } from "../../utils/defaultFormsUtils";
import { documents_typeAttributes, IActions, IFilters, ITagList } from "../../interfaces/index";
import { documentsExtensionsOptions, documentsTypesOptions, get_corrrect_names } from "../../utils/options";

const whatIsTheExtension = (fileName: string) => {
    if (!fileName) return <FaFileImage style={{ marginRight: 5 }} size={14} />
    const fileName_array = fileName.split(".")
    const extension = fileName_array[fileName_array.length - 1].toLowerCase()
    if (extension === "pdf") return <VscFilePdf style={{ marginRight: 5 }} size={14} />
    else if (["doc", "docx"].includes(extension)) return <AiFillFileWord style={{ marginRight: 5 }} size={14} />
    else if (["gif"].includes(extension)) return <AiOutlineFileGif style={{ marginRight: 5 }} size={14} />
    else return <FaFileImage style={{ marginRight: 5 }} size={14} />
}

const initialFilters: IFilters[] = [
    getFilterFields("document_types_type", ROOT_USERS)
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome", style: {}, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "type", name: "Tipo", style: {}, styleContent: {} },
        { attr: "extensions", name: "Extensões aceitas", style: { whiteSpace: "nowrap" }, styleContent: {} },
        { attr: "tam", name: "Tamanho do arquivo (MB)", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { textAlign: "center" } }
    ]
}

const getExtensions: React.FC<ITagList> = ({ theme, documentType }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {JSON.parse(documentType.extensions as any).map((extension: string) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ padding: 5, minWidth: 55, justifyContent: "center", whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], color: "#FFF", fontWeight: 600, fontSize: 12 }}>
                            {whatIsTheExtension(extension)}
                            {extension}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Novo Tipo de Documento", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

export const DocumentsTypesList: React.FC = () => {
    const { api } = useApi()
    const { theme, paginationLimit, notify } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [numOfDocumentsTypes, setNumOfDocumentsTypes] = useState(0)
    const [loadingDocumentsTypes, setLoadingDocumentsTypes] = useState(true)
    const [documentsTypeselected, setDocumentsTypeselected] = useState<documents_typeAttributes | null>(null)

    const searchDocumentsTypes = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingDocumentsTypes(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/documents-types?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_document_types = result.data.rows as documents_typeAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_document_types.forEach((documentType) => {
                    new_table.data.push({
                        id: documentType.id,
                        name: documentType?.name || null,
                        type: get_corrrect_names(documentsTypesOptions)[documentType.type],
                        extensions: getExtensions({ theme, documentType }),
                        tam: documentType.size,
                        this: documentType
                    })
                })
                return { ...new_table }
            })

            setNumOfDocumentsTypes(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelos tipos de documentos!", "error")
        }
        setLoadingDocumentsTypes(false)
    }, [filters, searchString])

    const onSaveDocumentType = useCallback(() => {
        setOpenModal(false)
        searchDocumentsTypes(1)
    }, [filters, searchString])

    const selectDocumentType = useCallback((DocumentType: documents_typeAttributes) => {
        setDocumentsTypeselected({ ...DocumentType })
        setOpenModal(true)
    }, [])

    const openModalNewDocumentType = useCallback(() => {
        setDocumentsTypeselected(null)
        setOpenModal(true)
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewDocumentType()
        else console.log({ action_id })
    }

    const handleEditDocumentType = useCallback((document_type: documents_typeAttributes) => {
        const _data: any = mapDefaultForm({ data: document_type, fields: documentsTypesDefaultForm })
        _data.extensions = _data.extensions.map((extension: string) => documentsExtensionsOptions.find((opt) => opt.value === extension))
        selectDocumentType({ ..._data })
    }, [])

    useEffect(() => { searchDocumentsTypes(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingDocumentsTypes}
            setLoading={setLoadingDocumentsTypes}
            numberOfItems={numOfDocumentsTypes}
            textHeader="Tipos de Documentos"
            textItems="tipos de documentos encontrados"
            theme={theme}
            search={searchDocumentsTypes}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_edit
            edit_action={handleEditDocumentType}
            text_loading={"Buscando tipos de Documentos"}
            text_empty={"Nenhum tipo de documento encontrado"}
            onClickOption={handleAction}
            actions={actionsInitial}
            pages={numberOfPages}
            page={atualPage}
            onChangeSearch={setSearchString}
        >
            <ModalGeneric open={openModal} onClose={() => { }} >
                <FormDocumentType
                    defaultData={documentsTypeselected}
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSaveDocumentType()}
                />
            </ModalGeneric>
        </PageList>
    )
}
