import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { EditButton, Title } from "./style";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { useGlobal } from "../../../../../hooks/global";
import { ITenantFormRefProps, TenantForm } from "./form";
import { getPersonLabel } from "../../../../../utils/labels";
import { HiOutlinePencilAlt, HiOutlineSave } from "react-icons/hi";
import { consultsOptionsRelations } from "../../../../../utils/options";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { customers_proposalAttributes, IOptionsProps } from "../../../../../interfaces";

export interface IStepRef {
    validate: () => boolean
}

interface ITenantStep {
    defaultId: string
    loading: boolean
    control: number
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
    editTenants: boolean
    setEditTenants: (editTenants: any) => void
    tenants: customers_proposalAttributes[]
    setTenants: React.Dispatch<React.SetStateAction<customers_proposalAttributes[]>>
}

const titleText = "Dados do(s) Locatário(s)"

const descriptionText = "Informe abaixo os dados cadastrais do(s) locatário(s)"

const TenantStepComponent: React.ForwardRefRenderFunction<IStepRef, ITenantStep> = (props, ref) => {
    const { changeProposal, control, setTenants, tenants, editTenants, setEditTenants, setUpdatedAt, defaultId, loading, setLoading } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [defaultData, setDefaultData] = useState<any>({})
    const [defaultTenants, setDefaultTenants] = useState<any>([])
    const [consultsOptions, setConsultsOptions] = useState<IOptionsProps[]>([])
    const [tenantsConsults, setTenantsConsults] = useState<customers_proposalAttributes[]>([])

    const formRef = useRef<ITenantFormRefProps>(null)

    const onSucessSubmit = useCallback((data: any) => {
        setTenants([...data])
        setEditTenants(false)
        setUpdatedAt(new Date())
        changeProposal(3)
    }, [])

    const searchTenants = useCallback(async () => {
        const { data } = await api.get(`/proposals/queries/${defaultId}`)

        const all_tenants = data.filter((consult: customers_proposalAttributes) => ["tenant", "tenant_spouse"].includes(consult.relation))
        setTenantsConsults([...all_tenants])

        const tenants_includes = all_tenants.filter((consult: customers_proposalAttributes) => consult.include)
        setTenants([...tenants_includes])

        let _defaultData: any = {}
        const defaultDataTenants = tenants_includes.map((tenant: any) => {
            const _uniqueId = uniqueId()
            const label = getPersonLabel({ person: tenant?.customer })
            const value = tenant?.customer?.id
            const _this = tenant?.customer
            const _relation = consultsOptionsRelations.find((e) => e?.value === tenant?.relation)
            return {
                ["customer_id-" + _uniqueId]: { label, value, relation: tenant?.relation, this: _this },
                ["relation-" + _uniqueId]: _relation,
                uniqueId: _uniqueId
            }
        })

        setDefaultTenants([...defaultDataTenants])

        for (const _tenant of defaultDataTenants) _defaultData = { ..._defaultData, ..._tenant }

        setDefaultData({ ..._defaultData })
    }, [])

    const searchConsultsOptions = useCallback(async () => {
        const response = await api.get(`/proposals/queries/${defaultId}`)
        const consults_result: IOptionsProps[] = response.data.filter((consult: customers_proposalAttributes) => ["tenant", "tenant_spouse"].includes(consult.relation)).map((consult: customers_proposalAttributes) => {
            return {
                relation: consult?.relation,
                label: getPersonLabel({ person: consult.customer as any }),
                value: consult?.customer?.id,
                spouse: consult?.customer?.spouse,
                this: consult?.customer
            }
        }) as IOptionsProps[]
        setConsultsOptions([...consults_result])
    }, [defaultId])

    const search = useCallback(async () => {
        setLoading(true)

        try {

            await Promise.all([searchTenants(), searchConsultsOptions()])

        } catch (err) {

            notify("Erro ao Buscar Locatário(s) da Proposta", "error")

        }
        setLoading(false)
    }, [])

    const validate = useCallback(() => {
        return tenants.length > 0
    }, [tenants])

    useImperativeHandle(ref, () => ({ validate }))

    useEffect(() => { search() }, [control])

    useEffect(() => { setEditTenants(false) }, [])

    return (
        <Column style={{ gap: 20, flex: 1 }}>

            <ContentOrLoading type="wave" loading={loading} theme={theme}>
                <>
                    <Line style={{ justifyContent: "space-between" }}>

                        <Column>
                            <Title theme={theme}>{titleText}</Title>
                            <div>{descriptionText}</div>
                        </Column>

                        <div>
                            {!editTenants &&
                                <EditButton
                                    onClick={() => setEditTenants((atual: any) => !atual)}
                                    theme={theme}
                                    children={(
                                        <>
                                            <div style={{ fontWeight: 600 }}>Editar</div>
                                            <HiOutlinePencilAlt size={20} />
                                        </>
                                    )}
                                />
                            }
                            {editTenants &&
                                <EditButton
                                    onClick={() => formRef.current?.forceSubmit()}
                                    theme={theme}
                                    children={(
                                        <>
                                            <div style={{ fontWeight: 600 }}>Salvar</div>
                                            <HiOutlineSave size={20} />
                                        </>
                                    )}
                                />
                            }
                        </div>
                    </Line>

                    <TenantForm
                        proposalId={defaultId}
                        tenantsConsults={tenantsConsults}
                        consultsOptions={consultsOptions}
                        defaultData={defaultData}
                        defaultTenants={defaultTenants}
                        onSuccess={onSucessSubmit}
                        isDisabled={!editTenants}
                        ref={formRef}
                    />

                </>
            </ContentOrLoading>

        </Column>
    )
}

export const TenantStep = React.forwardRef(TenantStepComponent)