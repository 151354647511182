import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { ContainerMessage } from "./style";
import { Line, Column } from "../../style";
import { BsArrowRight } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { useGlobal } from "../../hooks/global";
import { BiMessageCheck } from "react-icons/bi";
import { messagesAttributes } from "../../interfaces";
import { proposal_status } from "../../utils/proposal_status";

interface MessageInterface {
    message: messagesAttributes
    main_id: string
    main_photo?: string
    onConfirmResolution: (id: string) => void
}

const isRevert = (message: messagesAttributes, main_id: string) => {
    if (message.sender_id === main_id) return true
    return false
}

const getDate = (date: string, complete: boolean = true) => {
    const dateObj = new Date(date)
    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1
    const year = dateObj.getFullYear()
    const hour = dateObj.getHours()
    const minutes = dateObj.getMinutes()
    if (complete) return `${day >= 10 ? day : `0${day}`}/${month >= 10 ? month : `0${month}`}/${year} às ${hour >= 10 ? hour : `0${hour}`}:${minutes >= 10 ? minutes : `0${minutes}`}`
    else return `${day >= 10 ? day : `0${day}`}/${month >= 10 ? month : `0${month}`}/${year} ${hour >= 10 ? hour : `0${hour}`}:${minutes >= 10 ? minutes : `0${minutes}`}`
}

const correctName = (message: messagesAttributes) => {
    return message.sender?.person?.registration_data?.name ? message.sender?.person?.registration_data?.name : "NÃO INFORMADO"
}

const correctStatusOfExport: any = {
    "exported_error": "Erro",
    "exported_sucess": "Sucesso",
    "exported_init": "Iniciado",
}

export const Message: React.FC<MessageInterface> = (props) => {
    const { message, main_id, onConfirmResolution, main_photo } = props

    const { user } = useApi()
    const { theme } = useGlobal()

    if (message.type === "first_analyst") return (
        <Line style={{ gap: 10, flexDirection: isRevert(message, main_id) ? "row-reverse" : "row" }}>
            <div>
                {main_photo && <div style={{ background: `url("${main_photo}")`, maxHeight: 26, maxWidth: 26, minWidth: 26, minHeight: 26, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }} />}
                {!main_photo &&
                    <div style={{ background: theme === "dark" ? "#282828" : "rgb(231 231 231)", borderRadius: "50%" }}>
                        <FaUserCircle size={26} />
                    </div>
                }
            </div>
            <ContainerMessage revert={isRevert(message, main_id)} theme={theme}>
                <Line style={{ justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <div style={{ fontWeight: 600 }}>{correctName(message)}</div>
                    {!message.isFlag ? (
                        <div style={{ fontSize: 12 }}>{getDate(message.createdAt, false)}</div>
                    ) : <CircularProgress size={20} style={{ color: secondary[theme] }} />}
                </Line>
                <div>
                    Olá{message.text ? (" " + message.text) : ""}, a partir de agora estarei analisando a
                    sua proposta, esse canal é apenas para acompanhamento e resolução de demandas,
                    para maior agilidade na aprovação da locação, certifique-se de que o preenchimento
                    da proposta está seguindo rigorosamente a política da imobiliária, em breve volto a
                    entrar em contato.
                    <br />
                    <br />
                    Atenciosamente ;)
                </div>
            </ContainerMessage>
        </Line>
    )
    if (message.type === "change_status") return (
        <Line style={{ gap: 10, flexDirection: isRevert(message, main_id) ? "row-reverse" : "row" }}>
            <div>
                {main_photo && <div style={{ background: `url("${main_photo}")`, maxHeight: 26, maxWidth: 26, minWidth: 26, minHeight: 26, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }} />}
                {!main_photo &&
                    <div style={{ background: theme === "dark" ? "#282828" : "rgb(231 231 231)", borderRadius: "50%" }}>
                        <FaUserCircle size={26} />
                    </div>
                }
            </div>
            <ContainerMessage revert={isRevert(message, main_id)} theme={theme}>
                <Line style={{ justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <div style={{ fontWeight: 600 }}>{correctName(message)}</div>
                    {!message.isFlag ? (
                        <div style={{ fontSize: 12 }}>{getDate(message.createdAt, false)}</div>
                    ) : <CircularProgress size={20} style={{ color: secondary[theme] }} />}
                </Line>
                <div style={{ padding: "10px", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ fontWeight: 600 }}>Mudança de status</div>
                    <Line style={{ gap: 10, alignItems: "center" }}>
                        <div style={{ fontSize: 14 }}>{proposal_status[message.old_status as string].text}</div>
                        <BsArrowRight />
                        <div style={{ fontSize: 14 }}>{proposal_status[message.new_status as string].text}</div>
                    </Line>
                </div>
                <div>{message.text}</div>
            </ContainerMessage>
        </Line>
    )
    if (["exported_sucess", "exported_error", "exported_init"].includes(message.type)) return (
        <Line style={{ gap: 10, flexDirection: isRevert(message, main_id) ? "row-reverse" : "row" }}>
            <div>
                {main_photo && <div style={{ background: `url("${main_photo}")`, maxHeight: 26, maxWidth: 26, minWidth: 26, minHeight: 26, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }} />}
                {!main_photo &&
                    <div style={{ background: theme === "dark" ? "#282828" : "rgb(231 231 231)", borderRadius: "50%" }}>
                        <FaUserCircle size={26} />
                    </div>
                }
            </div>
            <ContainerMessage revert={isRevert(message, main_id)} theme={theme}>
                <Line style={{ justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <div style={{ fontWeight: 600 }}>{correctName(message)}</div>
                    {!message.isFlag ? (
                        <div style={{ fontSize: 12 }}>{getDate(message.createdAt, false)}</div>
                    ) : <CircularProgress size={20} style={{ color: secondary[theme] }} />}
                </Line>
                <div style={{ padding: "10px", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div style={{ fontWeight: 600, textAlign: "center" }}>Exportação da Proposta</div>
                    <Line style={{ gap: 10, alignItems: "center" }}>
                        <div style={{ fontSize: 14, minWidth: 45 }}>Status</div>
                        <BsArrowRight />
                        <div style={{ fontSize: 14 }}>{correctStatusOfExport[message.type]}</div>
                    </Line>
                    <Line style={{ gap: 10, alignItems: "center" }}>
                        <div style={{ fontSize: 14, minWidth: 45 }}>Motivo</div>
                        <BsArrowRight />
                        <div style={{ fontSize: 14 }}>{message.text}</div>
                    </Line>
                </div>
            </ContainerMessage>
        </Line>
    )
    return (
        <Line style={{ gap: 10, flexDirection: isRevert(message, main_id) ? "row-reverse" : "row" }}>
            <div>
                {main_photo && <div style={{ background: `url("${main_photo}")`, maxHeight: 26, maxWidth: 26, minWidth: 26, minHeight: 26, borderRadius: "50%", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }} />}
                {!main_photo &&
                    <div style={{ background: theme === "dark" ? "#282828" : "rgb(231 231 231)", borderRadius: "50%" }}>
                        <FaUserCircle size={26} />
                    </div>
                }
            </div>
            <ContainerMessage revert={isRevert(message, main_id)} theme={theme}>
                <Line style={{ justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <div style={{ fontWeight: 600 }}>{correctName(message)}</div>
                    {!message.isFlag ? (
                        <div style={{ fontSize: 12 }}>{getDate(message.createdAt, false)}</div>
                    ) : <CircularProgress size={20} style={{ color: secondary[theme] }} />}
                </Line>
                {message.text}
                {message.type === "requirement" && (
                    <Column>
                        {["responsible", "broker", "manager"].includes(user.type) &&
                            <div style={{ display: "flex" }}>
                                <Line onClick={() => !message.resolved ? onConfirmResolution(message.id) : () => { }} style={{ background: (message.resolved) ? (theme === "light" ? "#C4C4C4" : "rgb(92 92 92)") : secondary[theme], padding: 5, alignItems: "center", gap: 5, cursor: (message.resolved) ? "default" : "pointer", color: "#FFF", borderRadius: 4 }}>
                                    {message.isFlagResolution ? <CircularProgress size={18} style={{ color: "#FFF" }} /> : <BiMessageCheck />}
                                    {message.resolved ? "Resolvido" : "Marcar como resolvido"}
                                </Line>
                            </div>
                        }
                        {user.type === "analyst" &&
                            <div style={{ display: "flex" }}>
                                <Line style={{ background: (theme === "light" ? "#C4C4C4" : "rgb(92 92 92)"), padding: 5, alignItems: "center", gap: 5, cursor: "default", color: "#FFF", borderRadius: 4 }}>
                                    <BiMessageCheck />
                                    {message.resolved ? "Resolvido" : "Aguardando resolução"}
                                </Line>
                            </div>
                        }
                        {message.resolved &&
                            <div style={{ fontStyle: "italic", fontSize: 12, color: theme === "light" ? "#C4C4C4" : "rgb(92 92 92)" }}>
                                Resolvido em <span>{getDate(message.updatedAt)}</span>
                            </div>
                        }
                    </Column>
                )}
            </ContainerMessage>
        </Line>
    )
}