import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../../../interfaces";
import { borderColors, primary, secondary } from "../../../../../theme";

export const Option = styled.div<IThemeProps>`
    gap: 10px;
    cursor: pointer;
    min-width: 250px;
    flex: 1;
    justify-content: space-between;
    border-radius: 5px;
    padding: 10px;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
        border: 1px solid ${borderColors[props.theme as themeTypes]};
    `}
`

export const Form = styled.form`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const ShoppingCart = styled.div<IThemeProps>`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 5px;
    padding: 10px;
    background: ${props => primary[props.theme as themeTypes]};
    border: 1px solid ${props => borderColors[props.theme as themeTypes]};
`

export const ContainerCard = styled.div`
    display: flex;
    align-items: flex-end;
    padding-top: 10px;
`

export const ContainerCreditCardData = styled.div`
    display: flex;
    gap: 20px;
    align-items: flex-end;
    flex-wrap: wrap;
`

export const ContainerPaymentMethodsOptions = styled.div`
    display: flex;
    flex: 1;
    gap: 20px;
    flex-wrap: wrap;
`

export const ChangeButton = styled.div<IThemeProps>`
    cursor: pointer;
    font-weight: 600;
    color: ${props => secondary[props.theme as themeTypes]};
`

export const ContainerIconPlan = styled.div<IThemeProps>`
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    min-height: 50px;
    max-height: 50px;
    min-width: 50px;
    max-width: 50px;
    ${props => css`
        background: ${secondary[props.theme as themeTypes]};
    `}
`