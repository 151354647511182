import styled, { css } from "styled-components";
import { themeTypes } from "../../../interfaces";
import { primary, secondary, shadowColor } from "../../../theme";

export const Container = styled.div<{ theme: themeTypes, selected: boolean }>`
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    cursor: pointer;
    padding: 20px;
    min-width: 300px;
    flex:1;
    ${props => css`
        border: 2px solid ${primary[props.theme as themeTypes]};
        background: ${primary[props.theme as themeTypes]};
        box-shadow:0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
    `}
    ${props => props.selected && css`
        border: 2px solid ${secondary[props.theme as themeTypes]};
    `}
`