import React from "react";
import Input from "../../../../Inputs/Input";
import { useGlobal } from "../../../../../hooks/global";
import { MaxCoin } from "../../../../SvgComponents/Icons/MaxCoin";
import { maskFunctions } from "../../../../../services/maskServices";
import { plansAttributes, themeTypes } from "../../../../../interfaces";
import { background, inactiveItens, primary } from "../../../../../theme";
import { Column, ContainerDefineQuantity, ContainerMxCoins, ContainerTable } from "./style";

interface IDefineQuantityProps {
    plans: plansAttributes[]
    plan_selected: string | null | undefined
    setQuantity: Function
    quantity: string
    theme: themeTypes
}

export const DefineQuantity: React.FC<IDefineQuantityProps> = (props) => {
    const { plans, plan_selected, setQuantity, quantity, theme } = props

    const { multiple_mxs } = useGlobal()

    return (
        <div style={{ display: "flex", flexDirection: "column", padding: 20, overflow: "auto", gap: 20, flex: 1 }}>
            <ContainerDefineQuantity>
                <Column style={{ gap: 10 }}>
                    <Column>
                        <div style={{ fontWeight: 600 }}>Informe a quantidade de créditos que deseja comprar</div>
                        Os créditos são utilizados para comprar qualquer coisa dentro da
                        plataforma - como, por exemplo, consultas cadastrais e documentos eletrônicos.
                        Você pode comprar mais quando quiser, sem fidelidade. Os créditos não expiram.
                    </Column>
                    <Column style={{ gap: 5 }}>
                        <Input
                            id="quantity-mxs"
                            onChange={(value: any) => setQuantity(value?.replace(/\D/g, "") || "0")}
                            mask="onlyNumber"
                            required
                        />
                        <div style={{ fontSize: 14 }}>Valor da compra: <b>{maskFunctions.currency.mask(Number.parseInt(quantity) / multiple_mxs)}</b></div>
                    </Column>
                </Column>
                <ContainerMxCoins theme={theme}>
                    <MaxCoin />
                    <div style={{ fontSize: 22, fontWeight: 600, textAlign: "center" }}>
                        {maskFunctions.mxs.mask(quantity)} créditos MXs
                    </div>
                </ContainerMxCoins>
            </ContainerDefineQuantity>
            <Column style={{ flex: 1, gap: 10 }}>
                <Column>
                    <div style={{ fontWeight: 600 }}>Tabela de preços (100 créditos = 1 real)</div>
                    Veja quantos dos seus créditos você consome no uso dos
                    módulos da plataforma
                </Column>
                <ContainerTable theme={theme}>
                    <table cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                        <thead>
                            <tr style={{ background: background[theme] }}>
                                <th style={{ whiteSpace: "nowrap", padding: 10 }}>MÓDULO</th>
                                {plans.filter((plan) => (plan.active || plan.id === plan_selected)).map((plan, index) => (
                                    <th key={`${plan.id}-th`} style={{ whiteSpace: "nowrap", textTransform: "uppercase", textAlign: "center", padding: 10 }}>
                                        Plano {plan.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ background: primary[theme] }}>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Consulta cadastral - PF
                                </td>
                                {plans.filter((plan) => (plan.active || plan.id === plan_selected)).map((plan, index) => (
                                    <td key={`${plan.id}-td-pf`} style={{ whiteSpace: "nowrap", color: plan_selected === plan.id ? "#FFF" : inactiveItens[theme], background: plan_selected === plan.id ? "rgb(147 206 149)" : "transparent", fontWeight: plan_selected === plan.id ? 600 : "normal", textAlign: "center", padding: 10 }}>
                                        {Number.parseInt(`${plan.pf_unit_value * multiple_mxs}`)}
                                    </td>
                                ))}
                            </tr>
                            <tr style={{ background: primary[theme] }}>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Consulta cadastral - PJ
                                </td>
                                {plans.filter((plan) => (plan.active || plan.id === plan_selected)).map((plan, index) => (
                                    <td key={`${plan.id}-td-pj`} style={{ whiteSpace: "nowrap", color: plan_selected === plan.id ? "#FFF" : inactiveItens[theme], background: plan_selected === plan.id ? "rgb(147 206 149)" : "transparent", fontWeight: plan_selected === plan.id ? 600 : "normal", textAlign: "center", padding: 10 }}>
                                        {Number.parseInt(`${plan.pj_unit_value * multiple_mxs}`)}
                                    </td>
                                ))}
                            </tr>
                            <tr style={{ background: primary[theme] }}>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Documento Eletrônico
                                </td>
                                {plans.filter((plan) => (plan.active || plan.id === plan_selected)).map((plan, index) => (
                                    <td key={`${plan.id}-td-doc`} style={{ whiteSpace: "nowrap", color: plan_selected === plan.id ? "#FFF" : inactiveItens[theme], background: plan_selected === plan.id ? "rgb(147 206 149)" : "transparent", fontWeight: plan_selected === plan.id ? 600 : "normal", textAlign: "center", padding: 10 }}>
                                        {Number.parseInt(`${plan.doc_unit_value * multiple_mxs}`)}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </ContainerTable>
            </Column>
        </div >
    )
}