import React, { FormEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EditButton } from "./style";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../../hooks/api";
import { useGlobal } from "../../../../../hooks/global";
import { Label, Column, Line } from "../../../../../style";
import { getPropertyLabel } from "../../../../../utils/labels";
import { ModalLoading } from "../../../../Loadings/ModalLoading";
import { HiOutlinePencilAlt, HiOutlineSave } from "react-icons/hi";
import { maskFunctions } from "../../../../../services/maskServices";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { background, borderColors, secondary } from "../../../../../theme";
import { GenericForm, IGenericFormRefProps } from "../../../../GenericForm";
import { destinationsOptions, get_corrrect_names, ownerRelationsOptions } from "../../../../../utils/options";
import { IGroupProps, IOptionsProps, propertiesAttributes, properties_customersAttributes } from "../../../../../interfaces";

export interface IStepRef {
    validate: () => boolean
}

interface IPropertyStep {
    defaultId: string
    control: number
    loading: boolean
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
    proposal: { property: IOptionsProps | undefined, contract_type: IOptionsProps | null | undefined }
    setProposal: React.Dispatch<React.SetStateAction<{ property: IOptionsProps | undefined, contract_type: IOptionsProps | null | undefined }>>
    editProperty: boolean
    setEditProperty: (editProperty: any) => void
}

const PropertyStepComponent: React.ForwardRefRenderFunction<IStepRef, IPropertyStep> = (props, ref) => {
    const { defaultId, control, proposal, setProposal, setUpdatedAt, loading, setLoading, changeProposal, editProperty, setEditProperty } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const { register, handleSubmit, control: controlForm, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        mode: "all"
    });

    const _form = watch()

    const [loadingSave, setLoadingSave] = useState<boolean>(false)

    const form_ref = useRef<IGenericFormRefProps>(null)
    const submitButtonRef = useRef<HTMLButtonElement>(null)

    const searchProposal = useCallback(async () => {

        setLoading(true)

        try {

            const { data } = await api.get(`/proposals/property/${defaultId}`)

            const old_property: propertiesAttributes = data.property

            const old_contract_type = destinationsOptions.find((item) => item.value === data.destination_type)

            let correct_property: any = undefined
            if (old_property) {
                correct_property = {}
                correct_property.this = old_property
                correct_property.label = getPropertyLabel({ property: old_property })
                correct_property.value = old_property.id
            }

            setProposal(() => ({ property: correct_property, contract_type: old_contract_type }))

            setValue("property_id", correct_property)

            setValue("destination_type", old_contract_type)

        } catch (err) {

            notify("Erro ao buscar Proposta", "error")

        }

        setLoading(false)
    }, [defaultId])

    const onSubmit = useCallback(async (data: any) => {
        setUpdatedAt(new Date())

        setLoadingSave(true)

        changeProposal(2)

        data.property_id = data.property_id ? data.property_id : null

        data.destination_type = data?.destination_type

        const form = form_ref.current?.getForm()

        try {

            await api.put(`/proposals/property/${defaultId}`, form)

        } catch (err: any) {

            notify("Erro ao salvar Imóvel", "error")

        }

        setEditProperty(false)

        setLoadingSave(false)
    }, [form_ref, api, _form])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, api, _form])

    const validate = useCallback(() => {
        return proposal.property?.value && proposal.contract_type?.value && !editProperty
    }, [proposal, editProperty])

    useImperativeHandle(ref, () => ({ validate }))

    useEffect(() => { searchProposal() }, [control])

    useEffect(() => { setEditProperty(false) }, [])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        label: "Imóvel",
                        name: "property_id",
                        type: "select-property",
                        isClearable: true,
                        required: true,
                        moreQueries: { occupation: "not_have" },
                        executeOnChange: async (data: any) => {
                            const destination_type = data?.this?.destination_type
                            if (destination_type) setValue("destination_type", destinationsOptions.find((item) => item.value === destination_type))
                        }
                    }
                ],
                [
                    {
                        label: "Tipo de Contrato",
                        name: "destination_type",
                        type: "select-fixed",
                        isClearable: true,
                        required: true,
                        options: destinationsOptions
                    }
                ]
            ]
        }
    ]

    return (
        <Column style={{ gap: 20, flex: 1 }}>

            <ContentOrLoading type="wave" loading={loading} theme={theme}>
                <>

                    <Line style={{ justifyContent: "space-between" }}>
                        <Column>
                            <div style={{ fontWeight: 600, color: secondary[theme], fontSize: 20 }}>
                                Selecione o Imóvel
                            </div>
                            <div>
                                Selecione o imóvel no campo abaixo ou cadastre um novo para vincular ao contrato
                            </div>
                        </Column>
                        <div>
                            {!editProperty &&
                                <EditButton onClick={() => setEditProperty((atual: any) => !atual)} theme={theme} style={{ gap: 5, alignItems: "center" }}>
                                    <div style={{ fontWeight: 600 }}>Editar</div>
                                    <HiOutlinePencilAlt size={20} />
                                </EditButton>
                            }
                            {editProperty &&
                                <EditButton onClick={() => submitButtonRef.current?.click()} theme={theme} style={{ gap: 5, alignItems: "center" }}>
                                    <div style={{ fontWeight: 600 }}>Salvar</div>
                                    <HiOutlineSave size={20} />
                                </EditButton>
                            }
                        </div>
                    </Line>
                    <form onSubmit={handleStopPropagation} style={{ gap: 20, display: "flex", flexDirection: "column" }}>
                        <GenericForm
                            ref={form_ref}
                            groups={groups}
                            _form={_form}
                            control={controlForm}
                            trigger={trigger}
                            errors={errors}
                            setValue={setValue}
                            register={register}
                            disabled={!editProperty}
                        />
                        {_form.property_id?.value && (
                            <Column>
                                <Label>
                                    Proprietário(s)
                                </Label>
                                <Column style={{ gap: 10 }}>
                                    <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background[theme] }}>
                                                <tr>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>NOME</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>CPF/CNPJ</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>PARTICIPAÇÃO</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>RELACIONAMENTO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {_form.property_id?.this?.owners.map((item: properties_customersAttributes) => (
                                                    <tr key={item.id}>
                                                        <td style={{ padding: 10, whiteSpace: "nowrap", textTransform: "uppercase" }}>
                                                            {item.customer?.registration_data?.name}
                                                        </td>
                                                        <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                                            {maskFunctions.cpfOrCnpj.mask(item.customer?.registration_data?.type === "PF" ? item.customer?.registration_data?.cpf : item.customer?.registration_data?.cnpj)}
                                                        </td>
                                                        <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                                            {Number.parseFloat(`${item.porcen}`).toFixed(2).replace(".", ",")}%
                                                        </td>
                                                        <td style={{ padding: 10, whiteSpace: "nowrap", textTransform: "uppercase" }}>
                                                            {get_corrrect_names(ownerRelationsOptions)[item.type as any]}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Column>
                                </Column>
                            </Column>
                        )}
                        <button
                            className="display-none"
                            type="submit"
                            ref={submitButtonRef}
                        />
                    </form>
                </>
            </ContentOrLoading>

            <ModalLoading theme={theme} loading={loadingSave} />
        </Column>
    )
}

export const PropertyStep = forwardRef(PropertyStepComponent)