import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import React, { useCallback, useEffect } from "react";
import { io, Socket } from "socket.io-client";
import { useApi } from "../../hooks/api";
import { IoClose } from "react-icons/io5";
import { BiUserCircle } from "react-icons/bi";
import { useGlobal } from "../../hooks/global";
import { useNavigate } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";
import { useWallet } from "../../hooks/wallet";
import { Notifications } from "./Notifications";
import { RiLoginBoxLine } from "react-icons/ri";
import { ProfileButton } from "../Buttons/Profile";
import { CircularProgress } from "@material-ui/core";
import { LogoDark } from "../SvgComponents/Logo/Dark";
import { LogoLight } from "../SvgComponents/Logo/Light";
import { background, fail, secondary } from "../../theme";
import { maskFunctions } from "../../services/maskServices";
import { correctDate } from "../../services/generalServices";
import { FiAlertCircle, FiMenu, FiPlusCircle } from "react-icons/fi";
import { MaxCoinSmall20 } from "../SvgComponents/Icons/MaxCoinSmall20";
import {
  subscription_description_header,
  subscription_situations_header,
} from "../../utils/subscriptionSituantions";
import {
  Button,
  ButtonFranchise,
  ButtonMaxCoins,
  Container,
  ContainerLogo,
  Content,
  Logo,
  SwitchContainer,
} from "./style";
import { AiFillCamera } from "react-icons/ai";
import { ModalAvatar } from "../Modals/ModalAvatar";

interface HeaderProps {
  chooseFranchise?: boolean;
}

const options: any = {
  root: [{ name: "Sair", icon: <RiLoginBoxLine size={16} /> }],
  responsible: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Dados da Empresa", icon: <FaRegBuilding size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
  manager: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
  analyst: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
  broker: [
    { name: "Meu Perfil", icon: <BiUserCircle size={16} /> },
    { name: "Alterar foto", icon: <AiFillCamera size={16} /> },
    { name: "Sair", icon: <RiLoginBoxLine size={16} /> },
  ],
};

const addDays = (date: any, days: number) => {
  const copy = new Date(date);
  copy.setDate(date.getDate() + days);
  return copy;
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { chooseFranchise } = props;

  const navigate = useNavigate();

  const { signOut, user, notifications, setNotifications, api } = useApi();
  const { loading_all, loading_wallet, wallet, subscription } = useWallet();
  const {
    playNotificationSound,
    activity,
    setMenuOpen,
    seeNotification,
    setSeeNotification,
    openSelect,
    menuOpen,
    changeFranchiseGlobal,
    multiple_mxs,
    franchise,
    setTheme,
    theme,
    notify,
    menuItemSelected,
    setSubMenuOpen,
    subMenuOpen,
  } = useGlobal();

  const [modalAvatarOpen, setModalAvatarOpen] = React.useState(false);
  const [socket, setSocket] = React.useState<Socket>();

  const clickButtonMenu = useCallback(() => {
    if (subMenuOpen) setSubMenuOpen(false);
    else setMenuOpen(!menuOpen);
  }, [menuOpen, menuItemSelected, subMenuOpen]);

  const toggleFullScreen = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreen) document.documentElement.requestFullscreen();
      else document.exitFullscreen();
    }
  };

  const clickInOption = useCallback((id, option) => {
    if (option === "Sair") signOut();
    else if (option === "Meu Perfil") navigate("/perfil");
    else if (option === "Dados da Empresa")
      navigate("/settings/real_estate/company-data");
    else if (option === "Alterar foto") setModalAvatarOpen(true);
  }, []);

  const searchNotifications = useCallback(async () => {
    try {
      const result = await api.get("/notifications");
      setNotifications([...result.data]);
    } catch (err) {
      notify("Erro ao buscar notificações", "error");
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const socketInstance = io(`${process.env.REACT_APP_API}`);

    setSocket((atual) => {
      atual?.removeAllListeners();
      atual?.disconnect();
      return socketInstance;
    });

    if (socketInstance && user) {
      searchNotifications().then(() => {
        socketInstance?.on(`${user.id}-notifications`, async () => {
          await searchNotifications();
          playNotificationSound(activity);
        });
      });
    }
  }, [user, activity]);

  return (
    <Container id="header-content" theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minWidth: "100vw",
        }}
      >
        {seeNotification &&
          subscription?.situation &&
          ["paid-free", "waiting-alert"].includes(subscription?.situation) &&
          ["responsible"].includes(user.type) && (
            <div
              style={{
                padding: 16,
                paddingRight: 26,
                background: "#FDEAE8",
                display: "flex",
                gap: 10,
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  flex: 1,
                }}
              >
                <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                  <FiAlertCircle size={23} color={fail} />
                  <b style={{ fontSize: 18 }}>
                    {
                      subscription_situations_header[subscription?.situation]
                        .text
                    }
                  </b>
                </div>
                <div style={{ marginLeft: 28 }}>
                  {subscription?.situation === "waiting-alert" && (
                    <>
                      {subscription_description_header[subscription?.situation]}{" "}
                      {correctDate(
                        addDays(
                          new Date(
                            subscription?.transactions?.[0]?.createdAt as any
                          ),
                          7
                        ),
                        { month_in_extension: true }
                      )}
                      . Caso já tenha pago, ignore essa notificação.
                      <b
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/billing/subscription")}
                      >
                        {" "}
                        Clique aqui para reguralizar a sua situação
                      </b>
                    </>
                  )}
                  {subscription?.situation === "paid-free" && (
                    <>
                      {subscription_description_header[subscription?.situation]}{" "}
                      Encerrando este período no dia{" "}
                      {correctDate(
                        addDays(
                          new Date(
                            subscription?.transactions?.[0]?.createdAt as any
                          ),
                          7
                        ),
                        { month_in_extension: true }
                      )}
                      .
                      <b
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/billing/subscription")}
                      >
                        {" "}
                        Clique aqui para assinar um novo plano
                      </b>
                    </>
                  )}
                </div>
              </div>
              <div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setSeeNotification(false)}
                >
                  <IoClose size={20} />
                </div>
              </div>
            </div>
          )}
        <Content>
          <ContainerLogo chooseFranchise={!!chooseFranchise}>
            <Logo chooseFranchise={!!chooseFranchise}>
              {theme === "dark" && <LogoDark size={40} />}
              {theme === "light" && <LogoLight size={40} />}
            </Logo>
            {!chooseFranchise && (
              <FiMenu
                onClick={clickButtonMenu}
                style={{ cursor: "pointer" }}
                color={background[theme === "dark" ? "light" : "dark"]}
                size={30}
              />
            )}
          </ContainerLogo>
          <div style={{ display: "flex", alignItems: "center" }}>
            {user.type === "responsible" && !chooseFranchise && (
              <>
                <ButtonMaxCoins
                  theme={theme}
                  onClick={() => navigate("/billing/wallet")}
                >
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    <div style={{ opacity: 0.6, fontSize: 12 }}>
                      Créditos MXs
                    </div>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 5 }}
                    >
                      {!(loading_all || loading_wallet) && (
                        <>
                          <MaxCoinSmall20 />
                          <div style={{ fontSize: 14 }}>
                            {maskFunctions.mxs.mask(
                              (wallet?.balance ?? 0) * multiple_mxs
                            )}
                          </div>
                        </>
                      )}
                      {(loading_all || loading_wallet) && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            minHeight: 21,
                          }}
                        >
                          <CircularProgress
                            size={20}
                            style={{ color: "#FFF" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <FiPlusCircle size={26} />
                </ButtonMaxCoins>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ButtonFranchise
                    onClick={() => changeFranchiseGlobal({} as any)}
                    theme={theme}
                  >
                    <div style={{ opacity: 0.6, fontSize: 12 }}>
                      Imobiliária
                    </div>
                    <div style={{ fontSize: 14, textTransform: "uppercase" }}>
                      {franchise.registration_data?.fantasy_name}
                    </div>
                  </ButtonFranchise>
                  <div
                    style={{
                      borderRight: "1px solid #C4C4C4",
                      marginRight: 10,
                      height: 40,
                    }}
                  />
                </div>
              </>
            )}
            <SwitchContainer>
              <Switch
                id="tooltip_switch"
                className="custom-switch custom-switch-primary custom-switch-small"
                checked={theme === "dark"}
                onChange={(value) => setTheme(value ? "dark" : "light")}
              />
            </SwitchContainer>

            <Button onClick={toggleFullScreen} isCollapse theme={theme}>
              <i
                className="i-Full-Screen"
                color="#FFF"
                data-fullscreen
                style={{ color: "#6c757d", fontSize: 22 }}
              />
            </Button>

            {((user.type === "responsible" && franchise?.id) ||
              user.type !== "responsible") && (
              <div className="notifications" style={{ position: "relative" }}>
                <Button theme={theme} className="notifications">
                  <i
                    className="i-Bell notifications"
                    color="#FFF"
                    data-fullscreen
                    style={{ color: "#6c757d", fontSize: 22 }}
                  />
                  {notifications.length > 0 && (
                    <div
                      className="notifications"
                      style={{ position: "absolute", top: 5, right: 5 }}
                    >
                      <div
                        className="notifications"
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: "50%",
                          background: secondary[theme],
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#FFF",
                          fontSize: 8,
                        }}
                      >
                        {notifications.length > 9 ? "+9" : notifications.length}
                      </div>
                    </div>
                  )}
                </Button>
                {["notifications", "i-Bell notifications"].includes(
                  openSelect
                ) && <Notifications notifications={notifications} />}
              </div>
            )}

            <ProfileButton
              id="profile-button"
              photo={
                user.photo
                  ? user.photo
                  : user.facebook_picture
                  ? user.facebook_picture
                  : null
              }
              onClick={clickInOption}
              options={options[user.type]}
              theme={theme}
            />
          </div>
        </Content>
      </div>
      {modalAvatarOpen && (
        <ModalAvatar
          onClose={() => setModalAvatarOpen(false)}
          theme={theme}
          photo={user.photo_public_id}
        />
      )}
    </Container>
  );
};
