import styled, { css } from "styled-components";
import { divider, fail, inactiveItens } from "./theme";
import { IThemeProps, themeTypes } from "./interfaces";

interface IAddButtonProps extends IThemeProps {
    noHaveDelete?: boolean;
}

export const Label = styled.label`
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    /* color: rgb(134 134 134); */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
`

export const ContainerSelect = styled.div`
    display: flex;
    min-width: 250px;
    flex-direction: column;
    flex: 1;
    text-transform: uppercase;
`

export const Column = styled.div`
    display:flex ;
    flex-direction:column;
`

export const Line = styled.div`
    display:flex ;
    flex-direction:row;
`

export const LineJCenter = styled(Line)`
    justify-content:center;
`

export const LineJBetween = styled(Line)`
    justify-content:space-between;
`

export const LineACenter = styled(Line)`
    align-items:center;
`

export const LineABetween = styled(Line)`
    align-items:center;
`

export const LineAllCenter = styled(Line)`
    align-items:center;
    justify-content:center;
`

export const FieldError = styled.span`
    font-size: 12px;
    color: ${fail};
    position: absolute;
    bottom: -20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
`

export const ContainerField = styled(Column)`
    gap:5px;
    position: relative;
`

export const ContainerFormModal = styled(Column)`
    flex: 1;
    overflow: auto;
    gap: 20px;
    padding: 20px;
`

export const ModalContainerButtons = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    border-top: 1px solid ${props => divider[props.theme as themeTypes]};
`

export const AddButton = styled.div<IAddButtonProps>`
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: ${props => inactiveItens[props.theme as themeTypes]};
    ${props => !props.noHaveDelete && css`
        margin-left: 10px;
    `}
`   