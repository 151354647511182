import React from "react";
import { TooltipProps, Tooltip as BaseTooltip, IconButton } from "@material-ui/core";

interface ITooltipProps extends TooltipProps {
}

export const Tooltip: React.FC<ITooltipProps> = (props) => {
    const { children, ...rest } = props

    return (
        <BaseTooltip {...rest}>
            <IconButton aria-label={props.title ? `${props.title}` : ""}>
                {children}
            </IconButton>
        </BaseTooltip>
    )
}