import * as React from "react";
import Time from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { inactiveItens } from "../../theme";
import { useGlobal } from "../../hooks/global";

interface IEvents {
    left: any;
    icon: any;
    right: any;
    type?: string
}

interface ITimeline {
    events: IEvents[];
    center?: boolean;
    noMaxWidth?: boolean;
}

export const Timeline: React.FC<ITimeline> = (props) => {
    const { events, noMaxWidth, center } = props

    const { theme, controlResize } = useGlobal()

    const children = (
        <Time style={{ margin: 0, padding: 0 }} position="right">
            {events.map((event, index) => (
                <TimelineItem key={"event" + index} style={{ margin: 0, padding: 0, marginBottom: event.type === "title" ? -20 : 0 }}>
                    <TimelineOppositeContent style={{ fontWeight: event.type === "title" ? 600 : undefined, textAlign: center ? "right" : undefined, maxWidth: noMaxWidth ? undefined : 175, minWidth: 160, fontFamily: "Poppins", paddingRight: event.type === "title" ? 10 : 20, color: inactiveItens[theme] }} color="text.secondary">
                        {event.left}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <div style={{ textAlign: "center", position: "relative", fontWeight: event.type === "title" ? 600 : undefined, fontFamily: "Poppins", minHeight: event.type !== "result" ? 40 : 80, minWidth: event.type === "title" ? 40 : 40, paddingTop: 6, paddingBottom: 6 }}>
                            {event.type !== "title" && (
                                <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                                    {event.icon}
                                </div>
                            )}
                            {event.type === "title" && (
                                <>
                                    {event.icon}
                                </>
                            )}
                        </div>
                        {index < (events.length - 1) && event.type !== "title" && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent style={{ fontWeight: event.type === "title" ? 600 : undefined, paddingLeft: event.type === "title" ? 10 : 20, textAlign: center ? "left" : undefined, fontFamily: "Poppins" }}>
                        {event.right}
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Time>
    )
    return (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
            {controlResize && children}
            {!controlResize && children}
        </div>
    );
}
