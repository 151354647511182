import React from "react"

export const MaxCoin = ({ size, ...props }: any) => (
  <svg
    width={117}
    height={117}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={58.5} cy={58.5} r={58.5} fill="#EFAA35" />
    <circle cx={58.792} cy={58.208} r={48.847} fill="#FCD634" />
    <path
      d="M58.5 23.985v39.206L42.058 79.788a7.143 7.143 0 0 1-3.675 1.976 7.103 7.103 0 0 1-4.143-.421 7.178 7.178 0 0 1-3.211-2.675 7.274 7.274 0 0 1-1.194-4.025V55.958a7.254 7.254 0 0 1 1.627-4.605c.298-.35.621-.678.966-.981L58.5 23.985ZM87.723 56.101v18.565a7.072 7.072 0 0 1-.54 2.763 7.175 7.175 0 0 1-1.579 2.347 7.318 7.318 0 0 1-2.375 1.57 7.422 7.422 0 0 1-2.807.554H65.807a7.4 7.4 0 0 1-5.168-2.12 7.144 7.144 0 0 1-2.139-5.087V63.335l19.81-19.46 6.795 6.703a7.217 7.217 0 0 1 1.953 2.485c.457.959.694 2.006.692 3.065l-.027-.027Z"
      fill="#EFAA35"
    />
  </svg>
)
