import "./style.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Calendar } from "./Calendar";
import { inactiveItens } from "../../theme";
import { ModalGeneric } from "../ModalGeneric";
import { useGlobal } from "../../hooks/global";
import { BiCalendar, BiX } from "react-icons/bi";
import { BaseInput, ContainerRemove } from "./style";
import { copyOf, correctDateRange } from "../../services/generalServices";

interface IDateRange {
    from: any
    to: any
}

interface IRangePicker {
    onChange: Function
    initialRange?: IDateRange
    disabled?: boolean
    noRemove?: boolean
    emptyMessage?: string
}

export interface RangePickerRefProps {
    applyRange: (newValue: { from: any, to: any }) => void
}

const generateDateByDateRange = (date: any) => {
    return date?.day ? new Date(date.year, date.month - 1, date.day) : null
}

const maskDateRange = ({ from, to }: { from: any, to: any }) => {
    const date_from = generateDateByDateRange(from)
    const date_to = generateDateByDateRange(to)
    if (from && to) {
        if (date_from?.getTime() === date_to?.getTime()) return `${correctDateRange(date_from)}`
        return `${correctDateRange(date_from)} - ${correctDateRange(date_to)}`
    }
    if (from) return `${correctDateRange(date_from)}`
    if (to) return `${correctDateRange(date_to)}`
    else return ""
}

const RangePickerComponent: React.ForwardRefRenderFunction<RangePickerRefProps, IRangePicker> = (props, ref) => {
    const { onChange, disabled = false, noRemove = false, initialRange = { from: null, to: null }, emptyMessage } = props

    const { theme } = useGlobal()

    const [range, setRange] = useState<IDateRange>(initialRange)
    const [showCalendar, setShowCalendar] = useState(false)

    const applyRange = (range: IDateRange) => {
        setRange(range)
        setShowCalendar(false)
    }

    useImperativeHandle(ref, () => ({ applyRange }))

    useEffect(() => { onChange(range) }, [range])

    return (
        <>
            <BaseInput theme={theme} disabled={disabled} haveValue={range.from || range.to}>
                <div onClick={() => setShowCalendar(true)} style={{ display: "flex", padding: ".375rem .75rem", flex: 1, height: "100%", alignItems: "center", gap: 5 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <BiCalendar
                            size={19}
                            color={inactiveItens.dark}
                        />
                    </div>
                    {(range.from || range.to) &&
                        <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
                            {maskDateRange(range)}
                        </div>
                    }
                    {!(range.from || range.to) && emptyMessage &&
                        <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
                            {emptyMessage}
                        </div>
                    }
                </div>
                {(range.from || range.to) && !disabled && !noRemove &&
                    <ContainerRemove onClick={() => setRange({ from: null, to: null })}>
                        <BiX size={22} />
                    </ContainerRemove>
                }
            </BaseInput>
            <ModalGeneric open={showCalendar} onClose={() => setShowCalendar(false)}>
                <Calendar
                    apply={applyRange}
                    initialRange={copyOf(range)}
                />
            </ModalGeneric>
        </>
    )
}

export const RangePicker = forwardRef(RangePickerComponent)