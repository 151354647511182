import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EditButton } from "./style";
import { secondary } from "../../../../../theme";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { useGlobal } from "../../../../../hooks/global";
import { HiOutlinePencilAlt, HiOutlineSave } from "react-icons/hi";
import { maskFunctions } from "../../../../../services/maskServices";
import { contractorDataAttributes } from "../../../../../interfaces";
import { ContractorDataForm, IContractorDataFormRefProps } from "./form";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { contractorDataDefaultForm, mapDefaultForm } from "../../../../../utils/defaultFormsUtils";

export interface IStepRef {
    validate: () => boolean
}

interface IPropertyStep {
    defaultId: string
    loading: boolean
    control: number
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
    editContractorData: boolean
    setEditContractorData: (editWarranty: any) => void
    contractorData: contractorDataAttributes | null
    setContractorData: React.Dispatch<React.SetStateAction<contractorDataAttributes | null>>
}

const ContractorDataStepComponent: React.ForwardRefRenderFunction<IStepRef, IPropertyStep> = (props, ref) => {
    const { changeProposal, control, contractorData, setContractorData, defaultId, editContractorData, loading, setEditContractorData, setLoading, setUpdatedAt } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [defaultData, setDefaultData] = useState<any>({})

    const formRef = useRef<IContractorDataFormRefProps>(null)

    const search = useCallback(async () => {

        setLoading(true)

        try {

            const { data } = await api.get(`/proposals/contractor-data/${defaultId}`)
            let { date_end, date_init, occupation, ...rest } = data
            let _defaultData: any = mapDefaultForm({ data, fields: contractorDataDefaultForm })

            _defaultData.rental_fee = {
                type: _defaultData.rental_fee_type,
                value: maskFunctions[_defaultData.rental_fee_type === "VALUE" ? "currency" : "porcen"].mask(_defaultData.rental_fee_value, true)
            }

            _defaultData.managed = {
                type: _defaultData.managed_type,
                value: maskFunctions[_defaultData.managed_type === "VALUE" ? "currency" : "porcen"].mask(_defaultData.managed_value, true)
            }

            setDefaultData({ ..._defaultData })

            setContractorData(data)

        } catch (err) {

            notify("Erro ao Buscar Dados do Contrato", "error")

        }

        setLoading(false)

    }, [])

    const validate = useCallback(() => {
        return !!(contractorData?.date_end && contractorData?.date_init && contractorData?.rent_value && contractorData?.expiration_day && contractorData?.readjustment_index)
    }, [contractorData])

    const onSucessSubmit = useCallback((data: any) => {
        setContractorData({ ...data })
        setEditContractorData(false)
        setUpdatedAt(new Date())
        changeProposal(5)
    }, [])

    useEffect(() => { setEditContractorData(false) }, [])

    useEffect(() => { search() }, [control])

    useImperativeHandle(ref, () => ({ validate }))

    return (
        <Column style={{ gap: 20, flex: 1 }}>
            <ContentOrLoading loading={loading} theme={theme} type="wave">
                <>
                    <Line style={{ justifyContent: "space-between" }}>
                        <Column>
                            <div style={{ fontWeight: 600, color: secondary[theme], fontSize: 20 }}>
                                Dados do Contrato
                            </div>
                            <div>
                                Informe abaixo os dados do contrato de locação
                            </div>
                        </Column>
                        <div>
                            {!editContractorData &&
                                <EditButton
                                    onClick={() => setEditContractorData((atual: any) => !atual)}
                                    theme={theme}
                                    children={(
                                        <>
                                            <div style={{ fontWeight: 600 }}>Editar</div>
                                            <HiOutlinePencilAlt size={20} />
                                        </>
                                    )}
                                />
                            }
                            {editContractorData &&
                                <EditButton
                                    onClick={() => formRef.current?.forceSubmit()}
                                    theme={theme}
                                    children={(
                                        <>
                                            <div style={{ fontWeight: 600 }}>Salvar</div>
                                            <HiOutlineSave size={20} />
                                        </>
                                    )}
                                />
                            }
                        </div>
                    </Line>
                    <ContractorDataForm
                        defaultData={defaultData}
                        isDisabled={!editContractorData}
                        onSuccess={onSucessSubmit}
                        proposalId={defaultId}
                        ref={formRef}
                    />
                </>
            </ContentOrLoading>
        </Column>
    )
}

export const ContractorDataStep = React.forwardRef(ContractorDataStepComponent)