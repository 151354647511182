import styled, { css } from "styled-components";
import { inactiveItens, primary } from "../../../../../theme";
import { IThemeProps, themeTypes } from "../../../../../interfaces";

export const Container = styled.div<IThemeProps>`
    display: flex;
    padding: 20px;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    position: relative;
    border-radius: 0.3125rem;
    outline: 0;
    min-width: 90vw;
    max-width: 90vw;
    @media (min-width: 700px) {
        min-width: 650px;
        max-width: 650px;
    }
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const ContainerText = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`

export const Title = styled.div`
    font-size:22px;
    font-weight: 600;
`

export const Description = styled.div`
    margin-top: 10px;
`