import React, { useEffect, useRef } from "react";
import { useGlobal } from "../../hooks/global";
import { ContainerTabs, Tab, Text } from "./style";

interface tabsProps {
    tabs: tabProps[]
    onChange: Function
    pos?: number
    tabsMaxWidth?: number
    disabled?: boolean
    tabs_full_width?: boolean
}

interface tabProps {
    label: string
    canClick: boolean
}

export const Tabs: React.FC<tabsProps> = (props) => {
    const { tabs, pos = 0, onChange, disabled = false, tabs_full_width } = props

    const { theme } = useGlobal()

    const refTabs = useRef<HTMLDivElement[]>([])
    const ref_div = useRef<HTMLDivElement>(null)

    useEffect(() => {
        let total_scroll = 0
        refTabs.current.forEach((ref, index) => { if (index < pos) total_scroll += ref.offsetWidth })
        total_scroll += pos * 10
        total_scroll -= 20
        ref_div.current?.scrollTo({ left: total_scroll, behavior: "smooth" })
    }, [pos, ref_div, refTabs])

    return (
        <ContainerTabs ref={ref_div}>
            {tabs.map((tab, index) => (
                <Tab
                    ref={(ref: any) => refTabs.current[index] = ref}
                    onClick={() => (tab.canClick && pos !== index && !disabled) ? onChange(index) : {}}
                    active={pos === index && !disabled}
                    canClick={tab.canClick && !disabled}
                    theme={theme}
                    style={{ flex: tabs_full_width ? 1 : "unset" }}
                >
                    <Text style={{ textAlign: "center" }} active={pos === index && !disabled} canClick={tab.canClick && !disabled} theme={theme}>
                        {tab.label}
                    </Text>
                </Tab>
            ))}
        </ContainerTabs>
    )
}