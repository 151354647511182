import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../../../interfaces";
import { inactiveItens, primary, secondary } from "../../../../../theme";

export const OptionAction = styled.div<{ theme: themeTypes, isTop?: boolean, isBottom?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-transform: uppercase;
    :hover{
        cursor: pointer;
        ${props => css`
            background: ${props.theme === "dark" ? "#383838" : "#F6F6F6"};
            
        `}
    }
`

export const AnchorDownload = styled.a<IThemeProps>`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #FFF;
    flex: 1;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    color: #FFF !important;
    ${props => css`
        background: ${secondary[props.theme as themeTypes]};
    `}
`

export const ButtonAction = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    color: #FFF;
    flex: 1;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    ${props => css`
        background: ${secondary[props.theme as themeTypes]};
    `}
`

export const RightSide = styled.div<{ theme: themeTypes, user_type: any }>`
    display: flex;
    flex-direction: column;
    border-top-right-radius: 5px;
    min-width: 460px;
    max-width: 460px;
    border-bottom-right-radius: 5px;
    ${props => css`
        background: ${props.theme === "dark" ? "#282828" : "rgb(231 231 231)"};
        border-left: 1px solid ${props.theme === "dark" ? "#E5E5E5" : "rgb(195 188 188)"};
        max-height: calc(calc(100 * var(--vh)) - 234px);
    `}
`

export const ContainerButtonAction = styled.div<IThemeProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 67px;
    max-height: 67px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-top-right-radius: 5px;
    border-bottom:1px solid #CCCCCC;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
    `}
`

export const ContainerGroupButtonActions = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    transform: translateY(calc(100% + 10px));
    left: 0px;
    right: 0px;
    border-radius: 5px;
    z-index: 100;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]};
        background: ${primary[props.theme as themeTypes]};
        box-shadow: 0.125rem 0.125rem 0.5rem ${props.theme === "dark" ? "#121111" : "#939393"};
    `}
`

export const ButtonSendMessage = styled.button<IThemeProps>`
    display: flex;
    width: 38px;
    height: 28px;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    border: none;
    ${props => css`
        background: ${secondary[props.theme as themeTypes]};
    `}
    ${props => props.disabled && css`
        opacity: 0.7;
        cursor: default;
    `}
`

export const TextAreaMessage = styled.textarea<IThemeProps>`
    border-radius: 4px;
    resize: none;
    width: 100%;
    padding: 10px;
    ${props => css`
        border: ${props.theme === "dark" ? "1px solid rgb(139 139 139)" : "1px solid rgb(201 201 201)"};
        background: ${props.theme === "dark" ? "#383838" : "#F6F6F6"};
        color:${inactiveItens[props.theme as themeTypes]};
    `}
`

export const ContainerMessages = styled.div<{ isBroker: boolean }>`
    display: flex;
    flex:1;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background: url(/assets/chat.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column-reverse;
    overflow: auto;
    max-height: calc(calc(100 * var(--vh)) - 439px);
    ${props => !props.isBroker && css`
        max-height: calc(calc(100 * var(--vh)) - 447px);
    `}
`

export const ConversationHeader = styled.div<{ theme: themeTypes }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap:20px;
    border-bottom: 1px solid #CCCCCC;
    border-top-left-radius: 5px;
`

export const IconMenuDrop = styled.div<{ theme: themeTypes, open?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.1s;
    ${props => props.open && css`
        transform: rotate(90deg);
    `}
    ${props => !props.open && css`
        transform: rotate(0deg);
    `}
`

export const FormMessage = styled.form<{ theme: themeTypes, noBackground: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px;
    padding-bottom: 0px;
    border-bottom-left-radius: 5px; 
    gap: 10px;
    ${props => !props.noBackground && css`
        background: ${primary[props.theme as themeTypes]};
    `}
`