import React, { useCallback, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useApi } from "../../hooks/api";
import { Line, Column } from "../../style";
import { useGlobal } from "../../hooks/global";
import { ModalGeneric } from "../ModalGeneric";
import { IconButton, Tooltip } from "@material-ui/core";
import { DocumentModal } from "../Modals/ModalDocument";
import { ModalLoading } from "../Loadings/ModalLoading";
import { ModalAttention } from "../Modals/ModalAttention";
import { ContentOrLoading } from "../Loadings/ContentOrLoading";
import { MdOutlineCancel, MdOutlineRemoveRedEye } from "react-icons/md";
import { background, borderColors, primary, secondary } from "../../theme";
import { personsAttributes, documentsAttributes, propertiesAttributes } from "../../interfaces";
import { AiOutlineFileGif, AiOutlineFilePdf, AiOutlineFileUnknown, AiOutlineFileWord } from "react-icons/ai";

interface IListDocuments {
    customer: personsAttributes
    property: propertiesAttributes
    relation: string
    loading: boolean
    open: boolean
    documents: documentsAttributes[] | undefined | null
    onSave: Function
    exibition: "grid" | "list"
    colors?: any
}

const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

const getExtension = (file: string) => {
    const extension = file.split(".")
    return extension[extension.length - 1]
}

const isImage = (extension: string) => {
    return ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"].includes(extension?.toLowerCase())
}

const ImageIcon = (extension: any) => {
    if (extension?.toLowerCase() === "gif") return <AiOutlineFileGif size={50} />
    if (extension?.toLowerCase() === "pdf") return <AiOutlineFilePdf size={50} />
    if (extension?.toLowerCase() === "doc" || extension?.toLowerCase() === "docx") return <AiOutlineFileWord size={50} />
    else return <AiOutlineFileUnknown />
}

export const ListDocuments: React.FC<IListDocuments> = (props) => {
    const { customer, exibition, colors = primary, documents, onSave, loading, property, relation } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loadingDestroy, setLoadingDestroy] = useState<boolean>(false)
    const [documentHover, setDocumentHover] = useState<documentsAttributes | undefined | null>(null)
    const [documentDestroy, setDocumentDestroy] = useState<documentsAttributes | undefined | null>(null)
    const [documentSelected, setDocumentSelected] = useState<documentsAttributes | undefined | null>(null)

    const anchor_ref = useRef<HTMLAnchorElement[]>([])

    const confirmDestroy = useCallback(async () => {
        const document_id = documentDestroy?.id
        setDocumentDestroy(null)
        setLoadingDestroy(true)
        try {
            await api.delete(`/documents/${document_id}`)
            onSave()
        } catch (err) {
            notify("Erro ao excluir documento", "error")
        }
        setLoadingDestroy(false)
    }, [documentDestroy])

    return (
        <>
            <ContentOrLoading loading={loading} theme={theme} type="wave">
                {exibition === "list" &&
                    <div style={{ margin: 16, fontSize: 13.5, borderRadius: 4, border: (documents && documents.length > 0) ? `1px solid ${borderColors[theme]}` : "none", overflow: "auto" }}>
                        {documents && documents.length > 0 &&
                            <table cellPadding={0} cellSpacing={0} style={{ width: "100%", borderRadius: 5 }}>
                                <thead>
                                    <tr style={{ background: background[theme] }}>
                                        <th style={{ padding: 10 }}>NOME ARQUIVO</th>
                                        <th style={{ textAlign: "center", padding: 10 }}>TIPO</th>
                                        <th style={{ textAlign: "center", padding: 10 }}>EXTENSÃO</th>
                                        <th style={{ textAlign: "center", padding: 10 }}>TAMANHO</th>
                                        <th style={{ textAlign: "center", padding: 10 }}>AÇÕES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documents.map((document: documentsAttributes) => (
                                        <tr style={{ background: colors[theme] }} key={document.id}>
                                            <td style={{ padding: 10 }}>{document.name}</td>
                                            <td style={{ textAlign: "center", padding: 10, textTransform: "uppercase" }}>{document.document_type?.name}</td>
                                            <td style={{ textAlign: "center", padding: 10, textTransform: "uppercase" }}>{document.name.split(".")[document.name.split(".").length - 1]}</td>
                                            <td style={{ textAlign: "center", padding: 10 }}>{formatBytes(document.size, 2)}</td>
                                            <td style={{ textAlign: "center", padding: 10, whiteSpace: "nowrap" }}>
                                                <a style={{ textDecoration: "none" }} target="_blank" href={`${process.env.REACT_APP_S3_URL}/${document.name}`}>
                                                    <Tooltip style={{ margin: 0, padding: 10 }} title="Visualizar">
                                                        <IconButton aria-label="Visualizar">
                                                            <MdOutlineRemoveRedEye
                                                                color={secondary[theme]}
                                                                size={20}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </a>
                                                <Tooltip onClick={() => setDocumentSelected(document)} title="Editar" style={{ margin: 0, padding: 10 }}>
                                                    <IconButton aria-label="Editar">
                                                        <FiEdit
                                                            color={secondary[theme]}
                                                            size={20}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip onClick={() => setDocumentDestroy(document)} style={{ margin: 0, padding: 10 }} title="Excluir">
                                                    <IconButton aria-label="Excluir">
                                                        <MdOutlineCancel
                                                            color={secondary[theme]}
                                                            size={20}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }

                        {documentSelected?.id &&
                            <ModalGeneric onClose={() => { }} open={true}>
                                <DocumentModal
                                    onClose={() => setDocumentSelected(null)}
                                    document={documentSelected}
                                    relation={relation}
                                    customer={customer}
                                    property={property}
                                    onSave={() => {
                                        setDocumentSelected(null)
                                        onSave()
                                    }}
                                />
                            </ModalGeneric>
                        }
                    </div>
                }

                {exibition === "grid" &&
                    <div style={{ margin: 16, fontSize: 13.5, borderRadius: 4 }}>
                        {documents && documents.length > 0 &&
                            <Line style={{ flexWrap: "wrap", flex: 1, gap: 20 }}>
                                {documents.map((document: documentsAttributes, index) => (
                                    <div onMouseMove={() => setDocumentHover(document)} onMouseLeave={() => setDocumentHover(null)} key={document.id} style={{ minWidth: 200, flex: 1, cursor: "pointer", border: `1px solid ${borderColors[theme]}`, borderRadius: 5 }}>
                                        <div style={{ height: 200, position: "relative", display: "flex", flexDirection: "column", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundImage: isImage(getExtension(document.name)) ? `url("${process.env.REACT_APP_S3_URL}/${document.name}")` : "" }}>
                                            <Column onClick={() => anchor_ref.current[index]?.click()} style={{ zIndex: 10, alignItems: "flex-start", gap: 10, padding: 10, flex: 1 }}>
                                                <div style={{ padding: 5, borderRadius: 5, background: "#3A3A3A", color: "#FFF", textTransform: "uppercase", fontWeight: 600 }}>{document.name.split(".")[document.name.split(".").length - 1]}</div>
                                                <div style={{ padding: 5, borderRadius: 5, background: secondary[theme], color: "#FFF", fontWeight: 600, textTransform: "uppercase" }}>{document.document_type?.name}</div>
                                            </Column>
                                            {!isImage(getExtension(document.name)) &&
                                                <div style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {ImageIcon(getExtension(document.name))}
                                                </div>
                                            }
                                            {documentHover?.id === document.id &&
                                                <Line style={{ gap: 10, position: "absolute", top: -10, right: -10, zIndex: 99 }}>
                                                    <Tooltip onClick={() => setDocumentDestroy(document)} style={{ margin: 0, padding: 10, borderRadius: 5, background: "#FF6B6B" }} title="Excluir">
                                                        <IconButton aria-label="Excluir">
                                                            <MdOutlineCancel
                                                                color={secondary[theme]}
                                                                size={20}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Line>
                                            }
                                        </div>
                                        <a ref={(ref: any) => anchor_ref.current[index] = ref} style={{ display: "none" }} target="_blank" href={`${process.env.REACT_APP_S3_URL}/${document.name}`}>
                                        </a>
                                        <div onClick={() => anchor_ref.current[index]?.click()} style={{ display: "flex", flexDirection: "column", borderTop: `1px solid ${borderColors[theme]}`, padding: 10 }}>
                                            <div style={{ overflow: "hidden", textAlign: "center", fontWeight: 600, whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                {document.name}
                                            </div>
                                            <div style={{ textAlign: "center" }}>
                                                {formatBytes(document.size)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Line>
                        }
                    </div>
                }

                {(!documents || documents?.length === 0) &&
                    <div style={{ textAlign: "center", marginBottom: 30, fontSize: 16, fontWeight: 600, opacity: 0.7, fontStyle: "italic" }}>
                        Nenhum documento cadastrado
                    </div>
                }
            </ContentOrLoading>

            <ModalAttention
                theme={theme}
                open={!!documentDestroy?.id}
                cancelAction={() => setDocumentDestroy(null)}
                confirmAction={confirmDestroy}
                content={(
                    <>
                        Você deseja excluir o documento <b>{documentDestroy?.name}</b> ?
                    </>
                )}
            />

            <ModalLoading
                loading={loadingDestroy}
                theme={theme}
            />
        </>
    )
}