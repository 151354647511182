import React, { forwardRef, useCallback, useEffect, useState, useRef } from "react";
import { BsChatText } from "react-icons/bs";
import { Message } from "../../../../Message";
import { HiOutlineHome } from "react-icons/hi";
import { RiSuitcaseLine } from "react-icons/ri";
import { CgFileDocument } from "react-icons/cg";
import { IoIosPaperPlane } from "react-icons/io";
import { IoPersonCircle } from "react-icons/io5";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { useGlobal } from "../../../../../hooks/global";
import { useWallet } from "../../../../../hooks/wallet";
import { ModalConfirmAction } from "./ModalConfirmAction";
import { MdOutlineLockClock, MdSecurity } from "react-icons/md";
import { inactiveItens, secondary } from "../../../../../theme";
import { useProposalChat } from "../../../../../hooks/proposals";
import { AiFillCaretDown, AiOutlineRollback } from "react-icons/ai";
import { copyOf, uuid } from "../../../../../services/generalServices";
import { proposal_status } from "../../../../../utils/proposal_status";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { BagDocumentProposal } from "../../../../Bags/BagDocumentProposal";
import { BiBlock, BiCheckCircle, BiSearch, BiXCircle } from "react-icons/bi";
import { Superlogica16 } from "../../../../SvgComponents/Icons/Superlogica/Superlogica16";
import { IIntegrationsProps, messagesAttributes, proposalsAttributes } from "../../../../../interfaces";
import { ButtonAction, ButtonSendMessage, ContainerButtonAction, ContainerGroupButtonActions, ContainerMessages, ConversationHeader, FormMessage, IconMenuDrop, OptionAction, RightSide, TextAreaMessage } from "./style";

export interface IStepRef {
    validate: () => boolean
}

export const correct_modality: any = {
    "CT": "CT - Construção em Terreno Próprio",
    "AC": "AC - Aquisição de Terreno e Construção",
}

const correct_relations: any = {
    guarantor: "Fiador",
    guarantor_spouse: "Cônjuge Fiador",
    tenant: "Locatário",
    tenant_spouse: "Cônjuge Locatário",
    owner: "Proprietário",
    property: "Imóvel"
}

interface IAnalysisStep {
    defaultId: string
    loading: boolean
    control: number
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
}

const AnalysisStepComponent: React.ForwardRefRenderFunction<IStepRef, IAnalysisStep> = (props, ref) => {
    const { defaultId, control, loading, onCancel, setLoading } = props

    const { user, api } = useApi()
    const { subscription } = useWallet()
    const { theme, notify } = useGlobal()
    const { controlRenderMessages, setControlRenderMessages, scroll_message_container_ref, socket } = useProposalChat()

    const [openAction, setOpenAction] = useState(false)
    const [requiredResolution, setRequiredResolution] = useState(false)
    const [proposal, setProposal] = useState<proposalsAttributes | null>(null)
    const [modalConfirmType, setModalConfirmType] = useState<"PA" | "PR" | "PEA" | null>(null)
    const [integrationsTokens, setIntegrationsTokens] = useState<IIntegrationsProps>({ super_logica: {}, click_sign: {} } as IIntegrationsProps)

    const message_ref = useRef<HTMLTextAreaElement>(null)

    const submitMessage = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const message = message_ref.current ? message_ref.current.value : ""
        if (message && proposal) {
            if (message_ref.current) message_ref.current.value = ""
            try {
                setProposal((atualProposal) => {
                    const newProposal: proposalsAttributes = copyOf(atualProposal)
                    const newMessage = {
                        id: uuid(),
                        text: message,
                        type: requiredResolution ? "requirement" : "normal",
                        sender_id: user.id,
                        sender: user as any,
                        isFlag: true
                    } as messagesAttributes
                    newProposal.messages = [newMessage, ...newProposal.messages as messagesAttributes[]]
                    return { ...newProposal }
                })
                setControlRenderMessages((atual) => !atual)
                scroll_message_container_ref.current?.scrollTo({ top: scroll_message_container_ref.current.scrollHeight, behavior: "smooth" })
                await api.post(`/messages/${proposal.id}`, { message, requiredResolution })
            } catch (err) {
                console.log(err)
                notify("Erro ao enviar mensagem!", "error")
            }
        }

    }, [requiredResolution, proposal, scroll_message_container_ref, message_ref, user])

    const confirmResolution = useCallback(async (message_id: string) => {
        setProposal((atualProposal) => {
            const newProposal: any = copyOf(atualProposal)
            const position = newProposal.messages.findIndex((message: any) => message.id === message_id)
            newProposal.messages[position].isFlagResolution = true
            return { ...newProposal }
        })
        setControlRenderMessages((atual) => !atual)
        await api.post(`/messages/confirmResolution/${message_id}`)
    }, [])

    const clickInAction = useCallback((type) => {
        setModalConfirmType(type)
        setOpenAction(false)
    }, [])

    const confirmAction = useCallback(async ({ type, reason }) => {
        setModalConfirmType(null)
        if (type !== "EXPORT_SUPER") {
            try {
                setProposal((atualProposal) => {
                    const newProposal: proposalsAttributes = copyOf(atualProposal)
                    const newMessage = {
                        id: uuid(),
                        text: reason ? reason : "",
                        type: "change_status",
                        sender_id: user.id,
                        sender: user as any,
                        isFlag: true,
                        old_status: newProposal.status,
                        new_status: type
                    } as messagesAttributes
                    if (newProposal.messages) newProposal.messages = [newMessage, ...newProposal.messages]
                    else newProposal.messages = [newMessage]
                    return { ...newProposal }
                })
                setControlRenderMessages((atual) => !atual)
                await api.post(`/proposals/actionInProposal/${proposal?.id}`, { new_status: type, reason })
            } catch (err: any) {
                setProposal((atualProposal) => {
                    const newProposal: proposalsAttributes = copyOf(atualProposal)
                    if (newProposal.messages) newProposal.messages.splice(0, 1)
                    return { ...newProposal }
                })
                const error = err?.response ? err?.response?.data : "SERVER ERROR"
                if (error !== "SERVER ERROR") notify(error.message, "error")
                else notify("Erro ao mudar status da proposta!", "error")
            }
        } else {
            try {
                await api.post(`/integrations/super_logica/export-proposal/${proposal?.id}`)
            } catch (err: any) {
                const error = err?.response ? err?.response?.data : "SERVER ERROR"
                if (error === "SERVER ERROR") notify("Erro Interno do Sistema!", "error")
                else notify(error.message, "alert")
            }
        }
    }, [proposal, user])

    const handleSearch = useCallback(async (inBackground: boolean = false) => {
        if (!inBackground) setLoading(true)
        await searchIntegrationsTokens()
        try {
            const [result, result_documents] = await Promise.all([
                api.get(`/proposals/messages/${defaultId}`),
                api.get(`/proposals/documents/${defaultId}`)
            ])
            result.data.warranty = JSON.parse(result.data.warranty)
            result.data.warranty.warranty_type = result.data.warranty_type
            setProposal({
                ...result.data,
                consults: result_documents.data.customers_proposal,
                property: result_documents.data.property
            })
            socket?.removeAllListeners()
            socket?.on(`${defaultId}-chat`, () => {
                handleSearch(true)
            })
        } catch (err) {
            console.log(err)
            notify("Erro ao Buscar Análise", "error")
            onCancel()
        }
        setLoading(false)
    }, [socket, api])

    const searchIntegrationsTokens = useCallback(async () => {

        try {

            const { data } = await api.get(`/integrations/get-tokens`)

            setIntegrationsTokens(data)

        } catch (err) {

            notify("Erro ao buscar as integrações", "error")

        }

        return true
    }, [])

    const validateAccessCRM = useCallback(() => {
        const access_token = integrationsTokens.super_logica.access_token
        const app_token = integrationsTokens.super_logica.app_token
        const status = integrationsTokens.super_logica.status
        const permissions = JSON.parse(subscription?.plan?.permissions as any ?? "[]")
        const plan_have_crm = !!permissions?.includes("crms")
        return !!access_token && !!app_token && status === "active" && plan_have_crm
    }, [integrationsTokens, subscription])

    useEffect(() => {
        scroll_message_container_ref.current?.scrollTo({
            top: scroll_message_container_ref.current.scrollHeight,
            behavior: "smooth"
        })
    }, [proposal, scroll_message_container_ref])

    useEffect(() => { handleSearch(control > 0) }, [control])

    const childrenMessages = (
        <>
            {proposal?.messages?.map((message) => (
                <Message
                    key={message.id}
                    onConfirmResolution={confirmResolution}
                    main_photo={message.sender?.photo ? message.sender?.photo : (message.sender?.facebook_picture ? message.sender?.facebook_picture : undefined)}
                    main_id={user.id}
                    message={message}
                />
            ))}
        </>
    )

    return (
        <Column style={{ gap: 20, flex: 1 }}>
            <ContentOrLoading loading={loading} theme={theme} type="wave">
                {proposal && (
                    <Column style={{ gap: 20, flex: 1 }}>

                        <Line style={{ flex: 1, border: "1px solid #C4C4C4", overflow: "auto", borderRadius: 4 }}>
                            <Column style={{ flex: 1 }}>

                                <ConversationHeader theme={theme}>
                                    <Line style={{ gap: 5, alignItems: "center", color: secondary[theme] }}>
                                        <div><BsChatText size={26} /></div>
                                        <div style={{ fontWeight: 600, fontSize: 16, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>Histórico de análise e solicitações de sua proposta</div>
                                    </Line>
                                    <Line style={{ alignItems: "center", gap: 5, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                                        <div style={{ fontSize: 14, background: proposal_status[proposal.status].color, width: 12, height: 12, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center", color: inactiveItens.light, fontWeight: 600 }}></div>
                                        <div style={{ opacity: 0.7, fontSize: 14 }}>{proposal_status[proposal.status].text}</div>
                                    </Line>
                                </ConversationHeader>

                                <div style={{ background: theme === "dark" ? "#282828" : "rgb(231 231 231)", display: "flex", flex: 1, overflow: "hidden" }}>
                                    <ContainerMessages isBroker={["broker", "root"].includes(user.type)} ref={scroll_message_container_ref} theme={theme}>
                                        {controlRenderMessages && childrenMessages}
                                        {!controlRenderMessages && childrenMessages}
                                    </ContainerMessages>
                                </div>

                                <FormMessage theme={theme} onSubmit={submitMessage} noBackground={["responsible", "broker"].includes(user.type)}>
                                    <TextAreaMessage ref={message_ref} autoCorrect="nope" spellCheck="false" theme={theme} placeholder="Escreva sua mensagem">
                                    </TextAreaMessage>
                                    <Line style={{ justifyContent: ["analyst", "responsible", "manager"].includes(user.type) ? "space-between" : "flex-end", paddingBottom: user.type === "analyst" ? 8 : 10, width: "100%" }}>
                                        {["analyst", "responsible", "manager"].includes(user.type) &&
                                            <Line style={{ justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                                                <label className="checkbox">
                                                    <input onClick={() => { setRequiredResolution(!requiredResolution) }} style={{ marginTop: 5, cursor: "pointer" }} type="checkbox" checked={requiredResolution} />
                                                    <span>Requer resolução</span>
                                                    <span style={{ background: requiredResolution ? secondary[theme] : "#C4C4C4", marginTop: 1 }} className="checkmark"></span>
                                                </label>
                                            </Line>
                                        }
                                        <ButtonSendMessage theme={theme}>
                                            <IoIosPaperPlane size={19} color="#FFF" />
                                        </ButtonSendMessage>
                                    </Line>
                                </FormMessage>

                            </Column>
                            <RightSide user_type={user.type} theme={theme}>
                                <ContainerButtonAction theme={theme}>
                                    {["responsible", "analyst", "manager"].includes(user.type) &&
                                        <>
                                            <ButtonAction onClick={() => setOpenAction(!openAction)} theme={theme}>
                                                <b>AÇÕES</b>
                                                <div style={{ borderLeft: "1px solid #FFF", marginLeft: 10, paddingLeft: 10 }}>
                                                    <IconMenuDrop open={openAction}>
                                                        <AiFillCaretDown size={20} color="#FFF" />
                                                    </IconMenuDrop>
                                                </div>
                                                {openAction && !["PR", "PA", "PC", "CF"].includes(proposal.status) &&
                                                    <ContainerGroupButtonActions theme={theme}>
                                                        <OptionAction onClick={() => clickInAction("PA")} isTop theme={theme}>
                                                            <BiCheckCircle size={20} color={proposal_status["PA"].color} />
                                                            Aprovar proposta
                                                        </OptionAction>
                                                        <OptionAction onClick={() => clickInAction("PR")} isBottom theme={theme}>
                                                            <BiXCircle size={20} color={proposal_status["PR"].color} />
                                                            Reprovar proposta
                                                        </OptionAction>
                                                        <OptionAction onClick={() => clickInAction("PC")} isBottom theme={theme}>
                                                            <BiBlock size={20} color={proposal_status["PC"].color} />
                                                            Cancelar proposta
                                                        </OptionAction>
                                                    </ContainerGroupButtonActions>
                                                }
                                                {openAction && ["PC"].includes(proposal.status) &&
                                                    <ContainerGroupButtonActions theme={theme}>
                                                        <OptionAction onClick={() => clickInAction(proposal.analyst_id ? "PEA" : "AP")} isTop theme={theme}>
                                                            <AiOutlineRollback size={20} color={secondary[theme]} />
                                                            Desfazer cancelamento
                                                        </OptionAction>
                                                    </ContainerGroupButtonActions>
                                                }
                                                {openAction && ["CF", "PR", "PA"].includes(proposal.status) &&
                                                    <ContainerGroupButtonActions theme={theme}>
                                                        <OptionAction onClick={() => clickInAction("PEA")} isTop theme={theme}>
                                                            <AiOutlineRollback size={20} color={secondary[theme]} />
                                                            Retornar para análise
                                                        </OptionAction>
                                                        {["PA"].includes(proposal.status) &&
                                                            <OptionAction onClick={() => clickInAction("CF")} isTop theme={theme}>
                                                                <MdOutlineLockClock size={20} color={proposal_status["PA"].color} />
                                                                Finalizar contrato
                                                            </OptionAction>
                                                        }
                                                        {!["PR"].includes(proposal.status) && validateAccessCRM() &&
                                                            <OptionAction onClick={() => clickInAction("EXPORT_SUPER")} isTop theme={theme}>
                                                                <Superlogica16 color={theme === "light" ? "#1034F2" : "FFF"} />
                                                                Exportar para a Superlógica
                                                            </OptionAction>
                                                        }
                                                    </ContainerGroupButtonActions>
                                                }
                                            </ButtonAction>
                                        </>
                                    }
                                    {["broker"].includes(user.type) && !["PR", "CF"].includes(proposal.status) &&
                                        <>
                                            <ButtonAction onClick={() => setOpenAction(!openAction)} theme={theme}>
                                                <b>AÇÕES</b>
                                                <div style={{ borderLeft: "1px solid #FFF", marginLeft: 10, paddingLeft: 10 }}>
                                                    <IconMenuDrop open={openAction}>
                                                        <AiFillCaretDown size={20} color="#FFF" />
                                                    </IconMenuDrop>
                                                </div>
                                                {openAction && !["PC"].includes(proposal.status) &&
                                                    <ContainerGroupButtonActions theme={theme}>
                                                        <OptionAction onClick={() => clickInAction("PC")} isBottom theme={theme}>
                                                            <BiBlock size={20} color={proposal_status["PC"].color} />
                                                            Cancelar proposta
                                                        </OptionAction>
                                                    </ContainerGroupButtonActions>
                                                }
                                                {openAction && ["PC"].includes(proposal.status) &&
                                                    <ContainerGroupButtonActions theme={theme}>
                                                        <OptionAction onClick={() => clickInAction(proposal.analyst_id ? "PEA" : "AP")} isTop theme={theme}>
                                                            <AiOutlineRollback size={20} color={secondary[theme]} />
                                                            Desfazer cancelamento
                                                        </OptionAction>
                                                    </ContainerGroupButtonActions>
                                                }
                                            </ButtonAction>
                                        </>
                                    }
                                </ContainerButtonAction>
                                <Column style={{ overflow: "auto" }}>
                                    <Column style={{ borderRadius: 5 }}>
                                        {(proposal.consults?.length ?? 0) > 0 && ["root", "responsible", "manager"].includes(user.type) &&
                                            <BagDocumentProposal
                                                title={"Consultas"}
                                                description="Consultas realizadas"
                                                icon={<BiSearch color={secondary[theme]} size={26} />}
                                                consults={proposal.consults}
                                            />
                                        }
                                        {proposal.property &&
                                            <BagDocumentProposal
                                                title={`#${proposal?.property?.friendly_id} - ${proposal?.property?.property_type?.name} na ${proposal?.property?.address?.public_place},  ${proposal?.property?.address?.number}, ${proposal?.property?.address?.district}, ${proposal?.property?.address?.city.name} - ${proposal?.property?.address?.state.initials}`}
                                                description="Imóvel"
                                                icon={<HiOutlineHome color={secondary[theme]} size={26} />}
                                                property_id={proposal.property.id}
                                                defaultProperty={proposal?.property}
                                            />
                                        }
                                        {proposal.property?.owners.map((owner) => (
                                            <BagDocumentProposal
                                                description={"Proprietário"}
                                                icon={owner.customer?.registration_data?.type === "PF" ? <IoPersonCircle color={secondary[theme]} size={26} /> : <RiSuitcaseLine color={secondary[theme]} size={26} />}
                                                title={owner.customer?.registration_data?.name as string}
                                                customer_id={owner?.customer_id}
                                                defaultCustomer={owner?.customer}
                                            />
                                        ))}
                                        {proposal.consults?.map((consult) => {
                                            return (
                                                <BagDocumentProposal
                                                    description={correct_relations[consult.relation]}
                                                    icon={consult.customer?.registration_data?.type === "PF" ? <IoPersonCircle color={secondary[theme]} size={26} /> : <RiSuitcaseLine color={secondary[theme]} size={26} />}
                                                    title={consult.customer?.registration_data?.name as string}
                                                    customer_id={consult?.customer_id}
                                                    defaultCustomer={consult?.customer}
                                                />
                                            )
                                        })}
                                        {!!proposal.warranty_type && proposal.warranty_type !== "GUARANTOR" &&
                                            <BagDocumentProposal
                                                title={"Garantia"}
                                                description="Dados da garantia"
                                                icon={<MdSecurity color={secondary[theme]} size={26} />}
                                                warrantyData={proposal.warranty}
                                            />
                                        }
                                        {proposal.contractorData?.id &&
                                            <BagDocumentProposal
                                                title={"Contrato"}
                                                description="Dados do contrato"
                                                icon={<CgFileDocument color={secondary[theme]} size={26} />}
                                                contractorData={proposal.contractorData}
                                            />
                                        }

                                    </Column>
                                </Column>
                            </RightSide>
                        </Line >
                    </Column>
                )}
            </ContentOrLoading>
            {!!modalConfirmType &&
                <ModalConfirmAction
                    onConfirm={confirmAction}
                    onCancel={() => setModalConfirmType(null)}
                    open={!!modalConfirmType}
                    type={modalConfirmType}
                    friendly_id={proposal?.friendly_id}
                />
            }
        </Column>
    )
}

export const AnalysisStep = forwardRef(AnalysisStepComponent)