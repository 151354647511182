import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { DashboardCard } from "../DashboardCard";
import { themeTypes } from "../../../../../interfaces";
import { ChartDoughnut } from "../../../../../components/Charts/Doughnut";
import { ColumnChart, Label, LabelIndicator, LabelInfo, LineLabels } from "./style";

interface IDashboardChartDoughnut {
    title: string;
    theme: themeTypes;
    minWidth: number;
    minHeight?: number;
    infos: any;
    labels: string[];
    colors: string[];
    mask?: Function;
}

export const DashboardChartDoughnut: React.FC<IDashboardChartDoughnut> = (props) => {
    const { infos, theme, labels, colors, title, minWidth, minHeight, mask = (v: any) => v } = props;

    return (
        <DashboardCard minHeight={minHeight} minWidth={minWidth} title={title} theme={theme}>
            <ColumnChart theme={theme}>
                <ChartDoughnut
                    infos={infos}
                    labels={labels}
                    colors={colors}
                    mask={mask}
                />
                <LineLabels>
                    {labels?.map((name, index) => {
                        if (infos[index]) {
                            return (
                                <Label key={name} theme={theme}>
                                    <LabelIndicator background={colors[index]} />
                                    <LabelInfo>
                                        <span style={{ textTransform: "uppercase" }}>{name}</span>
                                        <BsArrowRight />
                                        {mask(infos[index])}
                                    </LabelInfo>
                                </Label>
                            )
                        }
                    })}
                </LineLabels>
            </ColumnChart>
        </DashboardCard>
    )
}