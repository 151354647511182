import styled, { css } from "styled-components";
import { borderColors, background } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Form = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    overflow: auto;
`

export const BagContent = styled.div<IThemeProps>`
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    overflow: auto;
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]} ;
    `}
`

export const TD = styled.td`
    padding: 10px;
    width: 33%;
`

export const TH = styled.th`
    padding: 10px;
    width: 33%;
`

export const THead = styled.thead<IThemeProps>`
    ${props => css`
        background: ${background[props.theme as themeTypes]};
    `}
`

export const Table = styled.table`
    width: 100%;
    overflow: auto;
`