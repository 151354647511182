import React, { useState } from "react";
import NotFoundImage from "../../../../assets/notfound.svg";
import { EmptyText } from "../style";
import { BiSearch } from "react-icons/bi";
import { secondary } from "../../../../theme";
import { useGlobal } from "../../../../hooks/global";
import { IconButton, Tooltip } from "@material-ui/core";
import { Column, LineAllCenter } from "../../../../style";
import { ContainerConsult, Description, Img, Title } from "./style";
import { ModalResultQuery } from "../../../Modals/ModalQuery/Result";
import { customers_proposalAttributes, queriesAttributes } from "../../../../interfaces";

interface IConsultsData {
    consults: customers_proposalAttributes[]
}

const correct_relations: any = {
    guarantor: "Fiador",
    guarantor_spouse: "Cônjuge Fiador",
    tenant: "Locatário",
    tenant_spouse: "Cônjuge Locatário",
    owner: "Proprietário",
    property: "Imóvel"
}

export const ConsultsData: React.FC<IConsultsData> = (props) => {
    const { consults } = props

    const { theme } = useGlobal()

    const [query_selected, set_query_selected] = useState<queriesAttributes | null | undefined>(null)

    return (
        <>
            <Column style={{ gap: 10 }}>
                {consults.map((consult) => (
                    <ContainerConsult key={consult.id} theme={theme} onClick={() => set_query_selected(consult.query)}>
                        
                        <Column>
                            <Title>{consult.customer?.registration_data?.name}</Title>
                            <Description>{correct_relations[consult.relation]}</Description>
                        </Column>
                        
                        <LineAllCenter>
                            <Tooltip onClick={() => set_query_selected(consult.query)} style={{ height: 40, width: 40 }} title="Visualizar">
                                <IconButton aria-label="Visualizar">
                                    <LineAllCenter>
                                        <BiSearch size={19} color={secondary[theme]} />
                                    </LineAllCenter>
                                </IconButton>
                            </Tooltip>
                        </LineAllCenter>

                    </ContainerConsult>
                ))}
                {consults.length === 0 && (
                    <Column style={{ alignItems: "center", gap: 10 }}>
                        <LineAllCenter>
                            <Img src={NotFoundImage} />
                        </LineAllCenter>
                        <EmptyText>Nenhuma consulta realizada</EmptyText>
                    </Column>
                )}
            </Column>
            {query_selected &&
                <ModalResultQuery
                    onCancel={() => set_query_selected(null)}
                    openModal={!!query_selected}
                    backgroundVisible={false}
                    defaultId={query_selected.id}
                />
            }
        </>
    )
}