import styled from "styled-components";
import { themeTypes } from "../../../interfaces";
import { borderDisabled, fail } from "../../../theme";

export const BaseInput = styled.div<{ haveError: boolean, theme: themeTypes }>`
    position: relative;
    width:100%;
    min-width:250px;
    flex:1;
    
    input{
        transition: 0s all;
        border: 1px solid ${props => props.haveError ? fail : (props.theme === "light" ? "#cccccc" : "rgb(68 68 68)")} !important;

        :disabled{
            border: 1px solid ${props => borderDisabled[props.theme as themeTypes]} !important;
            color:#999999;
        }

        :focus{
            box-shadow:none !important;
            outline:1px solid #2684FF !important;
            border: 1px solid #2684FF !important;
        }
    }
`

export const ContainerIconSeePassword = styled.div`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`