import React from "react";
import { Column, Line } from "../../../style";
import { themeTypes } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";
import { borderColors, primary } from "../../../theme";

interface IBag {
    title: string
    description: string
    icon: any
    borderRadius?: number
    children?: any
    noPageBreak?: boolean
    forceTheme?: themeTypes
}

export const BagPrint: React.FC<IBag> = (props, ref) => {
    const { children, description, icon, title, noPageBreak, forceTheme } = props
    const { theme } = useGlobal()

    return (
        <Column style={{ marginTop: !noPageBreak ? -20 : 0, pageBreakBefore: noPageBreak ? undefined : "always" }}>
            <Column style={{ border: `1px solid ${borderColors[forceTheme ? forceTheme : theme]}`, background: primary[forceTheme ? forceTheme : theme], borderRadius: 4 }}>
                <Line style={{ padding: 16, gap: 10, alignItems: "center", cursor: "pointer", justifyContent: "space-between" }}>
                    <Line style={{ gap: 10, alignItems: "center" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {icon}
                        </div>
                        <Column>
                            <div style={{ fontSize: 15, fontWeight: 600, textTransform: "uppercase" }}>{title}</div>
                            <div style={{ fontSize: 12, marginTop: -3, textTransform: "uppercase" }}>{description}</div>
                        </Column>
                    </Line>
                </Line>
                <div style={{ borderTop: `1px solid ${borderColors[forceTheme ? forceTheme : theme]}` }}>
                    {children}
                </div>
            </Column>
        </Column>

    )

}