import styled, { css } from "styled-components";
import { primary, background, secondary } from "../../theme";
import { IThemeProps, themeTypes } from "../../interfaces";

export const Form = styled.div<IThemeProps>`
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    gap:20px;
    box-shadow: 0.125rem 0.125rem 0.5rem #57545419;
    ${props => css`
        background-color: ${primary[props.theme as themeTypes]};
    `}
`

export const CardFranchise = styled.div<IThemeProps>`
    cursor:pointer;
    display:flex ;
    min-width:300px;
    height:200px;
    justify-content:space-between;
    flex-direction:column;
    border-radius:5px;
    padding:20px;
    gap:10px;
    ${props => css`
        background-color: ${background[props.theme as themeTypes]};
        box-shadow: 0.125rem 0.125rem 0.5rem ${props.theme === "dark" ? "#0c0a0a" : "#bdbdbd"}};
    `}
    @media(max-width:550px){
        min-width:200px;
        min-height:200px;
        flex:1;
    }
`

export const ButtonFranchise = styled.button<IThemeProps>`
    outline:0;
    border:0;
    padding:5px ;
    border-radius:4px;
    color:#FFF ;
    cursor:pointer;
    ${props => css`
        background-color: ${secondary[props.theme as themeTypes]};
    `}
`

export const ContainerFranchises = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    @media(max-width:550px){
        align-items: unset;
        flex-direction: column;
        flex:1;
    }
`