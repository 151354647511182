import React, { useCallback, useState, useEffect } from "react"
import PaginationCustom from "../../../../components/PaginationCustom";
import { RiMedalFill } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { useApi } from "../../../../hooks/api";
import { IoWarningOutline } from "react-icons/io5";
import { useGlobal } from "../../../../hooks/global";
import { IconButton, Tooltip } from "@material-ui/core";
import { getStatus } from "../../../../utils/columnsTables";
import { DashboardCard } from "../DashboardCards/DashboardCard";
import { fail, inactiveItens, success } from "../../../../theme";
import { BsArrowDownSquare, BsPatchCheck } from "react-icons/bs";
import { maskFunctions } from "../../../../services/maskServices";
import { IFranchisesInRankingProps, themeTypes } from "../../../../interfaces";
import { subscription_situations } from "../../../../utils/subscriptionSituantions";
import { ContentOrLoading } from "../../../../components/Loadings/ContentOrLoading";
import { correct_date_filter, setUpConsultation, valueIsEmpty } from "../../../../services/generalServices";
import { ContainerArrow, ContainerLogo, ContainerLogoDefault, ContainerPagination, ContainerRanking, Content, Img, TableLine, TD, TH } from "./style";

interface IRankingFranchisesProps {
    minWidth: number
    theme: themeTypes
    maxWidth: string
    range: { from: any, to: any }
}

interface ISearchProps {
    _page: number
    _order: "ASC" | "DESC"
    _orderBy: string
}

const franchiseValue = (item: IFranchisesInRankingProps, attribute: string) => {
    const value = item[attribute]

    const values_attributes = ["increase", "decrease", "subscription_payment", "balance", "provisioned"]

    if (values_attributes.includes(attribute)) return value === "0" ? "R$ 0,00" : maskFunctions.currency.mask(value)

    if (valueIsEmpty(value)) return "Não informado"

    return value
}

const colors_podium: any = {
    0: "#FFD800",
    1: "#CBCCCC",
    2: "#BD7B65"
}

const columns = [
    { name: "Nome", attribute: "name", className: "text-center pointer j-center" },
    { name: "Propostas", attribute: "proposals", className: "text-center pointer j-center" },
    { name: "Consultas", attribute: "queries", className: "text-center pointer j-center" },
    { name: "Doc's", attribute: "contracts", className: "text-center pointer j-center" },
    { name: "Doc's Assinados", attribute: "contracts_signeds", className: "text-center pointer j-center" },
    { name: "Doc's Cancelados", attribute: "contracts_canceleds", className: "text-center pointer j-center" },
    { name: "Assinatura", attribute: "subscription_payment", className: "text-center pointer j-center" },
    { name: "Entrada", attribute: "increase", className: "text-center pointer j-center" },
    { name: "Saída", attribute: "decrease", className: "text-center pointer j-center" },
    { name: "Carteira", attribute: "balance", className: "text-center pointer j-center" },
    { name: "Provisionado", attribute: "provisioned", className: "text-center pointer j-center" },
    { name: "Status do plano", attribute: "subscription", className: "text-center cursor-default j-center", noHaveClick: true }
]

const getName: React.FC<IFranchisesInRankingProps> = ({ status, name, is_trusted }) => {
    return (
        <div style={{ display: "flex", gap: 5, alignItems: "center", justifyContent: "center", textTransform: "uppercase" }}>
            <div style={{ whiteSpace: "nowrap", position: "relative" }}>
                {status === "block" && (
                    <Tooltip placement="top" title={"Imobiliária Bloqueada"} style={{ width: 30, height: 30, position: "absolute", left: -36, top: "50%", transform: "translateY(-50%)" }}>
                        <IconButton aria-label={"Imobiliária Bloqueada"}>
                            <div style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <IoWarningOutline
                                    color={fail}
                                    size={16}
                                />
                            </div>
                        </IconButton>
                    </Tooltip>
                )}
                {name}
                {is_trusted && (
                    <Tooltip placement="top" title={"Modo Confiança"} style={{ width: 30, height: 30, position: "absolute", right: -36, top: "50%", transform: "translateY(-50%)" }}>
                        <IconButton aria-label={"Modo Confiança"}>
                            <div style={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <BsPatchCheck
                                    color={success}
                                    size={16}
                                />
                            </div>
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

export const RankingFranchises: React.FC<IRankingFranchisesProps> = (props) => {
    const { minWidth, theme, maxWidth, range } = props

    const default_order = "DESC"
    const default_orderBy = columns[1].attribute

    const [atualPage, setAtualPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [numOfFranchises, setNumOfFranchises] = useState(0)
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [orderBy, setOrderBy] = useState<string>(default_orderBy)
    const [order, setOrder] = useState<"ASC" | "DESC">(default_order)
    const [controlPagination, setControlPagination] = useState<boolean>(false)
    const [franchisesRanking, setFranchisesRanking] = useState<IFranchisesInRankingProps[]>([])

    const { api } = useApi()
    const { paginationLimit, notify } = useGlobal()

    const search = useCallback(async (props: ISearchProps) => {
        const { _page, _order, _orderBy } = props

        setAtualPage(_page)
        setLoading(true);
        try {
            const query: any = {}
            const date_init = range.from ? range.from : (range.to ? range.to : null)
            const date_end = range.to ? range.to : (range.from ? range.from : null)
            query.date_init = date_init ? correct_date_filter(date_init) : null
            query.date_end = date_end ? correct_date_filter(date_end) : null
            query.page = _page
            query.limit = paginationLimit
            query.order = _order
            query.orderBy = _orderBy

            const string: any = setUpConsultation(query)
            const { data } = await api.get(`/franchises-ranking?${string}`)
            for (let i = 0; i < data.rows.length; i++) {
                const subscription = data.rows[i].subscription
                data.rows[i].name = getName(data.rows[i])
                data.rows[i].subscription = subscription ? getStatus({ ...subscription_situations[subscription], theme }) : undefined
            }
            setFranchisesRanking(data.rows)
            setNumOfFranchises(data.count)
            setNumberOfPages(Math.ceil((data.count / paginationLimit)))
        } catch (err) {
            console.log(err);
            notify("Erro ao buscar ranking das franquias", "error")
        }
        setLoading(false);
    }, [paginationLimit])

    useEffect(() => { setControlPagination(atual => !atual) }, [atualPage])

    useEffect(() => { search({ _page: 1, _order: default_order, _orderBy: default_orderBy }) }, [])

    const childrenPaginationCustom = (
        <ContainerPagination theme={theme}>
            <PaginationCustom
                disable={loading}
                onChange={(newPage: any) => search({ _page: newPage, _order: order, _orderBy: orderBy })}
                page={atualPage}
                pages={numberOfPages}
            />
        </ContainerPagination>
    )

    const handleOrder = useCallback((attribute: string) => {
        let newOrder: any = undefined

        setOrderBy((atualOrderBy) => {
            if (atualOrderBy === attribute) {
                setOrder((atualOrder) => {
                    newOrder = atualOrder === "ASC" ? "DESC" : "ASC"
                    return newOrder
                })
            }
            else {
                setOrder((atualOrder) => {
                    newOrder = atualOrder
                    return newOrder
                })
            }
            return attribute
        })

        search({ _page: 1, _order: newOrder, _orderBy: attribute })
    }, [])

    return (
        <DashboardCard minWidth={minWidth} title={"Ranking Imobiliárias"} theme={theme}>
            <ContainerRanking theme={theme}>
                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    <Content maxWidth={maxWidth} theme={theme}>
                        <table style={{ width: "100%", borderSpacing: "0 0px" }}>
                            <thead>
                                <tr>
                                    <TH className="text-center"></TH>
                                    <TH></TH>
                                    {columns.map(({ attribute, className, name, noHaveClick }) => (
                                        <TH key={attribute} className={className} onClick={() => !noHaveClick ? handleOrder(attribute) : {}}>
                                            <div className={className} style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ position: "relative" }}>
                                                    {orderBy === attribute && (
                                                        <ContainerArrow invert={order === "DESC"}>
                                                            <BsArrowDownSquare
                                                                size={16}
                                                                color={inactiveItens[theme]}
                                                            />
                                                        </ContainerArrow>
                                                    )}
                                                    {name}
                                                </div>
                                            </div>
                                        </TH>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {franchisesRanking.map((item, pos) => {
                                    const correctPos = (atualPage - 1) * paginationLimit + pos
                                    return (
                                        <TableLine key={item.name} isPair={correctPos % 2 === 0} isPodium={correctPos <= 2} theme={theme}>
                                            <TD className="text-center">
                                                <div>
                                                    {(correctPos <= 2) ? <RiMedalFill color={colors_podium[correctPos]} size={20} /> : `${correctPos + 1}º`}
                                                </div>
                                            </TD>
                                            <TD style={{ padding: 5 }}>
                                                <ContainerLogo theme={theme}>
                                                    {item.logo ?
                                                        <Img src={item.logo} />
                                                        :
                                                        <ContainerLogoDefault>
                                                            <FaUserCircle size={30} />
                                                        </ContainerLogoDefault>
                                                    }
                                                </ContainerLogo>
                                            </TD>
                                            {columns.map(({ attribute, className }) => (
                                                <TD key={attribute} className={className + (valueIsEmpty(item[attribute]) ? " empty" : "")}>
                                                    {franchiseValue(item, attribute)}
                                                </TD>
                                            ))}
                                        </TableLine>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Content>
                    {controlPagination && childrenPaginationCustom}
                    {!controlPagination && childrenPaginationCustom}
                </ContentOrLoading>
            </ContainerRanking>
        </DashboardCard>
    )
}