import React, { FormEvent, forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { Form } from "./style";
import { uniqueId } from "lodash";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { Tooltip } from "../../../../../Tooltip";
import { useApi } from "../../../../../../hooks/api";
import { inactiveItens } from "../../../../../../theme";
import { useGlobal } from "../../../../../../hooks/global";
import { AddButton, Column, Line } from "../../../../../../style";
import { ModalLoading } from "../../../../../Loadings/ModalLoading";
import { validateDate } from "../../../../../../utils/validatesFields";
import { correctDate2 } from "../../../../../../services/generalServices";
import { GenericForm, IGenericFormRefProps } from "../../../../../GenericForm";
import { IGroupProps, IOptionsProps, customers_proposalAttributes } from "../../../../../../interfaces";
import { consultsOptionsRelations, responsibleTypeOptions, securityTypeOptions, warrantyOptions } from "../../../../../../utils/options";

interface IWarrantyFormProps {
    defaultData: any
    onSuccess: (data: any) => void
    guarantorsConsults: customers_proposalAttributes[]
    defaultGuarantors: any
    consultsOptions: IOptionsProps[]
    isDisabled: boolean
    proposalId: string
}

export interface IWarrantyFormRefProps {
    forceSubmit: () => any
}

const guarantorFields = ["customer_id", "relation"]

const WarrantyFormComponent: React.ForwardRefRenderFunction<IWarrantyFormRefProps, IWarrantyFormProps> = (props, ref) => {
    const { proposalId, isDisabled, consultsOptions, defaultGuarantors, guarantorsConsults, onSuccess, defaultData } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const generateGuarantorGroup = (defaultId?: string) => {
        const _uniqueId = defaultId ? defaultId : uniqueId()

        const newGuarantorGroup: IGroupProps = {
            name: "",
            label: "",
            uniqueId: _uniqueId,
            fields: [
                [
                    {
                        name: "customer_id-" + _uniqueId,
                        label: "Pessoa",
                        type: "select-person",
                        isClearable: true,
                        required: true,
                        getOptions: (value) => {
                            const form = watch()
                            const guarantorsFieldsIds = Object.keys(form).filter(key => key.startsWith("customer_id-"));
                            const guarantorsIds = guarantorsFieldsIds.map(id => form?.[id]?.value);
                            return consultsOptions.filter(option => !guarantorsIds.includes(option.value))
                        },
                        executeOnChange: async (value) => {
                            const relation = value?.relation
                            setValue("relation-" + _uniqueId, consultsOptionsRelations.find((option) => option.value === relation) ?? null)
                        },
                        canSee: (data) => data?.warranty_type?.value === "GUARANTOR"
                    },
                    {
                        name: "relation-" + _uniqueId,
                        label: "Tipo",
                        type: "select-fixed",
                        isClearable: true,
                        required: true,
                        options: consultsOptionsRelations,
                        getIsDisabled: () => true,
                        canSee: (data) => data?.warranty_type?.value === "GUARANTOR"
                    }
                ],
            ]
        }

        return newGuarantorGroup
    }

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const defaultGuarantorsGroups = () => {
        if (defaultGuarantors && defaultGuarantors.length > 0) {
            const _guarantorsGroups: any[] = []

            const _defaultDataGuarantors = defaultGuarantors ?? []
            _defaultDataGuarantors.forEach((executor: any) => {
                _guarantorsGroups.push(generateGuarantorGroup(executor.uniqueId))
            })

            return _guarantorsGroups
        }
        return []
    }

    const [loading, setLoading] = useState(false)
    const [guarantorsGroups, setGuarantorsGroups] = useState<IGroupProps[]>(defaultGuarantorsGroups())

    const buttonSubmitRef = useRef<HTMLButtonElement>(null)
    const guarantorsRefs = useRef<IGenericFormRefProps[]>([])
    const formNoGuarantorRef = useRef<IGenericFormRefProps>(null)
    const formWarrantyTypeRef = useRef<IGenericFormRefProps>(null)

    const forceSubmit = useCallback(() => buttonSubmitRef.current?.click(), [buttonSubmitRef])

    const onSubmit = useCallback(async () => {

        setLoading(true)

        const formWarrantyType = formWarrantyTypeRef.current?.getForm()
        const formNoGuarantor = formNoGuarantorRef.current?.getForm()
        const guarantors = getGuarantors()
        const data = {
            warranty_type: formWarrantyType?.warranty_type,
            warranty: { ...formNoGuarantor, guarantors }
        }
        if (data.warranty?.date_init) data.warranty.date_init = correctDate2(data.warranty.date_init)
        if (data.warranty?.date_end) data.warranty.date_end = correctDate2(data.warranty.date_end)

        try {

            await api.put(`/proposals/warranty/${proposalId}`, data)

            notify("Proposta atualizada com sucesso.", "success")

            onSuccess(data)

        } catch (err: any) {

            notify("Erro ao atualizar proposta.", "error")

        }

        setLoading(false)

    }, [_form, guarantorsConsults, guarantorsRefs, formWarrantyTypeRef, formNoGuarantorRef])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, guarantorsConsults, guarantorsRefs, formWarrantyTypeRef, formNoGuarantorRef])

    const getGuarantors = useCallback(() => {
        const _refs = guarantorsRefs.current.filter((ref) => ref?.getForm())
        const owners = _refs.map((ref) => {
            const form = ref?.getForm()
            const _uniqueId = form?.uniqueId
            const customer_id = form?.["customer_id-" + _uniqueId]
            const relation = form?.["relation-" + _uniqueId]

            return { customer_id, relation }
        })
        return owners
    }, [_form, guarantorsConsults, guarantorsRefs, formWarrantyTypeRef, formNoGuarantorRef])

    const addGuarantorGroup = useCallback(() => {
        const newGuarantorGroup = generateGuarantorGroup()
        setGuarantorsGroups((prev) => [...prev, newGuarantorGroup])
    }, [uniqueId])

    const removeGuarantorGroup = useCallback((index: number) => {
        setGuarantorsGroups((prev) => {
            return prev.filter(({ uniqueId }, i) => {
                if (i !== index) return true
                else guarantorFields.forEach((field) => unregister(field + "-" + uniqueId))
            })
        })
    }, [])

    const groupsType: IGroupProps[] = [
        {
            label: "",
            name: "",
            fields: [
                [
                    {
                        name: "warranty_type",
                        label: "Garantia",
                        type: "select-fixed",
                        options: warrantyOptions,
                        required: true,
                        isClearable: true
                    }
                ]
            ]
        }
    ]

    const groupsNoGuarantor: IGroupProps[] = [
        {
            label: "",
            name: "",
            fields: [
                [
                    {
                        name: "security_type",
                        label: "Tipo",
                        type: "select-fixed",
                        options: securityTypeOptions,
                        required: true,
                        isClearable: true,
                        canSee: (data: any) => data?.warranty_type?.value === "SECURITY"
                    },
                    {
                        name: "responsible_type",
                        label: "Responsável",
                        type: "select-fixed",
                        options: responsibleTypeOptions,
                        tooltip: "Quem administra o dinheiro da garantia?",
                        required: true,
                        isClearable: true,
                        canSee: (form: any) => form?.warranty_type?.value === "SECURITY" && form?.security_type?.value === "value"
                    }
                ],
                [
                    {
                        name: "description",
                        label: "Descrição",
                        type: "input",
                        canSee: (form: any) => form?.warranty_type?.value === "SECURITY" && ["letter_of_guarantee", "others"].includes(form?.security_type?.value)
                    },
                    {
                        name: "identification",
                        label: "Identificador",
                        type: "input",
                        canSee: (form: any) => form?.warranty_type?.value === "SECURITY" && ["letter_of_guarantee", "others"].includes(form?.security_type?.value)
                    }
                ],
                [
                    {
                        name: "date_init",
                        label: "De",
                        type: "input",
                        mask: "date",
                        validate: validateDate,
                        canSee: (form: any) => form?.warranty_type?.value && !["GUARANTOR", "NO_WARRANTY"].includes(form?.warranty_type?.value ?? "")
                    },
                    {
                        name: "date_end",
                        label: "Até",
                        type: "input",
                        mask: "date",
                        validate: validateDate,
                        canSee: (form: any) => form?.warranty_type?.value && !["GUARANTOR", "NO_WARRANTY"].includes(form?.warranty_type?.value ?? "")
                    },
                    {
                        name: "value",
                        label: "Valor",
                        type: "input",
                        mask: "currency",
                        canSee: (form: any) => form?.warranty_type?.value && !["GUARANTOR", "NO_WARRANTY"].includes(form?.warranty_type?.value ?? "")
                    }
                ],
                [
                    {
                        name: "bank",
                        label: "Banco",
                        type: "input",
                        canSee: (form: any) => form?.warranty_type?.value === "CAPITALIZATION_TITLE"
                    },
                    {
                        name: "insurance_company",
                        label: "Seguradora",
                        type: "input",
                        canSee: (form: any) => form?.warranty_type?.value === "GUARANTEE_INSURANCE"
                    },
                    {
                        name: "policy",
                        label: "Apólice",
                        type: "input",
                        canSee: (form: any) => ["CAPITALIZATION_TITLE", "GUARANTEE_INSURANCE"].includes(form?.warranty_type?.value)
                    },
                    {
                        name: "number_of_installments",
                        label: "Nº de parcelas",
                        type: "input",
                        mask: "int",
                        canSee: (form: any) => form?.warranty_type?.value === "GUARANTEE_INSURANCE"
                    }
                ],
                [
                    {
                        name: "background_type",
                        label: "Tipo de fundo",
                        type: "input",
                        canSee: (form: any) => form?.warranty_type?.value === "FIDUCIARY_ASSIGNMENT"
                    },
                    {
                        name: "application_number",
                        label: "Nº da aplicação",
                        type: "input",
                        canSee: (form: any) => form?.warranty_type?.value === "FIDUCIARY_ASSIGNMENT"
                    }
                ],
                [
                    {
                        name: "observations",
                        label: "Observações",
                        type: "textarea",
                        canSee: (form: any) => form?.warranty_type?.value && !["GUARANTOR", "NO_WARRANTY"].includes(form?.warranty_type?.value ?? "")
                    }
                ]
            ]
        }
    ]

    useImperativeHandle(ref, () => ({ forceSubmit }))

    return (
        <>
            <Form theme={theme} onSubmit={handleStopPropagation}>
                <Column style={{ gap: 20 }}>
                    <GenericForm
                        ref={formWarrantyTypeRef}
                        groups={groupsType}
                        _form={_form}
                        control={control}
                        errors={errors}
                        trigger={trigger}
                        setValue={setValue}
                        register={register}
                        disabled={isDisabled}
                    />
                    {_form?.warranty_type?.value && _form?.warranty_type?.value !== "GUARANTOR" && (
                        <GenericForm
                            ref={formNoGuarantorRef}
                            groups={groupsNoGuarantor}
                            _form={_form}
                            control={control}
                            errors={errors}
                            trigger={trigger}
                            setValue={setValue}
                            register={register}
                            disabled={isDisabled}
                        />
                    )}
                    {_form.warranty_type?.value === "GUARANTOR" && (
                        <>
                            {guarantorsGroups.map((owner_group, index) => (
                                <Line key={owner_group.uniqueId} style={{ gap: 10 }}>

                                    {!isDisabled && (guarantorsGroups.length > 1) && (
                                        <Line style={{ alignItems: "flex-start", marginTop: 24, minWidth: 36 }}>
                                            <Tooltip
                                                onClick={() => removeGuarantorGroup(index)}
                                                style={{ height: 36, width: 36 }}
                                                title="Excluir"
                                                children={(
                                                    <div>
                                                        <BsTrash
                                                            color={inactiveItens[theme]}
                                                            size={20}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </Line>
                                    )}

                                    <Column style={{ gap: 20, flex: 1 }}>
                                        <GenericForm
                                            ref={(ref: any) => guarantorsRefs.current[index] = ref}
                                            groups={[owner_group]}
                                            _form={_form}
                                            control={control}
                                            errors={errors}
                                            trigger={trigger}
                                            setValue={setValue}
                                            register={register}
                                            disabled={isDisabled}
                                        />
                                    </Column>

                                </Line>
                            ))}
                            {guarantorsGroups.length === 0 && (
                                <div style={{ textAlign: "center" }}>
                                    Nenhum fiador vinculado à proposta
                                </div>
                            )}
                            {guarantorsGroups.length < consultsOptions.length && !isDisabled && (
                                <Line>
                                    <AddButton noHaveDelete={!(guarantorsGroups.length > 1)} theme={theme} onClick={addGuarantorGroup}>
                                        Incluir+
                                    </AddButton>
                                </Line>
                            )}
                        </>
                    )}
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Column>
            </Form>

            <ModalLoading theme={theme} loading={loading} />
        </>
    )
}

export const WarrantyForm = forwardRef(WarrantyFormComponent)
