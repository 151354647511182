import Button from "../../Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { BsCheck } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { secondary } from "../../../theme";
import { useApi } from "../../../hooks/api";
import { Column, Line } from "../../../style";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { useWallet } from "../../../hooks/wallet";
import { ModalGeneric } from "../../ModalGeneric";
import { IGroupProps } from "../../../interfaces";
import { ModalLoading } from "../../Loadings/ModalLoading";
import { personsTypesOptions } from "../../../utils/options";
import { maskFunctions } from "../../../services/maskServices";
import { MaxCoinSmall20 } from "../../SvgComponents/Icons/MaxCoinSmall20";
import { validateCNPJ, validateCPF } from "../../../utils/validatesFields";
import { Container, ContainerBottom, ContainerDebit, ContainerIcon, ContainerIndicators, ContainerMxsCoins, Form, Indicator } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../GenericForm";

interface IModalQueryProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

const indicators = [
    "Score de crédito",
    "Dados Cadastrais",
    "Outros Endereços",
    "Outros Emails",
    "Outros Telefones",
    "Quadro Societário",
    "Participação em Empresas",
    "Total de Consultas Realizadas",
    "Indicadores",
    "Pendências Financeiras",
    "Inadimplências Comunicadas",
    "Ações Judiciais",
    "Protestos",
    "Controle Cheque Sem Fundo"
]

const max_consult_text = `
    Pesquisa as informações cadastrais e de crédito da empresa.
    As informações são dispostas em grupos para facilitar a compreensão dos dados.
    Essa fonte busca detalhes da empresa pesquisada,
    traz também indicadores de crédito e o Score,
    risco de crédito, probabilidade de pagamento e ainda informações relacionadas a
    pendências financeiras, dados cadastrais, busca do quadro societário, participações da
    empresa pesquisada em outras empresas, processos e protestos, cheque sem fundo.
`

export const ModalQuery: React.FC<IModalQueryProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId = null } = props

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: { type: personsTypesOptions.find((p) => p.value === "PF") } as any,
        mode: "all"
    });

    const [loading, setLoading] = useState(!!defaultId)

    const _form = watch()
    const { api } = useApi()
    const _wallet = useWallet()
    const { theme, notify, multiple_mxs } = useGlobal()

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const onSubmit = useCallback(async () => {
        setLoading(true)

        const form = form_ref?.current?.getForm() as any

        const cpf_cnpj = form.cpf ?? form.cnpj

        try {

            await api.post("/queries", { cpf_cnpj })

            onSave()

        } catch (err) {

            notify("Erro ao consultar Biro", "error")

            onCancel()

        }

        setLoading(false)
    }, [_form, api, form_ref])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, api, form_ref])

    const getValueOfQuery = (_wallet: any, type: "PF" | "PJ") => {
        if (type === "PF") return _wallet?.subscription?.plan?.pf_unit_value ?? 0
        else return _wallet?.subscription?.plan?.pj_unit_value ?? 0
    }

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "type",
                        label: "Tipo",
                        type: "select-fixed",
                        required: true,
                        options: personsTypesOptions
                    }
                ],
                [
                    {
                        name: "cpf",
                        label: "CPF",
                        type: "input",
                        mask: "cpf",
                        validate: validateCPF,
                        required: true,
                        canSee: (data: any) => data?.type?.value === "PF"
                    },
                    {
                        name: "cnpj",
                        label: "CNPJ",
                        type: "input",
                        mask: "cnpj",
                        required: true,
                        validate: validateCNPJ,
                        canSee: (data: any) => data?.type?.value === "PJ"
                    },
                ]
            ]
        }
    ]

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }} >
            <Container theme={theme}>

                <ModalHeader
                    title={"Preencher CPF ou CNPJ"}
                    subTitle={"É necessário informar o CPF da pessoa ou CNPJ da empresa que deseja realizar a consulta"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <Form onSubmit={handleStopPropagation}>
                    <GenericForm
                        ref={form_ref}
                        groups={groups}
                        _form={_form}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                    />
                    <Column style={{ gap: 10 }}>

                        <div style={{ fontWeight: 600 }}>Max consulta</div>

                        <div>{max_consult_text}</div>

                        <ContainerIndicators>
                            {indicators.map((indicator) => (
                                <Indicator key={indicator}>
                                    <ContainerIcon>
                                        <BsCheck
                                            color={secondary[theme]}
                                            size={19}
                                        />
                                    </ContainerIcon>
                                    {indicator}
                                </Indicator>
                            ))}
                        </ContainerIndicators>

                    </Column>
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Form>

                <ContainerBottom theme={theme}>

                    <ContainerDebit theme={theme}>
                        Será debitado
                        <ContainerMxsCoins>
                            <MaxCoinSmall20 />
                            {maskFunctions.mxs.mask(getValueOfQuery(_wallet, _form?.type?.value) * multiple_mxs)} MXs
                        </ContainerMxsCoins>
                    </ContainerDebit>

                    <Button
                        type="button"
                        onClick={() => buttonSubmitRef.current?.click()}
                        color="#FFF"
                        background={secondary[theme]}
                        children="Realizar consulta"
                    />

                </ContainerBottom>

                <ModalLoading loading={loading} theme={theme} />

            </Container>
        </ModalGeneric>
    )
}