import React, { FormEvent, useCallback, useRef, useState } from "react";
import Button from "../../../components/Buttons/Button";
import { uniqueId } from "lodash";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { useApi } from "../../../hooks/api";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { Tooltip } from "../../../components/Tooltip";
import { AddButton, Column, Line } from "../../../style";
import { inactiveItens, secondary } from "../../../theme";
import { ModalHeader } from "../../../components/ModalHeader";
import { consultsOptionsRelations } from "../../../utils/options";
import { IGroupProps, proposalsAttributes } from "../../../interfaces";
import { ModalLoading } from "../../../components/Loadings/ModalLoading";
import { GenericForm, IGenericFormRefProps } from "../../GenericForm";
import { Container, ContainerBottom, ContainerForm, Form, TextInformation } from "./style";

interface IModalConsultsProps {
    onCancel: Function
    onSave: Function
    defaultData?: proposalsAttributes | null
    defaultConsults?: any[]
}

const correct_return: any = {
    guarantor: "guarantor_spouse",
    guarantor_spouse: "guarantor",
    tenant: "tenant_spouse",
    tenant_spouse: "tenant"
}

const tenantsFields = ["customer_id", "relation"]

const title = {
    new: "Nova Proposta",
    edit: "Editar Consultas"
}

const subTitle = `
        Informe abaixo os dados das pessoas envolvidas no
        processo de locação para consulta cadastral,
        análise de risco e histórico de crédito
`

export const ModalConsults: React.FC<IModalConsultsProps> = (props) => {
    const { onCancel, onSave, defaultData, defaultConsults } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const generateTenantsGroup = (defaultId?: string) => {
        const _uniqueId = defaultId ? defaultId : uniqueId()

        const newTenantGroup: IGroupProps = {
            name: "",
            label: "",
            uniqueId: _uniqueId,
            fields: [
                [
                    {
                        name: "customer_id-" + _uniqueId,
                        label: "Pessoa",
                        type: "select-person",
                        required: true,
                        isClearable: true,
                        getRemoveIds: (obj: any) => {
                            const customerIds = Object.keys(obj).filter(key => key.startsWith('customer_id-'));
                            const customerValues = customerIds.map(id => obj?.[id]?.value);
                            return customerValues?.filter((value: any) => !!value)
                        },
                        backgroundVisible: true,
                        executeOnChange: async (value: any) => {

                            const spouse = value?.this?.spouse
                            const spouse_name = spouse?.registration_data?.name
                            const spouse_id = spouse?.id

                            let spouse_option = value?.spouse

                            if (!spouse_option && spouse_id) {
                                spouse_option = { label: spouse_name, value: spouse_id }
                            }

                            if (spouse_option?.value) {
                                const form = watch()
                                const type = form?.["relation-" + _uniqueId]?.value

                                const tenants = Object.keys(form).filter(key => key.startsWith('customer_id-') && key !== `customer_id-${_uniqueId}`);
                                const tenantsValues = tenants.map(id => form?.[id]?.value)?.filter((value: any) => !!value) ?? []

                                if (!tenantsValues.includes(spouse_option?.value)) {

                                    setTenantsGroups((prevGroups) => {
                                        let group_empty = prevGroups.find((group: any) => !form[`customer_id-${group.uniqueId}`]?.value && group.uniqueId !== _uniqueId)?.uniqueId

                                        if (!group_empty) {
                                            const newOwnerGroup = generateTenantsGroup()
                                            setValue(`customer_id-${newOwnerGroup.uniqueId}`, spouse_option)
                                            if (type) setValue(`relation-${newOwnerGroup.uniqueId}`, consultsOptionsRelations.find((option: any) => option.value === correct_return[type]))
                                            return [...prevGroups, newOwnerGroup]
                                        } else {
                                            setValue(`customer_id-${group_empty}`, spouse_option)
                                            if (type) setValue(`relation-${group_empty}`, consultsOptionsRelations.find((option: any) => option.value === correct_return[type]))
                                            return [...prevGroups]
                                        }

                                    })

                                }
                            }

                        }
                    },
                    {
                        name: "relation-" + _uniqueId,
                        label: "Tipo",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: consultsOptionsRelations
                    }
                ]
            ]
        }

        return newTenantGroup
    }

    const tenantsRefs = useRef<IGenericFormRefProps[]>([])
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const [loadingCreate, setLoadingCreate] = useState(false)
    const [tenantsGroups, setTenantsGroups] = useState<IGroupProps[]>(() => {
        if (defaultConsults && defaultConsults.length > 0) {
            const _signatoriesGroups: any[] = []

            const _defaultDataSignatories = defaultConsults ?? []
            _defaultDataSignatories.forEach((executor: any) => {
                _signatoriesGroups.push(generateTenantsGroup(executor.uniqueId))
            })

            return _signatoriesGroups
        }
        return [generateTenantsGroup()]
    })

    const onSubmit = useCallback(async () => {

        setLoadingCreate(true)

        const consults: any = getTenants()

        try {
            if (defaultData) await api.put(`/proposals/queries/${defaultData?.id}`, { consults })
            else await api.post("/proposals", { consults })

            const saveText = defaultData ? "atualizada" : "cadastrada"

            notify(`Proposta ${saveText} com sucesso.`, "success")

            onSave()

        } catch (err: any) {

            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) { }
            else notify("ERRO INTERNO DO SISTEMA", "error")

        }

        setLoadingCreate(false)

    }, [tenantsRefs, _form, api, defaultData])

    const getTenants = useCallback(() => {
        const _refs = tenantsRefs.current.filter((ref) => ref?.getForm())
        const tenants = _refs.map((ref) => {
            const form = ref?.getForm()
            const _uniqueId = form?.uniqueId
            return {
                customer_id: form?.["customer_id-" + _uniqueId],
                relation: form?.["relation-" + _uniqueId],
                uniqueId: _uniqueId
            }
        })
        return tenants
    }, [tenantsRefs])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, tenantsRefs, _form, api, defaultData])

    const addSignatoryGroup = useCallback(() => {
        const newOwnerGroup = generateTenantsGroup()
        setTenantsGroups((prev) => {
            return [...prev, newOwnerGroup]
        })
    }, [uniqueId])

    const removeOwnerGroup = useCallback((index: number) => {
        setTenantsGroups((prev) => {
            return prev.filter(({ uniqueId }, i) => {
                if (i !== index) return true
                else tenantsFields.forEach((field) => unregister(field + "-" + uniqueId))
            })
        })
    }, [])

    return (
        <ModalGeneric open onClose={() => { }} >

            <Container theme={theme}>

                <ModalHeader
                    title={title[defaultData?.id ? "edit" : "new"]}
                    subTitle={subTitle}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <ContainerForm>

                    <TextInformation>
                        <b>Importante!</b> Informe os dados das partes envolvidas, locatários,
                        fiadores, seus respectivos cônjuges e as pessoas jurídicas,
                        excetos os proprietários.
                    </TextInformation>

                    <Form onSubmit={handleStopPropagation} theme={theme}>

                        {tenantsGroups.map((tenantGroup, index) => (
                            <Line key={tenantGroup.uniqueId} style={{ gap: 10 }}>
                                {tenantsGroups.length > 1 && (
                                    <Line style={{ alignItems: "flex-start", marginTop: 24, minWidth: 36 }}>
                                        <Tooltip
                                            onClick={() => removeOwnerGroup(index)}
                                            style={{ height: 36, width: 36 }}
                                            title="Excluir"
                                            children={(
                                                <div>
                                                    <BsTrash
                                                        color={inactiveItens[theme]}
                                                        size={20}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Line>
                                )}

                                <Column style={{ gap: 20, flex: 1 }}>
                                    <GenericForm
                                        ref={(ref: any) => tenantsRefs.current[index] = ref}
                                        groups={[tenantGroup]}
                                        _form={_form}
                                        control={control}
                                        errors={errors}
                                        trigger={trigger}
                                        setValue={setValue}
                                        register={register}
                                    />
                                </Column>
                            </Line>
                        ))}

                        <Line>
                            <AddButton noHaveDelete={!(tenantsGroups.length > 1)} onClick={addSignatoryGroup}>
                                Incluir+
                            </AddButton>
                        </Line>

                        <button
                            ref={buttonSubmitRef}
                            type="submit"
                            style={{ display: "none" }}
                        />
                    </Form>
                </ContainerForm>

                <ContainerBottom theme={theme}>
                    <Button
                        onClick={() => buttonSubmitRef.current?.click()}
                        background={secondary[theme]}
                        color={"#FFF"}
                        style={{ padding: 8, fontSize: 14 }}
                        children="Realizar Consulta(s)"
                    />
                </ContainerBottom>

                <ModalLoading
                    loading={loadingCreate}
                    theme={theme}
                />

            </Container>

        </ModalGeneric>
    )
}
