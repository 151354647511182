import Button from "../../../../../Buttons/Button";
import React, { useCallback, useRef } from "react";
import { Modal } from "@material-ui/core";
import { FiAlertCircle } from "react-icons/fi";
import { useGlobal } from "../../../../../../hooks/global";
import { borderColors, inactiveItens, secondary } from "../../../../../../theme";
import { BaseInput, Container, ContainerButton, ContainerText, Description } from "./style";

interface ModalConfirmActionProps {
    open: boolean
    onCancel: Function
    onConfirm: Function
    friendly_id?: string
    type: "PA" | "PR" | "EXPORT_SUPER" | "PC" | "AP" | "CF" | "PEA"
}

const correct_status = {
    "PA": "Proposta Aprovada",
    "PR": "Proposta Reprovada",
    "PEA": "Proposta Em Análise",
    "AP": "Aguardando Preenchimento",
    "CG": "Contrato Gerado",
    "EXPORT_SUPER": "Exportação para Superlógica",
    "PC": "Proposta Cancelada",
    "CF": "Contrato Finalizado"
}

export const ModalConfirmAction: React.FC<ModalConfirmActionProps> = (props) => {
    const { open, onCancel, onConfirm, type, friendly_id } = props

    const { theme } = useGlobal()

    const reason_ref = useRef<HTMLTextAreaElement>(null)

    const submit = useCallback((event) => {
        event.preventDefault()
        const reason = reason_ref.current ? reason_ref.current.value : null
        onConfirm({ type, reason })
    }, [type, reason_ref])

    return (
        <Modal open={open} onClose={() => onCancel()} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Container theme={theme} onSubmit={submit}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ padding: 20, display: "flex", justifyContent: "center", alignItems: "center", background: borderColors[theme], borderRadius: "50%" }}>
                        <FiAlertCircle size={40} color={inactiveItens[theme]} />
                    </div>
                </div>
                <ContainerText>
                    {type === "EXPORT_SUPER" &&
                        <Description>
                            Deseja realmente <b>exportar</b> esta <b>proposta</b> ?
                        </Description>
                    }
                    {type !== "EXPORT_SUPER" &&
                        <Description>
                            Deseja realmente alterar o status da proposta <b>#{friendly_id}</b> para <span style={{ fontWeight: 600 }}> {correct_status[type]}</span> ?
                            {type === "PR" && " Caso afirmativo, informe o motivo da reprovação!"}
                            {type === "PC" && " Caso afirmativo, informe o motivo do cancelamento!"}
                        </Description>
                    }
                    {"EXPORT_SUPER" === type &&
                        <ul style={{ color: inactiveItens[theme] }}>
                            <li>Apenas o <b>imóvel</b> e as <b>pessoas</b> envolvidas na <b>proposta</b> serão exportados</li>
                            <li>Esta operação pode demorar alguns minutos para ser finalizada</li>
                            <li>Ao fim desta operação será gerado uma mensagem automática informando o status da mesma</li>
                        </ul>
                    }
                    {["PR", "PC"].includes(type) &&
                        <BaseInput theme={theme}>
                            <textarea ref={reason_ref} required rows={4} className="form-control form-control-rounded position-relative" style={{ resize: "none" }}></textarea>
                        </BaseInput>
                    }
                </ContainerText>
                <ContainerButton>
                    <Button onClick={() => onCancel()} background={inactiveItens[theme]} color="#FFF" style={{ height: 40, margin: 0, boxShadow: "0.125rem 0.125rem 0.5rem #57545419", justifyContent: "center", alignItems: "center", gap: 5, fontSize: 16, minWidth: 80, flex: 1 }}>
                        Voltar
                    </Button>
                    <Button type="submit" background={secondary[theme]} color="#FFF" style={{ height: 40, margin: 0, boxShadow: "0.125rem 0.125rem 0.5rem #57545419", justifyContent: "center", alignItems: "center", gap: 5, fontSize: 16, minWidth: 80, flex: 1 }}>
                        Confirmar
                    </Button>
                </ContainerButton>
            </Container>
        </Modal >
    )
}