import React from "react";
import { Line } from "./style";
import { themeTypes } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";

interface IWaveLoadingProps {
    themeDefault?: themeTypes
}

export const WaveLoading: React.FC<IWaveLoadingProps> = (props) => {
    const { themeDefault } = props

    const { theme } = useGlobal()

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
            <Line theme={themeDefault ? themeDefault : theme} duration={0} />
            <Line theme={themeDefault ? themeDefault : theme} duration={0.1} />
            <Line theme={themeDefault ? themeDefault : theme} duration={0.2} />
            <Line theme={themeDefault ? themeDefault : theme} duration={0.3} />
            <Line theme={themeDefault ? themeDefault : theme} duration={0.4} />
        </div>
    )
}