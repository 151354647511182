import Button from "../../../Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { Column, ContainerButtons, Form } from "./style";
import { inactiveItens, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { userPersonsTypesOptions } from "../../../../utils/options";
import { IGroupProps, personsAttributes } from "../../../../interfaces";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";

interface IFormUserProps {
    onCancel: Function
    onSave: Function
    defaultData?: personsAttributes | null
    defaultPerson?: personsAttributes
}

export const FormUser: React.FC<IFormUserProps> = (props) => {
    const { onCancel, defaultData = {} as any, onSave } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const [loading, setLoading] = useState(false)

    const buttonSubmitRef = useRef<HTMLButtonElement>(null)
    const form_ref = useRef<IGenericFormRefProps>(null)

    const onSubmit = useCallback(async (data: any) => {
        setLoading(true)
        const { type, person_id } = form_ref.current?.getForm() ?? {}

        try {
            await api.put(`/persons/type/${person_id}`, { type })
            notify(`Usuário atualizado com sucesso!`, "success")
            onSave()
        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) {
                if (error.path === "email") error.path = "person_id"
                setError(error.path, { type: "manual", message: error.message })
            }
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoading(false)
    }, [form_ref])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "type",
                        label: "Tipo do Usuário",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: userPersonsTypesOptions
                    }
                ],
                [
                    {
                        name: "person_id",
                        label: "Pessoa",
                        type: "select-person",
                        required: true,
                        getIsDisabled: () => !!defaultData?.id,
                        backgroundVisible: true,
                        isClearable: true,
                        moreQueries: {
                            onlyCustomers: true,
                            type: "PF"
                        }
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <Column style={{ overflow: "auto", flex: 1, gap: 20, padding: 20 }}>
                <Form onSubmit={handleStopPropagation}>
                    <GenericForm
                        ref={form_ref}
                        groups={groups}
                        _form={_form}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                    />

                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Form>
            </Column>

            <ContainerButtons theme={theme}>
                <Button
                    onClick={() => onCancel()} background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Voltar"
                />
                <Button
                    onClick={() => buttonSubmitRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14, display: "flex" }}
                    children="Salvar"
                />
            </ContainerButtons>

            {loading && (
                <ModalLoading
                    loading={loading}
                    theme={theme}
                />
            )}

        </>
    )
}