import React from "react"

export const Superlogica16: React.FC<{ color: any }> = ({ color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        children={(
            <path
                fill={color}
                d="M1.246 10.688a.257.257 0 0 1 .372-.145c.044.026.08.064.102.11l.709.929a1.25 1.25 0 0 0 .98.575l3.5.407a1.355 1.355 0 0 1 .994.576l.82 1.06c.153.203.103.383-.134.456l-4.594 1.22a1.276 1.276 0 0 1-1.13-.153L.855 14.57a1.45 1.45 0 0 1-.829-1.602c.041-.213.13-.414.259-.589l.96-1.693ZM5.35 1.703l-.911.71a1.273 1.273 0 0 0-.56.979l-.455 3.515a1.438 1.438 0 0 1-.555 1.017l-1.015.799c-.203.168-.383.103-.455-.135L.166 4.002a1.281 1.281 0 0 1 .153-1.117L1.468.855C1.988-.024 2.75-.242 3.627.28l1.688.964c.238.138.238.291.035.46Zm2.09-.322L11.966.145a1.264 1.264 0 0 1 1.13.134l2.01 1.152c.895.506 1.148 1.3.623 2.183l-.98 1.693c-.115.219-.318.253-.47.05l-.744-.948a1.386 1.386 0 0 0-.995-.576l-3.446-.407a1.357 1.357 0 0 1-.995-.575L7.29 1.838a.265.265 0 0 1 .15-.457Zm4.135 12.2a1.323 1.323 0 0 0 .56-1.017l.405-3.485a1.339 1.339 0 0 1 .555-.982l1.05-.806c.202-.15.382-.084.436.154l1.217 4.551a1.284 1.284 0 0 1-.119 1.117l-1.148 2.03c-.51.879-1.302 1.102-2.179.576l-1.673-.948c-.233-.134-.233-.303-.03-.457l.926-.733Z"
            />
        )}
    />
)
