import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { secondary, success } from "../../theme";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { RESPONSIBLE_USERS } from "../../utils/users.groups";
import { ModalUser } from "../../components/Modals/ModalUser";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ModalAttention } from "../../components/Modals/ModalAttention";
import { initialsName, setUpConsultation } from "../../services/generalServices";
import { IActions, IFilters, ITagList, personsAttributes } from "../../interfaces/index";

const initialFilters: IFilters[] = [
    getFilterFields("user_type", RESPONSIBLE_USERS)
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome completo", limitWidth: true, style: { whiteSpace: "nowrap" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "cpf", name: "CPF", style: {}, styleContent: {} },
        { attr: "email", name: "Email", limitWidth: true, style: {}, styleContent: {} },
        { attr: "phone", name: "Telefone", style: {}, styleContent: {} },
        { attr: "city", name: "Cidade", style: {}, styleContent: {} },
        { attr: "type", name: "Tipo", style: { textAlign: "center" }, styleContent: { textAlign: "center" } }
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Novo Usuário", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

const getNameWithPhoto: React.FC<ITagList> = ({ person, theme }) => {
    if (person.user?.photo) return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 30, maxWidth: 30, maxHeight: 30, minHeight: 30, borderRadius: "50%", overflow: "hidden" }}>
                <img src={person.user.photo} alt="Foto" style={{ minWidth: 30, minHeight: 30, objectFit: "cover" }} />
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{person?.registration_data?.name || null}</div>
        </div>

    )
    else return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: 12, color: "rgb(21 21 21)", background: "rgb(206 206 206)", alignItems: "center", minWidth: 30, maxWidth: 30, minHeight: 30, maxHeight: 30, borderRadius: "50%" }}>
                {initialsName(person?.registration_data?.name as string)}
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{person?.registration_data?.name || null}</div>
        </div>
    )
}

const correctUserType: any = {
    "broker": { name: "Corretor", background: secondary.light },
    "analyst": { name: "Analista", background: success },
    "manager": { name: "Gerente", background: "#000094" }
}

const getType: React.FC<ITagList> = ({ person, theme }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ padding: 3, justifyContent: "center", whiteSpace: "nowrap", display: "flex", paddingLeft: 5, paddingRight: 5, borderRadius: 3, background: correctUserType[person.type].background, color: "#FFF", fontSize: 11 }}>{correctUserType[person.type].name}</div>
            </div>
        </div>
    )
}

const getCity = (customer: personsAttributes) => {
    if (customer?.address?.city && customer?.address?.state) {
        return `${customer?.address?.city.name} - ${customer?.address?.state?.initials}`
    }
    else return null
}

export const UsersList: React.FC = () => {
    const { api } = useApi()
    const { theme, paginationLimit, notify, franchise } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [numOfPersons, setNumOfPersons] = useState(0)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingPersons, setLoadingPersons] = useState(true)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [openModalDelete, setOpenModalDelete] = useState(false)
    const [personSelected, setPersonSelected] = useState<personsAttributes | null>(null)

    const searchPersons = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingPersons(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }
            if (filters_obj.person_type) {
                filters_obj.person_types = JSON.stringify([filters_obj.person_type])
                delete filters_obj.person_type
            }
            else filters_obj.person_types = JSON.stringify(["broker", "analyst", "manager"])

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/persons?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_brokers = result.data.rows as personsAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_brokers.forEach((person) => {
                    new_table.data.push({
                        id: person.id,
                        name: getNameWithPhoto({ person, theme }),
                        cpf: person?.registration_data?.cpf ? maskFunctions.cpf.mask(person?.registration_data?.cpf) : null,
                        email: person?.user?.email || null,
                        phone: person?.registration_data?.phone ? maskFunctions.phone.mask(person?.registration_data?.phone) : null,
                        type: getType({ person, theme }),
                        city: getCity(person as personsAttributes),
                        this: person
                    })
                })
                return { ...new_table }
            })

            setNumOfPersons(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelos corretores!", "error")
        }
        setLoadingPersons(false)
    }, [franchise, filters, searchString])

    const changeStatusUser = useCallback(async (userId: string, old_status: "block" | "active" | null) => {
        const new_status = old_status === "active" ? "block" : "active"

        setTable((atual) => {
            const new_table = { ...atual }
            const pos = new_table.data.findIndex((broker: any) => broker?.this.user?.id === userId)
            if (pos !== -1) new_table.data[pos].this.user.status = new_status
            return { ...new_table }
        })

        try {
            await api.put(`/users/${userId}/status`, { status: new_status })
            notify("Status do corretor atualizado com sucesso.", "success")
        } catch (err) {
            notify("Erro ao alterar status do corretor.", "error")
            setTable((atual) => {
                const new_table = { ...atual }
                const pos = new_table.data.findIndex((broker: any) => broker?.this.user?.id === userId)
                if (pos !== -1) new_table.data[pos].this.user.status = old_status
                return { ...new_table }
            })
        }

    }, [])

    const onSaveUser = useCallback(() => {
        setOpenModal(false)
        searchPersons(1)
    }, [franchise, filters, searchString])

    const handleOpenEditUser = useCallback((person: personsAttributes) => {
        setPersonSelected({ ...person })
        setOpenModal(true)
    }, [])

    const openModalNewUser = useCallback(() => {
        setPersonSelected(null)
        setOpenModal(true)
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewUser()
        else console.log({ action_id })
    }

    const handleConfirmRemoveUser = useCallback(async (person_id) => {
        setOpenModalDelete(false)
        setLoadingDelete(true)
        try {
            await api.put(`/persons/type/${person_id}`, { type: null })
            notify("Acesso removido com sucesso!", "success")
            searchPersons(1)
        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) notify(error?.message ?? "ERRO", "error")
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }
        setLoadingDelete(false)
    }, [franchise, filters, searchString])

    const handleOpenModalDelete = useCallback((person: personsAttributes) => {
        setPersonSelected({ ...person })
        setOpenModalDelete(true)
    }, [])

    useEffect(() => { searchPersons(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingPersons}
            setLoading={setLoadingPersons}
            numberOfItems={numOfPersons}
            textHeader="Usuários"
            textItems="usuários encontrados"
            text_loading={"Buscando Usuários"}
            text_empty={"Nenhum usuário encontrado"}
            theme={theme}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_status
            have_edit
            have_delete
            status_action={(userId: string, status: "block" | "active" | null) => changeStatusUser(userId, status)}
            edit_action={handleOpenEditUser}
            delete_action={handleOpenModalDelete}
            search={searchPersons}
            pages={numberOfPages}
            page={atualPage}
            onClickOption={handleAction}
            actions={actionsInitial}
            onChangeSearch={setSearchString}
            textDelete="Remover acesso"
        >
            {openModal &&
                <ModalUser
                    onCancel={() => setOpenModal(false)}
                    onSave={onSaveUser}
                    openModal={openModal}
                    backgroundVisible={false}
                    defaultId={personSelected?.id}
                />
            }

            <ModalAttention
                theme={theme}
                open={openModalDelete}
                cancelAction={() => setOpenModalDelete(false)}
                confirmAction={() => { handleConfirmRemoveUser(personSelected?.id) }}
                content={(
                    <>
                        Você deseja remover o acesso de <b>{correctUserType?.[personSelected?.type as any]?.name?.toUpperCase()} </b>
                        do usuário <b>{personSelected?.registration_data?.name?.toUpperCase()}</b>?
                    </>
                )}
            />

            <ModalLoading loading={loadingDelete} theme={theme} />

        </PageList>
    )
}
