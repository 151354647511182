import styled, { css } from "styled-components";
import { secondary } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const StatusSubscription = styled.div<{ background: string }>`
    display: flex;
    color: #FFF;
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 4px;
    ${props => css`
        background:${props.background};
    `}
`

export const SubscriptionName = styled.div<IThemeProps>`
    font-weight: 600;
    font-size: 18px;
    ${props => css`
        color:${secondary[props.theme as themeTypes]};
    `}
`