import Button from "../../Buttons/Button";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { Column } from "../../../style";
import { Container, Form } from "./style";
import { useForm } from "react-hook-form";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { inactiveItens, secondary } from "../../../theme";
import { ModalLoading } from "../../Loadings/ModalLoading";
import { GenericForm, IGenericFormRefProps } from "../../GenericForm";
import { IGroupProps, documentsAttributes, personsAttributes, propertiesAttributes } from "../../../interfaces";

export interface IDocumentModal {
    onClose: () => void
    onSave: () => void
    customer?: personsAttributes
    property?: propertiesAttributes
    document?: documentsAttributes
    relation: string
}

const correct_relations: any = {
    guarantor: "Fiador",
    guarantor_spouse: "Cônjuge Fiador",
    tenant: "Locatário",
    tenant_spouse: "Cônjuge Locatário",
    owner: "Proprietário",
    property: "Imóvel",
    person: "Pessoa"
}

export const DocumentModal: React.FC<IDocumentModal> = (props) => {
    const { onClose, customer, property, relation, document, onSave } = props

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: document ? {
            document_type_id: {
                label: document.document_type?.name,
                value: document.document_type?.id,
                this: document.document_type
            },
            file: document.name
        } : {},
        mode: "all"
    });

    const _form = watch()

    const { api, consultsServices } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(false)

    const form_ref = useRef<IGenericFormRefProps>(null)

    const onSubmit = useCallback(async (form: any) => {
        setLoading(true)

        try {
            const formData = new FormData()
            if (form?.file?.file !== "arquivo" && form?.file?.file) formData.append("file", form?.file?.file)
            formData.append("document_type_id", form?.document_type_id?.value)

            if (document?.id) {
                await api.put(`/documents/${document.id}`, formData)
                notify(`Documento atualizado com sucesso`, "success")
            } else {
                formData.append("customer_id", customer?.id as string)
                formData.append("property_id", property?.id as string)
                await api.post("/documents", formData)
                notify(`Documento adicionado com sucesso`, "success")
            }

        } catch (err) {
            notify("Erro ao Enviar Documento", "error")
        }

        setLoading(false)
        onSave()
    }, [customer, property, document, _form, api])

    const descriptionProperty = useCallback((property: propertiesAttributes) => {
        const aux = [
            "#" + property.friendly_id,
            " - ",
            property.property_type?.name,
            " na ",
            property.address?.public_place,
            ", ",
            property.address?.number,
            ", ",
            property.address?.district,
            ", ",
            property.address?.city.name,
            " - ",
            property.address?.state.initials
        ]

        return aux.join("")
    }, [])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const groups: IGroupProps[] = [
        {
            label: "",
            name: "",
            fields: [
                [
                    {
                        label: "Tipo de Documento",
                        name: "document_type_id",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        required: true,
                        searchOptions: consultsServices.document_type_id,
                        moreQueries: { type: property?.id ? "property" : customer?.registration_data?.type }
                    }
                ],
                [
                    {
                        label: "Arquivo",
                        name: "file",
                        type: "file",
                        required: true,
                        canSee: (data: any) => data?.document_type_id?.value,
                        getFileSizeMax: (data: any) => data?.document_type_id?.this?.size,
                        getFileTypes: (data: any) => JSON.parse(data?.document_type_id?.this?.extensions ?? "[]")
                    }
                ]
            ]
        }
    ]

    return (
        <Container theme={theme}>

            <ModalHeader
                title={"Anexar Documento"}
                subTitle={(
                    <>
                        <b style={{ color: inactiveItens[theme], textTransform: "capitalize" }}>
                            {correct_relations[relation]} -
                        </b> {property ? descriptionProperty(property) : customer?.registration_data?.name}
                    </>
                )}
                onCancel={() => onClose()}
                theme={theme}
            />

            <Form onSubmit={handleStopPropagation}>

                <Column style={{ flex: 1, justifyContent: "flex-start" }}>
                    <Column>
                        <GenericForm
                            ref={form_ref}
                            groups={groups}
                            _form={_form}
                            control={control}
                            trigger={trigger}
                            errors={errors}
                            disabled={loading}
                            setValue={setValue}
                            register={register}
                        />
                    </Column>
                </Column>

                <Button
                    type="submit"
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, fontSize: 14 }}
                    children="Salvar"
                />

            </Form>

            <ModalLoading
                loading={loading}
                theme={theme}
            />

        </Container>
    )
}