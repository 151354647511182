import styled, { css } from "styled-components";
import { secondary, shadowColor } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const Box = styled.div`
    position: relative;
`

export const Perfil = styled.div`
    display: flex;
    margin: 10px;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    min-height: 40px;
    max-height: 40px;
    min-width: 40px;
    max-width: 40px;
    border-radius: 40px;
    overflow: hidden;
`

export const ContainerIcon = styled.div`
    display: flex;
    align-items: center;
`

export const ContainerDropItems = styled.div <{ color: string, background: string, borderColor: string }>`
    display: flex;
    font-weight: 600;
    flex-direction: column;
    position: absolute;
    font-size: 12px;
    z-index: 999;
    border-radius: 4px;
    padding: 4px;
    bottom: 0px;
    transform: translateY(calc(100%));
    right: 16px;
    ${props => css`
        color: ${props.color};
        background: ${props.background};
        box-shadow: 0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
    `};
`

export const ItemDropdown = styled.div<IThemeProps>`
    display: flex;
    padding: 5px;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 3px;
    gap:5px;
    ${props => css`
        :hover{
            background: ${secondary[props.theme as themeTypes]};
            color: #FFF;
        }
    `}
`