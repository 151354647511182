import React, { useCallback, useState } from "react";
import { useApi } from "../../hooks/api";
import { ResetStep } from "./Steps/ResetStep";
import { useGlobal } from "../../hooks/global";
import { FinishStep } from "./Steps/FinishStep";
import { SendEmailStep } from "./Steps/SendEmailStep";
import { ReceiveCodeStep } from "./Steps/ReceiveCodeStep";
import { Content, ContainerImage, Side, Container } from "./style";
import { ModalLoading } from "../../components/Loadings/ModalLoading";

export const ResetPassword: React.FC = () => {
    const { api } = useApi()
    const { notify } = useGlobal()

    const [pos, setPos] = useState(0)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const sendCodeToEmail = useCallback(async (email: string) => {

        setLoading(true)

        try {

            await api.post("/recover_password", { email })

        } catch (err: any) {

            notify("Erro ao enviar o código para o email.", "error")

        }

        setLoading(false)

    }, [])

    const handleFinishSendEmailStep = useCallback(async (email: string) => {
        setEmail(email)
        setPos(1)
    }, [])

    const handleFinishReceiveCodeStep = useCallback(async () => {
        setPos(2)
    }, [])

    const handleFinishResetStep = useCallback(async () => {
        setPos(3)
    }, [])

    return (
        <Container>

            <ContainerImage />

            <Content>

                <Side>

                    {pos === 0 &&
                        <SendEmailStep
                            onNext={handleFinishSendEmailStep}
                            setLoading={setLoading}
                        />
                    }

                    {pos === 1 &&
                        <ReceiveCodeStep
                            email={email}
                            onNext={handleFinishReceiveCodeStep}
                            sendCodeToEmail={sendCodeToEmail}
                        />
                    }

                    {pos === 2 &&
                        <ResetStep
                            email={email}
                            onNext={handleFinishResetStep}
                            setLoading={setLoading}
                            setPassword={setPassword}
                        />
                    }

                    {pos === 3 &&
                        <FinishStep
                            email={email}
                            password={password}
                            setLoading={setLoading}
                        />
                    }

                </Side>

            </Content>

            <ModalLoading loading={loading} theme="light" />

        </Container>
    )
}