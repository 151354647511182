import styled, { css } from "styled-components";
import { shadowColor } from "../../../../../theme";
import { themeTypes } from "../../../../../interfaces";

interface IContainer {
    minWidth: number
    minHeight?: any
    theme: themeTypes
}

export const Container = styled.div<IContainer>`
    border-radius: 5px;
    display: flex;
    flex: 1;
    flex-direction: column;
    ${props => css`
        min-width: ${props.minWidth}px;
        box-shadow: 0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
    `}
    ${props => props.minHeight && css`
        min-height: ${props.minHeight}px;
    `}
`

export const Title = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`