import styled, { css } from "styled-components";
import { background, inactiveItens } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    outline: 0;
    min-width:100vw;
    max-width:100vw;
    min-height: calc(100 * var(--vh));
    max-height: calc(100 * var(--vh));
    @media (min-width: 550px) {
        min-width:80vw;
        max-width:80vw;
        min-height: calc(90 * var(--vh));
        max-height: calc(90 * var(--vh));
    }
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const ContainerCreditRisk = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    gap: 16px;
    @media(max-width: 650px){
        flex-direction: column;
        align-items: center;
    }
`

export const Label = styled.label`
    font-weight: 600;
    white-space: nowrap;
`

export const Value = styled.div`
    text-align: end;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const ContainerValue = styled.span`
    font-size: 13.5px;
    justify-content: space-between;
    display: flex;
    gap: 10px;
`

export const TD = styled.td`
    padding: 10px;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 13.5px;
`

export const ContainerIndicators = styled.div`
    display: flex;
    flex-direction: row;
    gap:16px;
    @media(max-width: 650px){
        flex-direction: column-reverse;
        align-items: center;
    }
`