import * as React from "react"

export const LogoDark = ({ size }: { size: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.56 19.57" height={size}>
    <defs>
      <style>{".cls-2{fill:#e20055}"}</style>
    </defs>
    <title>{"Max Locação"}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          style={{
            fill: "#000094",
          }}
          d="m16.41 6.62-6.63 6.63V0l6.63 6.62z"
        />
        <path
          className="cls-2"
          d="M9.78 0v13.25l-5.61 5.61a2.41 2.41 0 0 1-1.73.71A2.45 2.45 0 0 1 0 17.12V10.8a2.45 2.45 0 0 1 .55-1.56 2.63 2.63 0 0 1 .34-.33Z"
        />
        <path
          className="cls-2"
          d="M19.56 10.8v6.32a2.43 2.43 0 0 1-2.44 2.45h-4.9a2.45 2.45 0 0 1-2.44-2.45v-3.87l6.63-6.63 2.27 2.29a2.06 2.06 0 0 1 .33.33 2.39 2.39 0 0 1 .55 1.56Z"
        />
      </g>
    </g>
  </svg>
)
