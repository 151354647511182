import Button from "../../../Buttons/Button";
import React, { useState, useCallback, useRef, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { Column, Line } from "../../../../style";
import { ModalHeader } from "../../../ModalHeader";
import { FiArrowRightCircle } from "react-icons/fi";
import { ModalGeneric } from "../../../ModalGeneric";
import { useGlobal } from "../../../../hooks/global";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { Anchor, Container, ContainerCrmLogo, Content, Form } from "./style";
import { IIntegrationTokensProps, IGroupProps } from "./../../../../interfaces";
import { Superlogica32 } from "../../../SvgComponents/Icons/Superlogica/Superlogica32";

interface IModalSuperlogicaProps {
    onCancel: Function
    onSave: Function
    integrationTokens: IIntegrationTokensProps
}

const superIcon: any = {
    dark: <Superlogica32 color="#FFF" />,
    light: <Superlogica32 color="#1034F2" />
}

const TUTORIAL_URL = "https://www.youtube.com/watch?v=GmQcQtY-ySc&ab_channel=MaxLoca%C3%A7%C3%A3o"

export const ModalSuperlogica: React.FC<IModalSuperlogicaProps> = (props) => {
    const { onCancel, onSave, integrationTokens } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState((integrationTokens.access_token && integrationTokens.app_token) ? 1 : 0)

    const form_ref = useRef<IGenericFormRefProps>(null)

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: integrationTokens as any,
        mode: "all"
    });

    const _form = watch()

    const onSubmit = useCallback(async () => {
        setLoading(true)

        try {
            const data = form_ref.current?.getForm()

            await api.put("/integrations/super_logica/edit-tokens", data)

            notify("Tokens da Superlógica atualizados com sucesso", "success")

            onSave()

        } catch (err: any) {

            const error = err?.response ? err?.response?.data : "SERVER ERROR"
            if (error === "SERVER ERROR" || error.message === "Internal server error!") notify("Erro Interno do Sistema!", "error")
            else {
                setError("app_token", { type: "manual", message: error.message })
                setError("access_token", { type: "manual", message: error.message })
            }

        }

        setLoading(false)
    }, [api, form_ref])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, api])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "app_token",
                        label: "App Token",
                        type: "input",
                        required: true,
                        noUpperCase: true
                    }
                ],
                [
                    {
                        name: "access_token",
                        label: "Access Token",
                        type: "input",
                        required: true,
                        noUpperCase: true
                    }
                ]
            ]
        }
    ]

    return (
        <ModalGeneric open onClose={() => { }}>
            <Container theme={theme}>

                <ModalHeader
                    title={"Integração com a Superlógica"}
                    subTitle={"Informe os dados necessários para a integração"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <Content>
                    {step === 0 && (
                        <>
                            <ContainerCrmLogo>
                                {superIcon[theme]}
                                Superlógica
                            </ContainerCrmLogo>
                            <Column style={{ gap: 10 }}>
                                <div>
                                    Bem-vindo ao nosso ambiente de integração.
                                </div>
                                <div>
                                    Integrando suas contas da MAX Locação e Superlógica,
                                    será possível exportar o cadastro de pessoas (inquilino, proprietário, corretores e etc)
                                    e de imóveis de uma ferramenta para outra, facilitando assim o processo interno de sua imobiliária
                                    evitando retrabalho na cópia dos dados.
                                </div>
                                <div>
                                    Está pronto para iniciar? 😊
                                </div>
                            </Column>
                            <Button
                                style={{ textTransform: "uppercase", fontWeight: 600 }}
                                onClick={() => setStep((atual) => atual + 1)}
                                background={secondary[theme]}
                                color="#FF"
                                children="Iniciar"
                            />
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <ContainerCrmLogo>
                                {superIcon[theme]}
                                Superlógica
                            </ContainerCrmLogo>
                            <Column>
                                <div style={{ fontWeight: 600, fontSize: 18 }}>Dados de Acesso</div>
                                <span>
                                    Para integração é necessário que você tenha em mãos alguns dados que serão gerados
                                    dentro da Superlógica, <Anchor theme={theme} target="_blank" href={TUTORIAL_URL}>clique aqui</Anchor> para
                                    assistir o passo a passo de como criar um
                                    aplicativo e gerar o App token e Access token.
                                </span>
                            </Column>
                            <Form onSubmit={handleStopPropagation}>
                                <GenericForm
                                    ref={form_ref}
                                    groups={groups}
                                    _form={_form}
                                    control={control}
                                    trigger={trigger}
                                    errors={errors}
                                    setValue={setValue}
                                    register={register}
                                />
                                <Line style={{ justifyContent: "center", marginTop: 20 }}>
                                    <div>
                                        <Button
                                            type="submit"
                                            style={{ paddingLeft: 16, paddingRight: 16, alignItems: "center", gap: 10, textTransform: "uppercase", fontWeight: 600 }}
                                            background={secondary[theme]}
                                            color="#FF"
                                            children={(
                                                <>
                                                    Validar dados
                                                    <FiArrowRightCircle size={16} color="#FFF" />
                                                </>
                                            )}
                                        />
                                    </div>
                                </Line>
                            </Form>
                        </>
                    )}
                </Content>

                <ModalLoading loading={loading} theme={theme} />

            </Container>
        </ModalGeneric>
    )
}