import React from "react";
import { Line } from "../../style";
import { inactiveItens } from "../../theme";
import { themeTypes } from "../../interfaces";
import { getValue, valueIsEmpty } from "../../services/generalServices";

export interface IFieldProps {
    label: string
    keys: string[]
    magic_key?: string
    transform?: any
    can_show?: (value: any) => boolean
    mask?: (value: any) => any
    only?: (value: any) => boolean
    get_type_mask?: (value: any) => Function
    is_person?: boolean
    empty_value?: string
    is_component?: boolean
}

export interface IListFieldsProps {
    fields?: IFieldProps[]
    title: string
    getFields?: (value: any) => IFieldProps[]
}

interface IFieldListProps {
    listFields: IListFieldsProps[]
    data: any
    theme: themeTypes
    fontSize?: number
}

const Field = (props: any) => {
    const { label, empty_value, value, theme, fontSize, isComponent } = props

    if (isComponent) return (
        <Line style={{ flex: 1, justifyContent: "space-between", gap: 10 }}>
            <div className="no-break-label" style={{ fontSize: fontSize, color: inactiveItens[theme as themeTypes] }}>
                {label}
            </div>
            {value}
        </Line>
    )
    if (typeof value !== 'object') return (
        <Line style={{ flex: 1, justifyContent: "space-between", gap: 10 }}>
            <div className="no-break-label" style={{ fontSize: fontSize, color: inactiveItens[theme as themeTypes] }}>
                {label}
            </div>
            <div className="no-break" style={{ fontSize: fontSize, textTransform: "uppercase", textAlign: "end", color: theme === "dark" ? "#FFF" : "#000" }}>
                {valueIsEmpty(value) ? (empty_value ?? "-") : value}
            </div>
        </Line>
    )
    return (
        <Line style={{ flex: 1, justifyContent: "space-between", gap: 10 }}>
            <div className="no-break-label" style={{ fontSize: fontSize, color: inactiveItens[theme as themeTypes] }}>
                {label}
            </div>
            <div style={{ overflow: "hidden" }}>
                {value.map((item: any, index: number) => (
                    <div key={"field-" + index} className="no-break" style={{ fontSize: fontSize, textTransform: "uppercase", textAlign: "end", color: theme === "dark" ? "#FFF" : "#000", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                        {valueIsEmpty(value) ? (empty_value ?? "-") : item.name}
                    </div>
                ))}
            </div>
        </Line>
    )
}

export const FieldList: React.FC<IFieldListProps> = (props) => {
    const { listFields, data, theme, fontSize = 15 } = props

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {listFields.map((item: any) => (
                <div key={item.title} style={{ display: "flex", flexDirection: "column" }}>
                    <div className="no-break-label" style={{ fontWeight: 600, textTransform: "uppercase", fontSize: 1.06666 * fontSize }}>
                        {item.title}
                    </div>
                    <div className="no-break" style={{ textTransform: "uppercase", gap: 5, display: "flex", flexDirection: "column" }}>
                        {data &&
                            <>
                                {item?.fields && item?.fields.map((field: any) => {

                                    if (!field.can_show || field.can_show(data)) return (
                                        <Field
                                            key={field.label}
                                            label={field.label}
                                            value={getValue(data, { ...field })}
                                            theme={theme}
                                            empty_value={field.empty_value}
                                            fontSize={fontSize}
                                            isComponent={field.is_component}
                                        />
                                    )
                                })}
                                {item?.getFields && item?.getFields(data)?.map((field: any) => {
                                    if (!field.can_show || field.can_show(data)) return (
                                        <Field
                                            key={field.label}
                                            label={field.label}
                                            value={getValue(data, { ...field })}
                                            theme={theme}
                                            empty_value={field.empty_value}
                                            fontSize={fontSize}
                                            isComponent={field.is_component}
                                        />
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}