import React from "react"

export const DocFinish = ({ size, color, ...props }: any) => (
  <svg
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 18H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v5a1 1 0 0 0 2 0V6.94a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.29.29 0 0 0-.1 0A1.1 1.1 0 0 0 9.06 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6a1 1 0 0 0 0-2Zm1-14.59L12.59 6H11a1 1 0 0 1-1-1V3.41ZM5 12h6a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2Zm4 2H5a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2ZM5 8h1a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2Zm13.71 6.29a1.002 1.002 0 0 0-1.42 0L14 17.59l-1.29-1.3a1.004 1.004 0 0 0-1.42 1.42l2 2a1.002 1.002 0 0 0 1.42 0l4-4a1.002 1.002 0 0 0 0-1.42Z"
      fill={color}
    />
  </svg>
)
