import { ResultBiroProps, franchiseProps, personsAttributes, propertiesAttributes, proposalsAttributes, queriesAttributes } from "../interfaces";
import { correctDate } from "../services/generalServices";
import { maskFunctions } from "../services/maskServices";

export const getPersonLabel = (props: { person: personsAttributes, haveCpfOrCnpj?: boolean }) => {
    const { person, haveCpfOrCnpj = false } = props;
    const name = person?.registration_data?.name;
    const cpf = person?.registration_data?.cpf;
    const cnpj = person?.registration_data?.cnpj;
    const cpfOrCnpj = maskFunctions.cpfOrCnpj.mask(cpf ?? cnpj);

    return `${name}`
}

export const getConsultLabel = (props: { consult: queriesAttributes }) => {
    const { consult } = props;

    let result_biro = consult?.result_biro
    if (typeof result_biro === "string") result_biro = JSON.parse(result_biro) as ResultBiroProps

    const name = result_biro?.BestInfo?.PersonName?.Name?.Full ?? (result_biro?.BestInfo?.CompanyName ?? null)
    const cpf_or_cnpj = result_biro?.BestInfo?.CPF ?? (result_biro?.CNPJ ?? null)
    const createdAt = correctDate(consult.createdAt)

    const label = `${maskFunctions.cpfOrCnpj.mask(cpf_or_cnpj)} - ${name} - (${createdAt})`

    return label
}

export const getFranchiseLabel = (props: { franchise: franchiseProps }) => {
    const { franchise } = props;

    return franchise?.registration_data?.name
}

export const getPropertyLabel = (props: { property: propertiesAttributes }) => {
    const { property } = props;

    return `#${property?.friendly_id} - ${property?.property_type?.name} na ${property?.address?.public_place},  ${property?.address?.number}, ${property?.address?.district}, ${property?.address?.city?.name} - ${property?.address?.state?.initials}`
}

export const getProposalLabel = (props: { proposal: proposalsAttributes }) => {
    const { proposal } = props;

    return `Proposta - #${proposal.friendly_id}`
}