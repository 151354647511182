import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { FormModalChangeAnalyst } from "./form";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { proposalsAttributes } from "../../../interfaces";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";

interface IModalChangeAnalystProps {
    onCancel: Function
    onSave: Function
    defaultId: string
    openModal: boolean
}

export const ModalChangeAnalyst: React.FC<IModalChangeAnalystProps> = (props) => {
    const { onCancel, onSave, openModal, defaultId } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [proposal, setProposal] = useState<proposalsAttributes | null>(null)

    const searchProposal = useCallback(async () => {

        setLoading(true)

        try {

            const { data } = await api.get(`/proposals/${defaultId}`)

            setProposal(data)

        } catch (err) {

            notify("Erro ao buscar Proposta", "error")

            onCancel()

        }

        setLoading(false)

    }, [api, defaultId])

    useEffect(() => { searchProposal() }, [])

    return (
        <ModalGeneric open={openModal} onClose={() => { }}>
            <Container theme={theme}>
                <ModalHeader
                    title={"Alterar titularidade"}
                    subTitle={"Informe o analista e o corretor responsável pela proposta"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading theme={theme} loading={loading} type="wave">
                    <FormModalChangeAnalyst
                        defaultProposalId={defaultId}
                        onCancel={() => onCancel()}
                        onSave={(data: any) => onSave(data)}
                        defaultData={proposal}
                    />
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    )
}