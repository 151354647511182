import Button from "../../../components/Buttons/Button";
import { Form } from "../style";
import { useForm } from "react-hook-form";
import { secondary } from "../../../theme";
import { useApi } from "../../../hooks/api";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../../../hooks/global";
import { FormEvent, useCallback, useRef, useState } from "react";
import { IGroupProps, personsAttributes } from "../../../interfaces";
import { ModalLoading } from "../../../components/Loadings/ModalLoading";
import {
  GenericForm,
  IGenericFormRefProps,
} from "../../../components/GenericForm";
import {
  validateCEP,
  validateCPF,
  validateCPFOrCNPJ,
  validateDate,
  validateEmail,
  validatePhone,
} from "../../../utils/validatesFields";
import {
  accountTypesOptions,
  banksOptions,
  maritalStatusOptions,
  paymentTypesOptions,
  personsTypesOptions,
} from "../../../utils/options";

interface IRegistrationDataFormProps {
  defaultData: personsAttributes;
}

export const RegistrationDataForm: React.FC<IRegistrationDataFormProps> = (
  props
) => {
  const { defaultData } = props;

  const navigate = useNavigate();
  const { theme, notify } = useGlobal();
  const { user, api, updateUser, consultsServices } = useApi();

  const [loadingCEP, setLoadingCEP] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const form_ref = useRef<IGenericFormRefProps>(null);

  const _defaultData = {
    type: { label: "Pessoa Física", value: "PF" },
    broker_id:
      user.type === "broker"
        ? {
            label: user.person?.registration_data?.name,
            value: user.person?.id,
            this: user.person,
          }
        : null,
  };

  const defaultValues = defaultData?.id ? defaultData : (_defaultData as any);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({ defaultValues, mode: "all" });

  const _form = watch();

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      const form = form_ref.current?.getForm();

      form.broker_id = form.registration_data.broker_id;
      delete form.registration_data.broker_id;

      form.payment.type = form.payment.payment_type;
      form.spouse_id = form.registration_data.spouse_id;
      form.representatives = form.registration_data.representatives;

      delete form.payment.payment_type;
      delete form.registration_data.spouse_id;
      delete form.registration_data.representatives;

      await api.put(`/customers/${defaultData?.id}`, form);

      await updateUser();

      notify("Dados cadastrais atualizados com sucesso.", "success");

      navigate("/");
    } catch (err: any) {
      const error = err.response ? err.response.data : "SERVER ERROR";

      if (error.path)
        setError(error.path, { type: "manual", message: error.message });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingCreate(false);
  }, [form_ref, _form, defaultData, api]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api]
  );

  const changeCEP = useCallback(async (search) => {
    search = search.replace(/\D/g, "");
    if (search.length === 8) {
      setLoadingCEP(true);

      const { address, city, state } = await consultsServices.address({
        search,
      });

      if (address) {
        setValue("public_place", address.logradouro);
        setValue("district", address.bairro);
        setValue("complement", address.complemento);
      }

      if (city)
        setValue("city_id", { label: city.name, value: city.id, this: city });

      if (state)
        setValue("state_id", {
          label: state.name,
          value: state.id,
          initials: state.initials,
          this: state,
        });

      setLoadingCEP(false);

      trigger();
    }
  }, []);

  const groups: IGroupProps[] = [
    {
      name: "registration_data",
      label: "Dados de Registro",
      fields: [
        [
          {
            name: "type",
            label: "Tipo",
            type: "select-fixed",
            required: true,
            options: personsTypesOptions,
            getIsDisabled: (data: any) => true,
          },
        ],
        [
          {
            name: "name",
            label: "Nome completo",
            type: "input",
            required: true,
          },
        ],
        [
          {
            name: "email",
            label: "Email",
            type: "input",
            validate: validateEmail,
          },
        ],
        [
          {
            name: "phone",
            label: "Telefone",
            type: "input",
            mask: "phone",
            validate: validatePhone,
          },
          {
            name: "birth",
            label: "Data de Nascimento",
            type: "input",
            mask: "date",
            validate: validateDate,
          },
          {
            name: "creci",
            label: "Creci",
            type: "input",
          },
          {
            name: "cpf",
            label: "CPF",
            type: "input",
            mask: "cpf",
            validate: validateCPF,
            required: true,
            getIsDisabled: (data: any) => true,
          },
          {
            name: "rg",
            label: "RG",
            type: "input",
          },
        ],
        [
          {
            name: "issuing_body",
            label: "Orgão Emissor",
            type: "input",
          },
          {
            name: "nationality",
            label: "Nacionalidade",
            type: "input",
          },
          {
            name: "profession",
            label: "Profissão",
            type: "input",
          },
          {
            name: "marital_status",
            label: "Estado Civil",
            type: "select-fixed",
            options: maritalStatusOptions,
            isClearable: true,
          },
        ],
        [
          {
            name: "spouse_id",
            label: "Cônjuge",
            type: "select-person",
            isClearable: true,
            canSee: (data: any) =>
              ["married", "stable_union"].includes(
                data?.marital_status?.value ?? ""
              ) && data?.type?.value === "PF",
            get_enabled_change_form_type: (data: any) => false,
            isPersonInPerson: true,
            personType: "spouse",
            getRemoveIds: (data: any) => {
              const ids = [];
              ids.push(data?.spouse_id?.value);
              ids.push(defaultData?.id);
              return ids;
            },
          },
        ],
        [
          {
            name: "representatives",
            label: "Representantes",
            type: "select-multi-persons",
            isClearable: true,
            searchOptions: consultsServices.person_id,
            get_enabled_change_form_type: (data: any) => false,
            isPersonInPerson: true,
            personType: "representative",
            getRemoveIds: (data: any) => {
              const ids =
                data?.representatives?.map((item: any) => item?.value) ?? [];
              ids.push(defaultData?.id);
              return ids;
            },
          },
        ],
        [
          {
            name: "broker_id",
            label: "Corretor",
            type: "select-single-cards-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.person_id,
            moreQueries: { person_types: ["broker"] },
          },
        ],
      ],
    },
    {
      name: "address",
      label: "Dados de Endereço",
      fields: [
        [
          {
            name: "cep",
            label: "CEP",
            type: "input",
            mask: "cep",
            validate: validateCEP,
            executeOnChange: changeCEP,
          },
          {
            name: "state_id",
            label: "Estado",
            type: "select-single-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.state_id,
            executeOnChange: async () => setValue("city_id", null),
          },
          {
            name: "city_id",
            label: "Cidade",
            type: "select-single-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.city_id,
            additionalsQueries: (data: any) => ({
              state_id: data?.state_id?.value,
            }),
            getIsDisabled: (data: any) => !data?.state_id,
          },
        ],
        [
          {
            name: "district",
            label: "Bairro",
            type: "input",
          },
          {
            name: "public_place",
            label: "Logradouro",
            type: "input",
          },
          {
            name: "complement",
            label: "Complemento",
            type: "input",
          },
          {
            name: "number",
            label: "Número",
            type: "input",
            mask: "onlyNumber",
          },
        ],
      ],
    },
    {
      name: "payment",
      label: "Dados de Pagamento",
      fields: [
        [
          {
            name: "payment_type",
            label: "Forma de recebimento padrão",
            type: "select-fixed",
            options: paymentTypesOptions,
            isClearable: true,
          },
        ],
        [
          {
            name: "bank_code",
            label: "Banco",
            type: "select-fixed",
            options: banksOptions,
            isClearable: true,
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "agency",
            label: "Agência",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "account",
            label: "Conta",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "account_type",
            label: "Tipo de conta",
            type: "select-fixed",
            options: accountTypesOptions,
            isClearable: true,
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "operation",
            label: "Operação",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
            tooltip:
              "É um identificador bancário, usado para informar o tipo de transação. Geralmente possui 3 dígitos. Informe apenas se necessário.",
          },
        ],
        [
          {
            name: "in_name",
            label: "Em nome de",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "RC", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "cpf_cnpj",
            label: "CPF/CNPJ",
            type: "input",
            mask: "cpfOrCnpj",
            validate: validateCPFOrCNPJ,
            canSee: (data: any) =>
              ["DC", "DD", "RC", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
        ],
      ],
    },
  ];

  return (
    <>
      <Form onSubmit={handleStopPropagation}>
        <GenericForm
          ref={form_ref}
          groups={groups}
          _form={_form}
          control={control}
          trigger={trigger}
          errors={errors}
          disabled={loadingCEP}
          setValue={setValue}
          register={register}
        />

        <Button
          type="submit"
          background={secondary[theme]}
          color={"#FFF"}
          style={{ padding: 8, fontSize: 14 }}
          children="Salvar"
        />
      </Form>

      <ModalLoading theme={theme} loading={loadingCreate} />
    </>
  );
};
