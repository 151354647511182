import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import Input from "../Inputs/Input";
import { FiInfo } from "react-icons/fi";
import { inactiveItens } from "../../theme";
import { TextArea } from "../Inputs/TextArea";
import { IGroupProps } from "../../interfaces";
import { useGlobal } from "../../hooks/global";
import { Column, Label, Line } from "../../style";
import { InputDocument } from "../Inputs/InputFile";
import { SwitchInput } from "../Buttons/SwitchInput";
import { SelectPerson } from "../Inputs/SelectPerson";
import { IconButton, Tooltip } from "@material-ui/core";
import { SelectProperty } from "../Inputs/SelectProperty";
import { maskFunctions } from "../../services/maskServices";
import { valueIsEmpty } from "../../services/generalServices";
import { SelectMultiPersons } from "../Inputs/SelectMultiPersons";
import { SelectFixedCards } from "../Inputs/BaseSelects/SelectFixedCards";
import { FixRequiredSelect } from "../Inputs/BaseSelects/FixRequiredSelect";
import { SelectCreateMulti } from "../Inputs/BaseSelects/SelectCreateMulti";
import { SelectCreateSingle } from "../Inputs/BaseSelects/SelectCreateSingle";
import { ContainerForm, ContainerGroup, FieldsLine, GroupTitle } from "./style";
import { SelectNoCreateSingle } from "../Inputs/BaseSelects/SelectNoCreateSingle";
import { SelectNoCreateSingleMulti } from "../Inputs/BaseSelects/SelectNoCreateSingleMulti";
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";

export interface IGenericFormRefProps {
    getForm: () => any
}

interface IGenericFormProps {
    groups: IGroupProps[]
    register: UseFormRegister<any>
    setValue: UseFormSetValue<FieldValues>
    _form: any
    errors: FieldErrors<FieldValues>
    trigger: any
    control: Control<FieldValues, any>
    disabled?: boolean
    noTrigger?: boolean
    containerStyle?: React.CSSProperties
}

const GenericFormComponent: React.ForwardRefRenderFunction<IGenericFormRefProps, IGenericFormProps> = (props, ref) => {
    const { register, setValue, errors, control, _form, groups, disabled, trigger, noTrigger = false, containerStyle = {} } = props

    const { theme } = useGlobal()

    const getForm = useCallback(() => {
        let correct_form: any = {}

        groups.forEach((group) => {
            if (group.name) correct_form[group.name] = {}
            group.fields.forEach((fields) => {
                fields.forEach((field) => {
                    const _form_obj = group.name ? correct_form[group.name] : correct_form
                    if (group.uniqueId) _form_obj.uniqueId = group.uniqueId

                    if (field.canSee ? field.canSee(_form) : true) {
                        _form_obj[field.name] = !valueIsEmpty(_form[field.name]) ? _form[field.name] : null
                        if (field.type === "file") return

                        const mask = field?.mask
                        const type = _form_obj[field.name]?.type

                        const _field = _form_obj[field.name]
                        let value = _form_obj[field.name]?.value

                        if (Array.isArray(_field)) value = _field.map(({ value }: any) => ({ id: value }))
                        else {
                            value = !valueIsEmpty(value) ? value : (typeof _field === "object" ? null : _field)
                            if (mask) value = maskFunctions[mask].unMask(value)
                        }

                        _form_obj[field.name] = value

                        if (field.type === "input-porcen-or-money") {
                            if (type === "PORCEN") value = maskFunctions.porcen.unMask(value)
                            else value = maskFunctions.currency.unMask(value)

                            delete _form_obj[field.name]
                            _form_obj[field.name + "_value"] = value ?? 0
                            _form_obj[field.name + "_type"] = type ?? "PORCEN"
                        }

                        if (field.type === "input-day-or-month") {
                            value = maskFunctions.int.unMask(value)

                            delete _form_obj[field.name]
                            _form_obj[field.name + "_value"] = value ?? 0
                            _form_obj[field.name + "_type"] = type ?? "day"
                        }
                    }
                    else _form_obj[field.name] = null
                })
            })
        })

        return correct_form
    }, [_form])

    const getSelectFields = useCallback(() => {
        let form_obj: any = {}
        groups.forEach((group) => {
            group.fields.forEach((fields) => {
                fields.forEach((field) => {
                    if (field.canSee ? field.canSee(_form) : true) form_obj[field.name] = _form[field.name] ?? null
                })
            })
        })

        let form: any[] = []

        Object.keys(form_obj).forEach((key) => {
            if (Array.isArray(form_obj[key])) form_obj[key].forEach((field: any) => form.push(field))
            else form.push(form_obj[key])
        })

        form = form.filter((field: any) => !!field?.label || !!field?.type)

        return form.map((field: any) => ({ label: field?.label, type: field?.type }))
    }, [_form])

    const defaultFormatOptionLabel = useCallback((value: any, options = []) => {
        if (!!options?.find && !!options?.find((item: any) => item.value === value.value)) {
            return (
                <div style={{ display: "flex", flexDirection: "row", marginRight: 10, color: "#FFF", alignItems: "center", gap: 5 }}>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: 600 }}>
                        {value.label}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{ display: "flex", flexDirection: "row", marginRight: 10, alignItems: "center", gap: 5 }}>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {value.label}
                    </div>
                </div>
            )
        }
    }, [])

    useImperativeHandle(ref, () => ({ getForm }))

    useEffect(() => { if (!noTrigger) trigger() }, [])

    return (
        <ContainerForm style={{ ...containerStyle }}>
            {groups.map((group) => {
                const { canSee } = group
                if (canSee ? canSee(_form) : true) {
                    return (
                        <ContainerGroup key={group?.name ?? group?.uniqueId}>

                            {group.label && (
                                <GroupTitle>
                                    {group.label}
                                </GroupTitle>
                            )}

                            {group.fields.map((fields, index) => {
                                if (fields.filter(field => field.canSee ? field.canSee(_form) : true).length === 0) return
                                return (
                                    <FieldsLine key={group.name + index}>
                                        {fields.map((_field) => {
                                            let { options, getOptions, required, mask, numberOfRows = 5, formatOptionLabel = defaultFormatOptionLabel, isPassword } = _field
                                            const { getFileSizeMax = () => undefined, getFileTypes = () => [], icon } = _field
                                            const { onClick, executeOnChange = async () => { }, getRemoveIds = () => [] } = _field
                                            const { searchOptions, validate, isClearable, moreQueries, personType } = _field
                                            const { getIsDisabled = () => false, additionalsQueries = () => { }, isPersonInPerson } = _field
                                            const { name, label, type, noUpperCase, canSee, onRemove, get_enabled_change_form_type, backgroundVisible } = _field

                                            options = getOptions ? getOptions(_form) : options

                                            const fileSizeMax = getFileSizeMax(_form)

                                            const fileTypes = getFileTypes(_form)

                                            const _disabled = disabled ? true : getIsDisabled(_form)

                                            const removeIds = getRemoveIds(_form).filter((id: any) => !!id)

                                            required = required ? "Campo Obrigatório" : undefined

                                            const _additionalsQueries = additionalsQueries ? additionalsQueries(_form) : {}

                                            const _searchOptions = async (search: string) => {
                                                return await searchOptions({ search, ...moreQueries, ..._additionalsQueries, removeIds })
                                            }

                                            const _executeOnChange = async (value: any) => {
                                                await executeOnChange(value)
                                                trigger()
                                            }

                                            const handleChange = (props: any) => {
                                                const { value, field } = props
                                                if (!value && onRemove) onRemove()
                                                field?.onChange(value)
                                                _executeOnChange(value)
                                            }

                                            if (canSee ? canSee(_form) : true) return (
                                                <Column key={name + JSON.stringify(getSelectFields()) + JSON.stringify(removeIds)} style={{ flex: 1, minWidth: 250 }}>
                                                    <Column>
                                                        <Line style={{ alignItems: "center" }}>
                                                            <Label>
                                                                {label}
                                                                {required && "*"}
                                                                {type === "input-porcen-or-money" && (
                                                                    _form?.[name]?.type === "VALUE" ? "(R$)" : "(%)"
                                                                )}
                                                                {type === "input-day-or-month" && (
                                                                    _form?.[name]?.type === "day" ? "(dias)" : "(meses)"
                                                                )}
                                                            </Label>
                                                            {_field.tooltip && (
                                                                <Tooltip placement="top" style={{ padding: 0, marginLeft: 5, marginTop: -4 }} title={_field.tooltip}>
                                                                    <IconButton aria-label={_field.tooltip}>
                                                                        <FiInfo color={inactiveItens[theme]} size={15} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </Line>
                                                        {type === "input" && (
                                                            <Input
                                                                noUpperCase={noUpperCase}
                                                                name={name}
                                                                mask={mask}
                                                                disabled={_disabled}
                                                                options={{ ...register(name, { required, validate }) }}
                                                                error={errors?.[name]?.message}
                                                                onChange={_executeOnChange}
                                                                isPassword={isPassword}
                                                            />
                                                        )}
                                                        {type === "textarea" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <TextArea
                                                                        {...field}
                                                                        rows={numberOfRows}
                                                                        error={invalid ? error?.message : undefined}
                                                                        disabled={_disabled}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "input-porcen-or-money" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => {
                                                                    const { name, onBlur, value } = field

                                                                    const _value = value?.value
                                                                    const _type = value?.type ?? "PORCEN"

                                                                    const handleChange = (newValue: any) => {
                                                                        setValue(name, { type: _type, value: newValue })
                                                                        _executeOnChange({ type: _type, value: newValue })
                                                                    }

                                                                    const handleClick = (props: any) => {
                                                                        setValue(name, props)
                                                                        _executeOnChange(props)
                                                                    }

                                                                    let unMaskedValue = _value?.replace(/\D/g, "")
                                                                    unMaskedValue = !valueIsEmpty(unMaskedValue) ? (Number.parseInt(unMaskedValue) / 100) : undefined

                                                                    const mask = _type === "VALUE" ? maskFunctions.currency.mask : maskFunctions.porcen.mask
                                                                    const maskedValue = !valueIsEmpty(unMaskedValue) ? mask(unMaskedValue, true) : undefined

                                                                    const switchOptions: any = [
                                                                        { value: "VALUE", label: "valor fixo", icon: "$" },
                                                                        { value: "PORCEN", label: "porcentagem", icon: "%" }
                                                                    ]

                                                                    return (
                                                                        <>
                                                                            <Line style={{ gap: 10, position: "relative" }}>
                                                                                <Input
                                                                                    name={name}
                                                                                    mask={_type === "VALUE" ? "currency" : "porcen"}
                                                                                    disabled={_disabled}
                                                                                    error={errors?.[name]?.message}
                                                                                    onChange={handleChange}
                                                                                    onBlur={onBlur}
                                                                                    defaultValue={maskedValue}
                                                                                    style={{ maxWidth: "calc(100% - 48px)" }}
                                                                                />
                                                                                <div style={{ position: "absolute", right: 0 }}>
                                                                                    <SwitchInput
                                                                                        inputValue={maskedValue}
                                                                                        value={_type}
                                                                                        options={switchOptions}
                                                                                        onChange={handleClick}
                                                                                        disabled={_disabled}
                                                                                    />
                                                                                </div>
                                                                            </Line>
                                                                        </>
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                        {type === "input-day-or-month" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => {
                                                                    const { name, onBlur, value } = field

                                                                    const _value = value?.value
                                                                    const _type = value?.type ?? "month"

                                                                    const handleChange = (newValue: any) => {
                                                                        setValue(name, { type: _type, value: newValue })
                                                                        _executeOnChange({ type: _type, value: newValue })
                                                                    }

                                                                    const handleClick = (props: any) => {
                                                                        setValue(name, props)
                                                                        _executeOnChange(props)
                                                                    }

                                                                    const switchOptions: any = [
                                                                        { value: "day", label: "dias", icon: "D" },
                                                                        { value: "month", label: "meses", icon: "M" }
                                                                    ]

                                                                    return (
                                                                        <Line style={{ gap: 10, position: "relative" }}>
                                                                            <Input
                                                                                name={name}
                                                                                mask="int"
                                                                                disabled={_disabled}
                                                                                error={errors?.[name]?.message}
                                                                                onChange={handleChange}
                                                                                onBlur={onBlur}
                                                                                defaultValue={_value}
                                                                                style={{ maxWidth: "calc(100% - 48px)" }}
                                                                            />
                                                                            <div style={{ position: "absolute", right: 0 }}>
                                                                                <SwitchInput
                                                                                    inputValue={_value}
                                                                                    value={_type}
                                                                                    options={switchOptions}
                                                                                    onChange={handleClick}
                                                                                    disabled={_disabled}
                                                                                />
                                                                            </div>
                                                                        </Line>
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                        {type === "select-fixed" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <FixRequiredSelect
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        options={options}
                                                                        error={invalid ? error?.message : undefined}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        icon={icon}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-fixed-multi" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <FixRequiredSelect
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        options={options}
                                                                        error={invalid ? error?.message : undefined}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        icon={icon}
                                                                        formatOptionLabel={(value: any) => formatOptionLabel(value, field.value)}
                                                                        isMulti
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-single-creatable" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectCreateSingle
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        searchOptions={_searchOptions}
                                                                        error={invalid ? error?.message : undefined}
                                                                        onCreateOption={() => window.alert("create")}
                                                                        onClick={onClick}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        icon={icon}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-multi-creatable" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectCreateMulti
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        searchOptions={_searchOptions}
                                                                        error={invalid ? error?.message : undefined}
                                                                        onCreateOption={() => window.alert("create")}
                                                                        onClick={onClick}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        icon={icon}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-single-no-creatable" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectNoCreateSingle
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        searchOptions={_searchOptions}
                                                                        error={invalid ? error?.message : undefined}
                                                                        onClick={onClick}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        icon={icon}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-single-cards-no-creatable" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectNoCreateSingleMulti
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        searchOptions={_searchOptions}
                                                                        error={invalid ? error?.message : undefined}
                                                                        onClick={onClick}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        icon={icon}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-person" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectPerson
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        error={invalid ? error?.message : undefined}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        options={options}
                                                                        removeIds={removeIds}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                        personType={personType}
                                                                        moreQueries={moreQueries}
                                                                        isPersonInPerson={isPersonInPerson}
                                                                        backgroundVisible={backgroundVisible}
                                                                        get_enabled_change_form_type={get_enabled_change_form_type}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-multi-persons" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectMultiPersons
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        error={invalid ? error?.message : undefined}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        removeIds={removeIds}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                        personType={personType}
                                                                        moreQueries={moreQueries}
                                                                        isPersonInPerson={isPersonInPerson}
                                                                        backgroundVisible={backgroundVisible}
                                                                        get_enabled_change_form_type={get_enabled_change_form_type}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "file" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <InputDocument
                                                                        error={invalid ? error?.message : undefined}
                                                                        fileSizeMax={fileSizeMax}
                                                                        fileTypes={fileTypes}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                        defaultDocument={field.value}
                                                                        ref={field?.ref as any}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-property" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectProperty
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        error={invalid ? error?.message : undefined}
                                                                        defaultValue={field.value}
                                                                        {...field}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                        moreQueries={moreQueries}
                                                                        backgroundVisible={backgroundVisible}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                        {type === "select-fixed-cards" && (
                                                            <Controller
                                                                name={name}
                                                                control={control}
                                                                rules={{ required, validate }}
                                                                render={({ field, fieldState: { invalid, error } }) => (
                                                                    <SelectFixedCards
                                                                        placeholder=""
                                                                        isClearable={!!isClearable}
                                                                        isDisabled={_disabled}
                                                                        error={invalid ? error?.message : undefined}
                                                                        onClick={onClick}
                                                                        defaultValue={field.value}
                                                                        options={options}
                                                                        {...field}
                                                                        icon={icon}
                                                                        onChange={(value: any) => handleChange({ value, field })}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    </Column>
                                                </Column>
                                            )
                                        })}
                                    </FieldsLine>
                                )
                            })}
                        </ContainerGroup>
                    )
                }
            })}
        </ContainerForm>
    )
}

export const GenericForm = forwardRef(GenericFormComponent)