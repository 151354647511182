import Button from "../../../components/Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { Container, Form } from "./style";
import { useForm } from "react-hook-form";
import { useApi } from "../../../hooks/api";
import { useGlobal } from "../../../hooks/global";
import { inactiveItens, secondary } from "../../../theme";
import { ModalHeader } from "../../../components/ModalHeader";
import { GenericForm } from "../../../components/GenericForm";
import { ModalLoading } from "../../../components/Loadings/ModalLoading";
import { ContainerFormModal, ModalContainerButtons } from "../../../style";
import { IGroupProps, properties_typesAttributes } from "../../../interfaces";

interface IFormPropertyTypeModalProps {
    onCancel: Function
    onSave: Function
    defaultData?: properties_typesAttributes | null
}

export const FormPropertyType: React.FC<IFormPropertyTypeModalProps> = (props) => {
    const { onCancel, onSave, defaultData } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const _defaultData = {}

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: defaultData?.id ? defaultData : _defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const [loadingCreate, setLoadingCreate] = useState(false)

    const onSubmit = useCallback(async (data: any) => {

        setLoadingCreate(true)

        try {
            const name = data.name

            if (defaultData?.id) {
                await api.put(`/properties_types/${defaultData.id}`, { name })
                notify("Tipo de Imóvel atualizado com sucesso.", "success")
            }
            else {
                await api.post("/properties_types", { name })
                notify("Tipo de Imóvel cadastrado com sucesso.", "success")
            }

            onSave()

        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoadingCreate(false)

    }, [defaultData, api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, api])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "name",
                        label: "Nome",
                        type: "input",
                        required: true
                    }
                ]
            ]
        }
    ]

    return (
        <Container theme={theme}>

            <ModalHeader
                title={defaultData ? "Editar Tipo de Imóvel" : "Novo Tipo de Imóvel"}
                subTitle={defaultData ? "Atualize os dados do Tipo de Imóvel" : "Informe os dados necessários para criar um novo Tipo de Imóvel"}
                onCancel={() => onCancel()}
                theme={theme}
            />

            <ContainerFormModal>
                <Form onSubmit={handleStopPropagation} theme={theme}>
                    <GenericForm
                        groups={groups}
                        _form={_form}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        setValue={setValue}
                        register={register}
                    />
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Form>
            </ContainerFormModal>

            <ModalContainerButtons theme={theme}>
                <Button
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Cancelar"
                />
                <Button
                    onClick={() => buttonSubmitRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Salvar"
                />
            </ModalContainerButtons>


            {loadingCreate && (
                <ModalLoading
                    loading={loadingCreate}
                    theme={theme}
                />
            )}

        </Container >

    )
}
