import Button from "../../Buttons/Button";
import React, { useState, useCallback, useEffect } from "react";
import { Receipt } from "./Receipt";
import { TiDelete } from "react-icons/ti";
import { useApi } from "../../../hooks/api";
import { ReceiptBolix } from "./ReceiptBolix";
import { io, Socket } from "socket.io-client";
import { Column, Line } from "../../../style";
import { inactiveItens } from "../../../theme";
import { ReceiptQRCode } from "./ReceiptQRCode";
import { ModalGeneric } from "../../ModalGeneric";
import { useGlobal } from "../../../hooks/global";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { transactionsAttributes } from "../../../interfaces";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { Container, ContainerBottom, ContainerButton, Content, ContentHeader, Title } from "./style";

interface IModalReceiptProps {
    title?: string
    onCancel: Function
    openModal: boolean
    transaction_id: string
    backgroundVisible?: boolean
}

const hoursToMs = (hours: number) => {
    return hours * 60 * 60 * 1000
}

export const ModalReceipt: React.FC<IModalReceiptProps> = (props) => {
    const { onCancel, openModal, transaction_id, backgroundVisible, title = "Comprar créditos" } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(true)
    const [socket, setSocket] = useState<Socket | null>(null)
    const [transaction, setTransaction] = useState<transactionsAttributes | null>(null)

    const search = useCallback(async (transaction_id) => {
        setLoading(true)
        try {
            const { data } = await api.get(`/transactions/${transaction_id}`)
            const createdAt = new Date(data.createdAt)
            let status = data.status
            const atualDate = new Date()
            const diff = Math.abs(atualDate.getTime() - createdAt.getTime())
            if (diff > hoursToMs(1) && data.method === "pix") status = status === "waiting" ? "expired" : status
            setTransaction({ ...data, status })
        } catch (err) {
            notify("Erro ao buscar transação", "error")
            onCancel()
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        const socketInstance = io(`${process.env.REACT_APP_API}`)

        setSocket((atual) => {
            atual?.removeAllListeners()
            atual?.disconnect()
            return socketInstance
        })

        if (socketInstance && transaction_id) {
            search(transaction_id).then(() => {
                socketInstance?.on(`${transaction_id}-transaction`, async () => {
                    await search(transaction_id)
                })
            })
        }
    }, [transaction_id])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>
                <ContentHeader theme={theme}>

                    <Line style={{ gap: 10 }}>
                        <ContainerButton onClick={() => onCancel()}>
                            <IoReturnUpBackOutline
                                color={theme === "dark" ? "#FFF" : inactiveItens.light}
                                size={20}
                            />
                        </ContainerButton>
                        <Column>
                            <Title theme={theme}>
                                {title}
                            </Title>
                            Abaixo segue as informações da sua compra
                        </Column>
                    </Line>

                    <ContainerButton onClick={() => onCancel()}>
                        <TiDelete
                            color={theme === "dark" ? "#FFF" : inactiveItens.light}
                            size={20}
                        />
                    </ContainerButton>

                </ContentHeader>

                <ContentOrLoading theme={theme} loading={loading} type="wave">

                    <Content>
                        {transaction?.status === "waiting" && transaction.method === "pix" &&
                            <ReceiptQRCode
                                theme={theme}
                                transaction={transaction}
                                refresh={() => search(transaction_id)}
                            />
                        }
                        {!["paid", "settled"].includes(transaction?.status as string) && transaction?.method === "banking_billet" &&
                            <ReceiptBolix
                                theme={theme}
                                transaction={transaction}
                                refresh={() => search(transaction_id)}
                            />
                        }
                        {!(transaction?.status === "waiting" && transaction.method === "pix") && !(!["paid", "settled"].includes(transaction?.status as string) && transaction?.method === "banking_billet") &&
                            <Receipt
                                theme={theme}
                                transaction={transaction}
                            />
                        }
                    </Content>

                    <ContainerBottom theme={theme}>
                        <Button
                            onClick={() => onCancel()}
                            background={inactiveItens[theme]}
                            color={"#FFF"}
                            style={{ padding: 8, maxWidth: 180, fontSize: 14 }}
                        >
                            Voltar
                        </Button>
                    </ContainerBottom>

                </ContentOrLoading>

            </Container>

        </ModalGeneric>
    )
}