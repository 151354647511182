import React from "react";
import { DashboardPage } from "../../components/DashboardPage";
import { ContainerNoSubscription, TextNoSubscription } from "./style";

export const NotPaid: React.FC = () => {

    return (
        <DashboardPage>
            <ContainerNoSubscription>
                <TextNoSubscription>
                    Imobiliária não possui uma assinatura que lhe conceda acesso ao módulo desejado
                </TextNoSubscription>
                <div>
                    Entre em contato com o responsável da imobiliária para adquirir uma nova assinatura
                </div>
            </ContainerNoSubscription>
        </DashboardPage>
    )
}