import React from "react";
import Lottie from "react-lottie";
import animationData from "./analysisBiro.json";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export const AnalysisBiro: React.FC = () => {
    return (
        <Lottie
            options={defaultOptions}
            width={200}
            isStopped={false}
            isPaused={false}
        />
    );
}