import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { DashboardCard } from "../DashboardCard";
import { inactiveItens } from "../../../../../theme";
import { themeTypes } from "../../../../../interfaces";
import { ChartLine } from "../../../../../components/Charts/Line";
import { valueIsEmpty } from "../../../../../services/generalServices";
import { ColumnChart, Label, LabelIndicator, LabelInfo, LineLabels } from "./style";

interface IDashboardCharLineProps {
    title: string;
    theme: themeTypes;
    minWidth: number;
    minHeight?: number;
    infos: any;
    labels: string[];
    total?: number;
    mask?: Function;
    isInverted?: boolean;
}

export const DashboardCharLine: React.FC<IDashboardCharLineProps> = (props) => {
    const { infos, labels, theme, mask, isInverted } = props;
    return (
        <DashboardCard {...props}>
            <ColumnChart theme={theme}>
                <ChartLine
                    infos={infos}
                    labels={labels}
                    color={inactiveItens[theme]}
                    mask={mask}
                    isInverted={isInverted}
                />
                <LineLabels>
                    {infos.map(({ name, color, total }: any) => (
                        <Label theme={theme}>
                            <LabelIndicator background={color} />
                            <LabelInfo>
                                {name}
                                {!valueIsEmpty(total) && <BsArrowRight />}
                                {!valueIsEmpty(total) && <div>{mask ? mask(total) : total}</div>}
                            </LabelInfo>
                        </Label>
                    ))}
                </LineLabels>
            </ColumnChart>
        </DashboardCard>
    )
}