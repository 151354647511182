import Button from "../../../Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { Column, ContainerButtons, Form } from "./style";
import { inactiveItens, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { personsTypesOptions } from "../../../../utils/options";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { IGroupProps, franchiseProps, IOptionsProps } from "../../../../interfaces";
import { validateCEP, validateCNPJ, validateEmail, validatePhone } from "../../../../utils/validatesFields";

interface IFormFranchiseModalProps {
    onCancel: Function
    onSave: Function
    defaultData?: franchiseProps | undefined
    defaultFranchise?: franchiseProps
}

const getDefaultResponsible = (props: any) => {
    const { defaultFranchise, user } = props
    if (defaultFranchise) return { label: defaultFranchise.responsible?.registration_data.name, value: defaultFranchise.responsible?.id } as IOptionsProps
    else if (user && ["responsible"].includes(user.type)) return { label: user?.person?.registration_data.name, value: user?.person_id } as IOptionsProps
    else return undefined
}

export const FormFranchise: React.FC<IFormFranchiseModalProps> = (props) => {
    const { onCancel, onSave, defaultData, defaultFranchise } = props

    const { theme, notify } = useGlobal()
    const { api, user, consultsServices } = useApi()

    const _defaultData = {
        type: { label: "Pessoa Jurídica", value: "PJ" },
        responsible_id: getDefaultResponsible({ user, defaultFranchise })
    }

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: defaultData?.id ? defaultData : _defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const [loadingCEP, setLoadingCEP] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)

    const onSubmit = useCallback(async () => {

        setLoadingCreate(true)

        try {

            const form = form_ref.current?.getForm()

            if (defaultData?.id) {
                await api.put(`/franchises/${defaultData.id}`, form)
                notify("Imobiliária atualizada com sucesso.", "success")
            }
            else {
                await api.post("/franchises", form)
                notify("Imobiliária criada com sucesso.", "success")
            }

            onSave()

        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoadingCreate(false)

    }, [api, form_ref])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const changeCEP = useCallback(async (search) => {
        search = search.replace(/\D/g, "")
        if (search.length === 8) {
            setLoadingCEP(true)

            const { address, city, state } = await consultsServices.address({ search })

            if (address) {
                setValue("public_place", address.logradouro)
                setValue("district", address.bairro)
                setValue("complement", address.complemento)
            }

            if (city) setValue("city_id", { label: city.name, value: city.id, this: city })

            if (state) setValue("state_id", { label: state.name, value: state.id, initials: state.initials, this: state })

            setLoadingCEP(false)

            trigger()
        }
    }, [])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "Responsável",
            fields: [
                [
                    {
                        name: "responsible_id",
                        label: "Responsável",
                        type: "select-single-cards-no-creatable",
                        required: true,
                        isClearable: true,
                        searchOptions: consultsServices.person_id,
                        moreQueries: { person_types: ["responsible"] },
                        getIsDisabled: (data: any) => user?.type === "responsible"
                    }
                ]
            ]
        },
        {
            name: "registration_data",
            label: "Dados de Registro",
            fields: [
                [
                    {
                        name: "type",
                        label: "Tipo",
                        type: "select-fixed",
                        required: true,
                        options: personsTypesOptions,
                        getIsDisabled: (data: any) => true
                    }
                ],
                [
                    {
                        name: "corporate_name",
                        label: "Razão Social",
                        type: "input",
                        required: true
                    },
                    {
                        name: "fantasy_name",
                        label: "Nome Fantasia",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "cnpj",
                        label: "CNPJ",
                        type: "input",
                        mask: "cnpj",
                        required: true,
                        validate: validateCNPJ,
                        getIsDisabled: (data: any) => !!defaultData?.id
                    },
                    {
                        name: "phone",
                        label: "Telefone",
                        type: "input",
                        mask: "phone",
                        validate: validatePhone
                    }
                ],
                [
                    {
                        name: "email",
                        label: "Email",
                        type: "input",
                        validate: validateEmail
                    },
                    {
                        name: "state_registration",
                        label: "Inscrição estadual",
                        type: "input"
                    },
                    {
                        name: "municipal_registration",
                        label: "Inscrição municipal",
                        type: "input"
                    }
                ]
            ]
        },
        {
            name: "address",
            label: "Dados de Endereço",
            fields: [
                [
                    {
                        name: "cep",
                        label: "CEP",
                        type: "input",
                        mask: "cep",
                        validate: validateCEP,
                        executeOnChange: changeCEP
                    },
                    {
                        name: "state_id",
                        label: "Estado",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        searchOptions: consultsServices.state_id,
                        executeOnChange: async () => setValue("city_id", null)
                    },
                    {
                        name: "city_id",
                        label: "Cidade",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        searchOptions: consultsServices.city_id,
                        additionalsQueries: (data: any) => ({ state_id: data?.state_id?.value }),
                        getIsDisabled: (data: any) => !data?.state_id
                    }
                ],
                [
                    {
                        name: "district",
                        label: "Bairro",
                        type: "input"
                    },
                    {
                        name: "public_place",
                        label: "Logradouro",
                        type: "input"
                    },
                    {
                        name: "complement",
                        label: "Complemento",
                        type: "input"
                    },
                    {
                        name: "number",
                        label: "Número",
                        type: "input",
                        mask: "onlyNumber"
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <Column style={{ overflow: "auto", flex: 1, gap: 20, padding: 20, paddingTop: 0 }}>
                <Form onSubmit={handleStopPropagation}>
                    <GenericForm
                        ref={form_ref}
                        groups={groups}
                        _form={_form}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        disabled={loadingCEP}
                        setValue={setValue}
                        register={register}
                    />
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Form>
            </Column>

            <ContainerButtons theme={theme}>
                <Button
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Voltar"
                />
                <Button
                    onClick={() => buttonSubmitRef?.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14, display: "flex" }}
                    children="Salvar"
                />
            </ContainerButtons>

            {loadingCreate && (
                <ModalLoading
                    loading={loadingCreate}
                    theme={theme}
                />
            )}
        </>
    )
}
