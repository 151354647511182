import CountUp from "react-countup";
import React, { useCallback } from "react"
import { themeTypes } from "../../interfaces";
import { Container, ProgressBackground, ProgressContent } from "./style";
interface ProgressStaticProps {
    max: number
    min: number
    atual: number
    theme: themeTypes
    onFinished?: () => void
}

export const ProgressStatic: React.FC<ProgressStaticProps> = (props) => {
    const { atual, max, min, theme, onFinished = () => { } } = props

    const handleFinished = useCallback((_atual, _max) => {
        if (_atual >= _max) setTimeout(() => onFinished(), 500)
    }, [])

    return (
        <Container>
            <ProgressContent
                width={((atual / (max - min)) * 100) > 100 ? 100 : ((atual / (max - min)) * 100)}
                theme={theme}
            />
            <ProgressBackground>
                <CountUp
                    start={(((atual - 1 < 0 ? 0 : atual - 1) / (max - min)) * 100) > 100 ? 100 : (((atual - 1 < 0 ? 0 : atual - 1) / (max - min)) * 100)}
                    end={((atual / (max - min)) * 100) > 100 ? 100 : ((atual / (max - min)) * 100)}
                    duration={0.5}
                    separator=" "
                    decimals={2}
                    decimal=","
                    prefix=""
                    suffix=" %"
                    onEnd={() => handleFinished(atual, max)}
                >
                    {({ countUpRef, start }) => (
                        <div>
                            <span ref={countUpRef} />
                        </div>
                    )}
                </CountUp>
            </ProgressBackground>
        </Container >
    )
}