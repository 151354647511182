import React from "react"

export const MaxCoinSmall = ({ size, ...props }: any) => (
  <svg
  width={33}
  height={33}
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <circle cx={16.5} cy={16.5} r={16.5} fill="#EFAA35" />
  <circle cx={16.583} cy={16.418} r={13.777} fill="#FCD634" />
  <path
    d="M16.5 6.765v11.058l-4.637 4.681a2.014 2.014 0 0 1-2.206.439 2.024 2.024 0 0 1-.905-.755 2.052 2.052 0 0 1-.337-1.135v-5.27a2.046 2.046 0 0 1 .731-1.576L16.5 6.765ZM24.742 15.823v5.237a1.996 1.996 0 0 1-.597 1.44 2.066 2.066 0 0 1-1.462.6h-4.122a2.087 2.087 0 0 1-1.458-.598 2.015 2.015 0 0 1-.603-1.435v-3.203l5.587-5.489 1.917 1.89c.234.192.422.43.55.701a2 2 0 0 1 .196.865l-.008-.008Z"
    fill="#EFAA35"
  />
</svg>
)
