import fileSize from "filesize";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { uniqueId } from "lodash";
import { UploadArchives } from "../UploadArchives";
import { documentsAttributes, fileProps } from "../../../interfaces";

export interface IInputDocumentRefProps {
  focus: () => void;
}

interface IInputDocumentProps {
  defaultDocument?: documentsAttributes;
  fileSizeMax?: number;
  fileTypes?: string[];
  onChange?: (file: fileProps) => void;
  error?: string;
}

const initFile = (defaultValue: any) => {
  if (defaultValue) {
    if (typeof defaultValue === "object") return defaultValue;
    return {
      preview: `${process.env.REACT_APP_S3_URL}/${defaultValue}`,
      name: defaultValue,
      file: "arquivo",
    } as fileProps;
  } else return {} as fileProps;
};

const InputDocumentComponent: React.ForwardRefRenderFunction<
  IInputDocumentRefProps,
  IInputDocumentProps
> = (props, ref) => {
  const { defaultDocument, onChange = () => {}, ...rest } = props;

  const [file, setFile] = useState<fileProps>(initFile(defaultDocument));

  const uploadRef = useRef<any>(null);

  const handleUpload = useCallback(async (file: any) => {
    const newUploadedFile = {
      file: file[0],
      id: uniqueId(),
      name: file[0].name,
      readableSize: fileSize(file[0].size),
      preview: URL.createObjectURL(file[0]),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    };
    setFile(newUploadedFile);
    onChange(newUploadedFile);
  }, []);

  const focus = useCallback(() => {
    uploadRef?.current?.focus();
  }, [uploadRef]);

  useImperativeHandle(ref, () => ({ focus }));

  return (
    <UploadArchives
      noLoading
      onUpload={(file: any) => handleUpload(file)}
      file={file}
      {...rest}
      ref={uploadRef}
    />
  );
};

export const InputDocument = forwardRef(InputDocumentComponent);
