import React from "react";
import { Container } from "./style";
import { FormVariablesTest } from "./form";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";

interface IModalVariablesTestProps {
    onCancel: Function
    openModal: boolean
    backgroundVisible?: boolean
}

export const ModalVariablesTest: React.FC<IModalVariablesTestProps> = (props) => {
    const { onCancel, openModal, backgroundVisible } = props

    const { theme } = useGlobal()

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>
                <ModalHeader
                    title={"Variáveis de Documentos Eletrônicos"}
                    subTitle={"Teste de Variáveis de Documentos Eletrônicos"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading theme={theme} loading={false}>
                    <FormVariablesTest />
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    )
}