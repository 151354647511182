import Button from "../../../components/Buttons/Button";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { Line } from "../../../style";
import { GrUpdate } from "react-icons/gr";
import { useApi } from "../../../hooks/api";
import { BsArrowRight } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import { useGlobal } from "../../../hooks/global";
import { AiFillCheckCircle } from "react-icons/ai";
import { ModalGeneric } from "../../../components/ModalGeneric";
import { ProgressStatic } from "../../../components/ProgressStatic";
import { personsAttributes, themeTypes } from "../../../interfaces";
import { WaveLoading } from "../../../components/Loadings/WaveLoading";
import { fail, inactiveItens, info, secondary, success } from "../../../theme";
import { Superlogica32 } from "../../../components/SvgComponents/Icons/Superlogica/Superlogica32";

interface IModalExport {
    open: boolean
    onClose: () => void
    onFinish: () => void
    theme: themeTypes
    franchise_id: string
    to_export?: personsAttributes[]
}

export const ModalExport: React.FC<IModalExport> = ({ onClose, onFinish, open, theme, franchise_id, to_export = [] }) => {
    const { api } = useApi()
    const { notify } = useGlobal()

    const [steps, setSteps] = useState(1)
    const [finished, setFinished] = useState(false)
    const [fails_count, setFailsCount] = useState(0)
    const [exist_count, setExistCount] = useState(0)
    const [success_count, setSuccessCount] = useState(0)
    const [total_exporteds, setTotalExporteds] = useState(0)
    const [properties, setProperties] = useState<personsAttributes[]>(to_export)

    const transformPlural = useCallback((word_single, word_plural, length) => {
        return length > 1 ? word_plural : word_single
    }, [])

    const handleFinishExport = useCallback(async () => {
        setFinished(true)
        onFinish()
    }, [])

    const searchPropertiesOfMax = useCallback(async () => {
        if (to_export.length === 0) {
            try {
                const result = await api.get(`/properties`)
                setProperties([...result.data.rows])
                setSteps(2)
            } catch (err) {
                notify("Erro ao buscar imóveis!", "error")
                onClose()
            }
        }
        else {
            const new_properties = []
            for (const customer of to_export) {
                try {
                    const person = await api.get(`/properties/${customer.id}`)
                    new_properties.push(person.data)
                } catch (err) {
                    notify("Erro ao buscar imóveis!", "error")
                    onClose()
                }
            }
            setProperties([...new_properties])
            setSteps(2)
        }
    }, [franchise_id])

    const confirmExportProperties = useCallback(async () => {
        let success_exporteds = 0
        let fail_exporteds = 0
        let exists_exporteds = 0

        setSteps(3)

        for (const property of properties) {
            try {
                const { data } = await api.post(`/integrations/super_logica/export-property/${property.id}`)
                if (data.property_exist) exists_exporteds++
                else success_exporteds++
            } catch (err: any) {
                fail_exporteds++
            }
            setTotalExporteds((prev) => prev + 1)
        }

        setTotalExporteds((prev) => prev + 1) // +1 for finish

        if (properties.length === 0) handleFinishExport()

        setSuccessCount(success_exporteds)
        setFailsCount(fail_exporteds)
        setExistCount(exists_exporteds)

    }, [franchise_id, properties])

    useEffect(() => { searchPropertiesOfMax() }, [])

    return (
        <ModalGeneric open={open} onClose={() => { }}>
            {steps === 1 && (
                <Container style={{ minHeight: 400 }} theme={theme}>
                    <div>Buscando imóveis selecionados da <b>Max Locação</b></div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <WaveLoading />
                    </div>
                    <Button
                        style={{ padding: 8, fontSize: 14 }}
                        onClick={() => onClose()}
                        background={inactiveItens[theme]}
                        color="#FFF"
                        children="Cancelar"
                    />
                </Container>
            )}
            {steps === 2 && (
                <Container theme={theme}>
                    <Superlogica32 color={theme === "light" ? "#1034F2" : "FFF"} />
                    <div style={{ textAlign: "center" }}>
                        {transformPlural("Foi selecionado", "Foram selecionados", properties.length)} <b>{properties.length}</b> {transformPlural("imóvel", "imóveis", properties.length)} na <b>Max Locação</b>,
                        deseja confirmar a exportação?
                    </div>
                    <ul>
                        <li style={{ fontSize: 14, opacity: 0.7 }}>
                            Após a confirmação não será mais possível cancelar esta ação!
                        </li>
                    </ul>
                    <Line style={{ gap: 10, width: "100%" }}>
                        <Button
                            style={{ padding: 8, fontSize: 14 }}
                            onClick={() => confirmExportProperties()}
                            background={secondary[theme]}
                            color="#FFF"
                            children="Confirmar"
                        />
                        <Button
                            style={{ padding: 8, fontSize: 14 }}
                            onClick={() => onClose()}
                            background={inactiveItens[theme]}
                            color="#FFF"
                            children="Cancelar"
                        />
                    </Line>
                </Container>
            )}
            {steps === 3 && !finished && (
                <Container theme={theme}>
                    <div style={{ textAlign: "center" }}>
                        Exportando <b>{properties.length}</b> {transformPlural("imóvel", "imóveis", properties.length)} da <b>Max Locação</b>
                    </div>
                    <ProgressStatic
                        onFinished={handleFinishExport}
                        theme={theme}
                        max={properties.length}
                        min={0}
                        atual={total_exporteds}
                    />
                    <div style={{ fontSize: 14, textAlign: "center", opacity: 0.7 }}>
                        Esta operação pode demorar alguns minutos! Permaneça nesta tela até o fim da exportação.
                    </div>
                </Container>
            )}
            {steps === 3 && finished && (
                <Container theme={theme}>
                    <div style={{ textAlign: "center", fontWeight: 600 }}>
                        Resumo das exportações
                    </div>
                    <table style={{ marginTop: -10 }}>
                        <tbody>
                            <tr>
                                <td>
                                    <AiFillCheckCircle color={success} />
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Imóveis exportados
                                </td>
                                <td style={{ padding: 10 }}>
                                    <BsArrowRight />
                                </td>
                                <td style={{ padding: 10, width: "100%", fontWeight: 600, color: success }}>
                                    {success_count}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <FiAlertCircle color={fail} />
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Imóveis não exportados
                                </td>
                                <td style={{ padding: 10 }}>
                                    <BsArrowRight />
                                </td>
                                <td style={{ padding: 10, width: "100%", fontWeight: 600, color: fail }}>
                                    {fails_count}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <GrUpdate color={info} />
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Imóveis atualizados na <b>Superlógica</b>
                                </td>
                                <td style={{ padding: 10 }}>
                                    <BsArrowRight />
                                </td>
                                <td style={{ padding: 10, width: "100%", fontWeight: 600, color: info }}>
                                    {exist_count}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Button
                        style={{ padding: 8, fontSize: 14 }}
                        onClick={() => onClose()}
                        background={inactiveItens[theme]}
                        color="#FFF"
                        children="Fechar"
                    />
                </Container>
            )}
        </ModalGeneric>
    )
}