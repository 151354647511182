import React from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { Content } from "./styles";

export const SemiCircleProgress: React.FC<any> = (props) => {
    const { value, percentage, type = "score", toPrint = false, orientation = "normal", highType = null } = props

    const correct_color = () => {

        if (type === "score") {
            if (value <= 533.33) return "#FF6B6B"
            else if (value <= 766.66) return "#FAAD14"
            else return "#52C41A"
        }
        else {
            if (orientation === "normal") {
                if (highType) {
                    if (highType === "ALTO") return "#52C41A"
                    else if (highType === "MEDIO") return "#FAAD14"
                    else if (highType === "BAIXO") return "#FF6B6B"
                }
                if (value <= 33.33) return "#FF6B6B"
                else if (value <= 66.66) return "#FAAD14"
                else return "#52C41A"
            }
            else {
                if (highType) {
                    if (highType === "ALTO") return "#FF6B6B"
                    else if (highType === "MEDIO") return "#FAAD14"
                    else if (highType === "BAIXO") return "#52C41A"
                }
                if (value <= 33.33) return "#52C41A"
                else if (value <= 66.66) return "#FAAD14"
                else return "#FF6B6B"
            }

        }
    }

    return (
        <Content>
            <div style={{ display: "flex", position: "relative" }}>
                <div style={{ transform: toPrint ? "scaleX(-1)" : undefined }}>
                    <SemiCircleProgressBar
                        strokeWidth={30}
                        stroke={correct_color()}
                        percentage={percentage}
                    />
                </div>

                <div style={{ display: "flex", whiteSpace: "nowrap", flexDirection: "column", bottom: -22, left: "50%", transform: "translate(-50%)", position: "absolute", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ fontSize: 30, fontWeight: 600, marginBottom: type === "score" ? -10 : 10 }}>{value}{type === "porcen" ? (value !== "--" ? "%" : "") : ""}</div>
                    {type === "score" && <div style={{ fontSize: 14 }}> Score de crédito</div>}
                </div>
            </div>
        </Content>
    );
}