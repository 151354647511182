import Button from "../../../Buttons/Button";
import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { useForm } from "react-hook-form";
import { Tooltip } from "../../../Tooltip";
import { AddButton, Column } from "../../../../style";
import { useApi } from "../../../../hooks/api";
import { FiHome, FiSend } from "react-icons/fi";
import { useGlobal } from "../../../../hooks/global";
import { IGroupProps } from "../../../../interfaces";
import { BsPersonSquare, BsTrash } from "react-icons/bs";
import { inactiveItens, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { maskFunctions } from "../../../../services/maskServices";
import { valueIsEmpty } from "../../../../services/generalServices";
import { ContentOrLoading } from "../../../Loadings/ContentOrLoading";
import { signatoriesRelationsOptions } from "../../../../utils/options";
import { validateCPF, validateEmail } from "../../../../utils/validatesFields";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { documentEletronicBondsOptions, documentEletronicTypeOptions, yesNoOptions } from "../../../../utils/options";
import { ButtonsContainer, ContainerButtonAdd, ContainerGroups, ContainerRemove, ContainerSignatories, DescriptionSignatories, EmptyText, Form, Group, HeaderSignatories, TitleSignatories } from "./style";

interface IFormContractModalProps {
    onCancel: Function
    onSave: Function
    defaultData: any
    defaultSignatories?: any[]
}

const dateFuture = (date: any, days: number) => {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result.toString()
}

const titleSignatories = "Signatários"

const descriptionSignatories = "Gerencie aqui quem vai assinar este documento eletrônico."

const siganatoriesFields = ["customer_id", "email", "cpf", "relation"]

const correct_bond: any = {
    "proposal": "proposal_id",
    "property": "property_id",
    "free": null
}

export const FormContractModal: React.FC<IFormContractModalProps> = (props) => {
    const { onCancel, onSave, defaultData, defaultSignatories } = props

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const { api, consultsServices } = useApi()
    const { theme, notify } = useGlobal()

    const generateSignatoryGroup = (defaultId?: string) => {
        const _uniqueId = defaultId ? defaultId : uniqueId()

        const newOwnerGroup: IGroupProps = {
            name: "",
            label: "",
            uniqueId: _uniqueId,
            fields: [
                [
                    {
                        name: "customer_id-" + _uniqueId,
                        label: "Nome",
                        type: "select-person",
                        required: true,
                        isClearable: true,
                        get_enabled_change_form_type: () => false,
                        getRemoveIds: (obj: any) => {
                            const customerIds = Object.keys(obj).filter(key => key.startsWith('customer_id-'));
                            const customerValues = customerIds.map(id => obj?.[id]?.value);
                            return customerValues?.filter((value: any) => !!value)
                        },
                        backgroundVisible: true,
                        moreQueries: { type: "PF" },
                        executeOnChange: async (data: any) => {
                            const customer = data?.this

                            if (customer?.id) {
                                const cpf = maskFunctions.cpf.mask(customer?.registration_data?.cpf)
                                const email = customer?.user?.email
                                setValue("cpf-" + _uniqueId, cpf)
                                setValue("email-" + _uniqueId, email)
                            } else {
                                setValue("cpf-" + _uniqueId, null)
                                setValue("email-" + _uniqueId, null)
                                setValue("relation-" + _uniqueId, null)
                            }
                        }
                    },
                    {
                        name: "email-" + _uniqueId,
                        label: "Email",
                        type: "input",
                        validate: validateEmail,
                        required: true,
                        getIsDisabled: (data) => !data["customer_id-" + _uniqueId]?.value
                    },
                    {
                        name: "cpf-" + _uniqueId,
                        label: "CPF",
                        type: "input",
                        mask: "cpf",
                        required: false,
                        validate: validateCPF,
                        getIsDisabled: () => true
                    },
                    {
                        name: "relation-" + _uniqueId,
                        label: "Tipo",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: signatoriesRelationsOptions,
                        getIsDisabled: (data) => !data["customer_id-" + _uniqueId]?.value
                    }
                ]
            ]
        }

        return newOwnerGroup
    }

    const [loading_create, setLoadingCreate] = useState(false)
    const [loadingSignatories, setLoadingSignatories] = useState(false)
    const [signatoriesGroups, setSignatoriesGroups] = useState<IGroupProps[]>(() => {
        if (defaultSignatories && defaultSignatories.length > 0) {
            const _signatoriesGroups: any[] = []

            const _defaultDataSignatories = defaultSignatories ?? []
            _defaultDataSignatories.forEach((executor: any) => {
                _signatoriesGroups.push(generateSignatoryGroup(executor.uniqueId))
            })

            return _signatoriesGroups
        }
        if (!defaultData?.bond_type) return []
        return [generateSignatoryGroup()]
    })

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)
    const signatoriesRefs = useRef<IGenericFormRefProps[]>([])

    const onSubmit = useCallback(async () => {

        setLoadingCreate(true)

        try {
            const form = form_ref.current?.getForm()

            const expiration_date = form.expiration_date
            const bond_type = form.bond_type
            const proposal_id = form.proposal_id
            const property_id = form.property_id
            const select_id = bond_type === "proposal" ? proposal_id : property_id
            const contract_model_id = form.contract_model_id
            const franchise_sign = form.franchise_sign
            const model_type = form.model_type
            const file_personalized = form.file_personalized
            const file = form.file
            const signatories = getSignatories()

            let _file = null
            if (model_type === "send_personalized" && file_personalized) _file = file_personalized
            else if ((model_type === "send_file" && file) || bond_type === "free") _file = file

            const bond_id = correct_bond[bond_type]
            const formData = new FormData()

            if (_file?.file !== "arquivo" && _file?.file) formData.append("file", _file?.file)
            formData.append("signatories", JSON.stringify(signatories))
            formData.append("expiration_date", dateFuture(new Date(), Number.parseInt(expiration_date)))
            formData.append("model_type", model_type)
            formData.append("contract_type", bond_type)
            if (franchise_sign === "true") formData.append("franchiseSign", franchise_sign)
            if (contract_model_id) formData.append("contract_model_id", contract_model_id)
            if (bond_id) formData.append(bond_id, select_id as string)

            await api.post(`/contracts`, formData)

            notify("Documento eletrônico criado com sucesso", "success")

            onSave()

        } catch (err: any) {

            notify("Erro ao criar documento eletrônico", "error")

        }

        setLoadingCreate(false)

    }, [form_ref, _form, api, signatoriesRefs])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api, signatoriesRefs])

    const handleChangePropertyOrProposal = useCallback(async (data: any) => {
        const { proposal_id, property_id } = data

        if (!proposal_id && !property_id) {
            setSignatoriesGroups((groups) => {
                for (const group of groups) siganatoriesFields.forEach((field) => unregister(field + "-" + group.uniqueId))
                return []
            })
        } else {

            setLoadingSignatories(true)

            try {

                const _signatoriesData = await consultsServices.signatories(data)
                const _signatoriesGroups: any[] = []

                const _signatories = _signatoriesData.map((signatory: any) => {
                    const _uniqueId = uniqueId()
                    const label = signatory?.customer?.registration_data?.name
                    const value = signatory?.customer?.id
                    const _this = signatory?.customer

                    return {
                        ["customer_id-" + _uniqueId]: { label, value, this: _this },
                        ["email-" + _uniqueId]: signatory?.customer?.user?.email,
                        ["cpf-" + _uniqueId]: maskFunctions.cpf.mask(signatory?.customer?.registration_data?.cpf),
                        ["relation-" + _uniqueId]: signatoriesRelationsOptions.find(option => option.value === signatory?.relation),
                        uniqueId: _uniqueId
                    }
                })

                for (const signatory of _signatories) {
                    const _uniqueId = signatory.uniqueId

                    for (const field of siganatoriesFields) {
                        setValue(field + "-" + _uniqueId, signatory[field + "-" + _uniqueId])
                    }

                    _signatoriesGroups.push(generateSignatoryGroup(signatory.uniqueId))
                }

                setSignatoriesGroups(_signatoriesGroups)

            } catch (err) {

                notify("Erro ao carregar signatários", "error")

            }

            setLoadingSignatories(false)
        }

    }, [])

    const handleChangeBondType = useCallback(async (data: any) => {
        const value = data?.value

        setValue("proposal_id", null)
        setValue("property_id", null)
        setSignatoriesGroups((groups) => {
            for (const group of groups) siganatoriesFields.forEach((field) => unregister(field + "-" + group.uniqueId))
            if (value === "free") return [generateSignatoryGroup()]
            else return []
        })
        if (value === "free") setValue("model_type", documentEletronicTypeOptions.find(option => option.value === "send_file"))
    }, [])

    const getSignatories = useCallback(() => {
        const _refs = signatoriesRefs.current.filter((ref) => ref?.getForm())
        const signatories = _refs.map((ref) => {
            const form = ref?.getForm()
            const _uniqueId = form?.uniqueId

            return {
                customer_id: form?.["customer_id-" + _uniqueId],
                email: form?.["email-" + _uniqueId],
                cpf: form?.["cpf-" + _uniqueId],
                relation: form?.["relation-" + _uniqueId],
                name: _form?.["customer_id-" + _uniqueId]?.this?.registration_data?.name,
                uniqueId: _uniqueId
            }
        })
        return signatories
    }, [signatoriesRefs, _form])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "bond_type",
                        label: "Vínculo",
                        type: "select-fixed",
                        isClearable: true,
                        required: true,
                        options: documentEletronicBondsOptions,
                        executeOnChange: handleChangeBondType,
                        getIsDisabled: (data: any) => defaultData?.bond_type?.value
                    },
                    {
                        name: "proposal_id",
                        label: "Proposta",
                        type: "select-single-cards-no-creatable",
                        isClearable: true,
                        required: true,
                        searchOptions: consultsServices.proposal_id,
                        moreQueries: { status_array: ["PA", "CF"] },
                        icon: <FiSend />,
                        canSee: (data: any) => data?.bond_type?.value === "proposal",
                        executeOnChange: async (data: any) => handleChangePropertyOrProposal({ proposal_id: data?.value }),
                        getIsDisabled: (data: any) => defaultData?.proposal_id?.value
                    },
                    {
                        name: "property_id",
                        label: "Imóvel",
                        type: "select-single-cards-no-creatable",
                        isClearable: true,
                        required: true,
                        searchOptions: consultsServices.property_id,
                        icon: <FiHome />,
                        canSee: (data: any) => data?.bond_type?.value === "property",
                        executeOnChange: async (data: any) => handleChangePropertyOrProposal({ property_id: data?.value }),
                        getIsDisabled: (data: any) => defaultData?.property_id?.value
                    }
                ],
                [
                    {
                        name: "model_type",
                        label: "Tipo",
                        type: "select-fixed",
                        isClearable: true,
                        required: true,
                        options: documentEletronicTypeOptions,
                        getIsDisabled: (data: any) => ["free"].includes(data?.bond_type?.value ?? ""),
                        canSee: (data: any) => data?.bond_type?.value
                    },
                    {
                        name: "file",
                        label: "Arquivo",
                        type: "file",
                        required: true,
                        getFileTypes: (data: any) => ["pdf", "doc", "docx"],
                        canSee: (data: any) => ["free"].includes(data?.bond_type?.value ?? "") || (["property", "proposal"].includes(data?.bond_type?.value ?? "") && data?.model_type?.value === "send_file")
                    },
                    {
                        name: "file_personalized",
                        label: "Modelo personalizado",
                        type: "file",
                        required: true,
                        getFileTypes: (data: any) => ["doc", "docx"],
                        canSee: (data: any) => ["property", "proposal"].includes(data?.bond_type?.value ?? "") && data?.model_type?.value === "send_personalized"
                    },
                    {
                        name: "contract_model_id",
                        label: "Modelo",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        required: true,
                        searchOptions: consultsServices.contract_model_id,
                        canSee: (data: any) => ["property", "proposal"].includes(data?.bond_type?.value ?? "") && data?.model_type?.value === "select"
                    }
                ],
                [
                    {
                        name: "expiration_date",
                        label: "Dias para expirar",
                        type: "input",
                        required: true,
                        mask: "int",
                        validate: async (value: any) => {
                            const _value = !valueIsEmpty(value) ? Number.parseInt(value) : 0
                            if (_value > 90 || _value < 1) return "O valor deve ser de 1 a 90 dias"
                            return true
                        }
                    },
                    {
                        name: "franchise_sign",
                        label: "Adicionar assinatura da imobiliária",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: yesNoOptions
                    }
                ]
            ]
        }
    ]

    const addSignatoryGroup = useCallback(() => {
        const newOwnerGroup = generateSignatoryGroup()
        setSignatoriesGroups((prev) => {
            return [...prev, newOwnerGroup]
        })
    }, [uniqueId])

    const removeOwnerGroup = useCallback((index: number) => {
        setSignatoriesGroups((prev) => {
            return prev.filter(({ uniqueId }, i) => {
                if (i !== index) return true
                else siganatoriesFields.forEach((field) => unregister(field + "-" + uniqueId))
            })
        })
    }, [])

    useEffect(() => {
        if (defaultData.proposal_id) {
            handleChangePropertyOrProposal({ proposal_id: defaultData?.proposal_id?.value })
        }
        if (defaultData.property_id) {
            handleChangePropertyOrProposal({ property_id: defaultData?.property_id?.value })
        }
    }, [])

    return (
        <>
            <Form onSubmit={handleStopPropagation}>

                <GenericForm
                    ref={form_ref}
                    groups={groups}
                    _form={_form}
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    setValue={setValue}
                    register={register}
                    disabled={loadingSignatories}
                />

                <ContainerSignatories theme={theme}>

                    <HeaderSignatories theme={theme}>
                        <BsPersonSquare
                            size={24}
                            color={secondary[theme]}
                        />
                        <Column>
                            <TitleSignatories>
                                {titleSignatories}
                            </TitleSignatories>
                            <DescriptionSignatories>
                                {descriptionSignatories}
                            </DescriptionSignatories>
                        </Column>
                    </HeaderSignatories>

                    <ContentOrLoading type="wave" theme={theme} loading={loadingSignatories}>
                        <ContainerGroups>
                            {signatoriesGroups.map((signatory_group, index) => (
                                <Group key={signatory_group.uniqueId}>
                                    {signatoriesGroups.length > 1 && (
                                        <ContainerRemove>
                                            <Tooltip
                                                onClick={() => removeOwnerGroup(index)}
                                                style={{ height: 36, width: 36 }}
                                                title="Excluir"
                                                children={(
                                                    <div>
                                                        <BsTrash
                                                            color={inactiveItens[theme]}
                                                            size={20}
                                                        />
                                                    </div>
                                                )}
                                            />
                                        </ContainerRemove>
                                    )}
                                    <Column style={{ flex: 1 }}>
                                        <GenericForm
                                            ref={(ref: any) => signatoriesRefs.current[index] = ref}
                                            groups={[signatory_group]}
                                            _form={_form}
                                            control={control}
                                            errors={errors}
                                            trigger={trigger}
                                            setValue={setValue}
                                            register={register}
                                            disabled={loadingSignatories}
                                        />
                                    </Column>
                                </Group>
                            ))}
                            {signatoriesGroups.length === 0 &&
                                <EmptyText>
                                    Nenhum signatário vinculado
                                </EmptyText>
                            }
                            {(["property", "proposal"].includes(_form?.bond_type?.value) && (_form?.property_id?.value || _form?.proposal_id?.value) || ["free"].includes(_form?.bond_type?.value)) && (
                                <ContainerButtonAdd>
                                    <AddButton noHaveDelete={!(signatoriesGroups.length > 1)} onClick={addSignatoryGroup} >
                                        Incluir+
                                    </AddButton>
                                </ContainerButtonAdd>
                            )}
                        </ContainerGroups>
                    </ContentOrLoading>
                </ContainerSignatories>

                <button
                    ref={buttonSubmitRef}
                    type="submit"
                    style={{ display: "none" }}
                />
            </Form>

            <ButtonsContainer theme={theme}>
                <Button
                    disabled={loadingSignatories}
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Voltar"
                />
                <Button
                    disabled={loadingSignatories}
                    onClick={() => buttonSubmitRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Salvar"
                />
            </ButtonsContainer>

            <ModalLoading
                loading={loading_create}
                theme={theme}
            />

        </>
    )
}