import React from "react";
import { Description, Title, Container, CircleInside, CircleOuside } from "./style";

interface IStepHeaderProps {
    title: any;
    description: any;
    icon: any
    colors: string[];
}

export const StepHeader: React.FC<IStepHeaderProps> = (props) => {
    const { title, description, icon, colors } = props;

    return (
        <Container>

            <CircleOuside color={colors[0]}>
                <CircleInside color={colors[1]}>{icon}</CircleInside>
            </CircleOuside>

            <Title>{title}</Title>

            <Description>{description}</Description>

        </Container>
    )

}