import axios from "axios";
import parse from "query-string";
import React, { useState, useCallback, useEffect } from "react";
import { Container } from "./style";
import { CgChart } from "react-icons/cg";
import { VscLaw } from "react-icons/vsc";
import { Column, Line } from "../../style";
import { useParams } from "react-router-dom";
import { useGlobal } from "../../hooks/global";
import { AiOutlineDashboard } from "react-icons/ai";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { FieldList } from "../../components/FiedlList";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BagPrint } from "../../components/Bags/BagPrint";
import { maskFunctions } from "../../services/maskServices";
import { RiShieldUserLine, RiSuitcaseLine } from "react-icons/ri";
import { WaveLoading } from "../../components/Loadings/WaveLoading";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { IndicatorsResultProps, queriesAttributes } from "../../interfaces";
import { SemiCircleProgress } from "../../components/Loadings/SemiCircleProgress";
import { background, borderColors, inactiveItens, primary, secondary } from "../../theme";
import { correct_date_result, ListFieldsHeader, ListFieldsLaw, ListFieldsPF, ListFieldsPJ } from "../../utils/queryListFields";

const states = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
]

const correct_indicator_type = [
    ["Comprometimento de crédito emergencial", "inversed"],
    ["Risco do perfil de contratação", "normal"],
    ["Quantidade de contratos em aberto", "inversed"],
    ["Gravidade em tempo de atrasos", "inversed"],
    ["Pontualidade de pagamento", "normal"],
    ["Quantidade de novas contratações", "inversed"],
    ["Estabilidade de relacionamento com o mercado", "inversed"]
]

const normalizeString = (string: string) => {
    return string?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")?.replace(/[^a-zA-Z ]/g, "")?.replace(/ /g, "")?.toLowerCase()
}

const compareNames = (a: any, b: any) => {
    const a_normalize = normalizeString(a)
    const b_normalize = normalizeString(b)

    return a_normalize === b_normalize
}

const getTypeOfIndicator = (indicator: string) => {
    const result = correct_indicator_type?.find(item => compareNames(item[0], indicator))
    return result ? result[1] : "normal"
}

export const QueryPDF: React.FC = () => {
    const { notify } = useGlobal()
    const { query_id } = useParams()
    const { token, franchise_id } = parse.parseUrl(window.location.href).query

    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState<queriesAttributes | null>(null)

    const api = axios.create({
        baseURL: process.env.REACT_APP_API
    })

    api.interceptors.request.use(async (config: any) => {
        config.headers.authorization = `Bearer ${token}`;
        return config
    });

    const searchQuery = useCallback(async () => {
        setLoading(true)
        try {
            const result = await api?.get("/queries/" + query_id + "?franchise_id=" + franchise_id)
            if (result?.data?.result_biro && result?.data?.status === "finished") result.data.result_biro = JSON?.parse(result?.data?.result_biro)
            setQuery(result?.data)
        } catch (err) {
            console?.log(err)
            notify("Erro ao buscar a consulta", "error")
        }
        setLoading(false)
    }, [query_id, franchise_id])

    const totalPendencies = (query: queriesAttributes) => {
        const protests_num = protests(query)?.num
        const apontamentos = inadimplencias(query)?.num
        const lawSuits = lawSuit(query)?.num
        return protests_num + apontamentos + lawSuits
    }

    const totalValuePendencies = (query: queriesAttributes) => {
        const protests_num = protests(query)?.value
        const apontamentos = inadimplencias(query)?.value
        const lawSuits = lawSuit(query)?.value
        return protests_num + apontamentos + lawSuits
    }

    const inadimplencias = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.Apontamentos?.length
        const value = query?.result_biro?.Negative?.TotalValorApontamentos ? query?.result_biro?.Negative?.TotalValorApontamentos : 0
        return { num, value }
    }

    const protests = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.TotalProtests ? query?.result_biro?.Negative?.TotalProtests : 0
        const value = query?.result_biro?.Negative?.TotalValorProtests ? query?.result_biro?.Negative?.TotalValorProtests : 0
        return { num, value }
    }

    const lawSuit = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.LawSuitApontamentos?.length
        const value = query?.result_biro?.Negative?.TotalValorLawSuitApontamentos ? query?.result_biro?.Negative?.TotalValorLawSuitApontamentos : 0
        return { num, value }
    }

    const cheques = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.CcfApontamentos?.length
        return { num }
    }

    const addressHistoryCount = (query: queriesAttributes) => {
        return query?.result_biro?.BestInfo?.AddressHistory ? query?.result_biro?.BestInfo?.AddressHistory?.Address?.length : 0
    }

    const partneshipsCount = (query: queriesAttributes) => {
        return query?.result_biro?.EnterpriseData?.Partnerships ? query?.result_biro?.EnterpriseData?.Partnerships?.Partnership?.length : 0
    }

    const partnersCount = (query: queriesAttributes) => {
        return query?.result_biro?.Partner ? query?.result_biro?.Partner?.Partnerships?.Partnership?.length : 0
    }

    const processEstaduais = (query: queriesAttributes) => {
        return query?.result_biro?.Negative?.LawSuitApontamentos?.filter((lawSuit) => lawSuit?.JusticeType === "ESTADUAL")
    }

    const processFederal = (query: queriesAttributes) => {
        return query?.result_biro?.Negative?.LawSuitApontamentos?.filter((lawSuit) => lawSuit?.JusticeType !== "ESTADUAL")
    }

    useEffect(() => { searchQuery() }, [])

    const getIndicators = (query: queriesAttributes) => {
        if (query?.result_biro?.Indicators?.Indicators?.indicator) return []
        else if (query?.result_biro?.Indicators) return query?.result_biro?.Indicators
        return []
    }

    return (
        <>
            <Container id="content-pdf" theme={"light"}>
                {!loading && !!query?.result_biro && !query?.result_biro?.ErrorMessage && (query?.type === "PJ" ? query?.result_biro?.BestInfo?.CompanyStatus !== "DESCONHECIDO" : true) &&
                    <Column style={{ padding: 40, paddingTop: 0, gap: 20, flex: 1 }}>
                        <Column style={{ border: `1px solid ${borderColors.light}`, borderRadius: 4, background: primary.light }}>
                            <div style={{ fontSize: 16, fontWeight: 600, padding: 16, textTransform: "uppercase" }}>
                                Análise de <span>{query?.result_biro ? (query?.type === "PF" ? query?.result_biro?.BestInfo?.PersonName?.Name?.Full : query?.result_biro?.BestInfo?.CompanyName) : "--"}</span>
                            </div>
                            <Column style={{ gap: 5, padding: 16, borderTop: `1px solid ${borderColors.light}` }}>
                                <FieldList
                                    data={query}
                                    listFields={ListFieldsHeader}
                                    theme="light"
                                    fontSize={13.5}
                                />
                            </Column>
                        </Column>
                        <Column style={{ gap: 20 }}>
                            <BagPrint forceTheme="light" noPageBreak title="Informações cadastrais" description="Dados autenticados pela Receita Federal" icon={<RiShieldUserLine color={secondary["light"]} size={26} />}>
                                <Column style={{ gap: 8, padding: 16 }}>
                                    {query?.type === "PJ" &&
                                        <FieldList
                                            data={query}
                                            theme="light"
                                            listFields={ListFieldsPJ}
                                            fontSize={13.5}
                                        />
                                    }
                                    {query?.type === "PF" &&
                                        <FieldList
                                            data={query}
                                            theme="light"
                                            listFields={ListFieldsPF}
                                            fontSize={13.5}
                                        />
                                    }
                                </Column>
                            </BagPrint>
                            {getIndicators(query).length > 0 && (
                                <BagPrint forceTheme="light" noPageBreak={query?.type === "PF"} title="Indicadores" description="Comportamento financeiro do consumidor" icon={<CgChart color={secondary["light"]} size={26} />}>
                                    <Column style={{ padding: 16, fontSize: 12, gap: 16 }}>
                                        <div>
                                            A partir do nosso banco de dados, formado junto ao cadastro positivo, desenvolvemos indicadores exclusivos complementares ao score.
                                            Entenda profundamente o comportamento financeiro do consumidor para que você tenha mais segurança na hora de conceder crédito.
                                        </div>
                                        {getIndicators(query)?.map((indicator: IndicatorsResultProps) => (
                                            <>
                                                <Line style={{ gap: 16 }}>
                                                    <Column style={{ gap: 8, paddingBottom: 16, justifyContent: "flex-start", marginTop: -16 }}>
                                                        <SemiCircleProgress
                                                            orientation={getTypeOfIndicator(indicator?.VariableName)}
                                                            type="porcen"
                                                            value={indicator?.Value}
                                                            percentage={(Number?.parseFloat(indicator?.Value ? indicator?.Value : "0"))}
                                                        />
                                                    </Column>
                                                    <Column style={{ fontSize: 12, justifyContent: "flex-end", marginBottom: 16 }}>
                                                        <div style={{ fontWeight: 600 }}>{indicator?.VariableName === "PaymentCommitmentScore" ? "Pontualidade do Pagador:" : (indicator?.VariableName === "ProfileScore" ? "Relacionamento com o Mercado:" : `${indicator?.VariableName}: `)}</div>
                                                        <div style={{ fontWeight: 600 }}>{indicator?.Risk?.type}</div>
                                                        <div>{indicator?.Risk?.text}</div>
                                                    </Column>
                                                </Line>
                                                <div style={{ marginTop: -16 }}>{indicator?.Description}</div>
                                            </>
                                        ))}
                                    </Column>
                                </BagPrint>
                            )}
                            <BagPrint forceTheme="light" title="Restrições financeiras" description="Dados coletados do birô de crédito" icon={<AiOutlineDashboard color={secondary["light"]} size={26} />}>
                                <Column style={{ padding: 16, gap: 20 }}>
                                    <Line style={{ padding: 16, border: "1px solid #CCCCCC", borderRadius: 4, gap: 16 }}>
                                        <Column style={{ gap: 8, paddingBottom: 16, justifyContent: "flex-start", marginTop: -16 }}>
                                            <SemiCircleProgress
                                                highType={query?.result_biro?.QuodScore?.CreditRisk}
                                                value={query?.type === "PF" ? query?.result_biro?.QuodScore?.Score : query?.result_biro?.ScorePJ?.Score}
                                                percentage={(query?.type === "PF" ? (query?.result_biro?.QuodScore?.Score ? query?.result_biro?.QuodScore?.Score : 0) : (query?.result_biro?.ScorePJ?.Score ? query?.result_biro?.ScorePJ?.Score : 0)) / 10}
                                            />
                                        </Column>
                                        <Column style={{ gap: 10 }}>
                                            <Column style={{ fontSize: 12 }}>
                                                <div style={{ fontWeight: 600 }}>Risco de Crédito:</div>
                                                <div style={{ fontWeight: 600 }}>{query?.result_biro?.QuodScore?.CreditRisk ? query?.result_biro?.QuodScore?.CreditRisk : "--"}</div>
                                                <div>{query?.result_biro?.QuodScore?.Message ? query?.result_biro?.QuodScore?.Message : "--"}</div>
                                            </Column>
                                            <Column style={{ fontSize: 12 }}>
                                                <div style={{ fontWeight: 600 }}>Probabilidade de pagamento:</div>
                                                <div style={{ fontWeight: 600 }}>
                                                    {query?.result_biro?.QuodScore?.ProbabilityOfPayment ? maskFunctions.porcen.mask(query?.result_biro?.QuodScore?.ProbabilityOfPayment) : "--"}
                                                </div>
                                                <div>{
                                                    query?.result_biro?.QuodScore?.ProbabilityOfPayment
                                                        ? `A chance deste consumidor não pagar nos próximos meses é de ${(maskFunctions.porcen.mask(100 - query?.result_biro?.QuodScore?.ProbabilityOfPayment))}.`
                                                        : "--"
                                                }</div>
                                            </Column>
                                        </Column>
                                    </Line>

                                    <Column style={{ gap: 10 }}>
                                        <div style={{ fontSize: 15, fontWeight: 600 }}>Pendências financeiras</div>
                                        <Column style={{ gap: 16 }}>
                                            <Line style={{ justifyContent: "space-between", gap: 16 }}>
                                                <Column style={{ alignItems: "center", padding: 16, border: `1px solid ${borderColors["light"]} `, flex: 1, borderRadius: 4 }}>
                                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Inadimplências comunicadas</div>
                                                    <div style={{ fontSize: 27, fontWeight: 600 }}>{maskFunctions.currency.mask(inadimplencias(query as queriesAttributes)?.value)}</div> {/* Ver depois */}
                                                    <div style={{ fontSize: 15 }}>{inadimplencias(query as queriesAttributes)?.num} {inadimplencias(query as queriesAttributes)?.num === 1 ? "pendência" : "pendências"}</div>
                                                </Column>
                                                <Column style={{ alignItems: "center", padding: 16, border: `1px solid ${borderColors["light"]} `, flex: 1, borderRadius: 4 }}>
                                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Protestos</div>
                                                    <div style={{ fontSize: 27, fontWeight: 600 }}>{maskFunctions.currency.mask(protests(query as queriesAttributes)?.value)}</div>
                                                    <div style={{ fontSize: 15 }}>{protests(query as queriesAttributes)?.num} {protests(query as queriesAttributes)?.num === 1 ? "pendência" : "pendências"}</div>
                                                </Column>
                                                <Column style={{ alignItems: "center", padding: 16, border: `1px solid ${borderColors["light"]} `, flex: 1, borderRadius: 4 }}>
                                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Ações judiciais</div>
                                                    <div style={{ fontSize: 27, fontWeight: 600 }}>{maskFunctions.currency.mask(lawSuit(query as queriesAttributes)?.value)}</div>
                                                    <div style={{ fontSize: 15 }}>{lawSuit(query as queriesAttributes)?.num} {lawSuit(query as queriesAttributes)?.num === 1 ? "pendência" : "pendências"}</div>
                                                </Column>
                                            </Line>
                                            <Column style={{ background: inactiveItens["light"], color: "#FFF", padding: 16, alignItems: "center", borderRadius: 4 }}>
                                                <div style={{ fontSize: 15, fontWeight: 600 }}>Total de pendências</div>
                                                <div style={{ fontSize: 27, fontWeight: 600 }}>{maskFunctions.currency.mask(totalValuePendencies(query as queriesAttributes))}</div>
                                                <div style={{ fontSize: 15 }}>{totalPendencies(query as queriesAttributes)} {totalPendencies(query as queriesAttributes) === 1 ? "pendência" : "pendências"}</div>
                                            </Column>
                                        </Column>
                                    </Column>

                                    <Column style={{ gap: 10 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de inadimplências comunicadas</div>
                                            <div style={{ fontSize: 13 }}>{inadimplencias(query as queriesAttributes)?.num === 1 ? "Foi encontrado" : "Foram encontrados"} {inadimplencias(query as queriesAttributes)?.num} {inadimplencias(query as queriesAttributes)?.num === 1 ? "registro" : "registros"} de inadimplência</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]} ` }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background["light"] }}>
                                                    <tr>
                                                        <th style={{ padding: 10 }}>VALOR</th>
                                                        <th style={{ padding: 10 }}>EMPRESA</th>
                                                        <th style={{ padding: 10 }}>CIDADE</th>
                                                        <th style={{ padding: 10, textAlign: "center" }}>VENCIMENTO</th>
                                                        <th style={{ padding: 10, textAlign: "center" }}>INCLUSÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.Negative?.Apontamentos?.map((apontamento: any) => (
                                                        <tr>
                                                            <td style={{ padding: 10 }}>{maskFunctions.currency.mask(apontamento?.Amount ? Number.parseFloat(apontamento?.Amount) : 0)}</td>
                                                            <td style={{ padding: 10 }}>{apontamento?.CompanyName ?? "--"}</td>
                                                            <td style={{ padding: 10 }}>{apontamento?.Address?.City ?? "--"}</td>
                                                            <td style={{ padding: 10, textAlign: "center" }}>{correct_date_result(apontamento?.DateOccurred)}</td>
                                                            <td style={{ padding: 10, textAlign: "center" }}>{correct_date_result(apontamento?.DateIncluded)}</td>
                                                        </tr>
                                                    ))}
                                                    {inadimplencias(query as queriesAttributes)?.num === 0 &&
                                                        <tr>
                                                            <td colSpan={5} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>

                                    <Column style={{ gap: 10 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de protestos</div>
                                            <div style={{ fontSize: 13 }}>{protests(query as queriesAttributes)?.num === 1 ? "Foi encontrado" : "Foram encontrados"} {protests(query as queriesAttributes)?.num} {protests(query as queriesAttributes)?.num === 1 ? "registro" : "registros"} de protesto</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]} ` }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background["light"] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap", textTransform: "uppercase" }}>Nº de Protestos</th>
                                                        <th style={{ padding: 10 }}>CARTÓRIO</th>
                                                        <th style={{ padding: 10 }}>CIDADE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.Negative?.Protests?.conteudo?.cartorio?.map((protest) => (
                                                        <tr>
                                                            <td style={{ padding: 10 }}>{protest?.protestos}</td>
                                                            <td style={{ padding: 10 }}>{protest?.nome}</td>
                                                            <td style={{ padding: 10 }}>{protest?.cidade} - {protest?.uf}</td>
                                                        </tr>
                                                    ))}
                                                    {protests(query as queriesAttributes)?.num === 0 &&
                                                        <tr>
                                                            <td colSpan={5} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>

                                    <Column style={{ gap: 10 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de cheques devolvidos</div>
                                            <div style={{ fontSize: 13 }}>{cheques(query as queriesAttributes)?.num === 1 ? "Foi encontrado" : "Foram encontrados"} {cheques(query as queriesAttributes)?.num} {cheques(query as queriesAttributes)?.num === 1 ? "registro" : "registros"} de cheques devolvidos</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]} ` }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background["light"] }}>
                                                    <tr>
                                                        <th style={{ padding: 10 }}>BANCO</th>
                                                        <th style={{ padding: 10 }}>AGÊNCIA</th>
                                                        <th style={{ padding: 10 }}>MOTIVO</th>
                                                        <th style={{ padding: 10 }}>QUANTIDADE</th>
                                                        <th style={{ padding: 10 }}>ÚLTIMA OCORRÊNCIA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.Negative?.CcfApontamentos?.map((apontamento) => (
                                                        <tr>
                                                            <td style={{ padding: 10 }}>{apontamento?.ReportingCodeBank ?? "--"}</td>
                                                            <td style={{ padding: 10 }}>{apontamento?.ReportingBranchPrefix ?? "--"}</td>
                                                            <td style={{ padding: 10 }}>{apontamento?.ReasonBounce ?? "--"}</td>
                                                            <td style={{ padding: 10 }}>{apontamento?.CountBounce ?? "--"}</td>
                                                            <td style={{ padding: 10 }}>{correct_date_result(apontamento?.DateLastBounce) ?? "--"}</td>
                                                        </tr>
                                                    ))}
                                                    {cheques(query as queriesAttributes)?.num === 0 &&
                                                        <tr>
                                                            <td colSpan={5} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>

                                    <Column style={{ fontSize: 12, marginTop: 16, marginBottom: -16 }}>
                                        <div style={{ fontWeight: 600 }}>Entenda o Score de crédito</div>
                                        <ul>
                                            <li>Pontuação baseada principalmente nos dados do cadastro positivo do consumidor</li>
                                            <li>Nota que vai de 300 a 1000. Quanto mais alto, maior a chance do consumidor pagar</li>
                                            <li>Banco de dados que vai além de atrasos em pagamentos, com mais de 100 atributos</li>
                                            <li>Análise profunda dos hábitos de pagamentos da pessoa e seu histórico de crédito</li>
                                        </ul>
                                    </Column>

                                </Column>
                            </BagPrint>
                            <BagPrint forceTheme="light" title="Endereços" description={`${addressHistoryCount(query as queriesAttributes)} ${addressHistoryCount(query as queriesAttributes) === 1 ? "registro foi encontrado" : "registros foram encontrados"} `} icon={<HiOutlineLocationMarker color={secondary["light"]} size={26} />}>
                                <Column style={{ gap: 10, padding: 16 }}>
                                    <Column>
                                        <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de endereços</div>
                                        <div style={{ fontSize: 13 }}>{addressHistoryCount(query as queriesAttributes) === 1 ? "Foi encontrado" : "Foram encontrados"} {addressHistoryCount(query as queriesAttributes)} {addressHistoryCount(query as queriesAttributes) === 1 ? "registro" : "registros"} de endereços</div>
                                    </Column>
                                    <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]} ` }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background["light"] }}>
                                                <tr>
                                                    <th style={{ padding: 10 }}>RUA</th>
                                                    <th style={{ padding: 10 }}>BAIRRO</th>
                                                    <th style={{ padding: 10 }}>CIDADE</th>
                                                    <th style={{ padding: 10 }}>CEP</th>
                                                    <th style={{ padding: 10 }}>ÚLTIMA OCORRÊNCIA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {addressHistoryCount(query as queriesAttributes) > 0 && query?.result_biro?.BestInfo?.AddressHistory?.Address?.map((address) => (
                                                    <tr>
                                                        <td style={{ padding: 10 }}>{address?.Street ?? "--"}</td>
                                                        <td style={{ padding: 10 }}>{address?.Neighborhood ?? "--"}</td>
                                                        <td style={{ padding: 10 }}>{address?.City ?? "--"}</td>
                                                        <td style={{ padding: 10 }}>{address?.PostalCode ?? "--"}</td>
                                                        <td style={{ padding: 10 }}>{correct_date_result(address?.DateLastSeen)}</td>
                                                    </tr>
                                                ))}
                                                {addressHistoryCount(query as queriesAttributes) === 0 &&
                                                    <tr>
                                                        <td colSpan={5} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </Column>
                                </Column>
                            </BagPrint>
                            {query?.type === "PF" &&
                                <BagPrint
                                    forceTheme="light"
                                    noPageBreak
                                    title={"Relacionamentos econômicos"}
                                    description={`${partneshipsCount(query as queriesAttributes)} ${partneshipsCount(query as queriesAttributes) === 1 ? "registro foi encontrado" : "registros foram encontrados"} `}
                                    icon={<RiSuitcaseLine color={secondary["light"]} size={26} />}
                                >
                                    <Column style={{ gap: 10, padding: 16 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Participações em empresas</div>
                                            <div style={{ fontSize: 13 }}>{partneshipsCount(query as queriesAttributes) === 1 ? "Foi encontrado" : "Foram encontrados"} {partneshipsCount(query as queriesAttributes)} {partneshipsCount(query as queriesAttributes) === 1 ? "registro" : "registros"} de participações em empresas</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]} ` }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background["light"] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>RAZÃO SOCIAL</th>
                                                        <th style={{ padding: 10 }}>CNPJ</th>
                                                        <th style={{ padding: 10 }}>PARTICIPAÇÃO</th>
                                                        <th style={{ padding: 10 }}>STATUS</th>
                                                        <th style={{ padding: 10 }}>RELACIONAMENTO</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>DATA DE INÍCIO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {partneshipsCount(query as queriesAttributes) > 0 && query?.result_biro?.EnterpriseData?.Partnerships?.Partnership?.map((partnership) => (
                                                        <tr>
                                                            <td style={{ padding: 10 }}>{partnership?.CompanyName ?? "--"}</td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap" }}>{maskFunctions.cnpj.mask(partnership?.CNPJ)}</td>
                                                            <td style={{ padding: 10 }}>{partnership?.PercentParticipation}%</td>
                                                            <td style={{ padding: 10 }}>{partnership?.CNPJStatus ?? "--"}</td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap" }}>{partnership?.RelationshipDescription ? partnership?.RelationshipDescription : "--"}</td>
                                                            <td style={{ padding: 10 }}>{correct_date_result(partnership?.DateStartPartner)}</td>
                                                        </tr>
                                                    ))}
                                                    {partneshipsCount(query as queriesAttributes) === 0 &&
                                                        <tr>
                                                            <td colSpan={6} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>
                                </BagPrint>
                            }
                            {query?.type === "PJ" &&
                                <BagPrint
                                    forceTheme="light"
                                    noPageBreak
                                    title={"Relacionamentos econômicos"}
                                    description={`${partnersCount(query as queriesAttributes)} ${partnersCount(query as queriesAttributes) === 1 ? "registro foi encontrado" : "registros foram encontrados"} `}
                                    icon={<RiSuitcaseLine color={secondary["light"]} size={26} />}
                                >
                                    <Column style={{ gap: 10, padding: 16 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Quadro Societário</div>
                                            <div style={{ fontSize: 13 }}>{partnersCount(query as queriesAttributes) === 1 ? "Foi encontrado" : "Foram encontrados"} {partnersCount(query as queriesAttributes)} {partnersCount(query as queriesAttributes) === 1 ? "registro" : "registros"} de sócios</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]} ` }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background["light"] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>NOME DO SÓCIO</th>
                                                        <th style={{ padding: 10 }}>CPF/CNPJ</th>
                                                        <th style={{ padding: 10 }}>PARTICIPAÇÃO</th>
                                                        <th style={{ padding: 10 }}>STATUS</th>
                                                        <th style={{ padding: 10 }}>RELACIONAMENTO</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {partnersCount(query as queriesAttributes) > 0 && query?.result_biro?.Partner?.Partnerships?.Partnership?.map((partnership) => (
                                                        <tr>
                                                            <td style={{ padding: 10 }}>{partnership?.Name ?? "--"}</td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap" }}>{maskFunctions.cpfOrCnpj.mask(partnership?.CpfCnpj)}</td>
                                                            <td style={{ padding: 10 }}>{partnership?.PercentParticipation}%</td>
                                                            <td style={{ padding: 10 }}>{partnership?.Status ?? "--"}</td>
                                                            <td style={{ padding: 10, whiteSpace: "nowrap" }}>{partnership?.RelationshipDescription ? partnership?.RelationshipDescription : "--"}</td>
                                                            <td style={{ padding: 10 }}>{correct_date_result(partnership?.DateReference)}</td>
                                                        </tr>
                                                    ))}
                                                    {partnersCount(query as queriesAttributes) === 0 &&
                                                        <tr>
                                                            <td colSpan={6} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>
                                </BagPrint>
                            }
                            <BagPrint
                                forceTheme="light"
                                title="Antecedentes judiciais"
                                description={`${lawSuit(query as queriesAttributes)?.num} ${lawSuit(query as queriesAttributes)?.num === 1 ? "registro foi encontrado" : "registros foram encontrados"} `}
                                icon={<VscLaw color={secondary["light"]} size={26} />}
                            >
                                <Column style={{ padding: 16, gap: 20 }}>
                                    <Column style={{ gap: 16 }} >
                                        <Column style={{ gap: 5 }}>
                                            <Column>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>Instâncias estaduais</div>
                                                <div style={{ fontSize: 15 }}>Cívil e criminal, na justiça estadual</div>
                                            </Column>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ background: secondary["light"], borderRadius: 4, fontSize: 12, padding: "4px 16px", color: "#FFF" }}>
                                                    {processEstaduais(query as queriesAttributes)?.length} {processEstaduais(query as queriesAttributes)?.length !== 1 ? "processos encontrados" : "processo encontrado"}
                                                </div>
                                            </div>

                                        </Column>
                                        <Line style={{ flexWrap: "wrap" }}>
                                            {states?.map((state) => (
                                                <Line style={{ alignItems: "center", gap: 5, minWidth: 50, maxWidth: 50, padding: 8, border: "1px solid #CCCCCC" }}>
                                                    <div style={{ minWidth: 8, maxWidth: 8, maxHeight: 8, minHeight: 8, borderRadius: "50%", background: processEstaduais(query as queriesAttributes)?.filter((process) => process?.State === state)?.length > 0 ? "#FF6B6B" : "#52C41A" }}></div>
                                                    <div style={{ fontSize: 12 }}>{state}</div>
                                                </Line>
                                            ))}
                                        </Line>
                                    </Column>
                                    <Column style={{ gap: 16 }} >
                                        <Column style={{ gap: 5 }}>
                                            <Column>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>Instância federal</div>
                                                <div style={{ fontSize: 15 }}>Cívil e criminal, nas seções estaduais da justiça</div>
                                            </Column>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ background: secondary["light"], borderRadius: 4, fontSize: 12, padding: "4px 16px", color: "#FFF" }}>
                                                    {processFederal(query as queriesAttributes)?.length} {processFederal(query as queriesAttributes)?.length !== 1 ? "processos encontrados" : "processo encontrado"}
                                                </div>
                                            </div>
                                        </Column>
                                        <Line style={{ flexWrap: "wrap" }}>
                                            {states?.map((state) => (
                                                <Line style={{ alignItems: "center", gap: 5, minWidth: 50, maxWidth: 50, padding: 8, border: "1px solid #CCCCCC" }}>
                                                    <div style={{ minWidth: 8, maxWidth: 8, maxHeight: 8, minHeight: 8, borderRadius: "50%", background: processFederal(query as queriesAttributes)?.filter((process) => process?.State === state)?.length > 0 ? "#FF6B6B" : "#52C41A" }}></div>
                                                    <div style={{ fontSize: 12 }}>{state}</div>
                                                </Line>
                                            ))}
                                        </Line>
                                    </Column>
                                    <Column style={{ gap: 16 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de processos</div>
                                            <div style={{ fontSize: 13 }}>Total de {lawSuit(query as queriesAttributes)?.num} {lawSuit(query as queriesAttributes)?.num === 1 ? "processo encontrado" : "processos encontrados"}</div>
                                        </Column>
                                        {query?.result_biro?.Negative?.LawSuitApontamentos?.map((law) => (
                                            <Column style={{ padding: 16, gap: 5, border: `1px solid ${borderColors["light"]} `, borderRadius: 4 }}>
                                                <Column style={{ gap: 5 }}>
                                                    <div style={{ fontSize: 15, fontWeight: 600 }}>{law?.ProcessType}</div>
                                                    <Line style={{ fontSize: 12, gap: 10, flexWrap: "wrap" }}>
                                                        <div style={{ background: secondary["light"], padding: "4px 16px", borderRadius: 4, color: "#FFF", whiteSpace: "nowrap" }}>
                                                            {law?.Vara}
                                                        </div>
                                                        {law?.Comarca && (
                                                            <div style={{ background: secondary["light"], padding: "4px 16px", borderRadius: 4, color: "#FFF", whiteSpace: "nowrap" }}>
                                                                {law?.Comarca}
                                                            </div>
                                                        )}
                                                    </Line>
                                                </Column>
                                                <FieldList
                                                    data={law}
                                                    listFields={ListFieldsLaw}
                                                    theme="light"
                                                    fontSize={13.5}
                                                />
                                            </Column>
                                        ))}
                                    </Column>
                                </Column>

                            </BagPrint>
                            <BagPrint
                                forceTheme="light"
                                title="Informações adicionais"
                                description={`Informações adicionais sobre o ${query?.type === "PF" ? "CPF" : "CNPJ"} informado.`}
                                icon={<BsFillInfoSquareFill color={secondary["light"]} size={26} />}
                            >
                                {query?.type === "PF" &&
                                    <Column style={{ gap: 8, padding: 16 }}>
                                        <div style={{ fontSize: 15, fontWeight: 600 }}>Outros nomes</div>
                                        <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]}` }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background["light"] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, width: "100%" }}>NOME</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.BestInfo?.PersonNameHistory?.Name?.map((name) => (
                                                        <tr>
                                                            <td style={{ padding: 10 }}>{name?.Name?.Full}</td>
                                                            <td style={{ padding: 10, textAlign: "center" }}>--</td>
                                                        </tr>
                                                    ))}
                                                    {!query?.result_biro?.BestInfo?.PersonNameHistory &&
                                                        <tr>
                                                            <td colSpan={2} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Column>
                                }
                                <Column style={{ gap: 8, padding: 16 }}>
                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Outros emails</div>
                                    <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]}` }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background["light"] }}>
                                                <tr>
                                                    <th style={{ padding: 10, width: "100%" }}>E-MAIL</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {query?.result_biro?.BestInfo?.EmailHistory && query?.result_biro?.BestInfo?.EmailHistory?.Email?.map((email) => (
                                                    <tr>
                                                        <td style={{ padding: 10 }}>{email?.Email}</td>
                                                        <td style={{ padding: 10, textAlign: "center" }}>{correct_date_result(email?.DateLastSeen)}</td>
                                                    </tr>
                                                ))}
                                                {!query?.result_biro?.BestInfo?.EmailHistory &&
                                                    <tr>
                                                        <td colSpan={2} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Column>
                                <Column style={{ gap: 8, padding: 16 }}>
                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Outros telefones</div>
                                    <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]}` }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background["light"] }}>
                                                <tr>
                                                    <th style={{ padding: 10, width: "100%" }}>NÚMERO</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {query?.result_biro?.BestInfo?.PhoneNumberHistory && query?.result_biro?.BestInfo?.PhoneNumberHistory?.PhoneNumber?.map((phone) => (
                                                    <tr>
                                                        <td style={{ padding: 10 }}>{maskFunctions.phone.mask(phone?.PhoneNumber)}</td>
                                                        <td style={{ padding: 10, textAlign: "center" }}>{correct_date_result(phone?.DateLastSeen)}</td>
                                                    </tr>
                                                ))}
                                                {!query?.result_biro?.BestInfo?.PhoneNumberHistory &&
                                                    <tr>
                                                        <td colSpan={2} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Column>
                                <Column style={{ gap: 8, padding: 16 }}>
                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Outros celulares</div>
                                    <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors["light"]}` }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background["light"] }}>
                                                <tr>
                                                    <th style={{ padding: 10, width: "100%" }}>NÚMERO</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {query?.result_biro?.BestInfo?.MobilePhoneNumberHistory && query?.result_biro?.BestInfo?.MobilePhoneNumberHistory?.MobilePhoneNumber?.map((phone) => (
                                                    <tr>
                                                        <td style={{ padding: 10 }}>{maskFunctions.phone.mask(phone?.PhoneNumber)}</td>
                                                        <td style={{ padding: 10, textAlign: "center" }}>{correct_date_result(phone?.DateLastSeen)}</td>
                                                    </tr>
                                                ))}
                                                {!query?.result_biro?.BestInfo?.MobilePhoneNumberHistory &&
                                                    <tr>
                                                        <td colSpan={2} style={{ padding: 10, textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>Nenhum registro</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Column>
                            </BagPrint>
                            <div style={{ fontSize: 12, marginTop: 16 }}>
                                Este relatório é feito por meio da coleta de informações disponíveis na internet e
                                bancos de dados de instituições públicas e privadas, não se responsabilizando o Max Locação,
                                pelo conteúdo das informações, nem garantindo-se que as pendências e informações apresentadas
                                representam a totalidade das mesmas. É estritamente confidencial e de uso exclusivo do usuário
                                do Max Locação, sendo proibida a sua reprodução, total ou parcial, bem como sua divulgação a
                                terceiros por qualquer forma. As decisões de negócios tomadas com base neste dossiê são de
                                inteira responsabilidade do usuário empresa concedente, estando o Max Locação totalmente
                                desonerada de responsabilidade por qualquer decisão ou efeito das mesmas.
                            </div>
                        </Column>
                    </Column>
                }
                {!loading && (!!query?.result_biro && !!query?.result_biro?.ErrorMessage || !(query?.type === "PJ" ? query?.result_biro?.BestInfo?.CompanyStatus !== "DESCONHECIDO" : true)) &&
                    <div style={{ display: "flex", padding: 40, paddingTop: 0, gap: 10, flexDirection: "column", flex: 1 }}>
                        <Column style={{ border: `1px solid ${borderColors.light}`, borderRadius: 4, background: primary.light }}>
                            <div style={{ fontSize: 16, fontWeight: 600, padding: 16, textTransform: "uppercase" }}>
                                Pessoa não encontrada pelo birô de crédito
                            </div>
                            <Column style={{ gap: 5, padding: 16, borderTop: `1px solid ${borderColors.light}` }}>
                                <FieldList
                                    data={query}
                                    listFields={ListFieldsHeader}
                                    theme="light"
                                    fontSize={13.5}
                                />
                            </Column>
                        </Column>
                        <span style={{ marginTop: 10 }}>Apesar do nosso robô de busca ser altamente eficiente, há casos em que não podemos fornecer informações:</span>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <b>Pessoas que recentemente completaram 18 anos:</b> como essas pessoas possuem poucas informações
                                financeiras associadas ao seu CPF, pode levar algum tempo para que as empresas criem um perfil de crédito.
                            </li>
                            <li>
                                <b>Imigrantes recém-chegados:</b> assim como as pessoas que acabaram de completar 18 anos e possuem poucas informações
                                financeiras disponíveis, os imigrantes recém-chegados também terão restrições de informação até que haja dados financeiros suficientes para fazer uma análise de crédito.
                            </li>
                            <li>
                                <b>Pessoas que solicitaram a exclusão de suas informações dos bancos de dados de birôs de crédito de acordo com a
                                    LGPD (Lei Geral de Proteção de Dados):</b> nesses casos, não será possível exibir essas informações.
                            </li>
                            <li>
                                <b>Pessoas com riscos associados à profissão:</b> para proteger a segurança de agentes de segurança, policiais,
                                investigadores, militares, entre outros, suas informações não serão divulgadas.
                            </li>
                            <li>
                                <b>Pessoas públicas:</b> como medida de segurança, informações de pessoas conhecidas publicamente,
                                como políticos, empresários, cantores, jogadores de futebol, entre outros, não serão divulgadas.
                            </li>
                            <li>
                                <b>CPF/CNPJ inexistente:</b> antes de realizar a consulta, é feita uma validação para verificar se
                                o CPF/CNPJ informado é válido. No entanto, é possível que o CPF/CNPJ seja válido, mas não exista nenhuma
                                pessoa física ou jurídica associada a ele. Nesses casos, é importante confirmar com o seu cliente se o CPF/CNPJ
                                fornecido está correto, para evitar possíveis erros ou fraudes.
                            </li>
                        </ul>
                    </div>
                }
                {loading &&
                    <div id="loading-content" style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <WaveLoading />
                    </div>
                }
            </Container>
            <ModalLoading
                loading={loading}
                theme="light"
            />
        </>
    )
}