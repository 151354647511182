import React, { useCallback, useEffect, useState } from "react"
import { useApi } from "../../hooks/api";
import { FaBuilding } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import { useGlobal } from "../../hooks/global";
import { useNavigate } from "react-router-dom";
import { useWallet } from "../../hooks/wallet";
import { Header } from "../../components/Header";
import { background, inactiveItens } from "../../theme";
import { franchiseProps } from "../../interfaces/index";
import { WaveLoading } from "../../components/Loadings/WaveLoading";
import { Form, CardFranchise, ButtonFranchise, ContainerFranchises } from "./style";
import { ModalFranchise } from "../../components/Modals/ModalFranchise";

export const ChooseFranchise: React.FC = () => {
    const navigate = useNavigate()

    const { api } = useApi()
    const { forceClearAll } = useWallet()
    const { theme, notify, changeFranchiseGlobal, setSeeNotification } = useGlobal()

    const [loading, setLoading] = useState(true)
    const [newFranchise, setNewFranchise] = useState<boolean>(false)
    const [franchises, setFranchises] = useState<franchiseProps[]>([])

    const searchFranchises = useCallback(async () => {
        setLoading(true)
        try {
            const result = await api.get("/franchises/all")
            setFranchises(result.data)
        } catch (err) {
            notify("Erro ao buscar imobiliárias!", "error")
        }
        setLoading(false)
    }, [])

    const onClickInFranchise = useCallback((newFranchise) => {
        changeFranchiseGlobal(newFranchise)
        setSeeNotification(true)
        navigate("/")
    }, [])

    const handleSaveFranchise = useCallback(async () => {
        setNewFranchise(false)
        searchFranchises()
    }, [])

    useEffect(() => {
        setSeeNotification(false)
        forceClearAll()
        searchFranchises()
    }, [])

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Header chooseFranchise />
                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 80, flex: 1, background: background[theme], minHeight: "calc(100 * var(--vh))" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", color: inactiveItens[theme], padding: 30, flex: 1, paddingLeft: 30, transition: "padding 0.3s" }}>
                            <Form theme={theme}>
                                <div style={{ fontSize: 20, fontWeight: 600, textAlign: "center", textTransform: "uppercase" }}>
                                    Escolha qual imobiliária deseja administrar
                                </div>
                                {loading &&
                                    <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <WaveLoading />
                                    </div>
                                }
                                {!loading && (
                                    <ContainerFranchises>
                                        {franchises.map((franchise) => (
                                            <CardFranchise onClick={() => onClickInFranchise(franchise)} theme={theme}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <FaBuilding size={40} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <div style={{ fontWeight: 600, textTransform: "uppercase", fontSize: 18 }}>{franchise?.registration_data?.fantasy_name?.toUpperCase()}</div>
                                                    <div style={{ fontSize: 14, opacity: 0.6, textTransform: "uppercase" }}>
                                                        {franchise.address?.city?.state?.initials ? (franchise.address?.city?.name?.toUpperCase() + " - " + franchise.address?.city?.state?.initials?.toUpperCase()) : "Não informado"}
                                                    </div>
                                                </div>
                                                <ButtonFranchise style={{ textTransform: "uppercase" }} theme={theme}>
                                                    Acessar
                                                </ButtonFranchise>
                                            </CardFranchise>
                                        ))}
                                        <CardFranchise onClick={() => setNewFranchise(true)} style={{ justifyContent: "center", alignItems: "center", gap: 10, textTransform: "uppercase", fontWeight: 600 }} theme={theme}>
                                            <BsPlusCircle size={40} />
                                            Nova imobiliária
                                        </CardFranchise>
                                    </ContainerFranchises>
                                )}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            {newFranchise && (
                <ModalFranchise
                    openModal
                    onCancel={() => setNewFranchise(false)}
                    onSave={handleSaveFranchise}
                />
            )}
        </>
    )
}