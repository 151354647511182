import styled from "styled-components";
import { divider } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Form = styled.form`
    display: flex;
    justify-content:space-between;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    flex: 1;
    overflow: auto;
`

export const ContainerButtons = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    border-top: 1px solid ${props => divider[props.theme as themeTypes]}
`