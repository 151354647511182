import { userTypes } from "../interfaces"

export const ALL_USERS: userTypes[] = ["root", "responsible", "manager", "analyst", "broker"]

export const STAFF_USERS: userTypes[] = ["root", "responsible", "manager", "analyst"]

export const MANAGERS_USERS: userTypes[] = ["root", "responsible", "manager"]

export const RESPONSIBLE_USERS: userTypes[] = ["root", "responsible"]

export const ROOT_USERS: userTypes[] = ["root"]

export const NO_ANALYST_USERS: userTypes[] = ["root", "responsible", "manager", "broker"]

export const NO_ROOT_NO_ANALYST_USERS: userTypes[] = ["responsible", "manager", "broker"]