import Button from "../../Buttons/Button";
import React, { useCallback, useRef, useState, useEffect, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { Timeline } from "../../Timeline";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { Container, TextEmphasis } from "./style";
import { ModalGeneric } from "../../ModalGeneric";
import { FaRegCheckCircle } from "react-icons/fa";
import { CircularProgress } from "@material-ui/core";
import { ModalResultQuery } from "../ModalQuery/Result";
import { FiAlertCircle, FiXCircle } from "react-icons/fi";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { maskFunctions } from "../../../services/maskServices";
import { DocReject } from "../../SvgComponents/Icons/DocReject";
import { valueIsEmpty } from "../../../services/generalServices";
import { DocPending } from "../../SvgComponents/Icons/DocPending";
import { borderColors, primary, secondary } from "../../../theme";
import { Column, ContainerFormModal, Line } from "../../../style";
import { GenericForm, IGenericFormRefProps } from "../../GenericForm";
import { DateResultProps, executorsProps, IGroupProps, queriesAttributes, ResultBiroProps, themeTypes } from "../../../interfaces";

interface IModalTestCreditPolicyProps {
    onCancel: Function
    openModal: boolean
    executors: executorsProps[]
    type: "PF" | "PJ"
    backgroundVisible?: boolean
    defaultQuery?: queriesAttributes
}

interface IApplyExecutorProps {
    executor: executorsProps
    value: ResultBiroProps
}

interface ICorrectValueProps {
    executor: any
    prop: string
}

interface IEvents {
    left: any;
    icon: any;
    right: any;
    type?: string
}

interface IGetFinalStatusProps {
    executors: executorsProps[]
    result_biro: ResultBiroProps | any
}

const correct_colors: any = {
    pending: "#FAAD14",
    approved: "#52C41A",
    reject: "#FF6B6B",
    rejected: "#FF6B6B"
}

const correct_background: any = {
    rejected: "#FDEAE8",
    approved: "#E6F7EB",
    pending: "#FFF7E6"
}

const correct_result: any = {
    rejected: (
        <>
            <FiXCircle size={24} />
            Consulta reprovada
        </>
    ),
    approved: (
        <>
            <FaRegCheckCircle size={24} />
            Consulta aprovada
        </>
    ),
    pending: (
        <>
            <FiAlertCircle size={24} />
            Consulta pendenciada
        </>
    )
}

export const ModalTestCreditPolicy: React.FC<IModalTestCreditPolicyProps> = (props) => {
    const { onCancel, openModal, backgroundVisible, executors, type, defaultQuery } = props

    const { theme } = useGlobal()
    const { consultsServices } = useApi()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        mode: "all"
    });

    const _form = watch()

    const [history, setHistory] = useState<IEvents[]>([])
    const [consultSelected, setConsultSelected] = useState<queriesAttributes | null>(defaultQuery ?? null)
    const [history_in_progress, setHistoryInProgress] = useState<boolean>(false)

    const scrollRef = useRef<HTMLDivElement>(null)
    const form_ref = useRef<IGenericFormRefProps>(null)

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "query_id",
                        label: "Consulta",
                        type: "select-single-cards-no-creatable",
                        required: true,
                        searchOptions: consultsServices.consults,
                        getIsDisabled: () => history_in_progress,
                        onClick: (value: any) => setConsultSelected(value ? { ...value.this } : null),
                        executeOnChange: async () => setHistory([])
                    }
                ]
            ]
        }
    ]

    const access_atribute = (value: any, id: string) => {
        const id_array = id.split(".")
        for (let i = 0; i < id_array.length; i++) value = value[id_array[i]]
        return value
    }

    const calcAge = (birthDate: any) => {
        if (!birthDate) return 0
        birthDate = new Date(`${birthDate}`)
        const now = (new Date()).getTime()
        birthDate = birthDate.getTime()
        return ((now - birthDate) / 31536000000).toFixed(2)
    }

    const approvedReturn = ({ name, value }: any) => {
        return ({
            message: (
                <span style={{ gap: 5 }}>
                    Consulta <span style={{ color: correct_colors.approved, fontWeight: 600 }}>
                        <FaRegCheckCircle
                            size={20}
                            color={correct_colors.approved}
                            style={{ marginRight: 5 }}
                        />
                        APROVADA
                    </span>
                    <span> no executor <span style={{ textDecoration: "underline" }}> {name}</span>: <b> {value} </b> </span>
                </span>
            ),
            error: false
        })
    }

    const rejectedOrPendingReturn = ({ name, value, action }: any) => {
        return ({
            message: (
                <span style={{ gap: 5 }}>
                    Consulta <span style={{ color: correct_colors[action], fontWeight: 600 }}>
                        {action === "pending" && (
                            <FiAlertCircle
                                size={20}
                                color={correct_colors[action]}
                                style={{ marginRight: 5 }}
                            />
                        )}
                        {action === "reject" && (
                            <FiXCircle
                                size={20}
                                color={correct_colors[action]}
                                style={{ marginRight: 5 }}
                            />
                        )}
                        {action === "reject" ? "REPROVADA" : "PENDENCIADA"}
                    </span>
                    <span> no executor <span style={{ textDecoration: "underline" }}> {name}</span>: <b> {value} </b> </span>
                </span>
            ), error: true
        })
    }

    const convertAge = (age: number) => {
        const years = Math.floor(age)
        const months = Math.floor((age - years) * 12)
        const days = Math.floor((age - years - months / 12) * 365)
        return `${years} ${years > 1 ? "anos" : "ano"}${months > 0 ? `, ${months} ${months > 1 ? "meses" : "mês"}` : ""}${days > 0 ? `, ${days} ${days > 1 ? "dias" : "dia"}` : ""}`
    }

    const applyExecutor = (props: IApplyExecutorProps) => {
        const { executor, value } = props

        try {
            let { init, end, id } = executor
            init = !valueIsEmpty(init) ? Number.parseInt(init) : null
            end = !valueIsEmpty(end) ? Number.parseInt(end) : null

            let comp_value = ["foundation", "BestInfo.Age"].includes(id) ? calcAge(access_atribute(value, id)) : (access_atribute(value, id) || 0)
            let is_in_interval = false

            if ((!init && init !== 0) && (!end && end !== 0)) is_in_interval = false
            else if (((end || end === 0) && (init || init === 0)) && (comp_value >= init && comp_value <= end)) is_in_interval = true
            else if ((end || end === 0) && (!init && init !== 0) && (comp_value <= end)) is_in_interval = true
            else if ((init || init === 0) && (!end && end !== 0) && (comp_value >= init)) is_in_interval = true
            else is_in_interval = false

            if (is_in_interval) return rejectedOrPendingReturn({
                name: executor.name,
                value: executor.mask === "currencyInt" ? maskFunctions.currency.mask(comp_value) : (["foundation", "BestInfo.Age"].includes(id) ? convertAge(comp_value) : comp_value),
                action: executor.action
            })
            else return approvedReturn({
                name: executor.name,
                value: executor.mask === "currencyInt" ? maskFunctions.currency.mask(comp_value) : (["foundation", "BestInfo.Age"].includes(id) ? convertAge(comp_value) : comp_value)
            })

        } catch (err) {
            if (!valueIsEmpty(executor.init) || !valueIsEmpty(executor.end)) return rejectedOrPendingReturn({
                name: executor.name,
                value: "Valor não informado",
                action: executor.action
            })
            else return approvedReturn({
                name: executor.name,
                value: "Valor não informado"
            })
        }
    }

    const correctValue = (props: ICorrectValueProps) => {
        const { executor, prop } = props
        if (executor.mask === "currencyInt") return maskFunctions.currencyInt.mask(executor[prop])
        else return executor[prop]
    }

    const correct_date_result = (date: DateResultProps) => {
        return new Date(Number.parseInt(date.Year), Number.parseInt(date.Month) - 1, Number.parseInt(date.Day))
    }

    const getFinalStatus = (props: IGetFinalStatusProps) => {
        const { executors, result_biro } = props
        let errors = []
        let deny = false

        for (let i = 0; i < executors.length; i++) {
            const result_executor = applyExecutor({
                executor: executors[i],
                value: result_biro
            })

            if (result_executor.error) {
                if (executors[i].action === "reject") deny = true
                errors.push(result_executor.message)
            }
        }
        if (errors.length > 0) return { status: deny ? "rejected" : "pending", errors }
        else return { status: "approved", errors }
    }

    const transformHistory = (executors: executorsProps[], consult: any, theme: themeTypes, _index?: number) => {

        if (typeof consult.result_biro === "string") consult.result_biro = JSON.parse(consult.result_biro)

        if (consult.result_biro?.BestInfo?.DateFoundation) {
            const foundation = correct_date_result(consult.result_biro.BestInfo.DateFoundation)
            consult.result_biro.foundation = foundation
        }

        if (consult.result_biro?.BestInfo.DOB) {
            const age = correct_date_result(consult.result_biro?.BestInfo.DOB)
            consult.result_biro.BestInfo.Age = age
        }

        let events: IEvents[] = [];
        events.push({ left: "Executor", icon: "Ação", right: "Análise", type: "title" })
        events = [...events, ...executors.map((executor: executorsProps) => {
            const left = (
                <div>
                    <span> {executor.name} </span>
                    {!valueIsEmpty(executor.init) && !valueIsEmpty(executor.end) && (
                        <>
                            entre
                            <TextEmphasis> {correctValue({ executor, prop: "init" })} </TextEmphasis>
                            e
                            <TextEmphasis> {correctValue({ executor, prop: "end" })} </TextEmphasis>
                        </>
                    )}
                    {!valueIsEmpty(executor.init) && valueIsEmpty(executor.end) && (
                        <>
                            a partir de
                            <TextEmphasis> {correctValue({ executor, prop: "init" })} </TextEmphasis>
                        </>
                    )}
                    {valueIsEmpty(executor.init) && !valueIsEmpty(executor.end) && (
                        <>
                            até
                            <TextEmphasis> {correctValue({ executor, prop: "end" })} </TextEmphasis>
                        </>
                    )}
                    {valueIsEmpty(executor.init) && valueIsEmpty(executor.end) && (
                        <>
                            <TextEmphasis> sem efeitos </TextEmphasis>
                        </>
                    )}
                </div>
            )
            let icon = executor.action === "reject" ? <DocReject theme={theme} size={24} /> : <DocPending theme={theme} size={24} />
            let right = (
                <>
                    {applyExecutor({
                        executor,
                        value: consult?.result_biro
                    }).message}
                </>
            )

            return { left, icon, right }
        })]
        events.push({
            left: <></>,
            icon: (
                <div style={{
                    display: "flex",
                    minWidth: "20vw",
                    borderRadius: 5,
                    gap: 5,
                    color: correct_colors[getFinalStatus({ executors, result_biro: consult?.result_biro }).status as any],
                    background: correct_background[getFinalStatus({ executors, result_biro: consult?.result_biro }).status as any],
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 20,
                    whiteSpace: "nowrap"
                }}>
                    {correct_result[getFinalStatus({
                        executors,
                        result_biro: consult?.result_biro
                    }).status]}
                </div>
            ),
            right: <></>,
            type: "result"
        })
        if (!valueIsEmpty(_index)) {
            const total = events.length
            events.splice((_index as number) + 1, events.length - (_index as number))
            events = events.map((event, i) => {
                if (i === _index && i !== total - 1) event.right = (
                    <div style={{ display: "flex", minHeight: 36, marginTop: -6, alignItems: "center" }}>
                        <CircularProgress variant="indeterminate" size={20} style={{ color: secondary[theme] }} />
                    </div>
                )
                return event
            })
        }

        return events
    }

    const getTitle = ({ type, defaultQuery }: any) => {
        if (defaultQuery?.id) return "Análise detalhada"
        else return "Teste de política de análise " + type
    }

    const getDescription = ({ consultSelected, type, defaultQuery }: any) => {
        let result_biro = {} as ResultBiroProps
        if (typeof consultSelected?.result_biro === "string") result_biro = JSON.parse(consultSelected?.result_biro as any)
        else result_biro = consultSelected?.result_biro

        const name = result_biro?.BestInfo?.PersonName?.Name?.Full ?? (result_biro?.BestInfo?.CompanyName ?? "NÃO INFORMADO")

        if (defaultQuery?.id) return `Passo a passo da análise de ${name}`
        else return "Verifique o funcionamento da sua política de análise " + type
    }

    const increaseIndex = useCallback(({ _index, executors, consultSelected, theme, max }: any) => {
        if (_index < max) {
            const new_history = [...transformHistory(executors, consultSelected, theme, _index + 1)]
            setHistory([...new_history])
            setTimeout(() => {
                increaseIndex({ _index: _index + 1, executors, consultSelected, theme, max })
            }, 500)
        } else {
            setHistoryInProgress(false)
        }
    }, [])

    const onSubmit = useCallback((data: any) => {
        if (data?.query_id?.this?.result_biro) {
            setHistoryInProgress(true)
            const historyTotal = transformHistory(executors, data?.query_id?.this, theme)
            increaseIndex({ _index: 0, executors, consultSelected: data?.query_id?.this, theme, max: historyTotal.length - 1 })
        }
    }, [executors, theme])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form])

    useEffect(() => {
        if (history.length > 0) {
            scrollRef.current?.scrollTo({
                top: scrollRef.current.scrollHeight,
                behavior: "smooth"
            })
        }
    }, [history, scrollRef])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>
                <ModalHeader
                    title={getTitle({ type, defaultQuery })}
                    subTitle={getDescription({ consultSelected, type, defaultQuery })}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContainerFormModal ref={scrollRef}>
                    {!defaultQuery?.id && (
                        <Column style={{ gap: 10 }}>
                            <Column>
                                <div style={{ fontWeight: 600, fontSize: 18 }}>
                                    Selecione uma Consulta Cadastral
                                </div>
                                <span>
                                    Selecione uma Consulta Cadastral já realizada, para testar a sua Política de Análise,
                                    esse teste não acarretará em novos custos, a consulta servirá apenas como base para atestar
                                    a eficácia da política e ajustes de eventuais inconsistências.
                                </span>
                            </Column>
                            <form onSubmit={handleStopPropagation}>
                                <GenericForm
                                    ref={form_ref}
                                    groups={groups}
                                    _form={_form}
                                    control={control}
                                    trigger={trigger}
                                    errors={errors}
                                    setValue={setValue}
                                    register={register}
                                />
                                <Line style={{ justifyContent: "flex-end", marginTop: 26 }}>
                                    <div>
                                        <Button
                                            type="submit"
                                            disabled={history_in_progress}
                                            style={{ textTransform: "uppercase", fontWeight: 600, gap: 10, alignItems: "center", padding: 10 }}
                                            background={secondary[theme]}
                                            color="#FFF"
                                            children={(
                                                <>
                                                    <BsFillLightningChargeFill size={20} color="#FFF" />
                                                    Testar política
                                                </>
                                            )}
                                        />
                                    </div>
                                </Line>
                            </form>
                        </Column>
                    )}
                    {_form.query_id?.value && history.length > 0 && (
                        <Column style={{ background: primary[theme], paddingTop: 20, borderRadius: 5, border: `1px solid ${borderColors[theme]}` }}>
                            <Timeline
                                center
                                noMaxWidth
                                events={history}
                            />
                        </Column>
                    )}
                    {consultSelected?.id && (
                        <ModalResultQuery
                            onCancel={() => setConsultSelected(null)}
                            openModal
                            backgroundVisible
                            defaultId={consultSelected?.id}
                        />
                    )}
                </ContainerFormModal>

            </Container>
        </ModalGeneric>
    )
}