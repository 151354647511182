import Button from "../../../Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { uniqueId } from "lodash";
import { info } from "../../../../theme";
import { useForm } from "react-hook-form";
import { Tooltip } from "../../../Tooltip";
import { useApi } from "../../../../hooks/api";
import { FiAlertCircle } from "react-icons/fi";
import { AddButton, Line } from "../../../../style";
import { useGlobal } from "../../../../hooks/global";
import { inactiveItens, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { maskFunctions } from "../../../../services/maskServices";
import { BsFillLightningChargeFill, BsTrash } from "react-icons/bs";
import { valueIsEmpty } from "../../../../services/generalServices";
import { ModalTestCreditPolicy } from "../../ModalTestCreditPolicy";
import { Column, Form, Content, ModalFooter, TextEmphasis } from "./style";
import { ContainerGroup, GroupTitle } from "../../../GenericForm/style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { credit_policiesAttributes, IGroupProps } from "../../../../interfaces";
import { executorActionsOptions, executorsOptions, personsTypesOptions } from "../../../../utils/options";

interface IFormCreditPolicyModalProps {
    onCancel: Function
    onSave: Function
    defaultData?: credit_policiesAttributes | null
    defaultCreditPolicy?: credit_policiesAttributes | undefined
    executors?: any[]
}

interface ICorrectValueProps {
    executor: any
    value: string
}

export const FormCreditPolicy: React.FC<IFormCreditPolicyModalProps> = (props) => {
    const { onCancel, onSave, defaultData, executors } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const _defaultData = { type: { value: "PF", label: "Pessoa Física" } }

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: defaultData?.id ? defaultData : _defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const generateExecutorGroup = (defaultId?: string) => {
        const _uniqueId = defaultId ? defaultId : uniqueId()

        const newOwnerGroup: IGroupProps = {
            name: "",
            label: "",
            uniqueId: _uniqueId,
            fields: [
                [
                    {
                        name: "executor_id-" + _uniqueId,
                        label: "Nome",
                        type: "select-fixed",
                        options: executorsOptions,
                        required: true,
                        isClearable: true,
                        getOptions: (data: any) => {
                            const policy_type = data?.type?.value
                            return executorsOptions.filter(({ value: { type } }) => ["PF_PJ", policy_type].includes(type))
                        }
                    },
                    {
                        name: "init-" + _uniqueId,
                        label: "De",
                        type: "input",
                        mask: "int",
                        validate: async (data: any) => {
                            let init = data
                            let end = watch("end-" + _uniqueId)

                            if (!valueIsEmpty(end) && !valueIsEmpty(init)) {
                                end = `${end}`.replace(/\D/g, "")
                                init = `${init}`.replace(/\D/g, "")

                                init = Number.parseInt(init)
                                end = Number.parseInt(end)

                                if (end <= init) return "O valor \"De\" deve ser menor que o valor \"Até\"."
                            }
                            if (valueIsEmpty(init) && valueIsEmpty(end)) return "Preencha o campo \"De\" ou \"Até\"."
                            return true
                        }
                    },
                    {
                        name: "end-" + _uniqueId,
                        label: "Até",
                        type: "input",
                        mask: "int"
                    },
                    {
                        name: "action-" + _uniqueId,
                        label: "Ação",
                        type: "select-fixed",
                        required: true,
                        isClearable: true,
                        options: executorActionsOptions
                    }
                ]
            ]
        }

        return newOwnerGroup
    }

    const [loadingCreate, setLoadingCreate] = useState(false)
    const [openModalTest, setOpenModalTest] = useState(false)
    const [executorsGroups, setExecutorsGroups] = useState<IGroupProps[]>(() => {
        if (executors && executors.length > 0) {
            const _executorsGroups: any[] = []

            const _defaultDataExecutors = executors ?? []
            _defaultDataExecutors.forEach((executor: any) => {
                _executorsGroups.push(generateExecutorGroup(executor.uniqueId))
            })

            return _executorsGroups
        }
        return [generateExecutorGroup()]
    })

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)
    const executors_refs = useRef<IGenericFormRefProps[]>([])

    const getExecutors = useCallback(() => {
        const _refs = executors_refs.current.filter((ref) => ref?.getForm())

        const executors = _refs.map((ref) => {
            const form = ref?.getForm()
            const _uniqueId = form?.uniqueId
            return {
                init: form?.["init-" + _uniqueId],
                end: form?.["end-" + _uniqueId],
                action: form?.["action-" + _uniqueId],
                ...form?.["executor_id-" + _uniqueId]
            }
        })

        return executors
    }, [executors_refs])

    const onSubmit = useCallback(async () => {
        setLoadingCreate(true)

        const _form_ref = form_ref.current?.getForm()

        _form_ref.executors = getExecutors()

        _form_ref.executors = JSON.stringify(_form_ref.executors)

        try {
            if (defaultData?.id) {
                await api.put(`/credit_policies/${defaultData.id}`, _form_ref)
                notify("Política de análise atualizada com sucesso.", "success")
            }
            else {
                await api.post("/credit_policies", _form_ref)
                notify("Política de análise cadastrada com sucesso.", "success")
            }
            onSave()
        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoadingCreate(false)

    }, [buttonSubmitRef, defaultData, form_ref])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const addExecutorGroup = useCallback(() => {
        const newOwnerGroup = generateExecutorGroup()
        setExecutorsGroups((prev) => {
            return [...prev, newOwnerGroup]
        })
    }, [uniqueId])

    const removeExecutorGroup = useCallback((index: number) => {
        setExecutorsGroups((prev) => {
            return prev.filter(({ uniqueId }, i) => {
                if (i !== index) return true
                else {
                    const fields = ["executor", "init", "end", "action"]
                    fields.forEach((field) => unregister(field + "-" + uniqueId))
                    return false
                }
            })
        })
    }, [])

    const correctValue = (props: ICorrectValueProps) => {
        const { executor, value } = props
        if (executor.mask === "currencyInt") return maskFunctions.currencyInt.mask(value)
        else return maskFunctions.int.mask(value)
    }

    const onChangeType = useCallback(async (data: any) => {
        const new_type = data?.value
        setExecutorsGroups((prev) => {

            prev = prev.filter(({ }, index) => {
                const executor = executors_refs?.current?.[index]?.getForm()
                const uniqueId = executor?.uniqueId
                const executor_type = executor?.["executor_id-" + uniqueId]?.type

                if (["PF_PJ", new_type].includes(executor_type)) return true
                else {
                    const fields = ["customer_id", "owner_type", "porcen"]
                    fields.forEach((field) => unregister(field + "-" + uniqueId))
                    return false
                }
            })

            if (prev.length === 0) prev.push(generateExecutorGroup())

            return [...prev]
        })
    }, [uniqueId, executorsOptions])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "name",
                        label: "Nome",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "type",
                        label: "Tipo",
                        type: "select-fixed",
                        required: true,
                        options: personsTypesOptions,
                        getIsDisabled: () => !!defaultData?.id,
                        executeOnChange: onChangeType
                    }
                ],
                [
                    {
                        name: "description",
                        label: "Descrição",
                        type: "textarea",
                        required: true
                    }
                ]
            ]
        }
    ]

    const handleTestPolicy = useCallback(async () => {
        const errorsKeys = Object.keys(errors)
        if (errorsKeys.length > 0) notify("Preencha todos os campos obrigatórios.", "alert")
        else setOpenModalTest(true)
    }, [errors])

    return (
        <>
            <Content>
                <Form onSubmit={handleStopPropagation} theme={theme}>

                    <GenericForm
                        ref={form_ref}
                        groups={groups}
                        _form={_form}
                        control={control}
                        errors={errors}
                        trigger={trigger}
                        setValue={setValue}
                        register={register}
                    />

                    <Column style={{ gap: 10, flex: 1 }}>
                        <Line>
                            <div>
                                <Button
                                    type="button"
                                    onClick={handleTestPolicy}
                                    style={{ textTransform: "uppercase", fontWeight: 600, gap: 10, alignItems: "center", padding: 10 }}
                                    background={secondary[theme]}
                                    color="#FFF"
                                    children={(
                                        <>
                                            <BsFillLightningChargeFill size={20} color="#FFF" />
                                            Testar política
                                        </>
                                    )}
                                />
                            </div>
                        </Line>

                        <ContainerGroup>
                            <GroupTitle>
                                Executores
                            </GroupTitle>
                            {executorsGroups.map((executor_group, index) => {

                                const { uniqueId } = executor_group

                                const init = _form?.["init-" + uniqueId]
                                const end = _form?.["end-" + uniqueId]
                                const action = _form?.["action-" + uniqueId]?.value
                                const executor = _form?.["executor_id-" + uniqueId]?.value
                                const name = _form?.["executor_id-" + uniqueId]?.label

                                return (
                                    <Line key={executor_group.uniqueId} style={{ gap: 10 }}>
                                        {executorsGroups.length > 1 && (
                                            <Line style={{ alignItems: "flex-start", marginTop: 24, minWidth: 36 }}>
                                                <Tooltip
                                                    onClick={() => removeExecutorGroup(index)}
                                                    style={{ height: 36, width: 36 }}
                                                    title="Excluir"
                                                    children={(
                                                        <div>
                                                            <BsTrash
                                                                color={inactiveItens[theme]}
                                                                size={20}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </Line>
                                        )}
                                        <Column style={{ gap: 20 }}>
                                            <GenericForm
                                                ref={(ref: any) => executors_refs.current[index] = ref}
                                                groups={[executor_group]}
                                                _form={_form}
                                                control={control}
                                                errors={errors}
                                                trigger={trigger}
                                                setValue={setValue}
                                                register={register}
                                            />
                                            <Line style={{ alignItems: "center", gap: 5 }}>
                                                <Tooltip placement="top" style={{ padding: 0, margin: 0 }} title="O que acontecerá!">
                                                    <Line style={{ justifyContent: "center", alignItems: "center" }}>
                                                        <FiAlertCircle color={info} size={18} />
                                                    </Line>
                                                </Tooltip>
                                                <span>
                                                    {(!valueIsEmpty(init) || !valueIsEmpty(end)) && name && action && (
                                                        <>
                                                            <TextEmphasis> {name} </TextEmphasis>
                                                            {!valueIsEmpty(init) && !valueIsEmpty(end) && (
                                                                <>
                                                                    entre
                                                                    <TextEmphasis> {correctValue({ executor, value: init })} </TextEmphasis>
                                                                    e
                                                                    <TextEmphasis> {correctValue({ executor, value: end })} </TextEmphasis>
                                                                </>
                                                            )}
                                                            {!valueIsEmpty(init) && !!valueIsEmpty(end) && (
                                                                <>
                                                                    a partir de
                                                                    <TextEmphasis> {correctValue({ executor, value: init })} </TextEmphasis>
                                                                </>
                                                            )}
                                                            {!!valueIsEmpty(init) && !valueIsEmpty(end) && (
                                                                <>
                                                                    até
                                                                    <TextEmphasis> {correctValue({ executor, value: end })} </TextEmphasis>
                                                                </>
                                                            )}
                                                            ira
                                                            {action === "pending" && (
                                                                <>
                                                                    <TextEmphasis> pendenciar </TextEmphasis> o
                                                                    cliente para uma aprovação/reprovação manual.
                                                                </>
                                                            )}
                                                            {action === "reject" && (
                                                                <>
                                                                    <TextEmphasis> rejeitar </TextEmphasis> o
                                                                    cliente automáticamente.
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {!((!valueIsEmpty(init) || !valueIsEmpty(end)) && name && action) && (
                                                        <TextEmphasis> Executor sem efeito </TextEmphasis>
                                                    )}
                                                </span>
                                            </Line>
                                        </Column>
                                    </Line>
                                )
                            })}
                            {executorsGroups.length === 0 &&
                                <div style={{ textAlign: "center", fontStyle: "italic" }}>
                                    Política de análise sem executores
                                </div>
                            }
                            <Line style={{ display: "flex" }}>
                                <AddButton noHaveDelete={!(executorsGroups.length > 1)} onClick={addExecutorGroup}>
                                    Incluir+
                                </AddButton>
                            </Line>
                        </ContainerGroup>

                    </Column>

                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />
                </Form>
            </Content>

            <ModalFooter theme={theme}>
                <Button
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Cancelar"
                />
                <Button
                    onClick={() => buttonSubmitRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"} style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Salvar"
                />
            </ModalFooter>

            {loadingCreate && (
                <ModalLoading
                    loading={loadingCreate}
                    theme={theme}
                />
            )}

            {openModalTest && (
                <ModalTestCreditPolicy
                    type={_form.type?.value}
                    executors={getExecutors()}
                    onCancel={() => setOpenModalTest(false)}
                    openModal
                    backgroundVisible
                />
            )}
        </>
    )
}
