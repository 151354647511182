import styled, { css } from "styled-components";
import { inactiveItens } from "../../../../theme";
import { themeTypes } from "../../../../interfaces";

export const StatusSubscription = styled.div<{ background: string }>`
    display: flex;
    color: #FFF;
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 4px;
    ${props => css`
        background:${props.background};
    `}
`

export const ContainerQrCode = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 531px) {
        flex-direction: column;
        align-items: center;
    }
`

export const ImageQrCode = styled.img`
    object-fit: cover;
    height: 200px;
    width: 200px;
    margin-top: -6px; 
    @media (max-width: 531px) {
        margin-top: 0px; 
    }
`

export const InputCopy = styled.input<{ theme: themeTypes }>`
    background: transparent;
    resize: none;
    border: none;
    width: 100%;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`