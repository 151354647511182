import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, inactiveItens, shadowColor, primary, secondary } from "../../../theme";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    min-width:80vw;
    max-width:80vw;
    min-height: calc(90 * var(--vh));
    max-height: calc(90 * var(--vh));
    outline: 0;
    box-shadow: 0.125rem 0.125rem 0.5rem ${props => shadowColor[props.theme as themeTypes]};
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const ContainerForm = styled.div<IThemeProps>`
    flex: 1;
    margin: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ${props => css`
        *{
            color:${inactiveItens[props.theme as themeTypes]};
            input{
                color:#000;
            }
        }
    `}
`

export const ContainerText = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
`

export const Title = styled.div<IThemeProps>`
    color:#595959;
    font-size:1.875rem;
`

export const Description = styled.div`
    color:#737373;
    padding-bottom: 20px;
    padding-top: 10px;
    text-align:center;
`

export const ContainerButton = styled.div`
    display:flex;
    flex-direction:row;
`

export const Button = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    color:#FFF;
    padding:8px;
    border-radius:0.3125rem;
    min-width:6.25rem;
    justify-content:center;
    margin-right:0.3125rem;
    cursor: pointer;
`

export const Card = styled.div<{ theme: themeTypes }>`
    display: flex;
    flex-direction:column;
    padding: 20px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 5px;
    ${props => css`
        box-shadow:0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
        background:${primary[props.theme as themeTypes]} ;
    `}
`

export const CircleStep = styled.div<{ theme: themeTypes, finish: boolean }>`
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    color:#FFF;
    align-items: center;
    font-size:26px;
    ${props => !props.finish && css`
        background:${props.theme === "light" ? "#E5E5E5" : "#2f2f2f"} ;
    `}
    ${props => props.finish && css`
        background:${secondary[props.theme as themeTypes]} ;
    `}
`

export const CardNameInitials = styled.div`
    display: flex;
    padding: 10px;
    width: 70px;
    font-size: 30px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #FFF;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    ${props => css`
        background: ${secondary[props.theme as themeTypes]};
    `}
`

export const Form = styled.form<IThemeProps>`
    display: flex;
    justify-content:space-between;
    flex-direction: column;
    gap: 20px;
`