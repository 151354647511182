import PaginationCustom from "../PaginationCustom";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Column } from "../../style";
import { HeaderList } from "../HeaderList";
import { ContainerPagination } from "./style";
import { DashboardPage } from "../DashboardPage";
import { ITableList, TableList } from "../TableList";
import { IActions, IFilters, themeTypes } from "../../interfaces";
import { SearchWithFilters, FiltersRef } from "../SearchWithFilters";

interface IPageList extends ITableList {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    numberOfItems: number
    actions?: IActions[]
    onClickOption?: (action_id: string) => void
    textHeader: string
    textItems: string
    theme: themeTypes
    initialFilters: IFilters[]
    updatedAt: any
    pages: number
    page: number
    search: Function
    onChangeFilters?: (filters: any) => void
    onChangeSearch?: (search: string) => void
}

export const PageList: React.FC<IPageList> = (props) => {
    const { search, onChangeFilters = () => { }, onChangeSearch = () => { } } = props

    const [selecteds, setSelecteds] = useState<string[]>([])
    const [searchString, setSearchString] = useState<string>("")
    const [controlSearch, setControlSearch] = useState<boolean>(false)

    const filter_ref = useRef<FiltersRef>(null)

    const handleChangeSelecteds = useCallback((selecteds: string[]) => {
        setSelecteds(selecteds)
        if (props.onChangeSelecteds) props.onChangeSelecteds(selecteds)
    }, [props])

    const handleSubmitFilters = useCallback(({ filters }: any) => {
        onChangeFilters(filters)
        search(1, { ...filters, search: filter_ref.current?.getSearch() ?? "" })
    }, [filter_ref])

    const handleChangeSearch = useCallback((value: string) => {
        setSearchString(value)
        return search(1, { ...filter_ref.current?.getFilters(), search: value })
    }, [filter_ref])

    useEffect(() => {
        onChangeSearch(searchString)
        setControlSearch((atual) => !atual)
    }, [searchString])

    const childrenPaginationCustom = (
        <ContainerPagination>
            <PaginationCustom
                {...props}
                disable={props.loading}
                onChange={(value: any) => search(value, { ...filter_ref.current?.getFilters(), search: searchString })}
            />
        </ContainerPagination>
    )

    const childrenHeaderList = (
        <HeaderList
            {...props}
            selecteds={selecteds}
            actions={props?.actions || []}
            onRefresh={() => search(1, { ...filter_ref.current?.getFilters(), search: searchString })}
        />
    )

    return (
        <DashboardPage>

            <Column style={{ flex: 1 }}>
                {controlSearch && childrenHeaderList}

                {!controlSearch && childrenHeaderList}

                <SearchWithFilters
                    {...props}
                    ref={filter_ref}
                    clickButtonSearch={(filters: any) => handleSubmitFilters({ filters })}
                    onChangeSearch={handleChangeSearch}
                />

                <TableList
                    {...props}
                    onChangeSelecteds={handleChangeSelecteds}
                />

            </Column>

            {controlSearch && childrenPaginationCustom}

            {!controlSearch && childrenPaginationCustom}

            {props.children}

        </DashboardPage>
    )
}