import styled, { css } from "styled-components";
import { Column, Line } from "../../../style";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, borderColors, divider, inactiveItens, shadowColor } from "../../../theme";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    outline: 0;
    min-width:100vw;
    max-width:100vw;
    min-height: calc(100 * var(--vh));
    max-height: calc(100 * var(--vh));
    box-shadow: 0.125rem 0.125rem 0.5rem ${props => shadowColor[props.theme as themeTypes]};
    @media (min-width: 550px) {
        min-width:80vw;
        max-width:80vw;
        min-height: calc(90 * var(--vh));
        max-height: calc(90 * var(--vh));
    }
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 30px;
    flex: 1;
    overflow: auto;
`

export const ContainerBottom = styled(Column)<IThemeProps>`
    gap: 20px;
    padding: 20px;
    border-top: 1px solid ${props => divider[props.theme as themeTypes]};
`

export const ContainerDebit = styled.div<IThemeProps>`
    display: flex;
    font-size: 13px;
    gap: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid ${props => borderColors[props.theme as themeTypes]};
`

export const ContainerMxsCoins = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`

export const ContainerIndicators = styled(Line)`
    flex-wrap: wrap;
    gap: 5px;
`

export const Indicator = styled(Line)`
    gap: 5px;
    align-items: center;
    min-width: 272px;
`

export const ContainerIcon = styled.div`
    display: flex;
    align-items: center;
`