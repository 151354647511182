import Cropper from "react-easy-crop";
import Button from "../../Buttons/Button";
import { useApi } from "../../../hooks/api";
import { FaUserCircle } from "react-icons/fa";
import { ModalHeader } from "../../ModalHeader";
import { ModalGeneric } from "../../ModalGeneric";
import { primary, secondary } from "../../../theme";
import { uuid } from "../../../services/generalServices";
import { fileProps, themeTypes } from "../../../interfaces";
import { useCallback, useEffect, useRef, useState } from "react";
import { IInputDocumentRefProps, InputDocument } from "../../Inputs/InputFile";
import {
  Container,
  ContainerButtons,
  ContainerCropper,
  Avatar,
  ContainerLogoDefault,
  Content,
  Img,
  ContainerAvatar,
} from "./style";
import { useGlobal } from "../../../hooks/global";
import { ModalLoading } from "../../Loadings/ModalLoading";
import { Line } from "../../../style";

interface IModalAvatarProps {
  theme: themeTypes;
  photo?: string | null;
  onClose: Function;
}

const initFile = (defaultValue: any) => {
  if (defaultValue) {
    if (typeof defaultValue === "object") return defaultValue;
    return {
      preview: `${process.env.REACT_APP_S3_URL}/${defaultValue}`,
      name: defaultValue,
      file: "arquivo",
    } as fileProps;
  } else return {} as fileProps;
};

const createImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });
};

function blobToFile(blob: Blob, fileName: string) {
  const file = new File([blob], fileName, { type: blob.type });

  return file;
}

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export default async function getCroppedImg(
  imageSrc: any,
  pixelCrop: any,
  rotation = 0
) {
  const image = (await createImage(imageSrc)) as any;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx?.translate(safeArea / 2, safeArea / 2);
  ctx?.rotate(getRadianAngle(rotation));
  ctx?.translate(-safeArea / 2, -safeArea / 2);

  ctx?.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx?.getImageData(0, 0, safeArea, safeArea) as any;

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx?.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob: any) => {
      const file = blobToFile(blob, uuid() + ".jpeg");
      resolve(file);
    }, "image/jpeg");
  });
}

export const ModalAvatar: React.FC<IModalAvatarProps> = (props) => {
  const { theme, photo, onClose } = props;

  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [file, setFile] = useState<fileProps>(initFile(photo));
  const [fileCropped, setFileCropped] = useState<fileProps>(initFile(photo));

  const inputFileRef = useRef<IInputDocumentRefProps>(null);

  const { notify } = useGlobal();
  const { api, updateUser } = useApi();

  const onCropComplete = useCallback(
    async (_, croppedAreaPixels) => {
      const canvas = (await getCroppedImg(
        file.preview,
        croppedAreaPixels
      )) as any;
      const preview = URL.createObjectURL(canvas);
      setFileCropped({
        id: uuid(),
        preview,
        name: canvas.name,
        url: null,
        file: canvas,
        readableSize: canvas.size,
        error: false,
        progress: 0,
        uploaded: false,
      });
    },
    [file]
  );

  const handleSave = useCallback(async () => {
    setLoading(true);
    const formData = new FormData();

    if (fileCropped.file && fileCropped.file !== "arquivo") {
      formData.append("photo", fileCropped.file);
    }

    try {
      await api.patch("/users", formData);

      await updateUser();

      notify("Foto de perfil atualizada com sucesso", "success");
    } catch (err) {
      notify("Erro ao atualizar foto de perfil", "error");
    }

    onClose();
  }, [fileCropped, api, updateUser, notify, onClose]);

  useEffect(() => setFileCropped(file), [file]);

  useEffect(() => {
    if (zoom < 0) setZoom(0);
  }, [zoom]);

  return (
    <ModalGeneric open onClose={() => onClose()}>
      <Container theme={theme}>
        <ModalHeader
          title="Foto de perfil"
          subTitle="Escolha uma foto de perfil para sua conta"
          onCancel={() => onClose()}
          theme={theme}
        />
        <Content>
          {[undefined, null, "arquivo"].includes(file.file) && (
            <ContainerAvatar>
              <Avatar theme={theme}>
                {file.preview && <Img src={file.preview} />}
                {!file.preview && (
                  <ContainerLogoDefault>
                    <FaUserCircle size={30} />
                  </ContainerLogoDefault>
                )}
              </Avatar>
            </ContainerAvatar>
          )}
          {![undefined, null, "arquivo"].includes(file.file) && (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: 20,
              }}
            >
              <Line
                style={{
                  alignItems: "center",
                  gap: 10,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  background="#FFF"
                  color={secondary[theme]}
                  borderColor={secondary[theme]}
                  children="-"
                  onClick={() => setZoom(zoom - 0.1)}
                  style={{ width: 40 }}
                />
                <Button
                  background={secondary[theme]}
                  color="#FFF"
                  borderColor={secondary[theme]}
                  children="+"
                  onClick={() => setZoom(zoom + 0.1)}
                  style={{ width: 40 }}
                />
              </Line>
              <ContainerCropper>
                <Cropper
                  image={file.preview}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                  cropSize={{ width: 250, height: 250 }}
                  showGrid={false}
                  style={{
                    containerStyle: { borderRadius: 5 },
                  }}
                />
              </ContainerCropper>
            </div>
          )}
          <div style={{ display: "none" }}>
            <InputDocument
              ref={inputFileRef}
              onChange={(e) => setFile(e)}
              fileTypes={["jpeg", "png"]}
            />
          </div>
          <ContainerButtons>
            <div style={{ flex: 1 }}>
              <Button
                background={primary[theme]}
                color={secondary[theme]}
                borderColor={secondary[theme]}
                onClick={() => inputFileRef.current?.focus()}
                children="Escolher outra"
                style={{ whiteSpace: "nowrap", minWidth: 170 }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Button
                background={secondary[theme]}
                borderColor={secondary[theme]}
                color="#FFF"
                onClick={handleSave}
                children="Salvar alterações"
                style={{ whiteSpace: "nowrap", minWidth: 170 }}
              />
            </div>
          </ContainerButtons>
        </Content>
      </Container>
      <ModalLoading loading={loading} theme={theme} />
    </ModalGeneric>
  );
};
