import React, { useCallback, useEffect, useState } from "react"
import { Container } from "./style";
import { FormPerson } from "./form";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { mapDefaultForm, personDefaultForm } from "../../../utils/defaultFormsUtils";

interface IModalPersonProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
    enabled_change_form_type?: boolean
    isPersonInPerson?: boolean
    personType?: "spouse" | "person" | "representative" | "responsible"
    relation?: string
}

const texts: any = {
    "spouse": {
        "title": {
            "new": "Cadastro de Cônjuge",
            "edit": "Informações do Cônjuge"
        },
        "subTitle": {
            "new": "Informe os dados do cônjuge",
            "edit": "Segue abaixo as informações do cônjuge"
        }
    },
    "person": {
        "title": {
            "new": "Cadastro de Pessoa",
            "edit": "Informações da Pessoa"
        },
        "subTitle": {
            "new": "Informe os dados da pessoa",
            "edit": "Segue abaixo as informações da pessoa"
        }
    },
    "representative": {
        "title": {
            "new": "Cadastro de Representante",
            "edit": "Informações do Representante"
        },
        "subTitle": {
            "new": "Informe os dados do representante",
            "edit": "Segue abaixo as informações do representante"
        }
    },
    "responsible": {
        "title": {
            "new": "Cadastro de Responsável",
            "edit": "Informações do Responsável"
        },
        "subTitle": {
            "new": "Informe os dados do responsável",
            "edit": "Segue abaixo as informações do responsável"
        }
    }
}

export const ModalPerson: React.FC<IModalPersonProps> = (props) => {
    const { personType = "person", relation, enabled_change_form_type = false, onCancel, onSave, openModal, backgroundVisible, defaultId, isPersonInPerson } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [defaultData, setDefaultData] = useState<any>(null)
    const [defaultPerson, setDefaultPerson] = useState<any>(null)

    const searchPerson = useCallback(async () => {

        if (defaultId) {

            setLoading(true)

            try {

                const { data } = await api.get(`/customers/${defaultId}`)

                const _data: any = mapDefaultForm({ data, fields: personDefaultForm })

                setDefaultPerson({ ...data })

                setDefaultData({ ..._data })

            } catch (err) {

                notify("Erro ao buscar pessoa", "error")

                onCancel()
            }

        }

        setLoading(false)

    }, [defaultId])

    useEffect(() => { searchPerson() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>
                <ModalHeader
                    title={texts[personType].title[defaultId ? "edit" : "new"]}
                    subTitle={texts[personType].subTitle[defaultId ? "edit" : "new"]}
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    <FormPerson
                        onSave={onSave}
                        onCancel={onCancel}
                        personType={personType}
                        defaultData={defaultData}
                        defaultPerson={defaultPerson}
                        isPersonInPerson={isPersonInPerson}
                        enabled_change_form_type={enabled_change_form_type}
                        relation={relation}
                    />
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    )
}