import styled, { css } from "styled-components";
import { fail } from "../../../theme";
import { Column } from "../../../style";
import { themeTypes } from "../../../interfaces";

interface dropContainerProps {
  isDragActive: boolean;
  isDragReject: boolean;
  isDragError: boolean;
  theme: themeTypes
}

interface uploadMessageProps {
  type?: string;
}

const dragActive = css`
  border-color: #2684FF;
`;

const dragError = css`
  border-color: ${fail};
`;

export const DropContent = styled.div.attrs({ className: "dropzone" }) <dropContainerProps>`
  border: 1px dashed ${props => props.theme === "light" ? "#cccccc" : "rgb(68 68 68)"};
  display:flex;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  align-items:center;
  transition: height 0.2s ease;
  ${props => props.isDragError && dragError};
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragError};
`;

const messageColors: any = {
  default: "#999",
  error: fail,
  success: "#2684FF"
};

export const UploadMessage = styled.div<uploadMessageProps>`
  display: flex;
  width:100%;
  overflow: hidden;
  padding-left:10px;
  font-size: 12px;
  color: ${props => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  :hover{
    opacity:0.8;
  }
`;

export const DropContainer = styled(Column)`
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`