import React from 'react'
import { GlobalProvider } from './global';
import { ProposalChatProvider } from './proposals';
import { WalletProvider } from './wallet';
import { ApiProvider } from './api';

const AppProvider: React.FC = ({ children }) => {
    return (
        <GlobalProvider>
            <ApiProvider>
                <ProposalChatProvider>
                    <WalletProvider>
                        {children}
                    </WalletProvider>
                </ProposalChatProvider>
            </ApiProvider>
        </GlobalProvider>
    )
}

export default AppProvider;