import styled, { css } from "styled-components";

export const StatusSubscription = styled.div<{ background: string }>`
    display: flex;
    color: #FFF;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    ${props => css`
        background:${props.background};
    `}
`