import styled, { css } from "styled-components";
import { primary, shadowColor } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Column = styled.div`
    display:flex;
    flex-direction:column;
`

export const Card = styled.div<IThemeProps>`
    display: flex;
    flex-direction:column;
    padding: 20px;
    border-radius: 5px;
    ${props => css`
        box-shadow:0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
        background:${primary[props.theme as themeTypes]} ;
    `}
`