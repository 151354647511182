import React from "react"

export const RemoveSign = ({ size, color, ...props }: any) => (
  <svg
    width={20}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 7h-4a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2Zm-7.7 1.72A4.92 4.92 0 0 0 13 5 5 5 0 0 0 3 5a4.92 4.92 0 0 0 1.7 3.72A8 8 0 0 0 0 16a1 1 0 1 0 2 0 6 6 0 1 1 12 0 1 1 0 0 0 2 0 8 8 0 0 0-4.7-7.28ZM8 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
      fill={color}
    />
  </svg>
)
