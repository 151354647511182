import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, inactiveItens, shadowColor } from "../../../theme";

export const Container = styled.div<IThemeProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.3125rem;
  outline: 0;
  width: 100vw;
  min-height: calc(100 * var(--vh));
  max-height: calc(100 * var(--vh));
  box-shadow: 0.125rem 0.125rem 0.5rem
    ${(props) => shadowColor[props.theme as themeTypes]};
  ${(props) => css`
    background: ${background[props.theme as themeTypes]};
    color: ${inactiveItens[props.theme as themeTypes]};
  `}
  * {
    font-family: "Poppins" !important;
  }
  @media (min-width: 550px) {
    min-width: 500px;
    max-width: 500px;
    min-height: calc(80 * var(--vh));
    max-height: calc(80 * var(--vh));
  }
`;

export const ContainerAvatar = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Avatar = styled.div<{ theme: themeTypes }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 270px;
  max-width: 270px;
  max-height: 270px;
  min-height: 270px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${(props) => inactiveItens[props.theme as themeTypes]};
`;

export const Img = styled.img`
  min-width: 270px;
  min-height: 270px;
`;

export const ContainerLogoDefault = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  padding: 30px;
`;

export const ContainerCropper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;
