import React, { useCallback, useEffect, useState } from "react";
import { PaginationComponent } from "./style";
import { useGlobal } from "../../hooks/global";

interface PaginationProps {
    pages: number;
    page: number;
    disable: boolean;
    onChange: Function;
}

const PaginationCustom: React.FC<PaginationProps> = (props, ref) => {
    const { pages, page, disable, onChange } = props

    const { theme } = useGlobal()

    const [controlRender, setControlRender] = useState(false)

    const clickInPagination = useCallback((event) => {

        let attribute = "-1"
        let newPage = -1

        const tagName = event.target.tagName

        if (tagName === "LI") return 0;
        else if (tagName === "path") attribute = event.target.getAttribute("d").split(" ")[0]
        else if (tagName === "svg") attribute = event.target.getElementsByTagName("path")[0].getAttribute("d").split(" ")[0]
        else if (tagName === "BUTTON") {
            if (event.target.getElementsByTagName("path").length > 0) attribute = event.target.getElementsByTagName("path")[0].getAttribute("d").split(" ")[0]
            else newPage = event.target.innerText * 1
        }

        if (attribute !== "-1") newPage = page + (attribute === "M10" ? 1 : -1)

        if (newPage !== -1) onChange(newPage)

    }, [page])

    useEffect(() => { setControlRender((atual) => !atual) }, [page])

    return (
        <>
            {controlRender &&
                <PaginationComponent
                    theme={theme}
                    ref={(ref: any) => ref = ref}
                    color="primary"
                    shape="round"
                    size="small"
                    disabled={disable}
                    onClick={clickInPagination}
                    page={page}
                    count={pages}
                />
            }
            {!controlRender &&
                <PaginationComponent
                    theme={theme}
                    ref={(ref: any) => ref = ref}
                    color="primary"
                    shape="round"
                    size="small"
                    disabled={disable}
                    onClick={clickInPagination}
                    page={page}
                    count={pages}
                />
            }
        </>
    )
}

export default PaginationCustom