import styled, { css } from "styled-components";
import { borderColors } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const StatusSubscription = styled.div<{ background: string }>`
    display: flex;
    color: #FFF;
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 4px;
    ${props => css`
        background:${props.background};
    `}
`

export const ContainerTable = styled.div<IThemeProps>`
    margin: 20px;
    font-size: 13.5px;
    border-radius: 4px;
    overflow: auto;
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]};
    `}
`