import React from "react";
import Countdown from "react-countdown";
import { Receipt } from "../Receipt";
import { FiClock, FiInfo } from "react-icons/fi";
import { Column, Line } from "../../../../style";
import { useGlobal } from "../../../../hooks/global";
import { getStatus } from "../../../../utils/columnsTables";
import { maskFunctions } from "../../../../services/maskServices";
import { ContainerQrCode, ImageQrCode, InputCopy } from "./style";
import { themeTypes, transactionsAttributes } from "../../../../interfaces";
import { borderColors, fail, info, primary, secondary, success } from "../../../../theme";

interface IReceiptProps {
    theme: themeTypes
    transaction: transactionsAttributes | null
    refresh: () => void
}

const status_of_transaction: any = {
    "new": {
        color: info,
        text: "Aguardando pagamento"
    },
    "waiting": {
        color: info,
        text: "Aguardando pagamento"
    },
    "identified": {
        color: info,
        text: "Aguardando pagamento"
    },
    "approved": {
        color: info,
        text: "Aguardando pagamento"
    },
    "paid": {
        color: success,
        text: "Pago"
    },
    "paid-free": {
        color: success,
        text: "Pago"
    },
    "unpaid": {
        color: fail,
        text: "Não pago"
    },
    "refunded": {
        color: fail,
        text: "Devolvido"
    },
    "contested": {
        color: fail,
        text: "Contestado"
    },
    "canceled": {
        color: fail,
        text: "Cancelado"
    },
    "settled": {
        color: success,
        text: "Pago"
    },
    "link": {
        color: info,
        text: "Aguardando pagamento"
    },
    "expired": {
        color: fail,
        text: "Expirado"
    }
}

const msToTime = (duration: number) => {
    let seconds: any = parseInt(((duration / 1000) % 60) + "", 10)
    let minutes: any = parseInt(((duration / (1000 * 60)) % 60) + "", 10)
    let hours: any = parseInt(((duration / (1000 * 60 * 60)) % 24) + "", 10)

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + "h" + " : " + minutes + "m" + " : " + seconds + "s";
}

const hoursToMs = (hours: number) => {
    return hours * 60 * 60 * 1000
}

export const ReceiptQRCode: React.FC<IReceiptProps> = (props) => {
    const { theme, transaction, refresh } = props

    const { notifyBottom } = useGlobal()

    const copyLink = ({ _id, text }: { _id: string, text: string }) => {
        try {
            const linkText: any = document.getElementById(_id)
            linkText.select();
            const success = document.execCommand("copy");
            linkText.selectionEnd = 0
            if (success) return notifyBottom(text + " copiado para área de tranferência!", "success");
            else throw new Error();
        } catch (err) {
            notifyBottom("Erro ao copiar o " + text.toLocaleLowerCase() + " para área de tranferência!", "error");
        }
    }

    return (
        <>
            <Receipt theme={theme} transaction={transaction} />
            <Column style={{ border: `1px solid ${borderColors[theme]}`, borderRadius: 5, margin: 20, marginTop: 0, padding: 20, gap: 20, background: primary[theme] }}>
                <div style={{ fontWeight: 600 }}>Escaneie o QR code ou copie a linha editável</div>
                <ContainerQrCode>
                    <Column style={{ justifyContent: "space-between", gap: 20 }}>
                        <Column>
                            <ol style={{ margin: 0, padding: 0, marginLeft: 16 }}>
                                <li style={{ fontSize: 16 }}>Acesse seu Internet Banking ou app de pagamentos.</li>
                                <li style={{ fontSize: 16 }}>Escolha pagar via Pix.</li>
                                <li style={{ fontSize: 16 }}>Escaneie o QR code ou copie o código.</li>
                            </ol>

                            <Line style={{ alignItems: "center", color: "#E20055", gap: 5, marginLeft: -5 }}>
                                <FiInfo size={15} />
                                <div style={{ fontSize: 14 }}>Pague e será creditado na hora</div>
                            </Line>
                        </Column>
                    </Column>
                    <ImageQrCode src={transaction?.qrcode ?? ""} />
                </ContainerQrCode>

                <Column style={{ gap: 5, marginTop: -10 }}>
                    <Line style={{ gap: 5, alignItems: "center" }}>
                        <div style={{ fontWeight: 600 }}>{maskFunctions.currency.mask(transaction?.value)}</div>
                        {getStatus({ ...status_of_transaction[transaction?.status ?? ""] })}
                    </Line>
                    <Line style={{ alignItems: "center", color: "#FAAD14", gap: 5 }}>
                        <FiClock size={15} />
                        <Line style={{ fontSize: 14, gap: 5 }}>
                            Você têm
                            <Countdown
                                date={(new Date(transaction?.createdAt ?? "")).getTime() + hoursToMs(1)}
                                intervalDelay={0}
                                precision={3}
                                onComplete={() => refresh()}
                                renderer={props => <b style={{ fontSize: 14 }}>{msToTime(props.total)}</b>}
                            />
                            para pagar
                        </Line>
                    </Line>
                </Column>

                <Column style={{ gap: 10, justifyContent: "center", alignItems: "center" }}>
                    <Line style={{ border: `1px solid ${borderColors[theme]}`, borderRadius: 5, padding: 10, gap: 20, background: primary[theme], width: "100%" }}>
                        <InputCopy id="link-qr-code" readOnly value={`${transaction?.qrcode_copy_and_paste ?? ""}`} />
                        <div onClick={() => copyLink({ _id: "link-qr-code", text: "QR Code" })} style={{ fontWeight: 600, color: secondary[theme], cursor: "pointer" }}>Copiar</div>
                    </Line>
                </Column>


            </Column>
        </>
    )
}