import styled, { css, keyframes } from "styled-components";
import { secondary } from "../../../theme";
import { themeTypes } from "../../../interfaces";

interface LineProps {
  duration: number
  theme: themeTypes
}

const FadeInAnimation = keyframes`  
  0% { height: 10px; }
  50% { height: 100px; }
  100% { height: 10px; }
`;

export const Line = styled.div<LineProps>`
     width: 10px;
     border-radius: 5px;
     ${props => css`
        background: ${secondary[props.theme as themeTypes]};
        animation: ${FadeInAnimation} 1s linear infinite ;
        animation-delay:${props.duration}s;
     `}
`