import { info, secondary, success } from "../theme";
import { ITagList } from "../interfaces";
import { pendencies } from "./proposal_status";
import { correctName } from "../services/generalServices";

const get_first_name = (broker: any) => {
    return broker.registration_data?.name || ''
}

export const getBroker: React.FC<ITagList> = ({ person, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5, justifyContent: "center" }}>
            {person.broker &&
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>
                        {get_first_name(person.broker)}
                    </div>
                </div>
            }
            {!person.broker &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const getFranchise: React.FC<ITagList> = ({ person, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5, justifyContent: "center" }}>
            {person.franchise &&
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>{get_first_name(person.franchise)}</div>
                </div>
            }
            {!person.franchise &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const getAnalyst: React.FC<ITagList> = ({ proposal, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 5 }}>
            {proposal.analyst &&
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>{get_first_name(proposal?.analyst)}</div>
                </div>
            }
            {!proposal.analyst &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const getPerson: React.FC<ITagList> = ({ person, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 5 }}>
            {person.person &&
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: "#FFF", background: secondary[theme], fontSize: 12 }}>{get_first_name(person?.person)}</div>
                </div>
            }
            {!person.person &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const getAuthor: React.FC<ITagList> = ({ person, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 5 }}>
            {person.person &&
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>{get_first_name(person?.person)}</div>
                </div>
            }
            {!person.person &&
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>
                        Imobiliária
                    </div>
                </div>
            }
        </div >
    )
}

export const getTenants: React.FC<ITagList> = ({ proposal, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {proposal.consults?.map((owner: any) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], color: "#FFF", fontWeight: 600, fontSize: 12 }}>
                            {correctName(owner.customer?.registration_data as any)}
                        </div>
                    </div>
                )
            })}
            {proposal.consults?.length === 0 &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const getOwners: React.FC<ITagList> = ({ proposal, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {proposal.property && proposal.property.owners.map((owner: any) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], color: "#FFF", fontWeight: 600, fontSize: 12 }}>
                            {correctName(owner.customer?.registration_data as any)}
                        </div>
                    </div>
                )
            })}
            {(!proposal.property || (proposal.property && proposal?.property?.owners.length === 0)) &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const getStatus: React.FC<ITagList> = ({ color, text }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textTransform: "uppercase" }}>
            <div style={{ whiteSpace: "nowrap", color: color, border: `1px solid ${color}`, padding: "5px 10px", fontSize: 12, borderRadius: 4, fontWeight: 600 }}>
                {text}
            </div>
        </div>
    )
}

export const getIndicator: React.FC<ITagList> = ({ proposal }) => {
    return (
        <div style={{ background: pendencies[proposal.status] === "PC" ? success : info, width: 10, height: 30 }}></div>
    )
}