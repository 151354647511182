import React, { useCallback, useState } from "react";
import Input from "../../../../Inputs/Input";
import Button from "../../../../Buttons/Button";
import { Modal } from "@material-ui/core";
import { TiDelete } from "react-icons/ti";
import { useGlobal } from "../../../../../hooks/global";
import { inactiveItens, secondary } from "../../../../../theme";
import { Container, ContainerText, Description, Title } from "./style";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";

interface IModalClickSignConfirmCancel {
    open: boolean
    loading: boolean
    onCancel: Function
    onConfirm: Function
}

export const ModalClickSignConfirm: React.FC<IModalClickSignConfirmCancel> = (props) => {
    const { open, onCancel, onConfirm, loading } = props

    const { theme } = useGlobal()

    const [textConfirm, setTextConfirm] = useState("")

    const handleConfirm = useCallback(() => {
        onConfirm()
        onCancel()
    }, [])

    return (
        <Modal open={open} onClose={() => onCancel()} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Container theme={theme}>
                <ContentOrLoading loading={loading} theme={theme}>
                    <ContainerText>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            <Title theme={theme}>
                                Você tem certeza disso?
                            </Title>
                            <div onClick={() => onCancel()} style={{ cursor: "pointer" }}>
                                <TiDelete
                                    color={theme === "dark" ? "#FFF" : inactiveItens.light}
                                    size={20}
                                />
                            </div>
                        </div>
                        <Description>
                            Ao optar por alternar entre o uso de sua própria conta da ClickSign e a conta da Max Locação na
                            plataforma, você deverá concordar em:
                            <ul>
                                <li>
                                    Cancelar todos os documentos eletrônicos que estiverem
                                    aguardando assinatura
                                </li>
                                <li>
                                    Remover todos os modelos de documentos eletrônicos que possuir na
                                    Max Locação
                                </li>
                            </ul>
                            As ações acima garantirão a integridade do processo e evitarão quaisquer conflitos de
                            interesse ou duplicidades de documentos.
                            Portanto, antes de alternar entre as contas:
                            <ul>
                                <li>
                                    Verifique se todos os documentos foram devidamente concluídos, caso contrário, considere concluir os documentos
                                    eletrônicos ainda em aberto
                                </li>
                                <li>
                                    Salve localmente os seus modelos de documentos eletrônicos, para recriá-los na Max Locação
                                </li>
                            </ul>
                            Vale ressaltar que documentos eletrônicos já finalizados poderão ser acessados normalmente
                            na Max Locação, independentemente da conta utilizada para assiná-los.
                        </Description>
                    </ContainerText>
                    <div style={{ display: "flex", gap: 5, flexDirection: "column", width: "100%", marginBottom: 10 }}>
                        <div>Por favor digite <b>CONFIRMAR</b> para efetuar a alternância da conta</div>
                        <Input
                            onChange={(value: any) => setTextConfirm(value)}
                            style={{ textTransform: "uppercase" }}
                        />
                    </div>
                    <Button
                        disabled={textConfirm.toUpperCase() !== "CONFIRMAR"}
                        background={secondary[theme]}
                        color="#FFF"
                        style={{ width: "100%", fontSize: 16 }}
                        onClick={handleConfirm}
                        children="Eu entendo as consequências, efetuar a alternância da conta"
                    />
                </ContentOrLoading>
            </Container>
        </Modal>
    )
}