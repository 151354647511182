import styled, { css } from "styled-components";
import { themeTypes } from "../../interfaces";
import { borderDisabled, disabledItems, inactiveItens, primary } from "../../theme";

export const ContainerPage = styled.div<{ theme: themeTypes, menuOpen?: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px;
    padding-bottom: 30px;
    flex: 1;
    transition: padding 0.3s;
    ${props => css`
        @media(min-width: 550px){
            padding-left: ${props.menuOpen ? 150 : 30}px;    
        }
        color: ${inactiveItens[props.theme as themeTypes]};
        .form-control{
            background-color: ${primary[props.theme as themeTypes]};
            color: ${props.theme === "light" ? "#000" : inactiveItens.dark};
            border-color: ${props.theme === "light" ? "#CCCCCC" : "rgb(68 68 68)"};
            :disabled{
                background-color: ${disabledItems[props.theme as themeTypes]};
                color: #999999;
                border-color: ${borderDisabled[props.theme as themeTypes]};
            }
        }
    `}
`