import Button from "../../../Buttons/Button";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Column } from "../../../../style";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { IGroupProps } from "../../../../interfaces";
import { inactiveItens, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ContainerButtons, ContainerForm, Form } from "./style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";

interface IFormModalChangeAnalystModalProps {
    onCancel: Function
    onSave: Function
    defaultData: any
    defaultProposalId: string
}

const getDefaultData = (defaultData: any) => {
    const _defaultData: any = {}

    if (defaultData?.broker) {
        _defaultData["brokerId"] = {
            label: defaultData?.broker?.registration_data?.name,
            value: defaultData?.broker?.id,
            this: defaultData?.broker
        }
    }
    if (defaultData?.analyst) {
        _defaultData["analystId"] = {
            label: defaultData?.analyst?.registration_data?.name,
            value: defaultData?.analyst?.id,
            this: defaultData?.analyst
        }
    }
    return _defaultData
}

export const FormModalChangeAnalyst: React.FC<IFormModalChangeAnalystModalProps> = (props) => {
    const { onCancel, onSave, defaultData, defaultProposalId } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: getDefaultData(defaultData) as any,
        mode: "all"
    });

    const _form = watch()

    const form_ref = useRef<IGenericFormRefProps>(null)
    const submitButtonRef = useRef<HTMLButtonElement>(null)

    const [loadingSave, setLoadingSave] = useState(false)

    const onSubmit = useCallback(async () => {
        setLoadingSave(true)

        try {

            const form = form_ref.current?.getForm()

            await api.post(`/proposals/changeAnalystAndBroker/${defaultProposalId}`, form)

            notify("Corretor e analista da proposta atualizados com sucesso!", "success")

            onSave()

        } catch (err: any) {
            notify("Erro ao atualizar corretor e analista da proposta!", "error")
        }

        setLoadingSave(false)

    }, [form_ref, _form, api, defaultProposalId])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "analystId",
                        label: "Analista",
                        type: "select-person",
                        required: true,
                        isClearable: true,
                        moreQueries: { person_types: ["analyst", "responsible"] }
                    }
                ],
                [
                    {
                        name: "brokerId",
                        label: "Corretor",
                        type: "select-person",
                        required: true,
                        isClearable: true,
                        moreQueries: { person_types: ["broker"] }
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <Column style={{ flex: 1, padding: 20, overflow: "auto" }}>
                <ContainerForm>
                    <Form onSubmit={handleStopPropagation}>
                        <GenericForm
                            ref={form_ref}
                            groups={groups}
                            _form={_form}
                            control={control}
                            trigger={trigger}
                            errors={errors}
                            setValue={setValue}
                            register={register}
                        />
                        <button
                            className="display-none"
                            type="submit"
                            ref={submitButtonRef}
                        />
                    </Form>
                </ContainerForm>

            </Column>

            <ContainerButtons theme={theme}>
                <Button
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children={"Voltar"}
                />
                <Button
                    onClick={() => submitButtonRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children={"Salvar"}
                />
            </ContainerButtons>

            <ModalLoading loading={loadingSave} theme={theme} />
        </>
    )
}