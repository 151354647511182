import React, { useCallback, useEffect, useState } from "react";
import { CgList } from "react-icons/cg";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { useNavigate } from "react-router-dom";
import { ModalVariables } from "./ModalVariables";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { setUpConsultation } from "../../services/generalServices";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ModalAttention } from "../../components/Modals/ModalAttention";
import { MdOutlineCancel, MdOutlineDocumentScanner } from "react-icons/md";
import { ModalContractModel } from "../../components/Modals/ModalContractModel";
import { contractModelsAttributes, IActions, IFilters } from "../../interfaces/index";
import { ModalVariablesTest } from "../../components/Modals/ModalVariablesTest";

const initialFilters: IFilters[] = [
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome", style: {}, styleContent: { fontSize: 16, fontWeight: 600, width: "100%" } },
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: {
            text: "Excluir",
            id: "remove"
        },
        className: "remove",
        icon: <MdOutlineCancel size={20} />,
        options: [],
        type: "outline",
        only_to_selecteds: true
    },
    {
        main_option: {
            text: "Lista de Váriaveis",
            id: "variables_list"
        },
        className: "variables_list",
        icon: <CgList size={20} />,
        options: [],
        type: "outline"
    },
    {
        main_option: {
            text: "Documentos Eletrônicos",
            id: "go_to_documents_eletronic"
        },
        className: "go_to_documents_eletronic",
        icon: <MdOutlineDocumentScanner size={20} />,
        options: [],
        type: "outline"
    },
    {
        main_option: {
            text: "Novo Modelo",
            id: "new_item"
        },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

export const ContractModelsList: React.FC = () => {
    const { user, api } = useApi()
    const { theme, paginationLimit, notify, franchise } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [selecteds, setSelecteds] = useState<string[]>([])
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [openVariablesList, setOpenVariablesList] = useState(false)
    const [numOfContractModels, setNumOfContractModels] = useState(0)
    const [loadingContractModels, setLoadingContractModels] = useState(true)
    const [openModalTestVariables, setOpenModalTestVariables] = useState(false)
    const [loadingRemoveContractModel, setLoadingRemoveContractModel] = useState(false)
    const [contractModelSelected, setContractModelSelected] = useState<contractModelsAttributes | null>(null)
    const [contractModelSelectedToRemove, setContractModelSelectedToRemove] = useState<contractModelsAttributes | null>(null)
    const [openModalConfirmRemoveSelectedsContractModels, setOpenModalConfirmRemoveSelectedsContractModels] = useState(false)
    const [controlRenderPageList, setControlRenderPageList] = useState<boolean>(false)

    const navigate = useNavigate()

    const searchContractModels = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingContractModels(true)
        try {
            const filters_obj = _filters ? { ..._filters, name: _filters.search } : { ...filters, name: searchString }
            let string = setUpConsultation(filters_obj)
            let url = `/contract-models/search/?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)
            const new_contractModels = result.data.rows as contractModelsAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_contractModels.forEach((contractModel) => {
                    new_table.data.push({
                        id: contractModel?.id,
                        name: contractModel?.name || null,
                        this: contractModel
                    })
                })
                return { ...new_table }
            })

            setNumOfContractModels(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelos modelos de documentos eletrônicos!", "error")
        }
        setLoadingContractModels(false)
    }, [user, franchise, filters, searchString])

    const onSaveContractModel = useCallback(() => {
        setOpenModal(false)
        searchContractModels(1)
    }, [user, franchise, filters, searchString])

    const selectContractModel = useCallback((contractModel: contractModelsAttributes) => {
        setContractModelSelected({ ...contractModel })
        setOpenModal(true)
    }, [])

    const openModalNewContractModel = useCallback(() => {
        setContractModelSelected(null)
        setOpenModal(true)
    }, [])

    const handleAction = useCallback((action_id: string) => {
        if (action_id === "new_item") openModalNewContractModel()
        else if (action_id === "go_to_documents_eletronic") navigate("/documents-electronic")
        else if (action_id === "variables_list") setOpenVariablesList(true)
        else if (action_id === "remove") setOpenModalConfirmRemoveSelectedsContractModels(true)
        else console.log({ action_id })
    }, [])

    const handleRemoveSelectedsContractsModels = useCallback(async (selectdsIds: string[]) => {
        for (const selectedId of selectdsIds) {
            await handleRemoveContractModel({
                contractModelId: selectedId,
                noSetLoadingEnd: true
            })
        }
        setSelecteds([])
        setLoadingRemoveContractModel(false)
        setOpenModalConfirmRemoveSelectedsContractModels(false)
        searchContractModels(1)
        setControlRenderPageList((prev) => !prev)
    }, [])

    const handleRemoveContractModel = useCallback(async ({ contractModelId, noSetLoadingEnd }: any) => {
        setLoadingRemoveContractModel(true)

        try {
            await api.delete(`/contract-models/${contractModelId}`)
            notify("Modelo de documento eletrônico removido com sucesso!", "success")
            if (!noSetLoadingEnd) searchContractModels(1)
        } catch (err) {
            notify("Erro ao remover o modelo de documento eletrônico!", "error")
        }
        if (!noSetLoadingEnd) {
            setLoadingRemoveContractModel(false)
            setContractModelSelectedToRemove(null)
            setControlRenderPageList((prev) => !prev)
        }
    }, [])

    const handleOpenModalTestVariables = useCallback(() => {
        setOpenModalTestVariables(true)
        setOpenVariablesList(false)
    }, [])

    useEffect(() => { searchContractModels(1) }, [])

    const children = (
        <PageList
            updatedAt={updatedAt}
            loading={loadingContractModels}
            setLoading={setLoadingContractModels}
            numberOfItems={numOfContractModels}
            textHeader="Modelos de Documentos Eletrônicos"
            textItems="modelos de documentos eletrônicos encontrados"
            text_loading={"Buscando Modelos de Documentos Eletrônicos"}
            text_empty={"Nenhum modelo de documento eletrônico encontrado"}
            theme={theme}
            search={searchContractModels}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_edit
            have_delete
            have_download
            getUrlDownload={contractModel => contractModel.file_url}
            edit_action={(contractModel: any) => selectContractModel(contractModel)}
            delete_action={(contractModel: any) => setContractModelSelectedToRemove(contractModel)}
            pages={numberOfPages}
            page={atualPage}
            onClickOption={handleAction}
            actions={actionsInitial}
            onChangeSearch={setSearchString}
            onChangeSelecteds={setSelecteds}
        >
            {openModal && (
                <ModalContractModel
                    openModal={openModal}
                    onCancel={() => setOpenModal(false)}
                    onSave={onSaveContractModel}
                    defaultId={contractModelSelected?.id}
                />
            )}
            {openVariablesList && (
                <ModalVariables
                    handleTestVariables={handleOpenModalTestVariables}
                    open={openVariablesList}
                    handleClose={() => setOpenVariablesList(false)}
                />
            )}
            {openModalTestVariables && (
                <ModalVariablesTest
                    onCancel={() => setOpenModalTestVariables(false)}
                    openModal={openModalTestVariables}
                />
            )}
            <ModalAttention
                theme={theme}
                open={!!contractModelSelectedToRemove?.id && !loadingRemoveContractModel}
                cancelAction={() => { setContractModelSelectedToRemove(null) }}
                confirmAction={() => handleRemoveContractModel({ contractModelId: contractModelSelectedToRemove?.id })}
                content={(
                    <>
                        Deseja realmente remover o
                        modelo de documento eletrônico <b>{contractModelSelectedToRemove?.name}</b> ?
                    </>
                )}
            />

            <ModalAttention
                theme={theme}
                open={openModalConfirmRemoveSelectedsContractModels && !loadingRemoveContractModel}
                cancelAction={() => { setOpenModalConfirmRemoveSelectedsContractModels(false) }}
                confirmAction={() => handleRemoveSelectedsContractsModels(selecteds)}
                content={(
                    <>
                        Deseja realmente remover os
                        modelos de documentos eletrônicos selecionados ?
                    </>
                )}
            />

            {loadingRemoveContractModel && (
                <ModalLoading
                    loading={loadingRemoveContractModel}
                    theme={theme}
                />
            )}
        </PageList>
    )

    return (
        <>
            {controlRenderPageList && children}
            {!controlRenderPageList && children}
        </>
    )
}