import React from "react"

export const SendEmail = ({ size, color, ...props }: any) => (
  <svg
    width={21}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 4H1a1 1 0 0 0 0 2h2a1 1 0 1 0 0-2Zm16.78-2.84V1.1A3 3 0 0 0 17.41 0H9.48a3 3 0 0 0-2 .74 2.93 2.93 0 0 0-.96 1.76l-.88 5a3 3 0 0 0 2.95 3.52h7.94a3 3 0 0 0 3-2.48l.88-5a3 3 0 0 0-.63-2.38ZM17.04 2l-3.4 2.76a1 1 0 0 1-1.38-.12L9.93 2h7.11Zm.48 6.17a1 1 0 0 1-1 .83H8.59a1 1 0 0 1-.96-.74 1 1 0 0 1-.02-.43l.8-4.53 2.35 2.66a3 3 0 0 0 4.14.35l3.44-2.81-.82 4.67ZM4 0H1a1 1 0 0 0 0 2h3a1 1 0 1 0 0-2Z"
      fill={color}
    />
  </svg>
)
