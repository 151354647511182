import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useCallback } from "react";
import { useApi } from "../../../hooks/api";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../../../hooks/global";
import { primary, secondary } from "../../../theme";
import { MdOutlineChatBubbleOutline } from "react-icons/md";
import { notificationsAttributes } from "../../../interfaces";
import { Column, ContainerNotifications, ContentNotification, NotificationCard } from "./style";

interface NotificationProps {
    notifications: notificationsAttributes[]
}

const titlesNotifications: any = {
    "interaction-proposal": "Nova interação",
    "new-opportunity": "Nova oportunidade",
    "new-proposal": "Nova proposta",
    "consult-pendency": "Pendência de consulta",
    "new-contract": "Novo documento eletrônico",
    "interaction-contract": "Nova interação",
}

export const Notifications: React.FC<NotificationProps> = ({ notifications }) => {
    const navigate = useNavigate()

    const { user } = useApi()
    const { theme } = useGlobal()

    const calcTime = useCallback((date: string) => {
        const dateNow = new Date()
        const dateDiff = new Date(date)
        const diff = Math.abs(dateNow.getTime() - dateDiff.getTime())
        const diffSeconds = Math.floor(diff / 1000)
        const diffMinutes = Math.floor(diff / (1000 * 60))
        const diffHours = Math.floor(diff / (1000 * 60 * 60))
        const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24))

        if (diffSeconds < 60) return `${diffSeconds} s atrás`
        else if (diffMinutes < 60) return `${diffMinutes} min atrás`
        else if (diffHours < 24) return `${diffHours} h atrás`
        else return `${diffDays} dias atrás`
    }, [])

    const clickInNotification = useCallback((notification: notificationsAttributes) => {
        if (["new-proposal", "interaction-proposal", "consult-pendency"].includes(notification.type)) {
            let flag = false
            if (window.location.href.includes("/proposals")) flag = true
            navigate(`/proposals?proposalId=${notification.proposal_id}`)
            if (flag) window.location.reload()
        }
        else if (["new-opportunity"].includes(notification.type)) {
            let flag = false
            if (window.location.href.includes("/opportunities")) flag = true
            navigate(`/opportunities?proposalId=${notification.proposal_id}`)
            if (flag) window.location.reload()
        }
        else if (["interaction-contract", "new-contract"]) {
            let flag = false;
            if (window.location.href.includes("/documents-electronic")) flag = true;
            navigate(`/documents-electronic?contractId=${notification.contract?.id}`)
            if (flag) window.location.reload()
        }
    }, [user])

    const messagesNotifications = (notification: notificationsAttributes) => {
        if (notification.type === "interaction-proposal") {
            return <>Houve uma nova interação em sua proposta <b style={{ whiteSpace: "nowrap" }}>#{notification.proposal?.friendly_id}</b>.</>
        }
        else if (notification.type === "new-opportunity") {
            return <>Uma nova oportunidade <b style={{ whiteSpace: "nowrap" }}>#{notification.proposal?.friendly_id}</b> aguardando ser analisada.</>
        }
        else if (notification.type === "new-proposal") {
            return <>Uma nova proposta <b style={{ whiteSpace: "nowrap" }}>#{notification.proposal?.friendly_id}</b> aguardando preenchimento.</>
        }
        else if (notification.type === "consult-pendency") {
            return <>Uma nova pendência de consulta na proposta <b style={{ whiteSpace: "nowrap" }}>#{notification.proposal?.friendly_id}</b> aguardando aprovação.</>
        }
        else if (notification.type === "interaction-contract") {
            return <>Houve uma nova interação no documento eletrônico <b style={{}}>#{notification.contract?.friendly_id}</b>.</>
        }
        else if (notification.type === "new-contract") {
            if (notification.proposal) return <>Um novo documento eletrônico foi gerado na proposta <b style={{ whiteSpace: "nowrap" }}>#{notification.proposal?.friendly_id}</b>.</>
            else if (notification.property) return <>Um novo documento eletrônico foi gerado no imóvel <b style={{ whiteSpace: "nowrap" }}>#{notification.property?.friendly_id}</b>.</>
            else return <>Um novo documento eletrônico foi gerado.</>
        }
    }

    return (
        <ContainerNotifications theme={theme}>
            <PerfectScrollbar style={{ maxHeight: 250 }}>
                <Column theme={theme}>
                    {notifications.map((notification, index) => (
                        <NotificationCard onClick={() => clickInNotification(notification)} hasBorder={(index + 1) !== notifications.length} theme={theme}>
                            <div style={{ padding: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <MdOutlineChatBubbleOutline size={20} color={secondary[theme]} />
                            </div>
                            <ContentNotification theme={theme}>
                                <div style={{ display: "flex", justifyContent: "space-between", gap: 20, flex: 1, alignItems: "center" }}>
                                    <div style={{ color: theme === "light" ? "#3A3A3A" : "rgb(207 207 207)", fontWeight: 600, whiteSpace: "nowrap" }}>{titlesNotifications[notification.type]}</div>
                                    <div style={{ fontStyle: "italic", fontSize: 14, whiteSpace: "nowrap" }}>{calcTime(notification.createdAt)}</div>
                                </div>
                                <div>{messagesNotifications(notification)}</div>
                            </ContentNotification>
                        </NotificationCard>
                    ))}
                    {notifications.length === 0 &&
                        <div style={{ padding: 10, display: "flex", justifyContent: "center", alignItems: "center", background: primary[theme] }}>
                            <div style={{ fontWeight: 600, opacity: 0.7, fontStyle: "italic" }}>Nenhuma notificação</div>
                        </div>
                    }
                </Column>
            </PerfectScrollbar>
        </ContainerNotifications>
    )
}