import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { inactiveItens, primary, shadowColor } from "../../../theme";

export const ContainerNotifications = styled.div<IThemeProps>`
    position: absolute;
    overflow: auto;
    border-radius: 5px;
    right: 0px;
    max-height: 250px;
    min-width: 300px;
    bottom: -10px;
    transform: translateY(100%);
    ${props => css`
        background: ${props.theme === "light" ? "#EEEEEE" : "rgb(53 53 53)"};
        box-shadow: 0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
    
`

export const Column = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
`

export const NotificationCard = styled.div<{ theme: themeTypes, hasBorder: boolean }>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    ${props => props.hasBorder && css`
        border-bottom: 1px solid #C4C4C4;
    `}
`

export const ContentNotification = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
    flex: 1;
    ${props => css`
        color: ${props => props.theme === "light" ? "#A8A8A8" : "rgb(129 127 127)"};
        background: ${primary[props.theme as themeTypes]}; 
    `}
    :hover{
        ${props => css`
            background-color: ${props.theme === "dark" ? "#2d2d2d" : "#f3f3f3"};
        `}
    }
`