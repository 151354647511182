import React from "react"

export const DocCancel = ({ size, color, ...props }: any) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 6a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2H5Zm5 12H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3v1a1 1 0 0 0 2 0V6.94a1.307 1.307 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19.32.32 0 0 0-.09 0L9.06 0H3a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h7a1 1 0 0 0 0-2Zm0-14.59L12.59 6H11a1 1 0 0 1-1-1V3.41Zm8.83 9.76a4.1 4.1 0 0 0-5.66 0 4 4 0 1 0 5.66 0ZM14 16a2 2 0 0 1 2-2c.172.002.343.026.51.07l-2.44 2.44A2.087 2.087 0 0 1 14 16Zm3.41 1.41a2 2 0 0 1-1.91.5l2.43-2.42c.044.166.068.338.07.51a2 2 0 0 1-.59 1.41ZM9 16a1 1 0 0 0 0-2H5a1 1 0 0 0 0 2h4Zm2-6H5a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2Z"
      fill={color}
    />
  </svg>
)
