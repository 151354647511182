import React, { useState, useCallback, useRef } from "react"
import { FiEye } from "react-icons/fi";
import { Line } from "../../../../style";
import { TiCancel } from "react-icons/ti";
import { useApi } from "../../../../hooks/api";
import { Link, useNavigate } from "react-router-dom";
import { useGlobal } from "../../../../hooks/global";
import { IconButton, Tooltip } from "@material-ui/core";
import { TD, TH, Table, Card, LabelNoData } from "./style";
import { AiOutlineRollback, AiOutlineSwap } from "react-icons/ai";
import { background, info, primary, secondary, success } from "../../../../theme";
import { proposalsAttributes, themeTypes } from "../../../../interfaces";
import { ModalLoading } from "../../../../components/Loadings/ModalLoading";
import { ModalConfirmAction } from "../../../../components/Modals/ModalProposal/steps/Analysis/ModalConfirmAction";

interface ILastProposals {
    theme: themeTypes
    data: proposalsAttributes[]
    onCancelProposal: Function
    maxWidth: string
    onClickToChangeOwner: Function
}

const columns: any[] = [
    { attr: "indicator", name: "", style: { padding: 0 }, styleContent: { padding: 0 } },
    { attr: "friendly_id", name: "Código", style: { whiteSpace: "nowrap" }, styleContent: { fontSize: 16, fontWeight: 600 } },
    { attr: "tenants", name: "Locatário(s)", style: {}, styleContent: {} },
    { attr: "owners", name: "Proprietário(s)", style: {}, styleContent: {} },
    { attr: "broker", name: "Corretor(a)", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
    { attr: "analyst", name: "Analista", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
    { attr: "status", name: "Status", style: { textAlign: "center" }, styleContent: { textAlign: "center" } }
]

export const LastProposals: React.FC<ILastProposals> = (props) => {
    const { data, theme, onCancelProposal, maxWidth, onClickToChangeOwner } = props

    const navigate = useNavigate()

    const { api, user } = useApi()
    const { notify } = useGlobal()

    const [modalConfirmType, setModalConfirmType] = useState<"PA" | "AP" | "PR" | "PC" | "PEA" | null>(null)
    const [loading_cancel, set_loading_cancel] = useState(false)
    const [proposalselected, setProposalselected] = useState<proposalsAttributes | null>(null)

    const link_ref = useRef<HTMLAnchorElement[]>([])

    const changeStatusProposal = useCallback(async ({ type, reason }) => {
        set_loading_cancel(true)
        try {
            const proposal_id = proposalselected?.id
            setModalConfirmType(null)
            setProposalselected(null)
            await api.post(`/proposals/actionInProposal/${proposal_id}`, { new_status: type, reason })
            onCancelProposal()
        } catch (err: any) {
            const error = err?.response ? err?.response?.data : "SERVER ERROR"
            if (error !== "SERVER ERROR") notify(error.message, "error")
            else notify("Erro ao mudar status da proposta!", "error")
        }
        set_loading_cancel(false)
    }, [proposalselected, api])

    const handleCancelProposal = useCallback(async (proposal: proposalsAttributes) => {
        setModalConfirmType("PC")
        setProposalselected({ ...proposal })
    }, [])

    const handleUnCancelProposal = useCallback(async (proposal: proposalsAttributes) => {
        setModalConfirmType(proposal.analyst_id ? "PEA" : "AP")
        setProposalselected({ ...proposal })
    }, [])

    return (
        <Card theme={theme} style={{ padding: 0 }}>
            <Line style={{ background: background[theme], borderTopLeftRadius: 5, borderTopRightRadius: 5, justifyContent: "space-between", padding: 20, flexWrap: "wrap" }}>
                <div style={{ fontWeight: 600, textTransform: "uppercase", whiteSpace: "nowrap", fontSize: 14 }}>Últimas proposta</div>
                <Line style={{ gap: 10, flexWrap: "wrap" }}>
                    <Line style={{ alignItems: "center", gap: 5 }}>
                        <div style={{ width: 12, height: 12, background: success }}></div>
                        <div style={{ fontSize: 12, opacity: 0.8, textTransform: "uppercase", whiteSpace: "nowrap" }}>Pendente analista</div>
                    </Line>
                    <Line style={{ alignItems: "center", gap: 5 }}>
                        <div style={{ width: 12, height: 12, background: info }}></div>
                        <div style={{ fontSize: 12, opacity: 0.8, textTransform: "uppercase", whiteSpace: "nowrap" }}>Pendente corretor</div>
                    </Line>
                </Line>
            </Line>
            {data.length > 0 &&
                <>
                    <div style={{ overflow: "auto", maxWidth: maxWidth }}>
                        <Table theme={theme}>
                            <thead>
                                <tr>
                                    {columns.map(({ attr, name, style }) => (
                                        <TH key={attr} style={{ ...style }}>{name}</TH>
                                    ))}
                                    <TH style={{ textAlign: "center" }}>Ações</TH>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((proposal: any, index) => (
                                    <tr key={proposal.id}>
                                        {columns.map(({ attr, styleContent }) => {
                                            return (
                                                <TD onClick={() => link_ref.current[index]?.click()} style={{ ...styleContent, textTransform: "uppercase" }}>
                                                    {proposal[attr]}
                                                </TD>
                                            )
                                        })}
                                        <TD isRight noHaveClick style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                            <Link target={"_blank"} to={`/proposals?proposalId=${proposal.id}`} style={{ margin: 5 }}>
                                                <Tooltip title="Visualizar">
                                                    <IconButton aria-label="Visualizar">
                                                        <FiEye size={20} color={secondary[theme]} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>
                                            {!["PR", "PC", "CF"].includes(proposal.status) &&
                                                <Tooltip onClick={() => handleCancelProposal(proposal)} title={"Cancelar"} style={{ margin: 5 }}>
                                                    <IconButton aria-label={"Cancelar"}>
                                                        <TiCancel color={secondary[theme]} size={20} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {["PC"].includes(proposal.status) &&
                                                <Tooltip onClick={() => handleUnCancelProposal(proposal)} title={"Desfazer Cancelamento"} style={{ margin: 5 }}>
                                                    <IconButton aria-label={"Desfazer Cancelamento"}>
                                                        <AiOutlineRollback color={secondary[theme]} size={20} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {["responsible", "manager"].includes(user.type) && (
                                                <Tooltip onClick={() => onClickToChangeOwner(proposal)} title={"Alterar titularidade"} style={{ margin: 5 }}>
                                                    <IconButton aria-label={"Alterar titularidade"}>
                                                        <AiOutlineSwap color={secondary[theme]} size={20} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TD>
                                        <Link
                                            ref={(ref: any) => link_ref.current[index] = ref}
                                            style={{ display: "none" }}
                                            target={"_blank"}
                                            to={`/proposals?proposalId=${proposal.id}`}
                                        />
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    </div>
                    <Line style={{ padding: 20, background: primary[theme], borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
                        <div onClick={() => navigate("/proposals")} style={{ fontWeight: 600, cursor: "pointer" }}>Listar todas as propostas</div>
                    </Line>
                </>
            }
            {data.length === 0 &&
                <LabelNoData>Sem dados</LabelNoData>
            }
            {!!modalConfirmType &&
                <ModalConfirmAction
                    onConfirm={changeStatusProposal}
                    onCancel={() => setModalConfirmType(null)}
                    open={!!modalConfirmType}
                    type={modalConfirmType}
                    friendly_id={proposalselected?.friendly_id}
                />
            }
            {loading_cancel &&
                <ModalLoading
                    loading={loading_cancel}
                    theme={theme}
                />
            }
        </Card>

    )
}