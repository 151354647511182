import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { borderColors, borderDisabled, disabledItems, primary } from "../../../theme";

export const ButtonHistory = styled.div<{ theme: themeTypes, disabled: boolean }>`
    padding: 10px;
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
        border: ${borderColors[props.theme as themeTypes]} 1px solid;
    `}
    ${props => props.disabled && css`
        background: ${disabledItems[props.theme as themeTypes]};
        border: ${borderDisabled[props.theme as themeTypes]} 1px solid;
        color: #999999;
    `}
`

export const Title = styled.div<IThemeProps>`
    padding-bottom: 10px;
    align-items: center;
    display: flex;
    ${props => css`
        border-bottom: 1px solid ${props.theme === "light" ? "#CCCCCC" : "#545454"};
    `}
`

export const ContainerLastUpdate = styled.div<{ menuIsOpen: boolean }>`
    gap: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    ${props => !props.menuIsOpen && css`
        @media(max-width: 440px) {
            display: none;
        }
    `}
    ${props => props.menuIsOpen && css`
        @media(max-width: 550px) {
            display: none;
        }
    `}
`

export const ContainerRankingPersons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    flex-wrap: wrap;
    @media (max-width: 800px){
        flex-direction: column;
    }
`

export const ContainerSelectFranchise = styled.div`
    min-width: 350px;
`