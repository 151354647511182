import { IFieldProps } from "../components/FiedlList";
import { maskFunctions } from "../services/maskServices";
import { DateResultProps, queriesAttributes } from "../interfaces";
import { calcAge, correctDate } from "../services/generalServices";

export const correct_date_result = (date: any) => {
    return date ? `${Number?.parseInt(date?.Day) > 9 ? Number?.parseInt(date?.Day) : `0${Number?.parseInt(date?.Day)}`}/${Number?.parseInt(date?.Month) > 9 ? Number?.parseInt(date?.Month) : `0${Number?.parseInt(date?.Month)}`}/${date?.Year}` : "--"
}

export const correct_date_result_in_date = (date: DateResultProps) => {
    return new Date(Number?.parseInt(date?.Year), Number?.parseInt(date?.Month) - 1, Number?.parseInt(date?.Day))
}

const FieldsDataPF: IFieldProps[] = [
    {
        label: "Nome Completo",
        keys: ["result_biro", "BestInfo", "PersonName", "Name", "Full"]
    },
    {
        label: "Situação Cadastral",
        keys: ["result_biro", "BestInfo", "CPFStatus"]
    },
    {
        label: "CPF",
        keys: ["occupation"],
        mask: maskFunctions.cpf.mask
    },
    {
        label: "Data de Nascimento",
        keys: ["result_biro", "BestInfo", "DOB"],
        mask: correct_date_result
    },
    {
        label: "Idade",
        keys: ["result_biro", "BestInfo", "DOB"],
        mask: (value: any) => Math?.floor(Number?.parseFloat(calcAge(correct_date_result_in_date(value as any))))
    },
    {
        label: "Sexo",
        keys: ["result_biro", "BestInfo", "Gender"],
        mask: (value: any) => value === "F" ? "Feminino" : "Masculino"
    },
    {
        label: "Nome da Mãe",
        keys: ["result_biro", "BestInfo", "MotherName", "Full"]
    },
    {
        label: "Endereço",
        keys: ["result_biro", "BestInfo", "Address"],
        mask: (value: any) => value ? `${value?.Street}${value?.Number ? `, ${value?.Number}` : ""}` : undefined
    },
    {
        label: "Bairro",
        keys: ["result_biro", "BestInfo", "Address", "Neighborhood"]
    },
    {
        label: "CEP",
        keys: ["result_biro", "BestInfo", "Address", "PostalCode"]
    },
    {
        label: "Cidade",
        keys: ["result_biro", "BestInfo", "Address"],
        mask: (value: any) => value ? `${value?.City} - ${value?.State}` : undefined
    },
    {
        label: "Telefone Atual",
        keys: ["result_biro", "BestInfo", "PhoneNumber", "PhoneNumber"],
        mask: maskFunctions.phone.mask
    },
    {
        label: "Celular Atual",
        keys: ["result_biro", "BestInfo", "MobilePhoneNumber", "PhoneNumber"],
        mask: maskFunctions.phone.mask
    },
    {
        label: "E-mail",
        keys: ["result_biro", "BestInfo", "Email", "Email"]
    },
    {
        label: "Data de Óbito",
        keys: ["result_biro", "BestInfo", "DOD"],
        mask: correct_date_result
    }
]

export const ListFieldsPF = [
    {
        title: "",
        fields: FieldsDataPF
    }
]

const FieldsDataHeader: IFieldProps[] = [
    {
        label: "Autor da consulta",
        keys: ["person", "registration_data", "name"],
        empty_value: "Imobiliária"
    },
    {
        label: "Data de emissão",
        keys: ["createdAt"],
        mask: correctDate
    },
    {
        label: "CPF consultado",
        keys: ["cpf_cnpj"],
        mask: maskFunctions.cpfOrCnpj.mask,
        can_show: (query: queriesAttributes) => query?.type === "PF"
    },
    {
        label: "CNPJ consultado",
        keys: ["cpf_cnpj"],
        mask: maskFunctions.cpfOrCnpj.mask,
        can_show: (query: queriesAttributes) => query?.type === "PJ"
    },
    {
        label: "Nº de verificação",
        keys: ["id"]
    }
]

export const ListFieldsHeader = [
    {
        title: "",
        fields: FieldsDataHeader
    }
]

const FieldsDataPJ: IFieldProps[] = [
    {
        label: "Razão Social",
        keys: ["result_biro", "BestInfo", "CompanyName"]
    },
    {
        label: "Nome Fantasia",
        keys: ["result_biro", "BestInfo", "TradeName"]
    },
    {
        label: "CNPJ",
        keys: ["result_biro", "CNPJ"],
        mask: maskFunctions.cnpj.mask
    },
    {
        label: "Situação Cadastral",
        keys: ["result_biro", "BestInfo", "CompanyStatus"]
    },
    {
        label: "Natureza Jurídica",
        keys: ["result_biro", "BestInfo", "LegalType"]
    },
    {
        label: "Quantidade de Filiais",
        keys: ["result_biro", "BestInfo", "CountBranches"]
    },
    {
        label: "Nº de Identificação",
        keys: ["result_biro", "BestInfo", "Nire"]
    },
    {
        label: "Código da Atividade Principal",
        keys: ["result_biro", "BestInfo", "MainActivity"]
    },
    {
        label: "Descrição da Atividade Principal",
        keys: ["result_biro", "BestInfo", "MainActivityDescription"]
    },
    {
        label: "Código da Atividade Secundária",
        keys: ["result_biro", "BestInfo", "SecondaryActivity"]
    },
    {
        label: "Descrição da Atividade Secundária",
        keys: ["result_biro", "BestInfo", "SecondaryActivityDescription"]
    },
    {
        label: "Data de Fundação",
        keys: ["result_biro", "BestInfo", "DateFoundation"],
        mask: correct_date_result
    },
    {
        label: "Endereço",
        keys: ["result_biro", "BestInfo", "Address"],
        mask: (value: any) => value ? `${value?.Street}${value?.Number ? `, ${value?.Number}` : ""}` : undefined
    },
    {
        label: "Bairro",
        keys: ["result_biro", "BestInfo", "Address", "Neighborhood"]
    },
    {
        label: "CEP",
        keys: ["result_biro", "BestInfo", "Address", "PostalCode"]
    },
    {
        label: "Cidade",
        keys: ["result_biro", "BestInfo", "Address"],
        mask: (value: any) => value ? `${value?.City} - ${value?.State}` : undefined
    },
    {
        label: "Telefone Atual",
        keys: ["result_biro", "BestInfo", "PhoneNumber", "PhoneNumber"],
        mask: maskFunctions.phone.mask
    },
    {
        label: "Celular Atual",
        keys: ["result_biro", "BestInfo", "MobilePhoneNumber", "PhoneNumber"],
        mask: maskFunctions.phone.mask
    },
    {
        label: "E-mail",
        keys: ["result_biro", "BestInfo", "Email", "Email"]
    }
]

export const ListFieldsPJ = [
    {
        title: "",
        fields: FieldsDataPJ
    }
]

const FieldsDataLaw: IFieldProps[] = [
    {
        label: "Número do processo",
        keys: ["ProcessNumber"]
    },
    {
        label: "Nome do Réu/Ré",
        keys: ["Name"]
    },
    {
        label: "Autor",
        keys: ["ProcessAuthor"]
    },
    {
        label: "Fórum",
        keys: ["Forum"]
    },
    {
        label: "Ajuizamento",
        keys: ["DateDistribution"],
        mask: correct_date_result
    },
    {
        label: "Valor da Ação",
        keys: ["AmountLawsuit"],
        mask: maskFunctions.currency.mask,
        empty_value: "R$ 0,00"
    },
    {
        label: "Cidade",
        keys: ["City"]
    },
    {
        label: "Estado",
        keys: ["State"]
    },
    {
        label: "Instância",
        keys: ["JusticeType"]
    },
    {
        label: "Tipo de Pessoa Réu/Ré",
        keys: ["EntityType"],
        mask: (value: any) => value === "F" ? "Física" : "Jurídica"
    },
    {
        label: "CPF/CNPJ",
        keys: ["CpfCnpj"],
        mask: maskFunctions.cpfOrCnpj.mask
    },
    {
        label: "Similaridade",
        keys: ["SimilarityIndex"]
    },
    {
        label: "Confiança",
        keys: ["IdConfidence"]
    },
    {
        label: "Id de registro",
        keys: ["RegisterId"]
    },
    {
        label: "Tipo de registro",
        keys: ["RegisterType"]
    },
    {
        label: "Fonte de origem",
        keys: ["OriginSource"]
    },
    {
        label: "Data de processamento",
        keys: ["DateProcessed"],
        mask: correct_date_result
    }
]

export const ListFieldsLaw = [
    {
        title: "",
        fields: FieldsDataLaw
    }
]