import styled, { css } from "styled-components";
import { secondary } from "../../theme";
import { IThemeProps, themeTypes } from "../../interfaces";

export const ContainerFiltersNumber = styled.div<IThemeProps>`
    display: flex;
    position: absolute;
    top: -1px;
    right: -5px;
    justify-content: center;
    align-items: center;
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    border-radius: 50%;
    font-size: 8px;
    color: #FFF;
    font-weight: 600;
    ${props => css`
        background: ${secondary[props.theme as themeTypes]};
    `}
`