import Button from "../../../Buttons/Button";
import React, { useCallback, useState, useRef, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/api";
import { ContainerButtons, Form } from "./style";
import { useGlobal } from "../../../../hooks/global";
import { inactiveItens, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { IGroupProps, contractModelsAttributes } from "../../../../interfaces";

interface IFormContractModelModalProps {
    onCancel: Function
    onSave: Function
    defaultData?: contractModelsAttributes | null
}

export const FormContractModel: React.FC<IFormContractModelModalProps> = (props) => {
    const { onCancel, onSave, defaultData } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const defaultValues = defaultData?.id ? defaultData : {} as any

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({ defaultValues, mode: "all" });

    const _form = watch()

    const form_ref = useRef<IGenericFormRefProps>(null)
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const [loadingCreate, setLoadingCreate] = useState(false)

    const onSubmit = useCallback(async () => {

        setLoadingCreate(true)

        const form = form_ref.current?.getForm()
        const name = form?.name
        const file = form?.file_name

        try {

            const formData = new FormData()

            if (file.file !== "arquivo" && file.file) formData.append("file", file.file)
            formData.append("name", name)

            if (defaultData?.id) await api.put(`/contract-models/${defaultData.id}`, formData)
            else await api.post(`/contract-models`, formData)

            const typeSave = defaultData?.id ? "atualizado" : "cadastrado"

            notify(`Modelo de Documento Eletrônico ${typeSave} com sucesso!`, "success")

            onSave()

        } catch (err: any) {
            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error.path) setError(error.path, { type: "manual", message: error.message })
            else notify("ERRO INTERNO DO SISTEMA", "error")
        }

        setLoadingCreate(false)

    }, [form_ref, _form, api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, form_ref, _form, api])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "name",
                        label: "Name",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "file_name",
                        label: "Arquivo",
                        type: "file",
                        required: true,
                        getFileTypes: (data: any) => ["doc", "docx"]
                    }
                ]
            ]
        }
    ]

    return (
        <>
            <Form onSubmit={handleStopPropagation}>
                <GenericForm
                    ref={form_ref}
                    groups={groups}
                    _form={_form}
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    setValue={setValue}
                    register={register}
                />
                <button
                    ref={buttonSubmitRef}
                    type="submit"
                    style={{ display: "none" }}
                />
            </Form>

            <ContainerButtons theme={theme}>
                <Button
                    onClick={() => onCancel()}
                    background={inactiveItens[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Voltar"
                />
                <Button
                    onClick={() => buttonSubmitRef.current?.click()}
                    background={secondary[theme]}
                    color={"#FFF"}
                    style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                    children="Salvar"
                />
            </ContainerButtons>

            <ModalLoading
                loading={loadingCreate}
                theme={theme}
            />
        </ >
    )
}
