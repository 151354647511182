import styled, { css } from "styled-components";
import { Column, Line } from "../../../../style";
import { borderColors, secondary } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Title = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const Description = styled.div<IThemeProps>`
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    color: #FFF;
    border-radius: 5px;
    background: ${props => secondary[props.theme as themeTypes]};
`

export const ContentTitle = styled(Column)`
    gap: 5px;
    max-width: 245px;
`

export const ContainerTitle = styled(Line) <{ haveBorder: boolean }>`
    padding: 10px;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    ${props => props.haveBorder && css`
        border-bottom: 1px solid ${borderColors[props.theme as themeTypes]};
    `}
`