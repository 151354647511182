import { fail, info, success } from "../theme";

export const proposal_status: any = {
    "AP": { text: "Aguardando preenchimento", color: "#626262" },
    "AA": { text: "Aguardando análise", color: "#626262" },
    "PEA": { text: "Proposta em análise", color: info },
    "RP": { text: "Resolução de pendências", color: info },
    "PA": { text: "Proposta aprovada", color: success },
    "PAC": { text: "Proposta aprovada condicionada", color: success },
    "PR": { text: "Proposta reprovada", color: fail },
    "PC": { text: "Proposta cancelada", color: fail },
    "CF": { text: "Contrato finalizado", color: success },
    "CG": { text: "Contrato gerado", color: success },
    "CA": { text: "Contrato assinado", color: success }
}

export const pendencies: any = {
    "AP": "PR",
    "AA": "PC",
    "PEA": "PC",
    "RP": "PR",
    "PA": "PC",
    "CF": "PC",
    "PAC": "PC",
    "PR": "PR",
    "PC": "PR",
    "CG": "PR",
    "CA": "PR"
}