import React, { DetailedHTMLProps, TextareaHTMLAttributes, forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import { ContainerTextArea } from "./style";
import { FieldError } from "../../../style";
import { useGlobal } from "../../../hooks/global";

interface ITextAreaProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    error?: any
}

export interface TextAreaRefProps {
    focus: () => void
}

const TextAreaComponent: React.ForwardRefRenderFunction<TextAreaRefProps, ITextAreaProps> = (props, ref) => {
    const { error, disabled, onChange = () => { } } = props;

    const { theme } = useGlobal()

    const textarea_ref = useRef<HTMLTextAreaElement>(null)

    const focus = useCallback(() => { textarea_ref.current?.focus() }, [textarea_ref])

    useImperativeHandle(ref, () => ({ focus }))

    const handleOnChange = useCallback((e: any) => {
        onChange(e?.target?.value)
    }, [])

    return (
        <ContainerTextArea theme={theme} haveError={!!error}>
            <textarea
                ref={textarea_ref}
                {...props}
                className="form-control form-control-rounded position-relative"
                style={{ resize: "none", padding: 10 }}
                onChange={handleOnChange}
            />
            {(error && !disabled) && <FieldError title={error}>{error}</FieldError>}
        </ContainerTextArea>
    )
}

export const TextArea = forwardRef(TextAreaComponent);