import Button from "../../Buttons/Button";
import React, { useCallback, useEffect, useState } from "react";
import { Tabs } from "../../Tabs";
import { TenantStep } from "./steps/Tenant";
import { useApi } from "../../../hooks/api";
import { Column, Line } from "../../../style";
import { QueriesStep } from "./steps/Queries";
import { PropertyStep } from "./steps/Property";
import { WarrantyStep } from "./steps/Warranty";
import { AnalysisStep } from "./steps/Analysis";
import { ContractStep } from "./steps/Contract";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { DocumentsStep } from "./steps/Documents";
import { ModalAttention } from "../ModalAttention";
import { correctDate } from "../ModalQuery/Result";
import { CircularProgress } from "@material-ui/core";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { WaveLoading } from "../../Loadings/WaveLoading";
import { copyOf } from "../../../services/generalServices";
import { useProposalChat } from "../../../hooks/proposals";
import { ContractorDataStep } from "./steps/ContractorData";
import { Container, ContainerLastInteraction } from "./style";
import { borderColors, divider, inactiveItens, primary, secondary, shadowColor } from "../../../theme";
import { contractorDataAttributes, customers_proposalAttributes, IOptionsProps, proposalsAttributes, proposal_status, tabProps, warrantyAttributes, warranty_type } from "../../../interfaces";

interface IModalProposalProps {
    onCancel: Function
    defaultId?: string
    friendlyId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

interface IChangeTabsProps {
    index: number
    _proposal?: proposalsAttributes | null
}

const initialsTabs: tabProps[] = [
    { label: "1. Consultas Cadastrais", canClick: false, open: false },
    { label: "2. Imóvel", canClick: false, open: false },
    { label: "3. Locatário(s)", canClick: false, open: false },
    { label: "4. Garantia", canClick: false, open: false },
    { label: "5. Dados do Contrato", canClick: false, open: false },
    { label: "6. Documentação", canClick: false, open: false },
    { label: "7. Análise", canClick: false, open: false },
    { label: "8. Documentos Eletrônicos", canClick: false, open: false }
]

const BLOCKED_STEPS = ["PEA", "PA", "PR", "PC", "CF"] as proposal_status[]

const UNBLOCKED_ELETRONIC_DOCUMENTS_STEPS = ["PA", "CF"] as proposal_status[]

export const ModalProposal: React.FC<IModalProposalProps> = (props) => {
    const { friendlyId, onCancel, openModal, backgroundVisible, defaultId } = props

    const { theme, notify, } = useGlobal()
    const { socketSteps } = useProposalChat()
    const { user, markAsRead, notifications, api } = useApi()

    const [loading, setLoading] = useState(true)
    const [control, setControl] = useState<number>(0)
    const [firstLoading, setFirstLoading] = useState(true)
    const [tabs, setTabs] = useState<tabProps[]>(initialsTabs)
    const [updatedAt, setUpdatedAt] = useState<Date | null>(null)
    const [editTenants, setEditTenants] = useState<boolean>(false)
    const [editProperty, setEditProperty] = useState<boolean>(false)
    const [editWarranty, setEditWarranty] = useState<boolean>(false)
    const [editDocuments, setEditDocuments] = useState<boolean>(false)
    const [warranty, setWarranty] = useState<warrantyAttributes | null>(null)
    const [tenants, setTenants] = useState<customers_proposalAttributes[]>([])
    const [consults, setConsults] = useState<customers_proposalAttributes[]>([])
    const [editContractorData, setEditContractorData] = useState<boolean>(false)
    const [confirmSendToAnalysis, setConfirmSendToAnalysis] = useState<boolean>(false)
    const [contractorData, setContractorData] = useState<contractorDataAttributes | null>(null)
    const [correctProposal, setCorrectProposal] = useState<proposalsAttributes | null | undefined>(null)
    const [proposal, setProposal] = useState<{ property: IOptionsProps | undefined, contract_type: IOptionsProps | null | undefined }>({ contract_type: null, property: undefined })

    const changeProposal = useCallback((step) => {
        setTabs((atualTabs) => {
            let newTabs = copyOf(atualTabs) as tabProps[]
            newTabs = newTabs.map((tab, index) => {
                tab.canClick = (index + 1) <= step
                tab.open = (index + 1) === step
                return tab
            })
            return [...newTabs]
        })
    }, [])

    const updateProposal = useCallback(async (response: any, required_step = null) => {
        setUpdatedAt(response.data.updatedAt)
        const step = response.data.step
        const status = response.data.status
        required_step = required_step || step
        if (BLOCKED_STEPS.includes(status) || user.type === "analyst") {
            setTabs((atualTabs) => {
                let newTabs = copyOf(atualTabs) as tabProps[]
                newTabs = newTabs.map((tab, index) => {
                    if (UNBLOCKED_ELETRONIC_DOCUMENTS_STEPS.includes(status)) {
                        tab.canClick = (index + 1) >= 7
                        tab.open = (index + 1) === (required_step < 8 ? 7 : 8)
                    } else {
                        tab.canClick = (index + 1) === 7
                        tab.open = (index + 1) === 7
                    }
                    return tab
                })
                return [...newTabs]
            })
        }
        else {
            const step_open = required_step === null ? step : (required_step <= step ? required_step : step)
            if (required_step > step) {
                try {
                    await api.post(`/proposals/next-step/${defaultId}`)
                    setTabs((atualTabs) => {
                        let newTabs = copyOf(atualTabs) as tabProps[]
                        newTabs = newTabs.map((tab, index) => {
                            tab.canClick = (index + 1) <= (step + 1)
                            tab.open = (index + 1) === (step + 1)
                            return tab
                        })
                        return [...newTabs]
                    })
                } catch (err) {
                    notify("Erro ao avancar o passo", "error")
                }
            }
            else {
                setTabs((atualTabs) => {
                    let newTabs = copyOf(atualTabs) as tabProps[]
                    newTabs = newTabs.map((tab, index) => {
                        tab.canClick = (index + 1) <= step
                        tab.open = (index + 1) === step_open
                        return tab
                    })
                    return [...newTabs]
                })
            }
        }
    }, [defaultId, user, socketSteps])

    const updateProposalBySocket = useCallback(async (response: any, required_step = null) => {
        setUpdatedAt(response.data.updatedAt)
        const step = response.data.step
        const status = response.data.status
        required_step = required_step || step
        if (BLOCKED_STEPS.includes(status) || user.type === "analyst") {
            setTabs((atualTabs) => {
                let newTabs = copyOf(atualTabs) as tabProps[]
                newTabs = newTabs.map((tab, index) => {
                    if (UNBLOCKED_ELETRONIC_DOCUMENTS_STEPS.includes(status)) {
                        tab.canClick = (index + 1) >= 7
                        tab.open = (index + 1) === (required_step < 8 ? 7 : 8)
                    } else {
                        tab.canClick = (index + 1) === 7
                        tab.open = (index + 1) === 7
                    }
                    return tab
                })
                return [...newTabs]
            })
        }
        else {
            const step_open = required_step === null ? step : (required_step <= step ? required_step : step)
            setTabs((atualTabs) => {
                let newTabs = copyOf(atualTabs) as tabProps[]
                newTabs = newTabs.map((tab, index) => {
                    tab.canClick = (index + 1) <= step
                    tab.open = (index + 1) === step_open
                    return tab
                })
                return [...newTabs]
            })
        }
        setFirstLoading(false)
    }, [defaultId, user, socketSteps])

    const searchProposal = useCallback(async ({ bySocket, required_step }) => {
        if (!bySocket) {
            setFirstLoading(true)
            setLoading(true)
        }
        else setControl((atual) => atual + 1)
        try {
            const response = await api.get(`/proposals/${defaultId}`)
            setCorrectProposal(response.data)

            socketSteps?.removeAllListeners()

            if (bySocket) await updateProposalBySocket(response, required_step)
            else await updateProposal(response, required_step)

            socketSteps?.on(`${defaultId}-steps`, async () => {
                let atualStep = null
                setTabs((atualTabs) => {
                    const step = atualTabs.findIndex((tab: any) => tab.open)
                    atualStep = step === -1 ? null : step + 1
                    return [...atualTabs]
                })
                await searchProposal({ bySocket: true, required_step: atualStep })
            })

        } catch (error) {
            notify("Erro ao buscar proposta!", "error")
            onCancel()
        }
        setFirstLoading(false)
    }, [defaultId, user, socketSteps])

    const changeTabs = useCallback(async (props: IChangeTabsProps) => {
        const { index, _proposal } = props
        const required_step = index + 1

        const atual_less_7_step = _proposal?.step ? _proposal?.step < 7 : false
        const dont_have_analyst = !_proposal?.analyst_id

        if (required_step === 7 && dont_have_analyst && atual_less_7_step) confirmSendToAnalyst()
        else searchProposal({ bySocket: false, required_step: required_step })
    }, [defaultId, user, socketSteps])

    const confirmSendToAnalyst = useCallback(async () => {
        setConfirmSendToAnalysis(true)
    }, [])

    const handleSendToAnalyst = useCallback(async () => {
        setConfirmSendToAnalysis(false)
        searchProposal({ bySocket: false, required_step: 7 })
    }, [])

    const validateStep = useCallback((step) => {
        if (step === 1) return !consults.find((item) => ["pending", "not_search", "waiting", "rejected_by_biro", "rejected_by_responsible"].includes(item.status))
        else if (step === 2) return proposal.property?.value && proposal.contract_type?.value && !editProperty
        else if (step === 3) return tenants.length > 0
        else if (step === 4) {
            if (warranty?.warranty_type === "GUARANTOR") return !!(warranty?.guarantors && warranty.guarantors.length > 0)
            else return !!warranty?.warranty_type
        }
        else if (step === 5) return !!(contractorData?.date_end && contractorData?.date_init && contractorData?.rent_value && contractorData?.expiration_day && contractorData?.readjustment_index)
        else if (step === 6) return true
        else return false
    }, [consults, proposal, tenants, warranty, contractorData, editProperty])

    useEffect(() => { searchProposal({ bySocket: false, required_step: null }) }, [defaultId])

    useEffect(() => {
        if (defaultId) {
            const notiticationsUnread = notifications.filter(notification => {
                return notification.proposal_id === defaultId && notification.user_id === user.id
            })
            if (notiticationsUnread.length > 0) markAsRead({ user_id: user.id, proposal_id: defaultId })
        }
    }, [user, notifications, defaultId])

    useEffect(() => {
        // Necessário para que o warrantyRef.current?.validate() funcione
        setTimeout(() => {
            const aux = document.getElementById("aux_sub")
            aux?.click()
        }, 500)
    }, [firstLoading, loading])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme} style={{ boxShadow: `0.125rem 0.125rem 0.5rem ${shadowColor[theme]}` }}>

                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", gap: 30, boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,0.1)", padding: 20, background: primary[theme], position: "relative", borderTopLeftRadius: "0.3125rem", borderTopRightRadius: "0.3125rem" }}>
                    <Line style={{ gap: 10 }}>
                        <div onClick={() => onCancel()} style={{ cursor: "pointer" }}>
                            <IoReturnUpBackOutline color={theme === "dark" ? "#FFF" : inactiveItens.light} size={20} />
                        </div>
                        <Column>
                            <div style={{ color: theme === "dark" ? "#FFF" : inactiveItens.light, fontWeight: 600, fontSize: 18 }}>Editar Proposta - #{friendlyId}</div>
                            <div id="aux_sub">
                                Informe os dados para concluir a proposta
                            </div>
                        </Column>
                    </Line>
                    <ContainerLastInteraction>
                        <div style={{ fontWeight: 600, textAlign: "end" }}>Última interação realizada em:</div>
                        {loading &&
                            <div style={{ fontStyle: "italic", height: 24, textAlign: "end", display: "flex", gap: 10, alignItems: "center" }}>
                                <CircularProgress size={10} variant="indeterminate" style={{ color: secondary[theme] }} />
                            </div>
                        }
                        {!loading &&
                            <div style={{ textAlign: "end", display: "flex", gap: 10, alignItems: "center" }}>
                                {correctDate(updatedAt)}
                            </div>
                        }
                    </ContainerLastInteraction>
                </div>

                {!firstLoading &&
                    <>
                        <Column style={{ padding: 20, flex: 1, overflow: "auto" }}>
                            <Column style={{ padding: 20, flex: 1, border: `1px solid ${borderColors[theme]}`, borderRadius: 5, background: primary[theme] }}>
                                <Tabs
                                    tabs={loading ? initialsTabs : tabs}
                                    onChange={(newPos: number) => changeTabs({ index: newPos, _proposal: correctProposal })}
                                    pos={tabs.findIndex((tab) => tab.open)}
                                    disabled={loading}
                                />
                                <>
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 1 &&
                                        <QueriesStep
                                            control={control}
                                            consults={consults}
                                            setConsults={setConsults}
                                            setUpdatedAt={setUpdatedAt}
                                            onCancel={() => onCancel()}
                                            changeProposal={changeProposal}
                                            setLoading={setLoading}
                                            loading={loading}
                                            defaultId={defaultId as string}
                                        />
                                    }
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 2 &&
                                        <PropertyStep
                                            control={control}
                                            proposal={proposal}
                                            setProposal={setProposal}
                                            setUpdatedAt={setUpdatedAt}
                                            onCancel={() => onCancel()}
                                            changeProposal={changeProposal}
                                            setLoading={setLoading}
                                            loading={loading}
                                            defaultId={defaultId as string}
                                            editProperty={editProperty}
                                            setEditProperty={setEditProperty}
                                        />
                                    }
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 3 &&
                                        <TenantStep
                                            control={control}
                                            tenants={tenants}
                                            setTenants={setTenants}
                                            editTenants={editTenants}
                                            setEditTenants={setEditTenants}
                                            setUpdatedAt={setUpdatedAt}
                                            onCancel={() => onCancel()}
                                            changeProposal={changeProposal}
                                            setLoading={setLoading}
                                            loading={loading}
                                            defaultId={defaultId as string}
                                        />
                                    }
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 4 &&
                                        <WarrantyStep
                                            control={control}
                                            setWarranty={setWarranty}
                                            warranty={warranty}
                                            editWarranty={editWarranty}
                                            setEditWarranty={setEditWarranty}
                                            setUpdatedAt={setUpdatedAt}
                                            onCancel={() => onCancel()}
                                            changeProposal={changeProposal}
                                            setLoading={setLoading}
                                            loading={loading}
                                            defaultId={defaultId as string}
                                        />
                                    }
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 5 &&
                                        <ContractorDataStep
                                            control={control}
                                            contractorData={contractorData}
                                            setContractorData={setContractorData}
                                            editContractorData={editContractorData}
                                            setEditContractorData={setEditContractorData}
                                            setUpdatedAt={setUpdatedAt}
                                            onCancel={() => onCancel()}
                                            changeProposal={changeProposal}
                                            setLoading={setLoading}
                                            loading={loading}
                                            defaultId={defaultId as string}
                                        />
                                    }
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 6 &&
                                        <DocumentsStep
                                            control={control}
                                            editDocuments={editDocuments}
                                            changeProposal={changeProposal}
                                            setUpdatedAt={setUpdatedAt}
                                            defaultId={defaultId as string}
                                            onCancel={() => onCancel()}
                                            loading={loading}
                                            setEditDocuments={setEditDocuments}
                                            setLoading={setLoading}
                                        />
                                    }
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 7 &&
                                        <AnalysisStep
                                            control={control}
                                            changeProposal={changeProposal}
                                            setUpdatedAt={setUpdatedAt}
                                            defaultId={defaultId as string}
                                            onCancel={() => onCancel()}
                                            loading={loading}
                                            setLoading={setLoading}
                                        />
                                    }
                                    {(tabs.findIndex((tab) => tab.open) + 1) === 8 &&
                                        <ContractStep
                                            control={control}
                                            changeProposal={changeProposal}
                                            setUpdatedAt={setUpdatedAt}
                                            defaultId={defaultId as string}
                                            onCancel={() => onCancel()}
                                            loading={loading}
                                            setLoading={setLoading}
                                        />
                                    }
                                </>
                            </Column>
                        </Column>

                        {![7, 8].includes((tabs.findIndex((tab) => tab.open) + 1)) &&
                            <div style={{ display: "flex", justifyContent: "space-between", padding: 20, borderTop: `1px solid ${divider[theme]}` }}>
                                <Button disabled={loading} onClick={() => onCancel()} background={inactiveItens[theme]} color={"#FFF"} style={{ padding: 8, maxWidth: 150, fontSize: 14 }}>
                                    Fechar
                                </Button>
                                {(tabs.findIndex((tab) => tab.open) + 1) === 3 &&
                                    <Button
                                        disabled={editTenants || !validateStep(3) || loading}
                                        onClick={() => changeTabs({ index: 3, _proposal: correctProposal })}
                                        background={secondary[theme]}
                                        color={"#FFF"}
                                        style={{ padding: 8, maxWidth: 150, fontSize: 14, display: "flex" }}>
                                        Próxima Etapa
                                    </Button>
                                }
                                {(tabs.findIndex((tab) => tab.open) + 1) === 4 &&
                                    <Button
                                        disabled={editWarranty || !validateStep(4) || loading}
                                        onClick={() => changeTabs({ index: 4, _proposal: correctProposal })}
                                        background={secondary[theme]}
                                        color={"#FFF"}
                                        style={{ padding: 8, maxWidth: 150, fontSize: 14, display: "flex" }}>
                                        Próxima Etapa
                                    </Button>
                                }
                                {(tabs.findIndex((tab) => tab.open) + 1) === 5 &&
                                    <Button
                                        disabled={editContractorData || !validateStep(5) || loading}
                                        onClick={() => changeTabs({ index: 5, _proposal: correctProposal })}
                                        background={secondary[theme]}
                                        color={"#FFF"}
                                        style={{ padding: 8, maxWidth: 150, fontSize: 14, display: "flex" }}>
                                        Próxima Etapa
                                    </Button>
                                }
                                {![3, 4, 5].includes(tabs.findIndex((tab) => tab.open) + 1) &&
                                    <Button
                                        disabled={!validateStep(tabs.findIndex((tab) => tab.open) + 1) || loading}
                                        onClick={() => changeTabs({
                                            index: tabs.findIndex((tab) => tab.open) + 1,
                                            _proposal: correctProposal
                                        })}
                                        background={secondary[theme]}
                                        color={"#FFF"}
                                        style={{ padding: 8, maxWidth: 150, fontSize: 14, display: "flex" }}>
                                        Próxima Etapa
                                    </Button>
                                }
                            </div>
                        }
                    </>
                }
                {firstLoading &&
                    <>
                        <Column style={{ padding: 20, flex: 1, overflow: "auto" }}>
                            <Column style={{ padding: 20, flex: 1, border: `1px solid ${borderColors[theme]}`, borderRadius: 5, background: primary[theme] }}>
                                <Tabs
                                    pos={0}
                                    disabled
                                    tabs={initialsTabs}
                                    onChange={(newPos: number) => changeTabs({ index: newPos, _proposal: correctProposal })}
                                />
                                <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <WaveLoading />
                                </div>
                            </Column>
                        </Column>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: 20, borderTop: `1px solid ${divider[theme]}` }}>
                            <Button disabled background={inactiveItens[theme]} color={"#FFF"} style={{ padding: 8, maxWidth: 150, fontSize: 14 }}>
                                Fechar
                            </Button>
                            <Button disabled background={secondary[theme]} color={"#FFF"} style={{ padding: 8, maxWidth: 150, fontSize: 14 }}>
                                Próxima Etapa
                            </Button>
                        </div>
                    </>
                }
            </Container>
            {confirmSendToAnalysis && (
                <ModalAttention
                    open
                    theme={theme}
                    cancelAction={() => setConfirmSendToAnalysis(false)}
                    confirmAction={handleSendToAnalyst}
                    content="Deseja enviar a proposta para análise?"
                />
            )}
        </ModalGeneric>
    )
}