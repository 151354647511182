import Button from "../../../Buttons/Button";
import React, { FormEvent, useCallback, useRef, useState } from "react";
import { Tabs } from "../../../Tabs";
import { useForm } from "react-hook-form";
import { useApi } from "../../../../hooks/api";
import { Column, Line } from "../../../../style";
import { DocumentModal } from "../../ModalDocument";
import { useGlobal } from "../../../../hooks/global";
import { BiGridAlt, BiListUl } from "react-icons/bi";
import { ModalGeneric } from "../../../ModalGeneric";
import { ListDocuments } from "../../../ListDocuments";
import { copyOf } from "../../../../services/generalServices";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { inactiveItens, primary, secondary } from "../../../../theme";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import {
  ContainerButtons,
  ContainerDocuments,
  ContainerForm,
  Form,
} from "./style";
import {
  IGroupProps,
  documentsAttributes,
  personsAttributes,
  tabProps,
} from "../../../../interfaces";
import {
  accountTypesOptions,
  banksOptions,
  maritalStatusOptions,
  paymentTypesOptions,
  personsTypesOptions,
} from "../../../../utils/options";
import {
  validateCEP,
  validateCNPJ,
  validateCPF,
  validateCPFOrCNPJ,
  validateDate,
  validateEmail,
  validatePhone,
} from "../../../../utils/validatesFields";

interface IFormPersonModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: personsAttributes | null;
  defaultPerson?: personsAttributes;
  enabled_change_form_type?: boolean;
  isPersonInPerson?: boolean;
  personType: "spouse" | "person" | "representative" | "responsible";
  relation?: string;
}

const disabledTabs: tabProps[] = [
  { label: "Dados da Pessoa", canClick: false, open: false },
  { label: "Documentos", canClick: false, open: false },
];

const initialsTabsCreate: tabProps[] = [
  { label: "Dados da Pessoa", canClick: true, open: true },
  { label: "Documentos", canClick: false, open: false },
];

const initialsTabsUpdate: tabProps[] = [
  { label: "Dados da Pessoa", canClick: true, open: true },
  { label: "Documentos", canClick: true, open: false },
];

const getDisabledAll = (props: any) => {
  const person_id = props?.defaultPerson?.id;
  const user_type = props?.user?.type;
  const broker_id = props?.defaultPerson?.broker_id;
  const user_person_id = props?.user?.person_id;

  if (!person_id) return false;
  return user_type === "broker" && !!broker_id && user_person_id !== broker_id;
};

const correct_persons_types: any = {
  person: {
    name: "Pessoa",
    predicate: "da",
    connect: "uma nova",
    update: "atualizada",
    create: "cadastrada",
  },
  representative: {
    name: "Representante",
    predicate: "do",
    connect: "um novo",
    update: "atualizado",
    create: "cadastrado",
  },
  spouse: {
    name: "Cônjuge",
    predicate: "do",
    connect: "um novo",
    update: "atualizado",
    create: "cadastrado",
  },
  responsible: {
    name: "Responsável",
    predicate: "do",
    connect: "um novo",
    update: "atualizado",
    create: "cadastrado",
  },
};

export const FormPerson: React.FC<IFormPersonModalProps> = (props) => {
  const { enabled_change_form_type = false, isPersonInPerson = false } = props;
  const { onCancel, onSave, defaultData, personType, defaultPerson, relation } =
    props;

  const { theme, notify } = useGlobal();
  const { user, api, consultsServices } = useApi();

  const disabledAll = getDisabledAll({ defaultPerson, user });

  const _defaultData = {
    type: { label: "Pessoa Física", value: "PF" },
    foreigner: { label: 'Não', value: false },
    broker_id:
      user.type === "broker"
        ? {
          label: user.person?.registration_data?.name,
          value: user.person?.id,
          this: user.person,
        }
        : null,
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultData?.id ? defaultData : (_defaultData as any),
    mode: "all",
  });

  const _form = watch();

  const form_ref = useRef<IGenericFormRefProps>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [loadingCEP, setLoadingCEP] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loading_documents, setLoadingDocuments] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [exibition, setExibition] = useState<"grid" | "list">("list");
  const [documents, setDocuments] = useState<documentsAttributes[]>(
    defaultPerson?.documents ? defaultPerson.documents : []
  );
  const [tabs, setTabs] = useState<tabProps[]>(() => {
    if (defaultPerson?.id && !disabledAll) return initialsTabsUpdate;
    else return initialsTabsCreate;
  });

  const onSubmit = useCallback(async () => {
    setLoadingCreate(true);

    try {
      const form = form_ref.current?.getForm();
      if (personType === "responsible") form.type = personType;

      form.broker_id = form.registration_data.broker_id;
      delete form.registration_data.broker_id;

      form.foreigner = form.registration_data.foreigner;
      delete form.registration_data.foreigner;

      if (defaultPerson?.id) {
        form.payment.type = form.payment.payment_type;
        form.spouse_id = form.registration_data.spouse_id;
        form.representatives = form.registration_data.representatives;

        delete form.payment.payment_type;
        delete form.registration_data.spouse_id;
        delete form.registration_data.representatives;
      }

      let value = null;
      const label =
        form?.registration_data?.name ?? form?.registration_data?.fantasy_name;
      const spouse = _form.spouse_id;
      const _this = {
        id: defaultPerson?.id,
        user: { email: form.registration_data.email },
        ...form,
      };

      if (defaultPerson?.id) {
        await api.put(`/customers/${defaultPerson?.id}`, form);
        value = defaultPerson?.id;
      } else {
        const { data } = await api.post("/customers", form);
        value = data?.id;
      }

      const { name, update, create } = correct_persons_types[personType];

      notify(
        `${name} ${defaultPerson?.id ? update : create} com sucesso!`,
        "success"
      );

      onSave({ label, value, relation, spouse, this: _this });
    } catch (err: any) {
      const error = err.response ? err.response.data : "SERVER ERROR";
      if (error.path)
        setError(error.path, { type: "manual", message: error.message });
      else notify("ERRO INTERNO DO SISTEMA", "error");
    }

    setLoadingCreate(false);
  }, [form_ref, _form, api, relation]);

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api, relation]
  );

  const changeTab = useCallback((index) => {
    setTabs((atual) => {
      const new_tabs = copyOf(atual) as tabProps[];
      for (let i = 0; i < new_tabs.length; i++) {
        new_tabs[i].open = i === index;
      }
      return new_tabs;
    });
  }, []);

  const searchDocuments = useCallback(async () => {
    if (defaultPerson?.id) {
      setLoadingDocuments(true);

      try {
        const result = await api.get(
          `/documents?customer_id=${defaultPerson?.id}`
        );
        setDocuments(result.data);
      } catch (err) {
        notify("Erro ao buscar documentos", "error");
        onCancel();
      }

      setLoadingDocuments(false);
    }
  }, []);

  const onSaveDocument = useCallback(async () => {
    setOpenDocumentModal(false);
    searchDocuments();
  }, []);

  const changeCEP = useCallback(async (search) => {
    search = search.replace(/\D/g, "");
    if (search.length === 8) {
      setLoadingCEP(true);

      const { address, city, state } = await consultsServices.address({
        search,
      });

      if (address) {
        setValue("public_place", address.logradouro);
        setValue("district", address.bairro);
        setValue("complement", address.complemento);
      }

      if (city)
        setValue("city_id", { label: city.name, value: city.id, this: city });

      if (state)
        setValue("state_id", {
          label: state.name,
          value: state.id,
          initials: state.initials,
          this: state,
        });

      setLoadingCEP(false);

      trigger();
    }
  }, []);

  const groupsEdit: IGroupProps[] = [
    {
      name: "registration_data",
      label: "Dados de Registro",
      fields: [
        [
          {
            name: "type",
            label: "Tipo",
            type: "select-fixed",
            required: true,
            options: personsTypesOptions,
            getIsDisabled: (data: any) => true,
          },
        ],
        [
          {
            name: "name",
            label: "Nome completo",
            type: "input",
            required: true,
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "corporate_name",
            label: "Razão Social",
            type: "input",
            required: true,
            canSee: (data: any) => data?.type?.value === "PJ",
          },
          {
            name: "fantasy_name",
            label: "Nome Fantasia",
            type: "input",
            required: true,
            canSee: (data: any) => data?.type?.value === "PJ",
          },
        ],
        [
          {
            name: "cnpj",
            label: "CNPJ",
            type: "input",
            mask: "cnpj",
            validate: validateCNPJ,
            required: true,
            getIsDisabled: (data: any) => true,
            canSee: (data: any) => data?.type?.value === "PJ",
          },
          {
            name: "phone",
            label: "Telefone",
            type: "input",
            mask: "phone",
            validate: validatePhone,
            canSee: (data: any) => data?.type?.value === "PJ",
          },
        ],
        [
          {
            name: "email",
            label: "Email",
            type: "input",
            validate: validateEmail,
          },
          {
            name: "state_registration",
            label: "Inscrição estadual",
            type: "input",
            canSee: (data: any) => data?.type?.value === "PJ",
          },
          {
            name: "municipal_registration",
            label: "Inscrição municipal",
            type: "input",
            canSee: (data: any) => data?.type?.value === "PJ",
          },
        ],
        [
          {
            name: "phone",
            label: "Telefone",
            type: "input",
            mask: "phone",
            validate: validatePhone,
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "birth",
            label: "Data de Nascimento",
            type: "input",
            mask: "date",
            validate: validateDate,
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "creci",
            label: "Creci",
            type: "input",
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: 'foreigner',
            label: 'Estrangeiro',
            type: 'select-fixed',
            options: [{ label: 'Sim', value: true }, { label: 'Não', value: false }],
            isClearable: false,
            required: true,
            getIsDisabled: (data: any) => true,
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "cpf",
            label: "CPF",
            type: "input",
            mask: "cpf",
            validate: validateCPF,
            required: true,
            getIsDisabled: (data: any) => true,
            canSee: (data: any) => data?.type?.value === "PF" && !data?.foreigner?.value,
          },
          {
            name: "rg",
            label: "RG",
            type: "input",
            canSee: (data: any) => data?.type?.value === "PF",
          },
        ],
        [
          {
            name: "issuing_body",
            label: "Orgão Emissor",
            type: "input",
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "nationality",
            label: "Nacionalidade",
            type: "input",
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "profession",
            label: "Profissão",
            type: "input",
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "marital_status",
            label: "Estado Civil",
            type: "select-fixed",
            options: maritalStatusOptions,
            isClearable: true,
            canSee: (data: any) => data?.type?.value === "PF",
            getIsDisabled: (data: any) => isPersonInPerson,
          },
        ],
        [
          {
            name: "spouse_id",
            label: "Cônjuge",
            type: "select-person",
            isClearable: true,
            getIsDisabled: (data: any) =>
              isPersonInPerson || personType === "responsible",
            canSee: (data: any) =>
              ["married", "stable_union"].includes(
                data?.marital_status?.value ?? ""
              ) && data?.type?.value === "PF",
            get_enabled_change_form_type: (data: any) => false,
            backgroundVisible: true,
            isPersonInPerson: true,
            personType: "spouse",
            getRemoveIds: (data: any) => {
              const ids = [];
              ids.push(data?.spouse_id?.value);
              ids.push(defaultPerson?.id);
              return ids;
            },
          },
        ],
        [
          {
            name: "representatives",
            label: "Representantes",
            type: "select-multi-persons",
            isClearable: true,
            getIsDisabled: (data: any) =>
              isPersonInPerson || personType === "responsible",
            searchOptions: consultsServices.person_id,
            get_enabled_change_form_type: (data: any) => false,
            backgroundVisible: true,
            isPersonInPerson: true,
            personType: "representative",
            getRemoveIds: (data: any) => {
              const ids =
                data?.representatives?.map((item: any) => item?.value) ?? [];
              ids.push(defaultPerson?.id);
              return ids;
            },
          },
        ],
        [
          {
            name: "broker_id",
            label: "Corretor",
            type: "select-single-cards-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.person_id,
            moreQueries: { person_types: ["broker"] },
            getIsDisabled: (data: any) =>
              user.type === "broker" || personType === "responsible",
          },
        ],
      ],
    },
    {
      name: "address",
      label: "Dados de Endereço",
      fields: [
        [
          {
            name: "cep",
            label: "CEP",
            type: "input",
            mask: "cep",
            validate: validateCEP,
            executeOnChange: changeCEP,
          },
          {
            name: "state_id",
            label: "Estado",
            type: "select-single-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.state_id,
            executeOnChange: async () => setValue("city_id", null),
          },
          {
            name: "city_id",
            label: "Cidade",
            type: "select-single-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.city_id,
            additionalsQueries: (data: any) => ({
              state_id: data?.state_id?.value,
            }),
            getIsDisabled: (data: any) => !data?.state_id,
          },
        ],
        [
          {
            name: "district",
            label: "Bairro",
            type: "input",
          },
          {
            name: "public_place",
            label: "Logradouro",
            type: "input",
          },
          {
            name: "complement",
            label: "Complemento",
            type: "input",
          },
          {
            name: "number",
            label: "Número",
            type: "input",
            mask: "onlyNumber",
          },
        ],
      ],
    },
    {
      name: "payment",
      label: "Dados de Pagamento",
      fields: [
        [
          {
            name: "payment_type",
            label: "Forma de recebimento padrão",
            type: "select-fixed",
            options: paymentTypesOptions,
            isClearable: true,
          },
        ],
        [
          {
            name: "bank_code",
            label: "Banco",
            type: "select-fixed",
            options: banksOptions,
            isClearable: true,
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "agency",
            label: "Agência",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "account",
            label: "Conta",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "account_type",
            label: "Tipo de conta",
            type: "select-fixed",
            options: accountTypesOptions,
            isClearable: true,
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "operation",
            label: "Operação",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
            tooltip:
              "É um identificador bancário, usado para informar o tipo de transação. Geralmente possui 3 dígitos. Informe apenas se necessário.",
          },
        ],
        [
          {
            name: "in_name",
            label: "Em nome de",
            type: "input",
            canSee: (data: any) =>
              ["DC", "DD", "RC", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
          {
            name: "cpf_cnpj",
            label: "CPF/CNPJ",
            type: "input",
            mask: "cpfOrCnpj",
            validate: validateCPFOrCNPJ,
            canSee: (data: any) =>
              ["DC", "DD", "RC", "TB", "DOC/TED", "PIX"].includes(
                data?.payment_type?.value ?? ""
              ),
          },
        ],
      ],
    },
  ];

  const groupsNew: IGroupProps[] = [
    {
      name: "registration_data",
      label: "Dados de Registro",
      fields: [
        [
          {
            name: "type",
            label: "Tipo",
            type: "select-fixed",
            required: true,
            getIsDisabled: (data: any) => !enabled_change_form_type,
            options: personsTypesOptions,
          },
        ],
        [
          {
            name: "corporate_name",
            label: "Razão Social",
            type: "input",
            required: true,
            canSee: (data: any) => data?.type?.value === "PJ",
          },
          {
            name: "fantasy_name",
            label: "Nome Fantasia",
            type: "input",
            required: true,
            canSee: (data: any) => data?.type?.value === "PJ",
          },
        ],
        [
          {
            name: "name",
            label: "Nome completo",
            type: "input",
            required: true,
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "cnpj",
            label: "CNPJ",
            type: "input",
            mask: "cnpj",
            required: true,
            validate: validateCNPJ,
            canSee: (data: any) => data?.type?.value === "PJ",
          },
          {
            name: "email",
            label: "Email",
            type: "input",
            validate: validateEmail,
          },
        ],
        [
          {
            name: 'foreigner',
            label: 'Estrangeiro',
            type: 'select-fixed',
            options: [{ label: 'Sim', value: true }, { label: 'Não', value: false }],
            isClearable: true,
            required: true,
            canSee: (data: any) => data?.type?.value === "PF",
          },
          {
            name: "cpf",
            label: "CPF",
            type: "input",
            mask: "cpf",
            validate: validateCPF,
            required: true,
            canSee: (data: any) => data?.type?.value === "PF" && !data?.foreigner?.value,
          },
          {
            name: "marital_status",
            label: "Estado Civil",
            type: "select-fixed",
            options: maritalStatusOptions,
            isClearable: true,
            canSee: (data: any) => data?.type?.value === "PF",
          },
        ],
        [
          {
            name: "spouse_id",
            label: "Cônjuge",
            type: "select-person",
            isClearable: true,
            getIsDisabled: (data: any) =>
              isPersonInPerson || personType === "responsible",
            get_enabled_change_form_type: (data: any) => false,
            backgroundVisible: true,
            isPersonInPerson: true,
            personType: "spouse",
            getRemoveIds: (data: any) => {
              const ids = [];
              ids.push(data?.spouse_id?.value);
              ids.push(defaultPerson?.id);
              return ids;
            },
            canSee: (data: any) =>
              ["married", "stable_union"].includes(
                data?.marital_status?.value ?? ""
              ) &&
              data?.type?.value === "PF" &&
              !isPersonInPerson,
          },
        ],
        [
          {
            name: "representatives",
            label: "Representantes",
            type: "select-multi-persons",
            isClearable: true,
            getIsDisabled: (data: any) =>
              isPersonInPerson || personType === "responsible",
            searchOptions: consultsServices.person_id,
            get_enabled_change_form_type: (data: any) => false,
            backgroundVisible: true,
            isPersonInPerson: true,
            personType: "representative",
            getRemoveIds: (data: any) => {
              const ids =
                data?.representatives?.map((item: any) => item?.value) ?? [];
              ids.push(defaultPerson?.id);
              return ids;
            },
            canSee: (data: any) => !isPersonInPerson,
          },
        ],
        [
          {
            name: "broker_id",
            label: "Corretor",
            type: "select-single-cards-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.person_id,
            moreQueries: { person_types: ["broker"] },
            getIsDisabled: (data: any) =>
              user.type === "broker" || personType === "responsible",
          },
        ],
      ],
    },
  ];

  return (
    <>
      <Column style={{ flex: 1, overflow: "auto", padding: 20 }}>
        <div>
          <Tabs
            tabs={!loading_documents ? tabs : disabledTabs}
            onChange={changeTab}
            pos={tabs.findIndex((tab) => tab.open)}
            disabled={loading_documents}
          />
        </div>

        <ContainerForm open={tabs.findIndex((tab) => tab.open) === 0}>
          <Form onSubmit={handleStopPropagation}>
            <GenericForm
              ref={form_ref}
              groups={defaultPerson?.id ? groupsEdit : groupsNew}
              _form={_form}
              control={control}
              trigger={trigger}
              errors={errors}
              disabled={loadingCEP || disabledAll}
              setValue={setValue}
              register={register}
            />
            <button
              className="display-none"
              type="submit"
              ref={submitButtonRef}
            />
          </Form>
        </ContainerForm>

        <ContainerDocuments open={tabs.findIndex((tab) => tab.open) === 1}>
          <Line
            style={{
              padding: 16,
              paddingBottom: 4,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Line
              style={{
                gap: 10,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ minHeight: 38, maxHeight: 38 }}
                onClick={() => setExibition("grid")}
                borderColor={secondary[theme]}
                background={exibition === "grid" ? secondary[theme] : "#FFF"}
                color={exibition === "list" ? secondary[theme] : "#FFF"}
                children={<BiGridAlt size={20} />}
              />
              <Button
                style={{ minHeight: 38, maxHeight: 38 }}
                onClick={() => setExibition("list")}
                borderColor={secondary[theme]}
                background={exibition === "list" ? secondary[theme] : "#FFF"}
                color={exibition === "grid" ? secondary[theme] : "#FFF"}
                children={<BiListUl size={20} />}
              />
              <Button
                style={{ minHeight: 38, maxHeight: 38 }}
                onClick={() => setOpenDocumentModal(true)}
                borderColor={secondary[theme]}
                background={secondary[theme]}
                color="#FFF"
                children={
                  <div style={{ whiteSpace: "nowrap" }}>Anexar documento</div>
                }
              />
            </Line>
          </Line>
          <ListDocuments
            exibition={exibition}
            open={true}
            customer={defaultPerson as any}
            relation={"person"}
            property={null as any}
            loading={loading_documents}
            documents={documents}
            onSave={onSaveDocument}
            colors={primary}
          />
        </ContainerDocuments>
      </Column>

      {tabs.findIndex((tab) => tab.open) === 0 && (
        <ContainerButtons theme={theme}>
          <Button
            onClick={() => onCancel()}
            background={inactiveItens[theme]}
            color={"#FFF"}
            style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
            children={"Voltar"}
          />
          {(["responsible", "analyst", "manager", "root"].includes(user.type) ||
            !disabledAll) && (
              <Button
                onClick={() => submitButtonRef.current?.click()}
                background={secondary[theme]}
                color={"#FFF"}
                style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
                children={"Salvar"}
              />
            )}
        </ContainerButtons>
      )}

      <ModalLoading loading={loadingCreate} theme={theme} />

      {openDocumentModal && (
        <ModalGeneric onClose={() => { }} open={openDocumentModal}>
          <DocumentModal
            onClose={() => setOpenDocumentModal(false)}
            customer={defaultPerson}
            property={null as any}
            relation={"person"}
            onSave={onSaveDocument}
          />
        </ModalGeneric>
      )}
    </>
  );
};
