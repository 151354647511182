import Button from 'react-bootstrap/Button'
import styled, { css } from "styled-components";
import { secondary } from "../../../theme";

interface GenericButtonProps {
    color: string
    background: string
    border?: string
}

export const GenericButton = styled(Button) <GenericButtonProps>`
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 100%;
    font-size:13px;
    transition: all 0s;
    text-transform: uppercase;
    font-weight: 600;
    ${props => css`
        background: ${props.background} !important; 
        color: ${props.color} !important; 
    `}
    ${props => !props.border && css`
        border: none !important;
    `}
    ${props => props.border && css`
        border: 1px solid ${props.border} !important;
    `}
    outline: none !important;
    border-radius: 5px;
    box-shadow:none !important;
    :hover{
        box-shadow:0 8px 25px -8px rgba(237,113,23,0.7);
        border: 2px solid ${secondary.light};
    }
    :focus{
        box-shadow:none !important;
    }
    :disabled{
        cursor: not-allowed !important;
    }
`