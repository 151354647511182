import styled, { css } from "styled-components";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { inactiveSubItens, shadowColor } from "../../../theme";

export const IconArrow = styled(MdKeyboardArrowDown) <{ theme: themeTypes, open: boolean }>`
    transition:transform 0.2s;
    font-size: 11px;
    ${props => css`
        color: ${inactiveSubItens[props.theme as themeTypes]};
    `}
    ${props => props.open && css`
        transform:rotate(90deg);
    `}
    ${props => !props.open && css`
        transform:rotate(0deg);
    `}
`

export const ItemDropdown = styled.div<IThemeProps>`
    display: flex;
    padding: 12px;
    align-items: center;
    gap:10px;
    ${props => css`
        :hover{
            background: ${props.theme === "light" ? "#edeff0" : "#433f3f"};
        }
    `}
`

export const Content = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-weight: 600 !important;
    position: relative;
    *{
        text-transform: uppercase !important;
    }
`

export const ContainerIcon = styled.div`
    display: flex;
    align-items: center;
`

export const ContainerDropItems = styled.div <{ color: string, background: string, borderColor: string, minWidth: any, zIndex: any }>`
    display: flex;
    font-weight: 600;
    flex-direction: column;
    font-size: 11px;
    position: absolute;
    border-radius: 4px;
    padding: 8px 0px;
    bottom: 0px;
    transform: translateY(calc(100% + 10px));
    right: -1px;
    *{
        text-transform: uppercase !important;
    }
    ${props => css`
        z-index: ${props.zIndex};
        min-width: ${props.minWidth + 10}px;
        color: ${props.color};
        background: ${props.background};
        box-shadow: 0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
    `};
`