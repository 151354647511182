import React, { useCallback, useEffect } from "react";
import Button from "../../../components/Buttons/Button";
import { Container } from "./style";
import { Line } from "../../../style";
import { GrUpdate } from "react-icons/gr";
import { useApi } from "../../../hooks/api";
import { BsArrowRight } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import { themeTypes } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";
import { AiFillCheckCircle } from "react-icons/ai";
import { ModalGeneric } from "../../../components/ModalGeneric";
import { ProgressStatic } from "../../../components/ProgressStatic";
import { WaveLoading } from "../../../components/Loadings/WaveLoading";
import { fail, inactiveItens, info, secondary, success } from "../../../theme";

interface IModalImport {
    open: boolean
    onClose: () => void
    onFinish: () => void
    onCancel: () => void
    theme: themeTypes
    franchise_id: string
}

const calculateAbilitiesPersons = (persons: any[]) => {
    const conjuges = persons.filter((person: any) => person?.spouse?.registration_data?.cpf).map((person: any) => person.spouse)
    const correct_persons = persons
    return correct_persons.length + conjuges.length
}

export const ModalImport: React.FC<IModalImport> = ({ onClose, onCancel, onFinish, open, theme, franchise_id }) => {
    const { api } = useApi()
    const { notify } = useGlobal()

    const [steps, setSteps] = React.useState(1)
    const [finished, setFinished] = React.useState(false)
    const [fails_count, setFailsCount] = React.useState(0)
    const [exist_count, setExistCount] = React.useState(0)
    const [success_count, setSuccessCount] = React.useState(0)
    const [customers, setCustomers] = React.useState<any[]>([])
    const [total_importeds, setTotalImporteds] = React.useState(0)

    const transformPlural = useCallback((word_single, word_plural, length) => {
        return length > 1 ? word_plural : word_single
    }, [])

    const handleFinishImport = useCallback(async () => {
        setFinished(true)
        onFinish()
    }, [])

    const searchClientsOfSuperlogic = useCallback(async () => {
        try {
            const to_import = JSON.stringify(["corretores", "pessoas"])
            const result = await api.get(`/integrations/super_logica/get-persons?to_import=${to_import}`)
            setCustomers(result.data)
            setSteps(2)
        } catch (err) {
            notify("Erro ao buscar pessoas da Superlógica! Verifique suas credenciais!", "error")
            onClose()
        }
    }, [franchise_id])

    const confirmImportClients = useCallback(async () => {
        const customers_to_import = customers
        let success_importeds = 0
        let fail_importeds = 0
        let exists_importeds = 0

        setSteps(3)

        for (const customer of customers_to_import) {
            try {
                const { data } = await api.post(`/integrations/super_logica/import-person`, { person: customer })
                const { person_exist, spouse_exist } = data
                if (customer?.spouse?.registration_data?.cpf && !spouse_exist) success_importeds++
                if (customer?.spouse?.registration_data?.cpf && spouse_exist) exists_importeds++
                if (person_exist) exists_importeds++
                else success_importeds++
            } catch (err) {
                if (customer?.spouse?.registration_data?.cpf) fail_importeds++
                fail_importeds++
            }
            setTotalImporteds((prev) => prev + 1)
        }

        setTotalImporteds((prev) => prev + 1) // +1 for finish

        if (customers_to_import.length === 0) handleFinishImport()

        setSuccessCount(success_importeds)
        setFailsCount(fail_importeds)
        setExistCount(exists_importeds)

    }, [franchise_id, customers])

    useEffect(() => { searchClientsOfSuperlogic() }, [])

    return (
        <ModalGeneric open={open} onClose={() => { }}>
            {steps === 1 && (
                <Container style={{ minHeight: 400 }} theme={theme}>
                    <div>Buscando pessoas da <b>Superlógica</b></div>
                    <div style={{ display: "flex", flex: 1 }}>
                        <WaveLoading />
                    </div>
                    <Button
                        style={{ padding: 8, fontSize: 14 }}
                        onClick={() => onClose()}
                        background={inactiveItens[theme]}
                        color="#FFF"
                        children="Cancelar"
                    />
                </Container>
            )}
            {steps === 2 && (
                <Container theme={theme}>
                    <div style={{ textAlign: "center" }}>
                        {transformPlural("Foi encontrada", "Foram encontradas", calculateAbilitiesPersons(customers))} <b>{calculateAbilitiesPersons(customers)}</b> {transformPlural("pessoa", "pessoas", calculateAbilitiesPersons(customers))} com CPF/CNPJ na <b>Superlógica</b>,
                        deseja confirmar a importação?
                    </div>
                    <ul>
                        <li style={{ fontSize: 14, opacity: 0.7 }}>
                            Pessoas sem um <b>nome</b> ou sem um <b>CPF/CNPJ</b> não serão importados!
                        </li>
                        <li style={{ fontSize: 14, opacity: 0.7 }}>
                            Após a confirmação não será mais possível cancelar esta ação!
                        </li>
                    </ul>
                    <Line style={{ gap: 10, width: "100%" }}>
                        <Button
                            style={{ padding: 8, fontSize: 14 }}
                            onClick={() => confirmImportClients()}
                            background={secondary[theme]}
                            color="#FFF"
                            children="Confirmar"
                        />
                        <Button
                            style={{ padding: 8, fontSize: 14 }}
                            onClick={() => onClose()}
                            background={inactiveItens[theme]}
                            color="#FFF"
                            children="Cancelar"
                        />
                    </Line>
                </Container>
            )}
            {steps === 3 && !finished && (
                <Container theme={theme}>
                    <div style={{ textAlign: "center" }}>
                        Importando <b>{calculateAbilitiesPersons(customers)}</b> {transformPlural("pessoa", "pessoas", calculateAbilitiesPersons(customers))} da <b>Superlógica</b>
                    </div>
                    <ProgressStatic
                        onFinished={handleFinishImport}
                        theme={theme}
                        max={customers.length}
                        min={0}
                        atual={total_importeds}
                    />
                    <div style={{ fontSize: 14, textAlign: "center", opacity: 0.7 }}>
                        Esta operação pode demorar alguns minutos! Permaneça nesta tela até o fim da importação!
                    </div>
                </Container>
            )}
            {steps === 3 && finished && (
                <Container theme={theme}>
                    <div style={{ textAlign: "center", fontWeight: 600 }}>
                        Resumo das importações
                    </div>
                    <table style={{ marginTop: -10 }}>
                        <tbody>
                            <tr>
                                <td>
                                    <AiFillCheckCircle color={success} />
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Pessoas importadas
                                </td>
                                <td style={{ padding: 10 }}>
                                    <BsArrowRight />
                                </td>
                                <td style={{ padding: 10, width: "100%", fontWeight: 600, color: success }}>
                                    {success_count}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <FiAlertCircle color={fail} />
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Pessoas não importadas
                                </td>
                                <td style={{ padding: 10 }}>
                                    <BsArrowRight />
                                </td>
                                <td style={{ padding: 10, width: "100%", fontWeight: 600, color: fail }}>
                                    {fails_count}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <GrUpdate color={info} />
                                </td>
                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                    Pessoas atualizadas
                                </td>
                                <td style={{ padding: 10 }}>
                                    <BsArrowRight />
                                </td>
                                <td style={{ padding: 10, width: "100%", fontWeight: 600, color: info }}>
                                    {exist_count}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Button
                        style={{ padding: 8, fontSize: 14 }}
                        onClick={() => onClose()}
                        background={inactiveItens[theme]}
                        color="#FFF"
                        children="Fechar"
                    />
                </Container>
            )}
        </ModalGeneric>
    )
}