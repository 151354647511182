import valid from "card-validator";
import Card from "react-credit-cards";
import React, { useImperativeHandle, useCallback, useRef, useState, FormEvent } from "react";
import { Pix } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { RiBarcodeFill } from "react-icons/ri";
import { BsCreditCardFill } from "react-icons/bs";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { PlanIcons } from "../../../../../utils/icons";
import { useGlobal } from "../../../../../hooks/global";
import { FinishSubscription } from "../FinishSubscription";
import { MagicButton } from "../../../../Buttons/MagicButton";
import { ModalLoading } from "../../../../Loadings/ModalLoading";
import { personsTypesOptions } from "../../../../../utils/options";
import { maskFunctions } from "../../../../../services/maskServices";
import { GerencianetCartao } from "../../../../../utils/gerencianetCartao";
import { GenericForm, IGenericFormRefProps } from "../../../../GenericForm";
import { IBillingDataProps, IGroupProps, buy_payment_methods, plansAttributes } from "../../../../../interfaces";
import { ChangeButton, ContainerCard, ContainerCreditCardData, ContainerIconPlan, ContainerPaymentMethodsOptions, Form, Option, ShoppingCart } from "./style";
import { validateCEP, validateCNPJ, validateCPF, validateCardNumber, validateDate, validateDueDate, validateEmail, validatePhone } from "../../../../../utils/validatesFields";

interface ICreditDataForm {
    default_data: IBillingDataProps
    default_plan?: plansAttributes
    onChangePlan?: Function
    amount: number
    onSucess: () => void
}

export interface CreditDataRefProps {
    forceSubmit: () => any
}

const methodsAllowed: buy_payment_methods[] = ["credit_card", "banking_billet"]

const correct_methods: any = {
    "credit_card": {
        label: "Cartão de crédito",
        icon: <BsCreditCardFill color="#FFF" size={26} />
    },
    "pix": {
        label: "Pix",
        icon: <Pix style={{ color: "#FFF" }} />
    },
    "banking_billet": {
        label: "Boleto bancário",
        icon: <RiBarcodeFill color="#FFF" size={26} />
    }
}

const formatDate = (date: any) => {
    const d = new Date(date)
    let month = "" + (d.getMonth() + 1)
    let day = "" + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
}

const getBrand = (cardNumber: any) => {
    // const brand_types = ["visa", "mastercard", "amex", "elo", "hipercard"]
    let value = cardNumber.replace(/\D+/g, "")?.split("") || [""]

    while (value.length > 16) value.splice(16, 1)

    const correct_value = value.join("")
    const card = valid.number(correct_value)

    const correct_card_type = card?.card?.type === "american-express" ? "amex" : card?.card?.type

    return correct_card_type
}

const CreditDataFormComponent: React.ForwardRefRenderFunction<CreditDataRefProps, ICreditDataForm> = (props, ref) => {
    const { default_data, default_plan, onChangePlan = () => { }, amount, onSucess } = props

    const { theme, notify } = useGlobal()
    const { consultsServices, api } = useApi()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: default_data as any,
        mode: "all"
    });

    const _form = watch()

    const [loadingCEP, setLoadingCEP] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const [payment_method, setPaymentMethod] = useState<buy_payment_methods>("credit_card")
    const [focus, setFocus] = useState<"name" | "cvc" | "expiry" | "number" | undefined>(undefined)

    const buttonSubmitRef = useRef<HTMLButtonElement>(null)
    const creditCardFormRef = useRef<IGenericFormRefProps>(null)
    const complementFormRef = useRef<IGenericFormRefProps>(null)

    const forceSubmit = useCallback(() => buttonSubmitRef.current?.click(), [buttonSubmitRef])

    const changeCEP = useCallback(async (search) => {
        search = search.replace(/\D/g, "")
        if (search.length === 8) {
            setLoadingCEP(true)

            const { address, city, state } = await consultsServices.address({ search })

            if (address) {
                setValue("public_place", address.logradouro)
                setValue("district", address.bairro)
                setValue("complement", address.complemento)
            }

            if (city) setValue("city_id", { label: city.name, value: city.id, this: city })

            if (state) setValue("state_id", { label: state.name, value: state.id, initials: state.initials, this: state })

            setLoadingCEP(false)

            trigger()
        }
    }, [])

    const onSubmit = useCallback(async () => setOpenModalConfirm(true), [_form, creditCardFormRef, complementFormRef, payment_method, api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, creditCardFormRef, complementFormRef, payment_method, api])

    const onPaymentConfirm = useCallback(async () => {
        setOpenModalConfirm(false)

        setLoadingSave(true)

        try {

            const creditDataForm = creditCardFormRef.current?.getForm()
            const complementCreditDataForm = complementFormRef.current?.getForm()

            const city_name = _form?.city_id?.this?.name
            const state_initials = _form?.state_id?.this?.initials

            const credit_card = creditDataForm?.credit_card
            const address = complementCreditDataForm?.address
            let customer = complementCreditDataForm?.customer

            if (amount > 0) {
                if (customer) {
                    customer.birth = customer?.birth ? formatDate(customer?.birth) : undefined
                    customer.juridical_person = {
                        corporate_name: customer?.corporate_name,
                        cnpj: customer?.cnpj
                    }

                    delete customer?.type
                    delete customer?.corporate_name
                    delete customer?.cnpj
                }

                if (payment_method === "credit_card") {
                    delete customer?.juridical_person

                    credit_card.brand = getBrand(credit_card?.cardNumber)
                    credit_card.number = credit_card?.cardNumber
                    credit_card.expiration_month = credit_card?.dueDate?.slice(0, 2)
                    credit_card.expiration_year = credit_card?.dueDate?.slice(3, 7)
                }
            }

            const data = {
                credit_card: {},
                plan_id: default_plan?.id,
                type: payment_method,
                banking_billet: { customer, expire_at: formatDate(new Date()) },
            }

            if (payment_method === "credit_card" && amount > 0) {
                const sdkGn = await GerencianetCartao.instance(process.env.REACT_APP_GERENCIANET_ACCOUNT_ID, process.env.REACT_APP_MODE === "PROD");
                const { card_mask, payment_token } = await sdkGn.getPaymentToken({ ...credit_card });

                const billing_address = {
                    street: address.public_place,
                    number: address.number,
                    neighborhood: address.district,
                    zipcode: address.cep,
                    city: city_name,
                    state: state_initials
                }

                data.credit_card = {
                    card_mask,
                    card_flag: credit_card.brand,
                    payment_token,
                    customer,
                    billing_address
                }
            }


            await api.post(`/subscriptions`, data)

            onSucess()

        } catch (err) {
            console.log(err)
            notify("Problema ao assinar plano! Tente novamente mais tarde!", "alert")
        }

        setLoadingSave(false)

    }, [_form, creditCardFormRef, complementFormRef, payment_method, api])

    const creditCardGroups: IGroupProps[] = [
        {
            label: "Dados do cartão",
            name: "credit_card",
            fields: [
                [
                    {
                        label: "Número do cartão",
                        name: "cardNumber",
                        type: "input",
                        mask: "creditCard",
                        validate: validateCardNumber,
                        required: true
                    }
                ],
                [
                    {
                        label: "Vencimento",
                        name: "dueDate",
                        type: "input",
                        mask: "dueDate",
                        validate: validateDueDate,
                        required: true
                    }
                ],
                [
                    {
                        label: "Código de segurança",
                        name: "cvv",
                        type: "input",
                        mask: "onlyNumber",
                        required: true,
                        validate: async (value: any) => {
                            if ([3, 4].includes(value?.length)) return true
                            else return "Formato inválido"
                        }
                    }
                ]
            ]
        }
    ]

    const complementGroups: IGroupProps[] = [
        {
            label: "Seus dados",
            name: "customer",
            canSee: () => ["credit_card", "banking_billet"].includes(payment_method),
            fields: [
                [
                    {
                        name: "type",
                        label: "Tipo",
                        type: "select-fixed",
                        required: true,
                        options: personsTypesOptions,
                        canSee: () => payment_method === "banking_billet"
                    }
                ],
                [
                    {
                        label: "Nome completo",
                        name: "name",
                        type: "input",
                        required: true,
                        canSee: (data: any) => ["credit_card"].includes(payment_method) || (["banking_billet"].includes(payment_method) && data?.type?.value === "PF")
                    },
                    {
                        name: "corporate_name",
                        label: "Razão Social",
                        type: "input",
                        required: true,
                        canSee: (data: any) => ["banking_billet"].includes(payment_method) && data?.type?.value === "PJ"
                    },
                    {
                        label: "Email",
                        name: "email",
                        type: "input",
                        validate: validateEmail,
                        required: true,
                        canSee: () => ["banking_billet", "credit_card"].includes(payment_method)
                    }
                ],
                [
                    {
                        name: "cnpj",
                        label: "CNPJ",
                        type: "input",
                        mask: "cnpj",
                        validate: validateCNPJ,
                        required: true,
                        canSee: (data: any) => ["banking_billet"].includes(payment_method) && data?.type?.value === "PJ"
                    },
                    {
                        label: "CPF",
                        name: "cpf",
                        type: "input",
                        mask: "cpf",
                        validate: validateCPF,
                        required: true,
                        canSee: (data: any) => ["credit_card"].includes(payment_method) || (["banking_billet"].includes(payment_method) && data?.type?.value === "PF")
                    },
                    {
                        label: "Telefone",
                        name: "phone_number",
                        type: "input",
                        mask: "phone",
                        validate: validatePhone,
                        required: true,
                        canSee: () => ["banking_billet", "credit_card"].includes(payment_method)
                    },
                    {
                        label: "Data de nascimento",
                        name: "birth",
                        type: "input",
                        mask: "date",
                        validate: validateDate,
                        required: true,
                        canSee: (data: any) => ["credit_card"].includes(payment_method) || (["banking_billet"].includes(payment_method) && data?.type?.value === "PF")
                    }
                ]
            ]
        },
        {
            name: "address",
            label: "Dados de Endereço",
            canSee: () => ["credit_card"].includes(payment_method),
            fields: [
                [
                    {
                        name: "cep",
                        label: "CEP",
                        type: "input",
                        mask: "cep",
                        validate: validateCEP,
                        executeOnChange: changeCEP,
                        required: true,
                        canSee: () => payment_method === "credit_card"
                    },
                    {
                        name: "state_id",
                        label: "Estado",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        searchOptions: consultsServices.state_id,
                        executeOnChange: async () => setValue("city_id", null),
                        required: true,
                        canSee: () => payment_method === "credit_card"
                    },
                    {
                        name: "city_id",
                        label: "Cidade",
                        type: "select-single-no-creatable",
                        isClearable: true,
                        searchOptions: consultsServices.city_id,
                        additionalsQueries: (data: any) => ({ state_id: data?.state_id?.value }),
                        getIsDisabled: (data: any) => !data?.state_id,
                        required: true,
                        canSee: () => payment_method === "credit_card"
                    }
                ],
                [
                    {
                        name: "district",
                        label: "Bairro",
                        type: "input",
                        canSee: () => payment_method === "credit_card",
                        required: true
                    },
                    {
                        name: "public_place",
                        label: "Logradouro",
                        type: "input",
                        canSee: () => payment_method === "credit_card",
                        required: true
                    },
                    {
                        name: "complement",
                        label: "Complemento",
                        type: "input",
                        canSee: () => payment_method === "credit_card"
                    },
                    {
                        name: "number",
                        label: "Número",
                        type: "input",
                        mask: "onlyNumber",
                        canSee: () => payment_method === "credit_card",
                        required: true
                    }
                ]
            ]
        }
    ]

    useImperativeHandle(ref, () => ({ forceSubmit }))

    return (
        <>
            <Form onSubmit={handleStopPropagation}>
                {default_plan?.id &&
                    <ShoppingCart theme={theme}>
                        <Line style={{ gap: 10, alignItems: "center" }}>
                            <ContainerIconPlan theme={theme}>
                                <PlanIcons type={default_plan.icon} size={26} opacity={1} />
                            </ContainerIconPlan>
                            <Column style={{ justifyContent: "center" }}>
                                <b>
                                    {default_plan.name}
                                </b>
                                <div style={{ fontSize: 14 }}>
                                    Valor mensal de {maskFunctions.currency.mask(default_plan.value)}
                                </div>
                            </Column>
                        </Line>

                        <Column style={{ justifyContent: "center" }}>
                            <ChangeButton
                                theme={theme}
                                onClick={() => { onChangePlan() }}
                                children="Alterar"
                            />
                        </Column>
                    </ShoppingCart>
                }

                {(Number.parseFloat(`${amount}`)) > 0 && (
                    <>
                        <Column>
                            <Column style={{ gap: 10 }}>
                                <b>Método de pagamento</b>
                                <ContainerPaymentMethodsOptions>
                                    {methodsAllowed.map((method) => (
                                        <Option key={method + "-button"} theme={theme} onClick={() => setPaymentMethod(method)}>
                                            <MagicButton
                                                disabled={false}
                                                theme={theme}
                                                type="radio"
                                                checked={payment_method === method}
                                                onClick={() => setPaymentMethod(method)}
                                                {...correct_methods[method]}
                                            />
                                        </Option>
                                    ))}
                                </ContainerPaymentMethodsOptions>
                            </Column>

                            {payment_method === "credit_card" && (
                                <ContainerCreditCardData>
                                    <GenericForm
                                        ref={creditCardFormRef}
                                        groups={creditCardGroups}
                                        _form={_form}
                                        control={control}
                                        errors={errors}
                                        trigger={trigger}
                                        setValue={setValue}
                                        register={register}
                                        disabled={loadingCEP}
                                        containerStyle={{ flex: 1 }}
                                    />
                                    <ContainerCard>
                                        <Card
                                            cvc={_form?.cvv ?? ""}
                                            expiry={_form?.dueDate ?? ""}
                                            focused={focus}
                                            name={_form?.name ?? ""}
                                            number={_form?.cardNumber ?? ""}
                                            locale={{ valid: "Validade" }}
                                            placeholders={{ name: "NOME SOBRENOME" }}
                                        />
                                    </ContainerCard>
                                </ContainerCreditCardData>
                            )}

                            <GenericForm
                                ref={complementFormRef}
                                groups={complementGroups}
                                _form={_form}
                                control={control}
                                errors={errors}
                                trigger={trigger}
                                setValue={setValue}
                                register={register}
                                disabled={loadingCEP}
                            />
                        </Column>

                    </>
                )}

                <button
                    ref={buttonSubmitRef}
                    type="submit"
                    style={{ display: "none" }}
                />

                <ModalLoading
                    theme={theme}
                    loading={loadingSave}
                />
            </Form>

            {openModalConfirm && default_plan?.id &&
                <FinishSubscription
                    planSelected={default_plan}
                    openModal={openModalConfirm}
                    onCancel={() => setOpenModalConfirm(false)}
                    onConfirm={onPaymentConfirm}
                    theme={theme}
                />
            }
        </>
    )
}

export const CreditDataForm = React.forwardRef(CreditDataFormComponent)