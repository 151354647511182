import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../../interfaces";
import { background, inactiveItens, shadowColor } from "../../../../theme";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap:10px;
`

export const Anchor = styled.a<IThemeProps>`
    font-weight: 600;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]} !important;
    `}
`

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    outline: 0;
    min-width:100vw;
    max-width:100vw;
    min-height: calc(100 * var(--vh));
    max-height: calc(100 * var(--vh));
    @media (min-width: 600px) {
        min-width: 550px;
        max-width: 550px;
        min-height: 0px;
    }
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
        box-shadow: 0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
    `}
`

export const ContainerCrmLogo = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    gap: 10px;
    font-weight: 600;
    font-size: 22px;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 20px;
    gap:20px;
    flex: 1;
`

export const TextInformation = styled.div`
    background: #FFF3CD;
    border-radius: 5px;
    color: #856404;
    padding: 20px;
`

export const InputVariable = styled.input<IThemeProps>`
    background: transparent;
    resize: none;
    border: none;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]} ;
    `}
`