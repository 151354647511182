import styled, { css } from "styled-components";
import { themeTypes } from "../../../../interfaces";
import { primary, shadowColor } from "../../../../theme";

export const Table = styled.table<{ theme: themeTypes }>`
    border-spacing: 0 0px;
    border-collapse: initial;
    font-size: 13.6px;
    width: 100%;
    ${props => css`
        background:${primary[props.theme as themeTypes]} ;
    `}
`

export const TH = styled.th<{ theme: themeTypes }>`
    border-bottom: 1px solid #e3e3e3;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 16px 10px;
    font-size: 12px;
    opacity: 0.7;
`

export const TD = styled.td<{ isLeft?: boolean, isRight?: boolean, theme: themeTypes, noHaveClick?: boolean }>`
    padding: 10px;
    border-bottom: 1px solid #e3e3e3;
    font-size: 16px;
    white-space:nowrap;
    cursor: ${props => props.noHaveClick ? 'default' : 'pointer'};
`

export const Card = styled.div<{ theme: themeTypes }>`
    display: flex;
    flex-direction:column;
    padding: 20px;
    border-radius: 5px;
    ${props => css`
        box-shadow:0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
        background:${primary[props.theme as themeTypes]} ;
    `}
`

export const LabelNoData = styled.div`
    font-style: italic;
    display: flex;
    padding: 20px;
    opacity:0.5;
    font-weight: 600;
    flex: 1;
    justify-content: center;
    align-items: center;
`