import React, { FormEvent, useCallback } from "react";
import Button from "../../../../components/Buttons/Button";
import { FiKey } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { StepHeader } from "./../../StepHeader";
import { useGlobal } from "../../../../hooks/global";
import { IGroupProps } from "../../../../interfaces";
import { validateEmail } from "../../../../utils/validatesFields";
import { ContainerLink, ContentFields, Form, Link } from "./style";
import { GenericForm } from "../../../../components/GenericForm";

interface ISendEmailStepProps {
    onNext: (email: string) => void;
    setLoading: (loading: boolean) => void;
}

export const SendEmailStep: React.FC<ISendEmailStepProps> = (props) => {
    const { onNext, setLoading } = props;

    const { api } = useApi()
    const { notify } = useGlobal()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        mode: "all"
    });

    const _form = watch()

    const onSubmit = useCallback(async (data: any) => {

        setLoading(true)

        try {

            await api.post("/recover_password", data)

            onNext(data?.email)

        } catch (err: any) {

            const error = err.response ? err.response.data : "SERVER ERROR"

            if (error !== "SERVER ERROR" && err.response) {
                let _error = ""
                if (error.message === "User not found") _error = "Email não cadastrado"
                else _error = "Formato inválido"
                setError("email", { type: "manual", message: _error })
            }
            else notify("ERRO INTERNO DO SISTEMA.", "error")

        }

        setLoading(false)

    }, [api])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, api])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "email",
                        label: "Email",
                        type: "input",
                        validate: validateEmail,
                        required: true
                    }
                ]
            ]
        }
    ]

    return (
        <Form onSubmit={handleStopPropagation}>

            <StepHeader
                colors={["#F3E1D7", "#F1C0A6"]}
                title={"Esqueceu a senha?"}
                description={"Não se preocupe, nós iremos lhe enviar instruções para resetá-la."}
                icon={<FiKey size={22} color={secondary.light} />}
            />

            <ContentFields>

                <GenericForm
                    groups={groups}
                    _form={_form}
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    setValue={setValue}
                    register={register}
                />

                <Button
                    color="#FFF"
                    background={secondary.light}
                    type="submit"
                    style={{ fontSize: 15, padding: "8px 16px", marginTop: 10, marginBottom: 10 }}
                    children={<div style={{ fontWeight: "600" }}>Resetar senha</div>}
                />

            </ContentFields>

            <ContainerLink>
                <Link to="/login">
                    <BsArrowLeft />
                    Voltar para o login
                </Link>
            </ContainerLink>

        </Form>
    )
}