import styled, { css } from "styled-components";
import { themeTypes } from "../../interfaces";
import { borderDisabled, disabledItems, primary } from "../../theme";

export const BaseInput = styled.div<{ haveValue: boolean, disabled?: boolean }>`
    display: flex;
    min-height: 100%;
    gap: 5px;
    padding: 0px;
    align-items: center;
    width:100%;
    border-radius: 4px;
    cursor: pointer;
    ${props => css`
        border: 1px solid ${props.theme === "light" ? "#CCCCCC" : "rgb(68 68 68)"} !important;
        background-color: ${primary[props.theme as themeTypes]} !important;
    `}
    ${props => props.haveValue && css`
        padding-right: 0px !important;
    `}
    ${props => props.disabled && css`
        background-color: ${disabledItems[props.theme as themeTypes]} !important;
        color:#999999 !important;
        border-color: ${borderDisabled[props.theme as themeTypes]} !important;
        pointer-events: none !important;
    `}

`

export const ContainerRemove = styled.div`
    transition: 0.3s all;
    color: #CCCCCC;
    padding: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover{
        color: #999999;
    }
`