import styled, { css } from "styled-components";

interface ContainerProps {
    haveData: boolean;
}

export const Container = styled.div<ContainerProps>`
    display:flex;
    height:100%;
    ${props => !props.haveData && css`
        justify-content: center;
        align-items: center;
    `}
`

export const LabelNoData = styled.div`
    font-style: italic;
    display: flex;
    opacity:0.5;
    font-weight: 600;
    flex: 1;
    justify-content: center;
    align-items: center;
`