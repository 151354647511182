import styled, { css } from "styled-components";
import { inactiveItens } from "../../theme";
import { IThemeProps, themeTypes } from "../../interfaces";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    flex:1;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`