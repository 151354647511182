import Switch from "rc-switch";
import React, { useCallback, useEffect } from "react"
import { TiCancel } from "react-icons/ti";
import { FiDownload } from "react-icons/fi";
import { useGlobal } from "../../hooks/global";
import { primary, secondary } from "../../theme";
import { CSSProperties } from "styled-components";
import { AiOutlineRollback } from "react-icons/ai";
import { MagicButton } from "./../Buttons/MagicButton";
import { IconButton, Tooltip } from "@material-ui/core";
import { BsFillBookmarkPlusFill } from "react-icons/bs";
import { copyOf } from "../../services/generalServices";
import { ContentOrLoading } from "../Loadings/ContentOrLoading";
import { Table, TD, TH, SwitchContainer, NoData } from "./style";
import { MdOutlineCancel, MdOutlineRemoveRedEye } from "react-icons/md";

export interface ITable {
    data: { [key: string]: any }[]
    columns: { attr: string, name: string, style: CSSProperties, styleContent: CSSProperties, limitWidth?: boolean }[]
}

export interface ITableList {
    table: ITable
    loading: boolean
    have_status_franchise?: boolean
    have_status?: boolean
    have_edit?: boolean
    have_delete?: boolean
    have_cancel?: boolean
    have_accept?: boolean
    have_rollback?: boolean
    have_is_test_free?: boolean
    have_download?: boolean
    have_default?: boolean
    haveIsTrusted?: boolean
    statusText?: string
    textDelete?: string
    accept_action?: (id: any) => void
    cancel_action?: (id: any) => void
    rollback_action?: (id: any) => void
    edit_action?: (id: any) => void
    delete_action?: (id: any) => void
    status_action?: (_id: any, old_status: any) => void
    change_default_action?: (_id: any, old_status: any) => void
    is_test_free_action?: (_id: any, old_status: any) => void
    haveIsTrustedAction?: (_id: any, old_status: any) => void
    canEdit?: (item: any) => boolean
    canCancel?: (item: any) => boolean
    canRollback?: (item: any) => boolean
    canDownload?: (item: any) => boolean
    getUrlDownload?: (item: any) => string
    text_loading: string
    text_empty: string
    getTextDownload?: (item: any) => string
    onChangeSelecteds?: (selecteds: any[]) => void
    listActions?: any[]
}

export const TableList: React.FC<ITableList> = (props) => {
    const {
        table,
        loading,
        have_status_franchise,
        have_status,
        have_delete,
        have_edit,
        have_cancel,
        have_accept,
        have_rollback,
        have_is_test_free,
        have_download,
        have_default,
        accept_action = () => { },
        cancel_action = () => { },
        rollback_action = () => { },
        status_action = () => { },
        change_default_action = () => { },
        edit_action = () => { },
        delete_action = () => { },
        is_test_free_action = () => { },
        canEdit = () => true,
        canCancel = () => true,
        canRollback = () => true,
        canDownload = () => true,
        onChangeSelecteds = () => { },
        text_empty,
        text_loading,
        statusText = "Status",
        textDelete = "Excluir",
        getUrlDownload = () => "",
        getTextDownload = () => "Download",
        listActions = [],
        haveIsTrusted,
        haveIsTrustedAction = () => { }
    } = props

    const { theme, getCorrectMaxWidth } = useGlobal()

    const [idsCheckeds, setIdsCheckeds] = React.useState<string[]>([])

    const handleCheck = useCallback((id: string) => {
        setIdsCheckeds((old) => {
            const new_checkeds = copyOf(old)
            if (new_checkeds.includes(id)) {
                new_checkeds.splice(new_checkeds.indexOf(id), 1)
            }
            else {
                new_checkeds.push(id)
            }
            return [...new_checkeds]
        })
    }, [])

    const handleCheckAll = useCallback(() => {
        setIdsCheckeds((old) => {
            if (allIsChecked(old, table.data)) return []
            return [...old, ...table.data.map((item) => item.id)]
        })
    }, [table])

    const allIsChecked = (checkeds: string[], infos: any[]) => {
        for (const info of infos) {
            if (!checkeds.includes(info.id)) return false
        }
        return true
    }

    const handleDownload = useCallback((id: string) => {
        const anchor = document.getElementById(id)
        if (anchor) anchor.click()
    }, [])

    useEffect(() => { onChangeSelecteds(idsCheckeds) }, [idsCheckeds])

    return (
        <ContentOrLoading loading={loading} text={text_loading} theme={theme}>
            {table.data.length > 0 &&
                <div style={{ display: "flex", overflow: "auto", maxWidth: getCorrectMaxWidth() }}>
                    <Table>
                        <thead>
                            <tr>
                                {table.columns.filter((column) => column.attr === "indicator").map(({ attr, name, style }) => (
                                    <TH key={attr} style={{ ...style }}>{name}</TH>
                                ))}
                                <TH style={{ position: "relative", width: 50, textAlign: "center" }}>
                                    <MagicButton
                                        checked={allIsChecked(idsCheckeds, table.data)}
                                        onClick={handleCheckAll}
                                        theme={theme}
                                        type="checkbox"
                                    />
                                </TH>
                                {table.columns.filter((column) => column.attr !== "indicator").map(({ attr, name, style }) => (
                                    <TH key={attr} style={{ ...style }}>{name}</TH>
                                ))}
                                {haveIsTrusted &&
                                    <TH style={{ textAlign: "center" }}>Confiança</TH>
                                }
                                {(have_status || have_status_franchise) &&
                                    <TH style={{ textAlign: "center" }}>{statusText}</TH>
                                }
                                {have_is_test_free && (
                                    <TH style={{ textAlign: "center", whiteSpace: "nowrap" }}>Teste Grátis</TH>
                                )}
                                {have_default && (
                                    <TH style={{ textAlign: "center", whiteSpace: "nowrap" }}>Padrão</TH>
                                )}
                                <TH style={{ textAlign: "center" }}>Ações</TH>
                            </tr>
                        </thead>
                        <tbody>
                            {table.data.map((item) => (
                                <tr style={{ background: primary[theme], borderRadius: 5, boxShadow: "0.125rem 0.125rem 0.5rem #57545419" }}>
                                    {table.columns.filter((column) => column.attr === "indicator").map(({ attr, styleContent, limitWidth }, index) => (
                                        <TD noHaveClick isLeft className={limitWidth ? "overflow-name-table" : ""} noData={!(["0", 0].includes(item[attr]) || !!item[attr])} style={{ ...styleContent }}>
                                            {item[attr] ?? "Não informado"}
                                        </TD>
                                    ))}
                                    <TD noHaveClick isLeft={table.columns.filter((column) => column.attr === "indicator").length === 0} style={{ textAlign: "center", width: 50, whiteSpace: "nowrap", position: "relative" }}>
                                        <MagicButton
                                            checked={idsCheckeds.includes(item.id)}
                                            onClick={() => handleCheck(item.id)}
                                            theme={theme}
                                            type="checkbox"
                                        />
                                    </TD>
                                    {table.columns.filter((column) => column.attr !== "indicator").map(({ attr, styleContent, limitWidth }, index) => (
                                        <TD onClick={() => edit_action(item.this)} className={limitWidth ? "overflow-name-table" : ""} noData={!(["0", 0].includes(item[attr]) || !!item[attr])} style={{ ...styleContent, textTransform: "uppercase" }}>
                                            {item[attr] ?? "Não informado"}
                                        </TD>
                                    ))}

                                    {haveIsTrusted &&
                                        <TD noHaveClick style={{ padding: 10, textAlign: "center" }}>
                                            <SwitchContainer theme={theme}>
                                                <Switch
                                                    className="custom-switch custom-switch-primary custom-switch-small"
                                                    checked={item.this.is_trusted}
                                                    onClick={() => haveIsTrustedAction(item.this.id, item.this.is_trusted)}
                                                />
                                            </SwitchContainer>
                                        </TD>
                                    }

                                    {have_status &&
                                        <TD noHaveClick style={{ padding: 10, textAlign: "center" }}>
                                            <SwitchContainer theme={theme}>
                                                <Switch
                                                    className="custom-switch custom-switch-primary custom-switch-small"
                                                    checked={item?.this?.user ? (item.this?.user?.status === "active") : (item?.this?.active ? item?.this?.active : item?.this?.is_default)}
                                                    onClick={() => item?.this?.user ? status_action(item?.this?.user?.id, item?.this?.user?.status) : (item?.this?.active ? status_action(item?.this?.id, item?.this?.active) : status_action(item?.this?.id, item?.this?.is_default))}
                                                />
                                            </SwitchContainer>
                                        </TD>
                                    }

                                    {have_default &&
                                        <TD noHaveClick style={{ padding: 10, textAlign: "center" }}>
                                            <MagicButton
                                                type="radio"
                                                checked={item?.this?.is_default}
                                                label=""
                                                onClick={() => change_default_action(item?.this?.id, item?.this?.is_default)}
                                                theme={theme}
                                            />
                                        </TD>
                                    }

                                    {have_status_franchise &&
                                        <TD noHaveClick style={{ padding: 10, textAlign: "center" }}>
                                            <SwitchContainer theme={theme}>
                                                <Switch
                                                    className="custom-switch custom-switch-primary custom-switch-small"
                                                    checked={item.this.status === "active"}
                                                    onClick={() => status_action(item.this.id, item.this.status)}
                                                />
                                            </SwitchContainer>
                                        </TD>
                                    }

                                    {have_is_test_free &&
                                        <TD noHaveClick style={{ padding: 10, textAlign: "center" }}>
                                            <MagicButton
                                                type="radio"
                                                checked={item.this.is_test_free}
                                                label=""
                                                onClick={() => is_test_free_action(item.this.id, item.this.is_test_free)}
                                                theme={theme}
                                            />
                                        </TD>
                                    }

                                    <TD noHaveClick isRight style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                        {have_edit && canEdit(item.this) &&
                                            <Tooltip onClick={() => edit_action(item.this)} title={"Visualizar"} style={{ margin: 5 }}>
                                                <IconButton aria-label={"Visualizar"}>
                                                    <MdOutlineRemoveRedEye
                                                        color={secondary[theme]}
                                                        size={20}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {have_download && canDownload(item.this) &&
                                            <Tooltip onClick={() => handleDownload(item.this.id)} title={getTextDownload(item.this)} style={{ margin: 5 }}>
                                                <IconButton aria-label={getTextDownload(item.this)}>
                                                    <FiDownload
                                                        color={secondary[theme]}
                                                        size={20}
                                                    />
                                                    <a id={item.this.id} href={getUrlDownload(item.this)} target="_blank" style={{ display: "none" }} />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {have_cancel && canCancel(item.this) &&
                                            <Tooltip onClick={() => cancel_action(item.this)} title={"Cancelar"} style={{ margin: 5 }}>
                                                <IconButton aria-label={"Cancelar"}>
                                                    <TiCancel
                                                        color={secondary[theme]}
                                                        size={20}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {have_rollback && canRollback(item.this) &&
                                            <Tooltip onClick={() => rollback_action(item.this)} title={"Desfazer Cancelamento"} style={{ margin: 5 }}>
                                                <IconButton aria-label="Desfazer Cancelamento">
                                                    <AiOutlineRollback
                                                        color={secondary[theme]}
                                                        size={20}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {have_delete &&
                                            <Tooltip onClick={() => delete_action(item.this)} title={textDelete} style={{ margin: 5 }}>
                                                <IconButton aria-label={textDelete}>
                                                    <MdOutlineCancel
                                                        color={secondary[theme]}
                                                        size={20}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {have_accept &&
                                            <Tooltip onClick={() => accept_action(item.this)} style={{ margin: 5, borderRadius: 4, background: secondary[theme], padding: 5 }} title="Assumir oportunidade">
                                                <IconButton aria-label="Assumir oportunidade">
                                                    <BsFillBookmarkPlusFill
                                                        size={20}
                                                        color="#FFF"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {listActions.map((action) => {
                                            if (!action.disabled(item.this)) {
                                                return (
                                                    <Tooltip key={action.title} onClick={() => action.onClick(item.this)} style={{ margin: 5 }} title={action.title}>
                                                        <IconButton aria-label={action.title}>
                                                            {action.icon}
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                        })}
                                    </TD>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            }
            {table.data.length === 0 &&
                <NoData>{text_empty}</NoData>
            }
        </ContentOrLoading>
    )
}