import styled from "styled-components";
import { Column } from "../../../../style";
import { secondary } from "../../../../theme";
import { Link as BaseLink } from "react-router-dom";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`

export const ContentFields = styled(Column)`
    width: 100%;
    gap: 16px;
`

export const Link = styled(BaseLink)`
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    color: #212529;
    font-weight: 600;
`

export const ContainerLink = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Span = styled.span`
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    color: ${secondary.light};
`

export const ContainerResend = styled.div`
    color: #7E838E;
    text-align: center;
`