import React, { useEffect, useState, useCallback } from "react";
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { modules } from "../../utils/modules";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { maskFunctions } from "../../services/maskServices";
import { ModalPlan } from "../../components/Modals/ModalPlan";
import { setUpConsultation } from "../../services/generalServices";
import { IActions, IFilters, ITagList, plansAttributes } from "../../interfaces";

const initialFilters: IFilters[] = []

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome", limitWidth: true, style: {}, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "value", name: "Valor", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "users_limit", name: "Usuários", style: { textAlign: "center", whiteSpace: "nowrap" }, styleContent: { textAlign: "center" } },
        { attr: "cloud_limit", name: "Armazenamento", style: { textAlign: "center", whiteSpace: "nowrap" }, styleContent: { textAlign: "center" } },
        { attr: "contract_templates_limit", name: "Modelos", style: { textAlign: "center", whiteSpace: "nowrap" }, styleContent: { textAlign: "center" } },
        { attr: "pj_unit_value", name: "Valor PJ - uni", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "pf_unit_value", name: "Valor PF - uni", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "doc_unit_value", name: "Valor DOC - uni", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { textAlign: "center" } }
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Novo Plano", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

const getPermissions: React.FC<ITagList> = ({ permissions, theme }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5, justifyContent: "center" }}>
            {permissions?.map((permission: any) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], color: "#FFF", fontWeight: 600, fontSize: 12 }}>
                            {modules.find((module) => module.name === permission)?.friendlyName}
                        </div>
                    </div>
                )
            })}
            {permissions?.length === 0 &&
                <span style={{ fontStyle: "italic", opacity: 0.7, fontSize: 14 }}>Nenhum(a)</span>
            }
        </div>
    )
}

export const PlansList: React.FC = () => {
    const { api } = useApi()
    const { theme, notify, franchise, paginationLimit } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [numOfPlans, setNumOfPlans] = useState(0)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [loadingPlans, setLoadingPlans] = useState(true)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [planSelected, setPlanSelected] = useState<plansAttributes | null>(null)

    const transformPlural = useCallback((word_single, word_plural, length) => {
        return length > 1 ? word_plural : word_single
    }, [])

    const searchPlans = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingPlans(true)
        try {
            const filters_obj = _filters ? { ..._filters, name: _filters.search } : { ...filters, name: searchString }

            const string = setUpConsultation(filters_obj)
            const url = `/plans?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_plans = result.data.rows as plansAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_plans.forEach((plan) => {
                    new_table.data.push({
                        id: plan.id,
                        name: plan.name,
                        users_limit: plan.users_limit,
                        cloud_limit: plan.cloud_limit + " GB",
                        contract_templates_limit: plan.contract_templates_limit,
                        permissions: getPermissions({ permissions: JSON.parse(plan.permissions as string), theme }),
                        value: maskFunctions.currency.mask(plan.value),
                        frequency: `A cada ${plan.frequency} ${transformPlural("mês", "meses", plan.frequency)}`,
                        recurrence: plan.recurrence ? `${plan.recurrence} ${transformPlural("vez", "vezes", plan.recurrence)}` : "Ilimitado",
                        pj_unit_value: maskFunctions.currency.mask(plan.pj_unit_value),
                        pf_unit_value: maskFunctions.currency.mask(plan.pf_unit_value),
                        doc_unit_value: maskFunctions.currency.mask(plan.doc_unit_value),
                        this: plan
                    })
                })
                return { ...new_table }
            })

            setNumOfPlans(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))

            setUpdatedAt(new Date())
        } catch (err) {
            console.log(err)
            notify("Erro na busca pelos Planos!", "error")
        }
        setLoadingPlans(false)
    }, [franchise, filters, searchString])

    const changeStatusPlan = useCallback(async (plan_id: string, old_status: boolean) => {
        const new_status = !old_status

        setTable((atual) => {
            const new_table = { ...atual }
            const pos = new_table.data.findIndex((manager: any) => manager?.this?.id === plan_id)
            if (pos !== -1) new_table.data[pos].this.active = new_status
            return { ...new_table }
        })

        try {
            await api.put(`/plans/change-active/${plan_id}`, { new_active: new_status })
            notify("Status do Plano atualizado com sucesso.", "success")
        } catch (err) {
            notify("Erro ao alterar status do Plano.", "error")
            setTable((atual) => {
                const new_table = { ...atual }
                const pos = new_table.data.findIndex((manager: any) => manager?.this?.id === plan_id)
                if (pos !== -1) new_table.data[pos].this.active = old_status
                return { ...new_table }
            })
        }

    }, [])

    const handleIsTestFree = useCallback(async (plan_id: string, old_is_test_free: boolean) => {
        const new_is_test_free = !old_is_test_free

        setTable((atual) => {
            const new_table = { ...atual }
            const pos = new_table.data.findIndex((manager: any) => manager?.this?.id === plan_id)
            for (let i = 0; i < new_table.data.length; i++) {
                new_table.data[i].this.is_test_free = false
            }
            if (pos !== -1) new_table.data[pos].this.is_test_free = new_is_test_free
            return { ...new_table }
        })

        try {
            await api.put(`/plans/change-is-test-free/${plan_id}`, { new_is_test_free })
            notify("Plano do teste grátis atualizado com sucesso.", "success")
        } catch (err) {
            notify("Erro ao alterar plano do teste grátis.", "error")
            setTable((atual) => {
                const new_table = { ...atual }
                const pos = new_table.data.findIndex((manager: any) => manager?.this?.id === plan_id)
                for (let i = 0; i < new_table.data.length; i++) {
                    new_table.data[i].this.is_test_free = false
                }
                if (pos !== -1) new_table.data[pos].this.is_test_free = old_is_test_free
                return { ...new_table }
            })
        }

    }, [])

    const selectPlan = useCallback((plan: plansAttributes) => {
        setPlanSelected({ ...plan })
        setOpenModal(true)
    }, [])

    const openModalNewPlan = useCallback(() => {
        setPlanSelected(null)
        setOpenModal(true)
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewPlan()
        else console.log({ action_id })
    }

    const onSavePlan = useCallback(() => {
        setOpenModal(false)
        searchPlans(1)
    }, [franchise, filters, searchString])

    useEffect(() => { searchPlans(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingPlans}
            setLoading={setLoadingPlans}
            numberOfItems={numOfPlans}
            textHeader="Planos"
            textItems="planos encontrados"
            statusText="Disponível"
            theme={theme}
            search={searchPlans}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_status
            have_edit
            have_is_test_free
            is_test_free_action={handleIsTestFree}
            status_action={changeStatusPlan}
            edit_action={selectPlan}
            text_loading={"Buscando Planos"}
            text_empty={"Nenhum plano encontrado"}
            onClickOption={handleAction}
            actions={actionsInitial}
            pages={numberOfPages}
            page={atualPage}
            onChangeSearch={setSearchString}
        >
            {openModal &&
                <ModalPlan
                    onCancel={() => setOpenModal(false)}
                    onSave={onSavePlan}
                    openModal={openModal}
                    backgroundVisible={false}
                    defaultId={planSelected?.id}
                />
            }
        </PageList>
    )
}