import React, { useCallback, useEffect, useState } from "react"
import { useApi } from "../../hooks/api";
import { BiExport } from "react-icons/bi";
import { ModalExport } from "./ModalExport";
import { ModalImport } from "./ModalImport";
import { useGlobal } from "../../hooks/global";
import { useWallet } from "../../hooks/wallet";
import { ITable } from "../../components/TableList";
import { IoAddCircleOutline } from "react-icons/io5";
import { PageList } from "../../components/PageList";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { ModalPerson } from "../../components/Modals/ModalPerson";
import { getBroker, getFranchise } from "../../utils/columnsTables";
import { ALL_USERS, ROOT_USERS, STAFF_USERS } from "../../utils/users.groups";
import { initialsName, setUpConsultation } from "../../services/generalServices";
import { IActions, IIntegrationsProps, IFilters, ITagList, personsAttributes, IThemeProps, themeTypes } from "../../interfaces/index";
import { Superlogica16 } from "../../components/SvgComponents/Icons/Superlogica/Superlogica16";
import { secondary } from "../../theme";

const initialFilters: IFilters[] = [
    getFilterFields("franchise_id", ROOT_USERS),
    getFilterFields("broker_id", STAFF_USERS),
    getFilterFields("state_id", ALL_USERS),
    getFilterFields("city_id", ALL_USERS),
    getFilterFields("person_type", ALL_USERS)
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome", limitWidth: true, style: {}, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "cpf_cnpj", name: "CPF/CNPJ", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "email", name: "Email", limitWidth: true, style: {}, styleContent: {} },
        { attr: "phone", name: "Telefone", style: {}, styleContent: {} },
        { attr: "city", name: "Cidade", style: {}, styleContent: {} },
        { attr: "broker", name: "Corretor(a)", style: { textAlign: "center" }, styleContent: { textAlign: "center" } }
    ]
}

const initialTableRoot: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome", limitWidth: true, style: {}, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "cpf_cnpj", name: "CPF/CNPJ", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "email", name: "Email", limitWidth: true, style: {}, styleContent: {} },
        { attr: "phone", name: "Telefone", style: {}, styleContent: {} },
        { attr: "city", name: "Cidade", style: {}, styleContent: {} },
        { attr: "franchise", name: "Imobiliária", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
    ]
}

const getCPFOrCNPJ = (customer: personsAttributes, theme: themeTypes) => {

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, color: secondary[theme], border: `1px solid ${secondary[theme]}`, fontWeight: 600, fontSize: 12 }}>
                {customer?.registration_data?.type === "PF" && (customer?.registration_data?.cpf ? maskFunctions.cpf.mask(customer?.registration_data?.cpf) : "ESTRANGEIRO")}
                {customer?.registration_data?.type === "PJ" && (customer?.registration_data?.cnpj ? maskFunctions.cnpj.mask(customer?.registration_data?.cnpj) : "ESTRANGEIRO")}
            </div>
        </div>
    )
}

const getCity = (customer: personsAttributes) => {
    if (customer?.address?.city && customer?.address?.state) {
        return `${customer?.address?.city.name} - ${customer?.address?.state?.initials}`
    }
    else return null
}

const getNameWithPhoto: React.FC<ITagList> = ({ customer }) => {
    if (customer.user?.photo) return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 30, maxWidth: 30, maxHeight: 30, minHeight: 30, borderRadius: "50%", overflow: "hidden" }}>
                <img src={customer.user.photo} alt="Foto" style={{ minWidth: 30, minHeight: 30, objectFit: "cover" }} />
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{customer?.registration_data?.name || null}</div>
        </div>

    )
    else return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: 12, color: "rgb(21 21 21)", background: "rgb(206 206 206)", alignItems: "center", minWidth: 30, maxWidth: 30, minHeight: 30, maxHeight: 30, borderRadius: "50%" }}>
                {initialsName(customer?.registration_data?.name as string)}
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{customer?.registration_data?.name || null}</div>
        </div>
    )
}

export const PersonsList: React.FC = () => {
    const { user, api } = useApi()
    const { subscription } = useWallet()
    const { theme, paginationLimit, notify, franchise } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [numOfPersons, setNumOfPersons] = useState(0)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [selecteds, setSelecteds] = useState<string[]>([])
    const [loadingPersons, setLoadingPersons] = useState(true)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [openModalImport, setOpenModalImport] = useState(false)
    const [openModalExport, setOpenModalExport] = useState(false)
    const [openModalExportAll, setOpenModalExportAll] = useState(false)
    const [personSelected, setPersonSelected] = useState<personsAttributes | null>(null)
    const [table, setTable] = useState<ITable>(user.type === "root" ? initialTableRoot : initialTable)
    const [integrationsTokens, setIntegrationsTokens] = useState<IIntegrationsProps>({ super_logica: {}, click_sign: {} } as IIntegrationsProps)

    const selectCustomer = useCallback((customer: personsAttributes) => {
        setPersonSelected({ ...customer })
        setOpenModal(true)
    }, [])

    const searchPersons = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingPersons(true)
        try {
            await searchIntegrationsTokens()
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }

            let string = setUpConsultation(filters_obj)
            let url = `/generic_searches/persons?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)
            const new_customers = result.data.rows as personsAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_customers.forEach((customer) => {
                    new_table.data.push({
                        id: customer.id,
                        name: getNameWithPhoto({ customer, theme }),
                        cpf_cnpj: getCPFOrCNPJ(customer, theme),
                        status: customer.user?.status,
                        email: customer?.user?.email || null,
                        phone: customer?.registration_data?.phone ? maskFunctions.phone.mask(customer?.registration_data?.phone) : null,
                        city: getCity(customer),
                        broker: getBroker({ person: customer, theme }),
                        franchise: getFranchise({ person: customer, theme }),
                        this: customer
                    })
                })
                return { ...new_table }
            })

            setNumOfPersons(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelas pessoas!", "error")
        }
        setLoadingPersons(false)
    }, [user, franchise, filters, searchString])

    const onSaveCustomer = useCallback(() => {
        setOpenModal(false)
        searchPersons(1)
    }, [user, franchise, filters, searchString])

    const openModalNewCustomer = useCallback(() => {
        setPersonSelected(null)
        setOpenModal(true)
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewCustomer()
        else if (action_id === "import_super") setOpenModalImport(true)
        else if ("export_all" === action_id) setOpenModalExportAll(true)
        else if ("export_super" === action_id) setOpenModalExport(true)
        else console.log({ action_id })
    }

    const handleFinish = useCallback(() => searchPersons(atualPage), [atualPage, franchise, filters, searchString])

    const cancelImportOrExport = useCallback(() => window.location.reload(), [])

    const validateAccessCRM = useCallback(() => {
        const access_token = integrationsTokens.super_logica.access_token
        const app_token = integrationsTokens.super_logica.app_token
        const status = integrationsTokens.super_logica.status
        const permissions = JSON.parse(subscription?.plan?.permissions as any ?? "[]")
        const plan_have_crm = !!permissions?.includes("crms")
        return !!access_token && !!app_token && status === "active" && plan_have_crm
    }, [integrationsTokens, subscription])

    const actionsInitial: IActions[] = [
        {
            only_to_selecteds: true,
            main_option: { text: "Exportar", id: "export" },
            className: "export",
            icon: <BiExport size={18} className="export" style={{ marginTop: -5 }} />,
            options: [
                {
                    text: "Exportar - Superlógica",
                    id: "export_super",
                    validateAccess: validateAccessCRM,
                    icon: <Superlogica16 color={"#1034F2"} />
                }
            ],
            canAccess: ["manager", "responsible", "analyst"]
        },
        {
            main_option: { text: "Exportar Todos", id: "export_main_all" },
            options: [
                {
                    text: "Exportar Todos - Superlógica",
                    id: "export_all",
                    validateAccess: validateAccessCRM,
                    icon: <Superlogica16 color={"#1034F2"} />
                }
            ],
            className: "export_main_all",
            icon: <BiExport size={18} className="export_main_all" style={{ marginTop: -5 }} />,
            canAccess: ["manager", "responsible", "analyst"],
            validateAccess: validateAccessCRM
        },
        {
            main_option: { text: "Nova Pessoa", id: "new_item" },
            className: "new_item",
            icon: <IoAddCircleOutline size={20} className="new_item" />,
            options: [],
            type: "solid",
            canAccess: ["manager", "responsible", "analyst", "broker"]
        }
    ]

    const searchIntegrationsTokens = useCallback(async () => {

        try {

            const { data } = await api.get(`/integrations/get-tokens`)

            setIntegrationsTokens(data)

        } catch (err) {

            notify("Erro ao buscar as integrações", "error")

        }

        return true
    }, [])

    useEffect(() => { searchPersons(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingPersons}
            setLoading={setLoadingPersons}
            numberOfItems={numOfPersons}
            actions={actionsInitial}
            onClickOption={handleAction}
            textHeader="Pessoas"
            textItems="pessoas encontradas"
            text_loading={"Buscanda Pessoas"}
            text_empty={"Nenhuma pessoa encontrada"}
            theme={theme}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_edit
            onChangeSelecteds={setSelecteds}
            edit_action={(customer: personsAttributes) => selectCustomer(customer)}
            pages={numberOfPages}
            page={atualPage}
            search={searchPersons}
            onChangeSearch={setSearchString}
        >
            {openModal &&
                <ModalPerson
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSaveCustomer()}
                    openModal={openModal}
                    backgroundVisible={false}
                    defaultId={personSelected?.id}
                    enabled_change_form_type
                />
            }
            {openModalImport &&
                <ModalImport
                    onClose={() => setOpenModalImport(false)}
                    open={openModalImport}
                    onCancel={cancelImportOrExport}
                    onFinish={handleFinish}
                    theme={theme}
                    franchise_id={franchise.id}
                />
            }
            {openModalExport &&
                <ModalExport
                    onClose={() => setOpenModalExport(false)}
                    open={openModalExport}
                    onFinish={handleFinish}
                    theme={theme}
                    franchise_id={franchise.id}
                    to_export={selecteds.map((id) => ({ id } as personsAttributes))}
                />
            }
            {openModalExportAll &&
                <ModalExport
                    onClose={() => setOpenModalExportAll(false)}
                    open={openModalExportAll}
                    onFinish={handleFinish}
                    theme={theme}
                    franchise_id={franchise.id}
                    to_export={[]}
                />
            }
        </PageList>
    )
}
