import styled, { css } from "styled-components";

export const Container = styled.div`
    flex: 1;
    position: relative;
    min-height: 38px;
    min-width: 350px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
`

export const ContainerIcon = styled.div<{ position: "left" | "right", cursor: "pointer" | "text" | "default" }>`
    display: flex;
    padding: 10px;
    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    ${props => props.position === "left" && css`
        left: 0px;
    `}
    ${props => props.position === "right" && css`
        right: 0px;
    `}
    ${props => css`
        cursor: ${props.cursor};
    `}
`