import React, { FormEvent, useCallback } from "react";
import Button from "../../../../components/Buttons/Button";
import { FiKey } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { BsArrowLeft } from "react-icons/bs";
import { StepHeader } from "../../StepHeader";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { useGlobal } from "../../../../hooks/global";
import { IGroupProps } from "../../../../interfaces";
import { ContainerLink, ContentFields, Form, Link } from "./style";
import { GenericForm } from "../../../../components/GenericForm";

interface IResetStepProps {
    onNext: () => void;
    setLoading: (loading: boolean) => void;
    setPassword: (password: string) => void;
    email: string;
}

export const ResetStep: React.FC<IResetStepProps> = (props) => {
    const { onNext, setLoading, email, setPassword } = props;

    const { api } = useApi()
    const { notify } = useGlobal()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        mode: "all"
    });

    const _form = watch()

    const onSubmit = useCallback(async (form: any) => {

        setLoading(true)

        try {
            const { code, new_password } = form
            const data = { email, code, new_password }

            await api.post("/recover_password/reset_password", data)

            setPassword(new_password)

            onNext()

        } catch (err: any) {

            const error = err.response ? err.response.data : "SERVER ERROR"
            if (error !== "SERVER ERROR" && err.response) setError("code", { type: "manual", message: "Código inválido" })
            else notify("ERRO INTERNO DO SISTEMA.", "error")

        }

        setLoading(false)

    }, [api, _form])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, api])

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "code",
                        label: "Código",
                        type: "input",
                        required: true
                    }
                ],
                [
                    {
                        name: "new_password",
                        label: "Nova senha",
                        type: "input",
                        required: true,
                        isPassword: true
                    }
                ],
                [
                    {
                        name: "confirm_password",
                        label: "Confirmar senha",
                        type: "input",
                        required: true,
                        isPassword: true,
                        validate: async (value: any) => {
                            const password = watch("new_password")
                            if (value !== password) return "As senhas não conferem."
                            return true
                        }
                    }
                ]
            ]
        }
    ]

    return (
        <Form onSubmit={handleStopPropagation}>

            <StepHeader
                colors={["#F3E1D7", "#F1C0A6"]}
                icon={<FiKey size={22} color={secondary.light} />}
                title={"Informe a nova senha"}
                description={"Crie uma nova senha e poderá acessar a Max Locação novamente."}
            />

            <ContentFields>

                <GenericForm
                    groups={groups}
                    _form={_form}
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    setValue={setValue}
                    register={register}
                />

                <Button
                    type="submit"
                    color="#FFF"
                    background={secondary.light}
                    style={{ fontSize: 15, padding: "8px 16px", marginTop: 10, marginBottom: 10 }}
                    children={<div style={{ fontWeight: "600" }}>Resetar senha</div>}
                />

            </ContentFields>

            <ContainerLink>
                <Link to="/login">
                    <BsArrowLeft />
                    Voltar para o login
                </Link>
            </ContainerLink>

        </Form>
    )
}