import React, { useCallback, useEffect, useState } from "react";
import { uniqueId } from "lodash";
import { Container } from "./style";
import { FormProperties } from "./form";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { ModalGeneric } from "../../ModalGeneric";
import { useGlobal } from "../../../hooks/global";
import { propertiesAttributes } from "../../../interfaces";
import { ownersTypesOptions } from "../../../utils/options";
import { maskFunctions } from "../../../services/maskServices";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { mapDefaultForm, propertyDefaultForm } from "../../../utils/defaultFormsUtils";

interface IModalPropertyProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

const text = {
    title: {
        new: "Cadastro de Imóvel",
        edit: "Informações do Imóvel"
    },
    description: {
        new: "Informe os dados necessários para criar um novo Imóvel",
        edit: "Segue abaixo as informações do imóvel"
    }
}

export const ModalProperty: React.FC<IModalPropertyProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId = null } = props

    const { theme, notify } = useGlobal()
    const { api, user, markAsRead, notifications } = useApi()

    const [loading, setLoading] = useState(!!defaultId)
    const [defaultData, setDefaultData] = useState<any>(null)
    const [defaultOwners, setDefaultOwners] = useState<any>([])
    const [defaultProperty, setDefaultProperty] = useState<propertiesAttributes | null>(null)

    const searchProperty = useCallback(async () => {

        if (defaultId) {

            setLoading(true)

            try {

                const { data } = await api.get(`/properties/${defaultId}`)

                let _data: any = mapDefaultForm({ data, fields: propertyDefaultForm })

                const owners = _data.owners.map((owner: any) => {
                    const _uniqueId = uniqueId()
                    return {
                        ["customer_id-" + _uniqueId]: {
                            label: owner?.customer?.registration_data?.name,
                            value: owner?.customer?.id,
                            this: owner?.customer
                        },
                        ["type-" + _uniqueId]: ownersTypesOptions.find((e) => e?.value === owner?.type),
                        ["porcen-" + _uniqueId]: maskFunctions.porcen.mask(owner?.porcen, true),
                        uniqueId: _uniqueId
                    }
                })

                for (const owner of owners) _data = { ..._data, ...owner }

                _data.rental_fee = {
                    type: _data.rental_fee_type,
                    value: maskFunctions[_data.rental_fee_type === "VALUE" ? "currency" : "porcen"].mask(_data.rental_fee_value, true)
                }

                _data.managed = {
                    type: _data.managed_type,
                    value: maskFunctions[_data.managed_type === "VALUE" ? "currency" : "porcen"].mask(_data.managed_value, true)
                }

                delete _data.owners

                delete _data.rental_fee_type

                delete _data.rental_fee_value

                delete _data.managed_type

                delete _data.managed_value

                setDefaultOwners([...owners])

                setDefaultData({ ..._data })

                setDefaultProperty({ ...data })

            } catch (err) {

                notify("Erro ao buscar Imóvel", "error")

                onCancel()

            }
        }

        setLoading(false)

    }, [defaultId])

    useEffect(() => { searchProperty() }, [])

    useEffect(() => {
        if (defaultId) {
            const notiticationsUnread = notifications.filter(notification => {
                return notification.property_id === defaultId && notification.user_id === user.id
            })
            if (notiticationsUnread.length > 0) markAsRead({ user_id: user.id, property_id: defaultId })
        }
    }, [user, notifications, defaultId])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }} >
            <Container theme={theme}>

                <ModalHeader
                    title={text.title[defaultId ? "edit" : "new"]}
                    subTitle={text.description[defaultId ? "edit" : "new"]}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <ContentOrLoading loading={loading} theme={theme} type="wave">
                    <FormProperties
                        defaultData={defaultData}
                        defaultProperty={defaultProperty}
                        owners={defaultOwners}
                        onSave={onSave}
                        onCancel={onCancel}
                        onCreateNewContract={searchProperty}
                    />
                </ContentOrLoading>

            </Container>
        </ModalGeneric>
    )
}