import React, { useCallback, useEffect, useImperativeHandle, useState, useRef } from "react";
import { inactiveItens } from "../../../theme";
import { IoIosArrowDown } from "react-icons/io";
import { useGlobal } from "../../../hooks/global";
import { IconButton, Tooltip } from "@material-ui/core";
import { Line, Column, LineAllCenter } from "../../../style";
import { Container, ContainerArrow, ContainerContent, ContainerTitle, Content, Description, Title } from "./style";

interface IBag {
    title: string
    description: any
    icon?: any
    children?: any
    everOpen?: boolean
    defaultOpen?: boolean
}

export interface IBagRef {
    openBag: () => void
    closeBag: () => void
}

const BagComponent: React.ForwardRefRenderFunction<IBagRef, IBag> = (props, ref) => {
    const { children, everOpen = false, description, icon, title, defaultOpen = true } = props
    const { theme } = useGlobal()

    const div_ref = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState(everOpen);
    const [firstRender, setFirstRender] = useState(true);

    const getHeight = useCallback(() => {
        return div_ref.current ? div_ref.current.offsetHeight : 0
    }, [div_ref])

    const closeBag = useCallback(() => { setOpen(false) }, [])

    const openBag = useCallback(() => { setOpen(true) }, [])

    useEffect(() => {
        if (firstRender && defaultOpen) {
            setOpen(true)
            setFirstRender(() => false)
        }
    }, [firstRender, defaultOpen])

    useImperativeHandle(ref, () => ({ openBag, closeBag }))

    return (
        <Container theme={theme}>

            <ContainerTitle onClick={() => everOpen ? {} : setOpen((atual) => !atual)} everOpen={everOpen}>
                <Line style={{ gap: 10, alignItems: "center" }}>
                    {icon && (
                        <LineAllCenter style={{ minWidth: 36, maxWidth: 36 }}>
                            {icon}
                        </LineAllCenter>
                    )}
                    <Column>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </Column>
                </Line>
                {!everOpen &&
                    <Tooltip title={open ? "Fechar" : "Abrir"} style={{ width: 30, height: 30 }}>
                        <IconButton aria-label={open ? "Fechar" : "Abrir"}>
                            <ContainerArrow open={open}>
                                <IoIosArrowDown
                                    color={inactiveItens[theme]}
                                    size={16}
                                />
                            </ContainerArrow>
                        </IconButton>
                    </Tooltip>
                }
            </ContainerTitle>

            <ContainerContent open={open} everOpen={everOpen} height={getHeight()}>
                <Content theme={theme} ref={div_ref}>
                    {children}
                </Content>
            </ContainerContent>

        </Container>
    )
}

export const Bag = React.forwardRef(BagComponent)