import styled, { css } from "styled-components";
import { Column, Line } from "../../../style";
import { borderColors, primary } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const ContainerList = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${props => css`
        border-top: 1px solid ${borderColors[props.theme as themeTypes]};
    `}
`

export const Container = styled(Column)<IThemeProps>`
    border-radius: 4px;
    background: ${props => primary[props.theme as themeTypes]}; 
    border: 1px solid ${props => borderColors[props.theme as themeTypes]};
`

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
`

export const Description = styled.div`
    font-size: 12px;
    margin-top: -3px;
    text-transform: uppercase;
`

export const ContainerTitle = styled(Line)`
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`

export const ContentTitle = styled(Line)`
    gap: 10px;
    padding: 16px;
    flex: 1;
    align-items: center;
    cursor: pointer;
`

export const ContainerArrow = styled.div<{ open: boolean }>`
    cursor: pointer;
    transition: all 0.3s;
    ${props => props.open && css`
        transform: rotate(90deg);
    `}
`