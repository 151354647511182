import styled, { css } from "styled-components";
import { Column, Line } from "../../../style";
import { background, borderColors, primary } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const Container = styled(Column) <IThemeProps>`
    border-bottom: 1px solid ${props => borderColors[props.theme as themeTypes]};
    background: ${props => primary[props.theme as themeTypes]};
`

export const ContainerTitle = styled(Line)`
    gap: 10px; 
    padding: 16px;
    align-items: center; 
    justify-content: space-between; 
    overflow: hidden;
    cursor: pointer;
`

export const Title = styled.div`
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
    text-transform: uppercase;
`

export const Description = styled.div`
    font-size: 12px;
    margin-top: -3px;
    text-transform: uppercase;
`

export const ContainerArrow = styled.div<{ open: boolean }>`
    cursor: pointer;
    transition: all 0.3s;
    ${props => props.open && css`
        transform: rotate(90deg);
    `}
`

export const Content = styled(Column) <IThemeProps>`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${props => background[props.theme as themeTypes]};
    border-top: 1px solid ${props => borderColors[props.theme as themeTypes]};
`

export const EmptyText = styled.div`
     text-align: center;
     font-size: 14px;
     font-weight: 600;
     opacity: 0.7;
`

export const ContainerObsOrDocs = styled(Column) <IThemeProps>`
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid ${props => borderColors[props.theme as themeTypes]};
`

export const ObsOrDocsTitle = styled.div`
    padding: 10px;
    background: #E7E7E7;
    text-align: center;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`

export const ContentObsOrDocs = styled.div`
    padding: 10px;
`