import React from "react";
import Button from "../../../../components/Buttons/Button";
import { BsArrowLeft } from "react-icons/bs";
import { StepHeader } from "../../StepHeader";
import { secondary } from "../../../../theme";
import { AiOutlineMail } from "react-icons/ai";
import { ContainerLink, ContainerResend, ContentFields, Form, Link, Span } from "./style";

interface IReceiveCodeStepProps {
    onNext: () => void;
    email: string;
    sendCodeToEmail: (email: string) => void;
}


export const ReceiveCodeStep: React.FC<IReceiveCodeStepProps> = (props) => {
    const { onNext, email, sendCodeToEmail } = props;

    return (
        <Form>

            <StepHeader
                colors={["#F3E1D7", "#F1C0A6"]}
                icon={<AiOutlineMail size={22} color={secondary.light} />}
                title={"Verifique seu email"}
                description={(
                    <>
                        Nós enviamos um código para <b>{email.toLocaleLowerCase()}</b>
                    </>
                )}
            />

            <ContentFields>
                <Button
                    onClick={() => onNext()}
                    color="#FFF"
                    background={secondary.light}
                    type="button"
                    style={{ fontSize: 15, padding: "8px 16px" }}
                    children={<div style={{ fontWeight: "600" }}>Recebi o código</div>}
                />
            </ContentFields>

            <ContainerResend>
                Não recebeu o código? <Span onClick={() => { sendCodeToEmail(email) }}>
                    Clique para reenviar
                </Span>
            </ContainerResend>

            <ContainerLink>
                <Link to="/login">
                    <BsArrowLeft />
                    Voltar para o login
                </Link>
            </ContainerLink>

        </Form>
    )

}