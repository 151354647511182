import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { HiOutlineHome } from "react-icons/hi";
import { RiSuitcaseLine } from "react-icons/ri";
import { IoPersonCircle } from "react-icons/io5";
import { secondary } from "../../../../../theme";
import { useApi } from "../../../../../hooks/api";
import { Column, Line } from "../../../../../style";
import { useGlobal } from "../../../../../hooks/global";
import { BagDocuments } from "../../../../Bags/BagDocuments";
import { copyOf } from "../../../../../services/generalServices";
import { ContentOrLoading } from "../../../../Loadings/ContentOrLoading";
import { customers_proposalAttributes, propertiesAttributes } from "../../../../../interfaces";

export interface IStepRef {
    validate: () => boolean
}

interface IDocumentsStep {
    defaultId: string
    loading: boolean
    control: number
    setLoading: (loading: boolean) => void
    changeProposal: (step?: number) => void
    onCancel: () => void
    setUpdatedAt: (updatedAt: any) => void
    editDocuments: boolean
    setEditDocuments: (editWarranty: any) => void
}

const correct_relations: any = {
    guarantor: "Fiador",
    guarantor_spouse: "Cônjuge Fiador",
    tenant: "Locatário",
    tenant_spouse: "Cônjuge Locatário"
}

interface ICustomersInProposal extends customers_proposalAttributes {
    exibition: "grid" | "list"
    open: boolean
    loading: boolean
}

interface IPropertiesInProposal extends propertiesAttributes {
    exibition: "grid" | "list"
    open: boolean
    loading: boolean
}

const DocumentsStepComponent: React.ForwardRefRenderFunction<IStepRef, IDocumentsStep> = (props, ref) => {
    const { control, defaultId, loading, setLoading } = props

    const { api } = useApi()
    const { notify, theme } = useGlobal()

    const [customers, setCustomers] = useState<ICustomersInProposal[]>([])
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false)
    const [property, setProperty] = useState<IPropertiesInProposal | null>(null)

    const search = useCallback(async () => {
        setLoading(true)
        try {
            const result = await api.get(`/proposals/documents/${defaultId}`)
            setCustomers(result.data.customers_proposal.map((item: ICustomersInProposal) => {
                item.exibition = "list"
                return { ...item }
            }))
            const correct_property = { ...result.data.property, exibition: "list" } as IPropertiesInProposal
            correct_property.owners = correct_property.owners.map((owner: any) => {
                owner.exibition = "list"
                return owner
            })
            setProperty({ ...correct_property })
        } catch (err) {
            console.log(err)
            notify("Erro ao Buscar Documentos", "error")
        }
        setLoading(false)
    }, [])

    const searchBackground = useCallback(async () => {
        try {
            const result = await api.get(`/proposals/documents/${defaultId}`)
            setCustomers(result.data.customers_proposal.map((item: ICustomersInProposal) => {
                item.exibition = "list"
                return { ...item }
            }))
            const correct_property = { ...result.data.property, exibition: "list" } as IPropertiesInProposal
            correct_property.owners = correct_property.owners.map((owner: any) => {
                owner.exibition = "list"
                return owner
            })
            setProperty({ ...correct_property })
        } catch (err) {
            console.log(err)
            notify("Erro ao Buscar Documentos", "error")
        }
    }, [])

    const changeExibition = useCallback((exibition: "grid" | "list", pos: number | null, type: "customers" | "owners" | "property") => {
        if (pos !== null && type === "customers") {
            setCustomers((atual) => {
                const newCustomers = copyOf(atual)
                newCustomers[pos].exibition = exibition
                return [...newCustomers]
            })
        }
        if (pos !== null && type === "owners") {
            setProperty((atual) => {
                const newProperty = copyOf(atual) as IPropertiesInProposal
                newProperty.owners[pos].exibition = exibition
                return { ...newProperty }
            })
        }
        if (type === "property") {
            setProperty((atual) => {
                const newProperty = copyOf(atual) as IPropertiesInProposal
                newProperty.exibition = exibition
                return { ...newProperty }
            })
        }
    }, [])

    const validate = useCallback(() => {
        return true
    }, [])

    useImperativeHandle(ref, () => ({ validate }))

    useEffect(() => { search() }, [])

    useEffect(() => { searchBackground() }, [control])

    return (
        <Column style={{ gap: 20, flex: 1 }}>
            <ContentOrLoading loading={loading} theme={theme} type="wave">
                <>
                    <Line style={{ justifyContent: "space-between" }}>
                        <Column>
                            <div style={{ fontWeight: 600, color: secondary[theme], fontSize: 20 }}>Documentação</div>
                            <div>Envie abaixo a documentação relacionada a proposta</div>
                        </Column>
                    </Line>
                    <Column style={{ gap: 20 }}>
                        {customers.map((customer, index) => (
                            <BagDocuments
                                loading={loadingDocuments}
                                setLoading={setLoadingDocuments}
                                relation={customer.relation}
                                customer={customer.customer}
                                onClickExibition={(newExibition: any) => changeExibition(newExibition, index, "customers")}
                                exibition={customer.exibition}
                                title={customer.customer?.registration_data?.name as string}
                                description={correct_relations[customer.relation]}
                                icon={customer.customer?.registration_data?.type === "PF" ? <IoPersonCircle color={secondary[theme]} size={26} /> : <RiSuitcaseLine color={secondary[theme]} size={26} />}
                            />
                        ))}
                        {property &&
                            <>
                                {property.owners.map((owner, index) => (
                                    <BagDocuments
                                        loading={loadingDocuments}
                                        setLoading={setLoadingDocuments}
                                        relation={"owner"}
                                        customer={owner.customer}
                                        onClickExibition={(newExibition: any) => changeExibition(newExibition, index, "owners")}
                                        exibition={owner?.exibition}
                                        title={owner.customer?.registration_data?.name as string}
                                        description="Proprietário"
                                        icon={owner.customer?.registration_data?.type === "PF" ? <IoPersonCircle color={secondary[theme]} size={26} /> : <RiSuitcaseLine color={secondary[theme]} size={26} />}
                                    />
                                ))}
                                <BagDocuments
                                    loading={loadingDocuments}
                                    setLoading={setLoadingDocuments}
                                    relation={"property"}
                                    property={property}
                                    onClickExibition={(newExibition: any) => changeExibition(newExibition, null, "property")}
                                    exibition={property.exibition}
                                    title={`#${property.friendly_id} - ${property.property_type?.name} na ${property.address?.public_place},  ${property.address?.number}, ${property.address?.district}, ${property.address?.city.name} - ${property.address?.state.initials}`}
                                    description="Imóvel"
                                    icon={<HiOutlineHome color={secondary[theme]} size={26} />}
                                />
                            </>
                        }
                    </Column>
                </>
            </ContentOrLoading>
        </Column>
    )
}

export const DocumentsStep = forwardRef(DocumentsStepComponent)