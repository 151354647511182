import styled, { css } from "styled-components";
import { animated } from "react-spring";
import { themeTypes } from "../../interfaces";
import { Link as Tunel } from "react-router-dom";
import { divider, primary, activeItens, inactiveItens, inactiveSubItens, secondary, shadowColor } from "../../theme";

interface ContainerProps {
    menuOpen: boolean
    theme: themeTypes
}

interface ItemProps {
    noBorder: boolean
    theme: themeTypes
}

interface LinkProps {
    active: boolean
    theme: themeTypes
}

interface ActiveIndicatorProps {
    theme: themeTypes
    active: boolean
}

interface IconArrowProps {
    theme: themeTypes
    open: boolean
}

interface SubMenuListProps {
    theme: themeTypes
    open: boolean
}

interface MenuListProps {
    theme: themeTypes
}

interface ContainerSubSubMenuItemsProps {
    open: boolean
    maxHeight: number
}

interface ItemSubMenuProps {
    active?: boolean
}

export const Container = styled.div<ContainerProps>`
    transition:transform 0.3s;
    ${props => props.menuOpen && css`
        transform:translateX(0);
    `}
    ${props => !props.menuOpen && css`
        transform:translateX(-120px);
    `}
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
    `}
    width: 120px;
    
    z-index:998;
    position: fixed;
    height:calc(100% - 81px);
`

export const MenuList = styled.ul<MenuListProps>`
    list-style-type: none;
    text-align: center;
    margin: 0px;
    padding: 0px;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
        box-shadow:0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
    `}
    min-width: 120px;
    z-index:5;
    
`

export const SubMenuList = styled(animated.ul) <SubMenuListProps>`
    transition:transform 0.3s;
    list-style-type: none;
    text-align: center;
    margin: 0px;
    ${props => css`
        background: ${primary[props.theme as themeTypes]};
    `}
    ${props => props.open && css`
        transform:translateX(0px);
    `}
    ${props => !props.open && css`
        transform:translateX(-220px);
    `}
    
    padding: 0px;
    min-width: 220px;
    height:100%;
    box-shadow:0.125rem 0.125rem 0.5rem rgba(0,0,0,0.1);
`

export const Item = styled.li<ItemProps>`

    position: relative;
    ${props => !props.noBorder && css`
        border-bottom:0.5px solid ${divider[props.theme as themeTypes]};
        
    `}
`

export const ItemSubMenu = styled.li<ItemSubMenuProps>`
    display: flex;
    flex-direction:row;
    align-items:center;
    font-size:13px;
    
    cursor: pointer;
    :hover{
        background: #EEEEEE;
    }
    ${props => props.active && css`
        background: #EEEEEE;
    `}
`

export const Link = styled(Tunel) <LinkProps>`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px;
    
    justify-content:space-between;
    flex:1;
    ${props => props.active && css`
        color:${activeItens[props.theme as themeTypes]} !important;
    `}
    ${props => !props.active && css`
        color:${inactiveItens[props.theme as themeTypes]} !important;
    `}
    ${props => css`
        :hover{
            color:${secondary[props.theme as themeTypes]} !important;
        }
    `}
`

export const IconLink = styled.i`
    font-size: 32px;
    margin-bottom: 6px;
`

export const LinkName = styled.span`
    font-size:13px;
`

export const ActiveIndicator = styled.div<ActiveIndicatorProps>`
    position: absolute;
    width: 0;
    height: 0;
    right: 0px;
    bottom: 1px;
    border-style: solid;
    border-width: 0 0 30px 30px;
    
    cursor: pointer;
    ${props => css`
        border-color: transparent transparent ${activeItens[props.theme as themeTypes]} transparent;
    `}
    ${props => props.active && css`
        opacity:1;
    `}
    ${props => !props.active && css`
        opacity:0;
    `}

`

export const IconSubMenu = styled.i`
    font-size: 19px;
    padding-right: 10px;
`

export const IconArrow = styled.i<IconArrowProps>`
    transition:transform 0.3s ;
    ${props => css`
        color: ${inactiveSubItens[props.theme as themeTypes]};
    `}
    ${props => props.open && css`
        transform:rotate(90deg);
    `}
    ${props => !props.open && css`
        transform:rotate(0deg);
    `}
    font-size: 11px;
`

export const ContainerSubSubMenuItems = styled.div<ContainerSubSubMenuItemsProps>`
    transition:max-height 0.3s;
    ${props => props.open && css`
        max-height:${props.maxHeight}px;
    `}
    ${props => !props.open && css`
        max-height:0px;
    `}
    display: flex;
    flex-direction:column;
    overflow:hidden;
`