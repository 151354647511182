import React, { useCallback, useEffect, useState } from "react";
import { uniqueId } from "lodash";
import { Container } from "./style";
import { FormCreditPolicy } from "./Form";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { credit_policiesAttributes } from "../../../interfaces";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { executorActionsOptions, executorsOptions } from "../../../utils/options";
import { creditPolicyDefaultForm, mapDefaultForm } from "../../../utils/defaultFormsUtils";

interface IModalCreditPolicyProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

const text = {
    title: {
        new: "Nova Política de Análise",
        edit: "Editar Política de Análise"
    },
    description: {
        new: "Informe os dados necessários para criar uma nova Política de análise",
        edit: "Atualize os dados da Política de Análise"
    }
}

export const ModalCreditPolicy: React.FC<IModalCreditPolicyProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [defaultData, setDefaultData] = useState<any>(null)
    const [defaultExecutors, setDefaultExecutors] = useState<any>([])
    const [defaultCreditPolicy, setDefaultCreditPolicy] = useState<credit_policiesAttributes | undefined>(undefined)

    const searchCreditPolicy = useCallback(async () => {

        if (defaultId) {

            setLoading(true)

            try {

                const { data } = await api.get(`/credit_policies/${defaultId}`)

                let _data: any = mapDefaultForm({ data, fields: creditPolicyDefaultForm })
                const executors = _data.executors.map(({ action, end, init, id }: any) => {
                    const executor = executorsOptions.find((e) => e?.value?.id === id)
                    const _uniqueId = uniqueId()

                    const _action = executorActionsOptions.find((e) => e?.value === action)

                    return {
                        ["action-" + _uniqueId]: _action,
                        ["end-" + _uniqueId]: end,
                        ["init-" + _uniqueId]: init,
                        ["executor_id-" + _uniqueId]: executor,
                        uniqueId: _uniqueId
                    }
                })

                for (const executor of executors) _data = { ..._data, ...executor }

                delete _data.executors

                setDefaultExecutors([...executors])

                setDefaultData({ ..._data })

                setDefaultCreditPolicy({ ...data })

            } catch (err) {

                notify("Erro ao buscar política de análise", "error")

                onCancel()

            }

        }

        setLoading(false)

    }, [defaultId, uniqueId])

    useEffect(() => { searchCreditPolicy() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>

                <ModalHeader
                    title={text.title[defaultId ? "edit" : "new"]}
                    subTitle={text.description[defaultId ? "edit" : "new"]}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    <FormCreditPolicy
                        defaultData={defaultData}
                        defaultCreditPolicy={defaultCreditPolicy}
                        executors={defaultExecutors}
                        onSave={onSave}
                        onCancel={onCancel}
                    />
                </ContentOrLoading>

            </Container>
        </ModalGeneric>
    )
}