import Button from "../../../Buttons/Button";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { CgChart } from "react-icons/cg";
import { VscLaw } from "react-icons/vsc";
import { TiDelete } from "react-icons/ti";
import { useApi } from "../../../../hooks/api";
import { FieldList } from "../../../FiedlList";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Bag, IBagRef } from "../../../Bags/Bag";
import { Column, Line } from "../../../../style";
import { AiOutlineDashboard } from "react-icons/ai";
import { useGlobal } from "../../../../hooks/global";
import { ModalGeneric } from "../../../ModalGeneric";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { WaveLoading } from "../../../Loadings/WaveLoading";
import { AnalysisBiro } from "../../../Animations/AnalysisBiro";
import { maskFunctions } from "../../../../services/maskServices";
import { RiShieldUserLine, RiSuitcaseLine } from "react-icons/ri";
import { SemiCircleProgress } from "../../../Loadings/SemiCircleProgress";
import { IndicatorsResultProps, queriesAttributes } from "../../../../interfaces";
import { Container, ContainerCreditRisk, ContainerIndicators, TD } from "./style";
import { background, borderColors, inactiveItens, primary, secondary, shadowColor } from "../../../../theme";
import { ListFieldsHeader, ListFieldsPF, ListFieldsPJ, ListFieldsLaw, correct_date_result } from "../../../../utils/queryListFields";

interface IModalResultQueryProps {
    onCancel: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

const states = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
]

const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]

export const correctDate = (date: any) => {
    date = new Date(date)
    const day = date?.getDate() >= 10 ? date?.getDate() : `0${date?.getDate()}`
    const month = (date?.getMonth() + 1) >= 10 ? (date?.getMonth() + 1) : `0${(date?.getMonth() + 1)}`
    const year = date?.getFullYear()
    const hours = date?.getHours() >= 10 ? date?.getHours() : `0${date?.getHours()}`
    const minutes = date?.getMinutes() >= 10 ? date?.getMinutes() : `0${date?.getMinutes()}`
    return `${day} ${months[month - 1]} ${year} às ${hours}:${minutes}`
}

const correct_indicator_type = [
    ["Comprometimento de crédito emergencial", "inversed"],
    ["Risco do perfil de contratação", "normal"],
    ["Quantidade de contratos em aberto", "inversed"],
    ["Gravidade em tempo de atrasos", "inversed"],
    ["Pontualidade de pagamento", "normal"],
    ["Quantidade de novas contratações", "inversed"],
    ["Estabilidade de relacionamento com o mercado", "inversed"]
]

const normalizeString = (string: string) => {
    return string?.normalize("NFD")?.replace(/[\u0300-\u036f]/g, "")?.replace(/[^a-zA-Z ]/g, "")?.replace(/ /g, "")?.toLowerCase()
}

const compareNames = (a: any, b: any) => {
    const a_normalize = normalizeString(a)
    const b_normalize = normalizeString(b)

    return a_normalize === b_normalize
}

const getTypeOfIndicator = (indicator: string) => {
    const result = correct_indicator_type?.find(item => compareNames(item[0], indicator))
    return result ? result[1] : "normal"
}

export const ModalResultQuery: React.FC<IModalResultQueryProps> = (props) => {
    const { onCancel, openModal, backgroundVisible, defaultId } = props

    const { api, token } = useApi()
    const { theme, notify, franchise } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [query, setQuery] = useState<queriesAttributes | null>(null)

    const anchorDownloadRef = useRef<HTMLAnchorElement>(null)
    const bagRefs = useRef<IBagRef[]>([])

    const searchQuery = useCallback(async () => {
        setLoading(true)
        try {
            const result = await api?.get(`queries/${defaultId}`)
            if (result?.data?.result_biro && result?.data?.status === "finished") result.data.result_biro = JSON?.parse(result?.data?.result_biro)
            setQuery(result?.data)

        } catch (err) {
            notify("Erro ao buscar a consulta", "error")
            onCancel()
        }
        setLoading(false)
    }, [defaultId, franchise])

    useEffect(() => { searchQuery() }, [franchise])

    const totalPendencies = (query: queriesAttributes) => {
        const protests_num = protests(query)?.num
        const apontamentos = inadimplencias(query)?.num
        const lawSuits = lawSuit(query)?.num
        return protests_num + apontamentos + lawSuits
    }

    const totalValuePendencies = (query: queriesAttributes) => {
        const protests_num = protests(query)?.value
        const apontamentos = inadimplencias(query)?.value
        const lawSuits = lawSuit(query)?.value
        return protests_num + apontamentos + lawSuits
    }

    const inadimplencias = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.Apontamentos?.length
        const value = query?.result_biro?.Negative?.TotalValorApontamentos ? query?.result_biro?.Negative?.TotalValorApontamentos : 0
        return { num, value }
    }

    const protests = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.TotalProtests ? query?.result_biro?.Negative?.TotalProtests : 0
        const value = query?.result_biro?.Negative?.TotalValorProtests ? query?.result_biro?.Negative?.TotalValorProtests : 0
        return { num, value }
    }

    const lawSuit = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.LawSuitApontamentos?.length
        const value = query?.result_biro?.Negative?.TotalValorLawSuitApontamentos ? query?.result_biro?.Negative?.TotalValorLawSuitApontamentos : 0
        return { num, value }
    }

    const cheques = (query: queriesAttributes) => {
        const num = query?.result_biro?.Negative?.CcfApontamentos?.length
        return { num }
    }

    const addressHistoryCount = (query: queriesAttributes) => {
        return query?.result_biro?.BestInfo?.AddressHistory ? query?.result_biro?.BestInfo?.AddressHistory?.Address?.length : 0
    }

    const partneshipsCount = (query: queriesAttributes) => {
        return query?.result_biro?.EnterpriseData?.Partnerships ? query?.result_biro?.EnterpriseData?.Partnerships?.Partnership?.length : 0
    }

    const partnersCount = (query: queriesAttributes) => {
        return query?.result_biro?.Partner ? query?.result_biro?.Partner?.Partnerships?.Partnership?.length : 0
    }

    const processEstaduais = (query: queriesAttributes) => {
        return query?.result_biro?.Negative?.LawSuitApontamentos?.filter((lawSuit) => lawSuit?.JusticeType === "ESTADUAL")
    }

    const processFederal = (query: queriesAttributes) => {
        return query?.result_biro?.Negative?.LawSuitApontamentos?.filter((lawSuit) => lawSuit?.JusticeType !== "ESTADUAL")
    }

    const openAll = useCallback(() => {
        bagRefs?.current?.forEach((bagRef) => {
            bagRef?.openBag()
        })
    }, [bagRefs])

    const closeAll = useCallback(() => {
        bagRefs?.current?.forEach((bagRef) => {
            bagRef?.closeBag()
        })
    }, [bagRefs])

    const handleDownload = useCallback(async () => {
        anchorDownloadRef?.current?.click()
    }, [anchorDownloadRef])

    const getIndicators = (query: queriesAttributes) => {
        if (query?.result_biro?.Indicators?.Indicators?.indicator) return []
        else if (query?.result_biro?.Indicators) return query?.result_biro?.Indicators
        return []
    }

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }} >
            <Container theme={theme} style={{ boxShadow: `0.125rem 0.125rem 0.5rem ${shadowColor[theme]}` }}>
                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", gap: 30, boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,0.1)", padding: 20, background: primary[theme], position: "relative", borderTopLeftRadius: "0.3125rem", borderTopRightRadius: "0.3125rem" }}>
                    <Line style={{ gap: 10 }}>
                        <div onClick={() => onCancel()} style={{ cursor: "pointer" }}>
                            <IoReturnUpBackOutline color={theme === "dark" ? "#FFF" : inactiveItens?.light} size={20} />
                        </div>
                        <Column>
                            <div style={{ color: theme === "dark" ? "#FFF" : inactiveItens?.light, fontWeight: 600, fontSize: 18 }}>Resultado da Consulta</div>
                            <div>Segue abaixo as informações encontradas pela Max consulta</div>
                        </Column>
                    </Line>
                    <div onClick={() => onCancel()} style={{ cursor: "pointer" }}>
                        <TiDelete color={theme === "dark" ? "#FFF" : inactiveItens?.light} size={20} />
                    </div>
                </div>
                {!loading && !!query?.result_biro && !query?.result_biro?.ErrorMessage && (query?.type === "PJ" ? query?.result_biro?.BestInfo?.CompanyStatus !== "DESCONHECIDO" : true) &&
                    <Column id="content-biro" style={{ padding: 20, gap: 20, flex: 1, overflow: "auto" }}>
                        <Column style={{ border: `1px solid ${borderColors[theme]}`, borderRadius: 4, background: primary[theme] }}>
                            <div style={{ fontSize: 16, fontWeight: 600, padding: 16, textTransform: "uppercase" }}>
                                Análise de <span>{query?.result_biro ? (query?.type === "PF" ? query?.result_biro?.BestInfo?.PersonName?.Name?.Full : query?.result_biro?.BestInfo?.CompanyName) : "--"}</span>
                            </div>
                            <Column style={{ gap: 5, padding: 16, borderBottom: `1px solid ${borderColors[theme]}`, borderTop: `1px solid ${borderColors[theme]}` }}>
                                <FieldList
                                    data={query}
                                    listFields={ListFieldsHeader}
                                    theme={theme}
                                    fontSize={13.5}
                                />
                            </Column>
                            {window?.innerWidth <= 1000 && (
                                <Line style={{ padding: 16, gap: 20, flexWrap: "wrap", flex: 1 }}>
                                    <div style={{ display: "flex", flex: 1, minWidth: 200 }}>
                                        <Button onClick={openAll} borderColor={inactiveItens[theme]} style={{ whiteSpace: "nowrap", padding: "10px 50px", display: "flex", gap: 10, alignItems: "center", justifyContent: "center" }} background={primary[theme]} color={inactiveItens[theme]}>
                                            <div>
                                                <FiEye color={inactiveItens[theme]} size={20} />
                                            </div>
                                            Abrir todas
                                        </Button>
                                    </div>
                                    <div style={{ display: "flex", flex: 1, minWidth: 200 }}>
                                        <Button onClick={closeAll} borderColor={inactiveItens[theme]} style={{ whiteSpace: "nowrap", padding: "10px 50px", display: "flex", gap: 10, alignItems: "center", justifyContent: "center" }} background={primary[theme]} color={inactiveItens[theme]}>
                                            <div>
                                                <FiEyeOff color={inactiveItens[theme]} size={20} />
                                            </div>
                                            Fechar todas
                                        </Button>
                                    </div>
                                    <div style={{ display: "flex", flex: 1, minWidth: 200 }}>
                                        <Button onClick={handleDownload} style={{ whiteSpace: "nowrap", padding: "10px 50px", display: "flex", alignItems: "center", justifyContent: "center" }} background={secondary[theme]} color="#FFF">Baixar consulta</Button>
                                        <a style={{ display: "none" }} target="_blank" href={`${process.env.REACT_APP_API_PDF_URL}/pdf?query_id=${defaultId}&franchise_id=${franchise?.id}&token=${token}`} ref={anchorDownloadRef} />
                                    </div>
                                </Line>
                            )}
                            {window?.innerWidth > 1000 && (
                                <Line style={{ padding: 16, gap: 20, justifyContent: "space-between" }}>
                                    <Line style={{ gap: 20 }}>
                                        <Button onClick={handleDownload} style={{ whiteSpace: "nowrap", padding: "10px 50px", display: "flex", alignItems: "center", justifyContent: "center" }} background={secondary[theme]} color="#FFF">Baixar consulta</Button>
                                        <a style={{ display: "none" }} target="_blank" href={`${process.env.REACT_APP_API_PDF_URL}/pdf?query_id=${defaultId}&franchise_id=${franchise?.id}&token=${token}`} ref={anchorDownloadRef} />
                                    </Line>
                                    <Line style={{ gap: 20 }}>
                                        <div style={{ display: "flex", flex: 1, minWidth: 200 }}>
                                            <Button onClick={openAll} borderColor={inactiveItens[theme]} style={{ whiteSpace: "nowrap", padding: "10px 50px", display: "flex", gap: 10, alignItems: "center", justifyContent: "center" }} background={primary[theme]} color={inactiveItens[theme]}>
                                                <div>
                                                    <FiEye color={inactiveItens[theme]} size={20} />
                                                </div>
                                                Abrir todas
                                            </Button>
                                        </div>
                                        <div style={{ display: "flex", flex: 1, minWidth: 200 }}>
                                            <Button onClick={closeAll} borderColor={inactiveItens[theme]} style={{ whiteSpace: "nowrap", padding: "10px 50px", display: "flex", gap: 10, alignItems: "center", justifyContent: "center" }} background={primary[theme]} color={inactiveItens[theme]}>
                                                <div>
                                                    <FiEyeOff color={inactiveItens[theme]} size={20} />
                                                </div>
                                                Fechar todas
                                            </Button>
                                        </div>
                                    </Line>
                                </Line>
                            )}
                        </Column>
                        <Column style={{ gap: 20 }}>
                            <Bag ref={(ref: any) => bagRefs.current[0] = ref} title="Informações cadastrais" description="Dados autenticados pela Receita Federal" icon={<RiShieldUserLine color={secondary[theme]} size={26} />}>
                                <Column style={{ gap: 8, padding: 16, borderBottom: `1px solid ${borderColors[theme]}` }}>
                                    {query?.type === "PJ" &&
                                        <FieldList
                                            data={query}
                                            theme={theme}
                                            listFields={ListFieldsPJ}
                                            fontSize={13.5}
                                        />
                                    }
                                    {query?.type === "PF" &&
                                        <FieldList
                                            data={query}
                                            theme={theme}
                                            listFields={ListFieldsPF}
                                            fontSize={13.5}
                                        />
                                    }
                                </Column>
                                {query?.type === "PF" &&
                                    <Column style={{ gap: 8, padding: 16 }}>
                                        <div style={{ fontSize: 15, fontWeight: 600 }}>Outros nomes</div>
                                        <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background[theme] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, width: "100%" }}>NOME</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.BestInfo?.PersonNameHistory?.Name?.map((name) => (
                                                        <tr>
                                                            <TD>{name?.Name?.Full}</TD>
                                                            <TD style={{ textAlign: "center" }}>--</TD>
                                                        </tr>
                                                    ))}
                                                    {!query?.result_biro?.BestInfo?.PersonNameHistory &&
                                                        <tr>
                                                            <TD colSpan={2} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                                Nenhum registro
                                                            </TD>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Column>
                                }
                                <Column style={{ gap: 8, padding: 16 }}>
                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Outros emails</div>
                                    <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background[theme] }}>
                                                <tr>
                                                    <th style={{ padding: 10, width: "100%" }}>E-MAIL</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {query?.result_biro?.BestInfo?.EmailHistory && query?.result_biro?.BestInfo?.EmailHistory?.Email?.map((email) => (
                                                    <tr>
                                                        <TD>{email?.Email}</TD>
                                                        <TD style={{ textAlign: "center" }}>{correct_date_result(email?.DateLastSeen)}</TD>
                                                    </tr>
                                                ))}
                                                {!query?.result_biro?.BestInfo?.EmailHistory &&
                                                    <tr>
                                                        <TD colSpan={2} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                            Nenhum registro
                                                        </TD>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Column>
                                <Column style={{ gap: 8, padding: 16 }}>
                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Outros telefones</div>
                                    <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background[theme] }}>
                                                <tr>
                                                    <th style={{ width: "100%", padding: 10 }}>
                                                        NÚMERO
                                                    </th>
                                                    <th style={{ whiteSpace: "nowrap", padding: 10 }}>
                                                        ÚLTIMA ATUALIZAÇÃO
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {query?.result_biro?.BestInfo?.PhoneNumberHistory && query?.result_biro?.BestInfo?.PhoneNumberHistory?.PhoneNumber?.map((phone) => (
                                                    <tr>
                                                        <TD>{maskFunctions.phone.mask(phone?.PhoneNumber)}</TD>
                                                        <TD style={{ textAlign: "center" }}>{correct_date_result(phone?.DateLastSeen)}</TD>
                                                    </tr>
                                                ))}
                                                {!query?.result_biro?.BestInfo?.PhoneNumberHistory &&
                                                    <tr>
                                                        <TD colSpan={2} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                            Nenhum registro
                                                        </TD>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Column>
                                <Column style={{ gap: 8, padding: 16 }}>
                                    <div style={{ fontSize: 15, fontWeight: 600 }}>Outros celulares</div>
                                    <div style={{ fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background[theme] }}>
                                                <tr>
                                                    <th style={{ padding: 10, width: "100%" }}>NÚMERO</th>
                                                    <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {query?.result_biro?.BestInfo?.MobilePhoneNumberHistory && query?.result_biro?.BestInfo?.MobilePhoneNumberHistory?.MobilePhoneNumber?.map((phone) => (
                                                    <tr>
                                                        <TD>{maskFunctions.phone.mask(phone?.PhoneNumber)}</TD>
                                                        <TD style={{ textAlign: "center" }}>{correct_date_result(phone?.DateLastSeen)}</TD>
                                                    </tr>
                                                ))}
                                                {!query?.result_biro?.BestInfo?.MobilePhoneNumberHistory &&
                                                    <tr>
                                                        <TD colSpan={2} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                            Nenhum registro
                                                        </TD>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Column>
                            </Bag>
                            <Bag ref={(ref: any) => bagRefs.current[1] = ref} title="Restrições financeiras" description="Dados coletados do birô de crédito" icon={<AiOutlineDashboard color={secondary[theme]} size={26} />}>
                                <Column style={{ padding: 16, gap: 20 }}>
                                    <ContainerCreditRisk>
                                        <Column style={{ gap: 8, paddingBottom: 16, justifyContent: "flex-start", marginTop: -16 }}>
                                            <SemiCircleProgress
                                                highType={query?.result_biro?.QuodScore?.CreditRisk}
                                                value={query?.type === "PF" ? query?.result_biro?.QuodScore?.Score : query?.result_biro?.ScorePJ?.Score}
                                                percentage={(query?.type === "PF" ? (query?.result_biro?.QuodScore?.Score ? query?.result_biro?.QuodScore?.Score : 0) : (query?.result_biro?.ScorePJ?.Score ? query?.result_biro?.ScorePJ?.Score : 0)) / 10} />
                                        </Column>
                                        <Column style={{ gap: 10 }}>
                                            <Column style={{ fontSize: 12 }}>
                                                <div style={{ fontWeight: 600 }}>Risco de Crédito:</div>
                                                <div style={{ fontWeight: 600 }}>{query?.result_biro?.QuodScore?.CreditRisk ? query?.result_biro?.QuodScore?.CreditRisk : "--"}</div>
                                                <div>{query?.result_biro?.QuodScore?.Message ? query?.result_biro?.QuodScore?.Message : "--"}</div>
                                            </Column>
                                            <Column style={{ fontSize: 12 }}>
                                                <div style={{ fontWeight: 600 }}>Probabilidade de pagamento:</div>
                                                <div style={{ fontWeight: 600 }}>{query?.result_biro?.QuodScore?.ProbabilityOfPayment ? maskFunctions.porcen.mask(query?.result_biro?.QuodScore?.ProbabilityOfPayment) : "--"}</div>
                                                <div>{
                                                    query?.result_biro?.QuodScore?.ProbabilityOfPayment
                                                        ? `A chance deste consumidor não pagar nos próximos meses é de ${(maskFunctions.porcen.mask(100 - query?.result_biro?.QuodScore?.ProbabilityOfPayment))}.`
                                                        : "--"
                                                }</div>
                                            </Column>
                                        </Column>
                                    </ContainerCreditRisk>

                                    <Column style={{ gap: 10 }}>
                                        <div style={{ fontSize: 15, fontWeight: 600 }}>Pendências financeiras</div>
                                        <Column style={{ gap: 16 }}>
                                            <Line style={{ justifyContent: "space-between", gap: 16, flexWrap: "wrap" }}>
                                                <Column style={{ minWidth: 240, alignItems: "center", padding: 16, border: `1px solid ${borderColors[theme]}`, flex: 1, borderRadius: 4 }}>
                                                    <div style={{ fontSize: 15, fontWeight: 600, whiteSpace: "nowrap" }}>Inadimplências comunicadas</div>
                                                    <div style={{ fontSize: 27, fontWeight: 600, whiteSpace: "nowrap" }}>{maskFunctions.currency.mask(inadimplencias(query as queriesAttributes)?.value)}</div>
                                                    <div style={{ fontSize: 15, whiteSpace: "nowrap" }}>{inadimplencias(query as queriesAttributes)?.num} {inadimplencias(query as queriesAttributes)?.num === 1 ? "pendência" : "pendências"}</div>
                                                </Column>
                                                <Column style={{ minWidth: 240, alignItems: "center", padding: 16, border: `1px solid ${borderColors[theme]}`, flex: 1, borderRadius: 4 }}>
                                                    <div style={{ fontSize: 15, fontWeight: 600, whiteSpace: "nowrap" }}>Protestos</div>
                                                    <div style={{ fontSize: 27, fontWeight: 600, whiteSpace: "nowrap" }}>{maskFunctions.currency.mask(protests(query as queriesAttributes)?.value)}</div>
                                                    <div style={{ fontSize: 15, whiteSpace: "nowrap" }}>{protests(query as queriesAttributes)?.num} {protests(query as queriesAttributes)?.num === 1 ? "pendência" : "pendências"}</div>
                                                </Column>
                                                <Column style={{ minWidth: 240, alignItems: "center", padding: 16, border: `1px solid ${borderColors[theme]}`, flex: 1, borderRadius: 4 }}>
                                                    <div style={{ fontSize: 15, fontWeight: 600, whiteSpace: "nowrap" }}>Ações judiciais</div>
                                                    <div style={{ fontSize: 27, fontWeight: 600, whiteSpace: "nowrap" }}>{maskFunctions.currency.mask(lawSuit(query as queriesAttributes)?.value)}</div>
                                                    <div style={{ fontSize: 15, whiteSpace: "nowrap" }}>{lawSuit(query as queriesAttributes)?.num} {lawSuit(query as queriesAttributes)?.num === 1 ? "pendência" : "pendências"}</div>
                                                </Column>
                                            </Line>
                                            <Column style={{ background: inactiveItens[theme], color: "#FFF", padding: 16, alignItems: "center", borderRadius: 4 }}>
                                                <div style={{ fontSize: 15, fontWeight: 600, whiteSpace: "nowrap" }}>Total de pendências</div>
                                                <div style={{ fontSize: 27, fontWeight: 600, whiteSpace: "nowrap" }}>{maskFunctions.currency.mask(totalValuePendencies(query as queriesAttributes))}</div>
                                                <div style={{ fontSize: 15, whiteSpace: "nowrap" }}>{totalPendencies(query as queriesAttributes)} {totalPendencies(query as queriesAttributes) === 1 ? "pendência" : "pendências"}</div>
                                            </Column>
                                        </Column>
                                    </Column>

                                    <Column style={{ gap: 10 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de inadimplências comunicadas</div>
                                            <div style={{ fontSize: 13 }}>{inadimplencias(query as queriesAttributes)?.num === 1 ? "Foi encontrado" : "Foram encontrados"} {inadimplencias(query as queriesAttributes)?.num} {inadimplencias(query as queriesAttributes)?.num === 1 ? "registro" : "registros"} de inadimplência</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background[theme] }}>
                                                    <tr>
                                                        <th style={{ padding: 10 }}>VALOR</th>
                                                        <th style={{ padding: 10 }}>EMPRESA</th>
                                                        <th style={{ padding: 10 }}>CIDADE</th>
                                                        <th style={{ padding: 10, textAlign: "center" }}>VENCIMENTO</th>
                                                        <th style={{ padding: 10, textAlign: "center" }}>INCLUSÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.Negative?.Apontamentos?.map((apontamento: any, index) => (
                                                        <tr>
                                                            <TD>{maskFunctions.currency.mask(apontamento?.Amount ? Number.parseFloat(apontamento?.Amount) : 0)}</TD>
                                                            <TD>{apontamento?.CompanyName ?? "--"}</TD>
                                                            <TD>{apontamento?.Address?.City ?? "--"}</TD>
                                                            <TD style={{ textAlign: "center" }}>{correct_date_result(apontamento?.DateOccurred)}</TD>
                                                            <TD style={{ textAlign: "center" }}>{correct_date_result(apontamento?.DateIncluded)}</TD>
                                                        </tr>
                                                    ))}
                                                    {inadimplencias(query as queriesAttributes)?.num === 0 &&
                                                        <tr>
                                                            <TD colSpan={5} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                                Nenhum registro
                                                            </TD>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>

                                    <Column style={{ gap: 10 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de protestos</div>
                                            <div style={{ fontSize: 13 }}>{protests(query as queriesAttributes)?.num === 1 ? "Foi encontrado" : "Foram encontrados"} {protests(query as queriesAttributes)?.num} {protests(query as queriesAttributes)?.num === 1 ? "registro" : "registros"} de protesto</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background[theme] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap", textTransform: "uppercase" }}>Nº de Protestos</th>
                                                        <th style={{ padding: 10 }}>CARTÓRIO</th>
                                                        <th style={{ padding: 10 }}>CIDADE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.Negative?.Protests?.conteudo?.cartorio?.map((protest) => (
                                                        <tr>
                                                            <TD>{protest?.protestos}</TD>
                                                            <TD>{protest?.nome}</TD>
                                                            <TD>{protest?.cidade} - {protest?.uf}</TD>
                                                        </tr>
                                                    ))}
                                                    {protests(query as queriesAttributes)?.num === 0 &&
                                                        <tr>
                                                            <TD colSpan={5} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                                Nenhum registro
                                                            </TD>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>

                                    <Column style={{ gap: 10 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de cheques devolvidos</div>
                                            <div style={{ fontSize: 13 }}>{cheques(query as queriesAttributes)?.num === 1 ? "Foi encontrado" : "Foram encontrados"} {cheques(query as queriesAttributes)?.num} {cheques(query as queriesAttributes)?.num === 1 ? "registro" : "registros"} de cheques devolvidos</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background[theme] }}>
                                                    <tr>
                                                        <th style={{ padding: 10 }}>BANCO</th>
                                                        <th style={{ padding: 10 }}>AGÊNCIA</th>
                                                        <th style={{ padding: 10 }}>MOTIVO</th>
                                                        <th style={{ padding: 10 }}>QUANTIDADE</th>
                                                        <th style={{ padding: 10 }}>ÚLTIMA OCORRÊNCIA</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {query?.result_biro?.Negative?.CcfApontamentos?.map((apontamento) => (
                                                        <tr>
                                                            <TD>{apontamento?.ReportingCodeBank ?? "--"}</TD>
                                                            <TD>{apontamento?.ReportingBranchPrefix ?? "--"}</TD>
                                                            <TD>{apontamento?.ReasonBounce ?? "--"}</TD>
                                                            <TD>{apontamento?.CountBounce ?? "--"}</TD>
                                                            <TD>{correct_date_result(apontamento?.DateLastBounce) ?? "--"}</TD>
                                                        </tr>
                                                    ))}
                                                    {cheques(query as queriesAttributes)?.num === 0 &&
                                                        <tr>
                                                            <TD colSpan={5} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                                Nenhum registro
                                                            </TD>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>

                                    <Column style={{ fontSize: 12, marginTop: 16, marginBottom: -16 }}>
                                        <div style={{ fontWeight: 600 }}>Entenda o Score de crédito</div>
                                        <ul>
                                            <li>Pontuação baseada principalmente nos dados do cadastro positivo do consumidor</li>
                                            <li>Nota que vai de 300 a 1000. Quanto mais alto, maior a chance do consumidor pagar</li>
                                            <li>Banco de dados que vai além de atrasos em pagamentos, com mais de 100 atributos</li>
                                            <li>Análise profunda dos hábitos de pagamentos da pessoa e seu histórico de crédito</li>
                                        </ul>
                                    </Column>

                                </Column>
                            </Bag>
                            {getIndicators(query).length > 0 && (
                                <Bag ref={(ref: any) => bagRefs.current[2] = ref} title="Indicadores" description="Comportamento financeiro do consumidor" icon={<CgChart color={secondary[theme]} size={26} />}>
                                    <Column style={{ padding: 16, fontSize: 12, gap: 16 }}>
                                        <div>
                                            A partir do nosso banco de dados, formado junto ao cadastro positivo, desenvolvemos indicadores exclusivos complementares ao score.
                                            Entenda profundamente o comportamento financeiro do consumidor para que você tenha mais segurança na hora de conceder crédito.
                                        </div>
                                        {getIndicators(query)?.map((indicator: IndicatorsResultProps) => (
                                            <>
                                                <ContainerIndicators>
                                                    <Column style={{ gap: 8, paddingBottom: 16, justifyContent: "flex-start", marginTop: -16 }}>
                                                        <SemiCircleProgress
                                                            orientation={getTypeOfIndicator(indicator?.VariableName)}
                                                            highType={indicator?.Risk}
                                                            type="porcen"
                                                            value={indicator?.Value}
                                                            percentage={(Number?.parseFloat(indicator?.Value ? indicator?.Value : "0"))} />
                                                    </Column>
                                                    <Column style={{ fontSize: 12, justifyContent: "flex-end", marginBottom: 16 }}>
                                                        <div style={{ fontWeight: 600 }}>{indicator?.VariableName === "PaymentCommitmentScore" ? "Pontualidade do Pagador:" : (indicator?.VariableName === "ProfileScore" ? "Relacionamento com o Mercado:" : `${indicator?.VariableName}:`)}</div>
                                                        <div style={{ fontWeight: 600 }}>{indicator?.Risk?.type}</div>
                                                        <div>{indicator?.Risk?.text}</div>
                                                    </Column>
                                                </ContainerIndicators>
                                                <div style={{ marginTop: -16 }}>{indicator?.Description}</div>
                                            </>
                                        ))}
                                    </Column>
                                </Bag>
                            )}
                            <Bag
                                title="Endereços"
                                description={`${addressHistoryCount(query as queriesAttributes)} ${addressHistoryCount(query as queriesAttributes) === 1 ? "registro foi encontrado" : "registros foram encontrados"}`}
                                icon={<HiOutlineLocationMarker color={secondary[theme]} size={26} />}
                                ref={(ref: any) => bagRefs.current[3] = ref}
                            >
                                <Column style={{ gap: 10, padding: 16 }}>
                                    <Column>
                                        <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de endereços</div>
                                        <div style={{ fontSize: 13 }}>{addressHistoryCount(query as queriesAttributes) === 1 ? "Foi encontrado" : "Foram encontrados"} {addressHistoryCount(query as queriesAttributes)} {addressHistoryCount(query as queriesAttributes) === 1 ? "registro" : "registros"} de endereços</div>
                                    </Column>
                                    <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                        <table style={{ width: "100%" }}>
                                            <thead style={{ background: background[theme] }}>
                                                <tr>
                                                    <th style={{ padding: 10 }}>RUA</th>
                                                    <th style={{ padding: 10 }}>BAIRRO</th>
                                                    <th style={{ padding: 10 }}>CIDADE</th>
                                                    <th style={{ padding: 10 }}>CEP</th>
                                                    <th style={{ padding: 10 }}>ÚLTIMA OCORRÊNCIA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {addressHistoryCount(query as queriesAttributes) > 0 && query?.result_biro?.BestInfo?.AddressHistory?.Address?.map((address) => (
                                                    <tr>
                                                        <TD>{address?.Street ?? "--"}</TD>
                                                        <TD>{address?.Neighborhood ?? "--"}</TD>
                                                        <TD>{address?.City ?? "--"}</TD>
                                                        <TD>{address?.PostalCode ?? "--"}</TD>
                                                        <TD>{correct_date_result(address?.DateLastSeen)}</TD>
                                                    </tr>
                                                ))}
                                                {addressHistoryCount(query as queriesAttributes) === 0 &&
                                                    <tr>
                                                        <TD colSpan={5} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                            Nenhum registro
                                                        </TD>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </Column>
                                </Column>
                            </Bag>
                            {query?.type === "PF" &&
                                <Bag
                                    title={"Relacionamentos econômicos"}
                                    description={`${partneshipsCount(query as queriesAttributes)} ${partneshipsCount(query as queriesAttributes) === 1 ? "registro foi encontrado" : "registros foram encontrados"}`}
                                    icon={<RiSuitcaseLine color={secondary[theme]} size={26} />}
                                    ref={(ref: any) => bagRefs.current[4] = ref}
                                >
                                    <Column style={{ gap: 10, padding: 16 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Participações em empresas</div>
                                            <div style={{ fontSize: 13 }}>{partneshipsCount(query as queriesAttributes) === 1 ? "Foi encontrado" : "Foram encontrados"} {partneshipsCount(query as queriesAttributes)} {partneshipsCount(query as queriesAttributes) === 1 ? "registro" : "registros"} de participações em empresas</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background[theme] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>RAZÃO SOCIAL</th>
                                                        <th style={{ padding: 10 }}>CNPJ</th>
                                                        <th style={{ padding: 10 }}>PARTICIPAÇÃO</th>
                                                        <th style={{ padding: 10 }}>STATUS</th>
                                                        <th style={{ padding: 10 }}>RELACIONAMENTO</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>DATA DE INÍCIO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {partneshipsCount(query as queriesAttributes) > 0 && query?.result_biro?.EnterpriseData?.Partnerships?.Partnership?.map((partnership) => (
                                                        <tr>
                                                            <TD>{partnership?.CompanyName ?? "--"}</TD>
                                                            <TD>{maskFunctions.cnpj.mask(partnership?.CNPJ)}</TD>
                                                            <TD>{partnership?.PercentParticipation}%</TD>
                                                            <TD>{partnership?.CNPJStatus ?? "--"}</TD>
                                                            <TD>{partnership?.RelationshipDescription ? partnership?.RelationshipDescription : "--"}</TD>
                                                            <TD>{correct_date_result(partnership?.DateStartPartner)}</TD>
                                                        </tr>
                                                    ))}
                                                    {partneshipsCount(query as queriesAttributes) === 0 &&
                                                        <tr>
                                                            <TD colSpan={6} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                                Nenhum registro
                                                            </TD>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>
                                </Bag>
                            }
                            {query?.type === "PJ" &&
                                <Bag
                                    title={"Relacionamentos econômicos"}
                                    description={`${partnersCount(query as queriesAttributes)} ${partnersCount(query as queriesAttributes) === 1 ? "registro foi encontrado" : "registros foram encontrados"}`}
                                    icon={<RiSuitcaseLine color={secondary[theme]} size={26} />}
                                    ref={(ref: any) => bagRefs.current[5] = ref}
                                >
                                    <Column style={{ gap: 10, padding: 16 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Quadro Societário</div>
                                            <div style={{ fontSize: 13 }}>{partnersCount(query as queriesAttributes) === 1 ? "Foi encontrado" : "Foram encontrados"} {partnersCount(query as queriesAttributes)} {partnersCount(query as queriesAttributes) === 1 ? "registro" : "registros"} de sócios</div>
                                        </Column>
                                        <Column style={{ gap: 8, fontSize: 13.5, borderRadius: 4, border: `1px solid ${borderColors[theme]}`, overflow: "auto" }}>
                                            <table style={{ width: "100%" }}>
                                                <thead style={{ background: background[theme] }}>
                                                    <tr>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>NOME DO SÓCIO</th>
                                                        <th style={{ padding: 10 }}>CPF/CNPJ</th>
                                                        <th style={{ padding: 10 }}>PARTICIPAÇÃO</th>
                                                        <th style={{ padding: 10 }}>STATUS</th>
                                                        <th style={{ padding: 10 }}>RELACIONAMENTO</th>
                                                        <th style={{ padding: 10, whiteSpace: "nowrap" }}>ÚLTIMA ATUALIZAÇÃO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {partnersCount(query as queriesAttributes) > 0 && query?.result_biro?.Partner?.Partnerships?.Partnership?.map((partnership) => (
                                                        <tr>
                                                            <TD>{partnership?.Name ?? "--"}</TD>
                                                            <TD>{maskFunctions.cpfOrCnpj.mask(partnership?.CpfCnpj)}</TD>
                                                            <TD>{partnership?.PercentParticipation}%</TD>
                                                            <TD>{partnership?.Status ?? "--"}</TD>
                                                            <TD>{partnership?.RelationshipDescription ? partnership?.RelationshipDescription : "--"}</TD>
                                                            <TD>{correct_date_result(partnership?.DateReference)}</TD>
                                                        </tr>
                                                    ))}
                                                    {partnersCount(query as queriesAttributes) === 0 &&
                                                        <tr>
                                                            <TD colSpan={6} style={{ textAlign: "center", textTransform: "uppercase", opacity: 0.7, fontSize: 14, fontWeight: 600 }}>
                                                                Nenhum registro
                                                            </TD>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Column>
                                    </Column>
                                </Bag>
                            }
                            <Bag
                                title="Antecedentes judiciais"
                                description={`${lawSuit(query as queriesAttributes)?.num} ${lawSuit(query as queriesAttributes)?.num === 1 ? "registro foi encontrado" : "registros foram encontrados"}`}
                                icon={<VscLaw color={secondary[theme]} size={26} />}
                                ref={(ref: any) => bagRefs.current[6] = ref}
                            >
                                <Column style={{ padding: 16, gap: 20 }}>
                                    <Column style={{ gap: 16 }} >
                                        <Column style={{ gap: 5 }}>
                                            <Column>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>Instâncias estaduais</div>
                                                <div style={{ fontSize: 15 }}>Cívil e criminal, na justiça estadual</div>
                                            </Column>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ background: secondary[theme], borderRadius: 4, fontSize: 12, padding: "4px 16px", color: "#FFF" }}>
                                                    {processEstaduais(query as queriesAttributes)?.length} {processEstaduais(query as queriesAttributes)?.length !== 1 ? "processos encontrados" : "processo encontrado"}
                                                </div>
                                            </div>

                                        </Column>
                                        <Line style={{ flexWrap: "wrap" }}>
                                            {states?.map((state) => (
                                                <Line style={{ alignItems: "center", gap: 5, minWidth: 50, maxWidth: 50, padding: 8, border: "1px solid #CCCCCC" }}>
                                                    <div style={{ minWidth: 8, maxWidth: 8, maxHeight: 8, minHeight: 8, borderRadius: "50%", background: processEstaduais(query as queriesAttributes)?.filter((process) => process.State === state)?.length > 0 ? "#FF6B6B" : "#52C41A" }}></div>
                                                    <div style={{ fontSize: 12 }}>{state}</div>
                                                </Line>
                                            ))}
                                        </Line>
                                    </Column>
                                    <Column style={{ gap: 16 }} >
                                        <Column style={{ gap: 5 }}>
                                            <Column>
                                                <div style={{ fontSize: 18, fontWeight: 600 }}>Instância federal</div>
                                                <div style={{ fontSize: 15 }}>Cívil e criminal, nas seções estaduais da justiça</div>
                                            </Column>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ background: secondary[theme], borderRadius: 4, fontSize: 12, padding: "4px 16px", color: "#FFF" }}>
                                                    {processFederal(query as queriesAttributes)?.length} {processFederal(query as queriesAttributes)?.length !== 1 ? "processos encontrados" : "processo encontrado"}
                                                </div>
                                            </div>
                                        </Column>
                                        <Line style={{ flexWrap: "wrap" }}>
                                            {states?.map((state) => (
                                                <Line style={{ alignItems: "center", gap: 5, minWidth: 50, maxWidth: 50, padding: 8, border: "1px solid #CCCCCC" }}>
                                                    <div style={{ minWidth: 8, maxWidth: 8, maxHeight: 8, minHeight: 8, borderRadius: "50%", background: processFederal(query as queriesAttributes)?.filter((process) => process.State === state)?.length > 0 ? "#FF6B6B" : "#52C41A" }}></div>
                                                    <div style={{ fontSize: 12 }}>{state}</div>
                                                </Line>
                                            ))}
                                        </Line>
                                    </Column>
                                    <Column style={{ gap: 16 }}>
                                        <Column>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Lista de processos</div>
                                            <div style={{ fontSize: 13 }}>Total de {lawSuit(query as queriesAttributes)?.num} {lawSuit(query as queriesAttributes)?.num === 1 ? "processo encontrado" : "processos encontrados"}</div>
                                        </Column>
                                        {query?.result_biro?.Negative?.LawSuitApontamentos?.map((law) => {
                                            return (
                                                <Column style={{ padding: 16, gap: 5, border: `1px solid ${borderColors[theme]}`, borderRadius: 4 }}>
                                                    <Column style={{ gap: 5, marginBottom: 5 }}>
                                                        <div style={{ fontSize: 15, fontWeight: 600 }}>{law?.ProcessType}</div>
                                                        <Line style={{ fontSize: 12, gap: 10, flexWrap: "wrap" }}>
                                                            <div style={{ background: secondary[theme], padding: "4px 16px", borderRadius: 4, color: "#FFF", whiteSpace: "nowrap" }}>
                                                                {law?.Vara}
                                                            </div>
                                                            {law?.Comarca && (
                                                                <div style={{ background: secondary[theme], padding: "4px 16px", borderRadius: 4, color: "#FFF", whiteSpace: "nowrap" }}>
                                                                    {law?.Comarca}
                                                                </div>
                                                            )}
                                                        </Line>
                                                    </Column>
                                                    <FieldList
                                                        data={law}
                                                        listFields={ListFieldsLaw}
                                                        theme={theme}
                                                        fontSize={13.5}
                                                    />
                                                </Column>
                                            )
                                        })}
                                    </Column>
                                </Column>

                            </Bag>
                            <div style={{ fontSize: 12, marginTop: 16 }}>
                                Este relatório é feito por meio da coleta de informações disponíveis na internet e
                                bancos de dados de instituições públicas e privadas, não se responsabilizando o Max Locação,
                                pelo conteúdo das informações, nem garantindo-se que as pendências e informações apresentadas
                                representam a totalidade das mesmas. É estritamente confidencial e de uso exclusivo do usuário
                                do Max Locação, sendo proibida a sua reprodução, total ou parcial, bem como sua divulgação a
                                terceiros por qualquer forma. As decisões de negócios tomadas com base neste dossiê são de
                                inteira responsabilidade do usuário empresa concedente, estando o Max Locação totalmente
                                desonerada de responsabilidade por qualquer decisão ou efeito das mesmas.
                            </div>
                        </Column>
                    </Column>
                }
                {!loading && !!!query?.result_biro &&
                    <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", alignItems: "center", padding: 20 }}>
                        <AnalysisBiro />
                        <b style={{ fontSize: 18, textAlign: "center" }}>
                            Aguardando resposta do birô de crédito
                        </b>
                        <div style={{ textAlign: "center" }}>
                            O tempo de resposta do birô de crédito pode variar de acordo com a quantidade de consultas em andamento.
                        </div>
                    </div>
                }
                {!loading && (!!query?.result_biro && !!query?.result_biro?.ErrorMessage || !(query?.type === "PJ" ? query?.result_biro?.BestInfo?.CompanyStatus !== "DESCONHECIDO" : true)) &&
                    <div style={{ display: "flex", padding: 20, gap: 10, flexDirection: "column", flex: 1, overflow: "auto" }}>
                        <Column style={{ border: `1px solid ${borderColors[theme]}`, borderRadius: 4, background: primary[theme] }}>
                            <div style={{ fontSize: 16, fontWeight: 600, padding: 16, textTransform: "uppercase" }}>
                                Pessoa não encontrada pelo birô de crédito
                            </div>
                            <Column style={{ gap: 5, padding: 16, borderBottom: `1px solid ${borderColors[theme]}`, borderTop: `1px solid ${borderColors[theme]}` }}>
                                <FieldList
                                    data={query}
                                    listFields={ListFieldsHeader}
                                    theme={theme}
                                    fontSize={13.5}
                                />
                            </Column>
                            <Line style={{ padding: 16, gap: 20, justifyContent: "flex-end" }}>
                                <Line style={{ gap: 20 }}>
                                    <Button onClick={handleDownload} style={{ whiteSpace: "nowrap", padding: "10px 50px", display: "flex", alignItems: "center", justifyContent: "center" }} background={secondary[theme]} color="#FFF">Baixar consulta</Button>
                                    <a style={{ display: "none" }} target="_blank" href={`${process.env.REACT_APP_API_PDF_URL}/pdf?query_id=${defaultId}&franchise_id=${franchise?.id}&token=${token}`} ref={anchorDownloadRef} />
                                </Line>
                            </Line>
                        </Column>
                        <span style={{ marginTop: 10 }}>Apesar do nosso robô de busca ser altamente eficiente, há casos em que não podemos fornecer informações:</span>
                        <ul style={{ margin: 0 }}>
                            <li>
                                <b>Pessoas que recentemente completaram 18 anos:</b> como essas pessoas possuem poucas informações
                                financeiras associadas ao seu CPF, pode levar algum tempo para que as empresas criem um perfil de crédito.
                            </li>
                            <li>
                                <b>Imigrantes recém-chegados:</b> assim como as pessoas que acabaram de completar 18 anos e possuem poucas informações
                                financeiras disponíveis, os imigrantes recém-chegados também terão restrições de informação até que haja dados financeiros suficientes para fazer uma análise de crédito.
                            </li>
                            <li>
                                <b>Pessoas que solicitaram a exclusão de suas informações dos bancos de dados de birôs de crédito de acordo com a
                                    LGPD (Lei Geral de Proteção de Dados):</b> nesses casos, não será possível exibir essas informações.
                            </li>
                            <li>
                                <b>Pessoas com riscos associados à profissão:</b> para proteger a segurança de agentes de segurança, policiais,
                                investigadores, militares, entre outros, suas informações não serão divulgadas.
                            </li>
                            <li>
                                <b>Pessoas públicas:</b> como medida de segurança, informações de pessoas conhecidas publicamente,
                                como políticos, empresários, cantores, jogadores de futebol, entre outros, não serão divulgadas.
                            </li>
                            <li>
                                <b>CPF/CNPJ inexistente:</b> antes de realizar a consulta, é feita uma validação para verificar se
                                o CPF/CNPJ informado é válido. No entanto, é possível que o CPF/CNPJ seja válido, mas não exista nenhuma
                                pessoa física ou jurídica associada a ele. Nesses casos, é importante confirmar com o seu cliente se o CPF/CNPJ
                                fornecido está correto, para evitar possíveis erros ou fraudes.
                            </li>
                        </ul>
                    </div>
                }
                {loading &&
                    <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <WaveLoading />
                    </div>
                }
            </Container >
        </ModalGeneric >
    )
}