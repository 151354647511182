import Input, { InputRefProps } from "../Input";
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react"
import { inactiveItens } from "../../../theme";
import { MaskTypes } from "../../../interfaces";
import { CSSProperties } from "styled-components";
import { Container, ContainerIcon } from "./style";

export interface InputWithLogoRefProps {
    getValue: () => string
    setValue: (newValue: string) => void
    focus: () => void
}

interface InputWithLogoProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    icon?: string
    placeholder?: string
    mask?: MaskTypes
    disabled?: boolean
    iconComponent?: any
    secondIcon?: string
    secondIconComponent?: any
    onClickSecondIcon?: () => void
    containerStyle?: CSSProperties
    noUpperCase?: boolean
}

const InputWithLogo: React.ForwardRefRenderFunction<InputWithLogoRefProps, InputWithLogoProps> = (data, ref) => {
    const { icon, containerStyle = {}, iconComponent, secondIconComponent, secondIcon, disabled = false, mask = undefined, placeholder = "", onClickSecondIcon = () => { }, ...props } = data

    const inputRef = useRef<InputRefProps>(null)

    const getValue = useCallback(() => {
        return inputRef.current ? inputRef.current.getValue() : ""
    }, [inputRef])

    const focus = useCallback(() => {
        inputRef.current?.focus()
    }, [inputRef])

    const setValue = useCallback((newValue: string) => {
        if (inputRef && inputRef.current) inputRef.current.setValue(newValue)
    }, [inputRef])

    useImperativeHandle(ref, () => (
        { getValue, setValue, focus }
    ))

    return (
        <Container style={{ ...containerStyle }}>
            <ContainerIcon onClick={focus} cursor="text" position="left">
                {iconComponent ? iconComponent : (
                    <i
                        className={icon}
                        data-fullscreen
                        style={{ color: inactiveItens["dark"], fontSize: 19 }}
                    />
                )}
            </ContainerIcon>
            <Input
                {...props}
                ref={inputRef}
                disabled={disabled}
                style={{ padding: "0px 40px", height: "100%" }}
                placeholder={placeholder}
                mask={mask}
            />
            {(!!secondIconComponent || !!secondIcon) && (
                <ContainerIcon cursor={!disabled ? "pointer" : "default"} onClick={!disabled ? onClickSecondIcon : () => { }} position="right">
                    {!!secondIconComponent ? secondIconComponent : (
                        <i
                            className={secondIcon}
                            data-fullscreen
                            style={{ color: inactiveItens["dark"], fontSize: 19 }}
                        />
                    )}
                </ContainerIcon>
            )}
        </Container>
    )
}

export default forwardRef(InputWithLogo)