import React from "react"
import { TiDelete } from "react-icons/ti";
import { Container, Title } from "./style";
import { Column, Line } from "../../style";
import { inactiveItens } from "../../theme";
import { themeTypes } from "../../interfaces";
import { IoReturnUpBackOutline } from "react-icons/io5";

interface IModalHeaderProps {
    title: any
    subTitle: any
    onCancel?: Function
    theme: themeTypes
}

export const ModalHeader: React.FC<IModalHeaderProps> = (props) => {
    const { title, subTitle, onCancel = () => { }, theme } = props

    return (
        <Container theme={theme}>

            <Line style={{ gap: 10 }}>
                <div className="pointer" onClick={() => onCancel()}>
                    <IoReturnUpBackOutline
                        color={theme === "dark" ? "#FFF" : inactiveItens.light}
                        size={20}
                    />
                </div>
                <Column>
                    <Title theme={theme}>
                        {title}
                    </Title>
                    <div>
                        {subTitle}
                    </div>
                </Column>
            </Line>

            <div className="pointer" onClick={() => onCancel()} >
                <TiDelete
                    color={theme === "dark" ? "#FFF" : inactiveItens.light}
                    size={20}
                />
            </div>
        </Container>
    )
}