import React, { useCallback, useEffect, useState } from "react";
import { FormPlan } from "./form";
import { Container } from "./style";
import { TiDelete } from "react-icons/ti";
import { useApi } from "../../../hooks/api";
import { Line, Column } from "../../../style";
import { ModalGeneric } from "../../ModalGeneric";
import { useGlobal } from "../../../hooks/global";
import { plansAttributes } from "../../../interfaces";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { inactiveItens, primary, shadowColor } from "../../../theme";
import { mapDefaultForm, plansDefaultForm } from "../../../utils/defaultFormsUtils";
import { modulesOptions } from "../../../utils/options";

interface IModalPlanProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

export const ModalPlan: React.FC<IModalPlanProps> = (data) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId = null } = data

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [defaultData, setDefaultData] = useState<any>(undefined)
    const [defaultPlan, setDefaultPlan] = useState<plansAttributes | null>(null)

    const searchPlan = useCallback(async () => {

        if (defaultId) {

            setLoading(true)

            try {

                const { data } = await api.get(`/plans/${defaultId}`)

                const _data: any = mapDefaultForm({ data, fields: plansDefaultForm })
                _data.permissions = _data.permissions.map((permission: any) => modulesOptions.find(({ value }) => value?.name === permission))
                _data.permissions = _data.permissions.filter((permission: any) => !!permission)

                setDefaultData({ ..._data })

                setDefaultPlan({ ...data })

            } catch (err) {

                notify("Erro ao buscar Plano", "error")

                onCancel()

            }
        }

        setLoading(false)

    }, [defaultId])

    useEffect(() => { searchPlan() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }} >
            <Container theme={theme} style={{ boxShadow: `0.125rem 0.125rem 0.5rem ${shadowColor[theme]}` }}>
                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", gap: 30, boxShadow: "0.125rem 0.125rem 0.5rem rgba(0,0,0,0.1)", padding: 20, background: primary[theme], position: "relative", borderTopLeftRadius: "0.3125rem", borderTopRightRadius: "0.3125rem" }}>
                    <Line style={{ gap: 10 }}>
                        <div className="pointer" onClick={() => onCancel()}>
                            <IoReturnUpBackOutline color={theme === "dark" ? "#FFF" : inactiveItens.light} size={20} />
                        </div>
                        <Column>
                            <div style={{ color: theme === "dark" ? "#FFF" : inactiveItens.light, fontWeight: 600, fontSize: 18 }}>{defaultId ? "Informações do Plano" : "Cadastro de Plano"}</div>
                            <div>
                                {defaultId ?
                                    "Segue abaixo as informações do Plano"
                                    :
                                    "Informe os dados necessários para criar um novo Plano"
                                }
                            </div>
                        </Column>
                    </Line>
                    <div className="pointer" onClick={() => onCancel()}>
                        <TiDelete color={theme === "dark" ? "#FFF" : inactiveItens.light} size={20} />
                    </div>
                </div>

                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    <FormPlan
                        defaultData={defaultData}
                        defaultPlan={defaultPlan}
                        onSave={onSave}
                        onCancel={onCancel}
                    />
                </ContentOrLoading>

            </Container>
        </ModalGeneric>
    )
}