import React from "react";
import userImageDark from "./../../../assets/user_dark.svg";
import userImageLight from "./../../../assets/user_light.svg";
import { themeTypes } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";
import { inactiveItens, primary } from "../../../theme";
import { Box, ContainerDropItems, ContainerIcon, ItemDropdown, Perfil } from "./style";

interface ProcfileButtonProps {
    onClick: Function;
    id: string;
    options: { name: string, icon: any }[];
    theme: themeTypes
    photo?: string | null
}

export const ProfileButton: React.FC<ProcfileButtonProps> = (props) => {
    const { id, onClick, options, theme, photo } = props

    const { openSelect } = useGlobal()

    return (
        <Box>
            <Perfil className="profile-button">
                {photo &&
                    <img
                        className="profile-button"
                        style={{ minHeight: 40, minWidth: 40, objectFit: "cover" }}
                        src={photo}
                    />
                }
                {!photo &&
                    <img
                        className="profile-button"
                        style={{ minHeight: 40, minWidth: 40, objectFit: "cover" }}
                        src={theme === "dark" ? userImageDark : userImageLight}
                    />
                }
            </Perfil>

            {openSelect === "profile-button" && options.length > 0 &&
                <ContainerDropItems theme={theme} color={inactiveItens[theme]} background={primary[theme]} borderColor={"#FFF"}>
                    {options.map((option) => (
                        <ItemDropdown onClick={() => onClick(id, option.name)} theme={theme}>
                            <ContainerIcon>{option.icon}</ContainerIcon>
                            {option.name}
                        </ItemDropdown>
                    ))}
                </ContainerDropItems>
            }
        </Box>
    );
};