import React from "react";
import { Container } from "./style";
import { PlanCard } from "../../../../PlansComponents/PlanCard";
import { plansAttributes, themeTypes } from "../../../../../interfaces";

interface IChoosePlan {
    plans: plansAttributes[]
    planSelected: string | null
    setPlanSelected: Function
    theme: themeTypes
}

export const ChoosePlan: React.FC<IChoosePlan> = (props) => {
    const { planSelected, plans, setPlanSelected, theme } = props

    return (
        <Container>
            {plans.map((plan) => {
                if (plan.active) return (
                    <PlanCard
                        plan={plan}
                        selected={planSelected === plan.id}
                        onClick={setPlanSelected}
                        theme={theme}
                        key={plan.id}
                    />
                )
                else return null
            })}
        </Container>
    )
}