import React from "react"

export const Superlogica32: React.FC<{ color: any }> = ({ color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
        children={(
            <path
                fill={color}
                d="M2.491 21.375a.514.514 0 0 1 .95-.07l1.416 1.858a2.5 2.5 0 0 0 1.96 1.152l7 .813a2.712 2.712 0 0 1 1.99 1.152l1.639 2.118c.306.407.206.768-.268.913l-9.189 2.441a2.551 2.551 0 0 1-2.259-.307l-4.02-2.302a2.904 2.904 0 0 1-1.616-1.918 2.916 2.916 0 0 1 .475-2.465l1.922-3.385ZM10.7 3.407l-1.823 1.42A2.545 2.545 0 0 0 7.76 6.784l-.91 7.03a2.876 2.876 0 0 1-1.111 2.035l-2.03 1.596c-.405.338-.765.207-.91-.268L.331 8.004a2.562 2.562 0 0 1 .306-2.233l2.297-4.06C3.977-.048 5.5-.486 7.254.558l3.377 1.927c.474.276.474.583.069.92Zm4.18-.645L23.931.29a2.53 2.53 0 0 1 2.26.269l4.02 2.303c1.79 1.013 2.296 2.602 1.247 4.367l-1.96 3.385c-.23.437-.636.506-.942.1l-1.485-1.896a2.772 2.772 0 0 0-1.991-1.152l-6.892-.813a2.714 2.714 0 0 1-1.99-1.151l-1.616-2.027a.53.53 0 0 1 .298-.913Zm8.27 24.4a2.647 2.647 0 0 0 1.118-2.034l.812-6.969a2.675 2.675 0 0 1 1.11-1.965l2.098-1.612c.406-.3.766-.169.873.307l2.435 9.103a2.569 2.569 0 0 1-.237 2.234l-2.297 4.06c-1.019 1.758-2.604 2.203-4.357 1.152l-3.347-1.896c-.467-.269-.467-.607-.06-.914l1.852-1.466Z"
            />
        )}
    />
)
