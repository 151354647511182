import React from "react";
import parse from "html-react-parser";
import { Container } from "./style";
import { secondary } from "../../../theme";
import { PlanIcons } from "../../../utils/icons";
import { AiFillCheckCircle } from "react-icons/ai";
import { maskFunctions } from "../../../services/maskServices";
import { plansAttributes, themeTypes } from "../../../interfaces";

interface IPlanCard {
    plan: plansAttributes
    selected: boolean
    onClick: Function
    theme: themeTypes
}

const getPartIntAndDecimal = (value: string) => {
    let partInt = value.split(",")[0].replace(/\D+/g, "")
    let partDecimal = value.split(",")[1].replace(/\D+/g, "")

    return (
        <>
            R$ <b style={{ fontSize: 36 }}>{partInt},</b>{partDecimal}
        </>
    )
}

export const PlanCard: React.FC<IPlanCard> = (props) => {
    const { plan, selected, onClick, theme } = props

    return (
        <Container onClick={() => onClick(plan.id)} selected={selected} theme={theme}>
            <div style={{ display: "flex", whiteSpace: "nowrap", justifyContent: "flex-end", gap: 10 }}>
                {selected &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, borderRadius: "50%", boxShadow: theme === "light" ? "rgb(187 187 187 / 70%) 0.125rem 0.125rem 0.5rem" : "rgb(7 7 7 / 70%) 0.125rem 0.125rem 0.5rem" }}>
                        <AiFillCheckCircle style={{ width: 20, height: 20 }} color={secondary[theme]} />
                    </div>}
                {!selected && <div style={{ width: 20, height: 20, borderRadius: "50%", boxShadow: theme === "light" ? "rgb(187 187 187 / 70%) 0.125rem 0.125rem 0.5rem" : "rgb(7 7 7 / 70%) 0.125rem 0.125rem 0.5rem" }} />}
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20, gap: 10 }}>
                <div style={{ display: "flex", background: secondary[theme], justifyContent: "center", alignItems: "center", minWidth: 100, maxWidth: 100, minHeight: 100, maxHeight: 100, borderRadius: "50%" }}>
                    <PlanIcons type={plan.icon} size={70} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontWeight: 600, fontSize: 22, color: secondary[theme], textAlign: "center" }}>{plan.name}</div>
                    <div style={{ opacity: 0.5 }}>Plano mensal</div>
                </div>
                <div style={{ fontWeight: 600, fontSize: 22, marginTop: -10, color: secondary[theme] }}>
                    {getPartIntAndDecimal(maskFunctions.currency.mask(plan.value))}
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                {parse(plan.description)}
            </div>
        </Container>
    )
}