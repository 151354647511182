import Button from "../../../Buttons/Button";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { uniqueId } from "lodash";
import { Tabs } from "../../../Tabs";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { TiCancel } from "react-icons/ti";
import { Tooltip } from "../../../Tooltip";
import { FiDownload } from "react-icons/fi";
import { useApi } from "../../../../hooks/api";
import { ModalContract } from "../../ModalContract";
import { DocumentModal } from "../../ModalDocument";
import { useGlobal } from "../../../../hooks/global";
import { ModalGeneric } from "../../../ModalGeneric";
import { BiGridAlt, BiListUl } from "react-icons/bi";
import { ModalAttention } from "../../ModalAttention";
import { ListDocuments } from "../../../ListDocuments";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { getPropertyLabel } from "../../../../utils/labels";
import { Line, Column, AddButton } from "../../../../style";
import { getStatus } from "../../../../utils/columnsTables";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { useProposalChat } from "../../../../hooks/proposals";
import { validateCEP } from "../../../../utils/validatesFields";
import { contract_status } from "../../../../utils/contract_status";
import { ContentOrLoading } from "../../../Loadings/ContentOrLoading";
import { ContainerGroup, GroupTitle } from "../../../GenericForm/style";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import {
  background,
  inactiveItens,
  primary,
  secondary,
} from "../../../../theme";
import {
  copyOf,
  correctDate,
  valueIsEmpty,
} from "../../../../services/generalServices";
import {
  ContainerButtons,
  ContainerDocuments,
  ContainerDocumentsEletronics,
  ContainerForm,
  Form,
} from "./style";
import {
  destinationsOptions,
  isManagerOptions,
  localizationsOptions,
  ownerRelationsOptions,
} from "../../../../utils/options";
import {
  IGroupProps,
  contractsAttributes,
  documentsAttributes,
  propertiesAttributes,
  tabProps,
} from "../../../../interfaces";

interface IFormPropertiesModalProps {
  onCancel: Function;
  onSave: Function;
  defaultData?: propertiesAttributes;
  defaultProperty?: propertiesAttributes | null;
  onCreateNewContract: Function;
  owners?: any[];
}

const disabledTabs: tabProps[] = [
  { label: "Dados do Imóvel", canClick: false, open: false },
  { label: "Documentos Eletrônicos", canClick: false, open: false },
  { label: "Documentos", canClick: false, open: false },
];

const initialsTabsCreate: tabProps[] = [
  { label: "Dados do Imóvel", canClick: true, open: true },
  { label: "Documentos Eletrônicos", canClick: false, open: false },
  { label: "Documentos", canClick: false, open: false },
];

const initialsTabsUpdate: tabProps[] = [
  { label: "Dados do Imóvel", canClick: true, open: true },
  { label: "Documentos Eletrônicos", canClick: true, open: false },
  { label: "Documentos", canClick: true, open: false },
];

const getDisabledAll = (props: any) => {
  const person_id = props?.defaultProperty?.id;
  const user_type = props?.user?.type;
  const broker_id = props?.defaultProperty?.broker_id;
  const user_person_id = props?.user?.person_id;

  if (!person_id) return false;
  return user_type === "broker" && !!broker_id && user_person_id !== broker_id;
};

export const FormProperties: React.FC<IFormPropertiesModalProps> = (props) => {
  const { onCancel, onSave, defaultData, defaultProperty, owners } = props;

  const { theme, notify } = useGlobal();
  const { socketContracts } = useProposalChat();
  const { user, api, consultsServices } = useApi();

  const _defaultData = {
    broker_id:
      user.type === "broker"
        ? {
            label: user.person?.registration_data?.name,
            value: user.person?.id,
            this: user.person,
          }
        : null,
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    setValue,
    trigger,
    unregister,
  } = useForm({
    defaultValues: defaultData?.id ? defaultData : (_defaultData as any),
    mode: "all",
  });

  const _form = watch();

  const disabledAll = getDisabledAll({ defaultProperty, user });

  const generateOwnerGroup = (defaultId?: string) => {
    const _uniqueId = defaultId ? defaultId : uniqueId();

    const newOwnerGroup: IGroupProps = {
      name: "",
      label: "",
      uniqueId: _uniqueId,
      fields: [
        [
          {
            name: "customer_id-" + _uniqueId,
            label: "Nome",
            type: "select-person",
            required: true,
            isClearable: true,
            getRemoveIds: (obj: any) => {
              const customerIds = Object.keys(obj).filter((key) =>
                key.startsWith("customer_id-")
              );
              const customerValues = customerIds.map((id) => obj?.[id]?.value);
              return customerValues?.filter((value: any) => !!value);
            },
            get_enabled_change_form_type: (type) => type === "new",
            backgroundVisible: true,
          },
          {
            name: "type-" + _uniqueId,
            label: "Tipo",
            type: "select-fixed",
            required: true,
            isClearable: true,
            options: ownerRelationsOptions,
          },
          {
            name: "porcen-" + _uniqueId,
            label: "Propriedade(%)",
            type: "input",
            mask: "porcen",
            required: true,
            validate: async (data: any) => {
              let form = watch();
              const porcenFields = Object.keys(form).filter((key) =>
                key.startsWith("porcen-")
              );
              const porcenValues = porcenFields.map((id) => {
                let value = !valueIsEmpty(form?.[id]) ? form?.[id] : 0;
                value = `${value}`.replace(/\D/g, "");
                value = Number.parseInt(value) / 100;
                return value;
              });

              const total = porcenValues.reduce((a, b) => a + b, 0);

              if (total !== 100) return "A soma deve ser igual a 100%.";

              return true;
            },
          },
        ],
      ],
    };

    return newOwnerGroup;
  };

  const [openModal, setOpenModal] = useState(false);
  const [loadingCEP, setLoadingCEP] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [modalNewContract, setModalNewContract] = useState(false);
  const [loadingContracts, setLoadingContracts] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [exibition, setExibition] = useState<"grid" | "list">("list");
  const [contractSelected, setContractSelected] =
    useState<contractsAttributes | null>(null);
  const [contracts, setContracts] = useState<contractsAttributes[]>(
    defaultData?.contracts ?? []
  );
  const [documents, setDocuments] = useState<documentsAttributes[]>(
    defaultData?.documents ? defaultData.documents : []
  );
  const [tabs, setTabs] = useState<tabProps[]>(() => {
    if (defaultProperty?.id && !disabledAll) return initialsTabsUpdate;
    else return initialsTabsCreate;
  });
  const [ownersGroups, setOwnersGroups] = useState<IGroupProps[]>(() => {
    if (owners && owners.length > 0) {
      const _ownersGroups: any[] = [];

      const _defaultDataOwners = owners ?? [];
      _defaultDataOwners.forEach((executor: any) => {
        _ownersGroups.push(generateOwnerGroup(executor.uniqueId));
      });

      return _ownersGroups;
    }
    return [generateOwnerGroup()];
  });

  const form_ref = useRef<IGenericFormRefProps>(null);
  const ownersRefs = useRef<IGenericFormRefProps[]>([]);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const changeCEP = useCallback(async (search) => {
    search = search.replace(/\D/g, "");
    if (search.length === 8) {
      setLoadingCEP(true);

      const { address, city, state } = await consultsServices.address({
        search,
      });

      if (address) {
        setValue("public_place", address.logradouro);
        setValue("district", address.bairro);
        setValue("complement", address.complemento);
      }

      if (city)
        setValue("city_id", { label: city.name, value: city.id, this: city });

      if (state)
        setValue("state_id", {
          label: state.name,
          value: state.id,
          initials: state.initials,
          this: state,
        });

      setLoadingCEP(false);

      trigger();
    }
  }, []);

  const searchContracts = useCallback(async () => {
    if (defaultData?.id) {
      setLoadingContracts(true);
      try {
        const result = await api.get(
          `/properties/contracts/${defaultData?.id}`
        );
        setContracts([...result.data]);
        socketContracts?.removeAllListeners();
        socketContracts?.on(`${defaultData?.id}-contracts`, searchContracts);
      } catch (err) {
        console.log(err);
        notify("Erro ao buscar documentos eletrônicos do Imóvel", "error");
        onCancel();
      }
      setLoadingContracts(false);
    }
  }, [defaultData, socketContracts]);

  const onSaveContract = useCallback(() => {
    setModalNewContract(false);
    searchContracts();
  }, [defaultData]);

  const getOwners = useCallback(() => {
    const _refs = ownersRefs.current.filter((ref) => ref?.getForm());
    const owners = _refs.map((ref) => {
      const form = ref?.getForm();
      const _uniqueId = form?.uniqueId;

      return {
        customer_id: form?.["customer_id-" + _uniqueId],
        type: form?.["type-" + _uniqueId],
        porcen: form?.["porcen-" + _uniqueId],
        customer: _form?.["customer_id-" + _uniqueId]?.this,
        uniqueId: _uniqueId,
      };
    });
    return owners;
  }, [ownersRefs, _form]);

  const onSubmit = useCallback(
    async (data: any) => {
      setLoadingCreate(true);

      const form = form_ref.current?.getForm();
      const owners: any = getOwners();
      form.owners = owners;

      const city = data?.city_id?.this;
      const state = data?.state_id?.this;
      const city_name = city?.name;
      const state_initials = state?.initials;
      const property_type = data?.property_type_id?.label;
      const property: any = {
        owners,
        address: form?.address,
        ...form?.details_property,
        ...form?.general_data_property,
      };

      try {
        if (defaultData?.id) {
          await api.put(`/properties/${defaultData.id}`, property);
          property.id = defaultData?.id;
          property.friendly_id = defaultData?.friendly_id;
          notify("Imóvel atualizado com sucesso.", "success");
        } else {
          const { data } = await api.post("/properties", property);
          property.id = data.id;
          property.friendly_id = data.friendly_id;
          notify("Novo imóvel cadastrado com sucesso.", "success");
        }

        property.property_type = { name: property_type };
        property.address.city = { name: city_name };
        property.address.state = { initials: state_initials };

        const label = getPropertyLabel({ property });

        onSave({ label, value: property?.id, this: property });
      } catch (err: any) {
        const error = err.response ? err.response.data : "SERVER ERROR";
        if (error.path)
          setError(error.path, { type: "manual", message: error.message });
        else notify("ERRO INTERNO DO SISTEMA", "error");
      }

      setLoadingCreate(false);
    },
    [defaultData, _form, form_ref, ownersRefs]
  );

  const handleStopPropagation = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e?.stopPropagation();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit, form_ref, _form, api, defaultData, ownersRefs]
  );

  const addOwnerGroup = useCallback(() => {
    const newOwnerGroup = generateOwnerGroup();
    setOwnersGroups((prev) => {
      return [...prev, newOwnerGroup];
    });
  }, [uniqueId]);

  const removeOwnerGroup = useCallback((index: number) => {
    setOwnersGroups((prev) => {
      return prev.filter(({ uniqueId }, i) => {
        if (i !== index) return true;
        else {
          const fields = ["customer_id", "type", "porcen"];
          fields.forEach((field) => unregister(field + "-" + uniqueId));
        }
      });
    });
  }, []);

  const changeTab = useCallback((index) => {
    setTabs((atual) => {
      const new_tabs = copyOf(atual) as tabProps[];
      for (let i = 0; i < new_tabs.length; i++) {
        new_tabs[i].open = i === index;
      }
      return new_tabs;
    });
  }, []);

  const onCancelModal = useCallback(() => {
    setContractSelected(null);
    setModalNewContract(false);
  }, []);

  const onClickCancelContract = useCallback(async (contract) => {
    setContractSelected({ ...contract });
    setOpenConfirmCancel(true);
  }, []);

  const openModalContract = useCallback(async (contract) => {
    setContractSelected({ ...contract });
    setModalNewContract(true);
  }, []);

  const confirmCancelContract = useCallback(async () => {
    setOpenConfirmCancel(false);

    setLoadingCancel(true);

    try {
      await api.put(`/contracts/cancel/${contractSelected?.id}`);

      notify("Documento eletrônico cancelado com sucesso!", "success");

      setContractSelected(null);

      searchContracts();
    } catch (err) {
      notify("Erro ao cancelar documento eletrônico!", "error");
    }

    setLoadingCancel(false);
  }, [contractSelected, defaultData]);

  const searchDocuments = useCallback(async () => {
    if (defaultData?.id) {
      setLoadingDocuments(true);

      try {
        const { data } = await api.get(
          `/documents?property_id=${defaultData?.id}`
        );

        setDocuments(data);
      } catch (err) {
        notify("Erro ao buscar documentos", "error");

        onCancel();
      }

      setLoadingDocuments(false);
    }
  }, []);

  const onSaveDocument = useCallback(async () => {
    setOpenModal(false);
    searchDocuments();
  }, []);

  const handleDownload = useCallback((id: string) => {
    const anchor = document.getElementById(id);
    if (anchor) anchor.click();
  }, []);

  const groups: IGroupProps[] = [
    {
      name: "general_data_property",
      label: "Dados Gerais",
      fields: [
        [
          {
            name: "broker_id",
            label: "Corretor",
            type: "select-single-cards-no-creatable",
            isClearable: true,
            searchOptions: consultsServices.person_id,
            moreQueries: { person_types: ["broker"] },
            getIsDisabled: (data: any) => user.type === "broker",
          },
        ],
        [
          {
            name: "property_type_id",
            label: "Tipo de Imóvel",
            type: "select-single-no-creatable",
            isClearable: true,
            required: true,
            searchOptions: consultsServices.property_type_id,
            moreQueries: { limit: 100 },
          },
          {
            name: "rent_value",
            label: "Valor do aluguel",
            type: "input",
            mask: "currency",
            required: true,
          },
          {
            name: "condo_value",
            label: "Valor do condomínio",
            type: "input",
            mask: "currency",
          },
          {
            name: "iptu_value",
            label: "Valor do IPTU",
            type: "input",
            mask: "currency",
          },
        ],
        [
          {
            name: "is_managed",
            label: "Administrado?",
            type: "select-fixed",
            required: true,
            isClearable: true,
            options: isManagerOptions,
          },
          {
            name: "managed",
            label: "Taxa de Administração",
            type: "input-porcen-or-money",
            canSee: (data: any) => data?.is_managed?.value,
          },
          {
            name: "rental_fee",
            label: "Taxa de Locação",
            type: "input-porcen-or-money",
          },
        ],
      ],
    },
    {
      name: "details_property",
      label: "Detalhes do Imóvel",
      fields: [
        [
          {
            name: "water",
            label: "Código água",
            type: "input",
          },
          {
            name: "electricity",
            label: "Código energia",
            type: "input",
          },
          {
            name: "gas",
            label: "Código gás",
            type: "input",
          },
          {
            name: "iptu",
            label: "Código IPTU",
            type: "input",
          },
          {
            name: "metreage",
            label: "Metragem",
            type: "input",
            mask: "m2",
            noUpperCase: true,
          },
        ],
        [
          {
            name: "localization_type",
            label: "Localização",
            type: "select-fixed",
            options: localizationsOptions,
            isClearable: true,
          },
          {
            name: "destination_type",
            label: "Destinação",
            type: "select-fixed",
            options: destinationsOptions,
            isClearable: true,
          },
        ],
      ],
    },
    {
      name: "address",
      label: "Dados de Endereço",
      fields: [
        [
          {
            name: "cep",
            label: "CEP",
            type: "input",
            mask: "cep",
            required: true,
            validate: validateCEP,
            executeOnChange: changeCEP,
          },
          {
            name: "state_id",
            label: "Estado",
            type: "select-single-no-creatable",
            isClearable: true,
            required: true,
            searchOptions: consultsServices.state_id,
            executeOnChange: async () => setValue("city_id", null),
          },
          {
            name: "city_id",
            label: "Cidade",
            type: "select-single-no-creatable",
            isClearable: true,
            required: true,
            searchOptions: consultsServices.city_id,
            additionalsQueries: (data: any) => ({
              state_id: data?.state_id?.value,
            }),
            getIsDisabled: (data: any) => !data?.state_id,
          },
        ],
        [
          {
            name: "district",
            label: "Bairro",
            type: "input",
            required: true,
          },
          {
            name: "public_place",
            label: "Logradouro",
            type: "input",
            required: true,
          },
          {
            name: "complement",
            label: "Complemento",
            type: "input",
          },
          {
            name: "number",
            label: "Número",
            type: "input",
            mask: "onlyNumber",
            required: true,
          },
        ],
      ],
    },
  ];

  useEffect(() => {
    searchContracts();
  }, []);

  useEffect(() => {
    searchDocuments();
  }, []);

  return (
    <>
      <Column style={{ overflow: "auto", flex: 1, padding: 20 }}>
        <div>
          <Tabs
            tabs={!loadingContracts ? tabs : disabledTabs}
            onChange={changeTab}
            pos={tabs.findIndex((tab) => tab.open)}
            disabled={loadingContracts}
          />
        </div>

        <ContainerForm open={tabs.findIndex((tab) => tab.open) === 0}>
          <Form onSubmit={handleStopPropagation} theme={theme}>
            <GenericForm
              ref={form_ref}
              groups={groups}
              _form={_form}
              control={control}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
              register={register}
              disabled={loadingCEP || disabledAll}
            />

            <ContainerGroup>
              <GroupTitle>Proprietário(s)</GroupTitle>
              {ownersGroups.map((owner_group, index) => (
                <Line key={owner_group.uniqueId} style={{ gap: 10 }}>
                  {ownersGroups.length > 1 && (
                    <Line
                      style={{
                        alignItems: "flex-start",
                        marginTop: 24,
                        minWidth: 36,
                      }}
                    >
                      <Tooltip
                        onClick={() => removeOwnerGroup(index)}
                        style={{ height: 36, width: 36 }}
                        title="Excluir"
                        children={
                          <div>
                            <BsTrash color={inactiveItens[theme]} size={20} />
                          </div>
                        }
                      />
                    </Line>
                  )}
                  <Column style={{ gap: 20, flex: 1 }}>
                    <GenericForm
                      ref={(ref: any) => (ownersRefs.current[index] = ref)}
                      groups={[owner_group]}
                      _form={_form}
                      control={control}
                      errors={errors}
                      trigger={trigger}
                      setValue={setValue}
                      register={register}
                      disabled={loadingCEP || disabledAll}
                    />
                  </Column>
                </Line>
              ))}
              {!(loadingCEP || disabledAll) && (
                <Line style={{ display: "flex" }}>
                  <AddButton
                    noHaveDelete={!(ownersGroups.length > 1)}
                    onClick={addOwnerGroup}
                  >
                    Incluir+
                  </AddButton>
                </Line>
              )}
            </ContainerGroup>

            <button
              ref={buttonSubmitRef}
              type="submit"
              style={{ display: "none" }}
            />
          </Form>
        </ContainerForm>

        <ContainerDocumentsEletronics
          open={tabs.findIndex((tab) => tab.open) === 1}
        >
          <ContentOrLoading
            loading={loadingContracts}
            theme={theme}
            type="wave"
          >
            <Line style={{ justifyContent: "flex-end" }}>
              <div>
                <Button
                  style={{ minHeight: 38, maxHeight: 38 }}
                  onClick={() => setModalNewContract(true)}
                  borderColor={secondary[theme]}
                  background={secondary[theme]}
                  color="#FFF"
                  children="Novo Documento Eletrônico"
                />
              </div>
            </Line>
            <Column style={{ flex: 1 }}>
              <Column
                style={{
                  gap: 8,
                  fontSize: 13.5,
                  background: primary[theme],
                  borderRadius: 4,
                  border: `1px solid ${
                    theme === "light" ? "#CCCCCC" : "rgb(68 68 68)"
                  }`,
                  overflow: "auto",
                }}
              >
                <table style={{ width: "100%", textTransform: "uppercase" }}>
                  <thead style={{ background: background[theme] }}>
                    <tr>
                      <th style={{ padding: 10 }}>NOME</th>
                      <th style={{ padding: 10 }}>SIGNATÁRIO(S)</th>
                      <th style={{ padding: 10, textAlign: "center" }}>
                        AUTOR
                      </th>
                      <th style={{ padding: 10, textAlign: "center" }}>
                        STATUS
                      </th>
                      <th style={{ padding: 10, textAlign: "center" }}>
                        CRIAÇÃO
                      </th>
                      <th style={{ padding: 10, textAlign: "center" }}>
                        EXPIRAÇÃO
                      </th>
                      <th style={{ padding: 10, textAlign: "center" }}>
                        AÇÕES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contracts.map((contract) => (
                      <tr>
                        <td style={{ padding: 10 }}>{contract.name}</td>
                        <td style={{ padding: 10 }}>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 5,
                            }}
                          >
                            {contract.signatories.map((signatory) => {
                              return (
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      padding: 5,
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                      paddingLeft: 7,
                                      paddingRight: 7,
                                      borderRadius: 3,
                                      background: secondary[theme],
                                      color: "#FFF",
                                      fontWeight: 600,
                                      fontSize: 12,
                                    }}
                                  >
                                    {signatory.name}
                                  </div>
                                </div>
                              );
                            })}
                            {contract.signatories.length === 0 && (
                              <span
                                style={{
                                  fontStyle: "italic",
                                  opacity: 0.7,
                                  fontSize: 14,
                                }}
                              >
                                Nenhum(a) signatário(a)
                              </span>
                            )}
                          </div>
                        </td>
                        <td style={{ padding: 10, textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              gap: 5,
                            }}
                          >
                            <div
                              style={{
                                padding: 5,
                                whiteSpace: "nowrap",
                                display: "flex",
                                paddingLeft: 7,
                                paddingRight: 7,
                                borderRadius: 3,
                                color: secondary[theme],
                                border: `1px solid ${secondary[theme]}`,
                                fontWeight: 600,
                                fontSize: 12,
                              }}
                            >
                              {contract.person.registration_data?.name}
                            </div>
                          </div>
                        </td>
                        <td style={{ padding: 10, textAlign: "center" }}>
                          {getStatus({ ...contract_status[contract.status] })}
                        </td>
                        <td
                          style={{
                            padding: 10,
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {correctDate(contract.createdAt)}
                        </td>
                        <td
                          style={{
                            padding: 10,
                            whiteSpace: "nowrap",
                            textAlign: "center",
                          }}
                        >
                          {correctDate(contract.expiries_in)}
                        </td>
                        <td
                          style={{
                            padding: 10,
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip
                            onClick={() => openModalContract(contract)}
                            title="Visualizar"
                            style={{ margin: 0, padding: 10 }}
                          >
                            <MdOutlineRemoveRedEye
                              color={secondary[theme]}
                              size={20}
                            />
                          </Tooltip>
                          {["pending"].includes(contract.status) && (
                            <Tooltip
                              onClick={() => onClickCancelContract(contract)}
                              style={{ margin: 0, padding: 10 }}
                              title="Cancelar"
                            >
                              <TiCancel color={secondary[theme]} size={20} />
                            </Tooltip>
                          )}

                          {["signed", "pending"].includes(contract.status) &&
                            (contract.file_signed_url ||
                              contract.file_original_url) && (
                              <Tooltip
                                onClick={() => handleDownload(contract.id)}
                                style={{ margin: 0, padding: 10 }}
                                title={
                                  contract.file_signed_url
                                    ? "documento eletrônico Assinado"
                                    : "documento eletrônico Original"
                                }
                              >
                                <>
                                  <FiDownload
                                    color={secondary[theme]}
                                    size={20}
                                  />
                                  <a
                                    id={contract.id}
                                    href={
                                      contract?.file_signed_url
                                        ? contract?.file_signed_url
                                        : contract?.file_original_url
                                    }
                                    target="_blank"
                                    style={{ display: "none" }}
                                  />
                                </>
                              </Tooltip>
                            )}
                        </td>
                      </tr>
                    ))}
                    {contracts.length === 0 && (
                      <tr>
                        <td
                          colSpan={7}
                          style={{
                            padding: 10,
                            textAlign: "center",
                            fontWeight: 600,
                            color: "rgb(127 127 127)",
                            fontStyle: "italic",
                          }}
                          children={"Imóvel sem documentos eletrônicos."}
                        />
                      </tr>
                    )}
                  </tbody>
                </table>
              </Column>
            </Column>
          </ContentOrLoading>
        </ContainerDocumentsEletronics>

        <ContainerDocuments open={tabs.findIndex((tab) => tab.open) === 2}>
          <Line
            style={{
              padding: 16,
              paddingBottom: 4,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Line
              style={{
                gap: 10,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ minHeight: 38, maxHeight: 38 }}
                onClick={() => setExibition("grid")}
                borderColor={secondary[theme]}
                background={exibition === "grid" ? secondary[theme] : "#FFF"}
                color={exibition === "list" ? secondary[theme] : "#FFF"}
                children={<BiGridAlt size={20} />}
              />
              <Button
                style={{ minHeight: 38, maxHeight: 38 }}
                onClick={() => setExibition("list")}
                borderColor={secondary[theme]}
                background={exibition === "list" ? secondary[theme] : "#FFF"}
                color={exibition === "grid" ? secondary[theme] : "#FFF"}
                children={<BiListUl size={20} />}
              />
              <Button
                style={{ minHeight: 38, maxHeight: 38 }}
                onClick={() => setOpenModal(true)}
                borderColor={secondary[theme]}
                background={secondary[theme]}
                color="#FFF"
                children={
                  <div style={{ whiteSpace: "nowrap" }}>Anexar documento</div>
                }
              />
            </Line>
          </Line>
          <ListDocuments
            exibition={exibition}
            open={true}
            customer={null as any}
            relation={"property"}
            property={defaultData as any}
            loading={loadingDocuments}
            documents={documents}
            onSave={onSaveDocument}
            colors={primary}
          />
        </ContainerDocuments>
      </Column>

      {tabs.findIndex((tab) => tab.open) === 0 && (
        <ContainerButtons theme={theme}>
          <Button
            onClick={() => onCancel()}
            background={inactiveItens[theme]}
            color={"#FFF"}
            style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
            children="Voltar"
          />
          {(["responsible", "analyst", "manager", "root"].includes(user.type) ||
            !disabledAll) && (
            <Button
              onClick={() => buttonSubmitRef.current?.click()}
              background={secondary[theme]}
              color={"#FFF"}
              style={{ padding: 8, maxWidth: 150, fontSize: 14 }}
              children="Salvar"
            />
          )}
        </ContainerButtons>
      )}

      {openModal && (
        <ModalGeneric onClose={() => {}} open={openModal}>
          <DocumentModal
            onClose={() => setOpenModal(false)}
            customer={null as any}
            property={defaultData as any}
            relation={"property"}
            onSave={onSaveDocument}
          />
        </ModalGeneric>
      )}

      {modalNewContract && (
        <ModalContract
          onCancel={onCancelModal}
          onSave={onSaveContract}
          openModal={modalNewContract}
          backgroundVisible
          defaultContract={copyOf(contractSelected)}
          property={defaultProperty}
        />
      )}

      <ModalAttention
        theme={theme}
        open={openConfirmCancel}
        cancelAction={() => setOpenConfirmCancel(false)}
        confirmAction={confirmCancelContract}
        cancelText="Voltar"
        content={
          <>
            Você deseja cancelar o documento eletrônico{" "}
            <b>{contractSelected?.name?.toUpperCase()}</b> ?
          </>
        }
      />

      <ModalLoading loading={loadingCreate || loadingCancel} theme={theme} />
    </>
  );
};
