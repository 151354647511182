import React, { useRef } from "react";
import { BiSearch } from "react-icons/bi";
import { secondary } from "../../../../theme";
import { FieldList } from "../../../FiedlList";
import { useGlobal } from "../../../../hooks/global";
import { Line, LineAllCenter } from "../../../../style";
import { IconButton, Tooltip } from "@material-ui/core";
import { maskFunctions } from "../../../../services/maskServices";
import { ContainerObsOrDocs, EmptyText, ObsOrDocsTitle } from "../style";
import { ContainerTitle, ContentTitle, Description, Title } from "./style";
import { documentsAttributes, propertiesAttributes } from "../../../../interfaces";
import { destinationsOptions, get_corrrect_names, localizationsOptions } from "../../../../utils/options";

interface IPropertyData {
    property?: propertiesAttributes
    documents?: documentsAttributes[] | null | undefined
}

export const PropertyData: React.FC<IPropertyData> = (props) => {
    const { property, documents } = props

    const { theme } = useGlobal()

    const anchor_refs = useRef<HTMLAnchorElement[]>([])

    const FieldsData = [
        {
            label: "Código",
            keys: ["friendly_id"]
        },
        {
            label: "Tipo",
            keys: ["property_type", "name"]
        },
        {
            label: "Valor do aluguel",
            keys: ["rent_value"],
            mask: maskFunctions.currency.mask
        },
        {
            label: "Valor do condomínio",
            keys: ["condo_value"],
            mask: maskFunctions.currency.mask
        },
        {
            label: "Valor do IPTU",
            keys: ["iptu_value"],
            mask: maskFunctions.currency.mask
        },
        {
            label: "Administrado",
            keys: ["is_managed"]
        },
        {
            label: "Taxa de Administração",
            keys: ["managed_value"],
            get_type_mask: (value: any) => value.managed_type === "PORCEN" ? (value: any) => maskFunctions.porcen.mask(value, true) : maskFunctions.currency.mask,
            can_show: (value: any) => value.is_managed
        },
        {
            label: "Taxa de Locação",
            keys: ["rental_fee_value"],
            get_type_mask: (value: any) => value.rental_fee_type === "PORCEN" ? (value: any) => maskFunctions.porcen.mask(value, true) : maskFunctions.currency.mask
        },
        {
            label: "Metragem",
            keys: ["metreage"],
            mask: (value: any) => maskFunctions.m2.mask(value, true)
        },
        {
            label: "Localização",
            keys: ["localization_type"],
            transform: get_corrrect_names(localizationsOptions)
        },
        {
            label: "Destinação",
            keys: ["destination_type"],
            transform: get_corrrect_names(destinationsOptions)
        },
        {
            label: "Código água",
            keys: ["water"],
        },
        {
            label: "Código energia",
            keys: ["electricity"]
        },
        {
            label: "Código gás",
            keys: ["gas"]
        },
        {
            label: "Código IPTU",
            keys: ["iptu"]
        },
        {
            label: "Proprietários",
            keys: ["owners"],
            magic_key: "customer"
        }
    ]

    const FieldsAddress = [
        {
            label: "CEP",
            keys: ["address", "cep"]
        },
        {
            label: "Estado",
            keys: ["address", "state", "initials"]
        },
        {
            label: "Cidade",
            keys: ["address", "city", "name"]
        },
        {
            label: "Bairro",
            keys: ["address", "district"]
        },
        {
            label: "Logradouro",
            keys: ["address", "public_place"]
        },
        {
            label: "Complemento",
            keys: ["address", "complement"]
        },
        {
            label: "Número",
            keys: ["address", "number"]
        }
    ]

    const ListFields = [
        {
            title: "Dados de Registro",
            fields: FieldsData
        },
        {
            title: "Dados de Endereço",
            fields: FieldsAddress
        }
    ]

    return (
        <>
            <FieldList
                theme={theme}
                data={property}
                listFields={ListFields}
            />

            <ContainerObsOrDocs theme={theme}>

                <ObsOrDocsTitle>DOCUMENTAÇÃO</ObsOrDocsTitle>

                {documents?.map((document, index) => (
                    <ContainerTitle theme={theme} haveBorder={documents.length > 1 && index !== (documents.length - 1)}>

                        <ContentTitle>
                            <Title>{document.name}</Title>
                            <Line>
                                <Description theme={theme}>
                                    {document.document_type?.name}
                                </Description>
                            </Line>
                        </ContentTitle>

                        <LineAllCenter>
                            <Tooltip onClick={() => anchor_refs.current[index].click()} style={{ height: 40, width: 40 }} title="Visualizar">
                                <IconButton aria-label="Visualizar">
                                    <LineAllCenter>
                                        <BiSearch
                                            size={19}
                                            color={secondary[theme]}
                                        />
                                    </LineAllCenter>
                                </IconButton>
                            </Tooltip>
                        </LineAllCenter>

                        <a
                            style={{ display: "none" }}
                            target="_blank"
                            href={`${process.env.REACT_APP_S3_URL}/${document.name}`}
                            ref={(ref: any) => anchor_refs.current[index] = ref}
                        />
                    </ContainerTitle>
                ))}

                {documents?.length === 0 && (
                    <EmptyText style={{ padding: 10 }}>
                        Nenhum documento encontrado
                    </EmptyText>
                )}

            </ContainerObsOrDocs>
        </>
    )
}