import React from "react";
import Button from "../../../Buttons/Button";
import { Receipt } from "../Receipt";
import { RiBarcodeFill } from "react-icons/ri";
import { Column, Line } from "../../../../style";
import { useGlobal } from "../../../../hooks/global";
import { copyOf } from "../../../../services/generalServices";
import { ContainerQrCode, ImageQrCode, InputCopy } from "./style";
import { borderColors, inactiveItens, primary, secondary } from "../../../../theme";
import { tabProps, themeTypes, transactionsAttributes } from "../../../../interfaces";
interface IReceiptProps {
    theme: themeTypes
    transaction: transactionsAttributes | null
    refresh: () => void
}

const initialsTabs: tabProps[] = [
    { label: "BOLIX", canClick: true, open: true },
    { label: "LINHA DIGITÁVEL", canClick: true, open: false }
]

export const ReceiptBolix: React.FC<IReceiptProps> = (props) => {
    const { theme, transaction } = props

    const [tabs, setTabs] = React.useState<tabProps[]>(initialsTabs)

    const { notifyBottom } = useGlobal()

    const changeTabs = (newPos: number) => {
        setTabs(atualTabs => {
            const newTabs = copyOf(atualTabs) as tabProps[]
            newTabs.forEach((tab, index) => {
                tab.open = index === newPos
            })
            return [...newTabs]
        })
    }

    const copyLink = ({ _id, text }: { _id: string, text: string }) => {
        try {
            const linkText: any = document.getElementById(_id)
            linkText.select();
            const success = document.execCommand("copy");
            linkText.selectionEnd = 0
            if (success) return notifyBottom(text + " copiado para área de tranferência!", "success");
            else throw new Error();
        } catch (err) {
            notifyBottom("Erro ao copiar o " + text.toLocaleLowerCase() + " para área de tranferência!", "error");
        }
    }

    return (
        <>
            <Receipt transaction={transaction} theme={theme} />
            <Column style={{ gap: 10, padding: 20, paddingTop: 0 }}>
                <Column style={{ border: `1px solid ${borderColors[theme]}`, borderRadius: 5, background: primary[theme], overflow: "hidden" }}>
                    <Line>
                        {tabs.map(({ label, open }, index) => (
                            <Line onClick={() => changeTabs(index)} style={{ alignItems: "center", flex: 1, cursor: "pointer", background: open ? primary[theme] : (theme === "light" ? "#E7E7E7" : "#535353"), padding: 20, justifyContent: "center" }} key={label + "-tab"}>
                                <div style={{ fontSize: 16, fontWeight: 600, color: theme === "light" ? inactiveItens[theme] : "#FFF", textAlign: "center" }}>{label}</div>
                            </Line>
                        ))}
                    </Line>
                    <Column style={{ padding: 20, gap: 20 }}>

                        <Column style={{ gap: 20, display: tabs.findIndex((tab) => tab.open) === 0 ? "flex" : "none" }}>
                            <Column style={{ gap: 16 }}>
                                <div style={{ fontWeight: 600 }}>Pague seu boleto agora com o PIX</div>
                                <ContainerQrCode>
                                    <ol style={{ margin: 0, padding: 0, marginLeft: 16 }}>
                                        <li style={{ fontSize: 16 }}>Acesse seu Internet Banking ou app de pagamentos.</li>
                                        <li style={{ fontSize: 16 }}>Escolha pagar via Pix.</li>
                                        <li style={{ fontSize: 16 }}>Escaneie o QR code.</li>
                                    </ol>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <ImageQrCode src={transaction?.qrcode ?? ""} />
                                    </div>
                                </ContainerQrCode>
                            </Column>
                            <Column style={{ gap: 10, justifyContent: "center", alignItems: "center" }}>
                                <Line style={{ border: `1px solid ${borderColors[theme]}`, borderRadius: 5, padding: 10, gap: 20, background: primary[theme], width: "100%" }}>
                                    <InputCopy id="link-qr-code" theme={theme} readOnly value={`${transaction?.qrcode_copy_and_paste ?? ""}`} />
                                    <div onClick={() => copyLink({ _id: "link-qr-code", text: "QR Code" })} style={{ fontWeight: 600, color: secondary[theme], cursor: "pointer" }}>Copiar</div>
                                </Line>
                            </Column>
                        </Column>

                        <Column style={{ gap: 20, display: tabs.findIndex((tab) => tab.open) === 1 ? "flex" : "none" }}>
                            <Column style={{ gap: 16 }}>
                                <div style={{ fontWeight: 600 }}>Escaneie o código de barras ou copie a linha editável</div>
                                <ContainerQrCode>
                                    <ol style={{ margin: 0, padding: 0, marginLeft: 16 }}>
                                        <li style={{ fontSize: 16 }}>Acesse seu Internet Banking ou app de pagamentos.</li>
                                        <li style={{ fontSize: 16 }}>Vá até a opção de pagamento de boleto bancário.</li>
                                        <li style={{ fontSize: 16 }}>Informe a linha digitável.</li>
                                    </ol>
                                </ContainerQrCode>
                            </Column>
                            <Column style={{ gap: 10, justifyContent: "center", alignItems: "center" }}>
                                <Line style={{ border: `1px solid ${borderColors[theme]}`, borderRadius: 5, padding: 10, gap: 20, background: primary[theme], width: "100%" }}>
                                    <InputCopy id="link-copy" theme={theme} readOnly value={`${transaction?.barcode ?? ""}`} />
                                    <div onClick={() => copyLink({ _id: "link-copy", text: "Linha digitável" })} style={{ fontWeight: 600, color: secondary[theme], cursor: "pointer" }}>Copiar</div>
                                </Line>
                            </Column>
                        </Column>

                        <Button onClick={() => window.open(transaction?.charge ?? "", "_blank")} style={{ gap: 10, alignItems: "center" }} background={secondary[theme]} color="#FFF">
                            <div>
                                <RiBarcodeFill size={24} />
                            </div>
                            Imprimir boleto
                        </Button>
                    </Column>
                </Column>
            </Column>
        </>
    )
}