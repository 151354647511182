import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { FiPlusCircle } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { IOptionsProps } from "../../../../interfaces";
import { FixRequiredSelect, IFixRequiredSelectProps, ISelectRefProps } from "../FixRequiredSelect";

const debounceEvent = () => {
    let timer: any = null
    return (fn: any, wait: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => fn(), wait)
    }
}

const debounce = debounceEvent()

export interface ISelectCreateSingleRefProps {
    getValue: () => IOptionsProps | null
    focus: () => void
}

export interface ISelectCreateSingleProps extends IFixRequiredSelectProps {
    searchOptions: Function
    onCreateOption: Function
    defaultValue?: IOptionsProps
    icon?: any
}

const SelectCreateSingleComponent: React.ForwardRefRenderFunction<ISelectCreateSingleRefProps, ISelectCreateSingleProps> = (props, ref) => {
    const { icon = <AiOutlineUser />, onFocus = () => { }, onChange = () => { }, onClick = () => { }, defaultValue = null } = props
    const { searchOptions, error, isClearable, isDisabled = false, isRequired = false, noOptionsMessage, onCreateOption } = props

    const [loading, setLoading] = useState(false)
    const [controlRender, setControlRender] = useState(false)
    const [options, setOptions] = useState<IOptionsProps[]>([])
    const [option_selected, setOptionSelected] = useState<IOptionsProps | null>(defaultValue)

    const select_ref = useRef<ISelectRefProps>(null)

    const formatOptionLabel = useCallback((value: any, option) => {
        if (option?.value === value?.value) {
            return (
                <div onClick={() => onClick(value)} style={{ display: "flex", flexDirection: "row", marginRight: 10, color: "#FFF", alignItems: "center", gap: 5, cursor: props.onClick ? "pointer" : "default" }}>
                    <div>{value.__isNew__ ? <FiPlusCircle /> : icon}</div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: 600 }}>{value.label}</div>
                </div>
            )
        }
        else {
            return (
                <div style={{ display: "flex", flexDirection: "row", marginRight: 10, alignItems: "center", gap: 5, cursor: props.onClick ? "pointer" : "default" }}>
                    <div>{value.__isNew__ ? <FiPlusCircle /> : icon}</div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{value.label}</div>
                </div>
            )
        }
    }, [isDisabled])

    const search = useCallback(async (name) => {
        setLoading(true)
        const result = await searchOptions(name)
        setOptions([...result.formatted])
        setLoading(false)
    }, [isDisabled])

    const onInputChange = useCallback((value) => {
        setOptions([])
        setLoading(true)
        debounce(() => search(value), 800)
    }, [isDisabled])

    const searchWithoutDebounce = useCallback((value) => {
        setOptions([])
        setLoading(true)
        search(value)
    }, [isDisabled])

    const onChangeOption = useCallback((options) => {
        const selected_option = options.length > 0 ? { ...options[options.length - 1] } : null
        setOptionSelected(selected_option ? { ...selected_option } : null)
        onChange(selected_option ? { ...selected_option } : null)
    }, [isDisabled])

    const onInputFocus = useCallback((e) => { onFocus(e) }, [])

    const getValue = useCallback(() => option_selected, [option_selected])

    const focus = useCallback(() => { select_ref.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ getValue, focus }))

    useEffect(() => { searchWithoutDebounce("") }, [])

    useEffect(() => { setControlRender((atual) => !atual) }, [option_selected])

    const childrenDisabled = (
        <FixRequiredSelect
            ref={select_ref}
            noWrap
            onFocus={onInputFocus}
            onBlur={() => searchWithoutDebounce("")}
            onCreateOption={(data: any) => onCreateOption(data)}
            formatCreateLabel={(value: any) => `Cadastrar "${value}"`}
            noOptionsMessage={() => noOptionsMessage}
            placeholder=""
            isDisabled
            isCreatable
            isClearable={false}
            isLoading={false}
            isRequired={isRequired}
            loadingMessage={() => <div style={{ position: "relative" }}>Buscando...</div>}
            options={options}
            onChange={onChangeOption}
            onInputChange={(text: any) => { onInputChange(text) }}
            value={option_selected}
            isMulti
            formatOptionLabel={(value: any) => formatOptionLabel(value, option_selected)}
            menuIsOpen={false}
            isSearchable={false}
            components={{ MultiValueRemove: () => null }}
            error={error}
        />
    )

    const childrenNotDisabled = (
        <FixRequiredSelect
            ref={select_ref}
            noWrap
            onFocus={onInputFocus}
            onBlur={() => searchWithoutDebounce("")}
            onCreateOption={(data: any) => onCreateOption(data)}
            formatCreateLabel={(value: any) => `Cadastrar "${value}"`}
            noOptionsMessage={() => noOptionsMessage}
            placeholder=""
            isCreatable
            isClearable={isClearable}
            isLoading={loading}
            isRequired={isRequired}
            loadingMessage={() => <div style={{ position: "relative" }}>Buscando...</div>}
            options={options}
            onChange={onChangeOption}
            onInputChange={(text: any) => { onInputChange(text) }}
            value={option_selected}
            isMulti
            formatOptionLabel={(value: any) => formatOptionLabel(value, option_selected)}
            error={error}
        />
    )

    return (
        <>
            {isDisabled &&
                <>
                    {controlRender && childrenDisabled}
                    {!controlRender && childrenDisabled}
                </>
            }
            {!isDisabled &&
                <>
                    {controlRender && childrenNotDisabled}
                    {!controlRender && childrenNotDisabled}
                </>
            }
        </>
    )
}

export const SelectCreateSingle = forwardRef(SelectCreateSingleComponent)