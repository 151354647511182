import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, borderColors, inactiveItens, primary, shadowColor } from "../../../theme";

export const ContainerCustomSelect = styled.div`
    display: flex;
    min-width: 350px;
    max-width: 350px;
    position:relative;
    flex-direction:column;
`

export const Container = styled.div<IThemeProps>`
    display: flex;
    max-width: 830px;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const Header = styled.div<IThemeProps>`
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    justify-content: center;
    min-height: 82px;
    ${props => css`
        box-shadow: 0.125rem 0.125rem 0.5rem ${shadowColor[props.theme as themeTypes]};
        background: ${primary[props.theme as themeTypes]};
    `}
`

export const ContainerBack = styled.div`
    cursor: pointer;
    position: absolute;
    left: 10px;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
`

export const Content = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 20px;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    max-height: calc(calc(100 * var(--vh)) - 82px);
    min-height: calc(calc(100 * var(--vh)) - 82px);
`

export const Anchor = styled.a<IThemeProps>`
    font-weight: 600;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const TDName = styled.td`
    padding: 10px;
`

export const TDDescription = styled.td`
    padding: 10px;
    font-weight: 600;
    white-space: nowrap;
`

export const TDActions = styled.td`
    padding: 10px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
`

export const TH = styled.th`
    padding: 10px;
`

export const THead = styled.thead<IThemeProps>`
    ${props => css`
        background: ${background[props.theme as themeTypes]};
    `}
`

export const Table = styled.table`
    width: 100%;
    overflow: auto;
`

export const BagContent = styled.div<IThemeProps>`
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    overflow: auto;
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]} ;
    `}
`

export const ModalTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
`

export const InputVariable = styled.input<IThemeProps>`
    background: transparent;
    resize: none;
    border: none;
    width: 100%;
    cursor: pointer;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]} ;
    `}
`