import Button from "../../Buttons/Button";
import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../../hooks/api";
import { IoIosArrowDown } from "react-icons/io";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { ListDocuments } from "../../ListDocuments";
import { BiGridAlt, BiListUl } from "react-icons/bi";
import { IconButton, Tooltip } from "@material-ui/core";
import { inactiveItens, secondary } from "../../../theme";
import { DocumentModal } from "../../Modals/ModalDocument";
import { Column, Line, LineAllCenter } from "../../../style";
import { documentsAttributes, personsAttributes, propertiesAttributes } from "../../../interfaces";
import { Container, ContainerArrow, ContainerList, ContainerTitle, ContentTitle, Description, Title } from "./style";

interface IBagDocuments {
    title: string
    description: string
    icon: any
    exibition: "grid" | "list"
    onClickExibition: Function
    customer?: personsAttributes
    property?: propertiesAttributes
    relation: string
    loading?: boolean
    setLoading?: any
}

export const BagDocuments: React.FC<IBagDocuments> = (props) => {
    const { relation, customer, property, description, icon, title, exibition, onClickExibition } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [documents, setDocuments] = useState<documentsAttributes[] | undefined | null>([])

    const searchDocuments = useCallback(async () => {
        setLoading(true)
        try {
            if (customer) {
                const result = await api.get(`/documents?customer_id=${customer.id}`)
                setDocuments([...result.data])
            } else if (property) {
                const result = await api.get(`/documents?property_id=${property.id}`)
                setDocuments([...result.data])
            }
        } catch (err) {
            setDocuments([])
            notify("Erro ao buscar documentos", "error")
        }
        setLoading(false)
    }, [])

    const onSaveDocument = useCallback(async () => {
        setOpenModal(false)
        searchDocuments()
    }, [])

    useEffect(() => { if (open) searchDocuments() }, [open])

    return (
        <Container theme={theme}>

            <ContainerTitle>

                <ContentTitle onClick={() => setOpen((atual) => !atual)}>
                    <LineAllCenter>{icon}</LineAllCenter>
                    <Column>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </Column>
                </ContentTitle>

                {window.innerWidth >= 600 && (
                    <Line style={{ paddingRight: 16, gap: 10, alignItems: "center" }}>
                        <Button onClick={() => onClickExibition("grid")} borderColor={secondary[theme]} background={exibition === "grid" ? secondary[theme] : "#FFF"} color={exibition === "list" ? secondary[theme] : "#FFF"}>
                            <BiGridAlt size={20} />
                        </Button>
                        <Button onClick={() => onClickExibition("list")} borderColor={secondary[theme]} background={exibition === "list" ? secondary[theme] : "#FFF"} color={exibition === "grid" ? secondary[theme] : "#FFF"}>
                            <BiListUl size={20} />
                        </Button>
                        <Button onClick={() => setOpenModal(true)} background={secondary[theme]} color="#FFF">
                            <div style={{ whiteSpace: "nowrap" }}>Anexar documento</div>
                        </Button>
                        <Tooltip onClick={() => setOpen((atual) => !atual)} title={open ? "Fechar" : "Abrir"} style={{ width: 30, height: 30 }}>
                            <IconButton aria-label={open ? "Fechar" : "Abrir"}>
                                <ContainerArrow open={open}>
                                    <IoIosArrowDown
                                        color={inactiveItens[theme]}
                                        size={16}
                                    />
                                </ContainerArrow>
                            </IconButton>
                        </Tooltip>
                    </Line>
                )}

                {window.innerWidth < 600 && (
                    <Line style={{ paddingRight: 16, gap: 10, alignItems: "center" }}>
                        <Tooltip onClick={() => setOpen((atual) => !atual)} title={open ? "Fechar" : "Abrir"} style={{ width: 30, height: 30 }}>
                            <IconButton aria-label={open ? "Fechar" : "Abrir"}>
                                <ContainerArrow open={open}>
                                    <IoIosArrowDown
                                        color={inactiveItens[theme]}
                                        size={16}
                                    />
                                </ContainerArrow>
                            </IconButton>
                        </Tooltip>
                    </Line>
                )}

            </ContainerTitle>

            {window.innerWidth < 600 && (
                <Line style={{ padding: "0px 16px 16px 16px", gap: 10, alignItems: "center", flexWrap: "wrap" }}>
                    <Line style={{ flex: 1 }}>
                        <Button onClick={() => onClickExibition("grid")} borderColor={secondary[theme]} background={exibition === "grid" ? secondary[theme] : "#FFF"} color={exibition === "list" ? secondary[theme] : "#FFF"}>
                            <BiGridAlt size={20} />
                        </Button>
                    </Line>
                    <Line style={{ flex: 1 }}>
                        <Button onClick={() => onClickExibition("list")} borderColor={secondary[theme]} background={exibition === "list" ? secondary[theme] : "#FFF"} color={exibition === "grid" ? secondary[theme] : "#FFF"}>
                            <BiListUl size={20} />
                        </Button>
                    </Line>
                    <Line style={{ flex: 1 }}>
                        <Button onClick={() => setOpenModal(true)} background={secondary[theme]} color="#FFF">
                            <div style={{ whiteSpace: "nowrap" }}>Anexar documento</div>
                        </Button>
                    </Line>
                </Line>
            )}

            {open && (
                <ContainerList theme={theme}>
                    <ListDocuments
                        exibition={exibition}
                        open={open}
                        customer={customer as any}
                        relation={relation}
                        property={property as any}
                        loading={loading}
                        documents={documents}
                        onSave={onSaveDocument}
                    />
                </ContainerList>
            )}

            {openModal &&
                <ModalGeneric onClose={() => { }} open={openModal}>
                    <DocumentModal
                        onClose={() => setOpenModal(false)}
                        customer={customer}
                        property={property}
                        relation={relation}
                        onSave={onSaveDocument}
                    />
                </ModalGeneric>
            }

        </Container>
    )
}