import React, { useCallback, useEffect, useState } from "react"
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { ROOT_USERS } from "../../utils/users.groups";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { ModalPerson } from "../../components/Modals/ModalPerson";
import { initialsName, setUpConsultation } from "../../services/generalServices";
import { responsibleProps, IFilters, personsAttributes, ITagList, IActions } from "../../interfaces/index";

const initialFilters: IFilters[] = [
    getFilterFields("state_id", ROOT_USERS),
    getFilterFields("city_id", ROOT_USERS)
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome completo", limitWidth: true, style: { whiteSpace: "nowrap" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "cpf", name: "CPF", style: {}, styleContent: {} },
        { attr: "email", name: "Email", limitWidth: true, style: {}, styleContent: {} },
        { attr: "phone", name: "Telefone", style: {}, styleContent: {} },
        { attr: "franchises", name: "Imobiliárias", style: {}, styleContent: {} },
        { attr: "city", name: "Cidade", style: {}, styleContent: {} }
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Novo Responsável", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

const getFranchises: React.FC<ITagList> = ({ theme, responsible }) => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            {responsible.franchises.map((franchise: any) => (
                <div style={{ display: "flex" }}>
                    <div style={{ padding: 5, whiteSpace: "nowrap", display: "flex", paddingLeft: 7, paddingRight: 7, borderRadius: 3, background: secondary[theme], color: "#FFF", fontWeight: 600, fontSize: 12 }}>
                        {franchise.registration_data?.fantasy_name}
                    </div>
                </div>
            ))}
            {responsible.franchises.length === 0 &&
                <span style={{ fontStyle: "italic", opacity: 0.7 }}>Nenhuma</span>
            }
        </div>
    )
}

const getNameWithPhoto: React.FC<ITagList> = ({ responsible, theme }) => {
    if (responsible.user?.photo) return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 30, maxWidth: 30, maxHeight: 30, minHeight: 30, borderRadius: "50%", overflow: "hidden" }}>
                <img src={responsible.user.photo} alt="Foto" style={{ minWidth: 30, minHeight: 30, objectFit: "cover" }} />
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{responsible?.registration_data?.name || null}</div>
        </div>

    )
    else return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: 12, color: "rgb(21 21 21)", background: "rgb(206 206 206)", alignItems: "center", minWidth: 30, maxWidth: 30, minHeight: 30, maxHeight: 30, borderRadius: "50%" }}>
                {initialsName(responsible?.registration_data?.name as string)}
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{responsible?.registration_data?.name || null}</div>
        </div>
    )
}

const getCity = (customer: personsAttributes) => {
    if (customer?.address?.city && customer?.address?.state) {
        return `${customer?.address?.city.name} - ${customer?.address?.state?.initials}`
    }
    else return null
}

export const ResponsiblesList: React.FC = () => {
    const { api } = useApi()
    const { theme, paginationLimit, notify } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [searchString, setSearchString] = useState<string>("")
    const [numOfResponsibles, setNumOfResponsibles] = useState(0)
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [loadingResponsibles, setLoadingResponsibles] = useState(true)
    const [responsibleSelected, setResponsibleSelected] = useState<responsibleProps | null>(null)

    const searchResponsibles = useCallback(async (page: number, _filters?: any) => {
        setAtualPage(page)
        setLoadingResponsibles(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }
            filters_obj.state = filters_obj.state_id
            filters_obj.city = filters_obj.city_id
            filters_obj.person_types = JSON.stringify(["responsible"])

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/persons?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_responsible = result.data.rows as personsAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_responsible.forEach((responsible) => {
                    new_table.data.push({
                        id: responsible.id,
                        name: getNameWithPhoto({ responsible, theme }),
                        cpf: responsible?.registration_data?.cpf ? maskFunctions.cpf.mask(responsible?.registration_data?.cpf) : null,
                        email: responsible?.user?.email || null,
                        phone: responsible?.registration_data?.phone ? maskFunctions.phone.mask(responsible?.registration_data?.phone) : null,
                        franchises: getFranchises({ theme, responsible }),
                        city: getCity(responsible),
                        this: responsible
                    })
                })
                return { ...new_table }
            })

            setNumOfResponsibles(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelos responsáveis!", "error")
        }
        setLoadingResponsibles(false)
    }, [filters, searchString])

    const changeStatusUser = useCallback(async (userId: string, old_status: "block" | "active" | null) => {
        const new_status = old_status === "active" ? "block" : "active"

        setTable((atual) => {
            const new_table = { ...atual }
            const pos = new_table.data.findIndex((responsible: any) => responsible?.this.user?.id === userId)
            if (pos !== -1) new_table.data[pos].this.user.status = new_status
            return { ...new_table }
        })

        try {
            await api.put(`/users/${userId}/status`, { status: new_status })
            notify("Status do responsável atualizado com sucesso.", "success")
        } catch (err) {
            notify("Erro ao alterar status do responsável.", "error")
            setTable((atual) => {
                const new_table = { ...atual }
                const pos = new_table.data.findIndex((responsible: any) => responsible?.this.user?.id === userId)
                if (pos !== -1) new_table.data[pos].this.user.status = old_status
                return { ...new_table }
            })
        }

    }, [])

    const onSaveResponsible = useCallback(() => {
        setOpenModal(false)
        searchResponsibles(1)
    }, [filters, searchString])

    const selectResponsible = useCallback((responsible: responsibleProps) => {
        setResponsibleSelected({ ...responsible })
        setOpenModal(true)
    }, [])

    const openModalNewResponsible = useCallback(() => {
        setResponsibleSelected(null)
        setOpenModal(true)
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewResponsible()
        else console.log({ action_id })
    }

    useEffect(() => { searchResponsibles(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingResponsibles}
            setLoading={setLoadingResponsibles}
            numberOfItems={numOfResponsibles}
            textHeader="Responsáveis"
            textItems="responsáveis encontrados"
            theme={theme}
            search={searchResponsibles}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_status
            have_edit
            have_delete
            status_action={(userId: string, status: "block" | "active" | null) => changeStatusUser(userId, status)}
            edit_action={(responsible: any) => selectResponsible(responsible)}
            delete_action={(responsible: any) => console.log({ responsible })}
            text_loading={"Buscando Responsáveis"}
            text_empty={"Nenhum responsáveis encontrado"}
            onClickOption={handleAction}
            actions={actionsInitial}
            pages={numberOfPages}
            page={atualPage}
            onChangeSearch={setSearchString}
        >
            {openModal &&
                <ModalPerson
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSaveResponsible()}
                    openModal={openModal}
                    backgroundVisible={false}
                    defaultId={responsibleSelected?.id}
                    personType="responsible"
                />
            }
        </PageList>
    )
}
