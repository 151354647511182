import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Container, LabelNoData } from "./style";

interface GraficoProps {
  infos: any;
  labels: any;
  colors: any;
  isMoney?: boolean;
  mask?: Function;
}

export const ChartDoughnut: React.FC<GraficoProps> = (props) => {
  const { infos, colors, labels, isMoney = false, mask = (v: any) => v } = props;

  const options = {
    scales: {
      x: {
        display: false,
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return ""
          },
          color: "#000"
        }
      },
      y: {
        display: false,
        ticks: {
          callback: function (value: any, index: any, values: any) {
            return ""
          },
          color: "#000",
          font: {
            family: "Poppins",
            weight: 600
          }
        },
        beginAtZero: true
      }
    },
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    hoverBorderJoinStyle: "bevel",
    plugins: {
      legend: {
        display: false
      },
      labelFontColor: "#FFF",
      tooltip: {
        bodyFont: {
          family: "Poppins",
          weight: 600
        },
        titleFont: {
          family: "Poppins",
          weight: 600
        },
        yAlign: "bottom",
        xAlign: "center",
        callbacks: {
          label: function (context: any) {
            var label = labels[context.dataIndex];
            if (label) label += ": ";
            label += mask(context.parsed);
            return `  ${label}`;
          }
        }
      }
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Receita",
        backgroundColor: colors,
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointRadius: 1,
        pointHitRadius: 10,
        data: infos
      }]
  }

  return (
    <Container haveData={!!infos}>
      {
        infos.filter((info: any) => info > 0).length > 0
          ?
          <Doughnut type={""} options={options} data={data} />
          :
          <LabelNoData>Sem dados</LabelNoData>
      }
    </Container>
  )
}