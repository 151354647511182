import Button from "../../Buttons/Button";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Tabs } from "../../Tabs";
import { useApi } from "../../../hooks/api";
import { ChoosePlan } from "./steps/ChoosePlan";
import { ModalHeader } from "../../ModalHeader";
import { ModalGeneric } from "../../ModalGeneric";
import { useGlobal } from "../../../hooks/global";
import { inactiveItens, secondary } from "../../../theme";
import { personsTypesOptions } from "../../../utils/options";
import { Container, ContainerBottom, Content } from "./style";
import { maskFunctions } from "../../../services/maskServices";
import { plansAttributes, tabProps } from "../../../interfaces";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { copyOf, correctDate2 } from "../../../services/generalServices";
import { CreditDataForm, CreditDataRefProps } from "./steps/CreditDataForm";

interface IModalResponsibleProps {
    onCancel: Function
    onSave: Function
    default_plan_id: any
    openModal: boolean
    backgroundVisible?: boolean
}

const initialsTabs: tabProps[] = [
    { label: "1. Escolha seu Plano", canClick: false, open: false },
    { label: "2. Dados do Pagamento", canClick: false, open: false }
]

export const ModalSubscription: React.FC<IModalResponsibleProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, default_plan_id = null } = props

    const { user, api } = useApi()
    const { theme, notify, franchise } = useGlobal()

    const defaultBillingData = {
        ...user.person?.registration_data,
        birth: correctDate2(user.person?.registration_data?.birth),
        email: user.email,
        cnpj: maskFunctions.cnpj.mask(franchise.registration_data?.cnpj),
        corporate_name: franchise.registration_data?.corporate_name,
        phone_number: maskFunctions.phone.mask(user.person?.registration_data?.phone),
        cpf: maskFunctions.cpf.mask(user.person?.registration_data?.cpf),
        ...user.person?.address,
        city_id: user.person?.address?.city?.id ? {
            value: user.person?.address?.city.id,
            label: user.person?.address?.city.name,
            this: user.person?.address?.city
        } : null,
        state_id: user.person?.address?.state?.id ? {
            value: user.person?.address?.state.id,
            label: user.person?.address?.state.name,
            this: user.person?.address?.state
        } : null,
        cep: maskFunctions.cep.mask(user.person?.address?.cep),
        type: personsTypesOptions.find((item) => item.value === "PF")
    } as any

    const [loading, setLoading] = useState(true)
    const [step, setStep] = useState(default_plan_id ? 2 : 1)
    const [plans, setPlans] = useState<plansAttributes[]>([])
    const [tabs, setTabs] = useState<tabProps[]>(initialsTabs)
    const [planSelected, setPlanSelected] = useState<string | null>(default_plan_id)

    const buttonSubmitRef = useRef<HTMLButtonElement>(null)
    const paymentFormRef = useRef<CreditDataRefProps>(null)

    const searchPlans = useCallback(async () => {

        setLoading(true)

        try {

            const { data } = await api.get("/plans")

            setPlans(data.rows)

        } catch (err) {

            notify("Erro ao buscar planos!", "error")

        }

        setLoading(false)

    }, [])

    const handleGoToNextStep = useCallback(async (nextStep) => {
        if (nextStep === 3) paymentFormRef.current?.forceSubmit()
        else if (nextStep === 2) {
            if (!planSelected) notify("Selecione um plano!", "error")
            else setStep(nextStep)
        }
    }, [planSelected, paymentFormRef])

    useEffect(() => {
        setTabs((tabs) => {
            const newTabs: tabProps[] = copyOf(tabs)
            newTabs.forEach((tab, index) => {
                newTabs[index].open = index === (step - 1)
                newTabs[index].canClick = index <= (step - 1)
            })
            newTabs[0].canClick = true
            newTabs[1].canClick = !!planSelected
            return newTabs
        })
    }, [step, planSelected])

    useEffect(() => { searchPlans() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>

                <ModalHeader
                    title={"Plano de Assinatura da sua Imobiliária"}
                    subTitle={"Selecione o melhor plano de assinatura para a sua Imobiliária"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <ContentOrLoading loading={loading} type="wave" theme={theme}>
                    <Content>
                        <div>
                            <Tabs
                                tabs={tabs}
                                onChange={(index: any) => setStep(index + 1)}
                                pos={tabs.findIndex((tab) => tab.open)}
                            />
                        </div>

                        <div style={{ display: step === 1 ? "flex" : "none", width: "100%" }}>
                            <ChoosePlan
                                plans={plans}
                                planSelected={planSelected}
                                setPlanSelected={setPlanSelected}
                                theme={theme}
                            />
                        </div>

                        <div style={{ display: step === 2 ? "flex" : "none" }}>
                            <CreditDataForm
                                onSucess={() => onSave()}
                                amount={plans.find((plan) => plan.id === planSelected)?.value ?? 0}
                                onChangePlan={() => setStep(1)}
                                default_plan={plans.find((plan) => plan.id === planSelected)}
                                default_data={defaultBillingData}
                                ref={paymentFormRef}
                            />
                        </div>

                        <button
                            ref={buttonSubmitRef}
                            type="submit"
                            style={{ display: "none" }}
                        />
                    </Content>
                </ContentOrLoading>

                <ContainerBottom theme={theme}>
                    <div style={{ height: "100%", flex: window.innerWidth <= 587 ? 1 : undefined }}>
                        <Button
                            onClick={() => step === 1 ? onCancel() : setStep((atual) => atual - 1)}
                            background={inactiveItens[theme]}
                            color={"#FFF"}
                            style={{ padding: 8, minWidth: 210, fontSize: 14, alignItems: "center" }}
                            children="Voltar"
                        />
                    </div>
                    <div style={{ height: "100%", flex: window.innerWidth <= 587 ? 1 : undefined }}>
                        <Button
                            onClick={() => handleGoToNextStep(step + 1)}
                            background={secondary[theme]}
                            color={"#FFF"}
                            disabled={planSelected === null}
                            style={{ padding: 8, minWidth: 210, fontSize: 14 }}
                            children={step === 1 ? "Próximo" : "Confirmar pagamento"}
                        />
                    </div>
                </ContainerBottom>

            </Container>

        </ModalGeneric>
    )
}