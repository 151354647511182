import React, { useCallback, useEffect, useRef, useState } from "react";
import { TopCard } from "./TopCard";
import { TiCancel } from "react-icons/ti";
import { useApi } from "../../../hooks/api";
import { Column, Line } from "../../../style";
import { LastProposals } from "./LastProposals";
import { MdOutlineHistory } from "react-icons/md";
import { useGlobal } from "../../../hooks/global";
import { RankingPersons } from "./RankingPersons";
import { primary, secondary } from "../../../theme";
import { CircularProgress } from "@material-ui/core";
import { DashboardLoading } from "./DashboardLoading";
import { RankingFranchises } from "./RankingFranchises";
import { maskFunctions } from "../../../services/maskServices";
import { DashboardCard } from "./DashboardCards/DashboardCard";
import { proposal_status } from "../../../utils/proposal_status";
import { DashboardCharLine } from "./DashboardCards/DashboardCharLine";
import { AiOutlineDollarCircle, AiOutlineReload } from "react-icons/ai";
import { DropdownButton } from "../../../components/Buttons/DropdownButton";
import { DashboardChartDoughnut } from "./DashboardCards/DashboardChartDoughnut";
import {
  RangePicker,
  RangePickerRefProps,
} from "../../../components/RangePicker";
import { ModalChangeAnalyst } from "../../../components/Modals/ModalChangeAnalyst";
import {
  franchiseProps,
  IStatistics,
  proposalsAttributes,
} from "../../../interfaces";
import {
  ContainerLastUpdate,
  ContainerRankingPersons,
  ContainerSelectFranchise,
  Title,
} from "./style";
import { SelectNoCreateSingleMulti } from "../../../components/Inputs/BaseSelects/SelectNoCreateSingleMulti";
import {
  BiBuildingHouse,
  BiCheckCircle,
  BiDislike,
  BiError,
  BiHomeAlt,
  BiMedal,
  BiXCircle,
} from "react-icons/bi";
import {
  getAnalyst,
  getBroker,
  getFranchise,
  getIndicator,
  getOwners,
  getStatus,
  getTenants,
} from "../../../utils/columnsTables";
import {
  correctDate,
  correctDateDashboard,
  correct_date_filter,
  setUpConsultation,
  valueIsEmpty,
} from "../../../services/generalServices";

const CardWithIcon = ({ icon, subTitle, value, background }: any) => {
  return (
    <Line style={{ gap: 10 }}>
      <div>
        <div
          style={{
            background,
            padding: 10,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </div>
      </div>
      <Column>
        <div style={{ fontSize: 20, fontWeight: 600 }}>
          {maskFunctions.currency.mask(value)}
        </div>
        <div style={{ fontSize: 14, marginTop: -5 }}>{subTitle}</div>
      </Column>
    </Line>
  );
};

const getAtualDate = ({ daysBefore = 0 }: any) => {
  const date = new Date();
  date.setDate(date.getDate() - daysBefore);
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

const returnValue = (value: number) => {
  if (value >= 1000000000) return returnWith2Decimals(value, 1000000000, "bi");
  else if (value >= 1000000) return returnWith2Decimals(value, 1000000, "mi");
  else if (value >= 1000) return returnWith2Decimals(value, 1000, "mil");
  else return maskFunctions.currency.mask(value).replace("R$ ", "");
};

const returnWith2Decimals = (value: number, div: number, text: string) => {
  value = value / div;
  const valueWith2Decimals = value.toString();
  const intPart = valueWith2Decimals.split(".")[0];
  const decimalPart = valueWith2Decimals.split(".")[1];
  if (valueIsEmpty(decimalPart)) return "R$ " + intPart + text;

  return "R$ " + intPart + "," + decimalPart.slice(0, 2) + text;
};

const initalRange = {
  from: getAtualDate({ daysBefore: 30 }),
  to: getAtualDate({ daysBefore: 0 }),
};

const DoughnutCharts = [
  [
    {
      title: "Contratos Administrados",
      attribute: "proposals_contracts",
    },
    {
      title: "Garantias",
      attribute: "proposals_warranties",
    },
  ],
  [
    {
      title: "Documentos por status",
      attribute: "eletronics_per_status",
    },
    {
      title: "Documentos gerados por vínculo",
      attribute: "eletronic_per_bond",
    },
    {
      title: "Consultas (PF x PJ)",
      attribute: "searchs_pf_X_pj",
    },
  ],
  [
    {
      title: "Créditos na plataforma",
      attribute: "credits",
      mask: maskFunctions.currency.mask,
      canAccess: (user: any) => ["root"].includes(user.type),
    },
    {
      title: "Assinaturas por status",
      attribute: "subscriptions_status",
      mask: (v: any) => v,
      canAccess: (user: any) => ["root"].includes(user.type),
    },
  ],
];

const LineCharts = [
  {
    title: "Documentos ao longo do período",
    attribute: "contracts",
  },
  {
    title: "Propostas e Consultas ao longo do período",
    attribute: "proposals",
  },
  {
    title: "Créditos ao longo do período",
    attribute: "transactions",
    mask: maskFunctions.currency.mask,
  },
  {
    title: "Pagamentos de assinaturas ao longo do período",
    attribute: "subscriptions",
    mask: maskFunctions.currency.mask,
  },
];

const contracts_infos = [
  {
    subTitle: "Maior locação",
    attribute: "value_max",
    color: "#52C41A",
    icon: <BiMedal color="#FFF" size={26} />,
  },
  {
    subTitle: "Menor locação",
    attribute: "value_min",
    color: "#FF6B6B",
    icon: <BiDislike color="#FFF" size={26} />,
  },
  {
    subTitle: "Valor médio de locação",
    attribute: "value_avg",
    color: "#FAAD14",
    icon: <BiHomeAlt color="#FFF" size={26} />,
  },
];

const history_options = [
  { text: "Hoje", id: "0" },
  { text: "Últimos 7 dias", id: "6" },
  { text: "Últimos 15 dias", id: "14" },
  { text: "Últimos 30 dias", id: "29" },
  { text: "Período completo", id: "all" },
];

const HISTORY_CLASS = "dropdown-button-history";

export const DashboardRoot: React.FC = () => {
  const { user, api, consultsServices } = useApi();
  const {
    theme,
    menuOpen,
    paginationLimit,
    loadingResize,
    getCorrectMaxWidth,
    isMobile,
    setOpenSelect,
  } = useGlobal();

  const [loading, setLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [proposals, setProposals] = useState<proposalsAttributes[]>([]);
  const [statistics, setStatistics] = useState<IStatistics | null>(null);
  const [franchiseIdSelected, setFranchiseIdSelected] =
    useState<franchiseProps | null>(null);
  const [proposalSelectToChangeAnalyst, setProposalSelectToChangeAnalyst] =
    useState<string | null>(null);
  const [range, setRange] = useState<{ from: any; to: any }>(
    user.type === "root" ? initalRange : { from: null, to: null }
  );

  const range_picker_ref = useRef<RangePickerRefProps>(null);

  const iconHistory = (
    <MdOutlineHistory
      size={20}
      className={HISTORY_CLASS}
      onClick={() => setOpenSelect(HISTORY_CLASS)}
    />
  );

  const search = useCallback(async () => {
    setLoading(true);
    try {
      const query: any = {};
      const date_init = range.from ? range.from : range.to ? range.to : null;
      const date_end = range.to ? range.to : range.from ? range.from : null;
      query.date_init = date_init ? correct_date_filter(date_init) : null;
      query.date_end = date_end ? correct_date_filter(date_end) : null;

      if (user.type === "root") {
        query.franchise_id = franchiseIdSelected
          ? franchiseIdSelected
          : undefined;
        const string: any = setUpConsultation(query);
        const { data } = await api.get(`/statistics?${string}`);
        setStatistics(data);
        setProposals([]);
      } else {
        const string: any = setUpConsultation(query);
        const [response_statistics, response_proposals] = await Promise.all([
          api.get(`/statistics?${string}`),
          api.get(
            `/proposals/search?page=1&limit=${paginationLimit}&${string}`
          ),
        ]);
        setStatistics(response_statistics.data);
        const _proposals = response_proposals.data.rows;
        for (let i = 0; i < _proposals.length; i++) {
          const _proposal = _proposals[i];
          const status = _proposal.status;

          _proposals[i] = {
            id: _proposal.id,
            indicator: getIndicator({ proposal: _proposal, theme }),
            friendly_id: _proposal.friendly_id,
            tenants: getTenants({ proposal: _proposal, theme }),
            owners: getOwners({ proposal: _proposal, theme }),
            broker: getBroker({ person: _proposal, theme }),
            analyst: getAnalyst({ proposal: _proposal, theme }),
            status: getStatus({ ...proposal_status[status], theme }),
            franchise: getFranchise({ person: _proposal, theme }),
            rent_value: _proposal.contractorData.rent_value
              ? maskFunctions.currency.mask(_proposal.contractorData.rent_value)
              : "R$ 0,00",
            createdAt: correctDate(_proposal.createdAt),
            updatedAt: correctDate(_proposal.updatedAt),
          };
        }
        setProposals(_proposals);
      }

      setLastUpdate(new Date());
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [range, paginationLimit, franchiseIdSelected]);

  const handleClickInHistory = useCallback(
    async (from) => {
      range_picker_ref.current?.applyRange({
        from,
        to: getAtualDate({ daysBefore: 0 }),
      });
    },
    [range_picker_ref]
  );

  const handleOpenSelectHistory = useCallback(
    (value: string) => {
      if (value !== "all")
        handleClickInHistory(getAtualDate({ daysBefore: parseInt(value) }));
      else if (user.type === "root")
        handleClickInHistory({ day: 9, month: 9, year: 2022 });
      else range_picker_ref.current?.applyRange({ from: null, to: null });
    },
    [range_picker_ref]
  );

  useEffect(() => {
    search();
  }, [range, paginationLimit, franchiseIdSelected]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        gap: 30,
      }}
    >
      <Title
        theme={theme}
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 20,
          justifyContent: "space-between",
        }}
      >
        <Column>
          <h4
            style={{
              padding: 0,
              margin: 0,
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            Dashboard
          </h4>
          <ContainerLastUpdate menuIsOpen={menuOpen}>
            {!loading ? (
              <>
                <div style={{ fontSize: 14, whiteSpace: "nowrap" }}>
                  Última atualização realizada em{" "}
                  {correctDateDashboard(lastUpdate)}
                </div>
                <div
                  onClick={search}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AiOutlineReload />
                </div>
              </>
            ) : (
              <>
                <div style={{ fontSize: 14 }}>Atualizando dashboard</div>
                <CircularProgress
                  size={10}
                  variant="indeterminate"
                  style={{ color: secondary[theme] }}
                />
              </>
            )}
          </ContainerLastUpdate>
        </Column>
        <Line style={{ gap: 10, flexWrap: "wrap" }}>
          {user.type === "root" && (
            <ContainerSelectFranchise>
              <SelectNoCreateSingleMulti
                isRequired
                onChange={(value: any) =>
                  setFranchiseIdSelected(value?.value ?? null)
                }
                noOptionsMessage="Nenhuma imobiliária encontrada"
                searchOptions={(search: string) =>
                  consultsServices.franchise_id({ search })
                }
                icon={<BiBuildingHouse size={20} />}
                isDisabled={loading}
              />
            </ContainerSelectFranchise>
          )}
          <div style={{ minHeight: "100%" }}>
            <RangePicker
              onChange={(newRange: any) => setRange({ ...newRange })}
              initialRange={range}
              disabled={loading}
              emptyMessage="Selecione um período"
              noRemove={user.type === "root"}
              ref={range_picker_ref}
            />
          </div>
          <DropdownButton
            className={HISTORY_CLASS}
            options={history_options}
            icon={iconHistory}
            loading={loading}
            main_option={{ text: "", id: HISTORY_CLASS }}
            onClick={handleOpenSelectHistory}
            theme={theme}
            zIndex={900}
            style={{ height: 38 }}
          />
        </Line>
      </Title>
      {!loading && !loadingResize && statistics && (
        <>
          <Line style={{ color: secondary[theme], gap: 30, flexWrap: "wrap" }}>
            <TopCard
              icon={<BiCheckCircle size={40} />}
              title="Aprovadas"
              value={statistics?.proposals_data.approved}
              theme={theme}
              containerStyle={{ color: secondary[theme] }}
              tooltip={
                statistics?.proposals_data.approved +
                " propostas aprovadas ou com contratos finalizados"
              }
            />
            <TopCard
              icon={<BiXCircle size={40} />}
              title="Reprovadas"
              value={statistics?.proposals_data.rejected}
              theme={theme}
              containerStyle={{ color: secondary[theme] }}
              tooltip={
                statistics?.proposals_data.rejected + " propostas reprovadas"
              }
            />
            <TopCard
              icon={<BiError size={40} />}
              title="Pendentes"
              value={statistics?.proposals_data.pending}
              theme={theme}
              containerStyle={{ color: secondary[theme] }}
              tooltip={
                statistics?.proposals_data.pending +
                " propostas aguardando preenchimento, aguardando análise, em análise ou em resolução de pendências"
              }
            />
            <TopCard
              icon={<TiCancel size={40} />}
              title="Canceladas"
              value={statistics?.proposals_data.canceled}
              theme={theme}
              containerStyle={{ color: secondary[theme] }}
              tooltip={
                statistics?.proposals_data.canceled + " propostas canceladas"
              }
            />
            <TopCard
              icon={
                <AiOutlineDollarCircle size={40} style={{ opacity: 0.7 }} />
              }
              title="Volume"
              value={returnValue(statistics.proposals_data.total_value)}
              theme={theme}
              containerStyle={{ background: secondary[theme], color: "#FFF" }}
              titleStyle={{ opacity: 0.7, color: "#FFF" }}
              tooltip={
                "Volume total - " +
                maskFunctions.currency.mask(
                  statistics.proposals_data.total_value
                )
              }
            />
          </Line>

          <Line style={{ gap: 30, flexWrap: "wrap" }}>
            {DoughnutCharts[0].map(({ title, attribute }) => (
              <DashboardChartDoughnut
                key={title}
                theme={theme}
                title={title}
                infos={statistics[attribute].values}
                labels={statistics[attribute].names}
                colors={statistics[attribute].colors}
                minWidth={250}
              />
            ))}
            <DashboardCard
              minWidth={250}
              title="Resumo dos Contratos"
              theme={theme}
            >
              <Column
                style={{
                  background: primary[theme],
                  padding: 20,
                  gap: 20,
                  flex: 1,
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                  justifyContent: "space-between",
                }}
              >
                {contracts_infos.map(({ subTitle, attribute, icon, color }) => (
                  <CardWithIcon
                    key={subTitle}
                    icon={icon}
                    subTitle={subTitle}
                    value={statistics.contracts_data[attribute]}
                    background={color}
                  />
                ))}
              </Column>
            </DashboardCard>
          </Line>

          {["root"].includes(user.type) && (
            <>
              <Line style={{ gap: 30, flexWrap: "wrap" }}>
                {DoughnutCharts[1].map(({ title, attribute }) => (
                  <DashboardChartDoughnut
                    key={title}
                    theme={theme}
                    title={title}
                    infos={statistics[attribute].values}
                    labels={statistics[attribute].names}
                    colors={statistics[attribute].colors}
                    minWidth={250}
                  />
                ))}
              </Line>
              <Line style={{ gap: 30, flexWrap: "wrap" }}>
                {DoughnutCharts[2].map(
                  ({ title, attribute, mask, canAccess = () => true }: any) => {
                    if (canAccess(user))
                      return (
                        <DashboardChartDoughnut
                          key={title}
                          theme={theme}
                          title={title}
                          infos={statistics[attribute].values}
                          labels={statistics[attribute].names}
                          colors={statistics[attribute].colors}
                          minWidth={250}
                          mask={mask}
                        />
                      );
                    else return null;
                  }
                )}
              </Line>
            </>
          )}

          {["root"].includes(user.type) && (
            <>
              <ContainerRankingPersons>
                <RankingFranchises
                  theme={theme}
                  minWidth={250}
                  maxWidth={getCorrectMaxWidth()}
                  range={range}
                />
              </ContainerRankingPersons>
              {LineCharts.map(({ title, attribute, mask }) => (
                <Line key={title}>
                  <DashboardCharLine
                    key={title}
                    theme={theme}
                    title={title}
                    infos={statistics[attribute].per_days}
                    labels={statistics.days}
                    minWidth={250}
                    total={statistics[attribute].per_days[0].values.reduce(
                      (a: number, b: number) => a + b,
                      0
                    )}
                    mask={mask}
                  />
                </Line>
              ))}
            </>
          )}

          {["responsible", "manager"].includes(user.type) && (
            <ContainerRankingPersons>
              <RankingPersons
                title="Ranking Corretores"
                data={statistics.broker_ranking}
                theme={theme}
                minWidth={250}
                maxWidth={getCorrectMaxWidth()}
              />
              <RankingPersons
                title="Ranking Proprietários"
                data={statistics.owner_ranking}
                theme={theme}
                minWidth={250}
                maxWidth={getCorrectMaxWidth()}
              />
            </ContainerRankingPersons>
          )}

          {["responsible", "manager", "broker", "analyst"].includes(
            user.type
          ) && (
            <LastProposals
              data={proposals}
              theme={theme}
              onCancelProposal={search}
              maxWidth={getCorrectMaxWidth()}
              onClickToChangeOwner={(proposal: proposalsAttributes) =>
                setProposalSelectToChangeAnalyst(proposal.id)
              }
            />
          )}
        </>
      )}
      {!(!loading && !loadingResize && statistics) && (
        <DashboardLoading
          theme={theme}
          user_type={user.type}
          isMobile={isMobile}
        />
      )}
      {proposalSelectToChangeAnalyst && (
        <ModalChangeAnalyst
          defaultId={proposalSelectToChangeAnalyst}
          openModal
          onSave={() => {
            setProposalSelectToChangeAnalyst(null);
            search();
          }}
          onCancel={() => setProposalSelectToChangeAnalyst(null)}
        />
      )}
    </div>
  );
};
