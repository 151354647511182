import styled, { css } from "styled-components";
import { IThemeProps, themeTypes } from "../../../../../../interfaces";
import { background, borderColors, inactiveItens, primary } from "../../../../../../theme";

export const Container = styled.form<IThemeProps>`
    display: flex;
    padding: 20px;
    min-width:400px;
    max-width:400px;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border-radius: 5px;
    outline: 0;
    gap: 20px;
    min-height: 200px;
    ${props => css`
        color: ${inactiveItens[props.theme as themeTypes]};
        background: ${background[props.theme as themeTypes]};
    `}
`

export const ContainerText = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:10px;
`

export const Description = styled.div`
    text-align:center;
    max-width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const ContainerButton = styled.div`
    display:flex;
    flex-direction:row;
    gap: 10px;
`

export const BaseInput = styled.div<{ theme: themeTypes }>`
    width:100%;
    margin-bottom: 30px;
    textarea{
        color: ${props => inactiveItens[props.theme as themeTypes]} !important;
        background: ${props => primary[props.theme as themeTypes]} !important;
        border: 1px solid ${props => borderColors[props.theme as themeTypes]} !important;
        :disabled{
            background: #F2F2F2 !important;
            border: 1px solid #E6E6E6;
            color:#999999;
        }
        :focus{
            box-shadow:none !important;
            outline:2px solid #2684FF !important;
        }
    }
`