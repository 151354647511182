import React, { useCallback, useEffect, useState } from "react";
import { Container } from "./style";
import { FormFranchise } from "./form";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { franchiseProps } from "../../../interfaces";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { franchiseDefaultForm, mapDefaultForm } from "../../../utils/defaultFormsUtils";

interface IModalFranchiseProps {
    onCancel: Function
    onSave: Function
    defaultId?: string
    openModal: boolean
    backgroundVisible?: boolean
}

export const ModalFranchise: React.FC<IModalFranchiseProps> = (props) => {
    const { onCancel, onSave, openModal, backgroundVisible, defaultId } = props

    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loading, setLoading] = useState(!!defaultId)
    const [defaultData, setDefaultData] = useState<any>(null)
    const [defaultFranchise, setDefaultFranchise] = useState<franchiseProps | undefined>(undefined)

    const searchFranchise = useCallback(async () => {
        if (defaultId) {

            setLoading(true)

            try {

                const { data } = await api.get(`/franchises/${defaultId}`)

                const _data: any = mapDefaultForm({ data, fields: franchiseDefaultForm })

                setDefaultData({ ..._data })

                setDefaultFranchise({ ...data })

            } catch (err) {

                notify("Erro ao buscar Imobiliária", "error")

                onCancel()

            }
        }

        setLoading(false)
    }, [defaultId])

    useEffect(() => { searchFranchise() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>
                <ModalHeader
                    title={defaultId ? "Informações do Imobiliária" : "Cadastro de Imobiliária"}
                    subTitle={defaultId ?
                        "Segue abaixo as informações do imobiliária"
                        :
                        "Informe os dados necessários para criar um novo Imobiliária"
                    }
                    onCancel={() => onCancel()}
                    theme={theme}
                />
                <ContentOrLoading type="wave" loading={loading} theme={theme}>
                    <FormFranchise
                        defaultData={defaultData}
                        defaultFranchise={defaultFranchise}
                        onSave={onSave}
                        onCancel={onCancel}
                    />
                </ContentOrLoading>
            </Container>
        </ModalGeneric>
    )
}