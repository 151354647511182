import React from "react"
import { Skeleton } from "@mui/material";
import { Line } from "../../../../style";
import { secondary } from "../../../../theme";
import { ContainerRankingPersons } from "./style";
import { themeTypes } from "../../../../interfaces";

interface IDashboardLoading {
    user_type: string
    theme: themeTypes
    isMobile: boolean
}

export const DashboardLoading: React.FC<IDashboardLoading> = ({ theme, user_type, isMobile }) => {
    return (
        <>
            <Line style={{ color: secondary[theme], gap: 30, flexWrap: "wrap" }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 105 }}
                />

                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 105 }}
                />

                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 105 }}
                />

                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 105 }}
                />

                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 220, borderRadius: 5, flex: 1, height: 105 }}
                />
            </Line>

            <Line style={{ gap: 30, flexWrap: "wrap" }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 282 }}
                />

                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 282 }}
                />

                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 282 }}
                />
            </Line>

            {["root"].includes(user_type) &&
                <Line style={{ gap: 30, flexWrap: "wrap" }}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 282 }}
                    />

                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 282 }}
                    />

                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 282 }}
                    />

                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ minWidth: 250, borderRadius: 5, flex: 1, height: 282 }}
                    />
                </Line>
            }


            {["responsible", "manager"].includes(user_type) &&
                <ContainerRankingPersons>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ minWidth: 250, borderRadius: 5, flex: 1, minHeight: 525 }}
                    />

                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ minWidth: 250, borderRadius: 5, flex: 1, minHeight: 525 }}
                    />
                </ContainerRankingPersons>
            }

            <Line style={{ gap: 30, flexWrap: "wrap", flex: 1 }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ minWidth: 250, borderRadius: 5, flex: 1, minHeight: 250, height: "100%" }}
                />
            </Line>

            {["root"].includes(user_type) && [0, 1, 2].map((item, index) => (
                <Line key={"loading - " + index} style={{ gap: 30, flexWrap: "wrap", flex: 1 }}>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ minWidth: 250, borderRadius: 5, flex: 1, minHeight: 250, height: "100%" }}
                    />
                </Line>
            ))}
        </>
    )
}