import Button from "../../../Buttons/Button";
import React, { useState, useCallback, useRef, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { BiCopyAlt } from "react-icons/bi";
import { Tooltip } from "../../../Tooltip";
import { primary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { Column, Line } from "../../../../style";
import { ModalHeader } from "../../../ModalHeader";
import { FiArrowRightCircle } from "react-icons/fi";
import { ModalGeneric } from "../../../ModalGeneric";
import { useGlobal } from "../../../../hooks/global";
import { MagicButton } from "../../../Buttons/MagicButton";
import { borderColors, secondary } from "../../../../theme";
import { ModalLoading } from "../../../Loadings/ModalLoading";
import { ModalClickSignConfirm } from "./ModalClickSignConfirm";
import { GenericForm, IGenericFormRefProps } from "../../../GenericForm";
import { IIntegrationTokensProps, IGroupProps } from "../../../../interfaces";
import { ClickSign32 } from "../../../SvgComponents/Icons/ClickSign/clickSign32";
import { Anchor, Container, ContainerCrmLogo, Content, Form, InputVariable, TextInformation } from "./style";

interface IModalClickSignProps {
    onCancel: Function
    onSave: Function
    integrationTokens: IIntegrationTokensProps
}

const clickIcon: any = {
    dark: <ClickSign32 color={"#FFF"} />,
    light: <ClickSign32 color={"#2B6AF3"} />
}

const TUTORIAL_URL = "https://www.youtube.com/watch?v=SCiySCDvrkA"

export const ModalClickSign: React.FC<IModalClickSignProps> = (props) => {
    const { onCancel, onSave, integrationTokens } = props

    const { api } = useApi()
    const { theme, notify, notifyBottom } = useGlobal()

    const [loading, setLoading] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [webhookConfigured, setWebhookConfigured] = useState(false)
    const [step, setStep] = useState(integrationTokens.access_token ? 1 : 0)

    const form_ref = useRef<IGenericFormRefProps>(null)

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger } = useForm({
        defaultValues: integrationTokens as any,
        mode: "all"
    });

    const _form = watch()

    const onSubmit = useCallback(async () => setOpenConfirm(true), [api, form_ref])

    const handleConfirm = useCallback(async () => {

        setLoading(true)

        try {
            const data = form_ref.current?.getForm()

            await api.put("/integrations/click_sign/edit-tokens", data)

            notify("Tokens da ClickSign atualizados com sucesso", "success")

            onSave()

        } catch (err: any) {

            const error = err?.response ? err?.response?.data : "SERVER ERROR"
            if (error === "SERVER ERROR" || error.message === "Internal server error!") notify("Erro Interno do Sistema!", "error")
            else setError("access_token", { type: "manual", message: error.message })

        }

        setLoading(false)

    }, [api, form_ref])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, api])

    const copyWebhookUrl = () => {
        try {
            const variable_name: any = document.getElementById("webhook-url")
            if (variable_name) {
                variable_name?.select();
                const success = document.execCommand("copy");
                variable_name.selectionEnd = 0
                if (success) return notifyBottom("URL copiada para área de tranferência!", "success");
                else throw new Error();
            }
        } catch (err) {
            notifyBottom("Erro ao copiar a URL para área de tranferência!", "error");
        }
    }

    const groups: IGroupProps[] = [
        {
            name: "",
            label: "",
            fields: [
                [
                    {
                        name: "access_token",
                        label: "Access Token",
                        type: "input",
                        required: true,
                        noUpperCase: true
                    }
                ]
            ]
        }
    ]

    return (
        <ModalGeneric open onClose={() => { }}>
            <Container theme={theme}>

                <ModalHeader
                    title={"Integração com a ClickSign"}
                    subTitle={"Informe os dados necessários para a integração"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <Content>
                    {step === 0 && (
                        <>
                            <ContainerCrmLogo>
                                {clickIcon[theme]}
                                ClickSign
                            </ContainerCrmLogo>
                            <Column style={{ gap: 10 }}>
                                <div>
                                    Bem-vindo ao nosso ambiente de integração.
                                </div>
                                <div>
                                    Integrando suas contas da MAX Locação e ClickSign,
                                    será possível criar documentos eletrônicos através
                                    da sua conta na ClickSign, com isso você poderá confeccionar
                                    seus documentos eletrônicos sem gastar créditos MX's.
                                </div>
                                <div>
                                    Está pronto para iniciar? 😊
                                </div>
                            </Column>
                            <Button
                                style={{ textTransform: "uppercase", fontWeight: 600 }}
                                onClick={() => setStep((atual) => atual + 1)}
                                background={secondary[theme]}
                                color="#FF"
                                children="Iniciar"
                            />
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <ContainerCrmLogo>
                                {clickIcon[theme]}
                                ClickSign
                            </ContainerCrmLogo>

                            <Column style={{ gap: 10 }}>

                                <Column>
                                    <div style={{ fontWeight: 600, fontSize: 18 }}>Dados de Acesso</div>
                                    <span>
                                        Para integração é necessário que você gere um Access token e configure o weebhook disponibilizado
                                        pela ClickSign, <Anchor theme={theme} target="_blank" href={TUTORIAL_URL}>clique aqui</Anchor> para
                                        assistir o passo a passo de como gerar o Access token e configurar o weebhook.
                                    </span>
                                </Column>
                            </Column>

                            <Line onClick={copyWebhookUrl} style={{ border: `1px solid ${borderColors[theme]}`, background: primary[theme], borderRadius: 4, padding: 10, alignItems: "center" }}>
                                <InputVariable
                                    id={"webhook-url"}
                                    readOnly
                                    theme={theme}
                                    value={"https://api.maxlocacao.com/clickSign/webhook"}
                                />
                                <Tooltip
                                    title={"Copiar"}
                                    style={{ margin: 0, padding: 0 }}
                                    children={<BiCopyAlt color={secondary[theme]} size={20} />}
                                />
                            </Line>

                            <Form onSubmit={handleStopPropagation}>
                                <TextInformation>
                                    <b>Importante!</b> A configuração do webhook dentro da ClickSign é essencial para que haja
                                    uma comunicação eficiente entre as plataformas.
                                </TextInformation>
                                <div >
                                    <MagicButton
                                        checked={webhookConfigured}
                                        label="Configurei o webhook na ClickSign"
                                        onClick={() => setWebhookConfigured((atual) => !atual)}
                                        theme={theme}
                                        type="checkbox"
                                        labelStyle={{ textTransform: "none", fontSize: 13 }}
                                    />
                                </div>
                                <GenericForm
                                    ref={form_ref}
                                    groups={groups}
                                    _form={_form}
                                    control={control}
                                    trigger={trigger}
                                    errors={errors}
                                    setValue={setValue}
                                    register={register}
                                />
                                <Line style={{ justifyContent: "center", marginTop: 20 }}>
                                    <div>
                                        <Button
                                            disabled={!webhookConfigured}
                                            type="submit"
                                            style={{ paddingLeft: 16, paddingRight: 16, alignItems: "center", gap: 10, textTransform: "uppercase", fontWeight: 600 }}
                                            background={secondary[theme]}
                                            color="#FF"
                                            children={(
                                                <>
                                                    Validar dados
                                                    <FiArrowRightCircle size={16} color="#FFF" />
                                                </>
                                            )}
                                        />
                                    </div>
                                </Line>
                            </Form>

                        </>
                    )}
                </Content>

                {openConfirm && !loading && (
                    <ModalClickSignConfirm
                        open={openConfirm}
                        onCancel={() => setOpenConfirm(false)}
                        onConfirm={handleConfirm}
                        loading={false}
                    />
                )}

                <ModalLoading
                    loading={loading}
                    theme={theme}
                />

            </Container>
        </ModalGeneric>
    )
}