import React, { useCallback } from "react";
import Button from "../../../../components/Buttons/Button";
import { BsArrowLeft } from "react-icons/bs";
import { StepHeader } from "../../StepHeader";
import { secondary } from "../../../../theme";
import { useApi } from "../../../../hooks/api";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Container, ContainerLink, ContentFields, Link } from "./style";

interface IFinishStepProps {
    email: string
    password: string
    setLoading: (loading: boolean) => void
}

export const FinishStep: React.FC<IFinishStepProps> = (props) => {
    const { email, password, setLoading } = props;

    const navigate = useNavigate()

    const { signIn } = useApi()

    const continueToLogin = useCallback(async () => {

        setLoading(true)
        
        await signIn({ email, password })
        
        setLoading(false)
        
        navigate("/login")

    }, [email, password])

    return (
        <Container>

            <StepHeader
                colors={["#ECFDF3", "#D3FAE0"]}
                icon={<IoMdCheckmarkCircleOutline size={22} color={"#26935E"} />}
                title={"Senha redefinida"}
                description={(
                    <>
                        Sua senha foi redefinida com sucesso.<br />Clique abaixo para fazer login magicamente.
                    </>
                )}
            />

            <ContentFields>

                <Button
                    onClick={continueToLogin}
                    color="#FFF"
                    background={secondary.light}
                    type="button"
                    style={{ fontSize: 15, padding: "8px 16px", marginBottom: 10 }}
                    children={<div style={{ fontWeight: "600" }}>Continuar</div>}
                />

            </ContentFields>

            <ContainerLink>
                <Link to="/login">
                    <BsArrowLeft />
                    Voltar para o login
                </Link>
            </ContainerLink>

        </Container>
    )
}