import React from "react"

export const ClickSign32: React.FC<{ color: any }> = ({ color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
        children={(
            <path
                fill={color}
                d="M15.899 0C7.882 0 1.213 5.873 0 13.502h5.154a11.032 11.032 0 0 1 10.745-8.473c5.221 0 9.6 3.646 10.745 8.473h-6.366c-.91-1.553-2.594-2.532-4.38-2.532-2.795 0-5.052 2.262-5.052 5.03a5.022 5.022 0 0 0 5.053 5.03c1.819 0 3.47-.946 4.379-2.532h6.366A11.032 11.032 0 0 1 15.9 26.97c-5.221 0-9.6-3.612-10.745-8.472H0C1.213 26.16 7.882 32 15.899 32 24.792 32.066 32 24.877 32 16.034 32 7.19 24.792 0 15.899 0Z"
            />
        )}
    />
)
