import styled, { css } from "styled-components";
import { background, inactiveItens } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    outline: 0;
    min-width:100vw;
    max-width:100vw;
    min-height: calc(100 * var(--vh));
    max-height: calc(100 * var(--vh));
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const ContainerLastInteraction = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
    white-space: nowrap;
    @media(max-width: 650px){
        display: none;
    }
`