import React, { useCallback, useEffect, useState } from "react";
import { FormContainer } from "./style";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { Tabs } from "./../../components/Tabs";
import { NewPasswordForm } from "./NewPasswordForm";
import { RegistrationDataForm } from "./RegistrationDataForm";
import { DashboardPage } from "../../components/DashboardPage";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import {
  mapDefaultForm,
  personDefaultForm,
} from "../../utils/defaultFormsUtils";

const tabs = [
  {
    label: "Dados cadastrais",
    canClick: true,
  },
  {
    label: "Alterar senha",
    canClick: true,
  },
];

export const Perfil: React.FC = () => {
  const { user, api } = useApi();
  const { notify, theme } = useGlobal();

  const [pos, setPos] = useState(0);
  const [loading, setLoading] = useState(true);
  const [defaultData, setDefaultData] = useState<any>({} as any);

  const search = useCallback(
    async (id) => {
      setLoading(true);

      try {
        const { data } = await api.get(`/customers/${id}`);

        const _data: any = mapDefaultForm({ data, fields: personDefaultForm });

        setDefaultData({ ..._data });
      } catch (err) {
        notify("Erro ao buscar seus dados cadastrais!", "error");
      }

      setLoading(false);
    },
    [api, notify]
  );

  useEffect(() => {
    search(user?.person_id);
  }, [user]);

  return (
    <DashboardPage>
      <FormContainer theme={theme}>
        <Tabs
          tabs={tabs}
          pos={pos}
          onChange={(new_pos: number) => setPos(new_pos)}
          disabled={loading}
        />

        <ContentOrLoading type="wave" loading={loading} theme={theme}>
          {pos === 0 && <RegistrationDataForm defaultData={defaultData} />}
          {pos === 1 && <NewPasswordForm />}
        </ContentOrLoading>
      </FormContainer>
    </DashboardPage>
  );
};
