import React from "react";
import { primary } from "../../../theme";
import { WaveLoading } from "../WaveLoading";
import { themeTypes } from "../../../interfaces";
import { ModalGeneric } from "../../ModalGeneric";

interface IModalLoading {
    loading: boolean
    theme: themeTypes
}

export const ModalLoading: React.FC<IModalLoading> = (props) => {
    const { loading, theme } = props

    return (
        <>
            {loading &&
                <ModalGeneric
                    onClose={() => { }}
                    open={loading}
                    backgroundVisible={false}>
                    <div style={{ display: "flex", height: 300, width: 300, borderRadius: 5, background: primary[theme], justifyContent: "center", alignItems: "center" }}>
                        <WaveLoading />
                    </div>
                </ModalGeneric>
            }
        </>
    )
}