import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { franchiseProps } from "../../interfaces";
import { CompanyDataForm } from "./CompanyDataForm";
import { DashboardPage } from "../../components/DashboardPage";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { franchiseDefaultForm, mapDefaultForm } from "../../utils/defaultFormsUtils";

export const CompanyData: React.FC = () => {
    const { api } = useApi()
    const { notify, theme, franchise } = useGlobal()

    const [loading, setLoading] = useState(true)
    const [defaultData, setDefaultData] = useState<any>(null)
    const [default_franchise, setDefaultFranchise] = useState<franchiseProps | null>(null)

    const search = useCallback(async () => {

        setLoading(true)

        try {
            const { data } = await api.get(`/franchises/${franchise.id}`)

            const _data: any = mapDefaultForm({ data, fields: franchiseDefaultForm })

            setDefaultData({ ..._data })

            setDefaultFranchise({ ...data })

        } catch (err) {

            notify("Erro ao Buscar Dados da Imobiliária!", "error")

        }

        setLoading(false)

    }, [api, franchise])

    useEffect(() => { search() }, [])

    return (
        <DashboardPage>
            <ContentOrLoading
                theme={theme}
                loading={loading}
                text={"Buscando Dados da Imobiliária"}
                children={(
                    <CompanyDataForm
                        defaultData={defaultData}
                        defaultFranchise={default_franchise}
                    />
                )}
            />
        </DashboardPage>
    )
}