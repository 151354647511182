import React, { FormEvent, forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { Form } from "./style";
import { uniqueId } from "lodash";
import { BsTrash } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { Tooltip } from "../../../../../Tooltip";
import { useApi } from "../../../../../../hooks/api";
import { inactiveItens } from "../../../../../../theme";
import { useGlobal } from "../../../../../../hooks/global";
import { AddButton, Column, Line } from "../../../../../../style";
import { copyOf } from "../../../../../../services/generalServices";
import { ModalLoading } from "../../../../../Loadings/ModalLoading";
import { consultsOptionsRelations } from "../../../../../../utils/options";
import { GenericForm, IGenericFormRefProps } from "../../../../../GenericForm";
import { IGroupProps, IOptionsProps, customers_proposalAttributes } from "../../../../../../interfaces";

interface ITenantFormProps {
    consultsOptions: IOptionsProps[]
    tenantsConsults: customers_proposalAttributes[]
    defaultTenants: any
    defaultData: any
    proposalId: string
    onSuccess: (data: any) => void
    isDisabled: boolean
}

export interface ITenantFormRefProps {
    forceSubmit: () => any
}

const tenantsFields = ["customer_id", "relation"]

const TenantFormComponent: React.ForwardRefRenderFunction<ITenantFormRefProps, ITenantFormProps> = (props, ref) => {
    const { consultsOptions, defaultTenants, defaultData, onSuccess, isDisabled, proposalId, tenantsConsults } = props

    const { theme, notify } = useGlobal()

    const { api } = useApi()

    const { register, handleSubmit, control, watch, formState: { errors }, setError, setValue, trigger, unregister } = useForm({
        defaultValues: defaultData as any,
        mode: "all"
    });

    const _form = watch()

    const generateTenantGroup = (defaultId?: string) => {
        const _uniqueId = defaultId ? defaultId : uniqueId()

        const newTenantGroup: IGroupProps = {
            name: "",
            label: "",
            uniqueId: _uniqueId,
            fields: [
                [
                    {
                        name: "customer_id-" + _uniqueId,
                        label: "Pessoa",
                        type: "select-person",
                        isClearable: true,
                        required: true,
                        getOptions: (value) => {
                            const form = watch()
                            const tenantsFieldsIds = Object.keys(form).filter(key => key.startsWith("customer_id-"));
                            const tenantsIds = tenantsFieldsIds.map(id => form?.[id]?.value);
                            return consultsOptions.filter(option => !tenantsIds.includes(option.value))
                        },
                        executeOnChange: async (value) => {
                            const relation = value?.relation
                            setValue("relation-" + _uniqueId, consultsOptionsRelations.find((option) => option.value === relation) ?? null)
                        }
                    },
                    {
                        name: "relation-" + _uniqueId,
                        label: "Tipo",
                        type: "select-fixed",
                        isClearable: true,
                        required: true,
                        options: consultsOptionsRelations,
                        getIsDisabled: () => true
                    }
                ]
            ]
        }

        return newTenantGroup
    }

    const defaultTenantsGroups = () => {
        if (defaultTenants && defaultTenants.length > 0) {
            const _tenantsGroups: any[] = []

            const _defaultDataTenants = defaultTenants ?? []
            _defaultDataTenants.forEach((executor: any) => {
                _tenantsGroups.push(generateTenantGroup(executor.uniqueId))
            })

            return _tenantsGroups
        }
        return []
    }

    const [loading, setLoading] = useState(false)
    const [tenantsGroups, setTenantsGroups] = useState<IGroupProps[]>(defaultTenantsGroups())

    const tenantsRefs = useRef<IGenericFormRefProps[]>([])
    const buttonSubmitRef = useRef<HTMLButtonElement>(null)

    const onSubmit = useCallback(async () => {

        setLoading(true)

        const tenantsValues = getTenants()

        try {
            let newTenants = copyOf(tenantsConsults)
            newTenants = newTenants.map((_tenant: customers_proposalAttributes) => {
                const correct_customer = _tenant
                correct_customer.include = !!tenantsValues.find((tenant) => tenant.customer_id === _tenant.customer_id)
                return correct_customer
            })

            await api.put(`/proposals/tenants/${proposalId}`, { consults: newTenants, nextStep: 3 })

            notify("Proposta atualizada com sucesso.", "success")

            onSuccess(newTenants)

        } catch (err: any) {

            notify("Erro ao atualizar locatários.", "error")

        }

        setLoading(false)

    }, [_form, tenantsConsults, tenantsRefs])

    const getTenants = useCallback(() => {
        const _refs = tenantsRefs.current.filter((ref) => ref?.getForm())
        const owners = _refs.map((ref) => {
            const form = ref?.getForm()
            const _uniqueId = form?.uniqueId

            return {
                customer_id: form?.["customer_id-" + _uniqueId],
                relation: form?.["relation-" + _uniqueId],
                uniqueId: _uniqueId
            }
        })
        return owners
    }, [_form, tenantsConsults, tenantsRefs])

    const handleStopPropagation = useCallback((e: FormEvent<HTMLFormElement>) => {
        e?.stopPropagation()
        handleSubmit(onSubmit)(e)
    }, [handleSubmit, onSubmit, _form, tenantsConsults, tenantsRefs])

    const addTenantGroup = useCallback(() => {
        const newTenantGroup = generateTenantGroup()
        setTenantsGroups((prev) => [...prev, newTenantGroup])
    }, [uniqueId])

    const removeTenantGroup = useCallback((index: number) => {
        setTenantsGroups((prev) => {
            return prev.filter(({ uniqueId }, i) => {
                if (i !== index) return true
                else tenantsFields.forEach((field) => unregister(field + "-" + uniqueId))
            })
        })
    }, [])

    const forceSubmit = useCallback(() => buttonSubmitRef.current?.click(), [buttonSubmitRef])

    useImperativeHandle(ref, () => ({ forceSubmit }) as ITenantFormRefProps)

    return (
        <>
            <Form onSubmit={handleStopPropagation}>
                <Column style={{ gap: 20 }}>
                    {tenantsGroups.map((owner_group, index) => (
                        <Line key={owner_group.uniqueId} style={{ gap: 10 }}>

                            {!isDisabled && (tenantsGroups.length > 1) && (
                                <Line style={{ alignItems: "flex-start", marginTop: 24, minWidth: 36 }}>
                                    <Tooltip
                                        onClick={() => removeTenantGroup(index)}
                                        style={{ height: 36, width: 36 }}
                                        title="Excluir"
                                        children={(
                                            <div>
                                                <BsTrash
                                                    color={inactiveItens[theme]}
                                                    size={20}
                                                />
                                            </div>
                                        )}
                                    />
                                </Line>
                            )}

                            <Column style={{ gap: 20, flex: 1 }}>
                                <GenericForm
                                    ref={(ref: any) => tenantsRefs.current[index] = ref}
                                    groups={[owner_group]}
                                    _form={_form}
                                    control={control}
                                    errors={errors}
                                    trigger={trigger}
                                    setValue={setValue}
                                    register={register}
                                    disabled={isDisabled}
                                />
                            </Column>

                        </Line>
                    ))}
                    {tenantsConsults.length === 0 && (
                        <div style={{ textAlign: "center" }}>
                            Nenhum locatário vinculado à proposta
                        </div>
                    )}
                    {tenantsGroups.length < consultsOptions.length && !isDisabled && (
                        <Line>
                            <AddButton noHaveDelete={!(tenantsGroups.length > 1)} theme={theme} onClick={addTenantGroup}>
                                Incluir+
                            </AddButton>
                        </Line>
                    )}
                    <button
                        ref={buttonSubmitRef}
                        type="submit"
                        style={{ display: "none" }}
                    />

                </Column>

            </Form>

            <ModalLoading theme={theme} loading={loading} />

        </>
    )
}

export const TenantForm = forwardRef(TenantFormComponent)