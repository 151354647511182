import Switch from "rc-switch";
import Button from "../../components/Buttons/Button";
import React, { useCallback, useEffect, useState } from "react";
import { Column, Line } from "../../style";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { TbPlugConnected } from "react-icons/tb";
import { BiCheckCircle, BiEdit } from "react-icons/bi";
import { inactiveItens, secondary } from "../../theme";
import { HeaderList } from "../../components/HeaderList";
import { DashboardPage } from "../../components/DashboardPage/index";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { DropdownButton } from "../../components/Buttons/DropdownButton";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { ClickSign32 } from "../../components/SvgComponents/Icons/ClickSign/clickSign32";
import { ModalClickSign } from "../../components/Modals/ModalIntegrations/ModalClickSign";
import { ModalSuperlogica } from "../../components/Modals/ModalIntegrations/ModalSuperlogica";
import { Superlogica32 } from "../../components/SvgComponents/Icons/Superlogica/Superlogica32";
import { IntegrationsTypes, IActions, IIntegrationsProps, IIntegrationTokensProps } from "../../interfaces";
import { ModalClickSignConfirm } from "../../components/Modals/ModalIntegrations/ModalClickSign/ModalClickSignConfirm";
import { ContainerIntegrationLogo, ContainerIntegrations, IntegrationContainer, IntegrationDescription, SwitchContainer } from "./style";
import { copyOf } from "../../services/generalServices";

interface IIntegrationsAvailblesProps {
    name: IntegrationsTypes
    title: string
    description: string
    icon: {
        dark: any
        light: any
    }
}

const correct_names: any = {
    super_logica: {
        name: "Superlógica",
        predicate: "a"
    },
    click_sign: {
        name: "ClickSign",
        predicate: "a"
    }
}

const integrationsAvailbles: IIntegrationsAvailblesProps[] = [
    {
        name: "super_logica",
        title: "Superlógica",
        description: "Principal plataforma para gestão financeira e de locação de imóveis do Brasil.",
        icon: {
            dark: <Superlogica32 color={"#FFF"} />,
            light: <Superlogica32 color={"#1034F2"} />
        }
    },
    {
        name: "click_sign",
        title: "ClickSign",
        description: "Plataforma de assinatura digital de documentos eletrônicos.",
        icon: {
            dark: <ClickSign32 color={"#FFF"} />,
            light: <ClickSign32 color={"#2B6AF3"} />
        }
    }
]

export const IntegrationsList: React.FC = () => {
    const { api } = useApi()
    const { theme, notify } = useGlobal()

    const [loadingEdit, setLoadingEdit] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(true)
    const [openConfirm, setOpenConfirm] = useState<IntegrationsTypes | null>(null)
    const [editIntegration, setEditIntegration] = useState<IntegrationsTypes | null>(null)
    const [integrationsTokens, setIntegrationsTokens] = useState<IIntegrationsProps>({ super_logica: {}, click_sign: {} } as IIntegrationsProps)

    const actionsActive: IActions = {
        main_option: { text: "Conectado", id: "connect" },
        className: "connect",
        icon: <BiCheckCircle size={16} className="connect" />,
        options: [
            {
                text: "Editar dados",
                id: "edit",
                icon: <BiEdit color={secondary[theme]} size={16} />
            },
            {
                text: "Desconectar",
                id: "disconnect",
                icon: <TbPlugConnected color={secondary[theme]} size={16} />
            }
        ],
        canAccess: ["responsible"],
        type: "solid"
    }

    const actionsDesactive: IActions = {
        main_option: { text: "Desconectado", id: "connect" },
        className: "connect",
        icon: <BiCheckCircle size={16} className="connect" />,
        options: [
            {
                text: "Editar dados",
                id: "edit",
                icon: <BiEdit color={secondary[theme]} size={16} />
            },
            {
                text: "Conectar",
                id: "connect",
                icon: <TbPlugConnected color={secondary[theme]} size={16} />
            }
        ],
        canAccess: ["responsible"],
        type: "solid"
    }

    const getActiveActions = (type: IntegrationsTypes): IActions => {
        const _activeActions = { ...actionsActive } as IActions
        _activeActions.className += `_${type}`
        return _activeActions
    }

    const getDesactiveActions = (type: IntegrationsTypes): IActions => {
        const _desactiveActions = { ...actionsDesactive } as IActions
        _desactiveActions.className += `_${type}`
        return _desactiveActions
    }

    const handleChangeStatus = useCallback(async (type: IntegrationsTypes) => {
        if (type === "click_sign") setOpenConfirm(type)
        else await handleConfirm(type)
    }, [integrationsTokens])

    const handleConfirm = useCallback(async (type: IntegrationsTypes) => {

        setLoadingEdit(true)

        try {
            const new_status = integrationsTokens[type].status === "active" ? "block" : "active"

            await api.put(`/integrations/${type}/change-status`, { status: new_status })

            notify(`Status da integração com ${correct_names[type].predicate} ${correct_names[type].name} atualizado com sucesso`, "success")

            searchIntegrationsTokens()

        } catch (error) {

            notify(`Erro ao atualizar status da integração com ${correct_names[type].predicate} ${correct_names[type].name}`, "error")

        }

        setLoadingEdit(false)

    }, [integrationsTokens])

    const searchIntegrationsTokens = useCallback(async () => {

        setLoadingSearch(true)

        try {

            const { data } = await api.get(`/integrations/get-tokens`)
            setIntegrationsTokens(data)

        } catch (err) {

            notify("Erro ao buscar as integrações", "error")

        }

        setLoadingSearch(false)

    }, [])

    const handleConectIntegration = useCallback(async (type: IntegrationsTypes) => {
        setEditIntegration(type)
    }, [])

    const handleClickInOption = useCallback((id: string, type: IntegrationsTypes) => {
        if (id === "edit") setEditIntegration(type)
        else if (id === "connect") handleChangeStatus(type)
        else if (id === "disconnect") handleChangeStatus(type)
    }, [integrationsTokens])

    const handleSaveTokens = useCallback(async () => {
        setEditIntegration(null)
        searchIntegrationsTokens()
    }, [])

    const validateTokensRequireds = useCallback((tokens: IIntegrationTokensProps, type: IntegrationsTypes) => {
        const { access_token, app_token } = tokens
        if (type === "click_sign") return !!access_token
        else return !!access_token && !!app_token
    }, [])

    useEffect(() => { searchIntegrationsTokens() }, [])

    useEffect(() => { console.log({ integrationsTokens }) }, [integrationsTokens])

    return (
        <DashboardPage>
            <Column style={{ gap: 20, flex: 1 }}>
                <HeaderList
                    textHeader="Integrações"
                    textDescritpion="Integrações com outras plataformas e crm's"
                    theme={theme}
                    haveBorder
                    loading={loadingSearch}
                    onRefresh={searchIntegrationsTokens}
                />
                <ContentOrLoading loading={loadingSearch} text="Buscando integrações" theme={theme}>

                    <Line style={{ gap: 20, flexWrap: "wrap", justifyContent: "center" }}>
                        {integrationsAvailbles.map((integration) => (
                            <ContainerIntegrations key={integration.name}>
                                <IntegrationContainer theme={theme}>
                                    <Column>
                                        <div style={{ minHeight: 19 }}>
                                            <SwitchContainer open={validateTokensRequireds(integrationsTokens?.[integration.name], integration.name)}>
                                                <Switch
                                                    id="tooltip_switch"
                                                    className="custom-switch custom-switch-primary custom-switch-small"
                                                    checked={integrationsTokens?.[integration.name]?.status === "active"}
                                                    onChange={() => handleChangeStatus(integration.name)}
                                                />
                                            </SwitchContainer>
                                        </div>
                                        <ContainerIntegrationLogo>
                                            {integration.icon[theme]}
                                            {integration.title}
                                        </ContainerIntegrationLogo>
                                    </Column>

                                    <IntegrationDescription>
                                        {integration.description}
                                    </IntegrationDescription>

                                    <Line style={{ justifyContent: "center" }}>
                                        {!validateTokensRequireds(integrationsTokens?.[integration.name], integration.name) && (
                                            <div>
                                                <Button
                                                    onClick={() => handleConectIntegration(integration.name)}
                                                    style={{ gap: 10, alignItems: "center", padding: "10px 16px", minHeight: 41.5, minWidth: 150 }}
                                                    background={inactiveItens[theme]}
                                                    color="#FFF"
                                                    children={(
                                                        <>
                                                            <TbPlugConnected size={20} color="#FFF" />
                                                            Integrar
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {validateTokensRequireds(integrationsTokens?.[integration.name], integration.name) && (
                                            <DropdownButton
                                                {...integrationsTokens?.[integration.name]?.status === "active" ? getActiveActions(integration.name) : getDesactiveActions(integration.name)}
                                                theme={theme}
                                                loading={false}
                                                onClick={(id: string) => handleClickInOption(id, integration.name)}
                                                zIndex={900}
                                            />
                                        )}
                                    </Line>
                                </IntegrationContainer>
                            </ContainerIntegrations>
                        ))}
                    </Line>

                </ContentOrLoading>
            </Column>

            <ModalLoading
                loading={loadingEdit}
                theme={theme}
            />

            {editIntegration === "super_logica" && (
                <ModalSuperlogica
                    integrationTokens={integrationsTokens?.[editIntegration]}
                    onCancel={() => setEditIntegration(null)}
                    onSave={handleSaveTokens}
                />
            )}

            {editIntegration === "click_sign" && (
                <ModalClickSign
                    integrationTokens={integrationsTokens?.[editIntegration]}
                    onCancel={() => setEditIntegration(null)}
                    onSave={handleSaveTokens}
                />
            )}

            {!!openConfirm && !loadingEdit && (
                <ModalClickSignConfirm
                    open={!!openConfirm}
                    onCancel={() => setOpenConfirm(null)}
                    onConfirm={() => handleConfirm(openConfirm)}
                    loading={false}
                />
            )}

        </DashboardPage >
    )
}