import React, { useCallback, useEffect, useState } from "react"
import { useApi } from "../../hooks/api";
import { FormPropertyType } from "./Form";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { ModalGeneric } from "../../components/ModalGeneric";
import { setUpConsultation } from "../../services/generalServices";
import { IActions, IFilters, properties_typesAttributes } from "../../interfaces/index";

const initialFilters: IFilters[] = []

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "name", name: "Nome", style: { width: "50%" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "qnt", name: "Nº de imóveis", style: { whiteSpace: "nowrap", width: "50%", textAlign: "center" }, styleContent: { textAlign: "center" } }
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Novo Tipo de Imóvel", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

export const PropertiesTypesList: React.FC = () => {
    const { api } = useApi()
    const { theme, paginationLimit, notify } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [numOfPropertiesTypes, setNumOfPropertiesTypes] = useState(0)
    const [loadingPropertiesTypes, setLoadingPropertiesTypes] = useState(true)
    const [PropertiesTypeselected, setPropertiesTypeselected] = useState<properties_typesAttributes | null>(null)

    const searchPropertiesTypes = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingPropertiesTypes(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/properties-types?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_properties_types = result.data.rows as properties_typesAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_properties_types.forEach((property_type) => {
                    new_table.data.push({
                        id: property_type.id,
                        name: property_type?.name || null,
                        qnt: property_type?.qnt || 0,
                        this: property_type
                    })
                })
                return { ...new_table }
            })

            setNumOfPropertiesTypes(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelos tipos de imóveis!", "error")
        }
        setLoadingPropertiesTypes(false)
    }, [filters, searchString])

    const onSavePropertyType = useCallback(() => {
        setOpenModal(false)
        searchPropertiesTypes(1)
    }, [filters, searchString])

    const selectPropertyType = useCallback((PropertyType: properties_typesAttributes) => {
        setPropertiesTypeselected({ ...PropertyType })
        setOpenModal(true)
    }, [])

    const openModalNewPropertyType = useCallback(() => {
        setPropertiesTypeselected(null)
        setOpenModal(true)
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewPropertyType()
        else console.log({ action_id })
    }

    useEffect(() => { searchPropertiesTypes(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingPropertiesTypes}
            setLoading={setLoadingPropertiesTypes}
            numberOfItems={numOfPropertiesTypes}
            textHeader="Tipos de Imóveis"
            textItems="tipos de imóveis encontrados"
            text_loading={"Buscando tipos de Imóveis"}
            text_empty={"Nenhum tipo de imóvel encontrado"}
            theme={theme}
            search={searchPropertiesTypes}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            table={table}
            have_edit
            edit_action={(property_type: any) => selectPropertyType(property_type)}
            pages={numberOfPages}
            page={atualPage}
            actions={actionsInitial}
            onClickOption={handleAction}
            onChangeSearch={setSearchString}
        >
            <ModalGeneric open={openModal} onClose={() => { }} >
                <FormPropertyType
                    defaultData={PropertiesTypeselected}
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSavePropertyType()}
                />
            </ModalGeneric>
        </PageList>
    )
}
