import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { FiPlusCircle } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { IOptionsProps } from "../../../../interfaces";
import { FixRequiredSelect, IFixRequiredSelectProps, ISelectRefProps } from "../FixRequiredSelect";

export interface SelectFixedCardsRefProps {
    getValue: () => IOptionsProps | null
}

interface SelectFixedCardsProps extends IFixRequiredSelectProps {
    defaultValue?: IOptionsProps
    icon?: any
}

const SelectFixedCardsComponent: React.ForwardRefRenderFunction<SelectFixedCardsRefProps, SelectFixedCardsProps> = (props, ref) => {
    const { error, options = [], icon = <AiOutlineUser />, isClearable, onFocus = () => { }, isDisabled = false, onChange = () => { }, isRequired = false, noOptionsMessage, onClick = () => { }, defaultValue } = props

    const [controlRender, setControlRender] = useState(false)
    const [option_selected, setOptionSelected] = useState<IOptionsProps | null>(defaultValue ? defaultValue : null)

    const select_ref = useRef<ISelectRefProps>(null)

    const formatOptionLabel = useCallback((value: any, option) => {
        if (option?.value === value?.value) {
            return (
                <div onClick={() => onClick(value)} style={{ display: "flex", flexDirection: "row", marginRight: 10, color: "#FFF", alignItems: "center", gap: 5, cursor: props.onClick ? "pointer" : "default" }}>
                    <div>{value.__isNew__ ? <FiPlusCircle /> : icon}</div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: 600 }}>{value.label}</div>
                </div>
            )
        }
        else {
            return (
                <div style={{ display: "flex", flexDirection: "row", marginRight: 10, alignItems: "center", gap: 5, cursor: props.onClick ? "pointer" : "default" }}>
                    <div>{value.__isNew__ ? <FiPlusCircle /> : icon}</div>
                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{value.label}</div>
                </div>
            )
        }
    }, [isDisabled])

    const getValue = useCallback(() => option_selected, [option_selected])

    const onChangeOption = useCallback((options) => {
        const selected_option = options.length > 0 ? { ...options[options.length - 1] } : null
        setOptionSelected(selected_option ? { ...selected_option } : null)
        onChange(selected_option ? { ...selected_option } : null)
    }, [isDisabled])

    const onInputFocus = useCallback((e) => { onFocus(e) }, [])

    const focus = useCallback(() => { select_ref.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ getValue, focus }))

    useEffect(() => { setControlRender((atual) => !atual) }, [option_selected])

    const childrenSelectDisabled = (
        <FixRequiredSelect
            ref={select_ref}
            noWrap
            onFocus={onInputFocus}
            noOptionsMessage={() => noOptionsMessage}
            placeholder=""
            isClearable={false}
            isRequired={isRequired}
            loadingMessage={() => <div style={{ position: "relative" }}>Buscando...</div>}
            options={options}
            onChange={onChangeOption}
            value={option_selected}
            isMulti
            isDisabled={isDisabled}
            formatOptionLabel={(value: any) => formatOptionLabel(value, option_selected)}
            components={{ MultiValueRemove: () => null }}
            error={error}
        />
    )

    const childrenSelectEnabled = (
        <FixRequiredSelect
            ref={select_ref}
            noWrap
            onFocus={onInputFocus}
            noOptionsMessage={() => noOptionsMessage}
            placeholder=""
            isClearable={isClearable}
            isRequired={isRequired}
            loadingMessage={() => <div style={{ position: "relative" }}>Buscando...</div>}
            options={options}
            onChange={onChangeOption}
            value={option_selected}
            isMulti
            formatOptionLabel={(value: any) => formatOptionLabel(value, option_selected)}
            error={error}
        />
    )

    return (
        <>
            {isDisabled &&
                <>
                    {controlRender && childrenSelectDisabled}
                    {!controlRender && childrenSelectDisabled}
                </>
            }
            {!isDisabled &&
                <>
                    {controlRender && childrenSelectEnabled}
                    {!controlRender && childrenSelectEnabled}
                </>
            }
        </>
    )
}

export const SelectFixedCards = forwardRef(SelectFixedCardsComponent)