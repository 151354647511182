import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { secondary } from "../../../theme";
import { BsCheckCircle } from 'react-icons/bs';
import { useGlobal } from "../../../hooks/global";

interface ProgressProps {
    porcen: number
    isFinished: boolean
}

export const Progress: React.FC<ProgressProps> = (props) => {
    const { isFinished } = props

    const { theme } = useGlobal()

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: 10, position: "relative" }}>
            {!isFinished &&
                <CircularProgress
                    style={{ color: secondary[theme] }}
                    size={26}
                    variant="indeterminate"
                />
            }
            {isFinished &&
                <div style={{ position: "absolute", right: 0, top: "50%", fontWeight: 600, transform: "translateY(-50%)", fontSize: 9 }}>
                    <BsCheckCircle
                        color={secondary[theme]}
                        size={26}
                    />
                </div>
            }
        </div>
    )
}