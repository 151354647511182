import Button from "../../components/Buttons/Button";
import React, { useCallback, useEffect, useState } from "react";
import { Column } from "../../style";
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { useWallet } from "../../hooks/wallet";
import { AiOutlineReload } from "react-icons/ai";
import { CircularProgress } from "@material-ui/core";
import { ModalConfirmCancel } from "./ModalConfirmCancel";
import { DashboardPage } from "../../components/DashboardPage";
import { correctDateDashboard } from "../../services/generalServices";
import { ContentOrLoading } from "../../components/Loadings/ContentOrLoading";
import { ModalSubscription } from "../../components/Modals/ModalSubscription";
import { TransactionList } from "../../components/PlansComponents/TransactionList";
import { SubscriptionCard } from "../../components/PlansComponents/SubscriptionCard";
import { ContainerLastUpdate, ContainerNoSubscription, ContainerReload, ContainerSubscription, Content, H4, Line, SubTitle, TextNoSubscription, Title } from "./style";

export const Subscription: React.FC = () => {
    const { api } = useApi()
    const _wallet = useWallet()
    const { theme, notify } = useGlobal()

    const [openModal, setOpenModal] = useState(false)
    const [changePlan, setChangePlan] = useState(false)
    const [openCancelSubscription, setOpenCancelSubscription] = useState(false)
    const [loadingCancelSubscription, setLoadingCancelSubscription] = useState(false)
    const [transaction_selected, setTransactionSelected] = useState<string | null>(null)

    const search = useCallback(async () => {
        await _wallet.forceSearchAll()
    }, [_wallet])

    const onSaveSubscription = useCallback(async (transaction_id: string) => {
        notify("Compra de nova assinatura realizada!", "success")
        setOpenModal(false)
        await search()
        setTransactionSelected(transaction_id)
    }, [_wallet])

    const onClickChangePlan = useCallback(() => {
        setChangePlan(true)
        setOpenModal(true)
    }, [])

    const onCloseModal = useCallback(() => {
        setOpenModal(false)
        setChangePlan(false)
    }, [])

    const handleCancelSubscription = useCallback(async () => {
        setLoadingCancelSubscription(true)
        try {
            await api.post(`/subscriptions/cancel/${_wallet.subscription?.id}`)
            notify("Assinatura cancelada com sucesso!", "success")
        } catch (err) {
            notify("Erro ao cancelar assinatura!", "error")
        }
        setOpenCancelSubscription(false)
        setLoadingCancelSubscription(false)
        search()
    }, [_wallet])

    useEffect(() => { search() }, [])

    return (
        <DashboardPage>
            <Title theme={theme}>
                <Column>
                    <H4>Assinatura</H4>
                    <ContainerLastUpdate>
                        {!_wallet.loading_all ?
                            <Column>
                                <Line>
                                    <SubTitle>Última atualização realizada em <b>{correctDateDashboard(_wallet.updatedAt)}</b></SubTitle>
                                    <ContainerReload onClick={() => search()}>
                                        <AiOutlineReload />
                                    </ContainerReload>
                                </Line>
                                <div style={{ fontSize: 14, whiteSpace: "nowrap" }}>

                                </div>
                            </Column>
                            :
                            <Line>
                                <SubTitle>Atualizando dados da sua assinatura</SubTitle>
                                <CircularProgress
                                    size={10}
                                    variant="indeterminate"
                                    style={{ color: secondary[theme] }}
                                />
                            </Line>
                        }
                    </ContainerLastUpdate>
                </Column>
            </Title>

            <ContentOrLoading loading={_wallet.loading_all} text="Buscando assinatura da imobiliária" theme={theme}>
                <Content>
                    {!_wallet.subscription?.id &&
                        <ContainerNoSubscription>
                            <TextNoSubscription>
                                Imobiliária ainda não possui uma assinatura
                            </TextNoSubscription>
                            <div>
                                <Button
                                    background={secondary[theme]}
                                    color="#FFF"
                                    onClick={() => setOpenModal(true)}
                                >
                                    Adquirir uma nova assinatura
                                </Button>
                            </div>
                        </ContainerNoSubscription>
                    }
                    {_wallet.subscription?.id &&
                        <ContainerSubscription>
                            <SubscriptionCard
                                openCancelSubscription={() => setOpenCancelSubscription(true)}
                                changeCreditCard={() => setOpenModal(true)}
                                changePlan={onClickChangePlan}
                                subscription={_wallet.subscription}
                                theme={theme}
                            />
                            <TransactionList
                                transactions={_wallet.transactions_subscriptions}
                                theme={theme}
                                setTransactionSelected={setTransactionSelected}
                                transaction_selected={transaction_selected}
                            />
                        </ContainerSubscription>
                    }
                </Content>
            </ContentOrLoading>

            {openModal &&
                <ModalSubscription
                    onCancel={onCloseModal}
                    onSave={onSaveSubscription}
                    openModal={openModal}
                    default_plan_id={changePlan ? null : _wallet.subscription?.plan_id}
                />
            }

            {openCancelSubscription &&
                <ModalConfirmCancel
                    loading={loadingCancelSubscription}
                    onCancel={() => !loadingCancelSubscription ? setOpenCancelSubscription(false) : {}}
                    onConfirm={handleCancelSubscription}
                    open={openCancelSubscription}
                />
            }

        </DashboardPage>
    )
}