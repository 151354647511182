import React, { useState } from "react";
import { WarrantyData } from "./Warranty";
import { PropertyData } from "./PropertyData";
import { CustomerData } from "./CustomerData";
import { ConsultsData } from "./ConsultsData";
import { inactiveItens } from "../../../theme";
import { IoIosArrowDown } from "react-icons/io";
import { useGlobal } from "../../../hooks/global";
import { ContractorData } from "./ContractorData";
import { IconButton, Tooltip } from "@material-ui/core";
import { Column, Line, LineAllCenter } from "../../../style";
import { Container, ContainerArrow, ContainerTitle, Content, Description, Title } from "./style";
import { contractorDataAttributes, personsAttributes, customers_proposalAttributes, propertiesAttributes, warrantyAttributes } from "../../../interfaces";

interface IBagDocumentProposal {
    title: string
    description: string
    icon: any
    customer_id?: string
    property_id?: string
    loading?: boolean
    setLoading?: any
    defaultCustomer?: personsAttributes
    defaultProperty?: propertiesAttributes
    contractorData?: contractorDataAttributes
    warrantyData?: warrantyAttributes
    consults?: customers_proposalAttributes[]
}

export const BagDocumentProposal: React.FC<IBagDocumentProposal> = (props) => {
    const { contractorData, warrantyData, defaultCustomer, defaultProperty, description, icon, title, consults } = props

    const { theme } = useGlobal()

    const [open, setOpen] = useState(false);

    return (
        <Container theme={theme}>
            <ContainerTitle onClick={() => setOpen((atual) => !atual)}>
                <Line style={{ gap: 10, alignItems: "center" }}>
                    {icon && (
                        <LineAllCenter>
                            {icon}
                        </LineAllCenter>
                    )}
                    <Column>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </Column>
                </Line>
                <Tooltip title={open ? "Fechar" : "Abrir"} style={{ width: 30, height: 30 }}>
                    <IconButton aria-label={open ? "Fechar" : "Abrir"}>
                        <ContainerArrow open={open}>
                            <IoIosArrowDown
                                color={inactiveItens[theme]}
                                size={16}
                            />
                        </ContainerArrow>
                    </IconButton>
                </Tooltip>
            </ContainerTitle>
            {open &&
                <Content theme={theme}>
                    {defaultProperty && <PropertyData property={defaultProperty} documents={defaultProperty.documents} />}
                    {defaultCustomer && <CustomerData customer={defaultCustomer} documents={defaultCustomer.documents} />}
                    {contractorData && <ContractorData contractorData={contractorData} />}
                    {warrantyData && <WarrantyData warranty={warrantyData} />}
                    {consults && <ConsultsData consults={consults.filter((consult) => consult.query_id)} />}
                </Content>
            }
        </Container>
    )
}