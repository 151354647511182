import React, { useCallback, useState, useEffect } from "react";
import PaginationCustom from "../../../components/PaginationCustom";
import { Column } from "../../../style";
import { FiInfo } from "react-icons/fi";
import { IoCard } from "react-icons/io5";
import { useApi } from "../../../hooks/api";
import { PixSharp } from "@mui/icons-material";
import { RiBarcodeFill } from "react-icons/ri";
import { BsArrowUpShort } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
import { useGlobal } from "../../../hooks/global";
import { TbHeartHandshake } from "react-icons/tb";
import { Bag } from "../../../components/Bags/Bag";
import { getStatus } from "../../../utils/columnsTables";
import { maskFunctions } from "../../../services/maskServices";
import { DashboardPage } from "../../../components/DashboardPage";
import { ModalReceipt } from "../../../components/Modals/ModalReceipt";
import { MdLibraryBooks, MdOutlineRemoveRedEye } from "react-icons/md";
import { ModalContract } from "../../../components/Modals/ModalContract";
import { MaxCoin } from "../../../components/SvgComponents/Icons/MaxCoin";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { ModalResultQuery } from "../../../components/Modals/ModalQuery/Result";
import { ContentOrLoading } from "../../../components/Loadings/ContentOrLoading";
import { ContentOrLoadingBag } from "../../../components/Loadings/ContentOrLoadingBag";
import { MaxCoinSmall20 } from "../../../components/SvgComponents/Icons/MaxCoinSmall20";
import { background, fail, inactiveItens, info, secondary, success } from "../../../theme";
import { correctDate, correctDateDashboard, setUpConsultation } from "../../../services/generalServices";
import { SelectNoCreateSingleMulti } from "../../../components/Inputs/BaseSelects/SelectNoCreateSingleMulti";
import { contractsAttributes, themeTypes, transactionsAttributes, walletAttributes } from "../../../interfaces";
import { BalanceCard, ContainerBalance, ContainerIconMaxCoin, ContainerLastUpdate, ContainerNoSubscription, ContainerPagination, ContainerReload, ContainerSelectFranchise, ContainerTable, H4, Line, SubTitle, TextNoSubscription, Title } from "./style";

interface ISearchProps {
    page?: number
    franchise_id?: string | null
}

const colors: any = {
    "outline": {
        "dark": secondary.dark,
        "light": secondary.light
    },
    "solid": {
        "dark": "#FFF",
        "light": "#FFF"
    }
}

const status_of_transaction: any = {
    "increase": {
        "new": {
            color: info,
            text: "Aguardando pagamento"
        },
        "waiting": {
            color: info,
            text: "Aguardando pagamento"
        },
        "identified": {
            color: info,
            text: "Aguardando pagamento"
        },
        "approved": {
            color: info,
            text: "Aguardando pagamento"
        },
        "paid": {
            color: success,
            text: "Pago"
        },
        'paid-free': {
            color: info,
            text: 'Teste grátis'
        },
        "unpaid": {
            color: fail,
            text: "Não pago"
        },
        "refunded": {
            color: fail,
            text: "Devolvido"
        },
        "contested": {
            color: fail,
            text: "Contestado"
        },
        "canceled": {
            color: fail,
            text: "Cancelado"
        },
        "settled": {
            color: success,
            text: "Pago"
        },
        "link": {
            color: info,
            text: "Aguardando pagamento"
        },
        "expired": {
            color: fail,
            text: "Expirado"
        }
    },
    "decrease": {
        "new": {
            color: info,
            text: "Provisionado"
        },
        "waiting": {
            color: info,
            text: "Provisionado"
        },
        "paid": {
            color: success,
            text: "Pago"
        },
        "unpaid": {
            color: fail,
            text: "Não pago"
        },
        "refunded": {
            color: fail,
            text: "Devolvido"
        },
        "contested": {
            color: fail,
            text: "Contestado"
        },
        "canceled": {
            color: fail,
            text: "Cancelado"
        },
        "settled": {
            color: success,
            text: "Pago"
        },
        "link": {
            color: info,
            text: "Provisionado"
        },
        "expired": {
            color: fail,
            text: "Expirado"
        }
    }
}

const transaction_description = (transaction: transactionsAttributes) => {
    if (transaction?.query?.id) return `Consulta ${transaction?.query?.type}`
    else if (transaction.contract?.id) return `Doc. eletrônico concluído`
    else return "Compra de créditos"
}

const transaction_pay_method = (transaction: transactionsAttributes, theme: themeTypes) => {
    if (transaction.method === "credit_card") {
        return (
            <Line style={{ alignItems: "center", gap: 5 }}>
                <IoCard size={20} color={inactiveItens[theme]} />
                <b style={{ textTransform: "capitalize" }}>{transaction?.card_flag} </b>
                ****{transaction?.card_mask?.replace(/\D/g, "")}
            </Line>
        )
    }
    else if (transaction.method === "pix") {
        return (
            <Line style={{ alignItems: "center", gap: 5 }}>
                <PixSharp fontSize="small" />
                <b style={{ textTransform: "capitalize", color: inactiveItens[theme] }}>Pix</b>
            </Line>
        )
    }
    else if (transaction.method === "mxs") return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <div style={{ display: "flex", background: secondary[theme], justifyContent: "center", alignItems: "center", minWidth: 36, maxWidth: 36, minHeight: 36, maxHeight: 36, borderRadius: "50%" }}>
                <MaxCoinSmall20 />
            </div>
            <b style={{ textTransform: "capitalize" }}>Créditos MXs</b>
        </Line>
    )
    else if (transaction.method === "banking_billet") return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <RiBarcodeFill size={20} color={inactiveItens[theme]} />
            <b style={{ textTransform: "capitalize" }}>Boleto Bancário</b>
        </Line>
    )
    else return (
        <Line style={{ alignItems: "center", gap: 5 }}>
            <TbHeartHandshake size={20} color={inactiveItens[theme]} />
            <b style={{ textTransform: "capitalize" }}>Grátis</b>
        </Line>
    )
}

const transaction_types_aux: any = {
    "increase": {
        color: success,
        rotate: 0,
        text: "Entrada"
    },
    "decrease": {
        color: fail,
        rotate: 180,
        text: "Saída"
    }
}

const hoursToMs = (hours: number) => {
    return hours * 60 * 60 * 1000
}

const correct_status = (transaction: transactionsAttributes) => {
    const createdAt = new Date(transaction?.createdAt ?? "")
    let status = transaction.status
    const atualDate = new Date()
    const diff = Math.abs(atualDate.getTime() - createdAt.getTime())
    if (diff > hoursToMs(1) && transaction.method === "pix") status = status === "waiting" ? "expired" : status
    return status
}

export const WalletRoot: React.FC = () => {
    const { api, consultsServices } = useApi()
    const { theme, notify, multiple_mxs, menuOpen, paginationLimit } = useGlobal()

    const [loading_all, set_loading_all] = useState(true)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [atual_page_extract, set_atual_page_extract] = useState(1)
    const [atual_page_history, set_atual_page_history] = useState(1)
    const [wallet, setWallet] = useState<walletAttributes | null>(null)
    const [query_selected, setQuerySelected] = useState<string | null>(null)
    const [number_of_pages_extract, set_number_of_pages_extract] = useState(1)
    const [number_of_pages_history, set_number_of_pages_history] = useState(1)
    const [franchiseIdSelected, setFranchiseIdSelected] = useState<string | null>(null)
    const [transaction_selected, setTransactionSelected] = useState<string | null>(null)
    const [loading_transactions_extract, set_loading_transactions_extract] = useState(true)
    const [loading_transactions_history, set_loading_transactions_history] = useState(true)
    const [contract_selected, setContractSelected] = useState<contractsAttributes | null>(null)
    const [transactions_extract, set_transactions_extract] = useState<transactionsAttributes[]>([])
    const [transactions_history, set_transactions_history] = useState<transactionsAttributes[]>([])

    const search = useCallback(async () => {
        if (franchiseIdSelected) {
            set_loading_all(true)
            try {
                const string = setUpConsultation({ franchise_id: franchiseIdSelected })
                const url = "/franchises/wallet?" + string
                const { data } = await api.get(url)
                setWallet(data)
                setUpdatedAt(new Date())
            } catch (err) {
                console.log(err)
                notify("Erro ao buscar assinatura da empresa!", "error")
            }
        }
        set_loading_all(false)
    }, [franchiseIdSelected])

    const onClickSeeDetails = useCallback(async (_transaction: transactionsAttributes) => {
        const transaction_type = _transaction.type
        const query_id = _transaction.query_id
        const contract = _transaction.contract
        setTransactionSelected(null)
        setQuerySelected(null)
        setContractSelected(null)
        if (transaction_type === "increase") setTransactionSelected(_transaction.id)
        else if (query_id) setQuerySelected(query_id)
        else if (contract?.id) setContractSelected(contract)
    }, [])

    const searchTransactionsExtract = useCallback(async (props: ISearchProps) => {
        const { page, franchise_id } = props
        if (franchise_id) {
            set_atual_page_extract(page as number)
            set_loading_transactions_extract(true)
            try {
                const string = setUpConsultation({ franchise_id, page, limit: paginationLimit, type: "extract" })
                const url = "/franchises/transactions?" + string
                const result = await api.get(url)

                set_transactions_extract(result.data.rows)
                set_number_of_pages_extract(Math.ceil((result.data.count / paginationLimit)))
            } catch (err) {
                notify("Erro ao buscar extrato", "error")
                console.log(err)
            }
        }
        set_loading_transactions_extract(false)
    }, [paginationLimit])

    const searchTransactionsHistory = useCallback(async (props: ISearchProps) => {
        const { page, franchise_id } = props
        if (franchise_id) {
            set_atual_page_history(page as number)
            set_loading_transactions_history(true)
            try {
                const string = setUpConsultation({ franchise_id, page, limit: paginationLimit, type: "history" })
                const url = "/franchises/transactions?" + string
                const result = await api.get(url)

                set_transactions_history(result.data.rows)
                set_number_of_pages_history(Math.ceil((result.data.count / paginationLimit)))
            } catch (err) {
                notify("Erro ao buscar histórico", "error")
                console.log(err)
            }
        }
        set_loading_transactions_history(false)
    }, [paginationLimit])

    const searchAll = useCallback(async () => {
        await Promise.all([
            searchTransactionsExtract({ page: 1, franchise_id: franchiseIdSelected }),
            searchTransactionsHistory({ page: 1, franchise_id: franchiseIdSelected }),
            search()
        ])
    }, [franchiseIdSelected])

    useEffect(() => { searchAll() }, [franchiseIdSelected])

    return (
        <DashboardPage>
            <Title theme={theme}>
                <Column>
                    <H4>Carteira de Créditos</H4>
                    <ContainerLastUpdate menuIsOpen={menuOpen}>
                        {!loading_all ?
                            <>
                                <SubTitle>Última atualização realizada em <b>{correctDateDashboard(updatedAt)}</b></SubTitle>
                                <ContainerReload onClick={() => search()}>
                                    <AiOutlineReload />
                                </ContainerReload>
                            </>
                            :
                            <>
                                <SubTitle>Atualizando dados da sua carteira de créditos</SubTitle>
                                <CircularProgress
                                    size={10}
                                    variant="indeterminate"
                                    style={{ color: secondary[theme] }}
                                />
                            </>
                        }
                    </ContainerLastUpdate>
                </Column>
                <ContainerSelectFranchise>
                    <SelectNoCreateSingleMulti
                        isRequired
                        onChange={(value: any) => setFranchiseIdSelected(value?.value ?? null)}
                        noOptionsMessage="Nenhuma imobiliária encontrada"
                        searchOptions={(search: string) => consultsServices.franchise_id({ search })}
                        icon={<BiBuildingHouse size={20} />}
                        isDisabled={loading_all}
                    />
                </ContainerSelectFranchise>
            </Title>
            {franchiseIdSelected && (
                <ContentOrLoading loading={loading_all} text="Buscando dados da carteira de créditos da imobiliária" theme={theme}>
                    <Column style={{ flex: 1, paddingTop: 20, gap: 20 }}>
                        <ContainerBalance theme={theme}>
                            <BalanceCard>
                                <ContainerIconMaxCoin>
                                    <MaxCoin size={50} />
                                </ContainerIconMaxCoin>
                                <Column style={{ color: "#FFF", justifyContent: "center", alignItems: "center" }}>
                                    <div style={{ fontSize: 30, textAlign: "center" }}>
                                        Seu saldo de MXs
                                    </div>
                                    <div style={{ fontSize: 50, fontWeight: 600 }}>
                                        {maskFunctions.mxs.mask((wallet?.balance ?? 0) * multiple_mxs)}
                                    </div>
                                    <Line style={{ gap: 5, color: "#FAAD14", alignItems: "center" }}>
                                        <b>{maskFunctions.mxs.mask((wallet?.provisioned ?? 0) * multiple_mxs)}</b>
                                        <Line style={{ alignItems: "center", gap: 5 }}>
                                            <span style={{ fontSize: 12, fontWeight: "normal" }}>provisionados</span>
                                            <Tooltip placement="top" style={{ padding: 0 }} title="Saldo reservado para Doc. Eletrônicos em aberto">
                                                <IconButton aria-label="Saldo reservado para Doc. Eletrônicos em aberto">
                                                    <FiInfo size={15} color={"#FAAD14"} />
                                                </IconButton>
                                            </Tooltip>
                                        </Line>
                                    </Line>
                                </Column>
                            </BalanceCard>

                        </ContainerBalance>
                        <Bag title="Extrato" description="Aqui estão todas suas movimentações de créditos" icon={<MdLibraryBooks color={secondary[theme]} size={26} />}>
                            <ContentOrLoadingBag theme={theme} type="wave" loading={loading_transactions_extract}>
                                <Column style={{ gap: 20, padding: 20, justifyContent: "center" }}>
                                    {transactions_extract.length > 0 &&
                                        <>
                                            <ContainerTable theme={theme}>
                                                <table cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr style={{ background: background[theme] }}>
                                                            <th style={{ padding: 10, whiteSpace: "nowrap" }}>CÓDIGO</th>
                                                            <th style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>EFETUADA EM</th>
                                                            <th style={{ padding: 10 }}>DESCRIÇÃO</th>
                                                            <th style={{ padding: 10 }}>VALOR EM CRÉDITOS</th>
                                                            <th style={{ padding: 10, whiteSpace: "nowrap" }}>TRANSAÇÃO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions_extract.map((transaction) => (
                                                            <tr style={{ background: colors[theme] }} key={transaction.id}>
                                                                <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>{transaction?.friendly_id ? `#${transaction?.friendly_id}` : "-"}</td>
                                                                <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>{correctDate(transaction.updatedAt)}</td>
                                                                <td style={{ padding: 10, whiteSpace: "nowrap" }}>
                                                                    {transaction_description(transaction)} <b onClick={() => onClickSeeDetails(transaction)} style={{ cursor: "pointer" }}>ver detalhes</b>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                    <Line style={{ alignItems: "center", gap: 5 }}>
                                                                        <MaxCoinSmall20 />
                                                                        <div>{maskFunctions.mxs.mask(transaction.value * multiple_mxs)}</div>
                                                                    </Line>
                                                                </td>
                                                                <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                    <Line style={{ alignItems: "center", gap: 5, textTransform: "capitalize" }}>
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", transform: `rotate(${transaction_types_aux[transaction.type].rotate}deg)` }}>
                                                                            <BsArrowUpShort size={22} color={transaction_types_aux[transaction.type].color} />
                                                                        </div>
                                                                        <b>{transaction_types_aux[transaction.type].text}</b>
                                                                    </Line>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </ContainerTable>
                                            <ContainerPagination>
                                                <PaginationCustom
                                                    page={atual_page_extract}
                                                    pages={number_of_pages_extract}
                                                    disable={loading_transactions_extract}
                                                    onChange={(value: any) => searchTransactionsExtract({ page: value, franchise_id: franchiseIdSelected })}
                                                />
                                            </ContainerPagination>
                                        </>
                                    }
                                    {transactions_extract.length === 0 &&
                                        <div style={{ textAlign: "center", fontStyle: "italic", opacity: 0.7 }}>Nenhuma movimentação de crédito</div>
                                    }
                                </Column>
                            </ContentOrLoadingBag>
                        </Bag>
                        <Bag title="Histórico de compras de créditos" description="Relatório de compras de créditos realizadas" icon={<MdLibraryBooks color={secondary[theme]} size={26} />}>
                            <ContentOrLoadingBag theme={theme} type="wave" loading={loading_transactions_history}>
                                <Column style={{ gap: 20, padding: 20, justifyContent: "center" }}>
                                    {transactions_history.length > 0 &&
                                        <>
                                            <ContainerTable theme={theme}>
                                                <table cellPadding={0} cellSpacing={0} style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr style={{ background: background[theme] }}>
                                                            <th style={{ padding: 10, whiteSpace: "nowrap" }}>CÓDIGO</th>
                                                            <th style={{ whiteSpace: "nowrap", padding: 10, textAlign: "center" }}>GERADA EM</th>
                                                            <th style={{ whiteSpace: "nowrap", padding: 10, textAlign: "center" }}>PAGO EM</th>
                                                            <th style={{ padding: 10 }}>PREÇO</th>
                                                            <th style={{ padding: 10, whiteSpace: "nowrap" }}>VALOR EM CRÉDITOS</th>
                                                            <th style={{ textAlign: "center", padding: 10, whiteSpace: "nowrap" }}>MÉTODO DE PAGAMENTO</th>
                                                            <th style={{ textAlign: "center", padding: 10, whiteSpace: "nowrap" }}>STATUS</th>
                                                            <th style={{ padding: 10, textAlign: "center" }}> AÇÕES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions_history.map((transaction) => (
                                                            <tr onClick={() => setTransactionSelected(transaction.id)} style={{ background: colors[theme] }} key={transaction.id}>
                                                                <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                    {transaction?.friendly_id ? `#${transaction?.friendly_id}` : "-"}
                                                                </td>
                                                                <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                                    {correctDate(transaction.createdAt)}
                                                                </td>
                                                                <td style={{ padding: 10, whiteSpace: "nowrap", textAlign: "center" }}>
                                                                    {["paid", "settled", "paid-free"].includes(correct_status(transaction)) ? correctDate(transaction.updatedAt) : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                    {maskFunctions.currency.mask(transaction.value)}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                    <Line style={{ alignItems: "center", gap: 5 }}>
                                                                        <MaxCoinSmall20 />
                                                                        <div>{maskFunctions.mxs.mask(transaction.value * multiple_mxs)}</div>
                                                                    </Line>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                    <Line style={{ alignItems: "center", gap: 5, justifyContent: "center" }}>
                                                                        {transaction_pay_method(transaction, theme)}
                                                                    </Line>
                                                                </td>
                                                                <td style={{ textAlign: "center", whiteSpace: "nowrap", padding: 10, textTransform: "uppercase" }}>
                                                                    <Line style={{ justifyContent: "center" }}>
                                                                        {getStatus({ ...status_of_transaction.increase[correct_status(transaction)], theme })}
                                                                    </Line>
                                                                </td>
                                                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                                    <Tooltip onClick={() => setTransactionSelected(transaction.id)} title={"Visualizar"} style={{ margin: 5 }}>
                                                                        <IconButton aria-label={"Visualizar"}>
                                                                            <MdOutlineRemoveRedEye color={secondary[theme]} size={20} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </ContainerTable>
                                            <ContainerPagination>
                                                <PaginationCustom
                                                    page={atual_page_history}
                                                    pages={number_of_pages_history}
                                                    disable={loading_transactions_history}
                                                    onChange={(value: any) => searchTransactionsHistory({ page: value, franchise_id: franchiseIdSelected })}
                                                />
                                            </ContainerPagination>
                                        </>
                                    }
                                    {transactions_history.length === 0 &&
                                        <div style={{ textAlign: "center", fontStyle: "italic", opacity: 0.7 }}>
                                            Nenhuma compra de crédito
                                        </div>
                                    }
                                </Column>
                            </ContentOrLoadingBag>

                        </Bag>
                    </Column>
                </ContentOrLoading>
            )}
            {!franchiseIdSelected && (
                <ContainerNoSubscription>
                    <TextNoSubscription>
                        Selecione uma imobiliária para visualizar os dados de créditos
                    </TextNoSubscription>
                </ContainerNoSubscription>
            )}
            {transaction_selected && (
                <ModalReceipt
                    transaction_id={transaction_selected}
                    onCancel={() => setTransactionSelected(null)}
                    openModal={true}
                />
            )}
            {query_selected && (
                <ModalResultQuery
                    onCancel={() => setQuerySelected(null)}
                    openModal={!!query_selected}
                    backgroundVisible={false}
                    defaultId={query_selected}
                />
            )}
            {contract_selected?.id && (
                <ModalContract
                    onCancel={() => setContractSelected(null)}
                    onSave={() => { }}
                    openModal={!!contract_selected?.id}
                    backgroundVisible={false}
                    defaultContract={contract_selected}
                />
            )}

        </DashboardPage >
    )
}
