import styled, { css } from "styled-components";
import { Column } from "../../../style";
import { IThemeProps, themeTypes } from "../../../interfaces";
import { background, inactiveItens, shadowColor, primary, borderColors, divider } from "../../../theme";

export const Container = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0.3125rem;
    min-width:80vw;
    max-width:80vw;
    min-height: calc(90 * var(--vh));
    max-height: calc(90 * var(--vh));
    outline: 0;
    box-shadow: 0.125rem 0.125rem 0.5rem ${props => shadowColor[props.theme as themeTypes]};
    ${props => css`
        background: ${background[props.theme as themeTypes]};
        color: ${inactiveItens[props.theme as themeTypes]};
    `}
`

export const ContainerForm = styled(Column)`
    flex: 1;
    overflow: auto;
    padding: 20px;
`

export const Form = styled.form<IThemeProps>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    border-radius: 5px;
    padding: 20px;
    ${props => css`
        border: 1px solid ${borderColors[props.theme as themeTypes]};
        background: ${primary[props.theme as themeTypes]};
    `}
`

export const TextInformation = styled.div`
    background: #FFF3CD;
    border-radius: 5px;
    color: #856404;
    padding: 20px;
    margin-bottom: 20px;
`

export const ContainerBottom = styled.div<IThemeProps>`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid ${props => divider[props.theme as themeTypes]};
`