import styled, { css } from "styled-components";
import { divider } from "../../../../theme";
import { IThemeProps, themeTypes } from "../../../../interfaces";

export const Column = styled.div`
    display: flex;
    min-width: 250px;
    flex-direction: column;
    flex: 1;
`

export const Form = styled.form<IThemeProps>`
    display: flex;
    justify-content:space-between;
    flex-direction: column;
    gap: 30px;
`

export const Content = styled.div`
    flex: 1;
    overflow: auto;
    gap: 20px;
    flex-direction: column;
    display: flex;
    padding:20px;
`

export const ModalFooter = styled.div<{ theme: themeTypes }>`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    ${props => css`
        border-top: 1px solid ${divider[props.theme as themeTypes]};
    `}
`

export const TextEmphasis = styled.span`
    font-weight: 600;
`