import React from "react"

export const MaxCoinSmall20 = ({ size, ...props }: any) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="#EFAA35" />
    <circle cx={10.05} cy={9.95} r={8.35} fill="#FCD634" />
    <path
      d="M10 4.1v6.702l-2.811 2.837a1.221 1.221 0 0 1-1.336.266 1.227 1.227 0 0 1-.55-.457 1.243 1.243 0 0 1-.203-.689V9.566a1.24 1.24 0 0 1 .443-.954L10 4.1ZM14.995 9.59v3.174a1.21 1.21 0 0 1-.362.873 1.251 1.251 0 0 1-.886.363H11.25a1.265 1.265 0 0 1-.883-.362 1.222 1.222 0 0 1-.366-.87v-1.942L13.386 7.5l1.162 1.146a1.235 1.235 0 0 1 .452.949l-.005-.005Z"
      fill="#EFAA35"
    />
  </svg>
)
