import React, { useCallback, useEffect, useState } from "react"
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { StatusSubscription } from "./style";
import { useGlobal } from "../../hooks/global";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { ROOT_USERS } from "../../utils/users.groups";
import { getStatus } from "../../utils/columnsTables";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { ModalFranchise } from "../../components/Modals/ModalFranchise";
import { subscription_situations } from "../../utils/subscriptionSituantions";
import { franchiseProps, IActions, IFilters, ITagList } from "../../interfaces";
import { correctDate, initialsName, setUpConsultation } from "../../services/generalServices";

const initialFilters: IFilters[] = [
    getFilterFields("state_id", ROOT_USERS),
    getFilterFields("city_id", ROOT_USERS)
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "fantasy_name", name: "Nome da imobiliária", limitWidth: true, style: { whiteSpace: "nowrap" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "plan", name: "Plano", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "plan_status", name: "Status do plano", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "users_count", name: "Usuários", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "contract_models_count", name: "Modelos", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "documents_size_count", name: "Arquivos", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "balance", name: "Saldo", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "provisioned", name: "Provisionado", style: { textAlign: "center" }, styleContent: { textAlign: "center" } }
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Nova Imobiliária", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid"
    }
]

const getNameWithPhoto: React.FC<ITagList> = ({ franchise }) => {
    if (franchise?.logo) return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 30, maxWidth: 30, maxHeight: 30, minHeight: 30, borderRadius: "50%", overflow: "hidden" }}>
                <img src={franchise?.logo} alt="Foto" style={{ minWidth: 30, minHeight: 30 }} />
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{franchise?.registration_data?.fantasy_name || null}</div>
        </div>
    )
    else return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <div style={{ display: "flex", justifyContent: "center", fontSize: 12, color: "rgb(21 21 21)", background: "rgb(206 206 206)", alignItems: "center", minWidth: 30, maxWidth: 30, minHeight: 30, maxHeight: 30, borderRadius: "50%" }}>
                {initialsName(franchise?.registration_data?.corporate_name as string)}
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{franchise?.registration_data?.fantasy_name || null}</div>
        </div>
    )
}

const getPlan = (plan: any) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <StatusSubscription background={secondary.light}>
                {plan?.name}
            </StatusSubscription>
        </div>
    )
}

const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 MB"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const FranchisesList: React.FC = () => {
    const { api } = useApi()
    const { theme, notify, paginationLimit } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [table, setTable] = useState<ITable>(initialTable)
    const [numOfFranchises, setNumOfFranchises] = useState(0)
    const [searchString, setSearchString] = useState<string>("")
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [loadingFranchises, setLoadingFranchises] = useState(true)
    const [franchise_seletected, setFranchiseSelected] = useState<franchiseProps | null>(null)

    const searchFranchises = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingFranchises(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/franchises?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_franchises = result.data.rows as franchiseProps[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_franchises.forEach((franchise) => {
                    const subscription = franchise?.subscription
                    new_table.data.push({
                        id: franchise.id,
                        fantasy_name: getNameWithPhoto({ franchise, theme }),
                        cnpj: franchise?.registration_data?.cnpj ? maskFunctions.cnpj.mask(franchise?.registration_data?.cnpj) : null,
                        city: franchise?.address?.state?.initials ? `${franchise?.address?.city?.name} - ${franchise?.address?.state?.initials}` : undefined,
                        plan: franchise?.subscription?.plan ? getPlan(franchise?.subscription?.plan) : undefined,
                        plan_status: !!subscription ? getStatus({ ...subscription_situations[subscription.situation], theme }) : undefined,
                        users_count: franchise?.wallet?.users_count,
                        contract_models_count: franchise?.wallet?.contract_models_count,
                        documents_size_count: franchise?.wallet?.documents_size_count ? formatBytes(Number.parseInt(`${franchise?.wallet?.documents_size_count}`)) : "0 MB",
                        balance: (Number.parseFloat(`${franchise?.wallet?.balance}`) < 0 ? "- " : "") + maskFunctions.currency.mask(Number.parseFloat(`${franchise?.wallet?.balance}`)),
                        provisioned: (Number.parseFloat(`${franchise?.wallet?.provisioned}`) < 0 ? "- " : "") + maskFunctions.currency.mask(Number.parseFloat(`${franchise?.wallet?.provisioned}`)),
                        created_at: correctDate(franchise?.createdAt),
                        this: franchise
                    })
                })
                return { ...new_table }
            })

            setNumOfFranchises(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelas imobiliárias!", "error")
        }
        setLoadingFranchises(false)
    }, [filters, searchString])

    const changeStatusUser = useCallback(async (franchiseId: string, old_status: "block" | "active" | null) => {
        const new_status = old_status === "active" ? "block" : "active"
        setTable((atual) => {
            const new_table = { ...atual }
            const pos = new_table.data.findIndex((franchise: any) => franchise?.this?.id === franchiseId)
            if (pos !== -1) new_table.data[pos].this.status = new_status
            return { ...new_table }
        })
        try {
            await api.put(`/franchises/${franchiseId}/status`, { status: new_status })
            notify("Status da imobiliária atualizado com sucesso.", "success")
        } catch (err) {
            notify("Erro ao alterar status da imobiliária.", "error")
            setTable((atual) => {
                const new_table = { ...atual }
                const pos = new_table.data.findIndex((franchise: any) => franchise?.this?.id === franchiseId)
                if (pos !== -1) new_table.data[pos].this.status = old_status
                return { ...new_table }
            })
        }
    }, [])

    const changeIsTrusted = useCallback(async (franchiseId: string, old_status: any) => {
        const new_status = !old_status
        setTable((atual) => {
            const new_table = { ...atual }
            const pos = new_table.data.findIndex((franchise: any) => franchise?.this?.id === franchiseId)
            if (pos !== -1) new_table.data[pos].this.is_trusted = new_status
            return { ...new_table }
        })
        try {
            await api.put(`/franchises/${franchiseId}/is_trusted`, { is_trusted: new_status })
            notify("Confiança da imobiliária atualizado com sucesso.", "success")
        } catch (err) {
            notify("Erro ao alterar confiança da imobiliária.", "error")
            setTable((atual) => {
                const new_table = { ...atual }
                const pos = new_table.data.findIndex((franchise: any) => franchise?.this?.id === franchiseId)
                if (pos !== -1) new_table.data[pos].this.is_trusted = old_status
                return { ...new_table }
            })
        }
    }, [])

    const onSaveFranchise = useCallback(() => {
        setOpenModal(false)
        searchFranchises(1)
    }, [filters, searchString])

    const selectFranchise = useCallback((franchise: franchiseProps) => {
        setFranchiseSelected({ ...franchise })
        setOpenModal(true)
    }, [])

    const openModalNewFranchise = useCallback(() => {
        setFranchiseSelected(null)
        setOpenModal(true)
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewFranchise()
        else console.log({ action_id })
    }

    useEffect(() => { searchFranchises(1) }, [])

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingFranchises}
            setLoading={setLoadingFranchises}
            numberOfItems={numOfFranchises}
            textHeader="Imobiliárias"
            textItems="imobiliárias encontradas"
            text_loading={"Buscando Imobiliárias"}
            text_empty={"Nenhuma imobiliária encontrada"}
            statusText="Status"
            theme={theme}
            search={searchFranchises}
            onChangeFilters={setFilters}
            initialFilters={initialFilters}
            table={table}
            have_status_franchise
            have_edit
            status_action={(userId: string, status: "block" | "active" | null) => changeStatusUser(userId, status)}
            edit_action={(franchise: any) => selectFranchise(franchise)}
            pages={numberOfPages}
            page={atualPage}
            onClickOption={handleAction}
            actions={actionsInitial}
            onChangeSearch={setSearchString}
            haveIsTrusted
            haveIsTrustedAction={(_id: string, is_trusted: any) => changeIsTrusted(_id, is_trusted)}
        >
            {openModal &&
                <ModalFranchise
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSaveFranchise()}
                    openModal={openModal}
                    backgroundVisible={false}
                    defaultId={franchise_seletected?.id}
                />
            }
        </PageList>
    )
}
