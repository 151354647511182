import Dropzone from "react-dropzone";
import React, {
  CSSProperties,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { fail } from "../../../theme";
import { BsXCircle } from "react-icons/bs";
import { FieldError } from "../../../style";
import { FaFileImage } from "react-icons/fa";
import { VscFilePdf } from "react-icons/vsc";
import { AiFillFileWord } from "react-icons/ai";
import { useGlobal } from "../../../hooks/global";
import { Progress } from "../../Loadings/Progress";
import { fileProps } from "../../../interfaces/index";
import { IconButton, Tooltip } from "@material-ui/core";
import { Container, DropContainer, DropContent, UploadMessage } from "./style";

export interface IUploadRefProps {
  focus: () => void;
}

interface IUploadProps {
  onUpload: any;
  styleContainer?: CSSProperties;
  file: fileProps;
  uploadProgress?: number;
  height?: number;
  noLoading?: boolean;
  isRequired?: boolean;
  fileSizeMax?: number;
  fileTypes?: string[];
  error?: any;
}

const tooltipMessage = (fileTypes: string[]) => {
  if (!fileTypes || fileTypes.length === 0) return "Nenhum arquivo suportado";
  if (fileTypes?.length === 1) return fileTypes[0].toUpperCase();
  else
    return (
      fileTypes
        .slice(0, fileTypes.length - 1)
        .map((item) => item.toUpperCase())
        .join(", ") +
      " ou " +
      fileTypes[fileTypes.length - 1].toUpperCase()
    );
};

const correct_accept = (fileTypes: string[]) => {
  return fileTypes.join(", ");
};

const correctFileTypesMimes: any = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  tiff: "image/tiff",
  webp: "image/webp",
  svg: "image/svg+xml",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  txt: "text/plain",
};

const UploadArchivesComponent: React.ForwardRefRenderFunction<
  IUploadRefProps,
  IUploadProps
> = (props, ref) => {
  const { onUpload, uploadProgress = -1, file, error } = props;
  const {
    height = 40,
    fileSizeMax = 10,
    noLoading = false,
    styleContainer = {},
  } = props;
  const {
    fileTypes = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "tiff",
      "webp",
      "svg",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "txt",
    ],
  } = props;

  const mimeTypes = fileTypes.map((item) => correctFileTypesMimes[item]);

  const [isDragReject, setIsDragReject] = useState(false);

  const dropzone_ref = useRef<any>(null);

  const { theme } = useGlobal();

  const whatIsTheExtension = (fileName: string) => {
    if (!fileName) return <FaFileImage style={{ marginRight: 10 }} size={20} />;
    const fileName_array = fileName.split(".");
    const extension = fileName_array[fileName_array.length - 1];
    if (extension === "pdf")
      return <VscFilePdf style={{ marginRight: 10 }} size={20} />;
    else if (["docx", "doc"].includes(extension))
      return <AiFillFileWord style={{ marginRight: 10 }} size={20} />;
    else return <FaFileImage style={{ marginRight: 10 }} size={20} />;
  };

  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      if (!file.preview) {
        return (
          <UploadMessage>
            <div
              style={{
                fontSize: 12,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Selecione um arquivo
            </div>
          </UploadMessage>
        );
      } else {
        return (
          <UploadMessage color="#000">
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              {whatIsTheExtension(file.name)}
              <div
                style={{
                  fontSize: 12,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 150,
                }}
              >
                {file.name}
              </div>
            </div>
          </UploadMessage>
        );
      }
    }
    if (isDragReject) {
      return (
        <UploadMessage>
          <BsXCircle size={20} color={fail} />
        </UploadMessage>
      );
    }
    return <UploadMessage type="success">Solte</UploadMessage>;
  };

  const focus = useCallback(() => {
    dropzone_ref?.current?.open();
  }, [dropzone_ref]);

  useImperativeHandle(ref, () => ({ focus }));

  return (
    <Container>
      <Tooltip
        placement="bottom"
        style={{
          margin: 0,
          padding: 0,
          borderRadius: 4,
          display: "flex",
          flex: 1,
        }}
        title={`Envie documentos de até ${fileSizeMax}MB nas seguintes extensões: ${tooltipMessage(
          fileTypes
        )}`}
      >
        <IconButton
          style={{
            margin: 0,
            padding: 0,
            borderRadius: 4,
            display: "flex",
            flex: 1,
          }}
          aria-label={`Envie documentos de até ${fileSizeMax}MB nas seguintes extensões: ${tooltipMessage(
            fileTypes
          )}`}
        >
          <Dropzone
            accept={correct_accept(mimeTypes)}
            onDropAccepted={onUpload}
            maxFiles={1}
            ref={dropzone_ref}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
              setIsDragReject(!!isDragReject);
              return (
                <DropContainer>
                  <DropContent
                    {...getRootProps()}
                    theme={theme}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    isDragError={!!error}
                    style={{
                      ...styleContainer,
                      display: "flex",
                      height,
                      width: "100%",
                    }}
                    aria-required
                    children={
                      <>
                        <input {...getInputProps()} />
                        {renderDragMessage(isDragActive, isDragReject)}
                        {!noLoading && file.preview && (
                          <Progress
                            isFinished={uploadProgress === -1}
                            porcen={uploadProgress}
                          />
                        )}
                      </>
                    }
                  />
                </DropContainer>
              );
            }}
          </Dropzone>
        </IconButton>
      </Tooltip>
      {error && !isDragReject && <FieldError title={error}>{error}</FieldError>}
      {isDragReject && (
        <FieldError title={"Arquivo não suportado"}>
          Arquivo não suportado
        </FieldError>
      )}
    </Container>
  );
};

export const UploadArchives = forwardRef(UploadArchivesComponent);
