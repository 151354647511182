import Button from "../../Buttons/Button";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useApi } from "../../../hooks/api";
import { ModalHeader } from "../../ModalHeader";
import { useGlobal } from "../../../hooks/global";
import { ModalGeneric } from "../../ModalGeneric";
import { DefineQuantity } from "./steps/DefineQuantity";
import { inactiveItens, secondary } from "../../../theme";
import { personsTypesOptions } from "../../../utils/options";
import { Container, ContainerBottom, Content } from "./style";
import { maskFunctions } from "../../../services/maskServices";
import { ContentOrLoading } from "../../Loadings/ContentOrLoading";
import { DefinePayment, DefinePaymentRefProps } from "./steps/DefinePayment";
import { plansAttributes, subscriptionsAttributes } from "../../../interfaces";
import { addError, correctDate2, removeError } from "../../../services/generalServices";

interface IModalTransactionProps {
    onCancel: Function
    onSave: Function
    openModal: boolean
    backgroundVisible?: boolean
    subscription?: subscriptionsAttributes | null
}

export const ModalTransaction: React.FC<IModalTransactionProps> = (props) => {
    const { onCancel, onSave, openModal, subscription, backgroundVisible } = props

    const { user, api } = useApi()
    const { theme, notify, franchise, gerencianet_min_credits } = useGlobal()

    const defaultBillingData = {
        ...user.person?.registration_data,
        birth: correctDate2(user.person?.registration_data?.birth),
        email: user.email,
        cnpj: maskFunctions.cnpj.mask(franchise.registration_data?.cnpj),
        corporate_name: franchise.registration_data?.corporate_name,
        phone_number: maskFunctions.phone.mask(user.person?.registration_data?.phone),
        cpf: maskFunctions.cpf.mask(user.person?.registration_data?.cpf),
        ...user.person?.address,
        city_id: user.person?.address?.city?.id ? {
            value: user.person?.address?.city.id,
            label: user.person?.address?.city.name,
            this: user.person?.address?.city
        } : null,
        state_id: user.person?.address?.state?.id ? {
            value: user.person?.address?.state.id,
            label: user.person?.address?.state.name,
            this: user.person?.address?.state
        } : null,
        cep: maskFunctions.cep.mask(user.person?.address?.cep),
        type: personsTypesOptions.find((item) => item.value === "PF")
    } as any

    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(true)
    const [quantity, setQuantity] = React.useState("0")
    const [plans, setPlans] = useState<plansAttributes[]>([])

    const buttonSubmitRef = useRef<HTMLButtonElement>(null)
    const paymentFormRef = useRef<DefinePaymentRefProps>(null)

    const searchPlans = useCallback(async () => {

        setLoading(true)

        try {

            const { data } = await api.get("/plans")

            setPlans(data.rows)

        } catch (err) {

            notify("Erro ao buscar planos!", "error")

        }

        setLoading(false)

    }, [])

    const clickSave = useCallback(async (apiError = null) => {
        if (step === 1) {
            if (apiError === null) {
                const quantity_number = Number.parseInt(quantity)
                if (quantity_number < gerencianet_min_credits) {
                    addError("quantity-mxs", `Quantidade mínima de créditos é ${gerencianet_min_credits} MXs`)
                }
            }
            else addError(`${apiError.path}_input`, apiError.message)
            buttonSubmitRef.current?.click()
        }
        else paymentFormRef.current?.forceSubmit()
    }, [buttonSubmitRef, paymentFormRef, step, quantity, gerencianet_min_credits])

    const handleSubmitStepOne = useCallback(async (e) => {
        e?.preventDefault()
        setStep(2)
    }, [])

    const onKeyDown = useCallback((event) => {
        if (event.key === "Enter") event.preventDefault()
        else removeError("quantity-mxs")
    }, [])

    useEffect(() => { searchPlans() }, [])

    return (
        <ModalGeneric backgroundVisible={backgroundVisible} open={openModal} onClose={() => { }}>
            <Container theme={theme}>

                <ModalHeader
                    title={"Comprar créditos"}
                    subTitle={"Selecione o quanto de créditos deseja comprar e a forma de pagamento"}
                    onCancel={() => onCancel()}
                    theme={theme}
                />

                <ContentOrLoading theme={theme} loading={loading} type="wave">
                    <Content>
                        <form onKeyDown={(e) => onKeyDown(e)} onSubmit={handleSubmitStepOne} style={{ display: step === 1 ? "flex" : "none" }}>
                            <DefineQuantity
                                plan_selected={subscription?.plan_id}
                                plans={plans}
                                theme={theme}
                                quantity={quantity}
                                setQuantity={setQuantity}
                            />
                            <button
                                ref={buttonSubmitRef}
                                type="submit"
                                style={{ display: "none" }}
                            />
                        </form>
                        <div style={{ display: step === 2 ? "flex" : "none" }}>
                            <DefinePayment
                                onSucess={() => onSave()}
                                goBack={() => setStep(1)}
                                quantity={Number.parseInt(quantity)}
                                theme={theme}
                                default_data={defaultBillingData}
                                ref={paymentFormRef}
                            />
                        </div>
                    </Content>

                    <ContainerBottom theme={theme}>
                        <div style={{ height: "100%", flex: window.innerWidth <= 587 ? 1 : undefined }}>
                            <Button
                                onClick={() => step === 1 ? onCancel() : setStep((atual) => atual - 1)}
                                background={inactiveItens[theme]}
                                color={"#FFF"}
                                style={{ padding: 8, minWidth: 210, fontSize: 14, alignItems: "center" }}
                                children="Voltar"
                            />
                        </div>
                        <div style={{ height: "100%", flex: window.innerWidth <= 587 ? 1 : undefined }}>
                            <Button
                                onClick={() => clickSave()}
                                background={secondary[theme]}
                                color={"#FFF"}
                                style={{ padding: 8, minWidth: 210, fontSize: 14 }}
                                children={step === 1 ? "Próximo" : "Confirmar pagamento"}
                            />
                        </div>
                    </ContainerBottom>
                </ContentOrLoading>
            </Container>

        </ModalGeneric>
    )
}