import styled from "styled-components";
import { Column } from "../../../style";
import { background, inactiveItens } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const Container = styled(Column) <IThemeProps>`
    border-radius: 5px;
    min-width: 30vw;
    min-height: 400px;
    background: ${props => background[props.theme as themeTypes]};
    color: ${props => inactiveItens[props.theme as themeTypes]};
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
    flex: 1;
`