import React, { useCallback, useEffect, useState } from "react"
import { secondary } from "../../theme";
import { useApi } from "../../hooks/api";
import { useGlobal } from "../../hooks/global";
import { AiOutlineSwap } from "react-icons/ai";
import { ITable } from "../../components/TableList";
import { PageList } from "../../components/PageList";
import { IoAddCircleOutline } from "react-icons/io5";
import { getFilterFields } from "../../utils/filterFields";
import { maskFunctions } from "../../services/maskServices";
import { proposal_status } from "../../utils/proposal_status";
import { ModalConsults } from "../../components/Modals/ModalConsults";
import { ModalLoading } from "../../components/Loadings/ModalLoading";
import { ModalProposal } from "../../components/Modals/ModalProposal";
import { ModalChangeAnalyst } from "../../components/Modals/ModalChangeAnalyst";
import { correctDate, setUpConsultation } from "../../services/generalServices";
import { IActions, IFilters, proposalsAttributes } from "../../interfaces/index";
import { ALL_USERS, NO_ROOT_NO_ANALYST_USERS, ROOT_USERS, STAFF_USERS } from "../../utils/users.groups";
import { ModalConfirmAction } from "../../components/Modals/ModalProposal/steps/Analysis/ModalConfirmAction";
import { getAnalyst, getBroker, getFranchise, getIndicator, getOwners, getStatus, getTenants } from "../../utils/columnsTables";

const initialFilters: IFilters[] = [
    getFilterFields("is_managed", ALL_USERS),
    getFilterFields('warranty_type', ALL_USERS),
    getFilterFields("franchise_id", ROOT_USERS),
    getFilterFields("broker_id", STAFF_USERS),
    getFilterFields("analyst_id", NO_ROOT_NO_ANALYST_USERS),
    getFilterFields("proposal_status", ALL_USERS),
    getFilterFields("date_range", ALL_USERS),
]

const initialTable: ITable = {
    data: [],
    columns: [
        { attr: "indicator", name: "", style: { padding: 0 }, styleContent: { padding: 0 } },
        { attr: "friendly_id", name: "Código", style: { whiteSpace: "nowrap" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "tenants", name: "Locatário(s)", style: {}, styleContent: {} },
        { attr: "owners", name: "Proprietário(s)", style: {}, styleContent: {} },
        { attr: "broker", name: "Corretor(a)", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "analyst", name: "Analista", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "status", name: "Status", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "rent_value", name: "Aluguel", style: { textAlign: "center" }, styleContent: { fontSize: 14, fontWeight: 600, textAlign: "center" } },
        { attr: "updatedAt", name: "Última interação", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } },
        { attr: "createdAt", name: "Abertura", style: { textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } },
    ]
}

const initialTableRoot: ITable = {
    data: [],
    columns: [
        { attr: "indicator", name: "", style: { padding: 0 }, styleContent: { padding: 0 } },
        { attr: "friendly_id", name: "Código", style: { whiteSpace: "nowrap" }, styleContent: { fontSize: 16, fontWeight: 600 } },
        { attr: "tenants", name: "Locatário(s)", style: {}, styleContent: {} },
        { attr: "owners", name: "Proprietário(s)", style: {}, styleContent: {} },
        { attr: "broker", name: "Corretor(a)", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "franchise", name: "Imobiliária", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "status", name: "Status", style: { textAlign: "center" }, styleContent: { textAlign: "center" } },
        { attr: "rent_value", name: "Aluguel", style: { textAlign: "center" }, styleContent: { fontSize: 14, fontWeight: 600, textAlign: "center" } },
        { attr: "updatedAt", name: "Última interação", style: { whiteSpace: "nowrap", textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } },
        { attr: "createdAt", name: "Abertura", style: { textAlign: "center" }, styleContent: { fontSize: 14, textAlign: "center" } },
    ]
}

const actionsInitial: IActions[] = [
    {
        main_option: { text: "Nova Proposta", id: "new_item" },
        className: "new_item",
        icon: <IoAddCircleOutline size={20} className="new_item" />,
        options: [],
        type: "solid",
        canAccess: ["broker", "manager", "responsible"]
    }
]

export const ProposalList: React.FC = () => {
    const { user, api } = useApi()
    const { theme, paginationLimit, notify, franchise } = useGlobal()

    const [atualPage, setAtualPage] = useState(1)
    const [filters, setFilters] = useState<any>({})
    const [openModal, setOpenModal] = useState(false)
    const [updatedAt, setUpdatedAt] = useState(new Date())
    const [numOfProposals, setNumOfProposals] = useState(0)
    const [searchString, setSearchString] = useState<string>("")
    const [loading_cancel, set_loading_cancel] = useState(false)
    const [numberOfPages, setNumberOfPages] = useState<number>(1)
    const [loadingProposals, setLoadingProposals] = useState(true)
    const [proposalselected, setProposalselected] = useState<proposalsAttributes | null>(null)
    const [modalConfirmType, setModalConfirmType] = useState<"PA" | "AP" | "PR" | "PC" | "PEA" | null>(null)
    const [table, setTable] = useState<ITable>(user.type === "root" ? initialTableRoot : initialTable)
    const [proposalSelectToChangeAnalyst, setProposalSelectToChangeAnalyst] = useState<string | null>(null)

    const searchProposals = useCallback(async (page, _filters = null) => {
        setAtualPage(page)
        setLoadingProposals(true)
        try {
            const filters_obj = _filters ? _filters : { ...filters, search: searchString }

            let period: any = {}
            period.date_init = filters_obj.date_init ? filters_obj.date_init : null
            period.date_end = filters_obj.date_end ? filters_obj.date_end : null
            if (!period.date_init || !period.date_end) period = undefined
            else period = JSON.stringify([period.date_init, period.date_end])

            filters_obj.period = period

            const string = setUpConsultation(filters_obj)
            const url = `/generic_searches/proposals?page=${page}&limit=${paginationLimit}&${string}`

            const result = await api.get(url)

            const new_proposals = result.data.rows as proposalsAttributes[]

            setTable((atual) => {
                const new_table = { ...atual }
                new_table.data = []
                new_proposals.forEach((proposal) => {
                    new_table.data.push({
                        id: proposal.id,
                        indicator: getIndicator({ proposal, theme }),
                        friendly_id: `#${proposal.friendly_id}`,
                        tenants: getTenants({ proposal, theme }),
                        owners: getOwners({ proposal, theme }),
                        broker: getBroker({ person: proposal, theme }),
                        analyst: getAnalyst({ proposal, theme }),
                        status: getStatus({ ...proposal_status[proposal.status], theme }),
                        franchise: getFranchise({ person: proposal, theme }),
                        rent_value: proposal.contractorData.rent_value ? maskFunctions.currency.mask(proposal.contractorData.rent_value) : "R$ 0,00",
                        createdAt: correctDate(proposal.createdAt),
                        updatedAt: correctDate(proposal.updatedAt),
                        this: proposal
                    })
                })
                return { ...new_table }
            })

            setNumOfProposals(result.data.count)
            setNumberOfPages(Math.ceil((result.data.count / paginationLimit)))
            setUpdatedAt(new Date())
        } catch (err) {
            notify("Erro na busca pelas propostas!", "error")
        }
        setLoadingProposals(false)
    }, [api, franchise, filters, searchString])

    const onSaveProposal = useCallback(() => {
        setOpenModal(false)
        searchProposals(1)
    }, [api, franchise, filters, searchString])

    const selectProposal = useCallback((Proposal: proposalsAttributes) => {
        setProposalselected({ ...Proposal })
        setOpenModal(true)
    }, [])

    const openModalNewProposal = useCallback(() => {
        setProposalselected(null)
        setOpenModal(true)
    }, [])

    const getQueryProposalId = useCallback(async () => {
        const query = window.location.search.split("?").pop()
        const queries = query ? query.split("&") : [""]
        let proposalId: any = queries.find((item: string) => item.split("=")[0] === "proposalId")
        proposalId = proposalId ? proposalId.split("=")[1] : null
        if (proposalId) {
            const result = await api.get(`/proposals/${proposalId}`)
            setProposalselected({ ...result.data })
            setOpenModal(true)
        }
    }, [])

    const handleAction = (action_id: string) => {
        if (action_id === "new_item") openModalNewProposal()
        else console.log({ action_id })
    }

    const changeStatusProposal = useCallback(async ({ type, reason }) => {
        set_loading_cancel(true)
        try {
            const proposal_id = proposalselected?.id
            setModalConfirmType(null)
            setProposalselected(null)
            await api.post(`/proposals/actionInProposal/${proposal_id}`, { new_status: type, reason })
            searchProposals(1)
        } catch (err: any) {
            const error = err?.response ? err?.response?.data : "SERVER ERROR"
            if (error !== "SERVER ERROR") notify(error.message, "error")
            else notify("Erro ao mudar status da proposta!", "error")
        }
        set_loading_cancel(false)
    }, [proposalselected, api, franchise, filters, searchString])

    const handleCancelProposal = useCallback(async (proposal: proposalsAttributes) => {
        setModalConfirmType("PC")
        setProposalselected({ ...proposal })
    }, [])

    const handleUnCancelProposal = useCallback(async (proposal: proposalsAttributes) => {
        setModalConfirmType(proposal.analyst_id ? "PEA" : "AP")
        setProposalselected({ ...proposal })
    }, [])

    useEffect(() => {
        getQueryProposalId().then(() => { searchProposals(1) })
    }, [])

    const handleCloseProposal = useCallback(async () => {
        setOpenModal(false)
        searchProposals(atualPage)
    }, [api, franchise, filters, searchString, atualPage])

    const listAction = [
        {
            title: "Alterar titularidade",
            onClick: (item: any) => setProposalSelectToChangeAnalyst(item?.id),
            icon: (
                <AiOutlineSwap
                    color={secondary[theme]}
                    size={20}
                />
            ),
            disabled: (item: any) => !["responsible", "manager"].includes(user.type)
        }
    ]

    return (
        <PageList
            updatedAt={updatedAt}
            loading={loadingProposals}
            setLoading={setLoadingProposals}
            numberOfItems={numOfProposals}
            textHeader="Propostas"
            textItems="propostas encontradas"
            theme={theme}
            search={searchProposals}
            initialFilters={initialFilters}
            onChangeFilters={setFilters}
            onClickOption={handleAction}
            pages={numberOfPages}
            page={atualPage}
            table={table}
            actions={actionsInitial}
            have_edit
            have_cancel={user.type !== "root"}
            have_rollback={user.type !== "root"}
            canRollback={(proposal: proposalsAttributes) => ["PC"].includes(proposal.status)}
            canCancel={(proposal: proposalsAttributes) => !["PR", "PC", "CF"].includes(proposal.status)}
            edit_action={(proposal: any) => selectProposal(proposal)}
            cancel_action={handleCancelProposal}
            rollback_action={handleUnCancelProposal}
            text_loading={"Buscando Propostas"}
            text_empty={"Nenhuma proposta encontrada"}
            onChangeSearch={setSearchString}
            listActions={listAction}
        >
            {(openModal && !proposalselected?.id) && (
                <ModalConsults
                    defaultData={proposalselected}
                    onCancel={() => setOpenModal(false)}
                    onSave={() => onSaveProposal()}
                />
            )}
            {(openModal && !!proposalselected?.id) && (
                <ModalProposal
                    defaultId={proposalselected?.id}
                    onCancel={handleCloseProposal}
                    openModal={openModal && !!proposalselected?.id}
                    friendlyId={proposalselected?.friendly_id}
                />
            )}
            {!!modalConfirmType && (
                <ModalConfirmAction
                    onConfirm={changeStatusProposal}
                    onCancel={() => setModalConfirmType(null)}
                    open={!!modalConfirmType}
                    type={modalConfirmType}
                    friendly_id={proposalselected?.friendly_id}
                />
            )}
            {loading_cancel && (
                <ModalLoading
                    loading={loading_cancel}
                    theme={theme}
                />
            )}
            {proposalSelectToChangeAnalyst && (
                <ModalChangeAnalyst
                    defaultId={proposalSelectToChangeAnalyst}
                    openModal
                    onSave={() => {
                        setProposalSelectToChangeAnalyst(null)
                        searchProposals(1)
                    }}
                    onCancel={() => setProposalSelectToChangeAnalyst(null)}
                />
            )}
        </PageList>
    )
}
