import styled, { css } from "styled-components";
import { primary } from "../../../theme";
import { IThemeProps, themeTypes } from "../../../interfaces";

export const Form = styled.form<IThemeProps>`
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: space-between;
    gap:30px;
    border-radius: 4px;
    padding: 30px;
    padding-top: 10px;
    box-shadow: 0.125rem 0.125rem 0.5rem #57545419;
    ${props => css`
        background-color: ${primary[props.theme as themeTypes]};
    `}
`