import React from "react";
import { useApi } from "../hooks/api";
import { useGlobal } from "../hooks/global";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRouteProps {
    canAcess: ("root" | "broker" | "responsible" | "customer" | "analyst" | "manager")[]
    chooseFranchise?: boolean
    redirectTo?: string
}

const thisUserCanSee = (type: string, array: string[]) => {
    for (let i = 0; i < array.length; i++) if (array[i] === type) return true
    return false
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ canAcess, chooseFranchise, redirectTo = "/" }) => {
    const { user } = useApi()
    const { franchise } = useGlobal()

    if (thisUserCanSee(user.type, canAcess)) {
        if (user.type === "responsible") {
            if (franchise.id && !chooseFranchise) return <Outlet/>
            else if (franchise.id && chooseFranchise) return <Outlet/>
            else if (!franchise.id && chooseFranchise) return <Outlet/>
            else return <Navigate to="/choose_the_real_estate" />
        }
        else return <Outlet/>
    }
    else return <Navigate to={redirectTo} />
}
