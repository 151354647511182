import styled from "styled-components";

export const ContainerSelect = styled.div`
    min-width: 350px;
    max-width: 350px;
    display: flex;
    height: 38px;
    div:first-child{
        width: 100%;
    }
`

export const ContainerCustomSelect = styled.div`
    display: flex;
    min-width: 350px;
    max-width: 350px;
    position:relative;
    flex-direction:column;
`