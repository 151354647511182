import React from "react"
import { RiMedalFill } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { inactiveItens, primary } from "../../../../theme";
import { DashboardCard } from "../DashboardCards/DashboardCard";
import { maskFunctions } from "../../../../services/maskServices";
import { IPersonRanking, themeTypes } from "../../../../interfaces";
import { valueIsEmpty } from "../../../../services/generalServices";
import { ContainerLogo, ContainerLogoDefault, Content, Img, TD, TH } from "./style";

interface IRankingPersons {
    data: IPersonRanking[]
    title: string
    minWidth: number
    theme: themeTypes
    maxWidth: string
}

const personValue = (person_ranking: IPersonRanking[], pos: number, attribute: string) => {
    if (attribute === "total_value") {
        return person_ranking.length >= (pos + 1) ? (person_ranking[pos][attribute] === "0" ? "R$ 0,00" : maskFunctions.currency.mask(person_ranking[pos][attribute])) : "-"
    }
    return person_ranking.length >= (pos + 1) ? person_ranking[pos][attribute] : "-"
}

const colors_podium: any = {
    0: "#FFD800",
    1: "#CBCCCC",
    2: "#BD7B65"
}

const aux = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

const columns = [
    { name: "Nome", attribute: "name", className: "cursor-default" },
    { name: "Volume de locação", attribute: "total_value", className: "text-center cursor-default j-center" },
    { name: "Quantidade", attribute: "qnt", className: "text-center cursor-default j-center" }
]

export const RankingPersons: React.FC<IRankingPersons> = (props) => {
    const { data, title, minWidth, theme, maxWidth } = props

    return (
        <DashboardCard minWidth={minWidth} title={title} theme={theme}>
            <Content maxWidth={maxWidth} theme={theme}>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <TH className="text-center"></TH>
                            <TH></TH>
                            {columns.map(({ attribute, className, name }) => (
                                <TH key={attribute} className={className}>
                                    <div className={className} style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ position: "relative" }}>
                                            {name}
                                        </div>
                                    </div>
                                </TH>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {aux.map((pos) => {
                            if (personValue(data, pos, "total_value") !== "R$ 0,00") {
                                return (
                                    <tr key={data?.[pos]?.name} style={{ background: pos % 2 === 0 ? theme === "light" ? "rgb(241, 242, 243)" : inactiveItens["light"] : primary[theme], fontWeight: pos <= 2 ? 600 : "normal" }}>
                                        <TD className="text-center">
                                            <div>
                                                {pos <= 2 ? <RiMedalFill color={colors_podium[pos]} size={20} /> : `${pos + 1}º`}
                                            </div>
                                        </TD>
                                        <TD>
                                            <ContainerLogo theme={theme}>
                                                {data?.[pos]?.photo ?
                                                    <Img src={data?.[pos]?.photo} />
                                                    :
                                                    <ContainerLogoDefault>
                                                        <FaUserCircle size={30} />
                                                    </ContainerLogoDefault>
                                                }
                                            </ContainerLogo>
                                        </TD>
                                        {columns.map(({ attribute, className }) => (
                                            <TD key={attribute} className={className + (valueIsEmpty(data?.[pos]?.[attribute]) ? " empty" : "")}>
                                                {personValue(data, pos, attribute)}
                                            </TD>
                                        ))}
                                    </tr>
                                )
                            }
                            else return (
                                <tr style={{ background: pos % 2 === 0 ? theme === "light" ? "rgb(241, 242, 243)" : inactiveItens["light"] : primary[theme], fontWeight: pos <= 2 ? 600 : "normal" }}>
                                    <TD style={{ textAlign: "center" }}>
                                        <div>
                                            {pos <= 2 ? <RiMedalFill color={colors_podium[pos]} size={20} /> : `${pos + 1}º`}
                                        </div>
                                    </TD>
                                    <TD>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minWidth: 30, maxWidth: 30, maxHeight: 30, minHeight: 30, borderRadius: "50%" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <FaUserCircle size={30} />
                                            </div>
                                        </div>
                                    </TD>
                                    <TD> - </TD>
                                    <TD> - </TD>
                                    <TD style={{ textAlign: "center" }}> - </TD>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Content>
        </DashboardCard>
    )
}