import Button, { ButtonRefProps } from "../Button";
import React, { useCallback, useEffect } from "react";
import { Line } from "../../../style";
import { themeTypes } from "../../../interfaces";
import { useGlobal } from "../../../hooks/global";
import { CSSProperties } from "styled-components";
import { inactiveItens, primary, secondary } from "../../../theme";
import { ContainerDropItems, ContainerIcon, Content, IconArrow, ItemDropdown } from "./style";

interface IDropdownButton {
    loading: boolean
    theme: themeTypes
    main_option: any
    options?: any[]
    onClick: (option: string) => void
    icon: any
    type?: "outline" | "solid"
    style?: CSSProperties
    className: string
    selects_number?: number
    zIndex?: number
}

const colors: any = {
    "outline": {
        "dark": inactiveItens.dark,
        "light": inactiveItens.light
    },
    "solid": {
        "dark": "#FFF",
        "light": "#FFF"
    }
}

const borders: any = {
    "outline": {
        "dark": inactiveItens.dark,
        "light": inactiveItens.light
    },
    "solid": {
        "dark": secondary.dark,
        "light": secondary.light
    }
}

export const DropdownButton: React.FC<IDropdownButton> = (data) => {
    const { loading, className, style = {}, theme, onClick, main_option, icon, selects_number = 0, options = [], type = "solid", zIndex = 999 } = data

    const { openSelect } = useGlobal()

    const contentRef = React.useRef<ButtonRefProps>(null)
    const divRef = React.useRef<HTMLDivElement>(null)

    const getMinWidth = useCallback(() => {
        return contentRef?.current?.getWidth() ?? 0
    }, [contentRef])

    useEffect(() => {
        const content = divRef.current
        const paths = content?.querySelectorAll("path")

        if (!paths) return

        for (let i = 0; i < paths?.length; i++) paths[i].classList.add(className)
    }, [])

    return (
        <div ref={divRef}>
            <Button
                disabled={loading}
                borderColor={borders[type][theme]}
                background={type === "outline" ? primary[theme] : secondary[theme]}
                color={colors[type][theme]}
                style={{ padding: 0, alignItems: "center", position: "relative", ...style }}
                className={className}
                ref={contentRef}
            >
                <Content className={className} onClick={options.length > 0 ? () => { } : () => onClick(main_option.id)}>
                    <Line className={className} style={{ gap: 5, fontWeight: "600 !important", whiteSpace: "nowrap", alignItems: "center" }}>
                        <ContainerIcon className={className}>
                            {icon}
                        </ContainerIcon>
                        {selects_number > 0 && (selects_number > 99 ? "(+99)" : `(${selects_number})`)} {main_option.text}
                    </Line>
                    {options.length > 0 &&
                        <IconArrow
                            size={18}
                            color={colors[type][theme]}
                            open={openSelect === className}
                            theme={theme}
                            className={className}
                        />
                    }
                </Content>
                {openSelect === className && options.length > 0 &&
                    <ContainerDropItems zIndex={zIndex} minWidth={getMinWidth()} theme={theme} color={colors["outline"][theme]} background={primary[theme]} borderColor={colors[type][theme]}>
                        <div style={{ display: "flex", flexDirection: "column", whiteSpace: "nowrap" }}>
                            {options.map((option) => (
                                <ItemDropdown onClick={() => onClick(option.id)} theme={theme}>
                                    <ContainerIcon>
                                        {option.icon ? option.icon : icon}
                                    </ContainerIcon>
                                    {option.text}
                                </ItemDropdown>
                            ))}
                        </div>
                    </ContainerDropItems>
                }
            </Button>
        </div >
    )
}