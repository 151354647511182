import { inactiveItens } from "../theme";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdDateRange, MdSearch } from "react-icons/md";
import { FaRegBuilding, FaUserTie } from "react-icons/fa";
import { BiBuildingHouse, BiUser, BiUserCircle } from "react-icons/bi";
import { RiHashtag, RiShareFill, RiShieldUserFill } from "react-icons/ri";

export const logos: any = {
    cep: "i-Map-Marker",
    cpf: "i-ID-Card",
    creci: "i-ID-Card",
    cnpj: "i-ID-Card",
    cpfOrCnpj: "i-ID-Card",
    name: "i-Male",
    value_min: "i-Dollar",
    value_max: "i-Dollar",
    public_place: "i-Map-Marker",
    number: "i-Map-Marker",
    district: "i-Map-Marker",
    city_id: "i-Map-Marker",
    state_id: "i-Map-Marker"
}

export const iconComponents: any = {
    type_consult: <MdSearch size={19} color={inactiveItens["dark"]} />,
    property_type_id: <BiBuildingHouse size={19} color={inactiveItens["dark"]} />,
    owner_id: <BiUserCircle size={19} color={inactiveItens["dark"]} />,
    person_id: <FaUserTie size={19} color={inactiveItens["dark"]} />,
    signatory_id: <FaUserTie size={19} color={inactiveItens["dark"]} />,
    broker_id: <FaUserTie size={19} color={inactiveItens["dark"]} />,
    analyst_id: <RiShieldUserFill size={19} color={inactiveItens["dark"]} />,
    tenant_id: <BiUser size={19} color={inactiveItens["dark"]} />,
    id: <RiHashtag size={19} color={inactiveItens["dark"]} />,
    friendly_id: <RiHashtag size={19} color={inactiveItens["dark"]} />,
    status: <IoPricetagsOutline size={19} color={inactiveItens["dark"]} />,
    date_init: <MdDateRange size={19} color={inactiveItens["dark"]} />,
    date_end: <MdDateRange size={19} color={inactiveItens["dark"]} />,
    signatory: <FaUserTie size={19} color={inactiveItens["dark"]} />,
    bond_type: <RiShareFill size={19} color={inactiveItens["dark"]} />,
    franchise_id: <FaRegBuilding size={19} color={inactiveItens["dark"]} />
}