import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { IOptionsProps } from "../../../../interfaces";
import { FixRequiredSelect, IFixRequiredSelectProps, ISelectRefProps } from "../FixRequiredSelect";

const debounceEvent = () => {
    let timer: any = null
    return (fn: any, wait: any) => {
        clearTimeout(timer)
        timer = setTimeout(() => fn(), wait)
    }
}

const debounce = debounceEvent()

export interface SelectNoCreateSingleRefProps {
    getValue: () => any
    cancelLoading: () => void
}

interface SelectNoCreateSingleProps extends IFixRequiredSelectProps {
    searchOptions: Function
    defaultValue?: IOptionsProps
    hasIcon?: boolean
    icon?: any
}

const SelectNoCreateSingleComponent: React.ForwardRefRenderFunction<SelectNoCreateSingleRefProps, SelectNoCreateSingleProps> = (props, ref) => {
    const { searchOptions, error, isClearable, hasIcon = false, onFocus = () => { }, onChange = () => { }, isRequired = false, isDisabled = false, noOptionsMessage, defaultValue } = props

    const [loading, setLoading] = useState(false)
    const [controlRender, setControlRender] = useState(false)
    const [options, setOptions] = useState<IOptionsProps[]>([])
    const [option_selected, setOptionSelected] = useState<IOptionsProps | null>(defaultValue ? defaultValue : null)

    const select_ref = useRef<ISelectRefProps>(null)

    const search = useCallback(async (name) => {
        setLoading(true)
        const result = await searchOptions(name)
        setOptions([...result.formatted])
        setLoading(false)
    }, [isDisabled])

    const onInputChange = useCallback((value) => {
        setOptions([])
        setLoading(true)
        debounce(() => search(value), 800)
    }, [isDisabled])

    const searchWithoutDebounce = useCallback((value) => {
        setOptions([])
        setLoading(true)
        search(value)
    }, [isDisabled])

    const getValue = useCallback(() => option_selected, [option_selected])

    const cancelLoading = useCallback(() => { setLoading(false) }, [])

    const onChangeOption = useCallback((option) => {
        setOptionSelected(option)
        onChange(option)
    }, [isDisabled])

    const onInputFocus = useCallback((e) => { onFocus(e) }, [])

    const focus = useCallback(() => { select_ref.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ getValue, cancelLoading, focus }))

    useEffect(() => { searchWithoutDebounce("") }, [])

    useEffect(() => { setControlRender((atual) => !atual) }, [option_selected])

    const childrenSelect = (
        <FixRequiredSelect
            ref={select_ref}
            isDisabled={isDisabled}
            onFocus={onInputFocus}
            onBlur={() => searchWithoutDebounce("")}
            noOptionsMessage={() => noOptionsMessage}
            placeholder=""
            isClearable={isClearable}
            hasIcon={hasIcon}
            isLoading={loading}
            isRequired={isRequired}
            loadingMessage={() => <div style={{ position: "relative" }}>Buscando...</div>}
            options={options}
            onChange={onChangeOption}
            onInputChange={(text: any) => { onInputChange(text) }}
            defaultValue={option_selected}
            error={error}
        />
    )

    return (
        <>
            {controlRender && childrenSelect}
            {!controlRender && childrenSelect}
        </>
    )
}

export const SelectNoCreateSingle = forwardRef(SelectNoCreateSingleComponent)