interface IModules {
    name: string
    description: string
    friendlyName: string
    [key: string]: any
}

export const modules: IModules[] = [
    {
        name: "app_broker",
        description: "Aplicativo do corretor",
        friendlyName: "App do Corretor"
    },
    {
        name: "crms",
        description: "Integração com o CRM's",
        friendlyName: "CRM's"
    },
    {
        name: "credit_policy",
        description: "Política de análise",
        friendlyName: "Política de Análise"
    },
    {
        name: "persons",
        description: "Pessoas",
        friendlyName: "Pessoas"
    },
    {
        name: "properties",
        description: "Imóveis",
        friendlyName: "Imóveis"
    },
    {
        name: "queries",
        description: "Consultas de CPF/CNPJ",
        friendlyName: "Consultas"
    },
    {
        name: "proposals",
        description: "Propostas",
        friendlyName: "Propostas"
    },
    {
        name: "documents_eletronics",
        description: "Documentos Eletrônicos",
        friendlyName: "Doc's Eletrônicos"
    },
    {
        name: "documents_eletronics_models",
        description: "Modelos de documentos eletrônicos",
        friendlyName: "Modelos de Doc's Eletrônicos"
    },
    {
        name: "documents",
        description: "Amarzenamento de documentos",
        friendlyName: "Amarzenamento de documentos"
    }
]