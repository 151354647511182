import React from "react";
import { DashboardRoot } from "./Root/index";
import { DashboardPage } from "../../components/DashboardPage";

export const Dashboard: React.FC = () => {

    return (
        <DashboardPage>
            <DashboardRoot />
        </DashboardPage>
    )
}