import React, { useCallback, useImperativeHandle, useState } from "react";
import { FiHome } from "react-icons/fi";
import { useApi } from "../../../hooks/api";
import { useGlobal } from "../../../hooks/global";
import { IOptionsProps } from "../../../interfaces";
import { ModalProperty } from "../../Modals/ModalProperty";
import { ISelectCreateSingleProps, ISelectCreateSingleRefProps, SelectCreateSingle } from "../BaseSelects/SelectCreateSingle";

export interface ISelectPropertyRefProps {
    getValue: () => IOptionsProps | null
    focus: () => void
}

interface ISelectPropertyProps extends ISelectCreateSingleProps {
    defaultValue?: IOptionsProps
    moreQueries?: object
    backgroundVisible?: boolean
}

const SelectPropertyComponent: React.ForwardRefRenderFunction<ISelectPropertyRefProps, ISelectPropertyProps> = (props, ref) => {
    const { backgroundVisible } = props
    const { defaultValue = null, onChange = () => { } } = props

    const { theme } = useGlobal()
    const { consultsServices } = useApi()

    const [openModalNewProperty, setOpenModalNewProperty] = useState(false)
    const [openModalEditProperty, setOpenModalEditProperty] = useState(false)
    const [propertySelected, setPropertySelected] = useState<IOptionsProps | null>(defaultValue)

    const select_ref = React.useRef<ISelectCreateSingleRefProps>(null)

    const onChangeOption = useCallback((value) => {
        setPropertySelected(value ? { ...value } : null)
        onChange(value ? { ...value } : null)
    }, [])

    const handleCancelModalProperty = useCallback(() => {
        setOpenModalNewProperty(false)
        setOpenModalEditProperty(false)
    }, [])

    const handleSaveModalProperty = useCallback((data: IOptionsProps) => {
        setPropertySelected(data)
        handleCancelModalProperty()
        onChange(data)
    }, [])

    const handleCreateNewProperty = useCallback(() => {
        setOpenModalNewProperty(true)
    }, [])

    const handleEditProperty = useCallback(() => {
        setOpenModalEditProperty(true)
    }, [])

    const getValue = useCallback(() => propertySelected, [propertySelected])

    const focus = useCallback(() => { select_ref.current?.focus() }, [select_ref])

    useImperativeHandle(ref, () => ({ getValue, focus }))

    return (
        <>
            <div style={{}}>
                <SelectCreateSingle
                    ref={select_ref}
                    placeholder=""
                    onClick={handleEditProperty}
                    onChange={onChangeOption}
                    {...props}
                    onCreateOption={handleCreateNewProperty}
                    searchOptions={(search: string) => consultsServices.property_id({ search, ...props.moreQueries })}
                    icon={<FiHome />}
                />
                {/* <Line style={{ justifyContent: "flex-end" }}>
                    <NewPropertyButton
                        theme={theme}
                        onClick={() => setOpenModalNewProperty(true)}
                        children="+"
                    />
                </Line> */}
            </div>
            {(openModalEditProperty || openModalNewProperty) && (
                <ModalProperty
                    openModal
                    onCancel={handleCancelModalProperty}
                    onSave={handleSaveModalProperty}
                    defaultId={openModalEditProperty ? propertySelected?.value : undefined}
                    backgroundVisible={backgroundVisible}
                />
            )}
        </>
    )
}

export const SelectProperty = React.forwardRef(SelectPropertyComponent)